import { useRecoilValue } from 'recoil';
import { ContextMenu } from 'components/general/ContextMenu';
import React, { memo, useRef } from 'react';
import { cellState } from 'store/attribution';
import { AttributionCellContent } from './AttributionCellContent';
import { AttributionMarkedCell } from './AttributionMarkedCell';
import type { AttributionCellContextMenuActions } from './AttributionCellContextMenuContent';
import { AttributionCellContextMenuContent } from './AttributionCellContextMenuContent';
import type { ColumnId, ProductAttributeContent } from 'store/attribution.types';
import './AttributionCell.module.scss';

/**
 * @todo Change confidenceLevel type to string union
 */
type Props = {
  isMarked?: boolean;
  onBlur?: () => void;
  columnId: ColumnId;
  itemId: ProductAttributeContent['itemId'];
  isDisabled?: boolean;
};

export const AttributionCell = memo(function AttributionCell(props: Props) {
  const { isMarked, onBlur, columnId, itemId, isDisabled } = props;
  const content = useRecoilValue(cellState({ columnId, itemId }));
  const contextMenuRef = useRef<AttributionCellContextMenuActions>(null);

  return (
    <ContextMenu
      ContentComponent={AttributionCellContextMenuContent}
      actionsRef={contextMenuRef}
      history={content.history}
    >
      {contextMenuHandler => {
        const contentProps = {
          content,
          isDisabled,
          history,
          contextMenuHandler,
        };

        return isMarked ? (
          <AttributionMarkedCell {...contentProps} onBlur={onBlur} ref={contextMenuRef} isMarked />
        ) : (
          <AttributionCellContent {...contentProps} />
        );
      }}
    </ContextMenu>
  );
});
