import type { ErrorInfo } from 'react';
import React, { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { Nav } from './nav/Nav';
import { Main } from './Main';
import { history } from '../../utils/history';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../general/ErrorFallback';
import { logger } from '../../utils/logger';
import { ToastMessage } from '../general/ToastMessage';
import { Loader } from '../general/Loader';
import Highcharts from 'highcharts';
import { env } from '../../../env';
import { HotkeysProvider } from 'react-hotkeys-hook';
import { useAuthentication } from 'hooks/useAuthentication';
import { useAnalytics } from 'hooks/useAnalytics';
import { useResetUrl } from 'hooks/useResetUrl';

// For Mabl tests
window.Highcharts = Highcharts;

function InnerApp() {
  const loader = <Loader textDisplayed />;
  const { user, auth, customerId } = useAuthentication();
  useAnalytics(user, customerId);
  useResetUrl(customerId);

  if (!auth && env.USE_AUTH) {
    return loader;
  }

  return (
    <Suspense fallback={loader}>
      <Nav />
      <Suspense fallback={loader}>
        <Main />
      </Suspense>
      <ToastMessage />
    </Suspense>
  );
}

// eslint-disable-next-line react/no-multi-comp
export function App() {
  const logError = (error: Error, info: ErrorInfo) => {
    logger(error.message, error.stack, info.componentStack);
  };

  return (
    <HistoryRouter history={history}>
      <RecoilRoot>
        <HotkeysProvider>
          <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
            <InnerApp />
          </ErrorBoundary>
        </HotkeysProvider>
      </RecoilRoot>
    </HistoryRouter>
  );
}
