import React, { useMemo } from 'react';
import styles from './AttributionCellContextMenuContent.module.scss';
import { Icon, type IconName } from 'components/general/Icon';
import { join } from 'components/general/Divider';
import { useHotkeys } from 'react-hotkeys-hook';
import { Shortcut } from 'components/general/Shortcut';
import { noop } from '@harmonya/utils';
import { shortcuts } from 'utils/shortcuts';
import { useEnableOnlySingleHotKeysScope } from 'hooks/useEnableOnlySingleHotKeysScope';
import type { ContentComponentProps } from 'components/general/DisplayedContextMenu';
import { useBooleanState } from 'hooks/useBooleanState';
import { AttributionCellHistory } from './AttributionCellHistory';
import type { RevisionRequestSchema } from '@harmonya/attribution.types';
import classNames from 'classnames';

export type AttributionCellContextMenuActions = {
  copy?: () => Promise<void>;
  paste?: () => Promise<void>;
  edit?: () => void;
  validate?: () => void;
};

type ContextMenuItem = {
  iconName: IconName;
  label: string;
  onClick: () => void;
  shortcut: string;
  closeDisabled?: boolean;
  isDisabled?: boolean;
};

export type ContextMenuGroup = ContextMenuItem[];

type Props = ContentComponentProps & {
  actionsRef: React.RefObject<AttributionCellContextMenuActions>;
  history: RevisionRequestSchema[];
};

export function AttributionCellContextMenuContent(props: Props) {
  const { close, actionsRef, history } = props;
  const hotKeysScope = useEnableOnlySingleHotKeysScope('contextMenu');
  const [historyDisplayed, displayHistory] = useBooleanState(false);

  /** @todo Consider moving it out once all actions will implemented + Consider move outside to make the useHotKeys in the loop below less dangerous */
  const groups = useMemo(() => {
    const getAction = (action: keyof AttributionCellContextMenuActions) => {
      const ensuredAction = actionsRef.current?.[action];

      return ensuredAction
        ? {
            onClick: ensuredAction,
          }
        : {
            onClick: noop,
            isDisabled: true,
          };
    };

    const rawGroups: ContextMenuGroup[] = [
      [
        {
          iconName: 'copy',
          label: 'Copy',
          ...getAction('copy'),
          shortcut: shortcuts.copy,
        },
        {
          iconName: 'clipboard',
          label: 'Paste',
          ...getAction('paste'),
          shortcut: shortcuts.paste,
        },
      ],
      [
        {
          iconName: 'circle-edit',
          label: 'Edit Cell',
          ...getAction('edit'),
          shortcut: 'shift+f2',
        },
        {
          iconName: 'circle-check',
          label: 'Validate Cell',
          ...getAction('validate'),
          shortcut: 'alt+v',
        },
        {
          iconName: 'box-archive',
          label: 'View History Versions',
          onClick: displayHistory,
          shortcut: 'meta+alt+shift+h',
          closeDisabled: true,
          isDisabled: !history.length,
        },
      ],
    ];
    const computedGroups = rawGroups.map(items =>
      items.map(({ onClick, closeDisabled, ...item }) => ({
        onClick: closeDisabled
          ? onClick
          : () => {
              close();
              onClick();
            },
        ...item,
      }))
    );

    return computedGroups;
  }, [close, actionsRef.current, history.length]);

  for (const items of groups) {
    for (const { shortcut, onClick } of items) {
      useHotkeys(shortcut, onClick, { scopes: hotKeysScope }, [shortcut, onClick]);
    }
  }

  const content = historyDisplayed ? (
    <AttributionCellHistory history={history} />
  ) : (
    <div className={styles.itemsContianer}>
      {join(
        groups.map(items =>
          items.map(({ iconName, label, onClick, shortcut, isDisabled }, i) => (
            <div
              key={i}
              className={classNames(styles.item, isDisabled && styles.disabled)}
              onClick={onClick}
            >
              <Icon name={iconName} weight='light' />
              {label}
              {shortcut && <Shortcut>{shortcut}</Shortcut>}
            </div>
          ))
        ),
        { direction: 'horizontal' }
      )}
    </div>
  );

  return <div className={styles.container}>{content}</div>;
}
