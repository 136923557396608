import { Icon } from 'components/general/Icon';
import React, { type MouseEvent } from 'react';
import { attributionActionIcons } from './attribution.utils';
import styles from './AttributionCellValidationButton.module.scss';
import appStyles from '../../layout/App.module.scss';

type Props = {
  validate?: () => void;
  isDisabled?: boolean;
  isEdited?: boolean;
};

export function AttributionCellValidationButton(props: Props) {
  const { isDisabled, validate, isEdited } = props;
  const handleClick =
    validate &&
    ((event: MouseEvent) => {
      event.stopPropagation();
      validate();
    });

  const iconName = isEdited
    ? attributionActionIcons.edited.name
    : attributionActionIcons.validated.name;

  return (
    <button className={styles.button} disabled={isDisabled} onClick={handleClick}>
      <Icon name={iconName} className={appStyles.transition} weight='regular' />
    </button>
  );
}
