import React from 'react';
import { Icon } from 'components/general/Icon';
import { attributionConfidenceLevelIcons } from './attribution.utils';
import styles from './AttributionCellConfidenceLevel.module.scss';
import attributionStyles from './AttributionPage.module.scss';
import classNames from 'classnames';
import type { ConfidenceLevel } from 'store/attribution.types';

type Props = {
  level: ConfidenceLevel;
};

export function AttributionCellConfidenceLevel(props: Props) {
  const { level } = props;

  return (
    <div className={classNames(styles.level, attributionStyles[level])}>
      <Icon name={attributionConfidenceLevelIcons[level].name} weight='solid' />
    </div>
  );
}
