import type { RefObject } from 'react';
import { useEffect } from 'react';

export function useOnClickOutside(
  callback: () => void,
  refs: Array<RefObject<Node> | undefined>,
  isEnabled = true
): void {
  const handleClick = ({ target }: MouseEvent) => {
    if (target instanceof Node) {
      const isClickOnRefsOrOnTheirChildren = refs.some(ref => ref?.current?.contains(target));

      if (!isClickOnRefsOrOnTheirChildren) {
        callback();
      }
    }
  };

  useEffect(() => {
    if (isEnabled) {
      const abortController = new AbortController();

      document.body.addEventListener('mousedown', handleClick, { signal: abortController.signal });

      return () => abortController.abort();
    }
  }, [isEnabled, ...refs]);
}
