import React from 'react';
import styles from './AttributionCellHistory.module.scss';
import type { IconName } from 'components/general/Icon';
import { Icon } from 'components/general/Icon';
import { useRecoilValue } from 'recoil';
import { attributionActorsState } from 'store/attribution';
import { attributionActionIcons } from './attribution.utils';
import attributionStyles from './AttributionPage.module.scss';
import classNames from 'classnames';
import type { RevisionRequestSchema } from '@harmonya/attribution.types';
import { formatDateTime } from '@harmonya/utils';

const expandedAttributionActionIcons = {
  ...attributionActionIcons,
  replaced: {
    ...attributionActionIcons.edited,
    title: 'Replaced',
  },
};

const renderAction = (action: RevisionRequestSchema['actions'][number]) => {
  const render = (icon: { name?: IconName; title: string }, oldValue = action.oldValue) => {
    const previousValueElement = oldValue && (
      <>
        <q className={styles.value}>{oldValue}</q>
        <div className={styles.action}>To</div>
      </>
    );

    return (
      <>
        {icon.name && (
          <Icon
            name={icon.name}
            weight='light'
            className={attributionStyles[action.cellType ?? '']}
          />
        )}
        <div className={styles.action}>{icon?.title}</div>
        {previousValueElement}
        <q className={styles.value}>{action.value}</q>
      </>
    );
  };

  switch (action.cellType) {
    case 'validated':
      return render(expandedAttributionActionIcons.validated, null);
    case 'provided':
      return render({ title: 'Provided' });
    case 'edited':
      return render(expandedAttributionActionIcons.edited);
    case 'predicted':
      return (
        <>
          {/** @todo: Enable when adding confidenceLevel to action */}
          {/* {action.confidenceLevel ? (
            <AttributionCellConfidenceLevel level={action.confidenceLevel} />
          ) : null} */}
          {render({ title: 'Predicted' })}
        </>
      );
  }
};

type Props = {
  history: RevisionRequestSchema[];
};

export function AttributionCellHistory(props: Props) {
  const { history } = props;
  const actors = useRecoilValue(attributionActorsState);

  return (
    <div>
      <div className={styles.header}>
        <Icon name='history' weight='light' />
        <div className={styles.title}>Cell Activity Log</div>
      </div>
      <div className={styles.items}>
        {history.map((revision, index) => {
          const actor = actors.get(revision.actorId);

          if (!actor) {
            return null;
          }

          return (
            <div key={index} className={styles.item}>
              <div className={styles.itemHeader}>
                <div
                  className={classNames(
                    styles.bigActorAvatar,
                    actor.id === 'model@harmonya' && styles.isHarmonya
                  )}
                >
                  {actor.displayName[0].toUpperCase()}
                </div>
                <div className={styles.headerDetails}>
                  <div className={styles.actorName}>{actor.displayName}</div>
                  <div className={styles.timestamp}>{formatDateTime(revision.timestamp)}</div>
                </div>
              </div>
              {revision.actions.map((action, actionIndex) => (
                <div key={actionIndex} className={styles.itemContent}>
                  {renderAction(action)}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
}
