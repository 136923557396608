import React, { useState } from 'react';
import styles from './AttributionHeaderCellFiltersOptionsSection.module.scss';
import type { ColumnId, Filter } from 'store/attribution.types';
import { cellOptionsFilterState, columnOptionFiltersState } from 'store/attributionPage';
import { useRecoilValue } from 'recoil';
import { AttributionHeaderCellFiltersFilterSection } from './AttributionHeaderCellFiltersFilterSection';
import { Input } from 'components/general/Input';
import { getSortedFilteredOptions } from 'utils/sortedFilteredOptions';
import { iterator } from '@harmonya/utils';
import { HighlightTexts } from 'components/general/HighlightTexts';

type Props = {
  columnId: ColumnId;
};

const filterOptions = (options: Map<string, Filter>, searchText: string) => {
  if (!searchText) {
    return { filteredOptions: options };
  }

  const { filteredOptions, highlightedOptions } = getSortedFilteredOptions(searchText, [
    ...options.keys(),
  ]);
  const filteredOptionsAsMap =
    filteredOptions.length === options.size
      ? options
      : new Map(
          iterator.definedMap(filteredOptions, option => {
            const filter = options.get(option);

            if (filter) {
              return [option, filter];
            }
          })
        );

  return { filteredOptions: filteredOptionsAsMap, highlightedOptions };
};

export function AttributionHeaderCellFiltersOptionsSection(props: Props) {
  const { columnId } = props;
  const options = useRecoilValue(columnOptionFiltersState(columnId));
  const [searchText, setSearchText] = useState('');
  const { filteredOptions, highlightedOptions } = filterOptions(options, searchText);

  return (
    <AttributionHeaderCellFiltersFilterSection
      columnId={columnId}
      setFilterState={cellOptionsFilterState}
      options={filteredOptions}
      scrollbarDisplayed
      sortByCountEnabled
      labelFormatter={label => (
        <div>
          <HighlightTexts items={highlightedOptions?.get(label)} defaultText={label} />
        </div>
      )}
    >
      {() => (
        <div className={styles.searchInputContainer}>
          <Input
            containerClassName={styles.searchInput}
            placeholder='Filter By Value'
            iconName='search'
            value={searchText}
            onInput={({ currentTarget }) => setSearchText(currentTarget.value)}
            autoFocus
          />
        </div>
      )}
    </AttributionHeaderCellFiltersFilterSection>
  );
}
