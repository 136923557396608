import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import styles from './VirtualizedTable.module.scss';
import appStyles from '../layout/App.module.scss';
import { Icon } from './Icon';
import { Tooltip } from './Tooltip';
import type { Properties, SortDirection, Stringable } from '@harmonya/utils';
import { set, string, hasEllipsis } from '@harmonya/utils';

export type ColumnMap<T, K extends keyof T & string> = {
  key: K;
  widthUnits: number;
  header: string;
  formatter: (value: T[K]) => React.ReactChild;
  defaultSortDirection?: SortDirection;
};

const getSortDirectionOpposite = (direction: SortDirection) =>
  direction === 'asc' ? 'desc' : 'asc';

function* ellipsisElementIndexesGenerator(collection: HTMLCollection) {
  for (let i = 0; i < collection.length; i++) {
    const element = collection[i];

    if (hasEllipsis(element)) {
      yield i;
    }
  }
}

type Props<T, K extends keyof T & string> = {
  columnsMap: ColumnMap<T, K>[];
  rows: T[];
  rowHeight: number;
  overscan?: number;
  sort?: {
    field: keyof Properties<T, Stringable>;
    direction: SortDirection;
    onChange: (field: keyof T, direction: SortDirection) => void;
    valueGetter?: (value: T[keyof Properties<T, Stringable>]) => Stringable | undefined;
    dependencies?: ReadonlyArray<unknown>;
  };
  keyGetter?: (item: T, index: number) => string | number;
};

export function VirtualizedTable<T, K extends keyof T & string>(props: Props<T, K>) {
  const { columnsMap, rows, rowHeight, overscan = 5, keyGetter = (_, i) => i, sort } = props;
  const [sortedRows, setSortedRows] = useState(rows);
  const [ellipsisHeadersIndexes, setEllipsisHeadersIndexes] = useState(new Set<number>());
  const [ellipsisCellsIndexes, setEllipsisCellsIndexes] = useState(new Map<number, Set<number>>());
  const parentRef = useRef<HTMLDivElement>(null);
  const headerRowRef = useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    overscan,
    getScrollElement: () => parentRef.current,
    count: sortedRows.length,
    estimateSize: useCallback(() => rowHeight, []),
  });
  const rowsChangedDependencies = [
    rows,
    sort?.field,
    sort?.direction,
    ...(sort?.dependencies ?? []),
  ];
  const gridTemplateColumns = columnsMap.map(({ widthUnits }) => `${widthUnits}fr`).join(' ');
  const sortChange =
    !!sort &&
    ((field: keyof T, defaultDirection?: SortDirection) => {
      const newDirection =
        sort.field === field
          ? getSortDirectionOpposite(sort.direction)
          : defaultDirection ?? sort.direction;

      sort.onChange(field, newDirection);
    });
  const getSort =
    !!sort &&
    ((field: keyof T) => {
      const getSortIcon = (direction: SortDirection) => (
        <Icon
          rotated={direction === 'asc'}
          name='bars-sort'
          className={classNames(styles.sortIcon, sort.direction === direction && styles.active)}
        />
      );

      return (
        <div className={classNames(styles.sortIcons, sort.field === field && styles.active)}>
          {getSortIcon('asc')}
          {getSortIcon('desc')}
        </div>
      );
    });

  const setNewSortedRows = () => {
    if (sort) {
      const { field, direction, valueGetter = value => value } = sort;

      const sortFunc = (a: T, b: T) => {
        const aValue = valueGetter(a[field])?.toString();

        if (aValue == null) {
          return -1;
        }

        const bValue = valueGetter(b[field])?.toString();

        if (bValue == null) {
          return 1;
        }

        return aValue.localeCompare(bValue, undefined, { numeric: true, sensitivity: 'base' });
      };

      const newSortedRows = [...rows].sort(
        direction === 'asc' ? sortFunc : (a, b) => sortFunc(b, a)
      );

      setSortedRows(newSortedRows);
    }
  };

  const setNewEllipsisHeadersIndexes = () => {
    if (headerRowRef.current) {
      const newEllipsisHeadersIndexes = new Set<number>();
      const headerElements = headerRowRef.current.getElementsByClassName(styles.headerCell);

      set.addMany(newEllipsisHeadersIndexes, ellipsisElementIndexesGenerator(headerElements));
      setEllipsisHeadersIndexes(newEllipsisHeadersIndexes);
    }
  };

  const setNewEllipsisCellsIndexes = () => {
    if (parentRef.current) {
      const newEllipsisCellsIndexes = new Map<number, Set<number>>();
      const rowElements = parentRef.current.getElementsByClassName(
        styles.bodyRow
      ) as HTMLCollectionOf<Element>;

      for (let rowIndex = 0; rowIndex < rowElements.length; rowIndex++) {
        const rowElement = rowElements[rowIndex];
        const rowIndexes = new Set<number>();

        set.addMany(rowIndexes, ellipsisElementIndexesGenerator(rowElement.children));

        if (rowIndexes.size) {
          newEllipsisCellsIndexes.set(rowIndex, rowIndexes);
        }
      }

      setEllipsisCellsIndexes(newEllipsisCellsIndexes);
    }
  };

  useEffect(() => {
    setNewSortedRows();
  }, rowsChangedDependencies);

  useEffect(() => {
    // We need the timeout to let the DOM render before we check the ellipsis
    setTimeout(setNewEllipsisHeadersIndexes);
  }, [columnsMap]);

  useEffect(() => {
    // We need the timeout to let the DOM render before we check the ellipsis
    setTimeout(setNewEllipsisCellsIndexes);
  }, [rowVirtualizer.getVirtualItems()[0]?.index, ...rowsChangedDependencies]);

  /** @todo: Check if this can remove rowHeight */
  // useEffect(() => {
  //     rowVirtualizer.measure()
  // }, [])

  return (
    <>
      <div ref={headerRowRef} className={styles.headerRow} style={{ gridTemplateColumns }}>
        {columnsMap.map(({ header, key, defaultSortDirection }, i) => {
          const innerElements = (
            <>
              <span className={styles.headerCell}>{header}</span>
              {getSort && getSort(key)}
            </>
          );
          const containerProps = {
            className: classNames(appStyles.horizontalFlex, styles.cell, styles.clickable),
            onClick: () => sortChange && sortChange(key, defaultSortDirection),
            content: header,
          };

          return ellipsisHeadersIndexes.has(i) ? (
            <Tooltip key={key} {...containerProps} content={header}>
              {innerElements}
            </Tooltip>
          ) : (
            <div key={key} {...containerProps}>
              {innerElements}
            </div>
          );
        })}
      </div>
      <div ref={parentRef} className={appStyles.overflowOverlay}>
        <ul className={styles.innerContainer} style={{ height: rowVirtualizer.getTotalSize() }}>
          {rowVirtualizer.getVirtualItems().map(({ size, index, start }, rowIndex) => (
            <li
              className={styles.bodyRow}
              key={keyGetter(sortedRows[index], index)}
              style={{
                height: size,
                transform: `translateY(${string.pixelize(start)})`,
                gridTemplateColumns,
              }}
            >
              {columnsMap.map(({ key, formatter }, cellIndex) => {
                const content = formatter(sortedRows[index][key]);

                return ellipsisCellsIndexes.get(rowIndex)?.has(cellIndex) ? (
                  <Tooltip key={key} className={styles.cell} content={content}>
                    {content}
                  </Tooltip>
                ) : (
                  <div key={key} className={styles.cell}>
                    {content}
                  </div>
                );
              })}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
