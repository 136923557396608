import type { PropsWithChildren } from 'react';
import React, { createContext } from 'react';
import type { RecoilValueReadOnly } from 'recoil';
import { useRecoilValue } from 'recoil';

export const OrderedColumnsContext = createContext([] as number[]);

type Props = {
  recoilState: RecoilValueReadOnly<number[]>;
};

export function OrderedColumnsProvider(props: PropsWithChildren<Props>) {
  const { recoilState, children } = props;
  const columnIds = useRecoilValue(recoilState);

  return (
    <OrderedColumnsContext.Provider value={columnIds}>{children}</OrderedColumnsContext.Provider>
  );
}
