import React from 'react';
import { createPortal } from 'utils/portal';
import styles from './AttributionHighlightedCell.module.scss';
import { AttributionCellDropdown } from './AttributionCellDropdown';
import type { ColumnId } from 'store/attribution.types';

export type HighlightProps = {
  initDisplayValue: string;
  rect: DOMRect;
  isOpenedByDoubleClick: boolean;
};

type Props = HighlightProps & {
  columnId: ColumnId;
  setValue: (value: string) => void;
};

export function AttributionHighlightedCell(props: Props) {
  const {
    initDisplayValue,
    rect: { height, width, top, left },
    isOpenedByDoubleClick,
    columnId,
    setValue,
  } = props;
  const style = { minHeight: height, minWidth: width, top, left };
  const placeholderCell = <div className={styles.cell} />;

  return (
    <>
      {placeholderCell}
      {createPortal(
        'highlighted-cell',
        <div className={styles.highlightedCell} style={style}>
          <div className={styles.value}>
            <AttributionCellDropdown
              setValue={setValue}
              columnId={columnId}
              initDisplayValue={initDisplayValue}
              isOpenedByDoubleClick={isOpenedByDoubleClick}
            />
          </div>
        </div>
      )}
    </>
  );
}
