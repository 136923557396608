import { env } from '../../env';

export function shouldTrackAnalytics(
  email = '',
  excludedEmailDomains = env.ANALYTICS_EXCLUDED_EMAIL_DOMAINS
) {
  const [, emailDomain] = email.split('@');

  return emailDomain ? !excludedEmailDomains.includes(emailDomain) : false;
}
