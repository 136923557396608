import { styleVariables } from './styleVariables';
import { deltaE, hslToLab } from './color';

interface Groupable {
  groups?: number[];
}

type Colorable<T> = T &
  Groupable & {
    colorId?: number;
  };

function* unusedColorIndex(): Generator<number, number, number> {
  let index = 0;

  while (true) {
    yield toColorId(index % styleVariables.tagColors.length);
    index++;
  }
}

const toColorId = (index: number) => index + 1;

const getNextUnusedColor = (usedColorIds: Set<number>): number => {
  const index = styleVariables.tagColors.findIndex((_, i) => !usedColorIds.has(toColorId(i)));
  const colorId = toColorId(index);

  // If all colors are used, the index will be -1. In that case we will return the next unused color
  return colorId || unusedColorIndex().next().value;
};

const getGroupedColorableItems = <T>(items: Colorable<T>[]): Map<number, Colorable<T>[]> => {
  const groupedItems = items.reduce((groups, item) => {
    item.groups?.forEach(groupKey => {
      const groupItems = groups.get(groupKey) ?? [];

      groupItems.push(item);
      groups.set(groupKey, groupItems);
    });

    return groups;
  }, new Map<number, Colorable<T>[]>());
  const sortedGroupedItemsEntries = [...groupedItems.entries()].sort(
    ([, aItem], [, bItem]) => bItem.length - aItem.length
  );

  return new Map(sortedGroupedItemsEntries);
};

const getMinSimilarity = (color: string, groupedItemColors: Set<string>) => {
  let minSimilarity = Infinity;

  for (const groupedItemsColor of groupedItemColors) {
    const groupedItemsColorLab = hslToLab(groupedItemsColor);
    const colorLab = hslToLab(color);
    const similarity = deltaE(groupedItemsColorLab, colorLab);

    minSimilarity = Math.min(minSimilarity, similarity);
  }

  return minSimilarity;
};

const getMostDifferenceColorId = (
  defaultColorId: number,
  groupedItemColors: Set<string> | undefined,
  usedColorIds: Set<number>
) => {
  let mostDifferentColorId = defaultColorId;
  let mostDifferentColorSimilarity = 0;

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  groupedItemColors?.size &&
    styleVariables.tagColors.forEach((color, i) => {
      const colorId = toColorId(i);

      // You can get more contrasting colors (but fewer colors overall) by removing "&& !usedColorIds.has(colorId)"
      if (!groupedItemColors.has(color) && !usedColorIds.has(colorId)) {
        const minSimilarity = getMinSimilarity(color, groupedItemColors);

        if (minSimilarity > mostDifferentColorSimilarity) {
          mostDifferentColorSimilarity = minSimilarity;
          mostDifferentColorId = toColorId(i);
        }
      }
    });

  return mostDifferentColorId;
};

const getGroupedItemsColors = <T>(
  groups: number[] | undefined,
  groupedColorableItems: Map<number, Colorable<T>[]>
) =>
  groups?.reduce((colors, groupId) => {
    groupedColorableItems.get(groupId)?.forEach(({ colorId }) => {
      if (colorId != null) {
        const color = styleVariables.tagColors[colorId - 1];

        colors.add(color);
      }
    });

    return colors;
  }, new Set() as Set<string>) ?? new Set();

export const colorize = <T extends Groupable>(
  items: T[]
): (Omit<Colorable<T>, 'colorId'> & { colorId: number })[] => {
  const colorableItems: Colorable<T>[] = items.map(item => ({ ...item, colorId: undefined }));
  const usedColorIds = new Set<number>();
  const groupedColorableItems = getGroupedColorableItems(colorableItems);

  colorableItems.forEach(item => {
    const groupedItemColors = getGroupedItemsColors(item.groups, groupedColorableItems);
    const defaultColor = getNextUnusedColor(usedColorIds);

    item.colorId = getMostDifferenceColorId(defaultColor, groupedItemColors, usedColorIds);

    usedColorIds.add(item.colorId);
  });

  const colorizedItems = colorableItems.map(({ colorId, ...item }) => ({
    ...item,
    colorId: colorId ?? getNextUnusedColor(usedColorIds),
  }));

  return colorizedItems;
};
