import type { PropsWithChildren } from 'react';
import React, { createContext } from 'react';
import { useRecoilValue, type RecoilState } from 'recoil';

export const PinnedColumnsContext = createContext(new Set<number>());

type Props = {
  recoilState: RecoilState<Set<number>>;
};

export function PinnedColumnsProvider(props: PropsWithChildren<Props>) {
  const { recoilState, children } = props;
  const columnIds = useRecoilValue(recoilState);

  return (
    <PinnedColumnsContext.Provider value={columnIds}>{children}</PinnedColumnsContext.Provider>
  );
}
