import type { TagType } from './TagType';
import { BaseTag } from './BaseTag';
import type { Dimensions } from 'store/tags';
import type { Tag as TagModel } from '@harmonya/models';

export class Tag extends BaseTag implements Omit<TagModel, 'dominantTypeId' | 'typeIds'> {
  productsCount: number;

  constructor(
    { productsCount, ...tag }: TagModel,
    allTagTypes: Map<number, TagType>,
    dimensions: Dimensions
  ) {
    super(tag, allTagTypes, dimensions);
    this.productsCount = productsCount;
  }
}
