import type { Icon } from 'components/general/Icon';
import type { ComponentProps } from 'react';
import { missingValue, number } from '@harmonya/utils';

export type FormmaterKey = keyof typeof formatters;

type FormattedValue = {
  title: string;
  value: string;
  tooltipKey?: string;
  icon: ComponentProps<typeof Icon>;
};

export const isMissingValue = (value?: ValueType) => value == null || isNaN(value);

type ValueType = number | null;

const hasValue = (value?: ValueType): value is number => !isMissingValue(value);

export const valueFunc = (valueFunction: (value: number) => string) => (value?: ValueType) =>
  hasValue(value) ? valueFunction(value) : missingValue;

export const formatters = {
  productsCount: {
    title: 'Products',
    tooltipKey: 'productsTableMetadataProducts',
    valueGetter: valueFunc(number.format),
    icon: { name: 'box-archive' },
  },
  brandSignificance: {
    title: 'Significance',
    valueGetter: valueFunc(value => number.format(value, { maximumFractionDigits: 1 })),
    icon: { name: 'bullseye-arrow' },
  },
  // launchedProductsCount: { title: 'Launched Products', valueGetter: valueFunc(number.format) },
  brandsCount: {
    title: 'Brands',
    tooltipKey: 'productsTableMetadataBrands',
    valueGetter: valueFunc(number.format),
    icon: { name: 'bag-shopping' },
  },
  marketShare: {
    title: 'Market Share',
    valueGetter: valueFunc(number.percent),
    tooltipKey: 'productsTableMetadataMarketShare',
    icon: { name: 'chart-pie-simple' },
  },
  customerMarketShare: {
    title: (cutomerName: string) => `${cutomerName} Market Share`,
    valueGetter: valueFunc(number.percent),
    tooltipKey: 'productsTableMetadataCustomerMarketShare',
    icon: { name: 'customer-market-share' },
  },
  totalSales: {
    title: 'Total Sales',
    valueGetter: valueFunc(number.shortCurrency),
    icon: { name: 'circle-dollar' },
  },
  salesGrowth: {
    title: 'Sales Growth',
    valueGetter: valueFunc(number.percent),
    tooltipKey: 'productsTableMetadataSalesGrowth',
    icon: { name: 'arrow-up-left-from-circle', flip: 'horizontal' },
  },
  differentiationMagnitude: {
    title: 'Differentiation magnitude',
    valueGetter: valueFunc(value => number.format(value ?? null, { maximumFractionDigits: 1 })),
    icon: { name: 'magnifying-glass-chart' },
  },
  reviewProductsRatio: {
    title: "Products' Reviews rate",
    valueGetter: valueFunc(number.percent),
    tooltipKey: 'reviews',
    icon: { name: 'star' },
  },
  listingProductsRatio: {
    title: "Products' Listing rate",
    valueGetter: valueFunc(number.percent),
    tooltipKey: 'marketing',
    icon: { name: 'square-list' },
  },
} as const;

export const getTitle = (
  key: keyof typeof formatters,
  customerMame: string,
  titleOverride?: string
) => {
  if (titleOverride) {
    return titleOverride;
  }

  const { title } = formatters[key];

  return typeof title === 'function' ? title(customerMame) : title;
};

export const getFormattedValue = (
  key: keyof typeof formatters,
  rawValue?: number,
  customerMame = 'Customer',
  titleOverride?: string
): FormattedValue => {
  const { valueGetter, tooltipKey = undefined, icon } = { ...formatters[key] };
  const formattedTitle = getTitle(key, customerMame, titleOverride);
  const formattedValue = valueGetter(rawValue);

  return {
    value: formattedValue,
    title: formattedTitle,
    tooltipKey,
    icon,
  };
};
