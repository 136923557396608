export const brandingNames = [
  'Volkswagen Group',
  'Toyota Group',
  'Apple',
  'Samsung Electronics',
  'Daimler',
  'General Motors',
  'Ford',
  'Hon Hai Precision Industry',
  'Honda',
  'Cardinal Health',
  'Q',
  '4C',
  'C4',
  'Lu',
  'V8',
  '5Oz',
  'Aha',
  'Alo',
  'Bai',
  'C2O',
  'Doc',
  'Ibc',
  'Mio',
  'Nos',
  'Ole',
  'Ski',
  'Sos',
  'Stk',
  'Tab',
  'Tgb',
  'Ucc',
  'Utz',
  'V8R',
  'Vpx',
  '4505',
  '7 Up',
  'Bang',
  'Bare',
  'Bigs',
  'Club',
  'Coke',
  'Core',
  'Dare',
  'Dole',
  'Epic',
  'Fiji',
  'Fuze',
  'Gloe',
  'Guru',
  'Hi C',
  'Hint',
  'Humm',
  'Icee',
  'Inca',
  'Izze',
  'Jero',
  'Kind',
  'Kitu',
  'Koia',
  'Live',
  'Love',
  'Macs',
  'Nehi',
  'Nubu',
  'Nuun',
  'Oreo',
  'Osem',
  'Pace',
  'Pibb',
  'Porq',
  'Real',
  'Ritz',
  'Roar',
  'Roxy',
  'Silk',
  'Sobe',
  'Stok',
  'Stur',
  'Suja',
  'Tang',
  'Tazo',
  'Voss',
  'Wise',
  'Xing',
  'Zhou',
  'Zico',
  'A & W',
  'Abita',
  'Biena',
  'Bimbo',
  'Brisk',
  'Bubly',
  'Crush',
  "Dad's",
  "Del's",
  'Evian',
  'Fanta',
  'Faygo',
  'Forto',
  'Franz',
  'Gilda',
  'Glico',
  'Gluck',
  'Gripz',
  'Heath',
  "Jay's",
  'Jones',
  'Jumex',
  "Kar's",
  'Karma',
  'Kedem',
  'Kraft',
  'Krave',
  'Lance',
  'Looza',
  'Lotus',
  'Mambi',
  'Meiji',
  "Mic's",
  'Naked',
  'Nerds',
  'Neuro',
  'Nilla',
  'Nixie',
  'Nooma',
  'Ohana',
  'Owens',
  'Pepsi',
  'Petit',
  'Polar',
  'Primo',
  'Pyure',
  'Reign',
  'Ricon',
  'Royal',
  'Shaka',
  'Siete',
  'Spitz',
  'Steaz',
  'Surge',
  'Swiss',
  'Terra',
  "Tim's",
  "Tom's",
  'Twang',
  'Venom',
  'Wasar',
  'Wilde',
  'Zevia',
  'Act Ii',
  'Ale 81',
  'Amport',
  'Austin',
  "Barq's",
  'BigTex',
  'Biscos',
  'Bolt24',
  'Borden',
  'Bravos',
  'Breese',
  'Bugles',
  'Calbee',
  "Carr's",
  'Chomps',
  'Combos',
  "Conn's",
  'Dasani',
  'Deweys',
  "Duke's",
  'Dupont',
  'Family',
  'Fisher',
  'FitJoy',
  "Fitz's",
  'Fresca',
  'Fritos',
  'G Fuel',
  'Gamesa',
  'Giants',
  'Go Raw',
  "Good's",
  'Gullon',
  "Herr's",
  'Hiland',
  'Honest',
  'Iconic',
  'Ito En',
  'Jell-O',
  "Jody's",
  "Kern's",
  'Kevita',
  'Krispy',
  'Lancer',
  'Lipton',
  'Marley',
  'McCafe',
  'Milano',
  "Milo's",
  'Mistic',
  "Mott's",
  'Murray',
  'Nestle',
  'Oberto',
  'Orgain',
  'Ozarka',
  'Propel',
  'Purity',
  "Reed's",
  'Rhythm',
  "Rose's",
  'Rovira',
  'Shaken',
  'Sprite',
  'Squirt',
  'Stryve',
  'Sunnyd',
  'Tejava',
  'Torani',
  'Uptime',
  'V.I.P.',
  'Welchs',
  'Whisps',
  'Wtrmln',
  'Yachak',
  'Yerbae',
  "Zapp's",
  'Zenwtr',
  'Almonds',
  "Annie's",
  'Archway',
  'Arizona',
  'Bachman',
  'Barberi',
  'Barnana',
  'Beet It',
  'Belvita',
  'Big Red',
  'Big Tex',
  'Bigbolo',
  'Calidad',
  'Calpico',
  'Calypso',
  'Celsius',
  'Central',
  'Cheetos',
  'Cheezit',
  'Chifles',
  'Clamato',
  'Delight',
  'Diamond',
  'Doritos',
  "Elmer's",
  'Emerald',
  "Ethan's",
  'Frostie',
  'Funyuns',
  'Gardein',
  'Generic',
  'Glaceau',
  'Go Girl',
  'Grapico',
  'Hanover',
  'Hfactor',
  'Hippeas',
  'Hydrant',
  'Hydrive',
  'Imagine',
  "Julio's",
  'Keebler',
  'KidsLuv',
  'Knudsen',
  'Lacroix',
  'Langers',
  'Le Bleu',
  "Libby's",
  'Lifeaid',
  'Lifewtr',
  'Lillian',
  'Loacker',
  'Lowreys',
  'Martins',
  'Mission',
  'Moments',
  'Monster',
  'Mtn Dew',
  'Muccico',
  'Munchos',
  'Nabisco',
  'Nesquik',
  'Niagara',
  "Nonni's",
  'Nugrape',
  'Oatsome',
  'Odwalla',
  'Penrose',
  'Perrier',
  'Pig Out',
  'Pokemon',
  'Poppilu',
  'Premium',
  "Ralph's",
  'Rc Cola',
  'ReaLime',
  "Reese's",
  'Rethink',
  'Ridgies',
  'Ruffles',
  'Salerno',
  'Saranac',
  'Snapple',
  'Soylent',
  'Spfm Lp',
  "Stacy's",
  'Sundrop',
  'Sunkist',
  'Sunripe',
  'Tampico',
  'Teaonic',
  'Teavana',
  'Trimino',
  'Twister',
  'Walkers',
  "Welch's",
  'Wis-Pak',
  "Wyler's",
  'Wyoming',
  'Xochitl',
  'Xyience',
  'Yoo-Hoo',
  'Ae Dairy',
  'Aquafina',
  'Ashleigh',
  'Avengers',
  "Backer's",
  "Barnum's",
  'Barsmith',
  'Beanitos',
  'Big Blue',
  'Cape Cod',
  'Cellucor',
  'Chex Mix',
  'Classico',
  'Clif Bar',
  'Craisins',
  'Daelmans',
  'Dr. Enuf',
  'Eldorado',
  'Emeraldr',
  'Essentia',
  'Fairlife',
  'Focusaid',
  'Gatorade',
  'Goldfish',
  'Guerrero',
  "Hannah's",
  'Hog Wash',
  "Husman's",
  'Jel Sert',
  'Kool Aid',
  'Kool-Aid',
  'Lakewood',
  "Lowrey's",
  'Man Cave',
  'Marinela',
  'Mayfield',
  'Mondelez',
  'Moon Pie',
  "Mother's",
  'Munchies',
  'Nongshim',
  'Orangina',
  'Ovaltine',
  "Parker's",
  'Planters',
  'Pop Zero',
  'Popchips',
  'Powerade',
  'Pringles',
  'Q Drinks',
  'ReaLemon',
  'Red Bull',
  'Red Gold',
  'Rockstar',
  'Sabritas',
  'Sambazon',
  "Sander's",
  'Santitas',
  'Saratoga',
  'Shweppes',
  'Skittles',
  'Slim Jim',
  'Sprecher',
  'Sunshine',
  'Sunsweet',
  'Sweetzel',
  'Thick-It',
  'Tia Rosa',
  'Tostitos',
  'Tree Top',
  'Triscuit',
  "Turner's",
  'Twist Up',
  'V8 Juice',
  "Vernor's",
  "Virgil's",
  'Vita Ice',
  "Vitner's",
  'Voortman',
  'WellWell',
  'West Soy',
  'Yumearth',
  'Angostura',
  'Antojitos',
  'Aquavista',
  'Arrowhead',
  'Aura Bora',
  'Baken Ets',
  "Barritt's",
  'Beer Nuts',
  'BevSource',
  'Blue Mist',
  'Bodyarmor',
  'Bridgford',
  'Bundaberg',
  'Cape Codr',
  'Capri Sun',
  'Carnation',
  'Cheerwine',
  "Chester's",
  'Cibo Vita',
  'Coca Cola',
  'Cool Brew',
  'Corn Nuts',
  'CytoSport',
  'Danone Us',
  'Deer Park',
  'Deja Blue',
  'Diet Coke',
  'Diet Rite',
  'Don Julio',
  'Everfresh',
  'Fiber One',
  "Fischer's",
  'Funtastik',
  'Get Bizzy',
  'Gold Peak',
  'Good2Grow',
  "Gosling's",
  "Hershey's",
  'High Brew',
  'Hines Nut',
  'HomeMaker',
  'Homemaker',
  'Jiffy Pop',
  "Kellogg's",
  'Klarbrunn',
  'Kona Deep',
  'Kor Shots',
  'Krunchers',
  'La Sirena',
  'Lakewoodr',
  'Late July',
  'Lifewater',
  'Limitless',
  'Love Corn',
  'Lunch Box',
  'Mauna Loa',
  "Mcvitie's",
  "Michael's",
  'Mist Twst',
  'Mixallogy',
  'Nextfoods',
  'Northland',
  'Nos Turbo',
  'O Ke Doke',
  "Ol' Glory",
  'Old Dutch',
  'Orangette',
  'Peace Tea',
  'Peach Tea',
  'Pillsbury',
  'Protein2O',
  'Pure Leaf',
  'Pure Life',
  'Rold Gold',
  'Ruby Kist',
  'Schweppes',
  "Seagram's",
  "Shearer's",
  'Shirakiku',
  'Skinnypop',
  'Smartfood',
  "Smucker's",
  'Snak Club',
  'Som Sleep',
  'Spindrift',
  'Starbucks',
  'Starburst',
  "Stewart's",
  'Stirrings',
  'Sun Chips',
  'Sun Puffs',
  'Sweetzels',
  'Taco Bell',
  'Taco Loco',
  'Tillamook',
  'Tortiyahs',
  'Tropicana',
  'Vita Coco',
  'Wachusett',
  'Wit Group',
  'Wonderful',
  'Zing Zang',
  'Acai Roots',
  'Alkaline88',
  'All Market',
  'Aloha Maid',
  'Apple Beer',
  'Baby Water',
  'Bare Fruit',
  'Beanfields',
  "Big John's",
  'Boy Bawang',
  "Campbell's",
  'Canada Dry',
  'Charleston',
  'Cheribundi',
  'Chips Ahoy',
  'Coco Lopez',
  'Cook Kwees',
  "CookKwee's",
  'Core Power',
  'Cornfields',
  'Dang Foods',
  'Dean Foods',
  'Dole Juice',
  'Dr Thunder',
  'Dr. Pepper',
  'Drink&Play',
  'Enjoy Life',
  'Fever Tree',
  'Flow Water',
  'Fruit Rush',
  "Gardetto's",
  'Good Belly',
  'Good Karma',
  'Good Thins',
  "Guy's Wavy",
  'Hapi Water',
  'Health Ade',
  'Hellowater',
  'Herr Foods',
  'Home Maker',
  'Honey Maid',
  'Java House',
  'Jays Foods',
  'Jolly Time',
  'Joni Juice',
  'Kill Cliff',
  'La Colombe',
  'La Moderna',
  "Lay's Stax",
  'Little Hug',
  'Lucky Club',
  'Lucky Leaf',
  'Mamma Chia',
  'Mariquitas',
  'Maui Style',
  "Mcvitie'Sr",
  "Mikesell's",
  'Milkadamia',
  "Milo's Tea",
  'Mister Bee',
  "Mott's Llp",
  'Mr & Mrs T',
  'Neon Zebra',
  'Old London',
  'Old Vienna',
  "Owl's Brew",
  'Paw Patrol',
  'Pop Secret',
  'Pop Weaver',
  'PopCorners',
  'Ralph Good',
  'Ready Leaf',
  'Real Foods',
  'Red Elixir',
  'Rice Dream',
  'Rosa Foods',
  'Sabritones',
  "Sam's Cola",
  "Sheila G's",
  'Smartwater',
  'Snack Pack',
  "Stauffer's",
  "Sterzing's",
  'Taco Works',
  'Tortolines',
  'Towne Club',
  'True Lemon',
  'True Nopal',
  'Wtrmln Wtr',
  'Acqua Panna',
  "Andy Capp's",
  'Apple & Eve',
  'Aquahydrate',
  "Ballreich's",
  'Bard Valley',
  'Bare Snacks',
  'Barnard Nut',
  'Better Made',
  'Black Rifle',
  'Blue Monkey',
  'Bulletproof',
  'Cherry Coke',
  'Circus Time',
  'Clean Cause',
  'Clear Fruit',
  'Coffee Mate',
  'Crunchsters',
  'Daily Crave',
  'Dairy Fresh',
  'David Seeds',
  "Debbie-Jo's",
  'Don Chelada',
  'Double Cola',
  'Double-Cola',
  'Drink Maple',
  'Easy Cheese',
  'El Ranchero',
  'Enlightened',
  'Family Food',
  'Famous Amos',
  "Frito Lay's",
  'Fruit Shoot',
  'Glico Pocky',
  'Gourmet Nut',
  'Great Value',
  'Green River',
  'Guru Energy',
  'Hapi Drinks',
  'Honest Kids',
  'Immunityaid',
  "Jack Link's",
  'Jose Cuervo',
  'Juicy Juice',
  'Juicy Mixes',
  'Kill Cliffr',
  'La Estrella',
  'La Favorita',
  'Lesser Evil',
  'Link Snacks',
  'Liquid I.V.',
  'Little Hugs',
  'Lorna Doone',
  'Meadow Gold',
  'Mello Yello',
  'Minute Maid',
  "Miss Mary's",
  'Muscle Milk',
  "Musselman's",
  'Ocean Spray',
  'Office Snax',
  'Ohana Punch',
  'Old Orchard',
  'Old Trapper',
  "Papa's Pops",
  'Perky Jerky',
  'Pop Gourmet',
  'Primo Water',
  'Rancheritos',
  'Red Diamond',
  'Royal Dansk',
  'Schineiders',
  "Schneider's",
  'Sierra Mist',
  'Snack World',
  "Snackwell's",
  'Stella Doro',
  'Straight Up',
  'Suiza Fruit',
  'Sunny Fruit',
  'Terra Chips',
  'Tom Sturgis',
  'Tomoe Brand',
  'Tres Agaves',
  'Turkey Hill',
  'V8R Energyr',
  "Vegan Rob's",
  'Vitanourish',
  'Welch Foods',
  'Wheat Thins',
  'White House',
  "Wild Bill's",
  'Wilde Chips',
  'Winona Pure',
  'Black Forest',
  'Blue Diamond',
  'Borden Dairy',
  'Citrus World',
  'Cookie Other',
  'Country Time',
  'Cracker Jack',
  "Duke's Meats",
  'Enjoy Snacks',
  'G.H. Cretors',
  'Genius Juice',
  'Golden Flake',
  'Grand Brands',
  "Guy's Ridges",
  "Guy's Snacks",
  'Handi Snacks',
  'Hawaiian Sun',
  'Highland Pop',
  'Hiland Dairy',
  'Ice Mountain',
  'J.M. Smucker',
  'Kauai Kookie',
  'Kettle Brand',
  'Knouse Foods',
  'Kodiak Cakes',
  'Ktm Services',
  'Land O Lakes',
  'Liquid Death',
  'Magic Straws',
  "Martinelli's",
  'Middleswarth',
  'Mighty Spark',
  'Minago Foods',
  'Mountain Dew',
  'Nabisco Food',
  'Neuro Drinks',
  'No Mans Land',
  'Orchard Pure',
  'Peanut Patch',
  'Plant Snacks',
  'Pop & Bottle',
  'Pure Organic',
  'R.W. Knudsen',
  "Sam's Choice",
  'Simply Apple',
  'Simply Peach',
  'Slush Puppie',
  'Snack Hawaii',
  'So Delicious',
  'Sparkling Oh',
  'Super Coffee',
  'Superior Nut',
  'Talking Rain',
  'Tasty Baking',
  'United Dairy',
  'Vitaminwater',
  'Wege Pretzel',
  'Zephyr Hills',
  'Abita Brewing',
  'Aloha Gourmet',
  'Betty Crocker',
  'Buncha Crunch',
  'Cactus Cooler',
  'Califia Farms',
  'Cooking Light',
  'Crunch Master',
  'Crystal Light',
  "Dieffenbach's",
  'Dunkin Donuts',
  'Eternal Water',
  'Ferrara Candy',
  'Fruit Gushers',
  'Fruit Rollups',
  'Fuel For Fire',
  'Full Throttle',
  'General Mills',
  'Ginger People',
  'Glacier Water',
  'Good Good Eat',
  'Hampton Farms',
  'Harvest Snaps',
  'Hawaiian Chip',
  'Hawaiian Host',
  'Hiball Energy',
  'Humpty Dumpty',
  'Indian Summer',
  'Island Snacks',
  'Kettle Brandr',
  "Lefty O'Douls",
  'Manzanita Sol',
  'Medora Snacks',
  "Miss Vickie's",
  "Mrs. Fisher's",
  'Mug Root Beer',
  'National Food',
  'Nestle Waters',
  "Nick's Sticks",
  'Northern Neck',
  'Nutter Butter',
  'Old Wisconsin',
  'On The Border',
  'Onward Brands',
  'Pacific Foods',
  'Peeled Snacks',
  "Peet's Coffee",
  'Point Premium',
  'Poland Spring',
  'Pom Wonderful',
  'Prairie Farms',
  'Primo Hoagies',
  'Red Rock Deli',
  'Remedy Drinks',
  'Sahale Snacks',
  'Second Nature',
  'Snack Factory',
  'Snow Mountain',
  'So Good Brand',
  'Sos Hydration',
  'Sparkling Ice',
  'Sterzing Food',
  'Swiss Premium',
  'Taste Nirvana',
  'Teddy Grahams',
  'Twisted Punch',
  'Unfi Products',
  'Unique Snacks',
  'Upstate Farms',
  "Wenzel's Farm",
  'Absopure Water',
  'Alamance Foods',
  'Animal Cookies',
  'Clear American',
  'Coca-Cola Life',
  'Cocktail Crate',
  'Country Archer',
  'Creative Roots',
  'Crystal Geyser',
  'Cumberland Gap',
  'Cure Hydration',
  "Dewey's Bakery",
  'Diamond Bakery',
  'Ellis Infinity',
  'Excel Beverage',
  'Ferrero U.S.A.',
  'Galliker Dairy',
  'Hawaiian Punch',
  'I Won Organics',
  'Kitchen Cooked',
  'Made In Nature',
  'Margaritaville',
  'Marquart Farms',
  "Matt's Cookies",
  'Mattson U.S.A.',
  'Mizkan America',
  "Nature's Twist",
  "Nellie & Joe's",
  'Paradise Fruit',
  "Pirate's Booty",
  'Pretzel Crisps',
  'Reggae Imports',
  'Royal Hawaiian',
  'San Pellegrino',
  'Schweppes 1783',
  'Shamrock Farms',
  'Simply Limeade',
  'Simply Organic',
  "Snyder's Lance",
  'So Good So You',
  'Sparkling Icer',
  'Symphony Chips',
  'Tahitian Treat',
  'Vital Proteins',
  "Welch's Snacks",
  '3D Energy Drink',
  'Adrenaline Shoc',
  'Ballreich Bros.',
  'Better Cheddars',
  'Big Easy Blends',
  'Bolthouse Farms',
  'Boylan Bottling',
  'Brownie Brittle',
  'Carolina Kettle',
  "Cattleman's Cut",
  'Cocktail Artist',
  "Crunch 'n Munch",
  'Crunchy Rollers',
  'Crystal Springs',
  "Dad's Root Beer",
  'Faygo Beverages',
  'Garden Of Eatin',
  'Great Lake Wine',
  "Guy's Legendary",
  'Heyday Beverage',
  'Horizon Organic',
  'Interbake Foods',
  "Juanita's Foods",
  'Keebler - Cones',
  "Kernel Season's",
  'Kristian Regale',
  "Laura Scudder's",
  "Lenny & Larry's",
  'Little Villager',
  'Lone Star Candy',
  'Madrinas Coffee',
  'Margaret Holmes',
  'Mariani Packing',
  "Martin's Snacks",
  'Master Of Mixes',
  'Mountain Valley',
  'Nabisco Newtons',
  "Nature's Garden",
  'Old Dutch Foods',
  'Pepperidge Farm',
  'Popcorn Indiana',
  "Ralph's Packing",
  'Red Smith Foods',
  'Remedy Organics',
  'Rise Brewing Co',
  "Ritchey's Dairy",
  'Royal Borinquen',
  "Shearer's Foods",
  'Shoreline Fruit',
  'Sonoma Creamery',
  'Sour Patch Kids',
  'Star Snacks Co.',
  'Straight Up Tea',
  'The Quaker Oats',
  "Tony Chachere's",
  'Ucc Hawaii Kona',
  'Vitamin Hydrate',
  'Wal-Mart Stores',
  'Wyoming Ranches',
  'Asia Trans & Co.',
  "Barnum's Animals",
  "Brad's Raw Foods",
  'Bright And Early',
  'Calypso Lemonade',
  'Collins Brothers',
  'Community Coffee',
  'Cutwater Spirits',
  'Energy Beverages',
  "Fitz's Root Beer",
  'Flavor Originals',
  'Harmless Harvest',
  "Henry Weinhard's",
  'I.B.C. Root Beer',
  'Ideal Pure Water',
  'Kar Nut Products',
  'Kid Tested Tunes',
  'Lakewood Juicesr',
  'Legacy Bakehouse',
  'Limitless Coffee',
  "Mother's Cookies",
  'Natural Delights',
  'Overwatch League',
  'Pepperidge Farmr',
  'Powell & Mahoney',
  'Pretzel Kravings',
  'Simply Cranberry',
  'Snack It Forward',
  'Snyder Of Berlin',
  "Tate's Bake Shop",
  'This Saves Lives',
  'Tropical Fantasy',
  'Virginia Peanuts',
  'American Pop Corn',
  'Big John Products',
  'Blue Diamond Bold',
  'Brew Dr. Kombucha',
  'Brewhouse Legends',
  'Da Crack Seed Jar',
  "Daily's Cocktails",
  "Deebee's Organics",
  'Deep River Snacks',
  "Florida's Natural",
  'Fruit By The Foot',
  "Gt's Living Foods",
  'Hella Cocktail Co',
  "Hubert's Lemonade",
  'Icelandic Glacial',
  'Jones Potato Chip',
  'Kraft Heinz Foods',
  'Late Julyr Snacks',
  'Liberto Specialty',
  'Mountain Dew Diet',
  "Mrs. Richardson's",
  'Murray Sugar Free',
  'Nantucket Nectars',
  'PepsiCo CytoSport',
  'Pop Daddy Popcorn',
  'Produce Unbranded',
  'Rhythm Superfoods',
  'Rio Del Sol Farms',
  'Sensible Portions',
  'Simply Grapefruit',
  'Simply Watermelon',
  'Sunshine Biscuits',
  'Tampico Beverages',
  'Truco Enterprises',
  'True Fabrications',
  'Wild Cherry Pepsi',
  "Zimmerman's Dairy",
  'Alexandria Trading',
  'Aloha Maid Natural',
  'Anatex Enterprises',
  "Brad's Plant Based",
  "Bud's Best Cookies",
  'Chattanooga Bakery',
  'Dakota Style Chips',
  'Diamond Bakery Co.',
  'Foster Dairy Farms',
  'Happy Valley Farms',
  'Hawaiian Hurricane',
  'King Juice Of Wis.',
  'Kinnikinnick Foods',
  "Knott's Berry Farm",
  'Mountain Lightning',
  "My Brother's Salsa",
  'Nuts About Florida',
  'Off The Eaten Path',
  'Old Orchard Brands',
  'Pretzel Perfection',
  'Reine River Studio',
  "Reser's Fine Foods",
  'Rutter Bros. Dairy',
  'Santa Cruz Organic',
  'Siete Family Foods',
  'Walkers Shortbread',
  "Werther's Original",
  'Beyond Better Foods',
  'Caffeine Free Pepsi',
  'Chameleon Cold Brew',
  'Cherry Bay Orchards',
  'Chicken In A Biskit',
  'Collins Consumables',
  'Conagra Foodservice',
  'Deans Country Fresh',
  "Denny's Corporation",
  'Great Value Organic',
  'I Won Nutrition Co.',
  'Indian River Select',
  'Just Poppin Popcorn',
  'Kauai Kookies Kauai',
  "Kellogg's Scoobydoo",
  'Macs Old Plantation',
  'Maple Hill Creamery',
  'Millennium Products',
  'MoonShine Sweet Tea',
  "Owen's Table Mixers",
  'Prairie Farms Dairy',
  'Ready Protein Water',
  'Schwepps Diet Tonic',
  "Snyder's Of Hanover",
  'Southern Style Nuts',
  'Amplify Snack Brands',
  'Blue Diamond Growers',
  'Bon Apettit Sa De Cv',
  "Caroline's Treasures",
  'Chicago Custom Foods',
  'Freshness Guaranteed',
  "Gary's Old Fashioned",
  'Ito En North America',
  'Joseph Huber Brewing',
  'Keebler Chips Deluxe',
  "Kellogg's Town House",
  "Lieutenant Blender's",
  'Mount Franklin Foods',
  'Nor-Cal Beverage Co.',
  'Pinnacle Foods Group',
  'Plantain Products Co',
  'Pure Steeps Beverage',
  "Snyder's Of Hanoverr",
  'Stretch Island Fruit',
  'Sweetwood Smokehouse',
  'Tom Sturgis Pretzels',
  'Tropicana Essentials',
  'United States Bakery',
  'Vermont Smoke & Cure',
  'We Are The Chompians',
  "Angie's Boomchickapop",
  'Diamond Of California',
  'Genuine Georgia Group',
  'Ghirardelli Chocolate',
  'Harvest Hill Beverage',
  'Hawaiian Sun Products',
  'La Cocina De Josefina',
  'Licensed Fruit Snacks',
  'Nixie Sparkling Water',
  'Northland Cranberries',
  "Orville Redenbacher's",
  'T. J. Marquart & Sons',
  'Texas Citrus Exchange',
  'Vital Pharmaceuticals',
  'Woodbolt Distribution',
  'Cape Cod Cranberry Dry',
  "Conn's Potato Chip Co.",
  'Continental Concession',
  'Crystal Body Deodorant',
  'Diamond Foods Holdings',
  'Fair Oaks Farms Brands',
  'Georgia Peach Products',
  'Maruchan Instant Lunch',
  "My Nana's Best Tasting",
  'Next Phase Enterprises',
  'Northampton Peanut Co.',
  'Orchard Valley Harvest',
  'American Metal Products',
  'Andrew Robbins Holdings',
  'Carlsbad Alkaline Water',
  'Creative Roots Beverage',
  'Distributed Consumables',
  'Great Lakes Potato Chip',
  'John B Sanfilippo & Son',
  'Kellogg Snacks Division',
  "Kellogg's Rice Krispies",
  'Monogram Food Solutions',
  "Ole Salty's Of Rockford",
  'Royal Borinquen Biscuit',
  'Royal Hawaiian Orchards',
  'Suiza Dairy Corporation',
  'Virginia Heritage Foods',
  'Biscuiterie Poppies N.V.',
  'Catskill Mountain Coffee',
  'Coca-Cola -0049000000016',
  "Dot's Homestyle Pretzels",
  'Farm Fresh Food Supplier',
  'Great Lakes Potato Chips',
  'Pepperidge Farmr Veronar',
  'Tillamook Country Smoker',
  'Ventures Food & Beverage',
  'Waterloo Sparkling Water',
  'Ferolito Vultaggio & Sons',
  'Good Health Natural Foods',
  'Late Julyr Snacks Dippers',
  'Original New York Seltzer',
  'Vpx Vital Pharmaceuticals',
  'West Virginia Potato Chip',
  'Blue Diamond Almond Breeze',
  'Hawaiian Isles Kona Coffee',
  'Health & Nutrition Systems',
  'Pepperidge Farmr Chessmenr',
  'Pepperidge Farmr Farmhouse',
  'Pepperidge Farmr Goldfishr',
  'Rovira Biscuit Corporation',
  'Anderson Erickson Dairy Co.',
  'Bridgford Foods Corporation',
  'Comercializadora Eloro S.A.',
  'Hawaii Associated Wholesale',
  'Nestle Waters North America',
  'Old Orchard Healthy Balance',
  'Old Trapper Smoked Products',
  'Caffeine Free Diet Dr Pepper',
  "Campbell'Sr 100 Tomato Juice",
  'Dominguez Family Enterprises',
  'King Car Food Industrial Co.',
  'La Colombe Draft Latte Mocha',
  'Late Julyr Snacks Multigrain',
  'Monogram Snacks Martinsville',
  'V8R Fruit & Vegetable Blends',
  'Carolina Beverage Corporation',
  'Pennsylvania Dutch Birch Beer',
  'Promotion In Motion Companies',
  'Sunmaid Growers Of California',
  'Fabrica De Galletas La Moderna',
  'Pepperidge Farmr Goldfishr Mix',
  'Saratoga Springs Mineral Water',
  'Wonderful Pistachios & Almonds',
  "Ktm It's Time To Enjoy Life Enjoy",
  'Performance Enhancing Meat Snacks',
  'Quaker Oats Co. Gatorade-Van Camp',
  'Nestle Waters North America Perrier',
  'Nestle Waters North America Ice Mtn.',
  'Pepperidge Farmr Goldfishr Flavor Blastedr',
  "Saratoga Chips America's First Kettle Chip",
  'Gullon Mega Dueto Chocolate Cream Sandwich Cookie',
];
