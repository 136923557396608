import type { ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';
import appStyles from '../../layout/App.module.scss';
import styles from './SubSectionChartLegend.module.scss';
import chartStyles from './SubSectionChart.module.scss';
import { Tooltip } from '../Tooltip';

// 3 items make the lagend a 3 column layout. less than 2 should be spread evenly
const hasMoreThanTwoItems = (items: unknown[]) => items.length > 2;

type Props = {
  data: { color: string; id: string; hasGradient: boolean }[];
  onClick: (id: string) => void;
  onHover: (id: string, state: boolean) => void;
  activatedIds: Set<string>;
  align: 'left' | 'bottom';
  titleGetter: (id: string, placement: 'tooltip' | 'title') => ReactNode;
  tooltipEnabled?: boolean;
};

export function SubSectionChartLegend(props: Props) {
  const { data, onClick, onHover, activatedIds, align, titleGetter, tooltipEnabled } = props;

  return (
    <div
      className={classNames(
        appStyles.horizontalFlex,
        !hasMoreThanTwoItems(data) && appStyles.justifySpaceBetween,
        styles.container,
        align === 'left' && styles.alignLeft
      )}
    >
      {data.map(({ color, id, hasGradient }, i) => {
        const title = titleGetter(id, 'title');
        const tooltipTitle = titleGetter(id, 'tooltip');
        const legend = (
          <label
            key={i}
            onClick={() => onClick(id)}
            onMouseEnter={() => onHover(id, true)}
            onMouseLeave={() => onHover(id, false)}
            className={classNames(
              appStyles.horizontalFlex,
              appStyles.alignCenter,
              appStyles.gap1,
              styles.item,
              data.length > 1 && styles.clickable,
              !activatedIds.has(id) && styles.disabled,
              hasMoreThanTwoItems(data) && styles.threeColumns
            )}
            style={{ color }}
          >
            <span className={classNames(hasGradient && chartStyles.hasGradient, styles.bullet)} />
            <span
              className={classNames(appStyles.horizontalFlex, appStyles.alignCenter, styles.itemId)}
            >
              {title}
            </span>
          </label>
        );

        return tooltipEnabled && typeof title === 'string' ? (
          <Tooltip key={i} content={tooltipTitle}>
            {legend}
          </Tooltip>
        ) : (
          legend
        );
      })}
    </div>
  );
}
