import { iterator, map, number, set as setUtil, type SortDirection } from '@harmonya/utils';
import { useCallback } from 'react';
import {
  atom,
  DefaultValue,
  selector,
  selectorFamily,
  useRecoilState,
  useResetRecoilState,
  type RecoilState,
  type RecoilValueReadOnly,
} from 'recoil';
import { attributionDataState, columnOptionsState } from './attributionState';
import type {
  ColumnId,
  Filter,
  ProductAttributeContent,
  ProductAttributes,
  SortOption,
  SortSettings,
} from 'store/attribution.types';

const getFilterMap = <T extends string>(entries: Record<T, Filter>) =>
  new Map(Object.entries(entries)) as Map<T, Filter>;

export const sortOptions = ['Confidence Level', 'A To Z'] as const;

const confidenceLevelToNumber = {
  none: 0,
  low: 1,
  medium: 2,
  high: 3,
} as const;

const sorters: Record<
  SortOption,
  (a: ProductAttributeContent, b: ProductAttributeContent) => number
> = {
  'Confidence Level': (a, b) =>
    confidenceLevelToNumber[a.confidenceLevel ?? 'none'] -
    confidenceLevelToNumber[b.confidenceLevel ?? 'none'],
  'A To Z': (a, b) => a.value?.localeCompare(b.value ?? '') ?? 0,
  'Sales': (a, b) =>
    (a.value ? number.currencyToFloat(a.value) : 0) -
    (b.value ? number.currencyToFloat(b.value) : 0),
};

const getSorter = (option: SortOption, columnId: ColumnId, direction: SortDirection) => {
  const sorter = sorters[option];
  const columnBasedSorter = (a: ProductAttributes, b: ProductAttributes) =>
    sorter(a[columnId], b[columnId]);
  const directionBasedSorter: typeof columnBasedSorter =
    direction === 'asc' ? columnBasedSorter : (a, b) => -columnBasedSorter(a, b);

  return directionBasedSorter;
};

export const defaultSortSettings: SortSettings = {
  columnId: 0,
  direction: 'asc',
  option: 'Confidence Level',
};

export const sortState = atom<SortSettings>({
  key: 'attributionSort',
  default: defaultSortSettings,
});

export const cellStatusFilters = getFilterMap({
  'Predicted': ({ isPredicted }) => isPredicted,
  'Not Predicted': ({ isPredicted }) => !isPredicted,
  'Provided': ({ isProvided }) => isProvided,
});

export const cellValidationFilters = getFilterMap({
  'Validated': ({ isValidated, isEdited }) => isValidated || isEdited,
  'Not Validated': ({ isValidated, isEdited }) => !isValidated && !isEdited,
});

export const cellConfidenceLevelFilters = getFilterMap({
  low: ({ confidenceLevel }) => confidenceLevel === 'low',
  medium: ({ confidenceLevel }) => confidenceLevel === 'medium',
  high: ({ confidenceLevel }) => confidenceLevel === 'high',
});

export const columnOptionFiltersState = selectorFamily({
  key: 'columnOptionFilters',
  get:
    (columnId: ColumnId) =>
    ({ get }) => {
      const columnOptions = get(columnOptionsState(columnId));
      const columnOptionFilters = new Map<string, Filter>(
        Array.from(columnOptions, option => [option, ({ value }) => value === option])
      );

      return columnOptionFilters;
    },
});

const getSetFilterState = <T>(key: string) => {
  const columnsFilterState = atom({
    key: `attributionColumnTo${key}Filter`,
    default: new Map<ColumnId, Set<T>>(),
  });

  const filterState = selectorFamily<Set<T>, ColumnId>({
    key: `attribution${key}Filter`,
    get:
      columnId =>
      ({ get }) => {
        const cellStatusFilter = get(columnsFilterState);
        const columnCellStatusFilter = cellStatusFilter.get(columnId) ?? new Set();

        return columnCellStatusFilter;
      },
    set:
      columnId =>
      ({ set, get }, newValue) => {
        const cellStatusFilter = get(columnsFilterState);
        const newCellStatusFilter = new Map(cellStatusFilter);

        if (newValue instanceof DefaultValue) {
          newCellStatusFilter.delete(columnId);
        } else {
          newCellStatusFilter.set(columnId, newValue);
        }

        set(columnsFilterState, newCellStatusFilter);
      },
  });

  return [filterState, columnsFilterState] as const;
};

export const [cellStatusFilterState, columnsCellStatusFilterState] =
  getSetFilterState<map.Key<typeof cellStatusFilters>>('CellStatus');

export const [cellValidationFilterState, columnsCellValidationFilterState] =
  getSetFilterState<map.Key<typeof cellValidationFilters>>('CellValidation');

export const [cellConfidenceLevelFilterState, columnsCellConfidenceLevelFilterState] =
  getSetFilterState<map.Key<typeof cellConfidenceLevelFilters>>('ConfidenceLevel');

export const [cellOptionsFilterState, columnsCellOptionsFilterState] =
  getSetFilterState<string>('Options');

export const useColumnFilter = <T>(
  columnId: ColumnId,
  state: (param: ColumnId) => RecoilState<Set<T>>
) => {
  const [values, setValues] = useRecoilState(state(columnId));
  const reset = useResetRecoilState(state(columnId));

  const toggleValue = useCallback(
    (value: T) => {
      setValues(prev => setUtil.toggleSet(prev, value));
    },
    [setValues]
  );

  return [values, toggleValue, reset, setValues] as const;
};

type ItemToFilter = Map<string, Filter>;
type FilterStateMapperOptions =
  | ItemToFilter
  | ((columnId: ColumnId) => RecoilValueReadOnly<ItemToFilter>);

type FilterStateMapper = [FilterStateMapperOptions, (param: number) => RecoilState<Set<string>>][];

const setFiltersToStates = new Map([
  [cellStatusFilters, cellStatusFilterState],
  [cellValidationFilters, cellValidationFilterState],
  [cellConfidenceLevelFilters, cellConfidenceLevelFilterState],
  [columnOptionFiltersState, cellOptionsFilterState],
] as FilterStateMapper);

const filteredProductsAttributesState = selector({
  key: 'filteredProductsAttributes',
  get: ({ get }) => {
    const { attributesMetadata, productsAttributes } = get(attributionDataState);
    const activeFilters = iterator.definedMap(attributesMetadata, ({ key }) => {
      for (const [mapper, filterState] of setFiltersToStates) {
        const computedMapper = mapper instanceof Map ? mapper : get(mapper(key));
        const columnFilterState = filterState(key);
        const selectedValues = get(columnFilterState);

        if (selectedValues.size) {
          return (productAttributes: ProductAttributes) =>
            iterator.some(selectedValues, value => {
              const filter = computedMapper.get(value);

              return !!filter?.(productAttributes[key]);
            });
        }
      }
    });
    const filteredProductsAttributes = new Map(
      iterator.filter(productsAttributes, ([_, productAttributes]) =>
        activeFilters.every(filter => filter(productAttributes))
      )
    );

    return filteredProductsAttributes;
  },
});

export const sortedFilteredAttributionDataState = selector({
  key: 'filteredAttributionData',
  get: ({ get }) => {
    const filteredProductsAttributes = get(filteredProductsAttributesState);
    const { columnId, direction, option } = get(sortState);
    const sorter = getSorter(option, columnId, direction);
    const sortedFilteredProductsAttributes = map.sort(filteredProductsAttributes, sorter);

    return sortedFilteredProductsAttributes;
  },
});

export const sortedFilteredAttributionItemIdsState = selector({
  key: 'sortedFilteredAttributionItemIds',
  get: ({ get }) => {
    const sortedFilteredProductsAttributes = get(sortedFilteredAttributionDataState);
    const sortedFilteredAttributionItemIds = [...sortedFilteredProductsAttributes.keys()];

    return sortedFilteredAttributionItemIds;
  },
});

export const activatedFiltersState = selectorFamily<boolean, ColumnId>({
  key: 'activatedFilters',
  get:
    columnId =>
    ({ get }) => {
      const hasActivatedFiltersForThisColumn = iterator.some(
        setFiltersToStates.values(),
        filterState => {
          const filters = get(filterState(columnId));

          return filters.size > 0;
        }
      );
      const hasActivatedSortForThisColumn = get(sortState).columnId === columnId;

      return hasActivatedFiltersForThisColumn || hasActivatedSortForThisColumn;
    },
});
