import { useRecoilValue } from 'recoil';
import { getFilters } from 'store/comparisonPage';
import type { Stringable } from '@harmonya/utils';
import { iterator } from '@harmonya/utils';
import { setsToQuery } from '../functions';

const parseIds = (ids: { [key: string]: Stringable[] }) => {
  const entries = Object.entries(ids);
  const mappedEntries = iterator.definedMap(entries, ([key, values]) => {
    if (values?.length) {
      return values.map(value => [key, value.toString()]);
    }
  });
  return mappedEntries.flat();
};

const getURLSearchParams = (parsedFilters: string[][]) => new URLSearchParams(parsedFilters);

export const useTagLinks = (itemIds: number[]) => {
  const filters = getFilters(useRecoilValue);
  /** @todo Enforce URL params names */
  const selectedFilters = {
    brandings: itemIds,
    categories: filters.categoryIds,
    markets: filters.marketIds,
    periods: filters.periodIds,
  };

  const getExploreUrl = (tagId: number) => {
    const parsedSelectedFilters = parseIds(selectedFilters);

    parsedSelectedFilters.push(['query', setsToQuery([new Set([tagId])])]);

    const searchParams = getURLSearchParams(parsedSelectedFilters as string[][]);

    return `/explore?${searchParams}`;
  };

  const getTrendUrl = (tagId: number) => {
    const parsedSelectedFilters = parseIds({ ...selectedFilters, tags: [tagId] });
    const searchParams = getURLSearchParams(parsedSelectedFilters as string[][]);

    return `/trends?${searchParams}`;
  };

  return { getExploreUrl, getTrendUrl };
};
