import styles from '../style/variablesExport.module.scss';
import appStyles from '../components/layout/App.module.scss';
import { getParsers, parseString } from './styleVariables.utils';
import { number, object } from '@harmonya/utils';
import { env } from '../../env';

type OriginalStyleVariables = {
  [Key in keyof typeof styleVariables]: (typeof styleVariables)[Key] extends unknown[]
    ? string[]
    : string;
};

const { baseFontSize, ...computedStyleVariables } = styles;
const styleVariables = {
  borderRadius: NaN as number,
  gap: NaN as number,
  halfPadding: NaN as number,
  padding: NaN as number,
  tagColors: [] as string[],
  lineChartColorScheme: [] as string[],
  gradientColorScheme: [] as string[],
  boxShadow: '' as string,
  inputRangeThumbSize: NaN as number,
  inputRangeTickMarkSize: NaN as number,
  textFontFamiliy: '' as string,
  titleFontFamiliy: '' as string,
  selectOptionHeight: NaN as number,
  trendHeight: NaN as number,
  expandedTrendHeight: NaN as number,
  selectDropdownMaxHeight: NaN as number,
  selectDropdownMaxWidth: NaN as number,
  animationDuration: NaN as number,
  defaultThemeBackgroundColor: '' as string,
  defaultThemeForegroundColor: '' as string,
  defaultThemeMainColorDark: '' as string,
  defaultThemeMainColorLight: '' as string,
  defaultThemeMainColorRegular: '' as string,
  defaultThemeSecondaryColorDark: '' as string,
  defaultThemeSecondaryColorLight: '' as string,
  defaultThemeSecondaryColorRegular: '' as string,
  successColor: '' as string,
  warningColor: '' as string,
  errorColor: '' as string,
  infoColor: '' as string,
  mapColors: [] as string[],
  iconTag: '' as string,
  iconCategory: '' as string,
  iconMarket: '' as string,
  iconProduct: '' as string,
  iconBrand: '' as string,
  iconTagType: '' as string,
  trendsLeftPanelWidth: NaN as number,
  dataTableRowHeight: NaN as number,
  dataTableCellWidth: NaN as number,
};
const originalStyleVariables = {} as OriginalStyleVariables;
const parsers = getParsers(baseFontSize);

object.keys(computedStyleVariables).forEach(key => {
  const rawVariable = computedStyleVariables[key];
  const { parsed, original } = parseString(rawVariable, parsers);

  if (key in styleVariables) {
    Object.assign(styleVariables, { [key]: parsed });
    Object.assign(originalStyleVariables, { [key]: original });
  } else if (env.isDev) {
    throw new Error(`Variable "${key}" is not defined in styleVariables`);
  }
});

export const getColorClassById = (colorId: number | undefined) =>
  colorId == null ? null : appStyles[`color${colorId}` as keyof typeof appStyles];

export const withOpacity = (color: string, opacity: number) => {
  const alphaHex = number.minmax(Math.floor(256 * opacity), 0, 256).toString(16);
  const computedColor = color + alphaHex;

  return computedColor;
};

export { styleVariables, originalStyleVariables };
