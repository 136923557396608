import type { CSSProperties, ReactNode } from 'react';
import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';
import appStyles from '../../layout/App.module.scss';
import styles from './Select.module.scss';
import { SelectButton } from './SelectButton';
import { SelectDropdown } from './SelectDropdown';
import type { DropdownRef } from '../dropdown/Dropdown';
import { Dropdown } from '../dropdown/Dropdown';
import type { Align } from '../dropdown/DropdownWindow';
import { useRecoilValue } from 'recoil';
import { analyticsState } from 'store/analytics';
import type { SelectEventProps, ToggleEventProps } from 'utils/analyticsSelect';
import {
  getSelectEvent as getSelectEventProps,
  getToggleEvent as getToggleEventProps,
} from 'utils/analyticsSelect';
import { getChildren, TreeOption } from 'utils/node';
import { Path } from '../Path';
import type { Highlightable } from 'utils/sortedFilteredOptions';
import { string, iterator, array, set, map } from '@harmonya/utils';
import { HighlightTexts } from '../HighlightTexts';
import type { DropDownDirection } from 'components/general/charts/types';
import type {
  ButtonContentGetterProps,
  ButtonContentProps,
  IsDisabled,
  NormalizedOptions,
  Option,
  RawOptionType,
  ReadOnlyNormalizedCollection,
  Options,
} from 'components/general/select/types';

function getIsSelectedFunction<T extends RawOptionType>(value: T | T[]) {
  return Array.isArray(value)
    ? (option: Option<T>) => {
        const values = value as T[];

        return values.includes(option.value as T);
      }
    : (option: Option<T>) => value === option.value;
}

const isGroups = <T extends RawOptionType>(
  options: Iterable<T | Option<T>> | Map<string, Iterable<T | Option<T>>>
): options is Map<string, Iterable<T | Option<T>>> => {
  if (options instanceof Map) {
    const firstValue = map.getFirstMap(options);

    return firstValue != null && iterator.isIterable(firstValue);
  }

  return false;
};

const getNormalizedOptions = <T extends RawOptionType>(
  options: Iterable<T | Option<T>> | Map<string, Iterable<T | Option<T>>>
): NormalizedOptions<T> =>
  isGroups(options)
    ? Array.from(options, ([key, groupOptions]) => [key, getNormalizedOptions(groupOptions)[0][1]])
    : [
        [
          '',
          Array.from(options, option => {
            let normalizedValue: Option<T>;

            // Options is Map<string, RawOptionType>
            if (Array.isArray(option)) {
              const [value, displayValue] = option;

              normalizedValue = { value, displayValue };
              // Options is Option[]
            } else if (option?.constructor === Object) {
              normalizedValue = option as unknown as Option<T>;
              // Options is RawOptionType[] / Set<RawOptionType>
            } else {
              normalizedValue = { value: option as T };
            }

            if (normalizedValue.displayValue instanceof TreeOption) {
              const { name, level, parentId, childrenIds, id, compactPathParts, fullPathParts } =
                normalizedValue.displayValue;
              const children = childrenIds?.map(childid => ({ value: childid as T })) ?? [];

              normalizedValue.displayValue = name;
              normalizedValue.value = id as T;
              normalizedValue.node = {
                level,
                children,
                compactPathParts,
                fullPathParts,
                ...(parentId != null && { parent: { value: parentId as T } }),
              };
            } else {
              normalizedValue.displayValue ??= normalizedValue.value?.toString();
            }

            return { ...normalizedValue } as Option<T>;
          }),
        ],
      ];

function setTreeRelationsToNormalizedOptions<T extends RawOptionType>(
  normalizedOptions: NormalizedOptions<T>
) {
  for (const [, options] of normalizedOptions) {
    const optionsAsMap = new Map(options.map(option => [option.value, option]));

    for (const { node } of options) {
      if (node) {
        if (node.parent) {
          const parentId = node.parent.value;

          node.parent = optionsAsMap.get(parentId);
        }

        node.children = iterator.definedMap(node.children, child =>
          optionsAsMap.get(child.value)
        ) as Option<T>[];
      }
    }
  }
}

const getFilteredNormalizedOptions = <T extends RawOptionType>(
  normalizedOptions: NormalizedOptions<T>,
  isDisabledOption: IsDisabled<T>
): NormalizedOptions<T> =>
  normalizedOptions.map(([key, groupOptions]) => {
    const optionsWithDisabled = groupOptions.map(option => ({
      ...option,
      disabled: isDisabledOption(option),
    }));

    return [key, optionsWithDisabled];
  });

const getPostSearchOptions = <T extends RawOptionType>(
  allOptions: NormalizedOptions<T>,
  rawPostSearchOptionGroups: NormalizedOptions<T>
) => {
  const resultNodeValues = new Set<T>();
  const disabledNodeValues = new Set<T>();
  const newExpandedIds = new Set<T>();
  const expandedParents = new Set<Option<T>>();

  for (const [_, rawPostSearchOptions] of rawPostSearchOptionGroups) {
    const exitingOptionsValuesSet = new Set(
      rawPostSearchOptions.map(exitingOption => exitingOption.value)
    );
    const sortedPostSearchOptions = [...rawPostSearchOptions].sort(
      (a, b) => (a.node?.level ?? 0) - (b.node?.level ?? 0)
    );

    const expandParent = (option: Option<T>) => {
      /** @todo Solve smarter instead of the memoization below */
      const parent = option.node?.parent;

      if (parent && !expandedParents.has(parent)) {
        expandedParents.add(parent);
        set.addMany(newExpandedIds, parent?.node?.children, child => child.value);
      }
    };

    const setDisabledParents = (option: Option<T>) => {
      let parent = option.node?.parent;

      while (parent && !exitingOptionsValuesSet.has(parent.value)) {
        disabledNodeValues.add(parent.value);
        expandParent(parent);
        parent = parent.node?.parent;
      }
    };

    const setResultChildren = (option: Option<T>) => {
      for (const child of getChildren(option, item => item.node?.children ?? [])) {
        if (!exitingOptionsValuesSet.has(child.value)) {
          resultNodeValues.add(child.value);
        }
      }
    };

    for (const rawPostSearchOption of sortedPostSearchOptions) {
      setDisabledParents(rawPostSearchOption);
      resultNodeValues.add(rawPostSearchOption.value);
      expandParent(rawPostSearchOption);
      setResultChildren(rawPostSearchOption);
    }
  }

  const postSearchOptionGroups = allOptions.map(([key, options]) => [
    key,
    iterator.definedMap(options, option => {
      const isByResult = resultNodeValues.has(option.value);
      const isByDisabled = disabledNodeValues.has(option.value);

      if (isByResult || isByDisabled) {
        return {
          ...option,
          ...(isByDisabled && !isByResult && { disabled: true }),
        };
      }
    }),
  ]) as typeof allOptions;

  return { postSearchOptionGroups, newExpandedIds };
};

export function getMinWidthByLongestOption<T>(
  options: ReadOnlyNormalizedCollection<T>,
  displayValueGetter: (item: T) => unknown,
  isMultiselect?: boolean,
  title?: string,
  charEstimatedWidth = 6
) {
  const labelWidthWithoutText = isMultiselect
    ? 64
    : 0; /** @todo: Calc dynamically by code, not hard coded */
  const titleWidth = (title?.length ?? 0) * charEstimatedWidth;
  const longestOptionCharsCount = options.reduce(
    (max, [key, groupOptions]) =>
      groupOptions.reduce((currentMax, item) => {
        const displayValue = displayValueGetter(item);
        const displayValueLength = string.isString(displayValue) ? displayValue.length : 0;

        return Math.max(displayValueLength, currentMax, key.length);
      }, max),
    0
  );
  const longestOptionWidth = longestOptionCharsCount * charEstimatedWidth;
  const width = labelWidthWithoutText + titleWidth + longestOptionWidth;

  return width;
}

function defaultSelectedDisplayValueGetter<T extends RawOptionType, V extends T | T[]>(
  displayValueGetter: NonNullable<Props<T, V>['displayValueGetter']>
) {
  const defaultFunction = (option: Option<T>) => {
    if (option.node) {
      const { fullPathParts, compactPathParts } = option.node;

      return fullPathParts?.length ? (
        <Path fullPathParts={fullPathParts} compactPathParts={compactPathParts ?? []} />
      ) : (
        displayValueGetter(option)
      );
    }

    return displayValueGetter(option);
  };

  return defaultFunction;
}

function defaultDisplayValueGetter<T extends RawOptionType>(
  option: Option<T>,
  highlights?: Highlightable[]
) {
  return (
    <HighlightTexts
      items={highlights}
      defaultText={option.displayValue ?? option.value?.toString()}
    >
      {elements => {
        const id = option.node?.fullPathParts?.join();

        return <span id={id}>{elements}</span>;
      }}
    </HighlightTexts>
  );
}

type Props<T extends RawOptionType, V extends T | T[]> = {
  onChange: (value: V) => void;
  value: V;
  defaultValue?: V;
  options: Options<T> | Map<string, Options<T>>;
  bordered?: boolean;
  isMultiselect?: boolean;
  title?: string;
  className?: string;
  buttonClassName?: string;
  style?: CSSProperties;
  searchEnabled?: boolean;
  maxWidth?: string | number;
  minWidth?: string | number;
  minWidthEnabled?: boolean;
  direction?: DropDownDirection;
  align?: Align;
  notFoundText?: string;
  selectedOptionsTitle?: string;
  displayValueGetter?: (item: Option<T>, highlights?: Highlightable[]) => ReactNode;
  selectedOptionDisplayValueGetter?: (item: Option<T>) => ReactNode;
  isDisabledOption?: IsDisabled<T>;
  buttonTooltip?: ReactNode;
  sections?: ReactNode[];
  optionClassNameGetter?: (item: Option<T>) => string;
  displaySelectedValueGetter?: (item: Option<T>) => ReactNode;
  offset?: number;
  analyticsSelectEventProps?: SelectEventProps;
  analyticsToggleEventProps?: ToggleEventProps;
} & (ButtonContentProps | ButtonContentGetterProps<T>);

export function Select<T extends RawOptionType, V extends T | T[]>(props: Props<T, V>) {
  const {
    onChange,
    value,
    defaultValue,
    options,
    bordered,
    isMultiselect,
    title,
    className,
    buttonClassName,
    style,
    searchEnabled,
    maxWidth,
    minWidth,
    minWidthEnabled = true,
    direction,
    align,
    notFoundText,
    selectedOptionsTitle,
    displayValueGetter = defaultDisplayValueGetter,
    selectedOptionDisplayValueGetter = defaultSelectedDisplayValueGetter(displayValueGetter),
    isDisabledOption,
    buttonTooltip,
    sections,
    optionClassNameGetter,
    displaySelectedValueGetter,
    offset,
    analyticsSelectEventProps = getSelectEventProps({ name: title, type: 'select' }),
    analyticsToggleEventProps = getToggleEventProps({ name: title, type: 'select' }),
  } = props;
  const dropdownRef = useRef<DropdownRef>({});
  const buttonRef = useRef<HTMLDivElement>(null);
  const isSelected = getIsSelectedFunction(value);
  const rawNormalizedOptions = getNormalizedOptions(options);

  setTreeRelationsToNormalizedOptions(rawNormalizedOptions);

  const normalizedOptions = isDisabledOption
    ? getFilteredNormalizedOptions(rawNormalizedOptions, isDisabledOption)
    : rawNormalizedOptions;
  const selectedOptions = normalizedOptions.flatMap(([, groupOptions]) =>
    groupOptions.filter(isSelected)
  );
  const isHierarchial = normalizedOptions.some(([, groupOptions]) =>
    groupOptions.some(option => !!option.node)
  );
  const optionsMinWidth = minWidthEnabled
    ? minWidth ??
      getMinWidthByLongestOption(
        normalizedOptions,
        option => option.stringValue ?? option.displayValue,
        isMultiselect,
        title
      )
    : undefined;
  const normalizedOptionsAsMap = new Map(normalizedOptions);
  const borderedClassName = bordered
    ? classNames(appStyles.border, appStyles.backgroundBackground)
    : undefined;
  const analytics = useRecoilValue(analyticsState);

  const reset = () => {
    if (defaultValue != null) {
      onChange(defaultValue);
    }
  };

  const getSelectedDisplayValue = (selectValue: V) => {
    const selectValues = [...normalizedOptionsAsMap.values()].flat();
    const result: string[] = [];
    const selectValuesSet = new Set(array.ensure(selectValue));

    for (const normalizedOption of selectValues) {
      if (selectValuesSet.has(normalizedOption.value)) {
        const optionValue =
          normalizedOption.displayValue || normalizedOption.value || normalizedOption.searchValue;

        if (optionValue) {
          const displayValue = optionValue.toString();

          result.push(displayValue);
        }
      }
    }

    return result;
  };

  const trackedOnChange = (newValue: V) => {
    if (title) {
      const selectedValue = getSelectedDisplayValue(newValue);
      const selectedValues = isMultiselect ? selectedValue : selectedValue[0];
      const { title: eventTitle, props: eventProps } = analyticsSelectEventProps;

      if (eventTitle) {
        analytics.track(eventTitle, { ...eventProps, value: selectedValues });
      }
    }

    onChange(newValue);
  };

  const computedOnChange = isMultiselect
    ? trackedOnChange
    : (newValue: V) => {
        dropdownRef.current.close?.();
        trackedOnChange(newValue);
      };

  const onToggle = useCallback(() => {
    const { title: toggleEventTitle, props: toggleEventProps } = analyticsToggleEventProps;

    if (toggleEventTitle) {
      analytics.track(toggleEventTitle, toggleEventProps);
    }
  }, []);
  const buttonContent =
    'buttonContentGetter' in props ? (
      props.buttonContentGetter(selectedOptions, reset)
    ) : (
      <SelectButton
        className={classNames(
          appStyles.horizontalFlex,
          appStyles.justifySpaceBetween,
          appStyles.gap1,
          appStyles.button,
          appStyles.positionRelative,
          styles.button,
          borderedClassName,
          buttonClassName
        )}
        onReset={props.resetButtonEnabled ? reset : undefined}
        selectedOptions={selectedOptions}
        title={title}
        placeholder={props.placeholder}
        displayValueGetter={
          displaySelectedValueGetter ?? (option => displayValueGetter(option, []))
        }
        isMultiselect={isMultiselect}
        containerElement={buttonRef.current}
      />
    );

  return (
    <Dropdown
      dropdownRef={dropdownRef}
      className={classNames(appStyles.fullBasis, className)}
      style={style}
      maxWidth={maxWidth}
      minWidth={optionsMinWidth}
      direction={direction}
      align={align}
      buttonTooltip={buttonTooltip}
      buttonRef={buttonRef}
      buttonContent={buttonContent}
      offset={offset}
      onToggle={onToggle}
    >
      <SelectDropdown
        value={value}
        onChange={computedOnChange}
        options={normalizedOptionsAsMap}
        searchEnabled={searchEnabled}
        formatSearchResults={
          isHierarchial
            ? searchOptions =>
                getPostSearchOptions(normalizedOptions, searchOptions as NormalizedOptions<T>)
            : undefined
        }
        isMultiselect={isMultiselect}
        isSelected={isSelected}
        selectedOptions={selectedOptions}
        notFoundText={notFoundText}
        selectedOptionsTitle={selectedOptionsTitle}
        displayValueGetter={displayValueGetter}
        selectedOptionDisplayValueGetter={selectedOptionDisplayValueGetter}
        optionClassNameGetter={optionClassNameGetter}
        sections={sections}
        className={borderedClassName}
      />
    </Dropdown>
  );
}
