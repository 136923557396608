import ReactDOM from 'react-dom';

const getPortalContainer = (id: string) => {
  let element = document.getElementById(id);

  if (!element) {
    element = document.createElement('div');
    element.id = id;

    document.body.append(element);
  }

  return element;
};

export const createPortal = (id: string, children: React.ReactNode) => {
  const portalContainer = getPortalContainer(id);
  const portal = ReactDOM.createPortal(children, portalContainer);

  return portal;
};
