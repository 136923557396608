import React, { Fragment } from 'react';
import { join } from 'components/general/Divider';
import { Icon } from 'components/general/Icon';
import { Tooltip } from 'components/general/Tooltip';
import styles from './AttributionAnalyticsPanelTotals.module.scss';
import analyticsPanelStyles from './AttributionAnalyticsPanel.module.scss';
import appStyles from '../../App.module.scss';
import type { AnalyticsTotal } from 'store/attribution';
import { number } from '@harmonya/utils';

type Props = {
  totals: AnalyticsTotal[];
};

export function AttributionAnalyticsPanelTotals(props: Props) {
  const { totals } = props;

  return (
    <div className={styles.container}>
      {join(
        totals.map(({ title, value, tooltip }) => (
          <Fragment key={title}>
            <span className={analyticsPanelStyles.panelTitle}>
              <h3>{title}</h3>
              {tooltip && (
                <Tooltip content={tooltip}>
                  <Icon name='circle-info' weight='light' className={appStyles.mediumFont} />
                </Tooltip>
              )}
            </span>
            <h1>{number.format(value)}</h1>
          </Fragment>
        ))
      )}
    </div>
  );
}
