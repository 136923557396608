import { historyAtom } from '../utils/historyAtom';
import type { TableProduct } from './product.types';
import {
  explorePageCategoryIdsDefaultState,
  explorePageMarketIdsDefaultState,
  explorePagePeriodIdsDefaultState,
  explorePageBrandingIdsDefaultState,
  explorePageProductsTableSearchTextDefaultState,
  explorePageProductsTableSortDefaultState,
  explorePageQueryDefaultState,
  explorePageCategoryLevelDefaultState,
  explorePageBrandingLevelDefaultState,
} from './settings';
import type { GetRecoilValue, RecoilState } from 'recoil';
import type { SortDirection } from '@harmonya/utils';
import { object } from '@harmonya/utils';
import type { ExplorePageSalesFilters, GrowthPresetName, SourcePresetName } from '@harmonya/models';

export const explorePageQueryState = historyAtom('query', 'explore', {
  key: 'explorePageQuery',
  default: explorePageQueryDefaultState,
});

export const explorePageCategoryIdsState = historyAtom<number[]>(
  'categories',
  'explore',
  { key: 'explorePageCategoryIds', default: explorePageCategoryIdsDefaultState },
  { isArray: true, parser: 'number' }
);

export const explorePageMarketIdsState = historyAtom<number[]>(
  'markets',
  'explore',
  { key: 'explorePageMarketIds', default: explorePageMarketIdsDefaultState },
  { isArray: true, parser: 'number' }
);

export const explorePagePeriodIdsState = historyAtom<number[]>(
  'periods',
  'explore',
  { key: 'explorePagePeriodIds', default: explorePagePeriodIdsDefaultState },
  { isArray: true, parser: 'number' }
);

export const explorePageBrandingIdsState = historyAtom<number[]>(
  'brandings',
  'explore',
  { key: 'explorePageBrandingIds', default: explorePageBrandingIdsDefaultState },
  { isArray: true, parser: 'number' }
);

export const explorePageProductsTableSearchTextState = historyAtom<string>(
  'searchText',
  'explore',
  {
    key: 'explorePageProductsTableSearchText',
    default: explorePageProductsTableSearchTextDefaultState,
  }
);

export const explorePageProductsTableSortState = historyAtom<{
  field: keyof TableProduct;
  direction: SortDirection;
}>('sort', 'explore', {
  key: 'explorePageProductsTableSort',
  default: explorePageProductsTableSortDefaultState,
});

export const explorePageCategoryLevelState = historyAtom<number>(
  'categoryLevel',
  'explore',
  { key: 'explorePageCategoryLevel', default: explorePageCategoryLevelDefaultState },
  { parser: 'number' }
);

export const explorePageBrandingLevelState = historyAtom<number>(
  'brandingLevel',
  'explore',
  { key: 'explorePageBrandingLevel', default: explorePageBrandingLevelDefaultState },
  { parser: 'number' }
);

export const explorePageGrowthPresetState = historyAtom<GrowthPresetName | undefined>(
  'growthPreset',
  'explore',
  { key: 'explorePageGrowthPreset', default: undefined }
);

export const explorePageSourcePresetState = historyAtom<SourcePresetName | undefined>(
  'sourcePreset',
  'explore',
  { key: 'explorePageSourcePreset', default: undefined }
);

export const getFilters = (get: GetRecoilValue) => {
  const query = get(explorePageQueryState);
  const categoryIds = get(explorePageCategoryIdsState);
  const marketIds = get(explorePageMarketIdsState);
  const periodIds = get(explorePagePeriodIdsState);
  const brandingIds = get(explorePageBrandingIdsState);
  const filters = { query, categoryIds, marketIds, periodIds, brandingIds };
  const definedFilterKeys = object.definedKeys(filters);

  return definedFilterKeys;
};

export const getSalesFilters = (
  get: GetRecoilValue,
  sortDirectionState?: RecoilState<SortDirection>
): ExplorePageSalesFilters => {
  const filters = {
    salesLimit: 6,
    ...getFilters(get),
    ...(sortDirectionState && { sortDirection: get(sortDirectionState) }),
  };

  return filters;
};
