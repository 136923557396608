import React from 'react';
import styles from './Path.module.scss';
import { Tooltip } from './Tooltip';
import { Icon } from './Icon';
import classNames from 'classnames';
import { iterator } from '@harmonya/utils';

type PathParts = string[];

type Props = {
  fullPathParts?: PathParts;
  compactPathParts: PathParts;
};

export function Path(props: Props) {
  const { fullPathParts, compactPathParts } = props;

  const getContent = (parts: PathParts, pathPartClassName?: string) => {
    const pathPartElements = parts.map((part, partIndex) => (
      <span key={`${part}-${partIndex}`} className={classNames(styles.pathPart, pathPartClassName)}>
        {part}
      </span>
    ));
    const content = iterator.join(pathPartElements, index => (
      <Icon key={`arrow-${index}`} name='angle-right' />
    ));

    return content;
  };

  const content = getContent(compactPathParts);

  return fullPathParts?.length ? (
    <Tooltip
      className={styles.container}
      content={
        <span className={styles.container}>{getContent(fullPathParts, styles.insideTooltip)}</span>
      }
    >
      {content}
    </Tooltip>
  ) : (
    <span className={styles.container}>{content}</span>
  );
}
