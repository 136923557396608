import { useState, useEffect } from 'react';
import { history } from 'utils/history';
import { DefaultValue } from 'recoil';
import { isDeepEqual } from 'utils/isDeepEqual';
import { getCurrentPathName } from 'utils/pageName';
import { array, object, string } from '@harmonya/utils';

export const parsers = {
  number: (value: unknown) => {
    // According to the specification, you can also get a number and an array (whose first cell is possible)
    const parsedValue = parseFloat(value as string);

    return isNaN(parsedValue) ? undefined : parsedValue;
  },
  boolean: (value: unknown) => value === true || value === 'true',
};

type NullableGeneric<T> = T | undefined | null;
type NullableStringGeneric<T> =
  | NullableGeneric<T>
  | string
  | string[]
  | Record<string, NullableGeneric<T>>;

export type Parser<T> = ((value: NullableStringGeneric<T>) => T) | keyof typeof parsers;
type Validator<T> = (
  value: T extends Array<unknown> ? NullableGeneric<T[0]> : NullableGeneric<T>
) => boolean;

export interface Options<T> {
  defaultValue?: NullableGeneric<T>;
  validator?: Validator<T>;
  parser?: Parser<T> | [number, Parser<T>][];
  isArray?: boolean;
}

export const parseValue = <T>(
  value: NullableStringGeneric<T>,
  parser?: Parser<T>,
  validator?: Validator<T>
) => {
  const computedParser = string.isString(parser) ? parsers[parser] : parser;
  const parsedValue = computedParser?.(value) ?? value;
  const isValidValue =
    validator?.(
      parsedValue as T extends Array<unknown> ? NullableGeneric<T[0]> : NullableGeneric<T>
    ) ?? true;
  const computedValue: NullableGeneric<T> = isValidValue ? (parsedValue as T) : undefined;

  return computedValue;
};

export function applyIfHasChange<T>(first: T, second: T, action: () => void) {
  const isEqualsByIgnoreOrder =
    first === second ||
    (Array.isArray(first) && Array.isArray(second)
      ? /** @todo Consider upgrade 'isEquals' to use 'isDeepEqual' */
        array.isEquals(first, second)
      : isDeepEqual(first, second));

  if (!isEqualsByIgnoreOrder) {
    action();
  }
}

const pathDelimiter = '.';

function* parseItemGenerator(item: unknown, path = ''): Generator<[string, string]> {
  if (item != null) {
    if (Array.isArray(item)) {
      for (let i = 0; i < item.length; i++) {
        yield* parseItemGenerator(item[i], path + pathDelimiter + i);
      }
    } else if (object.isObject(item)) {
      for (const [key, value] of Object.entries(item)) {
        yield* parseItemGenerator(value, path + pathDelimiter + key);
      }
    } else {
      yield [path, `${item}`];
    }
  }
}

const setObjectByPath = (
  pathParts: string[],
  result: Record<string, unknown>
): Record<string, unknown> => {
  let obj = result;

  for (const part of pathParts) {
    obj = obj[part] = (obj[part] as Record<string, unknown>) ?? {};
  }

  return obj;
};

const setOrPush = (obj: Record<string, unknown>, key: string, value: unknown) => {
  if (key in obj) {
    const firstValue = obj[key];

    if (Array.isArray(firstValue)) {
      firstValue.push(value);
    } else {
      obj[key] = [firstValue, value];
    }
  } else {
    obj[key] = value;
  }
};

const objectify = (searchParams: URLSearchParams, result = {} as Record<string, unknown>) => {
  for (const [key, value] of searchParams) {
    if (key.includes(pathDelimiter)) {
      const pathParts = key.split(pathDelimiter);
      const lastPathParts = pathParts.pop();

      // Avoid empty second part (i.e. 'foo.=bar' => ['foo', ''])
      if (lastPathParts) {
        const obj = setObjectByPath(pathParts, result);

        setOrPush(obj, lastPathParts, value);
      }
    } else {
      setOrPush(result, key, value);
    }
  }

  return result;
};

const isNullOrEmpty = (value: unknown) =>
  value == null ||
  (string.isString(value) && !value) ||
  array.isEmpty(value) ||
  (object.isObject(value) && object.isEmpty(value));

const removeKeysRecursively = (searchParams: URLSearchParams, item: unknown, path = '') => {
  /** @todo Support array of non-scalar values */
  /** @todo Support reset of non-scalar values (=> item instanceof DefaultValue) */
  if (
    item != null &&
    !Array.isArray(item) &&
    !(item instanceof DefaultValue) &&
    object.isObject(item)
  ) {
    for (const [key, value] of Object.entries(item)) {
      removeKeysRecursively(searchParams, value, path + pathDelimiter + key);
    }
  } else {
    searchParams.delete(path);
  }
};

export const getNewSearchParams = <T>(
  key: string,
  newValue: NullableGeneric<T>,
  defaultValue: NullableGeneric<T>,
  isReset = false
) => {
  const newSearchParams = new URLSearchParams(location.search);

  removeKeysRecursively(newSearchParams, newValue, key);

  if (!isReset) {
    applyIfHasChange(newValue, defaultValue, () => {
      if (array.isEmpty(newValue)) {
        newSearchParams.append(key, '');
      } else if (!isNullOrEmpty(newValue)) {
        array.ensure(newValue).forEach(item => {
          for (const [parsedKey, parsedItem] of parseItemGenerator(item, key)) {
            newSearchParams.append(parsedKey, parsedItem);
          }
        });
      }
    });
  }

  return newSearchParams;
};

const objectifyCache = new Map<string, Record<string, unknown>>();

const objectifyByCache = (searchParams: URLSearchParams) => {
  const clonedSearchParams = new URLSearchParams(searchParams);

  clonedSearchParams.sort();

  const cacheKey = clonedSearchParams.toString();
  const cachedResult = objectifyCache.get(cacheKey);

  if (cachedResult) {
    return cachedResult;
  }

  const result = objectify(searchParams);

  objectifyCache.set(cacheKey, result);

  return result;
};

export const getEnsuredSearchParam = <T>(
  key: string,
  search: string,
  defaultValue: NullableGeneric<T>,
  isArray: boolean
): NullableStringGeneric<T> => {
  const searchParams = new URLSearchParams(search);
  const objectifySearchParams = objectifyByCache(searchParams);
  const searchParamValue = objectifySearchParams[key];

  if (isNullOrEmpty(searchParamValue)) {
    // Empty param represents an empty array (for example: www.foo.com?bar=&a=1&b=2 gives us { bar: [], a: 1, b: 2 })
    if (isArray && searchParamValue === '') {
      return [];
    }

    return defaultValue;
  }

  const ensuredSearchParamsValue = isArray ? array.ensure(searchParamValue) : searchParamValue;

  return ensuredSearchParamsValue as NullableStringGeneric<T>;
};

const getParsedValue = <T>(value: NullableStringGeneric<T>, options: Options<T>) => {
  let parsedValue: NullableStringGeneric<T> | NullableStringGeneric<T>[] = value;

  if (Array.isArray(options.parser) && Array.isArray(value)) {
    const indexedParsers = options.parser;

    parsedValue = value.map((item, i) => {
      const indexedParser = indexedParsers.find(([index]) => index === i);

      return parseValue(item, indexedParser?.[1], options.validator);
    });
  } else {
    const parse = (curValue: NullableStringGeneric<T>) =>
      parseValue(curValue, options.parser as Parser<T>, options.validator);

    parsedValue = Array.isArray(value) ? value.map(parse) : parse(value);
  }

  return parsedValue;
};

function getUrlValue<T>(key: string, options: Options<T>): T {
  const { defaultValue, isArray = Array.isArray(defaultValue) } = options;
  const ensuredSearchParam = getEnsuredSearchParam(key, location.search, defaultValue, isArray);
  const parsedValue = getParsedValue(ensuredSearchParam, options);

  return parsedValue as T;
}

function isSamePage(originalPathName: string) {
  const currentPathName = getCurrentPathName();

  return originalPathName === currentPathName;
}

export const useSearchParam = <T = string>(
  key: string,
  options: Options<T> = {}
): [T, (newValue: T | undefined) => void] => {
  // To avoid losing the current value (and reverting to the default value) when switching between pages, we must
  // check whether a location change is due to a page change. We will not change the current value if it results from
  // a page change. We assume that the page detected when the initialized component is the page for which the key is
  // intended
  // eslint-disable-next-line react/hook-use-state
  const [pathName] = useState(() => getCurrentPathName());
  const [value, setValue] = useState<T>(() => getUrlValue(key, options));

  const setSearchParam = (newValue: NullableGeneric<T>) => {
    if (isSamePage(pathName)) {
      applyIfHasChange(newValue, value, () => {
        const searchParams = getNewSearchParams(key, newValue, options.defaultValue, false);

        history.push({ search: searchParams.toString() });
      });
    }
  };

  useEffect(() => {
    const removeListener = history.listen(() => {
      if (isSamePage(pathName)) {
        const urlValue = getUrlValue(key, options);

        applyIfHasChange(urlValue, value, () => setValue(urlValue));
      }
    });

    return removeListener;
  }, [value]);

  return [value, setSearchParam];
};
