import React, { useEffect, useState } from 'react';
import type { Point } from 'highcharts';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { styleVariables, withOpacity } from '../../../utils/styleVariables';
import styles from './SubSectionMap.module.scss';
import subSectionStyles from '../../layout/explorePage/ExplorePageSubSection.module.scss';
import type { SalesSeries } from '../../../models/SalesSeries';
import { useRecoilValue, useRecoilState } from 'recoil';
import { growthPointState } from '../../../store/growthPoint';
import { explorePageMarketIdsState } from '../../../store/explorePage';
import appStyles from '../../layout/App.module.scss';
import highchartsMap from 'highcharts/modules/map';
import classNames from 'classnames';
import { Icon } from '../Icon';
import { marketsState } from 'store/markets';
import { ButtonGroup } from 'components/general/ButtonGroup';
import { RadioButtonGroup } from 'components/general/RadioButtonGroup';
import { marketsMapSalesState } from 'store/marketsMapSales';
import { marketsMapState } from 'store/marketsMap';
import type { FeatureCollection } from 'geojson';
import { useSearchParam } from 'hooks/useSearchParam';
import dividerStyles from 'components/general/Divider.module.scss';
import { iterator, missingValue, number, string } from '@harmonya/utils';
import type { MapMarketItem } from '../../../interfaces';

highchartsMap(Highcharts);

type MapSeriesItem = MapMarketItem & { value?: number; selected: boolean };
type MapPoint = Point & { code?: string };

interface HighchartsMapChart extends Highcharts.MapChart {
  mapView: Highcharts.MapView;
}

const { mapColors } = styleVariables;
const disabledMapColors = Array<string>(mapColors.length).fill(
  withOpacity(styleVariables.defaultThemeSecondaryColorDark, 0.15)
);
const zoomStep = 0.25;
const maxZoom = 9;
const isMarketAreaFiltered = (
  filteredMarketIds: number[],
  marketId: number,
  restOfWhichMarket?: number
) =>
  filteredMarketIds.includes(marketId) ||
  (!!restOfWhichMarket && filteredMarketIds.includes(restOfWhichMarket));

type Props = {
  activeSeriesKey: keyof SalesSeries;
  retailerDisplayName: string;
};

export function SubSectionMap(props: Props) {
  const { activeSeriesKey, retailerDisplayName } = props;
  const growthPoint = useRecoilValue(growthPointState);
  const markets = useRecoilValue(marketsState);
  const [filteredMarketIds, setFilteredMarketIds] = useRecoilState(explorePageMarketIdsState);
  const [lastFilteredMarketIds, setLastFilteredMarketIds] = useState(new Set(filteredMarketIds));
  // Markets that capture all the map are ignored when filtered (no map selection and no error)
  const [allMarketIds, setAllMarketIds] = useState(new Set<number>());
  const [zoom, setZoom] = useState<number | undefined>();
  const [minZoom, setMinZoom] = useState<number>(1);
  const [error, setError] = useState<string>();
  const [restOfMarketActivated, setRestOfMarketActivated] = useSearchParam(
    'restOfMarketActivated',
    { defaultValue: false, parser: 'boolean' }
  );
  const [series, setSeries] = useState<MapSeriesItem[]>();
  const marketsMapSales = useRecoilValue(marketsMapSalesState);
  const marketsMap = useRecoilValue(marketsMapState);

  if (!marketsMap) {
    return null;
  }

  const { map, areaMapper } = marketsMap;

  const checkFilteredMarketSupport = () => {
    const nonSupportedMarketIds = filteredMarketIds.filter(
      filteredMarketId => !areaMapper.has(filteredMarketId) && !allMarketIds.has(filteredMarketId)
    );

    if (nonSupportedMarketIds.length) {
      const nonMappedMarketNames = nonSupportedMarketIds
        .map(marketId => markets.get(marketId))
        .join(', ');

      setError(
        `The map displays ${retailerDisplayName} markets only. ${string.getPluralOrSingular('The market', nonSupportedMarketIds.length)} ${nonMappedMarketNames} cannot be displayed`
      );
    } else {
      setError(undefined);
    }
  };

  const getMarketValue = (marketId: number) =>
    activeSeriesKey === 'totalSalesItems'
      ? marketsMapSales.totalSalesItems.get(marketId)
      : marketsMapSales.salesGrowthItems[growthPoint]?.get(marketId);

  useEffect(() => {
    const rawSeries = [...areaMapper].reduce((acc: MapSeriesItem[], item) => {
      const [marketId, { restOfWhichMarket, isRestOfMarket, ...itemProps }] = item;
      // To set the right color by the right value, we set the point's value to one aligned with restOfMarketActivated
      const valueMarketId = isRestOfMarket === restOfMarketActivated ? marketId : restOfWhichMarket;
      const value = getMarketValue(valueMarketId);
      const selected = isMarketAreaFiltered(filteredMarketIds, marketId, restOfWhichMarket);

      acc.push({
        ...itemProps,
        value,
        marketId,
        isRestOfMarket,
        restOfWhichMarket,
        selected,
      });

      return acc;
    }, []);
    const [allMarketSeries, newSeries] = iterator.split(
      rawSeries,
      seriesItem => seriesItem.isAllMarket
    );
    const rawNewAllMarketIds = allMarketSeries.map(seriesItem => seriesItem.marketId);
    const newAllMarketIds = new Set(rawNewAllMarketIds);

    setAllMarketIds(newAllMarketIds);
    setSeries(newSeries);
    checkFilteredMarketSupport();
  }, [map, marketsMapSales, activeSeriesKey, growthPoint, restOfMarketActivated]);

  useEffect(() => {
    if (series?.length) {
      const newSeries = series?.map(item => ({
        ...item,
        selected: isMarketAreaFiltered(filteredMarketIds, item.marketId, item.restOfWhichMarket),
      }));

      setSeries(newSeries);
      checkFilteredMarketSupport();

      // Set restOfMarketActivated by the new filtered market ID
      if (!error && lastFilteredMarketIds.size < filteredMarketIds.length) {
        const newMarketId = filteredMarketIds.find(id => !lastFilteredMarketIds.has(id));

        if (newMarketId) {
          const isRestOfMarket = !!areaMapper.get(newMarketId)?.isRestOfMarket;

          setRestOfMarketActivated(isRestOfMarket);
        }
      }

      const newLastFilteredMarketIds = new Set(filteredMarketIds);

      setLastFilteredMarketIds(newLastFilteredMarketIds);
    }
  }, [filteredMarketIds]);

  const computedMapColors = error ? disabledMapColors : mapColors;

  const toggleMarket = (updatedCode?: string) => {
    if (updatedCode && series) {
      const updatedSeries = series.filter(item => item.code === updatedCode);
      const isRemoveMode = updatedSeries.some(({ selected }) => selected);

      if (isRemoveMode) {
        const updatedAreaSeries = new Set(updatedSeries.map(item => item.marketId));
        const newFilteredMarketIds = filteredMarketIds.filter(
          marketId => !updatedAreaSeries.has(marketId)
        );

        setFilteredMarketIds(newFilteredMarketIds);
      } else {
        const addedMarketSeries = updatedSeries.find(
          item => item.isRestOfMarket === restOfMarketActivated
        );

        if (addedMarketSeries?.value != null) {
          const newFilteredMarketIds = [...filteredMarketIds, addedMarketSeries.marketId];

          setFilteredMarketIds(newFilteredMarketIds);
        }
      }
    }
  };

  if (series?.length) {
    const getColorAxis = (stopsCount: number): Partial<Highcharts.Options['colorAxis']> => {
      const ensuredStopsCount = Math.min(stopsCount, computedMapColors.length);
      const stopsCountIndex = ensuredStopsCount - 1;
      const stops = [...Array(ensuredStopsCount)].map<[number, string]>((_, i) => [
        i / stopsCountIndex,
        computedMapColors[stopsCountIndex - i],
      ]);

      return {
        stops,
        minColor: computedMapColors[stopsCountIndex],
        maxColor: computedMapColors[0],
      };
    };

    const options: Highcharts.Options = {
      accessibility: { enabled: false },
      chart: {
        backgroundColor: 'transparent',
        animation: false,
        events: {
          render() {
            const mapChart = this as HighchartsMapChart;
            const currentZoom = mapChart.mapView?.zoom;

            if (currentZoom != null && currentZoom !== zoom) {
              if (zoom === undefined) {
                // Setting initial zoom
                const newMinZoom = Math.max(minZoom, currentZoom);

                setMinZoom(newMinZoom);
              }

              setZoom(currentZoom);
            }
          },
        },
      },
      title: undefined,
      mapView: { zoom, maxZoom },
      colorAxis: {
        type: 'linear',
        ...getColorAxis(5),
      },
      colors: [
        // Though other colors are set in the colorAxis, missing color (like value is undefined) use color from here
        disabledMapColors[0],
      ],
      mapNavigation: { enabled: false },
      credits: { enabled: false },
      legend: { enabled: false },
      tooltip: {
        enabled: true,
        borderWidth: 0,
        useHTML: true,
        backgroundColor: undefined,
        formatter: tooltip => {
          if (tooltip.chart.hoverPoint === null) {
            return tooltip.defaultFormatter(tooltip);
          }

          const hoverPoint = tooltip.chart.hoverPoint as MapPoint;
          const pointsMapSeries = series.filter(({ code }) => code === hoverPoint.code);

          // Sort by restOfMarketActivated filter state
          const pointSortFunction = (point: { isRestOfMarket: boolean }) => {
            if (restOfMarketActivated) {
              return point.isRestOfMarket ? -1 : 1;
            }

            return point.isRestOfMarket ? 1 : -1;
          };

          // Disable tooltip if no value at all the series of the area (regular and rest of market)
          if (pointsMapSeries.every(point => getMarketValue(point.marketId) == null)) {
            return '';
          }

          const sortedPointsMapSeries = [...pointsMapSeries].sort(pointSortFunction);
          const pointsHtml = sortedPointsMapSeries
            .map(point => {
              if (!marketsMapSales) {
                return '';
              }

              const growthValue = marketsMapSales.salesGrowthItems[growthPoint]?.get(
                point.marketId
              );
              const totalSalesValue = marketsMapSales.totalSalesItems.get(point.marketId);
              const growthHtmlValue = growthValue
                ? `${number.short(growthValue * 100)}%`
                : missingValue;
              const growthHtml = `<i class='fat fa-chart-line-up'></i>${growthHtmlValue}`;
              const html = `
                            <i class='fat fa-location-dot' style='color: ${hoverPoint.color}'></i>
                            ${markets.get(point.marketId)}
                            <div class='${dividerStyles.divider}'></div>
                            ${totalSalesValue == null ? missingValue : number.shortCurrency(totalSalesValue)}
                            ${growthHtml}
                        `;

              return html;
            })
            .join('');
          const containerClassNames = classNames(
            subSectionStyles.tooltipContainer,
            styles.tooltipContainer,
            subSectionStyles.tooltipTitle
          );

          return `<div class='${containerClassNames}'>${pointsHtml}</div>`;
        },
      },
      series: [
        {
          type: 'map',
          mapData: map as FeatureCollection,
          data: series,
          joinBy: ['code', 'code'],
          states: {
            select: { color: undefined },
            hover: { brightness: 0.05 },
          },
          point: {
            events: {
              click(this: MapPoint) {
                toggleMarket(this.code);
              },
            },
          },
        },
      ],
    };

    const updateZoom = (value: string) => {
      if (zoom) {
        const newZoom =
          value === '-' ? Math.max(zoom - zoomStep, minZoom) : Math.min(zoom + zoomStep, maxZoom);

        setZoom(newZoom);
      }
    };

    return (
      <div
        className={classNames(
          styles.container,
          error && styles.hasError,
          zoom != null && zoom > minZoom && styles.zoomedIn
        )}
      >
        <div className={classNames(appStyles.horizontalFlex, appStyles.justifySpaceBetween)}>
          <div className={appStyles.horizontalFlex}>
            {error ? (
              <span
                className={classNames(
                  styles.warning,
                  appStyles.horizontalFlex,
                  appStyles.alignCenter
                )}
              >
                <Icon name='circle-exclamation' weight='light' />
                {error}
              </span>
            ) : (
              <RadioButtonGroup
                value={String(restOfMarketActivated) as 'true' | 'false'}
                onChange={newValue => setRestOfMarketActivated(newValue === 'true')}
                options={[
                  { value: 'false', name: retailerDisplayName },
                  { value: 'true', name: 'RM xAOC' },
                ]}
              />
            )}
          </div>
          <div className={appStyles.horizontalFlex}>
            <ButtonGroup onClick={updateZoom}>
              {[
                {
                  value: '-',
                  element: <Icon name='magnifying-glass-minus' weight='light' />,
                  disabled: zoom != null && zoom <= minZoom,
                },
                {
                  value: '+',
                  element: <Icon name='magnifying-glass-plus' weight='light' />,
                  disabled: zoom != null && zoom >= maxZoom,
                },
              ]}
            </ButtonGroup>
          </div>
        </div>
        <HighchartsReact
          constructorType='mapChart'
          highcharts={Highcharts}
          allowChartUpdate
          options={options}
        />
      </div>
    );
  }

  return null;
}
