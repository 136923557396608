import type { PropsWithChildren } from 'react';
import React from 'react';
import classNames from 'classnames';
import appStyles from './App.module.scss';
import styles from './Header.module.scss';

type Props = PropsWithChildren<{
  title: string;
}>;

export function Header(props: Props) {
  const { title, children } = props;

  return (
    <header
      className={classNames(
        appStyles.horizontalFlex,
        appStyles.unpaddedBox,
        appStyles.padded1,
        appStyles.alignCenter,
        appStyles.justifySpaceBetween,
        appStyles.shadowed,
        styles.container
      )}
    >
      <h2 className={classNames(appStyles.padded1, styles.title)}>{title.toUpperCase()}</h2>
      {children}
    </header>
  );
}
