import type { ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';
import styles from './SelectOption.module.scss';
import appStyles from '../../layout/App.module.scss';
import { Tooltip } from '../Tooltip';
import { IconsBox } from '../IconsBox';
import { Icon } from '../Icon';
import type { InFull } from '@harmonya/utils';
import type { Option, RawOptionType } from 'components/general/select/types';

const iconsBoxIconNames = ['check', 'hyphen'] as const;

type Props<T extends Option<RawOptionType>> = {
  option: T;
  isSelected?: boolean;
  hasSelectedDescendant?: boolean;
  isHighlighted?: boolean;
  toggleEnabled?: boolean;
  colorInverted?: boolean;
  style?: React.CSSProperties;
  tooltip?: ReactNode;
  onClick: (option: T) => void;
  onMouseOver?: (option: T) => void;
  displayValueGetter: (option: T) => ReactNode;
  optionClassNameGetter?: (option: T) => string;
  isCheckboxDisplayed?: boolean;
  arrowPosition?: 'left' | 'right';
} & InFull<{
  onArrowClick: (option: T) => void;
  childrenDisplayed: boolean;
}>;

export function SelectOption<T extends Option<RawOptionType>>(props: Props<T>) {
  const {
    option,
    isSelected,
    hasSelectedDescendant,
    isHighlighted,
    toggleEnabled,
    colorInverted,
    style,
    tooltip,
    onClick,
    onMouseOver,
    displayValueGetter,
    optionClassNameGetter,
    onArrowClick,
    childrenDisplayed,
    isCheckboxDisplayed,
    arrowPosition = 'left',
  } = props;
  const { disabled, node } = option;

  const handleClick = (event: React.MouseEvent) => {
    // We don't disable it by the CSS pointer-events property because we want to listen for pointer events (hover
    // to unpaint background of another options and click to stop propagation)
    if (!disabled) {
      onClick(option);
    }

    event.stopPropagation();
  };

  const className = classNames(
    appStyles.horizontalFlex,
    appStyles.alignCenter,
    appStyles.animatedFadeIn,
    styles.option,
    arrowPosition === 'right' && styles.optionArrowRight,
    isSelected && styles.selected,
    isHighlighted && styles.highlighted,
    toggleEnabled && styles.toggleable,
    colorInverted && styles.colorInverted,
    disabled && styles.disabled,
    optionClassNameGetter && [appStyles.gap0, optionClassNameGetter(option)],
    isCheckboxDisplayed && appStyles.gap0
  );
  const state = (isSelected && 'check') || (hasSelectedDescendant ? 'hyphen' : undefined);
  const checkboxElement = isCheckboxDisplayed && (
    <div className={styles.checkboxContainer}>
      <IconsBox activeIconName={state} iconNames={iconsBoxIconNames} />
    </div>
  );

  const handleArrowClick: React.MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation();
    onArrowClick?.(option);
  };

  const arrowElement = !!node?.children.length && onArrowClick && (
    <button
      className={classNames(
        appStyles.unpaddedButton,
        styles.arrowContainer,
        colorInverted && appStyles.colorInverted,
        arrowPosition == 'right' && styles.arrowRight
      )}
      onClick={handleArrowClick}
    >
      <Icon
        className={classNames(styles.arrow, childrenDisplayed && styles.expanded)}
        name='angle-right'
        weight='light'
      />
    </button>
  );
  const result = (
    <li
      // This listener comes to prevent a blur event (which skips a click event in the parent component). See more here: https://stackoverflow.com/a/57630197
      onMouseDown={event => event.preventDefault()}
      style={style}
      className={className}
      onClick={handleClick}
      onMouseOver={() => onMouseOver?.(option)}
      {...(node && { 'data-level': node.level })}
    >
      {arrowElement}
      {checkboxElement}
      {displayValueGetter(option)}
    </li>
  );

  /** @todo We need to understand why with verticalDirection=center it does not align with the center */
  return tooltip && !disabled ? (
    <Tooltip content={tooltip} verticalDirection='bottom' horizontalDirection='right'>
      {result}
    </Tooltip>
  ) : (
    result
  );
}
