import type { MutableRefObject } from 'react';
import { useEffect, useRef } from 'react';

type ReturnType<T> = readonly [MutableRefObject<T[]>, (index: number) => (item?: T | null) => void];

export const useRefs = <T>(dependencySource: unknown[], initialValue: T[] = []): ReturnType<T> => {
  const refs = useRef<T[]>(initialValue);

  useEffect(() => {
    refs.current = refs.current.slice(0, dependencySource.length);
  }, [dependencySource]);

  const setCallbackGetter = (index: number) => (item?: T | null) =>
    item && (refs.current[index] = item);

  return [refs, setCallbackGetter] as const;
};
