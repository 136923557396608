import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { useSetRecoilState } from 'recoil';
import { analyticsState } from 'store/analytics';
import { usePageName } from 'hooks/usePageName';
import { shouldTrackAnalytics } from 'utils/analytics';
import { env } from '../../env';

type AnalyticsUser = {
  email?: string;
  name?: string;
};

export function useAnalytics(user: AnalyticsUser | undefined, customerId: string) {
  const setAnalytics = useSetRecoilState(analyticsState);
  const page = usePageName();

  useEffect(() => {
    if (customerId) {
      if (env.MIXPANEL_TOKEN && shouldTrackAnalytics(user?.email)) {
        mixpanel.init(env.MIXPANEL_TOKEN, {
          debug: env.DEBUG_ANALYTICS,
          track_pageview: true,
          persistence: 'localStorage',
        });

        if (user) {
          mixpanel.identify(user.email);
          mixpanel.people.set_once({ $email: user.email, $name: user.name });
        }

        setAnalytics({
          track: (name, props) => {
            mixpanel.track(name, { page, customerId, ...props });
          },
        });
      }
    }
  }, [customerId, user, setAnalytics, page]);
}
