import classNames from 'classnames';
import { Icon } from 'components/general/Icon';
import { Tooltip } from 'components/general/Tooltip';
import type { AggregateTag } from 'models/AggregateTag';
import React from 'react';
import appStyles from '../../App.module.scss';
import styles from './TagsVennMoreTagsIndicator.module.scss';
import { Divider } from 'components/general/Divider';

type Props = {
  tags: AggregateTag[];
  tagIndex: number;
  top: number;
  left: number;
  width: number;
  tooltipDescription: string;
  vennName: string;
};

export function TagsVennMoreTagsIndicator(props: Props) {
  const { tags, tagIndex, top, left, width, tooltipDescription, vennName } = props;
  const tooltipContent = (
    <div className={classNames(appStyles.verticalFlex, appStyles.unpaddedBox, styles.tooltip)}>
      <span>{tooltipDescription}</span>
      <Divider direction='horizontal' />
      <div
        className={classNames(
          appStyles.horizontalFlex,
          appStyles.justifySpaceBetween,
          styles.footer
        )}
      >
        <span>{vennName}</span>
        <span className={styles.tooltipIndicator}>
          {` +${tags?.length - tagIndex} `}
          <Icon className={styles.indicatorIcon} name='diamond' weight='light' />
        </span>
      </div>
    </div>
  );

  return (
    <Tooltip horizontalDirection='right' content={tooltipContent}>
      <div
        className={classNames(appStyles.horizontalFlex, styles.indicator)}
        style={{ width, left, top }}
      >
        <span className={styles.indicatorText}>{`+${tags?.length - tagIndex}`}</span>
        <Icon className={styles.indicatorIcon} name='diamond' weight='light' />
      </div>
    </Tooltip>
  );
}
