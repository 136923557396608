import type { Falsy } from '@harmonya/utils';
import { array, iterator, map } from '@harmonya/utils';
import type { Option, RawOptionType } from 'components/general/select/types';
import type { SelectOption } from '../models/SelectOption';
import type { SelectOption as RawSelectOption } from '@harmonya/models';

export type Node<T> = T & { children: Node<T>[]; parent: Node<T> };

export class TreeOption implements Pick<SelectOption, 'id' | 'name'> {
  id: number;
  name: string;
  level: number;
  parentId?: number;
  childrenIds?: number[];
  compactPathParts?: string[];
  fullPathParts?: string[];

  constructor(
    id: number,
    name: string,
    parentId?: number,
    childrenIds?: number[],
    level = 0,
    compactPathParts?: string[],
    fullPathParts?: string[]
  ) {
    this.id = id;
    this.name = name;
    this.level = level;
    this.parentId = parentId;
    this.childrenIds = childrenIds;
    this.compactPathParts = compactPathParts;
    this.fullPathParts = fullPathParts;
  }
}

export function toggleNewValues(values: Set<RawOptionType>, id: RawOptionType, state: boolean) {
  const action = state ? 'add' : 'delete';

  values[action](id);
}

export function hasSelectedAncestor<T extends Option<RawOptionType>>(
  isSelected: (option: T) => boolean,
  option: T
): boolean {
  if (isSelected(option)) {
    return true;
  }

  return !!option.node?.parent && hasSelectedAncestor(isSelected, option.node.parent as T);
}

function* getSiblings(option: Option<RawOptionType>) {
  const siblingsWithOption = option.node?.parent?.node?.children;

  if (siblingsWithOption) {
    for (const sibling of siblingsWithOption) {
      if (sibling !== option) {
        yield sibling;
      }
    }
  }
}

function setSiblingsSelected<T extends RawOptionType>(newValues: Set<T>, option: Option<T>) {
  for (const child of getSiblings(option)) {
    toggleNewValues(newValues, child.value, true);
  }
}

// If the original option changed to unselected, and its parent first appeared as selected (either because he was
// selected or because one of his ancestors was selected) - then the option's siblings must be left to the selected
function siblingsShouldRemainSelected<T extends RawOptionType>(
  sourceOptionChangedToSelected: boolean,
  oldValues: Set<T>,
  option: Option<T>
) {
  const result =
    !sourceOptionChangedToSelected &&
    hasSelectedAncestor(node => oldValues.has(node.value), option);

  return result;
}

export function setParentsSelectedRecursively<T extends RawOptionType, O extends Option<T>>(
  oldValues: Set<T>,
  newValues: Set<T>,
  sourceOptionChangedToSelected: boolean,
  childOption: O,
  option?: O
) {
  if (option?.node) {
    const { children, parent } = option.node;
    const allChildrenSelected = children.every(child => newValues.has(child.value));

    if (allChildrenSelected) {
      setChildrenSelectedRecursively(newValues, children, false);
    } else if (siblingsShouldRemainSelected(sourceOptionChangedToSelected, oldValues, option)) {
      setSiblingsSelected(newValues, childOption);
    }

    toggleNewValues(newValues, option.value, allChildrenSelected);

    setParentsSelectedRecursively(
      oldValues,
      newValues,
      sourceOptionChangedToSelected,
      option,
      parent
    );
  }
}

export function setChildrenSelectedRecursively(
  values: Set<RawOptionType>,
  children: Option<RawOptionType>[],
  sourceOptionSelected: boolean
) {
  for (const child of children) {
    toggleNewValues(values, child.value, sourceOptionSelected);

    if (child.node) {
      setChildrenSelectedRecursively(values, child.node.children, sourceOptionSelected);
    }
  }
}

export function getWithoutImplicitNodes<T extends RawOptionType, O extends Option<T>>(
  oldValues: Set<T>,
  newValues: Set<T>,
  option: O,
  isOptionSelected: boolean
) {
  const clonedNewValues = new Set(newValues);

  if (option.node) {
    const { parent, children } = option.node;

    setParentsSelectedRecursively(oldValues, clonedNewValues, isOptionSelected, option, parent);

    if (isOptionSelected) {
      setChildrenSelectedRecursively(clonedNewValues, children, false);
    }
  }

  return clonedNewValues;
}

export function hasSelectedDescendant<T extends Option<RawOptionType>>(
  isSelected: (option: T) => boolean,
  option: T
): [boolean, boolean] {
  if (isSelected(option)) {
    return [true, true];
  }

  let hasSelected = false;
  let hasUnselected = false;

  for (const child of option.node?.children ?? []) {
    const [childAllDescendantsSelected, childHasSelectedDescendant] = hasSelectedDescendant(
      isSelected,
      child as T
    );

    hasSelected ||= childHasSelectedDescendant;
    hasUnselected ||= !childAllDescendantsSelected;

    if (hasSelected && hasUnselected) {
      return [false, true];
    }
  }

  return [hasSelected && !hasUnselected, hasSelected];
}

export function allDescendantsSelected<T extends RawOptionType>(
  values: Set<T>,
  option: Option<T>
): boolean {
  if (!values.has(option.value)) {
    return false;
  }

  return option.node?.children.every(child => allDescendantsSelected(values, child)) ?? true;
}

export function getTreeMap(items: RawSelectOption[]) {
  const entries = items.map(
    ({ parentId, ...item }) =>
      [
        item.id,
        {
          ...item,
          ...(parentId != null && { parent: { id: parentId } }),
        } as Node<SelectOption>,
      ] as const
  );
  const treeMap = new Map(entries);

  for (const item of treeMap.values()) {
    if (item.parent) {
      const parentId = item.parent.id;
      const parent = treeMap.get(parentId);

      if (!parent) {
        throw new Error(`Parent #${parentId} is missing`);
      }

      item.parent = parent;
      array.ensuredPush(parent, 'children', item);
    }
  }

  return treeMap;
}

export const toTreeOption = (
  { id, name, parent, children, level }: Node<SelectOption>,
  paths?: PathParts
) =>
  new TreeOption(
    id,
    name,
    parent?.id,
    children?.map(child => child.id),
    level?.id,
    paths?.compact,
    paths?.full
  );

export function* getChildren<T>(item: T, childrenGetter: (option: T) => T[]): Generator<T> {
  const children = childrenGetter(item);

  yield item;

  if (children) {
    for (const child of children) {
      yield* getChildren(child, childrenGetter);
    }
  }
}

export function selectOptionsToTreeMap<T extends Node<SelectOption>>(
  options: Map<T['id'], T>,
  parentGetter: (option: T) => T | undefined,
  childrenGetter: (option: T) => T[]
) {
  const rootOptions = iterator.filter(options.values(), option => !parentGetter(option));
  const sortedFlattenTree = rootOptions.flatMap(option => [...getChildren(option, childrenGetter)]);
  const paths = getPaths(options);
  const sortedFlattenTreeEntries = sortedFlattenTree.map(
    item => [item.id, toTreeOption(item, paths.get(item.id))] as const
  );
  const treeMap = new Map(sortedFlattenTreeEntries);

  return treeMap;
}

export type PathParts = { compact: string[]; full: string[] };

export type AncestorsDirection = 'top-down' | 'bottom-up';

export function getAncestors<T>(
  option: T | undefined,
  parentGetter: (option: T) => T | Falsy,
  direction: AncestorsDirection = 'bottom-up'
) {
  const ancestors: T[] = [];
  const functionName = direction === 'bottom-up' ? 'push' : 'unshift';
  const addAncestor = (ancestor: T) => ancestors[functionName](ancestor);

  if (option) {
    let parent = parentGetter(option);

    while (parent) {
      addAncestor(parent);
      parent = parentGetter(parent);
    }
  }

  return ancestors;
}

export function getPathParts(option: SelectOption) {
  const ancestors = getAncestors(option, ancestor => ancestor.parent, 'top-down');
  const ancestorNames = ancestors.map(ancestor => ancestor.name);

  return [...ancestorNames, option.name];
}

export function getPaths(treeMap: Map<number, Node<SelectOption>>): Map<number, PathParts> {
  const namesByLevel: Record<number, Record<string, number>> = {};
  const rawOptions = new Map(
    Array.from(new Map(treeMap), ([key, option]) => {
      const full = getPathParts(option);
      const lastIndex = full.length - 1;
      const optionalNames = full.map((_, i) => full.slice(lastIndex - i).join());

      return [key, { full, optionalNames }];
    })
  );

  rawOptions.forEach(({ optionalNames }) =>
    optionalNames.forEach((name, i) => {
      const levelNames = (namesByLevel[i] ??= {});

      levelNames[name] ??= 0;
      levelNames[name]++;
    })
  );

  const options = map.definedMapValues(rawOptions, ({ full, optionalNames }) => {
    const uniqueNameIndex = optionalNames.findIndex((name, i) => namesByLevel[i][name] === 1);
    const computedUniqueNameIndex = optionalNames.length - 1 - uniqueNameIndex;
    const isIndexInRange = computedUniqueNameIndex > 0 && computedUniqueNameIndex < full.length;
    const compact = isIndexInRange ? full.slice(computedUniqueNameIndex) : full;

    return { full, compact };
  });

  return options;
}

type Entry = [string, string];

export function getNamedPath<Entity extends SelectOption>(
  lastEntity: Entity,
  order?: 'bottomUp' | 'topDown'
) {
  const entries: Entry[] = [];
  const addToEntries: (entry: Entry) => void =
    order === 'bottomUp' ? entry => entries.push(entry) : entry => entries.unshift(entry);
  let currentEntity: SelectOption | undefined = lastEntity;

  while (currentEntity?.level) {
    const entry: Entry = [currentEntity.level.name, currentEntity.name];

    addToEntries(entry);
    currentEntity = currentEntity.parent;
  }

  const entities = Object.fromEntries(entries);

  return entities;
}
