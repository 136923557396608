import React from 'react';
import { Icon } from 'components/general/Icon';
import { Divider } from 'components/general/Divider';
import classNames from 'classnames';
import appStyles from '../App.module.scss';
import styles from './AttributionPageToolbar.module.scss';
import { Tooltip } from 'components/general/Tooltip';
import { AttributionLegend } from './AttributionLegend';
import { Dropdown } from 'components/general/dropdown/Dropdown';
import {
  attributionActionIcons,
  attributionConfidenceLevelIcons,
  confidenceLevelTitle,
  sourceInputTitle,
  valueTypesTitle,
} from './attribution.utils';
import { getMinWidthByLongestOption } from 'components/general/select/Select';
import { string } from '@harmonya/utils';
import { AttributionPerformanceBoostingTag } from './AttributionPerformanceBoostingTag';
import { AttributionDisplayDropdown } from './AttributionDisplayDropdown';

const legendOptions = [
  ...Object.values(attributionActionIcons),
  ...Object.values(attributionConfidenceLevelIcons),
  valueTypesTitle,
  sourceInputTitle,
  confidenceLevelTitle,
];
const attributionLegendMinWidth = getMinWidthByLongestOption(
  [['', legendOptions]],
  item => (string.isString(item) ? item : item.title),
  false,
  '',
  8
);

type Props = {
  isAnalyticsPanelOpen: boolean;
  openAnalyticsPanel: () => void;
  onExport: () => void;
};

export function AttributionPageToolbar(props: Props) {
  const { isAnalyticsPanelOpen, openAnalyticsPanel, onExport } = props;

  const legendButton = (
    <button className={appStyles.button}>
      <Icon name='circle-info' className={appStyles.hugeFont} />
    </button>
  );

  const displayButton = (
    <button className={classNames(appStyles.button, styles.displayButton)}>
      <Icon name='columns-3' className={appStyles.hugeFont} />
      Display
    </button>
  );

  return (
    <div
      className={classNames(
        appStyles.horizontalFlex,
        appStyles.alignCenter,
        appStyles.justifySpaceBetween
      )}
    >
      <div className={classNames(appStyles.horizontalFlex, styles.section)}>
        <button
          className={classNames(
            appStyles.button,
            styles.displayButton,
            styles.hideable,
            isAnalyticsPanelOpen && styles.hide
          )}
          onClick={openAnalyticsPanel}
        >
          <Icon name='expand-right' className={appStyles.hugeFont} />
          Analytics
        </button>
        <Divider className={classNames(styles.hideable, isAnalyticsPanelOpen && styles.hide)} />
        <Dropdown buttonContent={displayButton} buttonMarkingEnabled isDraggable>
          <AttributionDisplayDropdown />
        </Dropdown>
        <AttributionPerformanceBoostingTag />
      </div>
      <div className={classNames(appStyles.horizontalFlex, styles.section)}>
        <Tooltip content='Export' verticalDirection='bottom'>
          <button className={appStyles.button} onClick={onExport}>
            <Icon name='file-export' className={appStyles.hugeFont} />
          </button>
        </Tooltip>
        <Tooltip content='Legend' verticalDirection='bottom'>
          <Dropdown
            minWidth={attributionLegendMinWidth}
            align='right'
            buttonContent={legendButton}
            buttonMarkingEnabled
          >
            <AttributionLegend />
          </Dropdown>
        </Tooltip>
      </div>
    </div>
  );
}
