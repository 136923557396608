import React, { useMemo, useState } from 'react';
import explorePageProductsTableStyles from '../../explorePage/ExplorePageProductsTable.module.scss';
import appStyles from '../../../layout/App.module.scss';
import styles from './AttributionAnalyticsPanel.module.scss';
import { Divider } from 'components/general/Divider';
import { AttributionAnalyticsPanelTotals } from './AttributionAnalyticsPanelTotals';
import { AttributionAnalyticsPanelHeader } from './AttributionAnalyticsPanelHeader';
import classNames from 'classnames';
import { Icon } from 'components/general/Icon';
import { Input } from 'components/general/Input';
import { ButtonGroup } from 'components/general/ButtonGroup';
import { tooltipTexts } from 'tooltipTexts';
import { AttributionAnalyticsPanelExpandableCard } from './card/AttributionAnalyticsPanelExpandableCard';
import { AttributionAnalyticsPanelPerformanceCard as PerformanceCard } from './card/AttributionAnalyticsPanelPerformanceCard';
import type { SortDirection } from '@harmonya/utils';
import {
  attributionAnalyticsPanelDataState,
  attributionPerformanceBoostingSortPropertyState,
} from 'store/attribution';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getSortedFilteredOptions } from 'utils/sortedFilteredOptions';
import { Tooltip } from 'components/general/Tooltip';
import type { TransitionStatus } from 'react-transition-group';
import { usePerformanceBoosting } from 'components/layout/attributionPage/hooks/usePerformanceBoosting';

type Props = {
  closeAnalyticsPanel: () => void;
  state: TransitionStatus;
};

export function AttributionAnalyticsPanel(props: Props) {
  const { closeAnalyticsPanel, state } = props;
  const [sortProperty, setSortProperty] = useRecoilState(
    attributionPerformanceBoostingSortPropertyState
  );
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [searchText, setSearchText] = useState('');
  const { analyticsTotals, analyticsAttributesPerformances } = useRecoilValue(
    attributionAnalyticsPanelDataState
  );
  const [performanceBoostingTag, setPerformanceBoostingTag] = usePerformanceBoosting();

  const sortedFilteredAnalyticsAttributesPerformances = useMemo(() => {
    const { filteredOptions } = getSortedFilteredOptions(
      searchText,
      analyticsAttributesPerformances,
      'title'
    );

    const sortDirectionFactor = sortDirection === 'asc' ? 1 : -1;
    const sortedFilteredOptions = filteredOptions.toSorted(
      (a, b) => (a[sortProperty] - b[sortProperty]) * sortDirectionFactor
    );

    return sortedFilteredOptions;
  }, [searchText, sortDirection, sortProperty, analyticsAttributesPerformances]);

  return (
    <div className={classNames(styles.container, state)}>
      <div className={styles.section}>
        <AttributionAnalyticsPanelHeader closeAnalyticsPanel={closeAnalyticsPanel} />
        <AttributionAnalyticsPanelTotals totals={analyticsTotals} />
      </div>
      <Divider direction='horizontal' />
      <div className={classNames(styles.section, styles.performance)}>
        <h2>Performance Boosting Opportunities</h2>
        <Input
          containerClassName={explorePageProductsTableStyles.searchInput}
          placeholder='Search'
          value={searchText}
          iconName='search'
          onInput={({ currentTarget }) => setSearchText(currentTarget.value)}
        />
        <div
          className={classNames(appStyles.horizontalCenteredFlex, appStyles.justifySpaceBetween)}
        >
          <ButtonGroup selectedValue={sortProperty} onClick={setSortProperty} contentFitted>
            {[
              {
                value: 'accuracy',
                element: <h4>Accuracy</h4>,
              },
              {
                value: 'coverage',
                element: <h4>Coverage</h4>,
              },
            ]}
          </ButtonGroup>
          <ButtonGroup selectedValue={sortDirection} onClick={setSortDirection}>
            {[
              {
                value: 'desc',
                element: <Icon name='arrow-down-wide-short' weight='light' />,
                tooltip: tooltipTexts.common.descSort,
              },
              {
                value: 'asc',
                element: <Icon name='arrow-up-short-wide' weight='light' />,
                tooltip: tooltipTexts.common.ascSort,
              },
            ]}
          </ButtonGroup>
        </div>
        <div className={classNames(styles.cardList, styles.shrinkEnabled)}>
          {sortedFilteredAnalyticsAttributesPerformances.map(
            ({ key, title, ...cardPerformance }, i) => (
              <AttributionAnalyticsPanelExpandableCard
                key={i}
                attributeKey={key}
                displayedTitle={<Tooltip content={title}>{title}</Tooltip>}
                performanceBoostingTag={performanceBoostingTag}
                setPerformanceBoostingTag={setPerformanceBoostingTag}
                {...cardPerformance}
              >
                <PerformanceCard performance={cardPerformance} />
              </AttributionAnalyticsPanelExpandableCard>
            )
          )}
        </div>
      </div>
    </div>
  );
}
