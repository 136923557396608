import type { TextareaHTMLAttributes, KeyboardEvent } from 'react';
import React, { useRef, useEffect } from 'react';
import styles from './Textarea.module.scss';

type Props = {
  setValue: (value: string) => void;
  isAutoSelect?: boolean;
  lineBreakDisabled?: boolean;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

export function Textarea(props: Props) {
  const { setValue, value, isAutoSelect, lineBreakDisabled, ...textareaProps } = props;
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current && isAutoSelect) {
      textareaRef.current.select();
    }
  }, [textareaRef.current]);

  const textareaAttrs = Object.assign(
    textareaProps,
    lineBreakDisabled && {
      onKeyDown: (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      },
    }
  );

  return (
    <div className={styles.container}>
      <div className={styles.hiddenElement}>{value}</div>
      <textarea
        rows={1}
        cols={1}
        ref={textareaRef}
        autoFocus
        className={styles.textarea}
        value={value}
        onChange={event => setValue(event.target.value)}
        {...textareaAttrs}
      />
    </div>
  );
}
