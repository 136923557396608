import { selector } from 'recoil';
import { fetchGet, fetchPost } from '../utils/fetch';
import { Tag } from '../models/Tag';
import { AggregateTag } from '../models/AggregateTag';
import { tagTypesState } from './tagTypes';
import { authState, customerIdState } from './auth';
import appStyles from '../components/layout/App.module.scss';
import {
  comparisonPageComperableItemIdsState,
  comparisonPageComperableItemIdStateByIndex,
  getFilters as getComparisonPageFilters,
  getFiltersWithComparableItems as getComparisonPageFiltersWithBrands,
} from './comparisonPage';
import { productsState } from './products';
import { sortedFlattenTreeBrandingState } from './branding';
import { getAncestors } from 'utils/node';
import { iterator, map } from '@harmonya/utils';
import type {
  AggregateTag as AggregateTagModel,
  Tag as TagModel,
  AggregateTagWithRawValues,
} from '@harmonya/models';

export type Dimensions = { width: number; height: number };

export const tagsWidthCache = new Map<string, Dimensions>();

const getTagDimensions = (tag: { id: number; name: string }) => {
  const dimensions = tagsWidthCache.get(tag.name);

  if (!dimensions) {
    throw new Error(`Missing dimensions for tag ${tag.id}`);
  }

  return dimensions;
};

const setTagsDimensionsCache = (tags: { name: string }[]) => {
  const newTags = tags.filter?.(tag => !tagsWidthCache.has(tag.name)) ?? [];

  if (newTags.length) {
    const parentElement = document.createElement('div');

    parentElement.setAttribute('style', 'opacity: 0; position: fixed');

    const elements = newTags.map(tag => {
      const element = document.createElement('div');

      element.classList.add(appStyles.borderedTag, appStyles.color1);
      element.innerText = tag.name;

      parentElement.appendChild(element);

      return element;
    });

    document.body.appendChild(parentElement);

    elements.forEach((element, i) => {
      const { width, height } = element.getBoundingClientRect();
      const { name } = newTags[i];

      tagsWidthCache.set(name, { width, height });
    });

    parentElement.remove();
  }
};

export const tagsState = selector({
  key: 'tags',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: async ({ get }) => {
    const customerId = get(customerIdState);
    const { user } = get(authState);
    const tagTypes = get(tagTypesState);
    const tags = await fetchGet<TagModel[]>('/api/tags', customerId, user.email);

    setTagsDimensionsCache(tags);

    const tagEntries = tags.map(tag => {
      const dimensions = getTagDimensions(tag);
      const classedTag = new Tag(tag, tagTypes, dimensions);

      return [tag.id, classedTag] as const;
    });

    tagEntries.sort(
      ([, a], [, b]) => b.productsCount - a.productsCount || a.dominantType.id - b.dominantType.id
    );

    const tagsMap = new Map(tagEntries);

    return tagsMap;
  },
});

export const aggregateTagsByExploreState = selector({
  key: 'aggregateTagsByExplore',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const products = get(productsState);
    const { aggregatedTags: tags } = products.metadata ?? { aggregatedTags: [] };
    const tagTypes = get(tagTypesState);

    setTagsDimensionsCache(tags);

    const tagEntries = tags.map(tag => {
      const dimensions = getTagDimensions(tag);
      const classedTag = new AggregateTag(tag, tagTypes, dimensions);

      return [tag.id, classedTag] as const;
    });
    const tagsMap = new Map(tagEntries);

    return tagsMap;
  },
});

export const aggregateTagsByComparableItemsState = selector({
  key: 'aggregateTagsByQueryComparisonPage',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: async ({ get }) => {
    const tagTypes = get(tagTypesState);
    const filters = getComparisonPageFiltersWithBrands(get);
    const customerId = get(customerIdState);
    const { user } = get(authState);
    const rawTags = await fetchPost<AggregateTagModel[]>(
      '/api/aggregateTags',
      customerId,
      user.email,
      filters,
      undefined,
      undefined,
      'total'
    );

    const tags = Array.isArray(rawTags) ? rawTags : [];

    setTagsDimensionsCache(tags);

    const tagEntries = tags.map(tag => {
      const dimensions = getTagDimensions(tag);
      const classedTag = new AggregateTag(tag, tagTypes, dimensions);

      return [tag.id, classedTag] as const;
    });
    const tagsMap = new Map(tagEntries);

    return tagsMap;
  },
});

export const tagNamesState = selector({
  key: 'tagNames',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const tags = get(tagsState);
    const mappedTags: [number, string][] = [...tags.values()].map(({ id, name }) => [id, name]);
    const tagsMap = new Map(mappedTags);

    return tagsMap;
  },
});

export const tagHashesState = selector({
  key: 'tagHashesState',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const tags = get(tagsState);
    const tagHashes = new Map(Array.from(tags.values(), tag => [tag.id, tag.hash]));

    return tagHashes;
  },
});

// store each brands tag list in seperate selector
const comparisonTagItemTemplate = (index: number) =>
  selector({
    key: `brandTags${index}`,
    cachePolicy_UNSTABLE: { eviction: 'most-recent' },
    get: async ({ get }) => {
      const idState = comparisonPageComperableItemIdStateByIndex[index];
      const id = get(idState);
      const keyFilter = 'brandingIds';
      const customerId = get(customerIdState);
      const { user } = get(authState);
      const filters = getComparisonPageFilters(get);
      const body = {
        ...filters,
        [keyFilter]: [id],
      };
      const newTags = await fetchPost<AggregateTagWithRawValues[]>(
        '/api/aggregateTags',
        customerId,
        user.email,
        body,
        undefined,
        undefined,
        `item${index}`
      );

      return Array.isArray(newTags) ? newTags : [];
    },
  });
const comparisonTags0State = comparisonTagItemTemplate(0);
const comparisonTags1State = comparisonTagItemTemplate(1);
const comparisonTags2State = comparisonTagItemTemplate(2);
const comparisonTagsStates = [comparisonTags0State, comparisonTags1State, comparisonTags2State];

export const comparisonPageAggregateTagsState = selector({
  key: 'compareAggregateTags',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const keyIds = get<number[]>(comparisonPageComperableItemIdsState);
    const items = get(sortedFlattenTreeBrandingState);
    const tagTypes = get(tagTypesState);
    const majorToTags: { [set: number]: { [tagId: number]: AggregateTagWithRawValues } } = {};

    // the key ids that have ancestors in the keyIds list, meaning their sales and metrics are contained in the other items
    const hasAncestorInKeyIds = (keyId: number) => {
      const item = items.get(keyId);
      const ancestors = getAncestors(item, ({ parentId }) => map.nullableGet(items, parentId || 0));
      const includedInKeyIds = ancestors.some(ancestor => keyIds.includes(ancestor.id));

      return includedInKeyIds;
    };

    const containedIds = new Set(keyIds.filter(hasAncestorInKeyIds));
    const majorEntitiesTags = iterator.definedMap(keyIds, (id, i) => {
      const brandTagState = comparisonTagsStates[i];
      const tags = get(brandTagState);

      majorToTags[id] = Object.fromEntries(tags.map(tag => [tag.id, tag]));
      setTagsDimensionsCache(tags);

      const tagEntries = tags.map<[number, AggregateTag]>(({ ...tag }) => {
        const dimensions = getTagDimensions(tag);
        const classedTag = new AggregateTag(tag, tagTypes, dimensions);

        return [tag.id, classedTag];
      });
      const tagsMap = new Map(tagEntries);
      const item = items.get(id);

      if (!item) {
        throw new Error(`Brand ${id} is missing`);
      }

      return {
        name: item.name,
        compactPathParts: item.compactPathParts,
        fullPathParts: item.fullPathParts,
        sets: [i],
        itemIds: [id],
        allTags: [...tagsMap.values()],
        differentiatedTags: [],
      };
    });

    return { majorEntitiesTags, majorToTags, containedIds };
  },
});
