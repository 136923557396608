import type {
  ComparableItem,
  TabName,
} from 'components/layout/comparisonPage/comparison-page.types';
import { useSearchParam } from 'hooks/useSearchParam';
import { useRecoilState, useRecoilValue } from 'recoil';
import { comparisonExpandedSetByTabAndItem } from 'store/comparisonPage';
import { tagHashesState } from 'store/tags';
import { useComparableItems } from 'hooks/useComparableItems';
import { array, map } from '@harmonya/utils';

export type IsExpendedItemTags = { [itemId: string]: Set<number> };

export type IsExpendedMapper = { [tabName: string]: IsExpendedItemTags };

export function getSectionId(tagIds: number[]) {
  return tagIds.join('!');
}

const expandActionOptionsCommonSection = [
  { label: 'Expand All Entities', value: 'expand_all_items' },
  { label: 'Collapse All Entities', value: 'collapse_all_items' },
];
const expandActionOptionsMajorSection = [
  { label: 'Expand This Entity', value: 'expand_this_item' },
  { label: 'Collapse This Entity', value: 'collapse_this_item' },
  ...expandActionOptionsCommonSection,
  { label: 'Reset This Entity', value: 'reset_this_item' },
];

export function useExpandableTags(tab: TabName, differentiationMagnitude: number) {
  const [searchTags] = useSearchParam<string>('searchTags', { defaultValue: '' });
  const { comparableItems, hiddenTagHashes } = useComparableItems(differentiationMagnitude);
  const tagHashes = useRecoilValue(tagHashesState);
  const getTagList = (item: ComparableItem, group: TabName) =>
    group === 'All Tags' ? item.allTags : item.differentiatedTags;
  const hiddenTagHashesAsSet = new Set(hiddenTagHashes);
  const getFilteredTagList = (item: ComparableItem, group: TabName) =>
    getTagList(item, group)
      ?.filter(tag => !hiddenTagHashesAsSet.has(map.safeGet(tagHashes, tag.id)))
      .filter(tag => !searchTags || tag.name.toLowerCase().includes(searchTags.toLowerCase()));
  const [isExpandedSetByItem, setIsExpandedSetByItem] = useRecoilState(
    comparisonExpandedSetByTabAndItem
  );

  const changeItemExpand = (tabName: TabName, sectionId: string, tagId: number) => {
    const newIsExpandedSetByItem = {
      ...isExpandedSetByItem[tabName],
      [sectionId]: new Set(
        array.toggle([...(isExpandedSetByItem[tabName]?.[sectionId] ?? [])], tagId)
      ),
    };

    setIsExpandedSetByItem({ ...isExpandedSetByItem, [tabName]: newIsExpandedSetByItem });
  };

  const changeSectionExpanded = (tabName: TabName, sectionId: string, toExpand: boolean) => {
    const matchingItem = comparableItems.find(item => getSectionId(item.itemIds) === sectionId);

    if (matchingItem) {
      const newIsExpandedSetByItem = {
        ...isExpandedSetByItem[tabName],
        [getSectionId(matchingItem.itemIds)]: new Set<number>(
          toExpand ? getFilteredTagList(matchingItem, tab).map(({ id }) => id) : []
        ),
      };

      setIsExpandedSetByItem({ ...isExpandedSetByItem, [tabName]: newIsExpandedSetByItem });
    }
  };

  const changeAllSectionExpanded = (tabName: TabName, toExpand: boolean) => {
    const newIsExpandedSetByItem = {} as Record<string, Set<number>>;

    comparableItems?.forEach(item => {
      newIsExpandedSetByItem[getSectionId(item.itemIds)] = new Set<number>(
        toExpand ? item.allTags.map(({ id }) => id) : undefined
      );
    });

    setIsExpandedSetByItem({ ...isExpandedSetByItem, [tabName]: newIsExpandedSetByItem });
  };

  const activeExpandedSetByItem: IsExpendedItemTags = {};

  comparableItems.forEach(comparableItem => {
    const tagList = getFilteredTagList(comparableItem, tab);
    const activeTagIds = new Set(tagList.map(tag => tag.id));
    const isExpandedSet = isExpandedSetByItem[tab]?.[getSectionId(comparableItem.itemIds)];
    const activeExpendedTagIds = [...(isExpandedSet ?? [])].filter(id => activeTagIds.has(id));

    activeExpandedSetByItem[getSectionId(comparableItem.itemIds)] = new Set(activeExpendedTagIds);
  });

  const isAllExpanded = comparableItems.every(
    comparableItem =>
      getFilteredTagList(comparableItem, tab).length ===
      activeExpandedSetByItem[getSectionId(comparableItem.itemIds)].size
  );
  const isAllCollapsed = comparableItems.every(
    comparableItem => !activeExpandedSetByItem[getSectionId(comparableItem.itemIds)].size
  );

  return {
    searchTags,
    getTagList,
    getFilteredTagList,
    changeItemExpand,
    changeAllSectionExpanded,
    changeSectionExpanded,
    isAllExpanded,
    isAllCollapsed,
    activeExpandedSetByItem,
    expandActionOptionsCommonSection,
    expandActionOptionsMajorSection,
    getSectionId,
  };
}
