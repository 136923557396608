import React from 'react';
import { Icon, type IconWeight } from 'components/general/Icon';
import styles from './AttributionLegend.module.scss';
import attributionStyles from './AttributionPage.module.scss';
import { Divider } from 'components/general/Divider';
import {
  attributionActionIcons,
  attributionConfidenceLevelIcons,
  confidenceLevelTitle,
  sourceInputTitle,
  valueTypesTitle,
} from './attribution.utils';
import { AttributionLegendItem } from './AttributionLegendItem';

export function AttributionLegend() {
  const renderIconItems = (
    items: typeof attributionActionIcons | typeof attributionConfidenceLevelIcons,
    weight?: IconWeight
  ) =>
    Object.entries(items).map(([key, { name, title }]) => (
      <AttributionLegendItem key={key}>
        <Icon
          name={name}
          weight={weight}
          className={attributionStyles[key as keyof typeof attributionStyles]}
        />
        {title}
      </AttributionLegendItem>
    ));

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <AttributionLegendItem className={styles.title}>{valueTypesTitle}</AttributionLegendItem>
        <AttributionLegendItem className={styles.disabled}>
          {sourceInputTitle}
        </AttributionLegendItem>
        {renderIconItems(attributionActionIcons, 'regular')}
      </div>
      <Divider direction='horizontal' />
      <div className={styles.section}>
        <AttributionLegendItem className={styles.title}>
          {confidenceLevelTitle}
        </AttributionLegendItem>
        {renderIconItems(attributionConfidenceLevelIcons, 'solid')}
      </div>
    </div>
  );
}
