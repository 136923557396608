import React, { useState, type ReactNode } from 'react';
import styles from './AttributionPageChangesSummary.module.scss';
import appStyles from '../App.module.scss';
import { Icon } from 'components/general/Icon';
import classNames from 'classnames';
import { formatDateTime } from '@harmonya/utils';
import { join } from 'components/general/Divider';
import { SpinnerLoader } from 'components/general/SpinnerLoader';
import { triggerModelText } from './AttributionPage';
import { fetchPost } from 'utils/fetch';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authState, customerIdState } from 'store/auth';
import { toastMessageState } from 'store/toastMessage';
import { revisionNumberState, sheetIdState, versionNumberState } from 'store/attribution';

const fieldsChangesMockData = [
  {
    upc: `0003700023873`,
    username: 'Tal',
    brand: 'Brand',
    oldValue: 'Secret',
    newValue: 'Old Spice',
    date: new Date(),
  },
  {
    upc: `0003700023873`,
    username: 'Tal',
    brand: 'Sub Brand',
    oldValue: 'OS Red Collection',
    newValue: 'Secret Fresh',
    date: new Date(),
  },
  {
    upc: `0003700023873`,
    username: 'Tal',
    brand: 'Manufacturer',
    oldValue: 'PROCTER & GAMBLE',
    newValue: 'UNILEVER',
    date: new Date(),
  },
  {
    upc: `0003700023873`,
    username: 'Tal',
    brand: 'Segment',
    oldValue: 'TTL Energy DRINK',
    newValue: 'TTL SPORTS DRINK',
    date: new Date(),
  },
  {
    upc: `0003700023873`,
    username: 'Tal',
    brand: 'Sub Brand',
    oldValue: 'OS Red Collection',
    newValue: 'Secret Fresh',
    date: new Date(),
  },
  {
    upc: `0003700023873`,
    username: 'Tal',
    brand: 'Brand',
    oldValue: 'Secret',
    newValue: 'Old Spice',
    date: new Date(),
  },
  {
    upc: `0003700023873`,
    username: 'Tal',
    brand: 'Sub Brand',
    oldValue: 'OS Red Collection',
    newValue: 'Secret Fresh',
    date: new Date(),
  },
  {
    upc: `0003700023873`,
    username: 'Tal',
    brand: 'Manufacturer',
    oldValue: 'PROCTER & GAMBLE',
    newValue: 'UNILEVER',
    date: new Date(),
  },
  {
    upc: `0003700023873`,
    username: 'Tal',
    brand: 'Segment',
    oldValue: 'TTL Energy DRINK',
    newValue: 'TTL SPORTS DRINK',
    date: new Date(),
  },
  {
    upc: `0003700023873`,
    username: 'Tal',
    brand: 'Sub Brand',
    oldValue: 'OS Red Collection',
    newValue: 'Secret Fresh',
    date: new Date(),
  },
];

type Props = {
  editedUpcsCount: number;
  totalEditedUpcsCount: number;
  validatedCount: number;
  editedCount: number;
  onClose: () => void;
};

export function AttributionPageChangesSummary(props: Props) {
  const { editedUpcsCount, totalEditedUpcsCount, validatedCount, editedCount, onClose } = props;
  const [isLoading, setIsLoading] = useState(false);

  const versionNumber = useRecoilValue(versionNumberState);
  const revisionNumber = useRecoilValue(revisionNumberState);
  const sheetId = useRecoilValue(sheetIdState);
  const setToastMessage = useSetRecoilState(toastMessageState);
  const { user } = useRecoilValue(authState);
  const customerId = useRecoilValue(customerIdState);

  const triggerRetrainModel = async () => {
    const body = { sheetId, versionNumber, revisionNumber };
    const response = await fetchPost<{ isSuccess: boolean }>(
      '/api/attribution/commit',
      customerId,
      user.email,
      body
    );

    setToastMessage({
      type: response.isSuccess ? 'success' : 'error',
      text: response.isSuccess ? 'Model triggered successfully' : 'Model trigger failed',
    });
  };

  const triggerModel = async () => {
    try {
      setIsLoading(true);
      await triggerRetrainModel();
    } finally {
      onClose();
      setIsLoading(false);
    }
  };

  const summaryItems = new Map<string, ReactNode>([
    ["Edited UPC'S", `${editedUpcsCount}/${totalEditedUpcsCount}`],
    ['Validated', validatedCount],
    ['Edited', editedCount],
  ]);

  return (
    <div className={classNames(appStyles.verticalFlex, styles.container)}>
      <div className={classNames(appStyles.horizontalFlex, styles.summarySection)}>
        {join(
          Array.from(summaryItems, ([title, value]) => (
            <div className={classNames(appStyles.horizontalFlex)} key={title}>
              <div className={classNames(appStyles.verticalFlex, styles.summaryItem)}>
                <div className={styles.description}>{title}</div>
                <div className={styles.value}>{value}</div>
              </div>
            </div>
          ))
        )}
      </div>
      <div className={styles.changesSection}>
        <div className={styles.header}>
          <h3>Latest Changes ({fieldsChangesMockData.length})</h3>
          <button className={appStyles.buttonBorder}>View Changes History</button>
        </div>
        <div className={styles.latestItems}>
          {fieldsChangesMockData.map((item, i) => (
            <div key={i} className={styles.row}>
              <div className={styles.actorAvatar}>T</div>
              <div className={styles.cell}>{item.upc}</div>
              <div className={styles.cell}>{item.brand}</div>
              <div
                className={classNames(
                  appStyles.horizontalFlex,
                  styles.cell,
                  styles.normalFontStyle
                )}
              >
                <Icon name='circle-edit' className={styles.icon} /> Edited:
                <q>{item.oldValue}</q> to:
                <q>{item.newValue}</q>
              </div>
              <div
                className={classNames(
                  appStyles.horizontalFlex,
                  appStyles.justifyEnd,
                  styles.cell,
                  styles.normalFontStyle
                )}
              >
                {formatDateTime(item.date)}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={classNames(appStyles.horizontalFlex, appStyles.justifyEnd)}>
        <button className={appStyles.buttonSecondary} onClick={onClose}>
          Cancel
        </button>
        <button className={appStyles.buttonPrimary} onClick={triggerModel}>
          {isLoading && <SpinnerLoader />} {triggerModelText}
        </button>
      </div>
    </div>
  );
}
