import React from 'react';
import styles from './Title.module.scss';
import { styleVariables } from 'utils/styleVariables';
import classNames from 'classnames';
import { number } from '@harmonya/utils';

const padding = styleVariables.gap / 2;

type Props = {
  pathParts: string[];
  tagsCount: number;
  titleIcon?: string;
};

export function Title(props: Props) {
  const { pathParts, tagsCount, titleIcon } = props;

  return (
    <>
      {!!titleIcon && (
        <tspan dx={padding} className={styles.curvedTitleIcon}>
          {titleIcon}
        </tspan>
      )}
      {pathParts.map((text, index) => (
        <tspan
          key={text + index}
          dy={tagsCount && titleIcon ? 1 : ''}
          dx={titleIcon ? padding : ''}
          className={classNames(
            styles.curvedTitle,
            index < pathParts.length - 1 && styles.pathPart
          )}
        >
          {`${text} ${index < pathParts.length - 1 ? '> ' : ''}`}
        </tspan>
      ))}
      {!!tagsCount && (
        <>
          <tspan dx={padding} className={styles.curvedTitleIcon}>
            {styleVariables.iconTag}
          </tspan>
          <tspan dx={padding} className={styles.curvedTitleDetails}>
            {number.format(tagsCount)} Tags
          </tspan>
        </>
      )}
    </>
  );
}
