import { MarkedCellContext, type CellIdentifier } from './context/MarkedCellContext';
import type { Key } from 'hooks/useKey';
import { useKey } from 'hooks/useKey';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useContext, useEffect, useRef } from 'react';
import styles from './DataTableCell.module.scss';

export const useMarkedCellProps = (
  columnId: number,
  nextColumnId: number | undefined,
  prevColumnId: number | undefined,
  rowIndex: number,
  lastRowIndex: number
) => {
  const context = useContext(MarkedCellContext);

  if (!context) {
    return {};
  }

  const directions: Record<string, Partial<CellIdentifier> & { enabled: boolean; keys: Key[] }> = {
    up: {
      rowIndex: rowIndex - 1,
      enabled: rowIndex > 0,
      keys: ['ArrowUp'],
    },
    down: {
      rowIndex: rowIndex + 1,
      enabled: rowIndex < lastRowIndex,
      keys: ['ArrowDown', 'Enter'],
    },
    right: {
      ...(nextColumnId != null && { columnId: nextColumnId }),
      enabled: nextColumnId != null,
      keys: ['ArrowRight', 'Tab'],
    },
    left: {
      ...(prevColumnId != null && { columnId: prevColumnId }),
      enabled: prevColumnId != null,
      keys: ['ArrowLeft'],
    },
  };
  const cellIdentifier = { columnId, rowIndex };
  const [isMarked, setIsMarked] = useRecoilState(context.replaceMarkedCellState(cellIdentifier));
  const isTheOnlyOneMarked = useRecoilValue(context.isTheOnlyOneMarkedState(cellIdentifier));
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isTheOnlyOneMarked) {
      ref.current?.scrollIntoView({ block: 'nearest', inline: 'nearest' });
    }
  }, [isTheOnlyOneMarked]);

  const listeners = Object.values(directions).flatMap(direction => {
    const { enabled, keys, ...deltas } = direction;
    const newCellIdentifier = { ...cellIdentifier, ...deltas };
    const setIsAdjusentMarked = useSetRecoilState(
      context.replaceMarkedCellState(newCellIdentifier)
    );

    if (deltas && newCellIdentifier.columnId != null && newCellIdentifier.rowIndex >= 0) {
      const keyHandler = isMarked
        ? (event: KeyboardEvent) => {
            event.preventDefault();

            setIsAdjusentMarked(true);
          }
        : undefined;

      return keys.map(key => [key, keyHandler] as const);
    }

    return [];
  });

  useKey(listeners, [nextColumnId, prevColumnId, rowIndex, isMarked]);

  const markedCellProps = {
    className: isMarked && styles.marked,
    onClick: () => setIsMarked(true),
    onContextMenu: isMarked ? undefined : () => setIsMarked(true),
  };
  const markedCellComponentProps = {
    isMarked,
    onBlur: () => setIsMarked(false),
  };

  return { markedCellProps, markedCellComponentProps, ref };
};
