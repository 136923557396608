import type { CSSProperties, ForwardedRef, HTMLInputTypeAttribute } from 'react';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import styles from './AutoExpandableInput.module.scss';
import { useBoundingClientRect } from '../../hooks/useBoundingClientRect';
import { useIsFocused } from '../../hooks/useIsFocused';
import classNames from 'classnames';
import type { Stringable } from '@harmonya/utils';
import { isDate, number } from '@harmonya/utils';

type Props = {
  uncontrolled?: boolean;
  formattedValue?: Stringable;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export function setValue(
  value: string | number | readonly string[] | undefined,
  formattedValue: Stringable | undefined,
  comparisonEnabled: boolean,
  inputRef: React.RefObject<HTMLInputElement>,
  formattedValueDisplayed: boolean,
  type?: HTMLInputTypeAttribute
) {
  if (inputRef.current) {
    const element = inputRef.current;

    const setFormattedValue = (setterIfIsFocused: () => void) => {
      if (formattedValueDisplayed) {
        element.type = 'text';
        element.value = formattedValue?.toString() ?? '';
      } else {
        if (type) {
          element.type = type;
        }

        setterIfIsFocused();
      }
    };

    if (type === 'number' && value != null) {
      if (number.isNumber(value) && (!comparisonEnabled || element.valueAsNumber !== value)) {
        setFormattedValue(() => (element.valueAsNumber = value));
      }
    } else if (type === 'date') {
      if (isDate(value) && (!comparisonEnabled || element.valueAsDate !== value)) {
        setFormattedValue(() => (element.valueAsDate = value));
      }
    } else if (!comparisonEnabled || element.value !== value) {
      setFormattedValue(() => (element.value = value?.toString() ?? ''));
    }
  }
}

function RawAutoExpandableInput(props: Props, ref: ForwardedRef<HTMLInputElement>) {
  const { uncontrolled, formattedValue, value, defaultValue, style, ...inputProps } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const hiddenInputRef = useRef<HTMLInputElement>(null);
  const dimensions = useBoundingClientRect(hiddenInputRef, 'dimensions');
  const isFocused = useIsFocused(inputRef);
  const formattedValueDisplayed = !isFocused && formattedValue != null;
  const [hiddenInputStyle, setHiddenInputStyle] = useState<CSSProperties>({});
  const getValueProp = (
    key: keyof Pick<HTMLInputElement, 'defaultValue' | 'value'>,
    currentValue: string | number | readonly string[] | undefined
  ) => ({ [key]: (formattedValueDisplayed ? formattedValue?.toString() : currentValue) ?? '' });
  const valueProp = uncontrolled
    ? getValueProp('defaultValue', defaultValue)
    : getValueProp('value', value);

  useEffect(() => {
    if (inputRef.current) {
      const {
        font,
        fontFeatureSettings,
        fontKerning,
        fontOpticalSizing,
        fontSynthesis,
        fontVariationSettings,
        letterSpacing,
        wordSpacing,
        textTransform,
        padding,
      } = getComputedStyle(inputRef.current);
      const newHiddenInputStyle = {
        font,
        fontFeatureSettings,
        fontKerning,
        fontOpticalSizing,
        fontSynthesis,
        fontVariationSettings,
        letterSpacing,
        wordSpacing,
        textTransform,
        padding,
      };

      // We using type assertion Because 'fontKerning' and 'fontOpticalSizing' don't match their equivalent
      // properties in CSSProperties
      setHiddenInputStyle(newHiddenInputStyle as CSSProperties);
    }
  }, []);

  if (uncontrolled) {
    useEffect(() => {
      setValue(value, formattedValue, true, inputRef, formattedValueDisplayed, inputProps.type);
    }, [value]);
  }

  return (
    <>
      <div
        ref={hiddenInputRef}
        className={classNames(styles.hiddenInput, formattedValueDisplayed && styles.show)}
        style={{ ...hiddenInputStyle, ...style }}
      >
        {formattedValueDisplayed ? formattedValue.toString() : value}
      </div>
      <input
        {...inputProps}
        {...valueProp}
        ref={element => {
          inputRef.current = element;

          if (typeof ref === 'function') {
            ref(element);
          } else if (ref) {
            ref.current = element;
          }
        }}
        style={{ width: dimensions?.width, ...style }}
        className={classNames(inputProps.className, styles.input)}
      />
    </>
  );
}

export const AutoExpandableInput = forwardRef(RawAutoExpandableInput);
