import { Divider } from 'components/general/Divider';
import { Icon } from 'components/general/Icon';
import React from 'react';
import { useRecoilValue } from 'recoil';
import type { AttributeMetadata } from 'store/attribution.types';
import {
  attributionSortedColumnIncludesPinnedState,
  useAttributionHiddenColumns,
  useAttributionPinnedColumns,
  useAttributionSortedColumnReordererState,
} from 'store/attribution';
import styles from './AttributionDisplayDropdown.module.scss';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import type { OnDragEndResponder } from '@hello-pangea/dnd';
import { AnimatedIcon } from 'components/general/AnimatedIcon';
import classNames from 'classnames';
import { useBooleanState } from 'hooks/useBooleanState';

export function AttributionDisplayDropdown() {
  const changeAttributionMetadataOrder = useAttributionSortedColumnReordererState();
  const [, togglePinnedColumnKey, changePinnedColumnsOrder] = useAttributionPinnedColumns();
  const [hiddenColumnKeys, toggleHiddenColumnKey] = useAttributionHiddenColumns();
  const { pinnedColumns, unpinnedColumns } = useRecoilValue(
    attributionSortedColumnIncludesPinnedState
  );
  const [isDragging, drag, lift] = useBooleanState(false);

  const renderColumns = (columns: AttributeMetadata[], isPinned: boolean) => {
    const columnElements = columns.map((column, index) => {
      const { key, title } = column;
      const isHidden = hiddenColumnKeys.has(key);

      return (
        <Draggable key={key} draggableId={key.toString()} index={index}>
          {({ innerRef, draggableProps, dragHandleProps }) => (
            <div ref={innerRef} {...draggableProps} {...dragHandleProps} className={styles.column}>
              <Icon name='grip-dots-vertical' weight='regular' />
              <div className={styles.title}>{title}</div>
              <button className={styles.actionButton} onClick={() => togglePinnedColumnKey(key)}>
                <AnimatedIcon
                  isFirstActivated={isPinned}
                  firstIcon={{ name: 'thumbtack', weight: 'solid' }}
                  secondIcon={{ name: 'thumbtack', weight: 'light' }}
                />
              </button>
              <button className={styles.actionButton} onClick={() => toggleHiddenColumnKey(key)}>
                <AnimatedIcon
                  isFirstActivated={isHidden}
                  firstIcon={{ name: 'eye-slash' }}
                  secondIcon={{ name: 'eye' }}
                />
              </button>
            </div>
          )}
        </Draggable>
      );
    });

    return (
      <DragDropContext onDragEnd={handleDragEnd} onDragStart={drag}>
        <Droppable droppableId={isPinned.toString()} isCombineEnabled={false}>
          {({ innerRef, droppableProps, placeholder }) => (
            <div ref={innerRef} {...droppableProps} className={styles.section}>
              {columnElements}
              {placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const handleDragEnd: OnDragEndResponder = ({ destination, source, draggableId }) => {
    const columnId = +draggableId;

    if (destination) {
      const isPinnedDestination = destination.droppableId === 'true';
      const isDestionationChanged = destination.droppableId !== source.droppableId;

      if (isDestionationChanged) {
        /** @todo Fix the problem when dragging between pinned and unpinned */
        return;
      }

      if (isDestionationChanged) {
        togglePinnedColumnKey(columnId);
      }

      if (isDestionationChanged || destination.index !== source.index) {
        if (isPinnedDestination) {
          changePinnedColumnsOrder(columnId, destination.index);
        } else {
          changeAttributionMetadataOrder(columnId, destination.index);
        }
      }
    }
    lift();
  };

  return (
    <div className={classNames(styles.container, isDragging && styles.dragging)}>
      {renderColumns(pinnedColumns, true)}
      {pinnedColumns.length ? <Divider direction='horizontal' /> : null}
      {renderColumns(unpinnedColumns, false)}
    </div>
  );
}
