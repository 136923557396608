import React from 'react';
import { useResetRecoilState } from 'recoil';
import { useSearchParam } from 'hooks/useSearchParam';
import {
  explorePageBrandingIdsState,
  explorePageCategoryIdsState,
  explorePageMarketIdsState,
  explorePagePeriodIdsState,
} from 'store/explorePage';
import { useScopeFilters } from 'hooks/useScopeFilters';
import type { TagType } from '@harmonya/models';

type Props = {
  text: string;
  className: string;
  onReset?: () => void;
};

export function ExplorePageResetScopeFilters(props: Props) {
  const { text, className, onReset } = props;
  const [, setActiveTagTypeIds] = useSearchParam<TagType['id'][]>('activeTagTypeIds', {
    parser: 'number',
    defaultValue: [],
    isArray: true,
  });
  const scopeFiltersSelects = useScopeFilters(
    explorePageCategoryIdsState,
    explorePageMarketIdsState,
    explorePagePeriodIdsState,
    explorePageBrandingIdsState
  );
  const scopeFiltersSelectsResetters = scopeFiltersSelects.map(({ valuesRecoilState }) =>
    useResetRecoilState(valuesRecoilState)
  );

  const resetScopeFilters = () => {
    setActiveTagTypeIds([]);
    scopeFiltersSelectsResetters.forEach(resetter => resetter());
    onReset?.();
  };

  return (
    <button className={className} type='button' onClick={resetScopeFilters}>
      {text}
    </button>
  );
}
