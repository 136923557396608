import type { SortDirection } from '@harmonya/utils';
import { number, iterator, array } from '@harmonya/utils';
import classNames from 'classnames';
import { ButtonGroup } from 'components/general/ButtonGroup';
import { EmptyState } from 'components/general/EmptyState';
import type { SliderFilter } from 'components/general/sliderFilters/SliderFiltersDropdown';
import { useTagValueGetter } from 'hooks/useTagValueGetter';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { analyticsState } from 'store/analytics';
import { explorePageQueryState, explorePageSourcePresetState } from 'store/explorePage';
import { getColorizedTags } from 'utils/prioritizedTags';
import { formatters, isMissingValue } from 'utils/formatters';
import { ReactComponent as ExplorePageEmptyStatePlaceholder } from '../../../assets/emptyState/diamondChart.svg';
import { FilterItem } from '../../general/FilterItem';
import { useSearchParam } from '../../../hooks/useSearchParam';
import type { AggregateTag, TagValue } from '../../../models/AggregateTag';
import type { TagType } from '../../../models/TagType';
import {
  explorePageBrandingIdsState,
  explorePageGrowthPresetState,
} from '../../../store/explorePage';
import { tagTypesState } from '../../../store/tagTypes';
import { tooltipTexts } from '../../../tooltipTexts';
import { styleVariables } from '../../../utils/styleVariables';
import { HiddenTagsMenu } from '../../general/HiddenTagsMenu';
import { Icon } from '../../general/Icon';
import type { ComputedItem } from '../../general/diamondCloud/DiamondCloud';
import { DiamondCloud } from '../../general/diamondCloud/DiamondCloud';
import appStyles from '../App.module.scss';
import { ExplorePageResetScopeFilters } from './ExplorePageResetScopeFilters';
import styles from './ExplorePageTags.module.scss';
import {
  ExplorePageTagsDisplayOptions,
  maxTagsDisplayedOptions,
} from './ExplorePageTagsDisplayOptions';
import { TagTooltip } from 'components/general/TagTooltip';
import { tagHashesState } from 'store/tags';
import { settingsState } from 'store/settings';
import { sortedDisplayedTagsState } from 'store/sortedDisplayedTags';
import { queryToSets, setsToQuery } from '../../../functions';
import type { TokenId } from '@harmonya/models';

const getColorId = (item: ComputedItem<AggregateTag>) => item.source.dominantType.colorId;
const getSubtext = (item: ComputedItem<AggregateTag>) => item.source.dominantType.name;

const tagValueDisplayValueGetter = (tagValue: TagValue, value: number) => {
  const formattedValue = formatters[tagValue].valueGetter(value);

  switch (tagValue) {
    case 'productsCount':
      return (
        <>
          <Icon className={styles.tagValueIcon} weight='light' {...formatters.productsCount.icon} />
          {formattedValue}
        </>
      );
    case 'salesGrowth':
      return (
        <>
          {formattedValue}
          {!isMissingValue(value) && (
            <Icon
              className={styles.tagValueIcon}
              weight='light'
              name={value < 0 ? 'angles-down' : 'angles-up'}
            />
          )}
        </>
      );
    case 'reviewProductsRatio':
      return (
        <>
          <Icon
            className={styles.tagValueIcon}
            weight='light'
            {...formatters.reviewProductsRatio.icon}
          />
          {formattedValue}
        </>
      );
    case 'listingProductsRatio':
      return (
        <>
          <Icon
            className={styles.tagValueIcon}
            weight='light'
            {...formatters.listingProductsRatio.icon}
          />
          {formattedValue}
        </>
      );
    default:
      return formattedValue;
  }
};

const getTagSortingFunction =
  (tagValueGetter: (item: AggregateTag) => number | null, sortDirection: SortDirection) =>
  (a: AggregateTag, b: AggregateTag) => {
    const direction = sortDirection === 'desc' ? 1 : -1;

    return direction * ((tagValueGetter(b) ?? -Infinity) - (tagValueGetter(a) ?? -Infinity));
  };

type Props = {
  activeTagTypeIds: Set<TagType['id']>;
  tags: Iterable<AggregateTag>;
  totalTagsCount: number;
  sliderFilters: SliderFilter<AggregateTag>[];
  resetMetricFilters: () => void;
};

export const ExplorePageTags = memo(function ExplorePageTags(props: Props) {
  const setSortedDisplayedTagsState = useSetRecoilState(sortedDisplayedTagsState);
  const { activeTagTypeIds, tags, sliderFilters, resetMetricFilters, totalTagsCount } = props;
  const [query, setQuery] = useRecoilState(explorePageQueryState);
  const settings = useRecoilValue(settingsState);
  const tagHashes = useRecoilValue(tagHashesState);
  const tagTypes = useRecoilValue(tagTypesState);
  const explorePageBrandingIds = useRecoilValue(explorePageBrandingIdsState);
  const [maxTagsDisplayed] = useSearchParam('maxTagsDisplayed', {
    defaultValue: maxTagsDisplayedOptions[0].value,
    parser: 'number',
  });
  const brandSignificanceDisabled = !explorePageBrandingIds.length;
  const [tagValueProp, setTagValueProp] = useSearchParam<TagValue>('tagValueProp', {
    defaultValue: settings.defaults.explorePage.searchParams.tagValueProp,
  });
  const [sortDirection, setSortDirection] = useSearchParam<SortDirection>('diamondChartSort', {
    defaultValue: settings.defaults.explorePage.searchParams.diamondChartSort,
  });
  const [hiddenTagIds, setHiddenTagIds] = useSearchParam<number[]>('hiddenTagIds', {
    defaultValue: [],
    parser: 'number',
  });
  const hiddenTagHashesAsSet = new Set(iterator.definedMap(hiddenTagIds, id => tagHashes.get(id)));
  const [hiddenTags, displayedTags] = useMemo(
    () =>
      iterator.split(tags, tag => {
        const hash = tagHashes.get(tag.id);
        return !hash || hiddenTagHashesAsSet.has(hash);
      }),
    [tags, hiddenTagIds]
  );
  const tagValueGetter = useTagValueGetter(tagValueProp);
  const sortedTags = useMemo(
    () => [...displayedTags].sort(getTagSortingFunction(tagValueGetter, sortDirection)),
    [displayedTags, tagValueGetter, sortDirection]
  );

  useEffect(() => setSortedDisplayedTagsState(sortedTags), [sortedTags]);

  const sortedTopTags = sortedTags.slice(0, maxTagsDisplayed);
  const colorizedTopTags = getColorizedTags(sortedTopTags, tagTypes, activeTagTypeIds);
  const clearHiddenTags = () => setHiddenTagIds([]);
  const hasTags = !!sortedTopTags?.length;
  const resetGrowthPreset = useResetRecoilState(explorePageGrowthPresetState);
  const resetSourcePreset = useResetRecoilState(explorePageSourcePresetState);
  const analytics = useRecoilValue(analyticsState);
  const addTagToQuery = useCallback(
    (tag: AggregateTag) => {
      const querySets = query ? queryToSets(query) : [new Set<TokenId>()];

      querySets.forEach(set => set.add(tag.id));

      const newQuery = setsToQuery(querySets);

      setQuery(newQuery);

      analytics.track('Diamond tag clicked', {
        name: 'Diamond Chart',
        type: 'tag',
        value: { id: tag.id, name: tag.name },
      });
    },
    [query]
  );

  const resetPresets = () => {
    resetGrowthPreset();
    resetSourcePreset();
  };

  const toggleHiddenTag = useCallback(
    (tagId: AggregateTag['id']) => {
      if (tagId != null) {
        const newHiddenTagIds = array.toggle(hiddenTagIds, tagId);

        setHiddenTagIds(newHiddenTagIds);
      }
    },
    [hiddenTagIds]
  );
  const tooltipContentGetter = (item: ComputedItem<AggregateTag>) => (
    <TagTooltip key={item.source.id} tag={item.source} hideTag={toggleHiddenTag} />
  );

  useEffect(() => {
    if (brandSignificanceDisabled && tagValueProp === 'brandSignificance') {
      setTagValueProp('productsCount');
    }
  }, [explorePageBrandingIds.length]);

  const changeSortDirection = (direction: SortDirection) => {
    if (direction !== sortDirection) {
      setSortDirection(direction);
      resetPresets();
    }
  };

  const colorizedTopTagsMemoKey = colorizedTopTags
    .map(tag => `${tag.id}:${tag.dominantType.id}`)
    .join();

  const diamondCloud = useMemo(
    () =>
      hasTags && (
        <DiamondCloud
          items={colorizedTopTags}
          valueGetter={tag => tagValueGetter(tag)}
          sizesCount={3}
          sizeIncrementFactor={2}
          gap={styleVariables.gap / 2}
          rotateDegrees={45}
          keyGetter={(name, index) => index}
          tooltipContentGetter={tooltipContentGetter}
          onItemClick={item => addTagToQuery(item.source)}
          getColorId={getColorId}
          getSubtext={getSubtext}
          displayValueGetter={item => tagValueDisplayValueGetter(tagValueProp, item.value)}
        />
      ),
    [colorizedTopTagsMemoKey, tagValueGetter, hiddenTagIds, addTagToQuery, tagValueProp, hasTags]
  );

  return (
    <div className={classNames(appStyles.verticalFlex, appStyles.flexGrow1)}>
      <div
        className={classNames(
          appStyles.horizontalFlex,
          appStyles.justifySpaceBetween,
          appStyles.alignCenter
        )}
      >
        <h5>{`Tags ${number.format(colorizedTopTags.length)} / ${number.format(totalTagsCount)}`}</h5>
        <div className={classNames(appStyles.horizontalFlex, styles.filterRow)}>
          <ExplorePageTagsDisplayOptions
            buttonTitle='Display'
            resetPresets={resetPresets}
            buttonIconName='bars-filter'
            className={classNames(appStyles.filter, styles.filterButton)}
          />
          <ButtonGroup
            className={styles.sortDirectionButtonGroup}
            selectedValue={sortDirection}
            onClick={changeSortDirection}
          >
            {[
              {
                value: 'desc',
                element: <Icon name='arrow-down-wide-short' weight='light' />,
                tooltip: tooltipTexts.common.descSort,
              },
              {
                value: 'asc',
                element: <Icon name='arrow-up-short-wide' weight='light' />,
                tooltip: tooltipTexts.common.ascSort,
              },
            ]}
          </ButtonGroup>
        </div>
      </div>
      <div className={appStyles.horizontalFlex}>
        {iterator.definedMap(
          sliderFilters,
          ({ isFilteredItem, title, textValue, reset, icon }) =>
            isFilteredItem &&
            icon && <FilterItem key={title} value={textValue} onFilterClick={reset} {...icon} />
        )}
      </div>
      {hasTags ? (
        <>
          {diamondCloud}
          <HiddenTagsMenu
            hiddenTags={hiddenTags}
            toggleHiddenTag={toggleHiddenTag}
            clearHiddenTags={clearHiddenTags}
          />
        </>
      ) : (
        <div
          className={classNames(
            appStyles.verticalFlex,
            appStyles.alignCenter,
            appStyles.justifyCenter,
            appStyles.flexGrow1
          )}
        >
          <EmptyState
            image={<ExplorePageEmptyStatePlaceholder />}
            message={
              <>
                <span>We couldn&apos;t find any Tags that match your search</span>
                <span>Try adjusting the filters or the query</span>
              </>
            }
          />
          <ExplorePageResetScopeFilters
            text='Reset'
            className={appStyles.resetButton}
            onReset={resetMetricFilters}
          />
        </div>
      )}
    </div>
  );
});
