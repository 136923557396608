import React from 'react';
import analyticsStyles from '../AttributionAnalyticsPanel.module.scss';
import analyticsCardPerformanceStyles from './AttributionAnalyticsPanelPerformanceCard.module.scss';
import styles from './AttributionAnalyticsPanelCardPredictions.module.scss';
import { TrafficLightsStackedProgressBar } from 'components/general/TrafficLightsStackedProgressBar';
import {
  analyticsPanelPredictionIcons,
  attributionConfidenceLevelIcons,
} from '../../attribution.utils';
import { Icon } from 'components/general/Icon';
import { number } from '@harmonya/utils';
import classNames from 'classnames';
import type { AttributePredictions } from 'store/attribution';

type Props = AttributePredictions;

export function AttributionAnalyticsPanelCardPredictions(props: Props) {
  const { highLevelPrediction, mediumLevelPrediction, lowLevelPrediction } = props;

  const displayValueGetter = (key: keyof Props) => {
    const value = props[key];

    return number.singleDigitFractionPercent(value);
  };

  return (
    <div className={analyticsCardPerformanceStyles.cardSectionContainer}>
      <h4 className={analyticsStyles.panelTitle}>Predictions By Confidence Level</h4>
      <TrafficLightsStackedProgressBar
        success={highLevelPrediction}
        warning={mediumLevelPrediction}
        error={lowLevelPrediction}
      />
      <div className={styles.legend}>
        {analyticsPanelPredictionIcons.map(key => {
          const { name, title, className } = attributionConfidenceLevelIcons[key];

          return (
            <div key={key}>
              <Icon name={name} weight='solid' className={classNames(styles.icon, className)} />
              <h6 className={analyticsStyles.panelTitle}>{title}</h6>
              <h4>{displayValueGetter(`${key}LevelPrediction`)}</h4>
            </div>
          );
        })}
      </div>
    </div>
  );
}
