import React, { useMemo, type ReactNode } from 'react';
import styles from './AttributionHeaderCellFiltersFilterSection.module.scss';
import { IconsBox } from 'components/general/IconsBox';
import type { Filter } from 'store/attribution.types';
import { useColumnFilter } from 'store/attributionPage';
import { useRecoilValue, type RecoilState } from 'recoil';
import { attributionDataState } from 'store/attributionState';
import { iterator, number } from '@harmonya/utils';
import { AttributionHeaderCellFiltersSection } from './AttributionHeaderCellFiltersSection';

export type FilterSection<T extends string> = {
  columnId: number;
  options: Map<T, Filter>;
  setFilterState: (param: number) => RecoilState<Set<T>>;
  title?: string;
  labelFormatter?: (value: T) => ReactNode;
  children?: (counts: Record<T, number>) => ReactNode;
  scrollbarDisplayed?: boolean;
  sortByCountEnabled?: boolean;
  isGroupedFilter?: boolean;
};

type Props<T extends string> = FilterSection<T>;

const iconsBoxIconNames = ['check', 'hyphen'] as const;
const defaultLabelFormatter = (value: string) => value;

export function AttributionHeaderCellFiltersFilterSection<T extends string>(props: Props<T>) {
  const {
    columnId,
    options,
    setFilterState,
    title,
    labelFormatter = defaultLabelFormatter,
    children,
    scrollbarDisplayed,
    sortByCountEnabled,
    isGroupedFilter,
  } = props;
  const [selectedStatuses, toggleStatus, resetStatuses, setValues] = useColumnFilter(
    columnId,
    setFilterState
  );
  const { productsAttributes } = useRecoilValue(attributionDataState);
  const counts = useMemo(
    () =>
      Object.fromEntries(
        Array.from(options, ([key, filter]) => {
          const count = iterator.count(productsAttributes.values(), productAttributes =>
            filter(productAttributes[columnId])
          );

          return [key, count];
        })
      ) as Record<T, number>,
    [productsAttributes, columnId, options]
  );
  const optionsKeysSortedByCount = [...options.keys()];

  if (sortByCountEnabled) {
    const hasDifferentCounts = new Set(Object.values(counts)).size > 1;

    if (hasDifferentCounts) {
      optionsKeysSortedByCount.sort((a, b) => counts[b] - counts[a]);
    }
  }

  const optionsElements = optionsKeysSortedByCount.map(key => {
    return (
      <div key={key} className={styles.item} onClick={() => toggleStatus(key)}>
        <IconsBox
          activeIconName={selectedStatuses.has(key) ? 'check' : undefined}
          iconNames={iconsBoxIconNames}
        />
        <div className={styles.label}>{labelFormatter(key)}</div>
        <div>{number.format(counts[key])}</div>
      </div>
    );
  });

  const selectAll = () => setValues(new Set(optionsKeysSortedByCount));

  const resetButton = (
    <button className={styles.button} onClick={resetStatuses}>
      Clear All
    </button>
  );

  const additionalContent = title ? (
    resetButton
  ) : (
    <div className={styles.additionalContent}>
      <button className={styles.button} onClick={selectAll}>
        Select All
      </button>
      {resetButton}
    </div>
  );

  return (
    <AttributionHeaderCellFiltersSection
      title={title}
      headerAdditionalContent={additionalContent}
      scrollbarDisplayed={scrollbarDisplayed}
      isGroupedFilter={isGroupedFilter}
    >
      {children && children(counts)}
      {optionsElements}
      {!optionsElements.length && <div className={styles.notFound}>No matches found</div>}
    </AttributionHeaderCellFiltersSection>
  );
}
