import React, { memo } from 'react';
import styles from './PercentageProgressBar.module.scss';
import { StackedProgressBar } from './StackedProgressBar';
import classNames from 'classnames';
import { number } from '@harmonya/utils';
import { styleVariables } from 'utils/styleVariables';

type Props = {
  value: number;
  className?: string;
  percentageClassName?: string;
};

function* generateValues(value: number) {
  yield [value, styleVariables.successColor] as const;

  // The purpose of this is to fill in the remaining space with a color that is not the successColor
  yield [1 - value] as const;
}

export const PercentageProgressBar = memo(function PercentageProgressBar(props: Props) {
  const { value, className, percentageClassName } = props;

  return (
    <div className={classNames(styles.container, className)}>
      <span className={classNames(styles.percentage, percentageClassName)}>
        {number.singleDigitFractionPercent(value)}
      </span>
      <StackedProgressBar values={generateValues(value)} />
    </div>
  );
});
