import type { map } from '@harmonya/utils';
import { useEffect, useState } from 'react';
import type { SetterOrUpdater } from 'recoil';
import { DefaultValue, useRecoilState, useRecoilValue } from 'recoil';
import {
  attributionPerformanceBoostingSortPropertyState,
  attributionPerformanceBoostingTagState,
  hiddenColumnKeysState,
  pinnedColumnKeysState,
} from 'store/attribution';
import type { ColumnId, SortSettings } from 'store/attribution.types';
import type {
  cellConfidenceLevelFilters,
  cellStatusFilters,
  cellValidationFilters,
} from 'store/attributionPage';
import {
  columnsCellConfidenceLevelFilterState,
  columnsCellOptionsFilterState,
  columnsCellStatusFilterState,
  columnsCellValidationFilterState,
  defaultSortSettings,
  sortState,
} from 'store/attributionPage';
import { attributionDataState } from 'store/attributionState';

type PreviousTableConfiguration = {
  previousPinnedColumns: Set<number>;
  previousHiddenColumns: Set<number>;
  previousFiltersStates: { state: [unknown, SetterOrUpdater<unknown>]; prevValue: unknown }[];
  previousSort: SortSettings;
};

type FilterState = [unknown, SetterOrUpdater<unknown>];

export function usePerformanceBoosting() {
  const [performanceBoostingTag, setPerformanceBoostingTag] = useRecoilState(
    attributionPerformanceBoostingTagState
  );
  const { attributesMetadata } = useRecoilValue(attributionDataState);
  const performanceBoostingSortProperty = useRecoilValue(
    attributionPerformanceBoostingSortPropertyState
  );
  const [pinnedColumnKeys, setPinnedColumnKeys] = useRecoilState(pinnedColumnKeysState);
  const [hiddenColumnKeys, setHiddenColumnKeys] = useRecoilState(hiddenColumnKeysState);
  const [sortSettings, setSortSettings] = useRecoilState(sortState);
  const filterStatesMap = new Map([
    ['status', useRecoilState(columnsCellStatusFilterState) as FilterState],
    ['validation', useRecoilState(columnsCellValidationFilterState) as FilterState],
    ['confidenceLevel', useRecoilState(columnsCellConfidenceLevelFilterState) as FilterState],
    ['options', useRecoilState(columnsCellOptionsFilterState) as FilterState],
  ]);

  const [previousPerformanceBoostingTag, setPreviousPerformanceBoostingTag] = useState<
    number | undefined
  >(undefined);
  const [previousTableConfigurationState, setPreviousTableConfigurationState] =
    useState<PreviousTableConfiguration>({
      previousPinnedColumns: new Set(),
      previousHiddenColumns: new Set(),
      previousFiltersStates: Array.from(filterStatesMap, ([, state]) => ({
        state,
        prevValue: new Map(),
      })),
      previousSort: defaultSortSettings,
    });

  const setPreviousToCurrentTableConfiguration = () => {
    setPreviousTableConfigurationState({
      previousPinnedColumns: pinnedColumnKeys,
      previousHiddenColumns: hiddenColumnKeys,
      previousSort: sortSettings,
      previousFiltersStates: Array.from(filterStatesMap, ([, state]) => ({
        state,
        prevValue: state[0],
      })),
    });
  };

  const setCurrentToPreviousFilters = () => {
    const { previousFiltersStates } = previousTableConfigurationState;
    for (const { state, prevValue } of previousFiltersStates) {
      const [, setFilterState] = state;
      setFilterState(prevValue);
    }
  };

  const resetAllFilters = () => {
    const { previousFiltersStates } = previousTableConfigurationState;
    for (const { state } of previousFiltersStates) {
      const [, setFilterState] = state;
      setFilterState(new DefaultValue());
    }
  };

  const updateColumnFilter = (filter: string, value: Map<ColumnId, unknown>) => {
    const [, setFilter] = filterStatesMap.get(filter) || [];

    if (setFilter) {
      setFilter(value);
    }
  };

  useEffect(() => {
    const { previousPinnedColumns, previousHiddenColumns, previousSort } =
      previousTableConfigurationState;
    const salesColumn = attributesMetadata.find(item => item.type === 'sales');
    const newSortSettings: SortSettings | undefined = salesColumn
      ? { columnId: salesColumn.key, direction: 'desc', option: 'Sales' }
      : undefined;
    const isReset = performanceBoostingTag === undefined;

    if (isReset) {
      setCurrentToPreviousFilters();
    } else {
      if (previousPerformanceBoostingTag === undefined) {
        setPreviousToCurrentTableConfiguration();
      }

      resetAllFilters();

      if (performanceBoostingSortProperty === 'accuracy') {
        updateColumnFilter(
          'confidenceLevel',
          new Map<ColumnId, Set<map.Key<typeof cellConfidenceLevelFilters>>>([
            [performanceBoostingTag, new Set(['low'])],
          ])
        );
        updateColumnFilter(
          'status',
          new Map<ColumnId, Set<map.Key<typeof cellStatusFilters>>>([
            [performanceBoostingTag, new Set(['Predicted', 'Not Predicted'])],
          ])
        );
        updateColumnFilter(
          'validation',
          new Map<ColumnId, Set<map.Key<typeof cellValidationFilters>>>([
            [performanceBoostingTag, new Set(['Not Validated'])],
          ])
        );
      } else if (performanceBoostingSortProperty === 'coverage') {
        updateColumnFilter(
          'status',
          new Map<ColumnId, Set<map.Key<typeof cellStatusFilters>>>([
            [performanceBoostingTag, new Set(['Not Predicted'])],
          ])
        );
      }
    }

    setPinnedColumnKeys(isReset ? previousPinnedColumns : new Set([performanceBoostingTag]));
    setHiddenColumnKeys(isReset ? previousHiddenColumns : new Set<number>());
    setSortSettings(isReset || !newSortSettings ? previousSort : newSortSettings);
    setPreviousPerformanceBoostingTag(performanceBoostingTag);
  }, [performanceBoostingTag, performanceBoostingSortProperty]);

  return [performanceBoostingTag, setPerformanceBoostingTag] as const;
}

export const usePerformanceBoostingTagTitle = () => {
  const [performanceBoostingTag] = useRecoilState(attributionPerformanceBoostingTagState);
  const { attributesMetadata } = useRecoilValue(attributionDataState);

  if (performanceBoostingTag) {
    const performanceBoostingTagAttribute = attributesMetadata.find(
      item => item.key === performanceBoostingTag
    );
    if (!performanceBoostingTagAttribute) {
      console.warn(
        `Attribute was not found for the ${performanceBoostingTag} performance boosting tag`
      );
    }
    return performanceBoostingTagAttribute?.title;
  }
};
