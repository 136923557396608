import type { ErrorInfo } from 'react';
import React, { Suspense, useRef, useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Page } from '../Page';
import { Loader } from '../../general/Loader';
import { ComparisonPageTypeFilter } from './ComparisonPageTypeFilter';
import { ComparisonPageDiagramChart } from './ComparisonPageDiagramChart';
import { ComparisonPageDiagramDetails } from './ComparisonPageDiagramDetails';
import { useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil';
import { useComparableItems } from 'hooks/useComparableItems';
import appStyles from '../App.module.scss';
import { ComparisonPageFilters } from './ComparisonPageFilters';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'components/general/ErrorFallback';
import { logger } from 'utils/logger';
import { useSliderFilters } from 'hooks/useSliderFilters';
import styles from './ComparisonPage.module.scss';
import { Icon } from 'components/general/Icon';
import { useExpander } from 'hooks/useExpander';
import explorePageProductsTableStyles from '../explorePage/ExplorePageProductsTable.module.scss';
import { Input } from 'components/general/Input';
import { useSearchParam } from 'hooks/useSearchParam';
import { Slider } from 'components/general/Slider';
import trendsPageTrendsStyles from '../trendsPage/TrendsPageTrends.module.scss';
import { Select } from 'components/general/select/Select';
import { formatters } from 'utils/formatters';
import {
  comparisonPageComperableItemIdsState,
  comparisonPageSortFieldState,
} from 'store/comparisonPage';
import type { AggregateTag } from 'models/AggregateTag';
import { GrowthPointSelect } from 'components/general/GrowthPointSelect';
import { ButtonGroup } from 'components/general/ButtonGroup';
import { tooltipTexts } from 'tooltipTexts';
import { Tooltip } from 'components/general/Tooltip';
import { useResize } from 'hooks/useResize';
import { styleVariables } from 'utils/styleVariables';
import { settingsState } from 'store/settings';
import { analyticsState } from 'store/analytics';
import { ComparisonPageExportButton } from './ComparisonPageExportButton';
import { differentiationMagnitudeProp } from 'components/layout/comparisonPage/comparison-page.constants';

const [expanderMinLeft, expanderMinRight] = [550, 420];
const expanderInitialLeftWidth = `calc(100% - ${expanderMinRight}px)`;
const expanderLeftOffset = 100;
const sortFieldOptions: {
  value: keyof Omit<AggregateTag['values'], 'brandsCount'>;
  displayValue: string;
}[] = [
  { value: 'totalSales', displayValue: formatters.totalSales.title },
  { value: 'salesGrowth', displayValue: formatters.salesGrowth.title },
  { value: 'productsCount', displayValue: 'Number of Products' },
];

export function ComparisonPage() {
  const [sortField, setSortField] = useRecoilState(comparisonPageSortFieldState);
  const [resizeIndicator, setResizeIndicator] = useState(0);
  const resetSortField = useResetRecoilState(comparisonPageSortFieldState);
  const resetComperableItemIds = useResetRecoilState(comparisonPageComperableItemIdsState);
  const analytics = useRecoilValue(analyticsState);
  const onSortChange = useCallback((value: keyof Omit<AggregateTag['values'], 'brandsCount'>) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    value ? setSortField(value) : resetSortField();
  }, []);
  const [focusIndex, setFocusIndex] = useState<number | undefined>(undefined);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerDimensions = useResize(containerRef);
  const settings = useRecoilValue(settingsState);
  const { initialDifferentiationMagnitude } = settings;
  const differentiationMagnitudeSliderFilters = useSliderFilters(
    [initialDifferentiationMagnitude],
    () => value => value,
    [
      {
        title: 'Differentiation magnitude',
        prop: differentiationMagnitudeProp,
        isSingle: true,
        precision: 0.1,
      },
    ]
  );
  const [searchTags, setSearchTags] = useSearchParam<string>('searchTags', { defaultValue: '' });
  const [firstSlider] = differentiationMagnitudeSliderFilters.sliderFilters;
  const [differentiationMagnitude = initialDifferentiationMagnitude] = firstSlider.value;
  const {
    comparableItems,
    setActiveTagTypeIds,
    rawSliderFilters,
    minDifferentiator,
    maxDifferentiator,
    sortDirection,
    sliderFilterFunc,
    setSortDirection,
  } = useComparableItems(differentiationMagnitude);
  const isDifferentiationMagnitudeDisabled =
    comparableItems.filter(item => item.allTags.length).length < 2;

  firstSlider.minValue = minDifferentiator;
  firstSlider.maxValue = maxDifferentiator;
  firstSlider.value =
    !isDifferentiationMagnitudeDisabled && minDifferentiator === Infinity
      ? [1, 1]
      : firstSlider.value;

  const containerBounding = containerRef?.current?.getBoundingClientRect();
  const { expanderRef, leftWidth, rightWidth, handleDragStart } = useExpander<HTMLDivElement>(
    expanderInitialLeftWidth,
    expanderLeftOffset,
    containerBounding?.x,
    containerBounding?.width,
    containerBounding?.height,
    expanderMinLeft,
    expanderMinRight
  );
  const logError = (error: Error, info: ErrorInfo) =>
    logger(error.message, error.stack, info.componentStack);

  const reset = () => {
    differentiationMagnitudeSliderFilters.reset();
    resetComperableItemIds();
    analytics.track('Reset Entities', { name: 'Reset all', type: 'button' });
  };

  useEffect(() => {
    setTimeout(() => {
      const epsilon = Math.random() / 1000;

      setResizeIndicator(epsilon);
    }, styleVariables.animationDuration);
  }, [containerDimensions]);

  if (!comparableItems) {
    return <Loader />;
  }

  const trackAndSetSearchTags = (value: string) => {
    setSearchTags(value);
    analytics.track('Search tags', { name: 'search', value });
  };

  return (
    <Suspense fallback={<Loader textDisplayed />}>
      <Page
        title='COMPARE'
        headerChildren={
          <ComparisonPageExportButton differentiationMagnitude={differentiationMagnitude} />
        }
      >
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
          <div className={appStyles.horizontalFlex}>
            <ComparisonPageFilters {...rawSliderFilters} />
            <div
              className={classNames(
                appStyles.box,
                appStyles.shadowed,
                appStyles.backgroundBackground,
                appStyles.horizontalFlex,
                appStyles.alignCenter,
                appStyles.justifySpaceBetween
              )}
            >
              <GrowthPointSelect />
            </div>
          </div>
          <ComparisonPageTypeFilter
            onChange={setActiveTagTypeIds}
            sliderFilterFunc={sliderFilterFunc}
          />
          <section
            ref={containerRef}
            className={classNames(
              appStyles.box,
              appStyles.verticalFlex,
              appStyles.flexGrow1,
              appStyles.overflowHidden,
              appStyles.backgroundGrid,
              appStyles.shadowed
            )}
          >
            <div className={classNames(appStyles.horizontalFlex, appStyles.justifySpaceBetween)}>
              <div className={classNames(appStyles.horizontalFlex, appStyles.alignCenter)}>
                <div
                  className={classNames(
                    appStyles.borderedBox,
                    appStyles.horizontalFlex,
                    styles.displayOptionsContainer
                  )}
                >
                  <Tooltip
                    content={
                      <span className={styles.tooltipContent}>
                        {tooltipTexts.comparisonPage.diffrentiationMagnitudeComponent}
                      </span>
                    }
                  >
                    <div
                      className={classNames(
                        appStyles.alignCenter,
                        trendsPageTrendsStyles.headerSection,
                        appStyles.horizontalFlex
                      )}
                    >
                      <h5>Differentiation magnitude</h5>
                      <span
                        className={classNames(
                          styles.differentiatedMagnitudeValueContainer,
                          appStyles.alignCenter,
                          appStyles.horizontalFlex
                        )}
                      >
                        <h5>X</h5>
                        <Slider
                          analyticsEventTitle='Differentiation magnitude'
                          value={firstSlider.value}
                          formatter={firstSlider.formatter}
                          onChange={firstSlider.handleChange}
                          min={firstSlider.minValue}
                          max={firstSlider.maxValue}
                          step={firstSlider.step}
                          isPercent={false}
                          isSingle={firstSlider.isSingle}
                          isDisabled={
                            isDifferentiationMagnitudeDisabled || minDifferentiator === Infinity
                          }
                          rounded={false}
                          tickMarks={{ count: 4, labelsEnabled: true }}
                          direction='horizontal'
                          inputClassName={styles.differentiatedMagnitudeValueInputSlider}
                        />
                      </span>
                    </div>
                  </Tooltip>
                  <div
                    className={classNames(
                      appStyles.alignCenter,
                      trendsPageTrendsStyles.headerSection,
                      appStyles.horizontalFlex
                    )}
                  >
                    <Tooltip
                      content={
                        <span className={styles.tooltipContent}>
                          {tooltipTexts.comparisonPage.sortByComponent}
                        </span>
                      }
                    >
                      <Select
                        title='Sort By'
                        value={sortField}
                        options={sortFieldOptions}
                        bordered
                        onChange={onSortChange}
                        className={styles.select}
                      />
                    </Tooltip>
                    <ButtonGroup
                      analyticsEventTitle='Sort Direction'
                      selectedValue={sortDirection}
                      onClick={setSortDirection}
                    >
                      {[
                        {
                          value: 'desc',
                          element: <Icon name='arrow-down-wide-short' weight='light' />,
                          tooltip: tooltipTexts.common.descSort,
                        },
                        {
                          value: 'asc',
                          element: <Icon name='arrow-up-short-wide' weight='light' />,
                          tooltip: tooltipTexts.common.ascSort,
                        },
                      ]}
                    </ButtonGroup>
                  </div>
                </div>
                <button className={appStyles.unpaddedButton} onClick={reset}>
                  Reset
                </button>
              </div>
              <div className={classNames(appStyles.horizontalFlex, appStyles.alignCenter)}>
                <Input
                  containerClassName={classNames(
                    explorePageProductsTableStyles.searchInput,
                    styles.searchInput
                  )}
                  debounce={100}
                  placeholder='Search...'
                  value={searchTags}
                  iconName='search'
                  onInput={({ currentTarget }) => trackAndSetSearchTags(currentTarget.value)}
                />
              </div>
            </div>
            <div
              className={classNames(
                appStyles.horizontalFlex,
                appStyles.fullBasis,
                appStyles.overflowHidden,
                appStyles.flexGrow1
              )}
            >
              <ComparisonPageDiagramChart
                width={`calc(${leftWidth} + ${resizeIndicator}px)`}
                focusIndex={focusIndex}
                onClick={setFocusIndex}
                itemName='entity'
                differentiationMagnitude={differentiationMagnitude}
              />
              <div
                style={{ width: `calc(100% - ${leftWidth} - 18px)` }}
                className={classNames(
                  appStyles.horizontalFlex,
                  appStyles.gap0,
                  appStyles.fullHeight,
                  appStyles.justifyCenter,
                  appStyles.alignCenter
                )}
              >
                <div
                  className={styles.expanderLine}
                  ref={expanderRef}
                  onMouseDown={handleDragStart}
                >
                  <Icon name='grip-lines-vertical' />
                </div>
                <ComparisonPageDiagramDetails
                  focusOn={focusIndex === undefined ? undefined : comparableItems[focusIndex]}
                  resetFocusOn={() => setFocusIndex(undefined)}
                  differentiationMagnitude={differentiationMagnitude}
                  width={rightWidth}
                />
              </div>
            </div>
          </section>
        </ErrorBoundary>
      </Page>
    </Suspense>
  );
}
