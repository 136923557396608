import React, { memo } from 'react';
import classNames from 'classnames';
import styles from './Loader.module.scss';
import appStyles from '../layout/App.module.scss';

let idPrefix = 0;

type Props = {
  textDisplayed?: boolean;
  className?: string;
};

export const Loader = memo(function Loader(props: Props) {
  const { textDisplayed, className } = props;

  idPrefix++;

  return (
    <div
      className={classNames(
        appStyles.verticalFlex,
        appStyles.alignCenter,
        appStyles.animatedFadeIn,
        styles.loader,
        className
      )}
    >
      <svg viewBox='0 0 37 41'>
        <defs>
          <linearGradient
            y2='0.49994'
            x2='1.01415'
            y1='0.49994'
            x1='-0.01368'
            id={`${idPrefix}gradient1`}
          >
            <stop stopColor='#969595' offset='0' />
            <stop stopColor='#D3D3D3' offset='0.1531' />
            <stop stopColor='#D6D5D4' offset='1' />
          </linearGradient>
          <linearGradient
            y2='0.50016'
            x2='1.01586'
            y1='0.50016'
            x1='-0.01643'
            id={`${idPrefix}gradient2`}
          >
            <stop stopColor='#CACAC9' offset='0' />
            <stop stopColor='#D3D3D3' offset='0.1525' />
            <stop stopColor='#D6D5D4' offset='1' />
          </linearGradient>
          <linearGradient
            y2='0.50017'
            x2='1.01391'
            y1='0.50017'
            x1='-0.01339'
            id={`${idPrefix}gradient3`}
          >
            <stop stopColor='#89C667' offset='0' />
            <stop stopColor='#2CC0D5' offset='0.9427' />
          </linearGradient>
          <linearGradient
            y2='0.50002'
            x2='1.01601'
            y1='0.50002'
            x1='-0.01606'
            id={`${idPrefix}gradient4`}
          >
            <stop stopColor='#DBE8A6' offset='0' />
            <stop stopColor='#A7D16B' offset='1' />
          </linearGradient>
          <linearGradient
            y2='0.50008'
            x2='1.01941'
            y1='0.50008'
            x1='-0.01948'
            id={`${idPrefix}gradient5`}
          >
            <stop stopColor='#F593A3' offset='0' />
            <stop stopColor='#FFC81A' offset='0.7144' />
          </linearGradient>
        </defs>
        <g>
          <path
            strokeWidth='0.75'
            stroke={`url(#${idPrefix}gradient1)`}
            fill='none'
            d='m8.18,7.56l20.29,-2.89c0,-0.3 0,-0.56 0,-0.76c0,-3.44 -3.59,-3.05 -3.59,-3.05l-20.68,2.94c-3.01,0.6 -2.73,3.97 -2.73,3.97l-0.15,21.9c0,3.92 3.5,3.21 3.5,3.21l0.49,-0.07l0.14,-21.29c0.01,0 -0.27,-3.37 2.73,-3.96z'
          />
          <path
            strokeWidth='0.75'
            stroke={`url(#${idPrefix}gradient2)`}
            fill='none'
            d='m9.5,15.44c0,0 -0.28,-3.37 2.73,-3.97l16.27,-2.32c-0.01,-1.86 -0.01,-3.45 -0.01,-4.49l-20.31,2.9c-3.01,0.6 -2.73,3.97 -2.73,3.97l-0.13,21.28l4.07,-0.58l0.11,-16.79z'
          />
        </g>
        <g className={styles.colorizedFrames}>
          {Array(2)
            .fill(0)
            .map((_, i) => (
              <g key={i}>
                <path
                  strokeWidth='0.75'
                  stroke={`url(#${idPrefix}gradient3)`}
                  fill='none'
                  d='m36.5,11.58c0,-3.44 -3.59,-3.05 -3.59,-3.05l-0.44,0.06c0.01,5.04 0.08,21.29 0.08,21.29s-0.17,3.01 -2.8,3.78l-20.4,2.89l-0.01,0.78c0,3.92 3.5,3.21 3.5,3.21l20.94,-2.97c2.62,-0.76 2.8,-3.78 2.8,-3.78s-0.08,-18.77 -0.08,-22.21z'
                />
                <path
                  strokeWidth='0.75'
                  stroke={`url(#${idPrefix}gradient4)`}
                  fill='none'
                  d='m32.55,29.89c0,0 -0.07,-16.26 -0.08,-21.29l-3.98,0.57c0.02,6.75 0.07,16.98 0.07,16.98s-0.17,3.01 -2.8,3.78l-16.38,2.3l-0.03,4.32l20.4,-2.89c2.62,-0.76 2.8,-3.77 2.8,-3.77z'
                />
                <path
                  strokeWidth='0.75'
                  stroke={`url(#${idPrefix}gradient5)`}
                  fill='none'
                  d='m9.5,15.44l-0.12,16.79l16.38,-2.32c2.62,-0.76 2.8,-3.78 2.8,-3.78s-0.05,-10.23 -0.07,-16.97l-16.27,2.32c-3.01,0.59 -2.72,3.96 -2.72,3.96z'
                />
              </g>
            ))}
        </g>
      </svg>
      {textDisplayed && <h1 className={styles.title}>Harmonya</h1>}
    </div>
  );
});
