import type { PropsWithChildren, SVGAttributes, SVGProps } from 'react';
import React, { useEffect, useRef } from 'react';
import type { Circle } from 'utils/circle';

type Props = PropsWithChildren<{
  id: string;
  circle: Circle;
  offset: number; // for the position of the text on the circle. ratio similar to using startOffset with percent
  sweepFlag: number; // Effects the direction of the text (internal vs external)
  dy: NonNullable<SVGAttributes<SVGTextElement>['dy']>; // Distance of the text from the circle
  maxOffset?: number;
}> &
  SVGProps<SVGSVGElement>;

// Create path of a circle starting from the left middle point of it. Children is text to be displayed on the circle
// curve. Should be valid as children of <textPath>
export function CircularText(props: Props) {
  const { id, circle, offset, sweepFlag, dy, maxOffset, children, ...svgProps } = props;
  const { radius } = circle;
  const x = circle.center.x - radius;
  const { y } = circle.center;
  const getArcPath = (xFactor: 1 | -1) =>
    `a${radius} ${radius} 0 1 ${sweepFlag} ${xFactor * 2 * radius} 0`;
  const linePath = `M${x} ${y}`;
  const firstArcPath = getArcPath(1);
  const secondArcPath = getArcPath(-1);
  const circlePath = linePath + firstArcPath + secondArcPath;
  const textPathRef = useRef<SVGTextPathElement>(null);
  const textRef = useRef<SVGTSpanElement>(null);
  const circularPathRef = useRef<SVGPathElement>(null);

  useEffect(() => {
    if (textPathRef?.current && circularPathRef?.current && textRef?.current) {
      let titleOffset = offset;

      if (maxOffset) {
        const circularPathLength = circularPathRef.current.getTotalLength();
        const textSize = textRef.current.getComputedTextLength();
        const lengthRatio = textSize / circularPathLength;
        const offsetFromRight = maxOffset - lengthRatio * 100;

        titleOffset = Math.min(offsetFromRight, titleOffset);
      }

      textPathRef.current.setAttribute('startOffset', `${titleOffset}%`);
    }
  }, [textPathRef?.current, circularPathRef?.current, textRef?.current]);

  return (
    <svg width='100%' height='100%' {...svgProps}>
      <defs>
        <path id={id} d={circlePath} ref={circularPathRef} />
      </defs>
      <text dy={dy}>
        <textPath ref={textPathRef} xlinkHref={`#${id}`}>
          <tspan ref={textRef}>{children}</tspan>
        </textPath>
      </text>
    </svg>
  );
}
