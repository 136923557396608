import type { ComponentProps } from 'react';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useCopyPaste } from './AttributionCell.utils';
import type { AttributionCellContextMenuActions } from './AttributionCellContextMenuContent';
import type { HighlightProps } from './AttributionHighlightedCell';
import { AttributionHighlightedCell } from './AttributionHighlightedCell';
import { useModifyCell } from './hooks/useModifyCell';
import { AttributionCellContent } from './AttributionCellContent';

type Props = Pick<
  ComponentProps<typeof AttributionCellContent>,
  'content' | 'isDisabled' | 'onBlur' | 'isMarked' | 'contextMenuHandler'
> & {
  onBlur?: () => void;
};

export const AttributionMarkedCell = forwardRef<AttributionCellContextMenuActions, Props>(
  function AttributionMarkedCell(props, ref) {
    const { onBlur, isDisabled, content, contextMenuHandler } = props;
    const { itemId, value, columnId, isValidated, isEdited, confidenceLevel, cellType } = content;
    const cellRef = useRef<HTMLDivElement>(null);
    const [highlightProps, setHighlightProps] = useState<HighlightProps>();
    const isHighlighted = !!highlightProps;
    const modifyCell = useModifyCell();
    const turnHighlightOff = () => setHighlightProps(undefined);
    const turnHighlightOn = (initDisplayValue = '', isOpenedByDoubleClick = false) => {
      if (cellRef.current && !isHighlighted) {
        setHighlightProps({
          rect: cellRef.current.getBoundingClientRect(),
          initDisplayValue,
          isOpenedByDoubleClick,
        });
      }
    };
    const setCellValue = useCallback(
      (newValue: string) => {
        turnHighlightOff();

        if (!isDisabled && newValue !== value) {
          void modifyCell([
            {
              itemId,
              columnId,
              value: newValue,
              oldValue: value ?? null,
              cellType: 'edited',
              oldCellType: cellType,
              confidenceLevel: null,
              oldConfidenceLevel: confidenceLevel ?? null,
            },
          ]);
        }
      },
      [itemId, columnId, value, confidenceLevel, cellType]
    );

    const handleValidate = useCallback(() => {
      if (value) {
        void modifyCell([
          {
            itemId,
            columnId,
            value,
            oldValue: value,
            cellType: 'validated',
            oldCellType: cellType,
            confidenceLevel: null,
            oldConfidenceLevel: confidenceLevel ?? null,
          },
        ]);
      }
    }, [itemId, columnId, value, confidenceLevel, cellType]);

    const turnHighlightWithCurrentValue = useCallback(
      () => turnHighlightOn(value, true),
      [value, isHighlighted]
    );
    const { copy, paste } = useCopyPaste(value, setCellValue);
    const validate = isValidated || isEdited ? undefined : handleValidate;

    useImperativeHandle(
      ref,
      () => ({
        copy,
        ...(!isDisabled && {
          paste,
          edit: turnHighlightWithCurrentValue,
          validate,
        }),
      }),
      [copy, paste, turnHighlightWithCurrentValue, validate, isDisabled]
    );

    useEffect(() => {
      if (!isDisabled) {
        const abortController = new AbortController();

        window.addEventListener(
          'keydown',
          event => {
            if (!isHighlighted) {
              if (event.key === 'Escape') {
                onBlur?.();
              } else {
                // Regular expression to match any letter (alphabetic character) or character from another language
                const isTextInput = /^[\p{L}\p{N}\p{P}\p{S}]$/u.test(event.key);
                const isHighlightableKey =
                  !event.ctrlKey &&
                  !event.altKey &&
                  !event.metaKey &&
                  (isTextInput || ['Backspace', ' '].includes(event.key));

                if (isHighlightableKey) {
                  turnHighlightOn(isTextInput ? event.key : '');
                }
              }
            } else if (event.key === 'Escape') {
              turnHighlightOff();
            }
          },
          { signal: abortController.signal }
        );

        return () => abortController.abort();
      }
    }, [isHighlighted, isDisabled]);

    useEffect(() => {
      return () => turnHighlightOff();
    }, []);

    if (isHighlighted && !isDisabled) {
      return (
        <AttributionHighlightedCell
          {...highlightProps}
          columnId={columnId}
          setValue={setCellValue}
        />
      );
    }

    return (
      <AttributionCellContent
        content={content}
        isDisabled={isDisabled}
        contextMenuHandler={contextMenuHandler}
        onValidate={handleValidate}
        ref={cellRef}
        onDoubleClick={turnHighlightWithCurrentValue}
        isMarked
      />
    );
  }
);
