import React from 'react';
import { useExplorePageConfigurationExportContent } from 'hooks/useConfigurationExportContent';
import { useTagsExportContent } from 'hooks/useTagsExportContent';
import { productsExportState } from 'store/products';
import { useRecoilValueLoadableState } from 'hooks/useRecoilValueLoadable';
import { ExportButton } from 'components/general/ExportButton';
import { useRecoilValue } from 'recoil';
import { sortedDisplayedTagsState } from 'store/sortedDisplayedTags';

function getOptionHook() {
  return () => {
    const productsExportContent = useRecoilValueLoadableState(productsExportState);
    const { getTagsExport } = useTagsExportContent();
    const tagsExportContent = getTagsExport(useRecoilValue(sortedDisplayedTagsState));
    const configurationExportContent = useExplorePageConfigurationExportContent();

    const options = [
      {
        key: 'query-configuration',
        title: 'Query Configuration',
        fileContent: configurationExportContent,
      },
      {
        key: 'tags',
        title: 'Tags',
        fileContent: tagsExportContent,
        disabled: !tagsExportContent.length,
      },
      {
        key: 'product-table',
        title: 'Product Table',
        fileContent: productsExportContent,
      },
    ];

    return {
      options,
      isAllDisabled: !productsExportContent?.length,
    };
  };
}

export function ExplorePageExportButton() {
  const optionsHook = getOptionHook();

  return <ExportButton useOptions={optionsHook} pageKey='explore' minWidth={200} />;
}
