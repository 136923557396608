import classNames from 'classnames';
import { AnimatedFontWeight } from 'components/general/AnimatedFontWeight';
import { Icon } from 'components/general/Icon';
import { Tooltip } from 'components/general/Tooltip';
import React, { memo, useState } from 'react';
import appStyles from '../App.module.scss';
import styles from './ExplorePagePresetButton.module.scss';
import type { PresetConfig } from './ExplorePageTags.utils';
import type { RecoilState } from 'recoil';
import { useRecoilState } from 'recoil';

type Props<T> = {
  name: T;
  selectedPresetRecoilState: RecoilState<T | undefined>;
  activatePreset: (isSelected: boolean) => void;
} & Pick<PresetConfig, 'title' | 'tooltip' | 'iconName'>;

export const ExplorePagePresetButton = memo(function RawExplorePagePresetButton<T extends string>(
  props: Props<T>
) {
  const { name, selectedPresetRecoilState, title, tooltip, iconName, activatePreset } = props;
  const [hoveredPresetButtonName, setHoveredPresetButtonName] = useState<string>();
  const [selectedPreset, setSelectedPreset] = useRecoilState(selectedPresetRecoilState);
  const isSelected = selectedPreset === name;

  const toggleSelection = () => {
    setSelectedPreset(isSelected ? undefined : name);
    //* @todo: activating the preset should be a direct derivative of the selectedPresetRecoilState
    activatePreset(isSelected);
  };

  return (
    <Tooltip content={tooltip}>
      <button
        className={classNames(
          appStyles.button,
          appStyles.border,
          appStyles.gap1,
          styles.button,
          isSelected && styles.selected
        )}
        onClick={toggleSelection}
        onMouseEnter={() => setHoveredPresetButtonName(name)}
        onMouseLeave={() => setHoveredPresetButtonName(undefined)}
      >
        <Icon className={classNames(appStyles.mediumFont, styles.icon)} name={iconName} />
        <AnimatedFontWeight
          altWeight={500}
          altActivated={isSelected || name === hoveredPresetButtonName}
          className={styles.text}
        >
          {title}
        </AnimatedFontWeight>
      </button>
    </Tooltip>
  );
});
