import classNames from 'classnames';
import type { ReactNode } from 'react';
import React from 'react';
import styles from './Divider.module.scss';
import { iterator } from '@harmonya/utils';

type Props = {
  direction?: 'horizontal' | 'vertical';
  className?: string;
};

export function Divider(props: Props) {
  const { direction = 'vertical', className } = props;

  return <div className={classNames(styles.divider, className)} dir={direction} />;
}

export function join(elements: Iterable<ReactNode>, props?: Props) {
  return iterator.join(elements, i => <Divider {...props} key={`${i}-divider`} />);
}
