import React, { useEffect, useRef } from 'react';
import { Icon } from './Icon';
import type { DropdownRef } from 'components/general/dropdown/Dropdown';
import { Dropdown } from 'components/general/dropdown/Dropdown';
import { SelectDropdown } from 'components/general/select/SelectDropdown';
import classNames from 'classnames';
import styles from './HiddenTagsMenu.module.scss';
import appStyles from '../layout/App.module.scss';
import type { AggregateTag } from 'models/AggregateTag';
import { getColorClassById } from 'utils/styleVariables';
import { tagsState } from 'store/tags';
import { useRecoilValue } from 'recoil';
import { getMinWidthByLongestOption } from 'components/general/select/Select';
import { formatters } from 'utils/formatters';
import { dividerOption } from './select/SelectOptions';
import { analyticsState } from 'store/analytics';
import { iterator, string } from '@harmonya/utils';

type AggregateTagId = AggregateTag['id'];

type Props = {
  hiddenTags: AggregateTag[];
  toggleHiddenTag: (tagId: AggregateTagId) => void;
  clearHiddenTags: () => void;
};

const unhideAllValue = -1;

export function HiddenTagsMenu(props: Props) {
  const { hiddenTags, toggleHiddenTag, clearHiddenTags } = props;
  const tags = useRecoilValue(tagsState);
  const analytics = useRecoilValue(analyticsState);
  const dropdownRef = useRef<DropdownRef>({});
  const hiddenTagsOptions = hiddenTags.map(item => ({
    value: item.id,
    name: item.name,
    count: item.values.productsCount ?? 0,
  }));
  const unhideAllOption = {
    value: unhideAllValue,
    name: 'Unhide All',
    count: iterator.sum(hiddenTagsOptions.map(option => option.count)),
  };
  const options = [...hiddenTagsOptions, { ...dividerOption, count: 0 }, unhideAllOption];

  const displayValueGetter = (option: (typeof hiddenTagsOptions)[number]) => {
    const tag = tags.get(option.value);
    const colorId = tag?.dominantType?.colorId;
    const colorClass = getColorClassById(colorId);
    const optionClassName = classNames(
      option.value !== unhideAllValue && appStyles.tag,
      colorClass,
      styles.hiddenItemName
    );

    return (
      <>
        <div className={classNames(appStyles.horizontalFlex, styles.hiddenItemNameContainer)}>
          <span className={optionClassName}>{option.name}</span>
        </div>
        <div className={classNames(appStyles.horizontalFlex, appStyles.alignCenter)}>
          <span
            className={classNames(appStyles.horizontalFlex, appStyles.gap1, styles.productsCount)}
          >
            <Icon {...formatters.productsCount.icon} weight='light' />
            {formatters.productsCount.valueGetter(option.count)}
          </span>
          <Icon name='eye' weight='light' />
        </div>
      </>
    );
  };

  const buttonContent = (
    <button
      className={classNames(appStyles.unpaddedButton, styles.button, appStyles.horizontalFlex)}
    >
      <Icon name='eye' weight='light' />
      {`${hiddenTags.length ? `${hiddenTags.length}  ` : ''}${string.getPluralOrSingular('Hidden Tag', hiddenTags.length)}`}
      <Icon name='chevron-up' weight='light' />
    </button>
  );
  /** @todo We need to find a more infereded solution to deduce the maximum option length at runtime (rather than hard coded) */
  const labelWidthWithoutText = 150;
  const optionsMinWidth =
    labelWidthWithoutText + getMinWidthByLongestOption([['', options]], option => option.name);

  const onToggle = () => {
    analytics.track('Hidden tags menu toggled', {
      name: 'Hidden Tags',
      type: 'button',
      value: hiddenTags.length,
    });
  };

  const trackAndClearHiddenTags = () => {
    analytics.track('Hidden tags cleared', { name: 'Hidden Tags', type: 'button' });
    clearHiddenTags();
  };

  const trackAndToggleHiddenTag = (tagId: AggregateTagId) => {
    const currentTag = tags.get(tagId);

    analytics.track('Hidden tag added', {
      name: 'Hidden Tags',
      type: 'button',
      value: currentTag?.name ?? tagId,
    });
    toggleHiddenTag(tagId);
  };

  useEffect(() => {
    if (!hiddenTags.length) {
      dropdownRef.current.close?.();
    }
  }, [hiddenTags]);

  return (
    <div className={appStyles.horizontalFlex}>
      <Dropdown
        dropdownRef={dropdownRef}
        onToggle={onToggle}
        minWidth={optionsMinWidth}
        direction='up'
        buttonContent={buttonContent}
        className={classNames(!hiddenTags.length && styles.disabled)}
      >
        <SelectDropdown
          onChange={tagId =>
            tagId === unhideAllValue ? trackAndClearHiddenTags() : trackAndToggleHiddenTag(tagId)
          }
          options={options}
          value={undefined as unknown as AggregateTagId}
          optionClassNameGetter={() => styles.hiddenItem}
          displayValueGetter={displayValueGetter}
        />
      </Dropdown>
    </div>
  );
}
