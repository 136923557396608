import classNames from 'classnames';
import React, { type HTMLAttributes } from 'react';
import styles from './AttributionLegend.module.scss';

export function AttributionLegendItem(props: HTMLAttributes<HTMLDivElement>) {
  const { children, className, ...divProps } = props;
  return (
    <div className={classNames(styles.item, className)} {...divProps}>
      {children}
    </div>
  );
}
