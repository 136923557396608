import type { ComponentProps, ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';
import appStyles from '../layout/App.module.scss';
import styles from './FilterItem.module.scss';
import { Icon } from './Icon';

type Props = {
  value: ReactNode;
  onFilterClick: () => void;
} & ComponentProps<typeof Icon>;

export function FilterItem(props: Props) {
  const { value, onFilterClick, ...iconProps } = props;
  const containerClassName = classNames(
    appStyles.horizontalFlex,
    appStyles.alignCenter,
    appStyles.smallFont,
    appStyles.gap0,
    styles.container
  );

  return (
    <div className={containerClassName} onClick={onFilterClick}>
      <span className={styles.icon}>
        <Icon weight='light' {...iconProps} />
      </span>
      <span>{value}</span>
      <span className={styles.iconRemove}>
        <Icon name='xmark' />
      </span>
    </div>
  );
}
