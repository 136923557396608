import React from 'react';
import analyticsStyles from '../AttributionAnalyticsPanel.module.scss';
import analyticsCardPerformanceStyles from './AttributionAnalyticsPanelPerformanceCard.module.scss';
import styles from './AttributionAnalyticsPanelCardValidatedLabels.module.scss';
import { PercentageProgressBar } from 'components/general/PercentageProgressBar';

type Props = {
  total: number;
  validatedTotal: number;
};

export function AttributionAnalyticsPanelCardValidatedLabels(props: Props) {
  const { total, validatedTotal } = props;

  return (
    <div className={analyticsCardPerformanceStyles.cardSectionContainer}>
      <h4 className={analyticsStyles.panelTitle}>Validated Labels</h4>
      <div className={styles.content}>
        <div className={styles.fraction}>
          <h1>{validatedTotal}</h1> / {total}
        </div>
        <PercentageProgressBar value={validatedTotal / total} />
      </div>
    </div>
  );
}
