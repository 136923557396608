import { defaultTagValueKeys, useGetTagValues } from './useGetTagValues';
import type { AggregateTag } from 'models/AggregateTag';

export function useTagsExportContent(tagKeysToFilter: (keyof AggregateTag['values'])[] = []) {
  const getTagValues = useGetTagValues(
    defaultTagValueKeys.filter(key => !tagKeysToFilter.includes(key)),
    true
  );

  const getTagsExport = (tags: AggregateTag[], titleAddition?: string) => {
    return tags.map(tag => {
      const { name, types } = tag;
      const joinedTypeNames = Array.from(types.values(), type => type.name).join(', ');
      const keyValues = Object.fromEntries(
        getTagValues(tag).map(item => [
          titleAddition ? `${item.title} ${titleAddition}` : item.title,
          item.value,
        ])
      );

      return { Tag: name, Types: joinedTypeNames, ...keyValues };
    });
  };
  return { getTagsExport };
}

export function unifyTagFileParts<T extends { Tag: string; Types: string }>(listOfTags: T[][]) {
  const allKeys = listOfTags.flatMap(tagList => (tagList[0] ? Object.keys(tagList[0]) : []));
  const emptyValues = Object.fromEntries(allKeys.map(key => [key, '']));
  const listOfTagsAsMap = listOfTags.reduce((acc, curr, index) => {
    if (index === 0) {
      curr.forEach(item => acc.set(`${item.Tag}_${item.Types}`, { ...emptyValues, ...item }));
      return acc;
    }
    curr.forEach(tag => {
      const key = `${tag.Tag}_${tag.Types}`;
      acc.set(
        key,
        acc.has(key) ? { ...emptyValues, ...acc.get(key), ...tag } : { ...emptyValues, ...tag }
      );
    });
    return acc;
  }, new Map());

  return [...listOfTagsAsMap.values()];
}
