/* eslint id-length: ["error", { "exceptions": ["r", "g", "b", "x", "y", "z", "h", "s", "l", "p", "q", "t"] }] */

type ColorModel = [number, number, number];

/**
 * @link Based on https://stackoverflow.com/a/9493060
 */
function hslToRgb(hsl: string): ColorModel {
  const [h, s, l] = hsl.match(/\d+/g)?.map(Number) ?? [];
  let r: number;
  let g: number;
  let b: number;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hueToRgb(p, q, h + 1 / 3);
    g = hueToRgb(p, q, h);
    b = hueToRgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

function hueToRgb(p: number, q: number, t: number) {
  let computedT = t;

  if (computedT < 0) {
    computedT += 1;
  }

  if (computedT > 1) {
    computedT -= 1;
  }

  if (computedT < 1 / 6) {
    return p + (q - p) * 6 * computedT;
  }

  if (computedT < 1 / 2) {
    return q;
  }

  if (computedT < 2 / 3) {
    return p + (q - p) * (2 / 3 - computedT) * 6;
  }

  return p;
}

/**
 * @link Based on https://github.com/antimatter15/rgb-lab/blob/master/color.js#L28
 */
export const hslToLab = (hsl: string) => {
  const rgb = hslToRgb(hsl);
  const [r, g, b] = rgb.map(channel => {
    const dividedChannel = channel / 255;

    return dividedChannel > 0.04045
      ? ((dividedChannel + 0.055) / 1.055) ** 2.4
      : dividedChannel / 12.92;
  });

  const rgbToXyz = (rFactor: number, gFactor: number, bFactor: number, divider: number) => {
    const value = (r * rFactor + g * gFactor + b * bFactor) / divider;

    return value > 0.008856 ? value ** (1 / 3) : 7.787 * value + 16 / 116;
  };

  const x = rgbToXyz(0.4124, 0.3576, 0.1805, 0.95047);
  const y = rgbToXyz(0.2126, 0.7152, 0.0722, 1.0);
  const z = rgbToXyz(0.0193, 0.1192, 0.9505, 1.08883);
  const lab: ColorModel = [116 * y - 16, 500 * (x - y), 200 * (y - z)];

  return lab;
};

/**
 * @link Based on https://github.com/antimatter15/rgb-lab/blob/master/color.js#L52
 */
export const deltaE = (lab1: ColorModel, lab2: ColorModel) => {
  const [l1, a1, b1] = lab1;
  const [l2, a2, b2] = lab2;
  const deltaL = l1 - l2;
  const deltaA = a1 - a2;
  const deltaB = b1 - b2;
  const c1 = Math.sqrt(a1 * a1 + b1 * b1);
  const c2 = Math.sqrt(a2 * a2 + b2 * b2);
  const deltaC = c1 - c2;
  const deltaH = Math.max(0, Math.sqrt(deltaA * deltaA + deltaB * deltaB - deltaC * deltaC));
  const sc = 1.0 + 0.045 * c1;
  const sh = 1.0 + 0.015 * c1;
  const deltaLKlsl = deltaL / 1.0;
  const deltaCkcsc = deltaC / sc;
  const deltaHkhsh = deltaH / sh;
  const delta = Math.max(
    0,
    Math.sqrt(deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh)
  );

  return delta;
};

const saturationValue = /\d+(?=%)/;

export function withModifiedSaturation(
  hslColor: string,
  mapper: (currentSaturation: number) => number
) {
  return hslColor.replace(saturationValue, saturation => mapper(Number(saturation)).toString());
}
