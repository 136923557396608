import { selector } from 'recoil';
import { fetchGet } from '../utils/fetch';
import { authState, customerIdState } from './auth';
import type { FeatureCollection } from 'geojson';
import type { MapMarketItem, MarketsMap } from '../interfaces';

export const marketsMapState = selector({
  key: 'marketsMap',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: async ({ get }) => {
    const customerId = get(customerIdState);
    const { user } = get(authState);
    const marketsMapServer = await fetchGet<MarketsMap>('/api/markets/map', customerId, user.email);

    if (marketsMapServer) {
      const marketMappingEntries: [number, MapMarketItem][] = marketsMapServer.areaMapper.map(
        mapMarketItem => [mapMarketItem.marketId, mapMarketItem]
      );
      const marketsMap = {
        map: marketsMapServer.map as FeatureCollection,
        areaMapper: new Map(marketMappingEntries),
      };

      return marketsMap;
    }

    return null;
  },
});
