import classNames from 'classnames';
import type { ComponentProps, ReactNode } from 'react';
import React from 'react';
import styles from './Page.module.scss';
import appStyles from '../layout/App.module.scss';
import { Header } from './Header';

type Props = {
  headerChildren?: ReactNode;
} & ComponentProps<typeof Header>;

export function Page(props: Props) {
  const { headerChildren, title, children } = props;

  return (
    <div className={classNames(appStyles.verticalFlex, styles.page)}>
      <Header title={title}>{headerChildren}</Header>
      {children}
    </div>
  );
}
