import { tagTypeNames } from './raw/tagTypeNames';
import {
  brandingIdsMap,
  categoryIdsMap,
  generateFloatNumber,
  generateNumber,
  generateSelectOptions,
  getRandomFrom,
  marketIdsMap,
  periodIdsMap,
  tagIdsMap,
} from './utils';
import { marketNames } from './raw/marketNames';
import { productNames } from './raw/productNames';
import { brandingNames } from './raw/brandingNames';
import { isDefined, noop } from '../general';
import { sum } from '../iterator';
import { getDateKey } from '../date';
import type { HttpMethod } from '../types';
import type {
  AggregateTagWithRawValues,
  ExplorePageSalesFilters,
  Products,
  SalesSeries,
  SelectOption,
  Tag,
  TagType,
  Trend,
} from '@harmonya/models';

const tagTypes = tagTypeNames.map((name, i) => ({
  id: i,
  name,
  groups: getRandomFrom([1, 2, 3, 4, 5], 3, 0),
}));

export const mockData = {
  tagTypes: (): TagType[] => tagTypes,
  aggregateTags: (): AggregateTagWithRawValues[] =>
    [...tagIdsMap].map(([id, name]) => ({
      id,
      name,
      typeIds: getRandomFrom(tagTypes, 3, 1).map(tagType => tagType.id),
      values: {
        brandSignificance: generateFloatNumber(1, 0),
        productsCount: generateNumber(20000, 1),
        salesGrowth: {
          inPeriod: generateFloatNumber(300, 1),
          lastYear: generateFloatNumber(300, 1),
        },
        totalSales: generateFloatNumber(30000, 400),
        brandsCount: generateNumber(40, 1),
        marketShare: generateFloatNumber(1, 0),
        customerMarketShare: generateFloatNumber(1, 0),
        reviewProductsRatio: generateFloatNumber(1, 0),
        listingProductsRatio: generateFloatNumber(1, 0),
      },
      rawValues: {
        firstWeekSales: generateFloatNumber(10000, 400),
        lastWeekSales: generateFloatNumber(10000, 400),
        lastYearTotalSales: generateFloatNumber(30000, 400),
        totalSales: generateFloatNumber(30000, 400),
        customerTotalSales: generateFloatNumber(30000, 400),
        reviewProductsCount: generateNumber(1200),
        listingProductsCount: generateNumber(1200),
      },
    })),
  tags: (): Tag[] =>
    [...tagIdsMap].map(([id, name]) => ({
      id,
      name,
      typeIds: getRandomFrom(tagTypes, 3, 1).map(type => type.id),
      productsCount: generateNumber(10000, 1),
    })),
  categories: (): SelectOption[] => [...categoryIdsMap.values()],
  markets: (): SelectOption[] => generateSelectOptions(marketIdsMap),
  periods: (): SelectOption[] => generateSelectOptions(periodIdsMap),
  brandings: (): SelectOption[] => [...brandingIdsMap.values()],
  sales: {
    query: (): SalesSeries => generateSalesData(13, 'MyQuery'),
    categories: (url: string, body?: ExplorePageSalesFilters): SalesSeries => {
      const keys = body?.categoryIds?.length
        ? body.categoryIds.map(id => categoryIdsMap.get(id)?.name).filter(isDefined)
        : Array.from(categoryIdsMap.values(), ({ name }) => name);

      return generateSalesData(13, ...keys.slice(0, body?.salesLimit));
    },
    brands: (url: string, body?: ExplorePageSalesFilters): SalesSeries =>
      generateSalesData(13, ...brandingNames.slice(0, body?.salesLimit)),
    products: (url: string, body?: ExplorePageSalesFilters): SalesSeries =>
      generateSalesData(13, ...productNames.slice(0, body?.salesLimit)),
    markets: (url: string, body?: ExplorePageSalesFilters): SalesSeries =>
      generateSalesData(13, ...marketNames.slice(0, body?.salesLimit)),
    marketsOverview: (): SalesSeries => ({
      keys: [],
      totalSalesItems: [],
      salesGrowthItems: { inPeriod: [] },
    }),
    hiddenIds: () => ['Other'],
  },
  products: (): Products => {
    const brandId = 1;
    const brandingId = 1;
    const categoryId = 1;
    const periodIds = [1];
    const marketIds = [1];
    const items = [...Array(generateNumber(10_000, 5_000))].map((_, i) => ({
      id: i + 1,
      normalizedUpc: (i + 1).toString(),
      name: productNames[i % productNames.length],
      brandName: getRandomFrom(brandingNames, 1, 1)[0],
      brandId,
      brandingId,
      marketShare: 0.3,
      categoryId,
      periodIds,
      marketIds,
      sales: generateNumber(10000000, 1),
      lastYearSales: generateNumber(10000000, 1),
      salesDaysCount: generateNumber(10000, 1),
      firstWeekSales: generateNumber(10000, 1),
      lastWeekSales: generateNumber(10000, 1),
      growth: {
        inPeriod: generateNumber(100, -100),
        lastYear: generateNumber(100, -100),
      },
    }));

    return {
      items,
      metadata: {
        productsCount: items.length,
        marketShare: generateNumber(100, 1),
        customerMarketShare: generateNumber(100, 1),
        brandsCount: generateNumber(10000, 1),
        totalSales: sum(items, product => product.sales),
        salesGrowth: {
          inPeriod: Math.ceil(
            sum(items, product => product.firstWeekSales) /
              sum(items, product => product.lastWeekSales)
          ),
          lastYear: Math.ceil(
            sum(items, product => product.firstWeekSales) /
              sum(items, product => product.lastWeekSales) /
              2
          ),
        },
        rawValues: {
          firstWeekSales: generateFloatNumber(10000, 400),
          lastWeekSales: generateFloatNumber(10000, 400),
          lastYearTotalSales: generateFloatNumber(30000, 400),
          totalSales: generateFloatNumber(30000, 400),
        },
        brandingSalesDataByLevel: {
          1: { keys: [], totalSalesItems: [], salesGrowthItems: { inPeriod: [] } },
        },
        categorySalesDataByLevel: {
          1: { keys: [], totalSalesItems: [], salesGrowthItems: { inPeriod: [] } },
        },
        querySalesData: { keys: [], totalSalesItems: [], salesGrowthItems: { inPeriod: [] } },
        productsSalesData: { keys: [], totalSalesItems: [], salesGrowthItems: { inPeriod: [] } },
        aggregatedTags: mockData.aggregateTags(),
        listingProductRatioTopPercentile: generateFloatNumber(1, 0),
        reviewProductRatioTopPercentile: generateFloatNumber(1, 0),
      },
    };
  },
  trends: (): { totalCount: number; items: Trend[] } => {
    const items = [...Array(generateNumber(50, 50))].map((_, i) => {
      const tagIds = getRandomFrom([...tagIdsMap.keys()], 1, 2);
      const salesGrowthTimeSeries = generateSalesData(12, i.toString());

      return {
        id: i,
        periodId: getRandomFrom([...periodIdsMap.keys()], 1, 1)[0],
        lastUpdate: new Date(
          generateNumber(2018, 2022),
          generateNumber(11),
          generateNumber(29)
        ).getDate(),
        tagIds,
        categoryIds: getRandomFrom([...categoryIdsMap.keys()], 1, 1),
        marketIds: getRandomFrom([...marketIdsMap.keys()], 1, 1),
        productsCount: generateNumber(10000, 1),
        trendingScore: generateFloatNumber(1, 0.1),
        launchedProductsContribution: generateFloatNumber(100, -100),
        relativeToBrandGrowth: generateNumber(100, 1),
        relativeToCategoryGrowth: generateNumber(100, 1),
        salesGrowthExcludingDominantBrand: generateFloatNumber(100, -100),
        tagsGrowthRelativeToEachIndividualTag: generateNumber(100, 1),
        tagsCategoryMaxAffiliation: generateNumber(100, 1),
        marketShare: generateNumber(100, 1),
        marketShareGrowth: generateNumber(100, 1),
        // customerMarketShare: generateFloatNumber(100, -100),
        totalSales: generateNumber(1000000, 10000),
        brandsCount: generateNumber(50, 1),
        salesGrowth: {
          inPeriod: generateNumber(100, -100),
          lastYear: generateNumber(100, -100),
        },
        salesGrowthTimeSeries: {
          keys: salesGrowthTimeSeries.keys,
          values: {
            inPeriod: salesGrowthTimeSeries.salesGrowthItems.inPeriod[0].values,
            ...(!!salesGrowthTimeSeries.salesGrowthItems.lastYear && {
              lastYear: salesGrowthTimeSeries.salesGrowthItems.lastYear[0].values,
            }),
          },
        },
      };
    });

    return {
      items,
      totalCount: generateNumber(5000, 3000),
    };
  },
  maxExportItems: () => 1000,
  settings: () => ({
    trendsEnabled: true,
    initialDifferentiationMagnitude: 2,
    tagTypePriority: [
      'Flavors',
      'Scent',
      'Ingredients',
      'Claims',
      'Warnings',
      'Concerns',
      'Animal',
      'Pet Size',
      'Pet Age',
      'Taste Properties',
      'Texture',
      'Textures ',
      'Form',
      'Cleaning Zone',
      'Application Area',
      'Surface',
      'Hair Type',
      'Container',
      'Packaging',
      'Product Container',
      'Product Form',
      'Size',
      'Condition',
      'Instructions',
      'Product Type',
      'Preparations',
      'Product Properties',
      'Audience / Primary Consumers',
      'Activity Related',
      'Consumer Benefits',
      'Events',
      'Occasions',
      'Place',
    ],
    presets: {
      drivers: {
        minimalBrandsCount: 3,
        productRatio: 0.01,
        brandRatio: 0.01,
        minSalesRatio: 0.001,
        minGrowthRatio: 1,
        negativeGrowthSalesGrowth: 0.01,
      },
      drains: {
        minimalBrandsCount: 3,
        productRatio: 0.01,
        brandRatio: 0.01,
        minSalesRatio: 0.001,
      },
      radar: {
        minimalBrandsCount: 3,
        productRatio: 0.005,
        brandRatio: 0.05,
        maxSalesRatio: 0.01,
        minGrowthRatio: 2,
        negativeGrowthSalesGrowth: 0.05,
      },
      reviews: {
        minimalBrandsCount: 3,
        productRatio: 0.01,
        brandRatio: 0.01,
      },
      marketing: {
        minimalBrandsCount: 3,
        productRatio: 0.01,
        brandRatio: 0.01,
      },
    },
    defaults: {
      explorePage: {
        categoryLevelId: 2,
        brandingLevelId: 2,
        searchParams: {
          tagValueProp: 'productsCount',
          diamondChartSort: 'desc',
        },
      },
    },
  }),
  filterDependencies: () => ({
    brandingIds: {
      categoryIds: Object.fromEntries(
        Array.from(categoryIdsMap.keys(), id => [id, [...brandingIdsMap.keys()]])
      ),
    },
  }),
  apps: () => ({ attribution: true, insight: true }),
};

export const generateSalesData = (weeksCount: number, ...ids: string[]): SalesSeries => {
  const keys = [...Array(weeksCount)].map((_, i) => {
    const date = new Date();
    const daysOffset = date.getDate() - (weeksCount + i) * 7;

    date.setDate(daysOffset);

    return getDateKey(date);
  });
  const generate = (max: number, min?: number) =>
    ids.map(id => ({
      id,
      values: [...Array(weeksCount)],
      aggregatedValue: weeksCount * generateNumber(max, min),
    }));
  const totalSalesItems = generate(100000, 1000);
  const salesGrowthItems = {
    inPeriod: generate(400, -400),
    lastYear: generate(400, -400),
  };

  return { keys, totalSalesItems, salesGrowthItems };
};

declare let globalThis: {
  env: {
    MOCK_TIMEOUT: number;
  };
};

interface MockConfig<B extends object = object> {
  getData: (url: string, body?: B, params?: object, init?: RequestInit) => unknown;
  timeout?: number;
}

type MethodMocks = {
  [Key in HttpMethod]?: MockConfig;
};

const timeout = globalThis.env?.MOCK_TIMEOUT;

export const routes: { [key: string]: MethodMocks } = {
  '/api/tagTypes': { GET: { timeout, getData: mockData.tagTypes } },
  '/api/aggregateTags': { POST: { timeout, getData: () => mockData.aggregateTags() } },
  '/api/tags': { GET: { timeout, getData: mockData.tags } },
  '/api/categories': { GET: { timeout, getData: mockData.categories } },
  '/api/markets': { GET: { timeout, getData: mockData.markets } },
  '/api/markets/map': { GET: { timeout, getData: noop } },
  '/api/periods': { GET: { timeout, getData: mockData.periods } },
  '/api/branding': { GET: { timeout, getData: mockData.brandings } },
  '/api/sales/markets': { POST: { timeout, getData: mockData.sales.markets } },
  '/api/sales/markets/overview': { POST: { timeout, getData: mockData.sales.marketsOverview } },
  '/api/sales/hiddenIds': { GET: { timeout, getData: mockData.sales.hiddenIds } },
  '/api/products': { POST: { timeout, getData: () => mockData.products() } },
  '/api/trends': { POST: { timeout, getData: mockData.trends } },
  '/api/trends/maxExportItems': { GET: { timeout, getData: mockData.maxExportItems } },
  '/api/settings': { GET: { timeout, getData: mockData.settings } },
  '/api/filterDependencies': { POST: { timeout, getData: mockData.filterDependencies } },
  '/api/apps': { GET: { timeout, getData: mockData.apps } },
};
