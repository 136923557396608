import type { IconName } from 'components/general/Icon';
import attributionStyles from './AttributionPage.module.scss';
import { object } from '@harmonya/utils';

const toIcons = <T extends keyof typeof attributionStyles>(
  ...items: [key: T, name: IconName, title: string][]
) => {
  const entries = items.map(
    ([key, name, title]) => [key, { name, title, className: attributionStyles[key] }] as const
  );

  return Object.fromEntries(entries) as Record<T, (typeof entries)[number][1]>;
};

export const attributionActionIcons = toIcons(
  ['validated', 'circle-check', 'Validated'],
  ['edited', 'circle-edit', 'Edited']
);

export const attributionConfidenceLevelIcons = toIcons(
  ['low', 'diamond', 'Low'],
  ['medium', 'diamond', 'Medium'],
  ['high', 'diamond', 'High']
);

// Attribution legend titles
export const valueTypesTitle = 'Value Types';
export const sourceInputTitle = 'Source Input';
export const confidenceLevelTitle = 'Confidence Level';

export const analyticsPanelPredictionIcons = object.keys(attributionConfidenceLevelIcons).reverse();
