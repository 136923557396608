import { useCallback, useState } from 'react';

const useAsyncError = (): ((error: Error) => void) => {
  // eslint-disable-next-line react/hook-use-state
  const [_, setError] = useState<Error>();

  return useCallback((error: Error) => {
    setError(() => {
      throw error;
    });
  }, []);
};

export default useAsyncError;
