import type { ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';
import { styleVariables, getColorClassById } from '../../../utils/styleVariables';
import appStyles from '../../layout/App.module.scss';
import styles from './DiamondCloudItem.module.scss';
import type { ComputedItem, Item } from './DiamondCloud';
import { string } from '@harmonya/utils';

type Props<S extends Item, T extends ComputedItem<S>> = {
  item: T;
  displayValueGetter: (item: T) => ReactNode;
  onClick?: (item: T) => void;
  getColorId: (item: T) => number;
  getSubtext: (item: T) => string;
};

export function DiamondCloudItem<S extends Item, T extends ComputedItem<S>>(props: Props<S, T>) {
  const { item, displayValueGetter, onClick, getColorId, getSubtext } = props;
  const colorId = getColorId(item);
  const subtext = getSubtext(item);
  const handleClick = () => onClick?.(item);
  const pixelizedHypotenuse = string.pixelize(item.hypotenuse);
  const subtextMetricElement = (
    <div className={classNames(styles.text, styles.metricText)}>{displayValueGetter(item)}</div>
  );
  const subtextElement = (
    <div className={styles.subtextWrapper}>
      <div className={classNames(styles.text, styles.subtext)}>{subtext}</div>
      {subtextMetricElement}
    </div>
  );
  const isExpanded = item.fontSize > 10;

  return (
    <g
      className={classNames(
        appStyles.animatedFadeIn,
        getColorClassById(colorId),
        styles.container,
        onClick && styles.clickable
      )}
      style={
        {
          '--x': string.pixelize(item.rotatedTopLeft.x),
          '--y': string.pixelize(item.rotatedTopLeft.y),
        } as React.CSSProperties
      }
      onClick={onClick && handleClick}
    >
      <rect
        className={styles.diamond}
        rx={styleVariables.borderRadius}
        width={item.size}
        height={item.size}
      />
      <foreignObject
        className={styles.textOuterContainer}
        style={{ '--hypotenuse': pixelizedHypotenuse } as React.CSSProperties}
      >
        <div
          className={classNames(
            styles.textInnerContainer,
            isExpanded && styles.textInnerContainerWithSubtext
          )}
          style={{ fontSize: item.fontSize }}
        >
          <div
            className={classNames(
              appStyles.verticalFlex,
              appStyles.overflowHidden,
              styles.contentWrapper
            )}
          >
            <div className={styles.text}>{item.source.name}</div>
            {isExpanded && subtextElement}
          </div>
        </div>
      </foreignObject>
    </g>
  );
}
