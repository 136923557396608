import type {
  ActionInputSchema,
  ActorResponseSchema,
  DetailedSheet,
  RevisionRequestSchema,
  SheetResponseSchema,
  CellWithHistory,
  ItemWithCells,
} from '@harmonya/attribution.types';
import { PickByValue, array, iterator, map, number, set as setUtils } from '@harmonya/utils';
import type { ItemId } from 'components/general/dataTable/DataTableBase';
import type { statuses } from 'components/layout/attributionPage/AttributionPageSaveStatus';
import {
  productsAttributesMock,
  getAttributesMetadataMock,
} from 'components/layout/attributionPage/data';
import { useCallback } from 'react';
import {
  DefaultValue,
  atom,
  selector,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { historyAtom } from 'utils/historyAtom';
import { fetchGet, fetchPost } from '../utils/fetch';
import { authState, customerIdState } from './auth';
import type { ColumnWidth } from 'components/general/dataTable/context/ColumnWidthContext';
import { styleVariables } from 'utils/styleVariables';
import type {
  AttributeMetadata,
  ProductAttributeContent,
  ProductAttributes,
  ColumnId,
} from 'store/attribution.types';
import { attributionDataState } from 'store/attributionState';

// TODO: Implement default values for version and revision
const _TODO_VERSION_NUMBER = 1;
// TODO: have sheet selection logic once multiple sheet support is implemented. until than 1 is the first sheet created.
const _INITIAL_SHEED_ID = 1;
const _MOCK_SHEED_ID = 0;

export const sheetIdState = historyAtom<number>(
  'sheetId',
  'attribution',
  {
    key: 'sheetId',
    default: _INITIAL_SHEED_ID,
  },
  { parser: 'number' }
);

export const versionNumberState = atom<number>({
  key: 'versionNumber',
  default: _TODO_VERSION_NUMBER,
});

export const revisionNumberState = atom<number>({
  key: 'revisionNumber',
  default: _TODO_VERSION_NUMBER,
});

export const isDemoSheetState = selector({
  key: 'isDemoSheet',
  get: ({ get }) => get(sheetIdState) === _MOCK_SHEED_ID,
});

type ServerColumn = DetailedSheet['columns'][number];
const getAttributesMetadata = (
  columns: ServerColumn[],
  itemsCount: number
): AttributeMetadata[] => {
  const upcAttribute = {
    key: 0,
    title: `UPC (${number.format(itemsCount)})`,
    isPredicted: false,
    order: 0,
    type: '',
  };
  const attributesMetadata = [
    upcAttribute,
    ...columns.map(column => ({
      key: column.id,
      title: column.title,
      type: column.type,
      isPredicted: column.type === 'predicted',
      order: column.order,
      percision: column.precision,
      metadata: column.metadata || {},
    })),
  ];

  return attributesMetadata;
};

const getCellHistory = (revisionCell: CellWithHistory['revisionCell'] = []) => {
  return revisionCell.map(revisionData => {
    const {
      revision: { revisionNumber, actorId, timestamp, actions },
    } = revisionData;

    return {
      revisionNumber,
      actorId,
      timestamp,
      actions,
    };
  });
};

const getProductsAttributes = (items: ItemWithCells[]) => {
  const productsAttributeEntries: [number, ProductAttributes][] = items.map(item => {
    const entries = item.cells.map(cell => [
      cell.columnId,
      {
        value: cell.value,
        cellType: cell.cellType,
        isValidated: cell.cellType === 'validated',
        isEdited: cell.cellType === 'edited',
        isPredicted: cell.cellType === 'predicted',
        isProvided: cell.cellType === 'provided',
        confidenceLevel: cell.confidenceLevel || undefined,
        itemId: item.id,
        columnId: cell.columnId,
        history: getCellHistory(cell.revisionCell),
      },
    ]);

    // TODO: Remove UPC column as it would be regular column
    return [
      item.id,
      {
        0: {
          value: item.normUpc,
          columnId: 0,
          itemId: item.id,
          history: [],
        },
        ...Object.fromEntries(entries),

        // TODO: New time columns
      },
    ] as const;
  });

  const productsAttributes = new Map(productsAttributeEntries);

  return productsAttributes;
};

export const attributionSheetState = selector({
  key: 'attributionSheet',
  get: async ({ get }) => {
    const customerId = get(customerIdState);
    const sheetId = get(sheetIdState);
    const isDemoSheet = get(isDemoSheetState);
    const versionNumber = get(versionNumberState);
    const { user } = get(authState);
    // eslint-disable-next-line prefer-const
    let activeSheetId = sheetId;

    if (isDemoSheet) {
      return {} as SheetResponseSchema['sheet'];
    }

    /** @todo: refactor to outer hook and internal state */
    // if (sheetId === _INITIAL_SHEED_ID) {
    //   const sheetList = await fetchGet<SheetInfo[]>(
    //     '/api/attribution/sheets',
    //     customerId,
    //     user.email
    //   );

    //   const activeSheet = sheetList.find(sheet => sheet.active);
    //   if (!activeSheet) {
    //     throw new Error('No active sheet found');
    //   }

    //   // todo: add updating sheetIdState? there is other usage now
    //   const setSheetId = useSetRecoilState(sheetIdState);
    //   setSheetId(activeSheet.id);
    //   activeSheetId = activeSheet.id;
    // }

    const body = { sheetId: activeSheetId, versionNumber };
    const activeSheet = await fetchPost<SheetResponseSchema>(
      '/api/attribution/sheet',
      customerId,
      user.email,
      body
    );

    const { sheet } = activeSheet;

    /** @todo: set current version before this atom */
    // const setVersion = useSetRecoilState(versionNumberState);
    // setVersion(activeSheet.currentVersion);
    /** @todo: refactor to outer hook and internal state, that listen also to version change */
    // const setRevision = useSetRecoilState(revisionNumberState);
    // setRevision(activeSheet.currentRevision);
    return sheet;
  },
});

export const attributionModelTriggerTime = selector({
  key: 'attributionModelTriggerTime',
  get: ({ get }) => {
    const sheet = get(attributionSheetState);
    return sheet?.modelTriggerTime;
  },
});

export const attributionTableState = selector({
  key: 'attributionTable',
  get: async ({ get }) => {
    const isDemoSheet = get(isDemoSheetState);
    const sheetData = get(attributionSheetState);

    if (isDemoSheet) {
      const productsAttributes = await Promise.resolve(productsAttributesMock);
      const attributesMetadata = getAttributesMetadataMock(productsAttributes.size);

      // TODO: this is temp typing until the integration with the server data. remove and replace with the above
      return {
        attributesMetadata,
        productsAttributes,
      } as unknown as {
        attributesMetadata: AttributeMetadata[];
        productsAttributes: typeof productsAttributesMock;
      };
    }

    const { columns, items } = sheetData;
    const productsAttributes = getProductsAttributes(items);
    const attributesMetadata = getAttributesMetadata(columns, items.length);

    return { productsAttributes, attributesMetadata };
  },
});

export const attributionMetadataState = selector({
  key: 'attributionMetadata',
  get: ({ get }) => {
    const { attributesMetadata } = get(attributionTableState);

    return attributesMetadata;
  },
});

export const predictedColumnIdsState = selector({
  key: 'predictedColumnIds',
  get: ({ get }) => {
    const { attributesMetadata } = get(attributionTableState);
    const predictedColumns = new Set(
      iterator.definedMap(attributesMetadata, ({ key, isPredicted }) =>
        isPredicted ? key : undefined
      )
    );

    return predictedColumns;
  },
});

export const isDisabledColumnState = selectorFamily({
  key: 'isDisabledColumn',
  get:
    (columnId: ColumnId) =>
    ({ get }) => {
      const predictedColumns = get(predictedColumnIdsState);
      const isPredicted = predictedColumns.has(columnId);

      return !isPredicted;
    },
});

export type RevisionAction = Omit<
  ActionInputSchema,
  'isValidated' | 'columnUniqueIdentifier' | 'itemUniqueIdentifier'
>;

// on set, set the revision number to the next number
export const attributionRevisionsState = atom<RevisionRequestSchema[]>({
  key: 'attributionRevisions',
  default: [],
});

export const cellState = selectorFamily({
  key: 'cellState',
  get:
    ({ columnId, itemId }: { columnId: ColumnId; itemId: ItemId }) =>
    ({ get }) => {
      const { productsAttributes } = get(attributionDataState);
      // For efficiency reasons, we assume that the productAttributes are always present
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const productAttributes = productsAttributes.get(itemId)![columnId];

      return productAttributes;
    },
});

function createBooleanColumnKeyState(stateKey: string) {
  const columnKeysState = atom<Set<ColumnId>>({
    key: `attribution${stateKey}ColumnKeys`,
    default: new Set(),
  });

  const setColumnKeyState = selectorFamily<boolean, ColumnId>({
    key: `attribution${stateKey}ColumnKey`,
    get:
      key =>
      ({ get }) => {
        const columnKeys = get(columnKeysState);

        return columnKeys.has(key);
      },
    set:
      key =>
      ({ set, get }, isIncluded) => {
        const columnKeys = get(columnKeysState);
        const currentState = isIncluded instanceof DefaultValue ? undefined : !isIncluded;
        const newColumnKeys = setUtils.toggleSet(columnKeys, key, false, currentState);

        set(columnKeysState, newColumnKeys);
      },
  });

  const singleToggleHook = (columnId: ColumnId) => {
    const [isIncludedColumn, setIsIncludedColumn] = useRecoilState(setColumnKeyState(columnId));
    const toggleColumn = useCallback(
      () => setIsIncludedColumn(!isIncludedColumn),
      [isIncludedColumn]
    );

    return [toggleColumn, isIncludedColumn] as const;
  };

  const toggleHook = () => {
    const [columns, setColumns] = useRecoilState(columnKeysState);
    const toggleColumn = useCallback(
      (columnId: ColumnId) => {
        const newColumns = setUtils.toggleSet(columns, columnId);

        setColumns(newColumns);
      },
      [columns]
    );

    const changeOrder = useCallback(
      (columnId: ColumnId, order: number) => {
        const newColumns = setUtils.changeOrder(columns, columnId, order);

        setColumns(newColumns);
      },
      [columns]
    );

    return [columns, toggleColumn, changeOrder] as const;
  };

  return [columnKeysState, setColumnKeyState, singleToggleHook, toggleHook] as const;
}

export const [
  hiddenColumnKeysState,
  isHiddenColumnKeyState,
  useAttributionHiddenColumn,
  useAttributionHiddenColumns,
] = createBooleanColumnKeyState('Hidden');

export const [
  pinnedColumnKeysState,
  isPinnedColumnKeyState,
  useAttributionPinnedColumn,
  useAttributionPinnedColumns,
] = createBooleanColumnKeyState('Pinned');

export const defaultColumnIdWidthsState = selector({
  key: 'defaultColumnIdWidths',
  get: ({ get }) => {
    const { attributesMetadata } = get(attributionTableState);
    const entries = attributesMetadata.map<[ColumnId, ColumnWidth]>(item => [item.key, undefined]);
    const columnIdWidths = new Map(entries);

    return columnIdWidths;
  },
});

export const emptyValueText = 'Not Predicted';

export const columnIdMaxWidthState = selectorFamily({
  key: 'columnIdMaxWidth',
  get:
    (columnId: ColumnId) =>
    ({ get }) => {
      const { productsAttributes } = get(attributionTableState);
      const values = iterator.definedMap(productsAttributes.values(), item => item[columnId].value);
      const charEstimationSizePx = 6;
      const padding = styleVariables.padding * 2;
      const mostLengthCellValue = iterator.max([emptyValueText, ...values], value => value.length);

      return mostLengthCellValue.length * charEstimationSizePx + padding;
    },
});

export const columnIdWidthsState = atom({
  key: 'columnIdWidths',
  default: defaultColumnIdWidthsState,
});

export const attributionOriginSortedColumnState = selector({
  key: 'attributionOriginSortedColumn',
  get: ({ get }) => {
    const { attributesMetadata } = get(attributionTableState);
    const sortedAttributesMetadata = attributesMetadata.toSorted((a, b) => a.order - b.order);
    const sortedAttributesMetadataAsMap = new Map(
      sortedAttributesMetadata.map(item => [item.key, item])
    );

    return sortedAttributesMetadataAsMap;
  },
});

export const attributionSortedColumnState = atom({
  key: 'attributionSortedColumn',
  default: attributionOriginSortedColumnState,
});

export const attributionSortedColumnKeysState = selector({
  key: 'attributionSortedColumnKeys',
  get: ({ get }) => {
    const sortedColumn = get(attributionSortedColumnState);

    return [...sortedColumn.keys()];
  },
});

export const useAttributionSortedColumnReordererState = () => {
  const pinnedColumnKeys = useRecoilValue(pinnedColumnKeysState);
  const [attributionMetadata, setAttributionMetadata] = useRecoilState(
    attributionSortedColumnState
  );

  const changeOrder = useCallback(
    (columnKey: ColumnId, order: number) => {
      let pinnedColumnsIndexedBeforeOrderCount = 0;

      for (const key of attributionMetadata.keys()) {
        if (pinnedColumnKeys.has(key)) {
          pinnedColumnsIndexedBeforeOrderCount++;
        } else if (key === columnKey) {
          break;
        }
      }

      const computedOrder = order + pinnedColumnsIndexedBeforeOrderCount;
      const newAttributionMetadata = map.changeOrder(attributionMetadata, columnKey, computedOrder);
      setAttributionMetadata(newAttributionMetadata);
    },
    [attributionMetadata, pinnedColumnKeys]
  );

  return changeOrder;
};

export const attributionSortedColumnIncludesPinnedState = selector({
  key: 'attributionSortedColumnIncludesPinned',
  get: ({ get }) => {
    const attributionSortedColumn = get(attributionSortedColumnState);
    const pinnedColumnKeys = get(pinnedColumnKeysState);

    const pinnedColumns = iterator.definedMap(pinnedColumnKeys, key =>
      attributionSortedColumn.get(key)
    );
    const unpinnedColumns = iterator.definedMap(attributionSortedColumn, ([key, value]) =>
      pinnedColumnKeys.has(key) ? undefined : value
    );
    const allColumns = [...pinnedColumns, ...unpinnedColumns];

    return { pinnedColumns, unpinnedColumns, allColumns };
  },
});

export type AttributePredictions = {
  highLevelPrediction: number;
  mediumLevelPrediction: number;
  lowLevelPrediction: number;
};

export type AttributePerformance = {
  key: ColumnId;
  title: string;
  accuracy: number;
  coverage: number;
  attributeValuesTotal: number;
  attributeValidatedValuesTotal: number;
  predictions: AttributePredictions;
};

export type AnalyticsTotal = {
  title: string;
  value: number;
  tooltip?: string;
};

const getAnalyticsTotals = (productsAttributesSize: number): AnalyticsTotal[] => {
  return [{ title: 'Total Products', value: productsAttributesSize }];
};

const getAttributesContentsMap = (productsAttributes: Map<ItemId, ProductAttributes>) => {
  const result: Record<ColumnId, ProductAttributeContent[]> = {};

  for (const item of productsAttributes.values()) {
    for (const key in item) {
      const content = item[key];

      if (content.value) {
        array.ensuredPush(result, +key, content);
      }
    }
  }

  return result;
};

const getAnalyticsPrediction = (
  confidenceLevel: ProductAttributeContent['confidenceLevel'],
  attributeContents: ProductAttributeContent[]
) =>
  iterator.count(attributeContents, content => content.confidenceLevel === confidenceLevel) /
  iterator.count(
    attributeContents,
    ({ isProvided, isValidated, isEdited }) => !isProvided && !isValidated && !isEdited
  );

const getAnalyticsAttributesPerformances = (
  attributesMetadata: AttributeMetadata[],
  productsAttributes: Map<ItemId, ProductAttributes>
): AttributePerformance[] => {
  const attributesContentsMap = getAttributesContentsMap(productsAttributes);

  const analyticsAttributesPerformances = iterator.definedMap(
    attributesMetadata,
    ({ key, title, precision, isPredicted }) => {
      const attributeContents = attributesContentsMap[key];

      if (isPredicted && attributeContents) {
        return {
          key,
          title,
          accuracy: (precision ?? 0) / 100,
          coverage: attributeContents.length / productsAttributes.size,
          attributeValuesTotal: attributeContents.length,
          attributeValidatedValuesTotal: iterator.count(
            attributeContents,
            content => content.isValidated
          ),
          predictions: {
            highLevelPrediction: getAnalyticsPrediction('high', attributeContents),
            mediumLevelPrediction: getAnalyticsPrediction('medium', attributeContents),
            lowLevelPrediction: getAnalyticsPrediction('low', attributeContents),
          },
        };
      }
    }
  );

  return analyticsAttributesPerformances;
};

export const attributionAnalyticsPanelDataState = selector({
  key: 'attributionAnalyticsPanelData',
  get: ({ get }) => {
    const { attributesMetadata, productsAttributes } = get(attributionDataState);
    const analyticsTotals = getAnalyticsTotals(productsAttributes.size);
    const analyticsAttributesPerformances = getAnalyticsAttributesPerformances(
      attributesMetadata,
      productsAttributes
    );

    return { analyticsTotals, analyticsAttributesPerformances };
  },
});

export const attributionPerformanceBoostingTagState = historyAtom<ColumnId | undefined>(
  'performanceBoostingTag',
  'attribution',
  {
    key: 'performanceBoostingTag',
    default: undefined,
  },
  { parser: 'number' }
);

export type PerformanceBoostingSortProperty = keyof PickByValue<AttributePerformance, number>;

export const attributionPerformanceBoostingSortPropertyState =
  historyAtom<PerformanceBoostingSortProperty>('performanceBoostingSortProperty', 'attribution', {
    key: 'performanceBoostingSortProperty',
    default: 'accuracy',
  });

export const attributionActorsState = selector({
  key: 'attributionActors',
  get: async ({ get }) => {
    const { user } = get(authState);
    const customerId = get(customerIdState);
    const actors = await fetchGet<ActorResponseSchema[]>(
      '/api/attribution/actors',
      customerId,
      user.email
    );

    const actorsAsMap = new Map(actors.map(actor => [actor.id, actor]));

    return actorsAsMap;
  },
});

export const attributionActorState = selector({
  key: 'attributionActor',
  get: async ({ get }) => {
    const { user } = get(authState);
    const customerId = get(customerIdState);
    const actors = get(attributionActorsState);

    const id = user.email;
    const actor =
      actors.get(id) ??
      (await fetchPost<ActorResponseSchema>('/api/attribution/actor', customerId, id, {
        id: id,
        displayName: user.name,
      }));

    return actor;
  },
});

export const saveStatusState = atom<keyof typeof statuses>({
  key: 'saveStatus',
  default: 'saved',
  effects: [
    ({ onSet, setSelf }) => {
      onSet(newValue => {
        if (newValue === 'recentlySaved') {
          setTimeout(() => {
            setSelf('saved');
          }, 3000);
        }
      });
    },
  ],
});
