type Fetch = () => Promise<void>;

const waitingExecutions: { action: Fetch; groupKey: string }[] = [];
const runningExecutions = new Set<string>();
const maxConcurrent = 10;

export function throttledFetch(input: RequestInfo, groupKey: string, init?: RequestInit) {
  return new Promise<Response>(resolve => {
    const execute = async () => {
      runningExecutions.add(groupKey);

      if (waitingExecutions.find(exec => exec.groupKey === groupKey)) {
        resolve(new Response('{}'));
      } else {
        const response = await fetch(input, init);

        runningExecutions.delete(groupKey);

        resolve(response);
      }

      executeNextPostponedExecution();
    };

    if (runningExecutions.size <= maxConcurrent && !runningExecutions.has(groupKey)) {
      void execute();
    } else {
      waitingExecutions.push({ action: execute, groupKey });
    }
  });
}

function executeNextPostponedExecution() {
  const queuedExecution = waitingExecutions.shift();

  void queuedExecution?.action();
}
