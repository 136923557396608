import React, { useRef } from 'react';
import type { DropdownRef } from './dropdown/Dropdown';
import { Dropdown } from './dropdown/Dropdown';
import classNames from 'classnames';
import appStyles from '../layout/App.module.scss';
import styles from './ExportButton.module.scss';
import { Icon } from './Icon';
import { useRecoilValue } from 'recoil';
import { analyticsState } from 'store/analytics';
import type { tooltipTexts } from 'tooltipTexts';
import { ExportButtonDropdown } from './ExportButtonDropdown';

export type ExportOption = {
  key: string;
  title: string;
  fileContent?: Record<string, unknown>[];
  disabled?: boolean;
  children?: ExportOption[];
};

export type OptionsHook = () => {
  options: ExportOption[];
  isAllDisabled?: boolean | undefined;
  disabledTooltip?: keyof (typeof tooltipTexts)['common'];
};

type Props = {
  pageKey: 'explore' | 'comparison';
  useOptions: OptionsHook;
  minWidth?: number;
};

export function ExportButton(props: Props) {
  const { useOptions, pageKey, minWidth } = props;
  const dropdownRef = useRef<DropdownRef>({});
  const analytics = useRecoilValue(analyticsState);
  const handleExportButtonClick = () => {
    analytics.track('Export button clicked', { name: 'Export', type: 'button' });
  };

  const buttonContent = (
    <button
      className={classNames(appStyles.horizontalFlex, appStyles.buttonBorder, styles.button)}
      onClick={handleExportButtonClick}
    >
      <Icon name='arrow-down-to-line' weight='regular' />
      Export
      <Icon name='chevron-down' weight='regular' />
    </button>
  );

  return (
    <Dropdown
      dropdownRef={dropdownRef}
      buttonContent={buttonContent}
      align='right'
      minWidth={minWidth}
      dropdownBorderedClassName={styles.dropdown}
    >
      <ExportButtonDropdown useOptions={useOptions} pageKey={pageKey} />
    </Dropdown>
  );
}
