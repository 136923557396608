export const array = (() => {
  const isIndexIncluded = (index: number) => index !== -1;

  const remove = <T>(items: T[], item: T, mutable = false) => {
    let result = items;

    if (mutable) {
      const index = items.indexOf(item);

      if (isIndexIncluded(index)) {
        items.splice(index, 1);
      }
    } else {
      result = items.filter(currentItem => currentItem !== item);
    }

    return result;
  };

  const toggle = <T>(
    items: T[],
    item: T,
    mutable = false,
    includes = items.includes(item)
  ): T[] => {
    let result = items;

    if (mutable) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      includes ? remove(items, item, true) : items.push(item);
    } else {
      result = includes ? remove(items, item) : items.concat(item);
    }

    return result;
  };

  const isEquals = <T>(first: T[], second: T[], ignoreOrder = true) =>
    first.length === second.length &&
    (ignoreOrder
      ? first.every(item => second.includes(item)) && second.every(item => first.includes(item))
      : first.every((item, i) => second[i] === item));

  const last = <T>(items: T[]) => items[items.length - 1];

  const ensure = <T>(item: T | T[]): T[] => (Array.isArray(item) ? item : [item]);

  function ensuredPush<T, K extends string | number | symbol>(
    parent: NonNullable<Record<K, T[]>>,
    key: K,
    ...items: T[]
  ): NonNullable<Record<K, T[]>> {
    parent[key] ??= [] as NonNullable<Record<K, T[]>>[K];

    parent[key].push(...items);

    return parent;
  }

  const isEmpty = (items: unknown) => Array.isArray(items) && !items.length;

  const isDefined = <T>(item: T | undefined): item is T => item != null;

  /**
   * Creats an array of consecutive integers in the desired range.
   * @param length The length of the desired range.
   * @returns An array of consecutive integers, starting from 0.
   * @example range(3) // [0, 1, 2]
   */
  function range(length: number): number[];

  /**
   * Creats an array of consecutive integers in the desired range.
   * @param start The number from which to start the array.
   * @param end The number at which to end the array.
   * @returns An array of consecutive integers.
   * @example range(2, 6) // [2, 3, 4, 5, 6]
   */
  function range(start: number, end: number): number[];

  function range(startOrLength: number, end?: number) {
    const length = end == null ? startOrLength - 1 : end - startOrLength + 1;
    const start = end == null ? 0 : startOrLength;
    const result = Array.from({ length }, (_, i) => start + i);

    return result;
  }

  function getPercentile(percentages: number[], percentile: number): number | null {
    if (!percentages.length) {
      return null;
    }

    const sortedPercentages = [...percentages].sort((a, b) => a - b);
    const index = ((sortedPercentages.length - 1) * percentile) / 100;
    const lowerIndex = Math.floor(index);
    const upperIndex = Math.ceil(index);

    if (lowerIndex === upperIndex) {
      return sortedPercentages[lowerIndex];
    }

    const weight = index - lowerIndex;
    return sortedPercentages[lowerIndex] * (1 - weight) + sortedPercentages[upperIndex] * weight;
  }

  return {
    isIndexIncluded,
    remove,
    toggle,
    isEquals,
    last,
    ensure,
    ensuredPush,
    isEmpty,
    isDefined,
    range,
    getPercentile,
  };
})();

export const assign = <T, K extends object>(items: T[], props: K) =>
  items.map(item => ({ ...item, ...props }));
