import type { RecoilState, Resetter, SetterOrUpdater } from 'recoil';
import { useRecoilValue, useResetRecoilState, useRecoilState } from 'recoil';

export function useResettableRecoilValue<T>(recoilState: RecoilState<T>): [T, Resetter] {
  const value = useRecoilValue<T>(recoilState);
  const resetter = useResetRecoilState(recoilState);

  return [value, resetter];
}

export function useResettableRecoilState<T>(
  recoilState: RecoilState<T>
): [T, SetterOrUpdater<T>, Resetter] {
  const state = useRecoilState<T>(recoilState);
  const resetter = useResetRecoilState(recoilState);

  return [...state, resetter];
}
