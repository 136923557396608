import type { ReactNode } from 'react';
import React, { memo, useContext, useMemo } from 'react';
import { type Column } from './DataTableBase';
import { DataTableIndexCell } from './DataTableIndexCell';
import styles from './DataTablePinCells.module.scss';
import { PinnedColumnsContext } from './context/PinnedColumnsContext';
import { HiddenColumnsContext } from './context/HiddenColumnsContext';
import { iterator } from '@harmonya/utils';
import { OrderedColumnsContext } from './context/OrderedColumnsContext';

type Props<T extends Column> = {
  columns: T[];
  index?: number;
  children: (column: T, nextColumn: T | undefined, prevColumn: T | undefined) => ReactNode;
};

export const DataTablePinCells = memo(function DataTablePinCells<T extends Column>(
  props: Props<T>
) {
  const { columns, index, children } = props;
  const orderedColumnKeys = useContext(OrderedColumnsContext);
  const pinnedColumnKeys = useContext(PinnedColumnsContext);
  const hiddenColumnKeys = useContext(HiddenColumnsContext);
  const orderedColumn = useMemo(
    () =>
      orderedColumnKeys.length
        ? iterator.definedMap(orderedColumnKeys, key => columns.find(column => column.key === key))
        : columns,
    [orderedColumnKeys, columns]
  );

  const displayedColum = useMemo(
    () =>
      hiddenColumnKeys.size
        ? orderedColumn.filter(column => !hiddenColumnKeys.has(column.key))
        : orderedColumn,
    [hiddenColumnKeys, orderedColumn]
  );
  const [pinnedColumns, unpinnedColumns, allColumns] = useMemo(() => {
    if (pinnedColumnKeys.size) {
      const splitted = iterator.split(displayedColum, column => pinnedColumnKeys.has(column.key));

      return [...splitted, splitted.flatMap(e => e)];
    }

    return [[], displayedColum, displayedColum];
  }, [displayedColum, pinnedColumnKeys]);
  const renderColumns = (currentColumns: T[]) =>
    currentColumns.map((column, i) => (
      <React.Fragment key={column.key}>
        {children(column, allColumns[i + 1], allColumns[i - 1])}
      </React.Fragment>
    ));

  return (
    <>
      <div className={styles.pinned}>
        <DataTableIndexCell index={index} />
        {renderColumns(pinnedColumns)}
      </div>
      {renderColumns(unpinnedColumns)}
    </>
  );
});
