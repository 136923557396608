import classNames from 'classnames';
import type { CSSProperties, ComponentProps } from 'react';
import React, { useRef, useState } from 'react';
import styles from './Slider.module.scss';
import { Input } from './Input';
import appStyles from '../layout/App.module.scss';
import { styleVariables } from '../../utils/styleVariables';
import { useResize } from '../../hooks/useResize';
import { analyticsState } from 'store/analytics';
import { useRecoilValue } from 'recoil';
import type { Stringable } from '@harmonya/utils';
import { number as numberUtil } from '@harmonya/utils';

export type RangeValue<T = number> = readonly [T | undefined, T | undefined];

type HorizontalPosition = { left: number; right: number };

type InputSide = 'start' | 'end';

const getTickMarksValues = (min: number, max: number, range: number, tickMarksCount: number) => {
  const values = [];
  const step = range / tickMarksCount;
  const floorMin = Math.floor(min);
  const ceilMax = Math.ceil(max);

  for (let i = 0; i < tickMarksCount; i++) {
    const value = Math.floor(min + step * i);

    if (value !== floorMin && value !== ceilMax) {
      values.push(value);
    }
  }

  return values;
};

const isTouched = (first: HorizontalPosition, second: HorizontalPosition) =>
  !(first.left > second.right || first.right < second.left);

type Props = {
  value: RangeValue;
  formatter?: (value?: number) => Stringable | undefined;
  min: number;
  max: number;
  color?: string;
  onChange: (value: RangeValue) => void;
  isPercent?: boolean;
  isDisabled?: boolean;
  isSingle?: boolean;
  rounded?: boolean;
  tickMarks?: {
    count: number;
    labelsEnabled?: boolean;
  };
  direction?: 'horizontal' | 'vertical';
  step?: number;
  analyticsEventTitle?: string;
  className?: string;
  inputClassName?: string;
};

export function Slider(props: Props) {
  const {
    value,
    formatter,
    min,
    max,
    color,
    onChange,
    isPercent,
    isDisabled,
    isSingle,
    rounded,
    tickMarks,
    direction = 'vertical',
    step,
    analyticsEventTitle,
    className,
    inputClassName,
  } = props;
  const [frontSlider, setFrontSlider] = useState<InputSide>('start');
  const startInputRef = useRef<HTMLInputElement>(null);
  const endInputRef = useRef<HTMLInputElement>(null);
  const startInputRect = useResize(startInputRef);
  const factor = isPercent ? 100 : 1;
  const parseByFactor = (number: number) => number * factor;
  const computedStep = step ?? isPercent ? 0.1 : 1;
  const analytics = useRecoilValue(analyticsState);

  const unparseByFactor = (number: number) => {
    const factorDigitsCount = numberUtil.getDigitsCount(factor);
    const precisionDigitsCount = numberUtil.getPrecisionDigitsCount(number);
    const unparsedNumber = number / factor;
    const fixedMaxPrecisionDigitsCount = factorDigitsCount - 1 + precisionDigitsCount;
    const fixedUnparsedNumber = numberUtil.fixedNumber(
      unparsedNumber,
      10 ** fixedMaxPrecisionDigitsCount
    );

    return fixedUnparsedNumber;
  };

  const parse = (newValue: RangeValue, method: typeof parseByFactor | typeof unparseByFactor) =>
    newValue.map(valuePart =>
      valuePart == null ? valuePart : method(valuePart)
    ) as unknown as RangeValue;
  const convertedMin = parseByFactor(min);
  const convertedMax = parseByFactor(max);
  const [startValue, endValue] = parse(value, parseByFactor);
  const [startFormattedValue, endFormattedValue] = formatter ? value.map(formatter) : value;
  const range = Math.abs(convertedMax - convertedMin);
  const tickMarksValues = getTickMarksValues(
    convertedMin,
    convertedMax,
    range,
    tickMarks?.count ?? 0
  );
  const getStyle = (inputSide: InputSide) =>
    ({
      background: color,
      pointerEvents: frontSlider === inputSide ? undefined : 'none',
    }) as CSSProperties;
  const getNumberInRange = (number: number) =>
    numberUtil.minmax(number, convertedMin, convertedMax);

  const getErrorMessage = (number: number) => {
    if (number < convertedMin) {
      return `Please enter a number greater than ${numberUtil.format(convertedMin)}`;
    }

    if (number > convertedMax) {
      return `Please enter a number less than ${numberUtil.format(convertedMax)}`;
    }
  };

  const valueChange = (newValuePart: number, getter: (number: number) => RangeValue) => {
    const newValue = getter(newValuePart);
    const parsedValue = parse(newValue, unparseByFactor);

    onChange(parsedValue);
  };

  const startValueChange = (newValuePart: number) =>
    valueChange(newValuePart, newValue => {
      const roundedNewValuePart = rounded ? Math.floor(newValue) : newValue;
      const stickedNewValue = newValue - 1 < convertedMin ? convertedMin : roundedNewValuePart;
      const computedEndValue = getNumberInRange(Math.max(endValue ?? convertedMax, newValue + 1));

      return [stickedNewValue, computedEndValue];
    });
  const endValueChange = (newValuePart: number) =>
    valueChange(newValuePart, newValue => {
      const roundedNewValuePart = rounded ? Math.ceil(newValue) : newValue;
      const stickedNewValue = newValue + 1 > convertedMax ? convertedMax : roundedNewValuePart;
      const computedStartValue = getNumberInRange(
        Math.min(startValue ?? convertedMin, newValue - 1)
      );

      return [computedStartValue, stickedNewValue];
    });

  const trackEvent = (eventType: string) => {
    const type = eventType === 'change' ? 'drag' : eventType;

    analytics.track('Slider changed', { name: analyticsEventTitle, value, type });
  };

  const valueChangeGetter = (setter: typeof endValueChange) => {
    const func = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValuePart = Number(event.target.value);

      setter(newValuePart);
      trackEvent(event.type);
    };

    return func;
  };

  const getAsRelative = (number: number) => ((number - convertedMin) * 100) / range;

  const getThumbX = (target: HTMLInputElement) => {
    const { width, x } = target.getBoundingClientRect();
    const thumbX = (width / 100) * getAsRelative(target.valueAsNumber) + x;

    return thumbX;
  };

  const updateFrontSlider = (event: React.MouseEvent<HTMLInputElement>) => {
    if (startInputRef.current && endInputRef.current) {
      const mouseX = event.nativeEvent.x;
      const startThumbX = getThumbX(startInputRef.current);
      const endThumbX = getThumbX(endInputRef.current);
      const startDiff = Math.abs(mouseX - startThumbX);
      const endDiff = Math.abs(mouseX - endThumbX);
      const newFrontSlider = startDiff > endDiff ? 'end' : 'start';

      if (newFrontSlider !== frontSlider) {
        setFrontSlider(newFrontSlider);
      }
    }
  };

  const getInput = (inputProps: ComponentProps<typeof Input>) => (
    <Input
      {...inputProps}
      className={styles.input}
      min={convertedMin}
      max={convertedMax}
      type='number'
      autoExpanded
    />
  );
  const getSliderInput = (
    inputProps: React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
  ) => (
    <input
      {...inputProps}
      type='range'
      className={styles.slider}
      onMouseMove={updateFrontSlider}
      min={convertedMin}
      max={convertedMax}
      step={computedStep}
    />
  );

  const getThumbHorizontalPosition = (size: number, thumbValue?: number) => {
    if (startInputRect && thumbValue != null && isFinite(thumbValue)) {
      const absoluteValue = thumbValue - convertedMin;
      const convertedRange = convertedMax - convertedMin;
      const thumbSize = styleVariables.inputRangeThumbSize;
      const halfThumbSize = styleVariables.inputRangeThumbSize / 2;
      const left =
        (absoluteValue / convertedRange) * (startInputRect.width - thumbSize) +
        halfThumbSize -
        size / 2;
      const right = left + size;

      return { left, right };
    }
  };

  const startValueHorizontalPosition = getThumbHorizontalPosition(
    styleVariables.inputRangeThumbSize,
    startValue
  );
  const endValueHorizontalPosition = getThumbHorizontalPosition(
    styleVariables.inputRangeThumbSize,
    endValue
  );
  const isUnderThumb = (tickMark?: HorizontalPosition) =>
    tickMark &&
    startValueHorizontalPosition &&
    endValueHorizontalPosition &&
    (isTouched(startValueHorizontalPosition, tickMark) ||
      isTouched(endValueHorizontalPosition, tickMark));

  const renderTickMark = (tickMarkValue: number, i: number) => {
    const tickMarkValueAsPercent = isPercent ? tickMarkValue / 100 : tickMarkValue;
    const horizontalPosition = getThumbHorizontalPosition(
      styleVariables.inputRangeTickMarkSize,
      tickMarkValue
    );

    return (
      <option
        key={i}
        style={{ left: horizontalPosition?.left }}
        className={classNames(styles.tickMark, isUnderThumb(horizontalPosition) && styles.hide)}
        value={formatter ? formatter(tickMarkValueAsPercent)?.toString() : tickMarkValueAsPercent}
        onClick={() => {
          const getDiff = (sourceValue?: number) =>
            Math.abs((sourceValue ?? tickMarkValue) - tickMarkValue);
          const diffFromStartValue = getDiff(startValue);
          const diffFromEndValue = getDiff(endValue);
          const changeFunc =
            diffFromStartValue < diffFromEndValue ? startValueChange : endValueChange;

          changeFunc(tickMarkValue);
        }}
      />
    );
  };

  const [styleStartValue, styleEndValue] = isSingle
    ? [0, 100]
    : [getAsRelative(startValue ?? convertedMin), getAsRelative(endValue ?? convertedMax)];
  const style = {
    '--start-value': `${styleStartValue}%`,
    '--end-value': `${styleEndValue}%`,
  } as CSSProperties;

  return (
    <div
      className={classNames(
        direction === 'vertical'
          ? appStyles.verticalFlex
          : [appStyles.horizontalFlex, appStyles.alignCenter],
        isDisabled && styles.disabled,
        className
      )}
      style={style}
    >
      <div
        className={classNames(
          appStyles.horizontalFlex,
          appStyles.flexGrow1,
          appStyles.justifySpaceBetween,
          inputClassName
        )}
      >
        {getInput({
          value: startValue,
          formattedValue: startFormattedValue,
          onInput: valueChangeGetter(startValueChange),
          error: getErrorMessage(startValue ?? convertedMin),
        })}
        {!isSingle &&
          getInput({
            value: endValue,
            formattedValue: endFormattedValue,
            onInput: valueChangeGetter(endValueChange),
            error: getErrorMessage(endValue ?? convertedMax),
          })}
      </div>
      <div
        className={classNames(
          appStyles.horizontalFlex,
          appStyles.flexGrow1,
          styles.innerContainer,
          direction === 'horizontal' && styles.horizontal,
          tickMarks?.labelsEnabled && [appStyles.wrap, styles.labelsEnabled]
        )}
      >
        {getSliderInput({
          ref: startInputRef,
          value: startValue,
          onChange: valueChangeGetter(startValueChange),
          style: getStyle('start'),
        })}
        {!isSingle &&
          getSliderInput({
            ref: endInputRef,
            value: endValue,
            onChange: valueChangeGetter(endValueChange),
            style: getStyle('end'),
          })}
        {!isDisabled && tickMarksValues.map(renderTickMark)}
      </div>
    </div>
  );
}
