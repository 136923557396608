import { map } from '@harmonya/utils';
import { throttledFetch } from './throttledFetch';

const cache = new Map<string, Response | undefined>();
const retryInterval = 200;
const limit = 100;

function getReusableResponse(response: Response) {
  return response.clone();
}

export async function cachedFetch(
  key: string,
  input: RequestInfo,
  groupKey: string,
  init?: RequestInit
) {
  if (cache.has(key)) {
    const cachedResponse = cache.get(key);

    if (cachedResponse) {
      return getReusableResponse(cachedResponse);
    }

    const retryResponse = await new Promise<Response>(resolve => {
      setTimeout(() => {
        void cachedFetch(key, input, groupKey, init).then(resolve);
      }, retryInterval);
    });

    return retryResponse;
  }

  setWithLimit(key);

  const response = await throttledFetch(input, groupKey, init);

  cache.set(key, response);

  return getReusableResponse(response);
}

function setWithLimit(key: string) {
  // Remove to re-insert it at the end of the queue, to ensure that the removal (if we have reached the limit) will
  // always remove the less frequent item
  cache.delete(key);

  if (cache.size >= limit) {
    const firstKey = map.getFirstKey(cache);

    cache.delete(firstKey);
  }

  // 'undefined' indicates that is loading
  cache.set(key, undefined);
}
