import type { ReactNode, MouseEvent } from 'react';
import React from 'react';
import classNames from 'classnames';
import appStyles from '../layout/App.module.scss';
import styles from './ButtonGroup.module.scss';
import { Tooltip } from './Tooltip';
import { analyticsState } from 'store/analytics';
import { useRecoilValue } from 'recoil';

interface Button<T> {
  value: T;
  element: ReactNode;
  tooltip?: ReactNode;
  disabled?: boolean;
}

type Props<T> = {
  children: Button<T>[];
  selectedValue?: T;
  className?: string;
  onClick: (clickedValue: T) => void;
  analyticsEventTitle?: string;
  contentFitted?: boolean;
};

export function ButtonGroup<T>(props: Props<T>) {
  const { children, selectedValue, className, onClick, analyticsEventTitle, contentFitted } = props;
  const analytics = useRecoilValue(analyticsState);

  const onChange = (event: MouseEvent, value: T) => {
    event.stopPropagation();

    if (analyticsEventTitle) {
      analytics.track(`${analyticsEventTitle} click`, {
        name: analyticsEventTitle,
        type: 'buttonGroup',
        value,
      });
    }

    onClick(value);
  };

  return (
    <div
      className={classNames(
        appStyles.horizontalFlex,
        appStyles.gap1,
        appStyles.border,
        appStyles.backgroundBackground,
        appStyles.unclickedButton,
        styles.container,
        className
      )}
    >
      {children.map(({ value, element, tooltip, disabled }, i) => {
        const button = (
          <button
            key={i}
            className={classNames(
              appStyles.box,
              styles.button,
              selectedValue === value && styles.active,
              disabled,
              contentFitted && styles.fitContent
            )}
            disabled={disabled}
            onClick={event => onChange(event, value)}
          >
            {element}
          </button>
        );

        return tooltip ? (
          <Tooltip key={i} content={tooltip} verticalDirection='bottom'>
            {button}
          </Tooltip>
        ) : (
          button
        );
      })}
    </div>
  );
}
