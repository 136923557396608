import { number, string } from '@harmonya/utils';
import classNames from 'classnames';
import type { DropDownDirection } from 'components/general/charts/types';
import type { CSSProperties, ReactNode, RefObject } from 'react';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import { createPortal } from '../../../utils/portal';
import { styleVariables } from '../../../utils/styleVariables';
import '../../layout/App.module.scss';
import styles from './DropdownWindow.module.scss';

type Width = string | number;

export type Align = 'left' | 'right' | 'center';

type Props = {
  content: ReactNode;
  open: () => void;
  isOpen: boolean;
  windowRef: RefObject<HTMLDivElement>;
  buttonRef: RefObject<HTMLDivElement>;
  inputRef?: RefObject<HTMLInputElement>;
  borderedClassName?: string;
  direction?: DropDownDirection;
  maxWidth?: Width;
  minWidth?: Width;
  align?: Align;
  offset?: number;
  isDraggable?: boolean;
};

const getHorizontalPosition = (x: number, width: number, align?: Align) => {
  switch (align) {
    case 'right':
      return { right: window.innerWidth - x - width };
    case 'center':
      return { left: x, translate: '-50%' };
    default:
      return { left: x };
  }
};

export function DropdownWindow(props: Props) {
  const {
    align,
    content,
    open,
    isOpen,
    windowRef,
    buttonRef,
    inputRef,
    borderedClassName,
    /** @todo Automatically adjust the position in case the screen borders are exceeded */
    direction = 'down',
    maxWidth,
    minWidth,
    offset = styleVariables.halfPadding / 2,
    isDraggable,
  } = props;
  const [windowStyle, setWindowStyle] = useState<CSSProperties>({});
  const hiddenInputRef = useRef<HTMLInputElement>(null);
  const computedInputRef = inputRef ?? hiddenInputRef;

  useEffect(() => {
    if (computedInputRef.current) {
      const abortController = new AbortController();

      computedInputRef.current.addEventListener('focus', open, { signal: abortController.signal });

      return () => abortController.abort();
    }
  }, []);

  useLayoutEffect(() => {
    if (isOpen && buttonRef.current && windowRef.current) {
      const { y, x, height, width, bottom } = buttonRef.current.getBoundingClientRect();
      const horizontalPosition = getHorizontalPosition(x, width, align);
      const { maxHeight, ...verticalPosition } =
        direction === 'up'
          ? {
              bottom: window.innerHeight - y,
              maxHeight: y - offset - styleVariables.padding,
            }
          : {
              top: y + height,
              maxHeight: window.innerHeight - bottom - offset - styleVariables.padding,
            };
      const naivePosition = {
        ...verticalPosition,
        ...horizontalPosition,
      };
      const style = {
        'minWidth': number.isNumber(minWidth) ? Math.max(minWidth, width) : minWidth ?? width,
        maxWidth,
        '--offset': string.pixelize(offset),
        ...naivePosition,
      };

      setWindowStyle(style);
    }
  }, [isOpen]);

  const { maxHeight, ...windowStyleWithoutMaxHeight } = windowStyle;

  return (
    <>
      {!inputRef && <input ref={hiddenInputRef} className={styles.hiddenInput} />}
      <Transition
        nodeRef={windowRef}
        in={isOpen}
        timeout={{ exit: styleVariables.animationDuration }}
        unmountOnExit
        mountOnEnter
      >
        {state => {
          // Because the window position is fixed, we need to give it the button dimensions manually
          /**
           * @todo Check why 'useBoundingClientRect' doesn't recognize all situations and forces us to do
           * the calculation here to cover all situations.
           * */

          return createPortal(
            'dropdown',
            <div
              style={windowStyleWithoutMaxHeight}
              ref={windowRef}
              className={classNames(borderedClassName, styles.container, state, styles[direction])}
            >
              <div
                className={classNames(styles.overflowContainer, isDraggable && styles.draggable)}
                style={{ maxHeight }}
              >
                {content}
              </div>
            </div>
          );
        }}
      </Transition>
    </>
  );
}
