import type {
  ModifyRequestData,
  ModifyResponseSchema,
  OptionalNullable,
  RevisionRequestSchema,
} from '@harmonya/attribution.types';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import type { RevisionAction } from 'store/attribution';
import {
  attributionActorState,
  attributionRevisionsState,
  isDemoSheetState,
  revisionNumberState,
  saveStatusState,
  sheetIdState,
  versionNumberState,
} from 'store/attribution';
import { authState, customerIdState } from 'store/auth';
import { toastMessageState } from 'store/toastMessage';
import { fetchPost } from 'utils/fetch';
import { env } from '../../../../../env';

const isValidateAction = (newValue: string, oldValue?: string) => newValue === oldValue;

export const useModifyCell = () => {
  const customerId = useRecoilValue(customerIdState);
  const sheetId = useRecoilValue(sheetIdState);
  const isDemoSheet = useRecoilValue(isDemoSheetState);
  const [revisionNumber, setRevisionNumber] = useRecoilState(revisionNumberState);
  const { user } = useRecoilValue(authState);
  const actor = useRecoilValue(attributionActorState);
  const setStatus = useSetRecoilState(saveStatusState);
  const [revisions, setRevisions] = useRecoilState(attributionRevisionsState);
  const [versionNumber, setVersionNumber] = useRecoilState(versionNumberState);
  const setToastMessage = useSetRecoilState(toastMessageState);

  const notifyOnModify = (cellType: string, actionsCount: number, undo: () => void) => {
    const isPlural = actionsCount > 1;
    const messageText = `${isPlural ? `${actionsCount} cells` : 'Cell'} ${cellType} successfuly`;

    setToastMessage({
      type: 'success',
      text: messageText,
      action: {
        title: 'Undo',
        clickHandler: undo,
      },
    });
  };
  const modify = async (actions: RevisionAction[]) => {
    const isValidated = actions.every(action =>
      isValidateAction(action.value, action.oldValue ?? undefined)
    );
    const cellType = isValidated ? 'validated' : 'edited';
    const revisionActions = actions.map(action => ({
      ...action,
      cellType: cellType as Required<RevisionAction>['cellType'],
      oldCellType: action.oldCellType as Required<RevisionAction>['cellType'],
    }));

    const newRevisionNumber = revisionNumber + 1;
    const newRevision = {
      actorId: actor.id,
      timestamp: new Date(),
      revisionNumber: newRevisionNumber,
      actions: revisionActions,
    };

    setRevisionNumber(newRevisionNumber);
    setRevisions(prevRevisions => [...prevRevisions, newRevision]);

    setStatus('saving');

    if (isDemoSheet) {
      notifyOnModify(cellType, actions.length, () => undefined);

      setTimeout(() => {
        setStatus('recentlySaved');
      }, 1000);
      return;
    }

    if (user.isAdmin && !env.isDev) {
      setToastMessage({
        type: 'error',
        text: 'Failed to save: Admin try to change the sheet',
      });
    } else {
      const body = {
        sheetId,
        revisionNumber: newRevisionNumber,
        versionNumber,
        revision: newRevision,
      };

      try {
        const response = await fetchPost<ModifyResponseSchema, ModifyRequestData>(
          '/api/attribution/sheet/modify',
          customerId,
          user.email,
          body
        );

        if (!response.isSuccess) {
          throw new Error('Failed to save');
        }

        if (response.currentVersion !== versionNumber) {
          setVersionNumber(response.currentVersion);
        }

        if (response.currentRevision !== newRevisionNumber) {
          const baseRevisions = [
            ...revisions.filter(revision => revision.revisionNumber !== newRevisionNumber),
          ];
          const updateRevisions = response.revisions as OptionalNullable<RevisionRequestSchema>[];
          const newRevisionsState = [...baseRevisions, ...updateRevisions];
          setRevisions(newRevisionsState);
        }

        setStatus('recentlySaved');

        return cellType;
      } catch (error) {
        setToastMessage({
          type: 'error',
          text: 'Failed to save',
        });
        setRevisions(revisions.filter(revision => revision.revisionNumber !== newRevisionNumber));
      }
    }
  };

  const changeCellValue = async (actions: RevisionAction[]) => {
    const newCellType = await modify(actions);

    if (newCellType) {
      notifyOnModify(newCellType, actions.length, async () => {
        const undoActions = actions.map(action => ({
          ...action,
          value: action.oldValue ?? '',
          oldValue: action.value,
          confidenceLevel: action.oldConfidenceLevel,
          oldConfidenceLevel: action.confidenceLevel,
          cellType: action.oldCellType,
          oldCellType: action.cellType,
        }));

        await modify(undoActions);
      });
    }
  };

  return changeCellValue;
};
