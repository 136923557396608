import React, { memo } from 'react';
import { useColumnActions } from './AttributionHeaderCell.utils';
import appStyles from '../../layout/App.module.scss';
import styles from './AttributionHeaderCell.module.scss';
import { Icon } from 'components/general/Icon';
import { Dropdown } from 'components/general/dropdown/Dropdown';
import { AttributionHeaderCellFilters } from './AttributionHeaderCellFilters';
import classNames from 'classnames';
import { useRecoilValue } from 'recoil';
import { activatedFiltersState } from 'store/attributionPage';
import { styleVariables } from 'utils/styleVariables';
import type { AttributeMetadata } from 'store/attribution.types';

type Props = {
  column: AttributeMetadata;
  width?: number;
};

export const AttributionHeaderCell = memo(function AttributionHeaderCell(props: Props) {
  const { column, width } = props;
  const { key, title, isPredicted } = column;
  const columnActions = useColumnActions(column, width);
  const hasActivatedFilters = useRecoilValue(activatedFiltersState(key));
  const filtersButtonContent = (isOpen: boolean) => (
    <button
      className={classNames(
        styles.filterButton,
        isOpen && styles.open,
        hasActivatedFilters && styles.hasActivatedFilters
      )}
    >
      <Icon name='bars-filter' weight='solid' />
    </button>
  );

  return (
    <div className={styles.cell} style={{ width }} {...columnActions.parentProps}>
      {columnActions.element}
      <div className={appStyles.ellipsis}>{title}</div>
      <Dropdown buttonContent={filtersButtonContent} offset={styleVariables.padding}>
        <AttributionHeaderCellFilters columnId={key} isPredicted={isPredicted} />
      </Dropdown>
    </div>
  );
});
