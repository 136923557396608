import classNames from 'classnames';
import type { ComponentProps } from 'react';
import React from 'react';
import styles from './LimitedInlineQuery.module.scss';
import appStyles from '../layout/App.module.scss';
import { InlineQuery } from './InlineQuery';
import { Tooltip } from './Tooltip';
import { queryToTokens } from '../../functions';

type Props = {
  limit: number;
} & ComponentProps<typeof InlineQuery>;

export function LimitedInlineQuery(props: Props) {
  const { query, tags, limit } = props;
  const queryTokens = queryToTokens(query);
  const numberOfTags = Math.ceil((queryTokens?.length ?? 0) / 2);
  const overflow = numberOfTags > limit;

  const inlineQuery = (
    <div
      className={classNames(
        appStyles.horizontalFlex,
        appStyles.alignCenter,
        appStyles.gap0,
        styles.container
      )}
    >
      <InlineQuery query={query} tags={tags} />
    </div>
  );

  return overflow ? (
    <Tooltip content={inlineQuery}>My Query ({numberOfTags})</Tooltip>
  ) : (
    inlineQuery
  );
}
