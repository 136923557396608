import type { HttpMethod } from '@harmonya/utils';
import { routes } from '@harmonya/utils';

export const fetchMock = async <B extends object>(
  url: string,
  method: HttpMethod,
  body?: B,
  params?: object,
  init?: RequestInit
) => {
  return await new Promise(resolve => {
    const routeConfig = routes[url];

    if (!routeConfig) {
      throw new Error(`Mock config for route ${url} is missing`);
    }

    const methodCallback = routeConfig[method];

    if (!methodCallback) {
      throw new Error(`Mock callback for method ${method} in route ${url} is missing`);
    }

    if (methodCallback) {
      const data = methodCallback.getData(url, body, params, init);

      if (methodCallback.timeout && process.env.USE_MOCK_TIMEOUT) {
        setTimeout(() => resolve(data), methodCallback.timeout);
      } else {
        resolve(data);
      }
    }
  });
};
