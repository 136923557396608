import classNames from 'classnames';
import React from 'react';
import { join } from 'components/general/Divider';
import appStyles from '../../layout/App.module.scss';
import type { GroupedWorkspaces } from './NavWorkspace.utils';
import { WorkspaceTypeHeader } from './WorkspaceTypeHeader';
import './WorkspaceList.module.scss';
import { WorkspaceButton } from './WorkspaceButton';

type Props = {
  workspaces: GroupedWorkspaces;
  setCustomerId: (id: string) => void;
};

export function WorkspaceList(props: Props) {
  const { workspaces, setCustomerId } = props;

  return (
    <div className={classNames(appStyles.verticalFlex, appStyles.gap3)}>
      {join(
        Array.from(workspaces, ([workspaceType, workspaceMap]) => (
          <React.Fragment key={workspaceType}>
            <WorkspaceTypeHeader type={workspaceType} />
            <div className={appStyles.verticalFlex}>
              {Array.from(workspaceMap, ([id, workspace]) => (
                <WorkspaceButton key={id} workspace={workspace} onClick={() => setCustomerId(id)} />
              ))}
            </div>
          </React.Fragment>
        )),
        { direction: 'horizontal' }
      )}
    </div>
  );
}
