import React from 'react';
import './Icon.module.scss';
import type iconsVariables from '../../style/iconsVariables.scss';
import classNames from 'classnames';

// Icons added to font awesome kit
const kitIconNames = new Set([
  'source-all',
  'source-review-listing',
  'source-review',
  'source-listing',
  'filter-sales',
  'filter-tags',
  'customer-market-share',
  'reviews-rate',
  'listing-rate',
  'brand-levels',
  'category-levels',
  'expand-right',
  'collapse-left',
  'circle-edit',
] as const);

export type KitIconName = typeof kitIconNames extends Set<infer T> ? T : never;

export type FontAwesomeIconName = keyof typeof iconsVariables;

export type IconName = FontAwesomeIconName | KitIconName;

export type IconWeight = 'thin' | 'light' | 'regular' | 'solid';

const weightToClassName = (weight: IconWeight) => `fa${weight[0]}`;

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
  name: IconName;
  weight?: IconWeight;
  className?: string;
  animationRotated?: boolean;
  rotated?: boolean;
  flip?: 'horizontal' | 'vertical' | 'both';
};

export function Icon(props: Props) {
  const { name, weight = 'thin', className, animationRotated, rotated, flip, ...iProps } = props;
  const isKitIcon = kitIconNames.has(name as KitIconName);
  const computedClassName = classNames(
    'icon',
    isKitIcon ? 'fa-kit' : `${weightToClassName(weight)}`,
    `fa-${name}`,
    animationRotated && 'fa-spin',
    rotated && ['fa-rotate-180', 'fa-flip-vertical'],
    flip && `fa-flip-${flip}`,
    className
  );

  return <i className={computedClassName} {...iProps} />;
}
