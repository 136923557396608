import type { FormEvent, ForwardedRef } from 'react';
import React, { forwardRef, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import explorePageQueryStyles from './ExplorePageQuery.module.scss';
import styles from './ExplorePageQueryToken.module.scss';
import selecStyles from '../../general/select/Select.module.scss';
import appStyles from '../App.module.scss';
import type { Token, Direction, EnsuredToken, TokenHighlightState } from './ExplorePageQuery';
import { AutoExpandableInput } from '../../general/AutoExpandableInput';
import { getColorClassById } from 'utils/styleVariables';
import { Icon } from '../../general/Icon';
import { getSortedFilteredOptions } from '../../../utils/sortedFilteredOptions';
import { SelectDropdown } from '../../general/select/SelectDropdown';
import type { DropdownRef } from '../../general/dropdown/Dropdown';
import { Dropdown } from '../../general/dropdown/Dropdown';
import { getMinWidthByLongestOption } from 'components/general/select/Select';
import { sanitizeQuery } from 'utils/sanitize';
import { getHighlightables } from 'utils/highlight';
import { Divider } from 'components/general/Divider';
import { caretAtEnd, caretAtStart, number } from '@harmonya/utils';

type Props = {
  index: number;
  selectedToken: Token;
  onRemove: (index: number) => void;
  onToggleHighlight: (index?: number) => void;
  onNavigate: (index: number, direction: Direction) => void;
  onChange: (index: number, token: Token, isConfirmed: boolean) => void;
  classNameGetter: (key: Token['key']) => string | undefined;
  options: EnsuredToken[];
  highlightState?: TokenHighlightState;
  valueCompletionEnforced?: boolean;
  intersectedOperandsCount?: number;
  isInSecondRowOnwards?: boolean;
};

function RawExplorePageQueryToken(
  {
    index,
    selectedToken,
    onRemove,
    onToggleHighlight,
    onNavigate,
    onChange,
    options,
    highlightState,
    valueCompletionEnforced,
    classNameGetter,
    intersectedOperandsCount,
    isInSecondRowOnwards,
  }: Props,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { value, type, colorId, key, className, isLineBreaker } = selectedToken;
  const sanitizedValue = sanitizeQuery(value);
  const { filteredOptions, highlightedOptions } = useMemo(
    () =>
      getSortedFilteredOptions(
        sanitizedValue,
        options,
        'value',
        (a, b) => (b.count ?? 0) - (a.count ?? 0)
      ),
    [sanitizedValue, value]
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<DropdownRef>({});
  const innerContainerClassName = classNames(
    highlightState && styles.showHighlight,
    selecStyles.container,
    styles.innerContainer,
    type === 'operand' ? styles.operand : styles.operator,
    getColorClassById(colorId) ?? styles.defaultColor,
    key && key !== 'logic' ? className : styles.text,
    intersectedOperandsCount && explorePageQueryStyles.intersectedOperandsCounterable
  );
  /** @todo We need to find a more infereded solution to deduce the maximum option length at runtime (rather than hard coded) */
  const labelWidthWithoutText = 70;
  const optionsMinWidth =
    labelWidthWithoutText +
    getMinWidthByLongestOption(
      [['', filteredOptions]],
      option => `${option.value} | ${option.typeName}`
    );
  /** @todo If there are multiple types of tokens (and not just tags), the sentence should be changed */
  const notFoundText = 'No matched tags were found';

  const onInput = (event: FormEvent<HTMLInputElement>) => {
    const token = { type: selectedToken.type, id: event.currentTarget.value.replaceAll('\\', '') };

    onChange(index, token, false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const inputElement = event.currentTarget;

    switch (event.key) {
      case 'ArrowRight':
        if (caretAtEnd(inputElement)) {
          onNavigate(index, 1);
        }

        break;
      case 'ArrowLeft':
        if (caretAtStart(inputElement)) {
          onNavigate(index, -1);
        }

        break;
      case 'Backspace':
        if (caretAtStart(inputElement)) {
          onRemove(index);
        } else if (valueCompletionEnforced) {
          const token = { type: selectedToken.type, id: '' };

          onChange(index, token, false);
        }

        break;
    }
  };

  const getDisplayOption = (option: EnsuredToken) => {
    const colorClass =
      option.key === 'tag' && option.colorId
        ? getColorClassById(option.colorId)
        : styles.defaultColor;
    const [valueFirstPart] = option.value.split(delimiter);
    const highlightables = getHighlightables(
      { ...option, value: valueFirstPart },
      highlightedOptions
    );
    const highlightedValueElements = highlightables.map(({ node: text, isHighlighted }, i) => (
      <span key={i} className={classNames(styles.value, isHighlighted && styles.highlighted)}>
        {text}
      </span>
    ));

    return (
      <span
        className={classNames(
          appStyles.horizontalFlex,
          appStyles.justifySpaceBetween,
          appStyles.alignCenter,
          styles.optionInner,
          styles[option.type]
        )}
      >
        <span className={classNames(styles.optionContent, colorClass, classNameGetter(option.key))}>
          <span>{highlightedValueElements}</span>
          {option.typeName && (
            <>
              <Divider direction='vertical' className={styles.divider} />
              <span className={styles.type}>{option.typeName}</span>
            </>
          )}
        </span>
        {!!option.count && (
          <span className={classNames(appStyles.product, styles.optionCount)}>
            {number.format(option.count)}
          </span>
        )}
      </span>
    );
  };

  useEffect(() => {
    if (valueCompletionEnforced && filteredOptions.length === 1) {
      onChange(index, filteredOptions[0], false);
    }
  }, [value]);

  const handleRemoveIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onRemove(index);
  };

  const buttonContent = (
    <div
      className={innerContainerClassName}
      data-intersected-operands-count={intersectedOperandsCount}
    >
      <div className={classNames(styles.highlight, highlightState && styles[highlightState])} />
      <AutoExpandableInput
        spellCheck={false}
        value={value}
        onInput={onInput}
        onKeyDown={handleKeyDown}
        className={styles.input}
        ref={inputRef}
        onFocus={() => dropdownRef.current.open?.()}
      />
      {value && (
        <Icon
          className={classNames(styles.sign, styles.remove)}
          name='xmark'
          onClick={handleRemoveIconClick}
          onMouseEnter={() => onToggleHighlight(index)}
          onMouseLeave={() => onToggleHighlight()}
        />
      )}
      <Icon name='angle-down' className={styles.sign} />
    </div>
  );
  const delimiter = '|';
  const result = (
    <Dropdown
      dropdownRef={dropdownRef}
      className={styles.container}
      minWidth={optionsMinWidth}
      buttonContent={buttonContent}
      inputRef={inputRef}
    >
      <SelectDropdown
        value={selectedToken.value + delimiter + selectedToken.id}
        onChange={newValue => {
          const [tokenName, tokenId] = newValue.split(delimiter);
          const existsSelectedToken = options.find(
            token => token.value === tokenName && token.id.toString() === tokenId
          );
          const token = existsSelectedToken ?? { type: selectedToken.type, id: tokenName };

          onChange(index, token, true);
        }}
        options={filteredOptions.map(option => ({
          ...option,
          value: option.value + delimiter + option.id,
        }))}
        notFoundText={notFoundText}
        displayValueGetter={getDisplayOption}
        colorInverted
      />
    </Dropdown>
  );

  return (
    <div
      ref={ref}
      className={classNames(
        styles.container,
        isLineBreaker && [styles.lineBreaker, appStyles.fullBasis],
        isInSecondRowOnwards && explorePageQueryStyles.isInSecondRowOnwards
      )}
    >
      {result}
    </div>
  );
}

export const ExplorePageQueryToken = forwardRef(RawExplorePageQueryToken);
