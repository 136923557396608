import type { TagType } from './TagType';
import { BaseTag } from './BaseTag';
import type { Dimensions } from 'store/tags';
import type { FormmaterKey } from 'utils/formatters';
import type { AggregateTag as AggregateTagModel } from '@harmonya/models';

export class AggregateTag
  extends BaseTag
  implements Omit<AggregateTagModel, 'typeIds' | 'dominantTypeId'>
{
  values: AggregateTagModel['values'];

  constructor(
    { values, ...tag }: AggregateTagModel,
    allTagTypes: Map<number, TagType>,
    dimensions: Dimensions
  ) {
    super(tag, allTagTypes, dimensions);
    this.values = values;
  }
}

// * @todo Concider removing  `& FormmaterKey` from this type
export type TagValue = keyof AggregateTag['values'] & FormmaterKey;
