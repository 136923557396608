import type { ReactNode } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './SelectButton.module.scss';
import appStyles from '../../layout/App.module.scss';
import { Icon } from '../Icon';
import { useBoundingClientRect } from 'hooks/useBoundingClientRect';
import { iterator, string } from '@harmonya/utils';
import type { Option, RawOptionType } from 'components/general/select/types';

const getOrder = <T extends Option<RawOptionType>>(option: T) =>
  option.order ?? (string.isString(option.displayValue) ? option.displayValue.length : 0);

const getButtonContent = <T extends Option<RawOptionType>>(
  selectedOptions: T[],
  displayValueGetter: (option: T) => ReactNode,
  delimiter: ReactNode,
  maxDisplayedItems?: number,
  placeholder?: string
) => {
  // Always prefer the shorter one in order to save space on the button
  const displayedOptions =
    maxDisplayedItems == null
      ? selectedOptions
      : [...selectedOptions]
          .sort((a, b) => getOrder(a) - getOrder(b))
          .slice(0, Math.max(maxDisplayedItems, 1));
  const restItemsCount = selectedOptions.length - displayedOptions.length;
  const commaSeperatedDisplayedOptions = iterator.join(
    (function* () {
      for (const option of displayedOptions) {
        const displayValue = displayValueGetter(option);
        const valueElement = string.isString(displayValue) ? (
          <span>{displayValue}</span>
        ) : (
          displayValue
        );

        yield valueElement;
      }
    })(),
    delimiter
  );

  if (commaSeperatedDisplayedOptions.length) {
    let extraContentIndication;

    if (restItemsCount) {
      extraContentIndication = <span className={styles.itemsCount}>{` + ${restItemsCount}`}</span>;
    }

    return [
      commaSeperatedDisplayedOptions.map((option, i) => (
        <React.Fragment key={i}>{option}</React.Fragment>
      )),
      extraContentIndication,
    ];
  }

  if (restItemsCount) {
    return [`${restItemsCount} Selected`];
  }

  return [placeholder];
};

const delimiter = <span className={styles.delimiter}>, </span>;

const getComputedMaxDisplayedItems = (
  containerElement: Element,
  hiddenInputElement: Element,
  actionsElement: Element
) => {
  const buttonActionsWidth = actionsElement.getBoundingClientRect().width;
  const containerRight = containerElement.getBoundingClientRect().right;
  const containerPaddingRight = parseFloat(getComputedStyle(containerElement).paddingRight);
  const restOptionsElementEstimatedWidth = 18;
  const rightThreshold =
    containerRight -
    buttonActionsWidth -
    containerPaddingRight * 2 -
    restOptionsElementEstimatedWidth;
  let newComputedMaxDisplayedItems = 0;

  for (const element of hiddenInputElement.childNodes) {
    if (element instanceof HTMLElement) {
      const isDelimiter = element.classList.contains(styles.delimiter);

      if (!isDelimiter) {
        const isExceedTheContainer = element.getBoundingClientRect().right >= rightThreshold;

        // There is no reason to continue checking the rest of the elements because we know that if the
        // current element exceed from the container then it is clear that the next ones as well.
        if (isExceedTheContainer) {
          break;
        }

        newComputedMaxDisplayedItems++;
      }
    }
  }

  return newComputedMaxDisplayedItems;
};

type Props<T> = {
  onReset?: () => void;
  selectedOptions: T[];
  title?: string;
  placeholder?: string;
  displayValueGetter: (option: T) => ReactNode;
  isMultiselect?: boolean;
  containerElement: HTMLDivElement | null;
  className?: string;
};

export function SelectButton<T extends Option<RawOptionType>>(props: Props<T>) {
  const {
    onReset,
    selectedOptions,
    title,
    placeholder,
    displayValueGetter,
    isMultiselect,
    containerElement,
    className,
  } = props;
  const [computedMaxDisplayedItems, setComputedMaxDisplayedItems] = useState<number>();
  const [buttonContent, buttonExtraContentIndication] = getButtonContent(
    selectedOptions,
    displayValueGetter,
    delimiter,
    computedMaxDisplayedItems,
    placeholder
  );
  const [hiddenButtonContent] = getButtonContent(
    selectedOptions,
    displayValueGetter,
    delimiter,
    Infinity
  );
  const hiddenButtonContentRef = useRef<HTMLDivElement>(null);
  const actionsRef = useRef<HTMLSpanElement>(null);
  const hiddenInputDimensions = useBoundingClientRect(hiddenButtonContentRef, 'dimensions');

  useEffect(() => {
    if (isMultiselect && containerElement) {
      const hiddenInputElement = hiddenButtonContentRef.current;
      const actionsElement = actionsRef.current;
      const elementsRendered = hiddenInputElement && actionsElement;

      if (elementsRendered && hiddenInputDimensions?.width && selectedOptions.length) {
        const newComputedMaxDisplayedItems = getComputedMaxDisplayedItems(
          containerElement,
          hiddenInputElement,
          actionsElement
        );

        setComputedMaxDisplayedItems(newComputedMaxDisplayedItems);
      }
    }
  }, [hiddenInputDimensions?.width, containerElement]);

  return (
    <div className={className}>
      <span className={styles.textContainer}>
        <div className={styles.hiddenButtonText}>
          {title && <span className={styles.title}>{title}</span>}
          <span className={styles.content} ref={hiddenButtonContentRef}>
            {hiddenButtonContent}
          </span>
        </div>
        {title && <span className={styles.title}>{title}</span>}
        <span className={styles.content}>{buttonContent}</span>
        {buttonExtraContentIndication ? buttonExtraContentIndication : null}
      </span>
      <span
        ref={actionsRef}
        className={classNames(styles.actions, appStyles.horizontalFlex, appStyles.gap1)}
      >
        {onReset && (
          <Icon
            className={classNames(styles.reset, selectedOptions.length && styles.show)}
            name='xmark'
            onClick={onReset}
          />
        )}
        <Icon name='angle-down' className={styles.arrow} />
      </span>
    </div>
  );
}
