import React from 'react';
import classNames from 'classnames';
import appStyles from '../../App.module.scss';
import styles from './EmptyTags.module.scss';
import { ReactComponent as NoTagsIcon } from '../../../../assets/emptyState/brands.svg';

const containerClasses = [
  appStyles.verticalFlex,
  appStyles.fullHeight,
  appStyles.justifyCenter,
  appStyles.alignCenter,
];

type Props = {
  text: string[];
};

export function EmptyTags(props: Props) {
  const { text } = props;

  return (
    <div className={classNames(containerClasses)}>
      <NoTagsIcon />
      {text.map(line => (
        <h4 key={line} className={styles.line}>
          {line}
        </h4>
      ))}
    </div>
  );
}
