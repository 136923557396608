export const tagTypeNames = [
  'Size',
  'Claims',
  'Flavors',
  'Textures',
  'Container',
  'Packaging',
  'Ingredients',
  'Product Type',
  'Serving Form',
  'Place of Origin',
  'Activity Related',
  'Taste Properties',
  'Consumer Benefits',
  'Consumption Habits',
  'Events / Occasions',
  'Product Properties',
  'Audience / Primary Consumers',
];
