import { useState } from 'react';

let idCounter = 1;

export const useInstanceId = () => {
  // eslint-disable-next-line react/hook-use-state
  const [instanceId] = useState(() => idCounter++);

  return instanceId;
};
