import { array } from '@harmonya/utils';
import { createBrowserHistory } from '@remix-run/router';
import type { Listener } from '@remix-run/router/history';

export const history = createBrowserHistory({ v5Compat: true });

const historyListeners: Listener[] = [];

history.listen((...args) => historyListeners.forEach(listener => listener(...args)));

// We can't listen to history more than once so we override it with our custom listener mechanism
history.listen = (listener: Listener) => {
  historyListeners.push(listener);

  const removeListener = () => array.remove(historyListeners, listener, true);

  return removeListener;
};
