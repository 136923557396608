import classNames from 'classnames';
import { Icon } from 'components/general/Icon';
import { Scroller } from 'components/general/Scroller';
import type { SliderFilter } from 'components/general/sliderFilters/SliderFiltersDropdown';
import { SliderFiltersDropdown } from 'components/general/sliderFilters/SliderFiltersDropdown';
import type { AggregateTag } from 'models/AggregateTag';
import React, { memo } from 'react';
import type { RecoilState } from 'recoil';
import { explorePageGrowthPresetState, explorePageSourcePresetState } from 'store/explorePage';
import selectStyles from '../../general/select/Select.module.scss';
import appStyles from '../App.module.scss';
import { ExplorePagePresetButton } from './ExplorePagePresetButton';
import styles from './ExplorePageTagFilter.module.scss';
import { usePresets, growthPresetNames, sourcePresetNames } from './ExplorePageTags.utils';
import { Divider } from 'components/general/Divider';
import { map } from '@harmonya/utils';
import type { PresetName } from '@harmonya/models';

type Props = {
  sliderFilters: SliderFilter<AggregateTag>[];
  /** @todo The props that are passed to the component are not memoized, so currently the memo is meaningless */
  reset: () => void;
  activatePreset: <T extends PresetName>(
    selectedNameRecoilState: RecoilState<T | undefined>,
    presetName: T,
    isSelected: boolean
  ) => void;
};

export const ExplorePageTagFilter = memo(function ExplorePageTagFilter(props: Props) {
  const { sliderFilters, reset, activatePreset } = props;
  const presets = usePresets();

  function renderPresetButtons<T extends PresetName>(
    presetNames: readonly T[],
    selectedNameRecoilState: RecoilState<T | undefined>
  ) {
    return presetNames.map(presetName => {
      const presetConfig = map.safeGet(presets, presetName);

      return (
        <ExplorePagePresetButton
          key={presetName}
          name={presetName}
          selectedPresetRecoilState={selectedNameRecoilState}
          title={presetConfig.title}
          tooltip={presetConfig.tooltip}
          iconName={presetConfig.iconName}
          activatePreset={isSelected =>
            activatePreset(selectedNameRecoilState, presetName, isSelected)
          }
        />
      );
    });
  }

  return (
    <div className={classNames(appStyles.horizontalFlex, appStyles.alignCenter, styles.filterRow)}>
      <Icon name='filter-tags' className={styles.filterIcon} />
      <SliderFiltersDropdown
        buttonTitle='Metrics'
        sliderFilters={sliderFilters}
        reset={reset}
        className={classNames(styles.filterButton, styles.sliderButton, selectStyles.button)}
      />
      <Scroller direction='horizontal' className={styles.presetButtonsScroller}>
        <div
          className={classNames(
            appStyles.horizontalFlex,
            appStyles.alignCenter,
            styles.presetButtons
          )}
        >
          {renderPresetButtons(growthPresetNames, explorePageGrowthPresetState)}
          <Divider />
          {renderPresetButtons(sourcePresetNames, explorePageSourcePresetState)}
        </div>
      </Scroller>
    </div>
  );
});
