import { iterator, map } from '@harmonya/utils';
import type { CustomerData } from 'store/auth';
import { getColorClassById } from 'utils/styleVariables';

export type Workspace = {
  type: WorkspaceType;
  name: string;
  isActive: boolean;
  colorClass?: string;
  shortName?: string;
};

export type WorkspaceType = 'insight' | 'attribution';

const attributionSuffix = '-attr';

export type GroupedWorkspaces = Map<WorkspaceType, Map<string, Workspace>>;

function getWorkspaceName(workspaceType: WorkspaceType, displayName: string, id: string) {
  return workspaceType === 'attribution'
    ? 'Attribution'
    : displayName ?? id.replace('-', ' ').toUpperCase();
}

function getWorkspaceTypeById(id: string): WorkspaceType {
  return id.endsWith(attributionSuffix) ? 'attribution' : 'insight';
}

export const getWorkspaceById = (workspaces: GroupedWorkspaces, id: string) => {
  for (const workspace of workspaces.values()) {
    if (workspace.has(id)) {
      return workspace.get(id);
    }
  }
};

function getWorkspaceColorClassName(workspaceType: WorkspaceType, colorId: number) {
  return (workspaceType === 'insight' && getColorClassById(colorId)) || undefined;
}

function createWorkspace(
  id: string,
  customer: CustomerData,
  colorIndex: number,
  activeCustomerId?: string
) {
  const workspaceType = getWorkspaceTypeById(id);

  return {
    name: getWorkspaceName(workspaceType, customer.displayName, id),
    isActive: activeCustomerId === id,
    colorClass: getWorkspaceColorClassName(workspaceType, colorIndex + 1),
    type: workspaceType,
    shortName: getWorkspaceShortName(customer.displayName, id),
  };
}

export function buildWorkspaces(customers: Map<string, CustomerData>, activeCustomerId?: string) {
  return iterator.reduce(
    customers,
    (groupedWorkspaces: GroupedWorkspaces, [id, customer], colorIndex) => {
      const workspaceType = getWorkspaceTypeById(id);
      const groupedWorkspace = map.getOrCreateMap(
        groupedWorkspaces,
        workspaceType,
        () => new Map()
      );

      const workspace = createWorkspace(id, customer, colorIndex, activeCustomerId);
      groupedWorkspace.set(id, workspace);

      return groupedWorkspaces;
    },
    new Map()
  );
}

function getWorkspaceShortName(displayName: string, id: string) {
  // Extract up to 2 alphabetic characters from the display name or ID
  // Convert to uppercase
  return (
    (displayName ?? id)
      .match(/[a-zA-Z]/g)
      ?.slice(0, 2)
      .join('')
      .toUpperCase() ?? ''
  );
}
