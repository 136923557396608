import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export function useResetUrl(customerId: string) {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParamCustomerId = searchParams.get('customerId');

    if (customerId && searchParamCustomerId !== customerId) {
      const newSearchParams = searchParamCustomerId ? new URLSearchParams() : searchParams;
      newSearchParams.set('customerId', customerId);
      setSearchParams(newSearchParams);
    }
  }, [searchParams, customerId, setSearchParams]);

  useEffect(() => {
    if (location.state && location.state.url !== location.pathname) {
      navigate(location.state.url);
    }
  }, [location.state]);
}
