import { bool, cleanEnv, str, num, json } from 'envalid';

export const customerIdDevDefault = 'testCustomer';

function testOnly(testDefault: string) {
  return process.env.NODE_ENV === 'test' ? testDefault : undefined;
}

type WithoutPrefix<Prefix extends string, Key> = Key extends `${Prefix}${infer TrimmedKey}`
  ? TrimmedKey
  : Key;
type ReturnType<T extends object, Prefix extends string> = {
  [Prop in keyof T as WithoutPrefix<Prefix, Prop>]: T[Prop];
};

const renameEnvKeys = <T extends object, Prefix extends string>(
  obj: T,
  prefixToRemove: Prefix
): ReturnType<T, Prefix> => {
  const entries = Object.entries(obj);
  const replaceRegexp = new RegExp(`^${prefixToRemove}`);
  const mappedEntries = entries.map(
    ([key, value]) => [key.replace(replaceRegexp, ''), value] as const
  );
  const mappedObject = Object.fromEntries(mappedEntries);

  return mappedObject as ReturnType<T, Prefix>;
};

const envWithoutReactPrefix = renameEnvKeys(process.env, 'REACT_APP_');

export const env = cleanEnv(envWithoutReactPrefix, {
  NODE_ENV: str({ default: 'development' }),
  CUSTOMER_ID: str({ default: 'harmonya', devDefault: testOnly(customerIdDevDefault) }),
  SERVER_URL: str({ default: 'http://localhost:3000' }),
  DOMAIN: str({ default: 'https://dev-qxg11dmn.us.auth0.com' }),
  CLIENT_ID: str({ default: 'ufxNyy0WEGdGYp5pESuUV8NMkUJBnxjx' }),
  CLIENT_SECRET: str({ default: '123' }),
  AUDIENCE: str({ default: 'http://localhost:3000' }),
  CUSTOM_CLAIM_NAMESPACE: str({ default: '' }),
  MOCK_TIMEOUT: num({ default: 2000 }),
  LOADER_TIMEOUT: num({ default: 3000 }),
  USE_MOCK_TIMEOUT: bool({ default: false }),
  USE_MOCK: bool({ default: false }),
  USE_AUTH: bool({ default: true }),
  ENABLE_SORT_LINE_CHART: bool({ default: false }),
  DEBUG_ANALYTICS: bool({ default: false }),
  MIXPANEL_TOKEN: str({ default: '' }),
  ANALYTICS_EXCLUDED_EMAIL_DOMAINS: json({ default: [] }),
  SENTRY_DSN: str({ default: '' }),
  SENTRY_RELEASE: str({ default: '1.0.0' }),
});
