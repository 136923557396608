import { selector, selectorFamily } from 'recoil';
import { attributionRevisionsState, attributionTableState } from 'store/attribution';
import type { ColumnId } from 'store/attribution.types';
import { iterator } from '@harmonya/utils';

export const attributionDataState = selector({
  key: 'attributionData',
  get: ({ get }) => {
    const { attributesMetadata, productsAttributes } = get(attributionTableState);
    const clonedProductsAttributes = new Map(productsAttributes);
    const revisions = get(attributionRevisionsState);

    revisions.forEach(revision => {
      revision.actions.forEach(action => {
        const { columnId, itemId, value, cellType, confidenceLevel } = action;

        const productData = clonedProductsAttributes.get(itemId);

        /** @todo Add audit for this action  */
        if (productData) {
          const columnData = {
            ...productData[columnId],
            value,
            confidenceLevel: confidenceLevel ?? undefined,
          };

          switch (cellType) {
            case 'validated':
              columnData.isValidated = true;
              break;
            case 'edited':
              columnData.isEdited = true;
              break;
          }

          productData[columnId] = columnData;
        }
      });
    });

    return { attributesMetadata, productsAttributes: clonedProductsAttributes };
  },
});

export const columnOptionsState = selectorFamily({
  key: 'columnOptions',
  get:
    (columnId: ColumnId) =>
    ({ get }) => {
      const attributionData = get(attributionDataState);
      const columnOptions = iterator.definedMap(
        attributionData.productsAttributes.values(),
        row => {
          const value = row[columnId].value ?? undefined;

          return value === '' ? undefined : value;
        }
      );
      const columnOptionsAsSet = new Set(columnOptions);

      return columnOptionsAsSet;
    },
});
