import classNames from 'classnames';
import React, { memo, useEffect, useRef, useState } from 'react';
import appStyles from '../App.module.scss';
import styles from './ExplorePageQuery.module.scss';
import { ExplorePageQueryToken } from './ExplorePageQueryToken';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { tagsState } from '../../../store/tags';
import { Icon } from '../../general/Icon';
import { useRefs } from 'hooks/useRefs';
import { useResize } from 'hooks/useResize';
import { styleVariables } from 'utils/styleVariables';
import { analyticsState } from 'store/analytics';
import { isDeepEqual } from 'utils/isDeepEqual';
import { explorePageQueryState } from 'store/explorePage';
import { getIsPriortizedTag, getTokenValueSet, isTokenNewToQuery } from 'utils/prioritizedTags';
import { tagTypesState } from 'store/tagTypes';
import { settingsState } from 'store/settings';
import type { Tag } from 'models/Tag';
import { iterator, map as mapUtil } from '@harmonya/utils';
import { queryToTokens, splitConditionsBy, tokensToQuery } from '../../../functions';
import type { QueryToken, TokenId } from '@harmonya/models';

/** Temporary solution, @see https://github.com/microsoft/TypeScript/issues/48829#issuecomment-1295493436 */
declare global {
  interface Array<T> {
    findLastIndex(
      predicate: (value: T, index: number, obj: T[]) => unknown,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      thisArg?: any
    ): number;
  }
}

export type Token = QueryToken & {
  typeName?: string;
  colorId?: number;
  className?: string;
  count?: number;
  isLineBreaker?: boolean;
  isEndOfLine?: boolean;
};

export type TokenHighlightState = 'start' | 'end' | 'both';

type HighlightedTokens = { [key: number]: TokenHighlightState };

export type EnsuredToken = Exclude<Token, 'value' | 'id' | 'count'> & {
  value: string;
  id: TokenId;
};

export type Direction = 1 | -1;

const isEquals = (first: Token, second: Token) =>
  (first.type !== 'operand' || second.type !== 'operand' || first.key === second.key) &&
  first.value === second.value &&
  first.id === second.id;

const getConditionTokens = (tokens: Token[], token: Token) => {
  const tokenIndex = tokens.indexOf(token);
  const map = new Map<TokenId, Token>();

  const addTags = (
    loopConditionFunc: (currentIndex: number) => boolean,
    increaserFunc: (number: number) => number
  ) => {
    let currentIndex = increaserFunc(tokenIndex);
    let currentToken = tokens[currentIndex];

    while (loopConditionFunc(currentIndex) && !currentToken?.isLineBreaker) {
      if (currentToken?.id && currentToken.type === token.type && currentToken.id != null) {
        map.set(currentToken.id, currentToken);
      }

      currentIndex = increaserFunc(currentIndex);
      currentToken = tokens[currentIndex];
    }
  };

  if (!token.isEndOfLine) {
    addTags(
      currentIndex => currentIndex <= tokens.length,
      number => number + 1
    );
  }

  addTags(
    currentIndex => currentIndex >= 0,
    number => number - 1
  );

  return map;
};

const filterOptions = (
  options: EnsuredToken[],
  tokens: Token[],
  currentToken: Token,
  hashGetter: (token: Token) => string
) => {
  const conditionTokens = getConditionTokens(tokens, currentToken);
  const selectedOperandNamesAsSet = getTokenValueSet(conditionTokens.values(), hashGetter);
  const filteredOptions = options.filter(option => {
    const isFiltererdByConditionOperands = !conditionTokens.has(option.id);
    const hasSelectedTokenWithSameName = isTokenNewToQuery(
      hashGetter(option),
      selectedOperandNamesAsSet as Set<string>
    );

    return isFiltererdByConditionOperands && hasSelectedTokenWithSameName;
  });

  return filteredOptions;
};

const dataPropertyToClassName: { [key in NonNullable<Token['key']>]?: string } = {
  tag: appStyles.borderedTag,
};
const classNameGetter = (key: Token['key']) => key && dataPropertyToClassName[key];

export const queryOperators = {
  and: 'AND',
  or: 'OR',
};

const operators = Object.values(queryOperators);

export const ExplorePageQuery = memo(function ExplorePageQuery() {
  const [query, setQuery] = useRecoilState(explorePageQueryState);
  const resetQuery = useResetRecoilState(explorePageQueryState);
  const tags = useRecoilValue(tagsState);
  const tagTypes = useRecoilValue(tagTypesState);
  const settings = useRecoilValue(settingsState);

  const queryToClientTokens = (currentQuery: string) => {
    const newTokens = queryToTokens(currentQuery);

    const getValue = (key: Token['key'], id?: TokenId) => {
      if (id != null) {
        switch (key) {
          case 'tag': {
            const tag = tags.get(Number(id));

            if (!tag) {
              throw new Error(`Tag id ${id} was not found`);
            }

            return tag.name;
          }
        }
      }

      return id?.toString();
    };

    return newTokens
      .map(token => ({
        ...token,
        value: getValue(token.key, token.id),
        isLineBreaker: splitConditionsBy(token),
      }))
      .map<Token>((token, i, tokens) => ({
        ...token,
        isEndOfLine: tokens[i + 1]?.isLineBreaker,
      }));
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const containerRefDimensions = useResize(containerRef);
  const inputContainerRef = useRef<HTMLDivElement>(null);
  const [currentQuery, setCurrentQuery] = useState(query ?? '');
  const [highlightedTokenStates, setHighlightedTokenStates] = useState<HighlightedTokens>({});
  const computedOperators = operators.map<EnsuredToken>(operator => ({
    type: 'operator',
    value: operator,
    id: operator,
  }));
  const tokens: Token[] = currentQuery ? queryToClientTokens(currentQuery) : [{ type: 'operand' }];
  const isPriortizedTag = getIsPriortizedTag(
    tags.values(),
    tagTypes,
    new Set([]),
    settings.tagTypePriority
  );
  const prioritizedTags = iterator.filter(tags.values(), tag => isPriortizedTag(tag, tag.hash));
  const operands = mapUtil.definedMapValues<Tag['id'], Tag, EnsuredToken>(
    tags,
    ({ id, name, dominantType, productsCount }) => ({
      type: 'operand',
      key: 'tag',
      id,
      value: name,
      colorId: dominantType.colorId,
      typeName: dominantType.name,
      count: productsCount,
    })
  );
  const prioritizedOperands = iterator.definedMap(prioritizedTags, tag => operands.get(tag.id));
  const hasQuery = tokens.some(token => token.value);
  const [rowsInfo, setRowsInfo] = useState({
    containerHeight: 0,
    intersectedOperandsCount: 0,
    firstSecondRowTokenIndex: null as number | null,
  });
  const [tokenRefs, setTokenRefGetter] = useRefs<HTMLDivElement>(tokens);
  const analytics = useRecoilValue(analyticsState);

  const trackQueryChange = (newQuery: string, token: QueryToken) => {
    const verb = query && query.length > newQuery.length ? 'removed' : 'added';

    analytics.track(`Query tag ${verb}`, {
      name: 'Query Bar',
      type: 'tag',
      tag: { id: token.id, name: token.value },
    });
  };

  const change = (index: number, token: Token, isConfirmed: boolean) => {
    const newTokens = queryToClientTokens(currentQuery);
    const isLastToken = index == null ? undefined : index === newTokens.length - 1;

    newTokens[index] = token;

    if (isConfirmed) {
      const focus = () => focusOnToken(index + 1, true);

      if (isLastToken) {
        const newTokenType = token.type === 'operand' ? 'operator' : 'operand';

        newTokens.push({ type: newTokenType, value: '' });
        // Wait until the DOM rendering is complete
        setTimeout(focus, 100);
      } else {
        focus();
      }
    }

    const newCurrentQuery = tokensToQuery(newTokens);

    if (newCurrentQuery !== currentQuery) {
      setCurrentQuery(newCurrentQuery);
    }

    setQueryByTokens(newTokens, token);
  };

  const setQueryByTokens = (newTokens: Token[], token: Token) => {
    const queryNewTokensLastIndex = newTokens.findLastIndex(
      ({ value, type }) => value && type === 'operand'
    );
    // Ignore tail operands and/or partial tokens (query should be "Drink AND Juice" instead of "Drink AND Juice AND" or "Drink AND Jui")
    const queryNewTokensWithoutRedundentTokens = newTokens.slice(0, queryNewTokensLastIndex + 1);
    const newQuery = tokensToQuery(queryNewTokensWithoutRedundentTokens);

    if (newQuery !== query) {
      trackQueryChange(newQuery, token);
      setQuery(newQuery);
    }
  };

  const getTokensDeleteCount = (index: number) => {
    const isLastToken = index != null && index === tokens.length - 1;

    // The '1 | 2' comes to to indicate that we do not currently support the highlighting of more than two tokens
    return isLastToken ? 1 : (2 as const);
  };

  const remove = (index: number) => {
    const deleteCount = getTokensDeleteCount(index);
    const nextTokenIndex = Math.max(index - 1, 0);
    const newTokens = [...tokens];

    newTokens.splice(index, deleteCount);

    const newQuery = tokensToQuery(newTokens);

    setCurrentQuery(newQuery);

    setQueryByTokens(newTokens, tokens[nextTokenIndex]);

    setHighlightedTokenStates({});

    // Wait until the DOM rendering is complete
    setTimeout(() => focusOnToken(nextTokenIndex, false));
  };

  const toggleHighlight = (index?: number) => {
    const newHighlightedTokenStates: HighlightedTokens = {};

    if (index != null) {
      const deleteCount = getTokensDeleteCount(index);

      for (let i = index; i < index + deleteCount; i++) {
        const token = tokens[i];
        const isSingle = deleteCount === 1 || token.isLineBreaker;
        const isFirst = i === index;
        let highlightState: TokenHighlightState;

        if (token.value) {
          if (isSingle) {
            highlightState = 'both';
          } else if (isFirst) {
            highlightState = token.isEndOfLine || !tokens[i + 1].value ? 'both' : 'start';
          } else {
            const previousToken = tokens[i - 1];

            highlightState =
              previousToken.isEndOfLine || previousToken.isLineBreaker ? 'both' : 'end';
          }

          newHighlightedTokenStates[i] = highlightState;
        }
      }
    }

    setHighlightedTokenStates(newHighlightedTokenStates);
  };

  const focusOnToken = (index: number, caretAtStart: boolean) => {
    if (inputContainerRef.current) {
      const nextChildElement = inputContainerRef.current?.childNodes[index] as
        | HTMLElement
        | undefined;
      const nextChildElementInput = nextChildElement?.querySelector('input');

      if (nextChildElementInput) {
        nextChildElementInput.focus();

        // Without this timeout, the cursor will be placed in the second position instead of at the beginning of the element
        const position = caretAtStart ? 0 : nextChildElementInput.value.length;

        setTimeout(() => nextChildElementInput.setSelectionRange(position, position));
      }
    }
  };

  const focusOnNewItem = (event: React.MouseEvent) => {
    if (!event.currentTarget.contains(document.activeElement)) {
      focusOnToken(tokens.length - 1, false);
    }
  };

  useEffect(() => {
    if (query) {
      const newTokens = queryToClientTokens(query);
      const isOverride = newTokens.some(newToken =>
        tokens.every(token => token.id !== newToken.id)
      );

      if (isOverride) {
        newTokens.push({ type: 'operator', value: '' });

        const newQuery = tokensToQuery(newTokens);

        setCurrentQuery(newQuery);
      }
    } else if (currentQuery) {
      setCurrentQuery('');
    }
  }, [query]);

  useEffect(() => {
    // If we don't use timeout, changes in the token that already has a value (from one value to another) will not
    // be reflected here and the counter will appear in the second line
    setTimeout(() => {
      const computedTokens = queryToClientTokens(currentQuery);
      const firstTop = tokenRefs.current[0].getBoundingClientRect().top;
      let firstSecondRowTokenIndex: number | null = null;
      let intersectedOperandsCount = 0;
      let containerHeight = 0;
      const firstTokenTop = tokenRefs.current[0]?.getBoundingClientRect().top;

      tokenRefs.current.forEach((element, i) => {
        const { top, bottom } = element.getBoundingClientRect();
        const newContainerHeight = bottom - firstTop + styleVariables.padding * 2;
        const isInFirstRow = top === firstTokenTop;
        const computedToken = computedTokens[i];

        if (computedToken) {
          const { type, key } = computedTokens[i];
          const isSelectedOperand = type === 'operand' && key;

          containerHeight = Math.max(newContainerHeight, containerHeight);

          if (!isInFirstRow) {
            firstSecondRowTokenIndex ??= i;

            if (isSelectedOperand) {
              intersectedOperandsCount++;
            }
          }
        }
      });

      const newRowsInfo = {
        intersectedOperandsCount,
        firstSecondRowTokenIndex,
        containerHeight,
      };

      if (!isDeepEqual(newRowsInfo, rowsInfo)) {
        setRowsInfo(newRowsInfo);
      }
    });
  }, [currentQuery, containerRefDimensions?.width]);

  const { intersectedOperandsCount, firstSecondRowTokenIndex, containerHeight } = rowsInfo;
  const lastFirstRowTokenIndex = firstSecondRowTokenIndex && firstSecondRowTokenIndex - 1;
  const getOperatorProps = (token: Token) => ({
    options: computedOperators,
    selectedToken: token,
    forceCompleteValue: true,
  });
  const getOperandProps = (token: Token) => {
    const { colorId, typeName } =
      iterator.find(operands.values(), operand => isEquals(operand, token)) ?? {};

    return {
      options: filterOptions(
        prioritizedOperands as EnsuredToken[],
        tokens,
        token,
        ({ id }) => tags.get(Number(id))?.hash ?? String(id)
      ),
      selectedToken: {
        ...token,
        className: classNameGetter(token.key),
        colorId,
        typeName,
      },
    };
  };
  const tokenElements = tokens.map((token, i) => {
    const getProps = token.type === 'operator' ? getOperatorProps : getOperandProps;
    const typeBasedProps = getProps(token);
    return (
      <ExplorePageQueryToken
        key={i}
        index={i}
        onRemove={remove}
        onToggleHighlight={toggleHighlight}
        onNavigate={(index, direction) => focusOnToken(index + direction, direction === 1)}
        onChange={change}
        highlightState={highlightedTokenStates[i]}
        classNameGetter={classNameGetter}
        ref={setTokenRefGetter(i)}
        isInSecondRowOnwards={!!firstSecondRowTokenIndex && i >= firstSecondRowTokenIndex}
        {...(lastFirstRowTokenIndex === i && { intersectedOperandsCount })}
        {...typeBasedProps}
      />
    );
  });

  const trackedResetQuery = () => {
    analytics.track('Query bar reset clicked', { name: 'Query Bar', type: 'button' });
    resetQuery();
  };

  return (
    <div
      ref={containerRef}
      className={classNames(
        appStyles.box,
        appStyles.padded2,
        appStyles.horizontalFlex,
        appStyles.backgroundMain,
        appStyles.justifySpaceBetween,
        styles.container,
        !firstSecondRowTokenIndex && styles.hasSingleRow
      )}
      style={{ height: containerHeight }}
      onClick={focusOnNewItem}
    >
      <div className={appStyles.horizontalFlex}>
        <Icon className={styles.controls} name='magnifying-glass' />
        <div
          ref={inputContainerRef}
          data-placeholder='Add tag'
          className={classNames(
            appStyles.flexGrow1,
            appStyles.horizontalFlex,
            appStyles.gap0,
            appStyles.wrap,
            styles.inputContainer,
            hasQuery && styles.hasQuery
          )}
        >
          {tokenElements}
        </div>
      </div>
      <div className={classNames(appStyles.horizontalFlex, appStyles.alignCenter, styles.controls)}>
        <Icon
          className={classNames(styles.clear, !hasQuery && appStyles.transparentHide)}
          onClick={trackedResetQuery}
          name='xmark'
        />
      </div>
    </div>
  );
});
