import React from 'react';
import classNames from 'classnames';
import appStyles from '../../layout/App.module.scss';
import styles from './ExplorePageMetadata.module.scss';
import { productsState } from '../../../store/products';
import { isLoading, useRecoilValueLoadableState } from '../../../hooks/useRecoilValueLoadable';
import { growthPointState } from '../../../store/growthPoint';
import { useRecoilValue } from 'recoil';
import type { tooltipTexts } from '../../../tooltipTexts';
import { withTooltip } from '../../general/Tooltip';
import { settingsState } from '../../../store/settings';
import { getFormattedValue } from 'utils/formatters';
import { join } from 'components/general/Divider';

type TooltipKey = keyof typeof tooltipTexts.explorePage;

type Props = {
  rightSectionPadding: string;
};

export function ExplorePageMetadata(props: Props) {
  const { rightSectionPadding } = props;
  const settings = useRecoilValue(settingsState);
  const growthPoint = useRecoilValue(growthPointState);
  const products = useRecoilValueLoadableState(productsState);
  const { productsCount, marketShare, customerMarketShare, brandsCount, totalSales, salesGrowth } =
    products?.metadata ?? {};
  const items = [
    getFormattedValue('productsCount', productsCount ?? NaN),
    getFormattedValue('brandsCount', brandsCount ?? NaN),
    getFormattedValue('totalSales', totalSales ?? NaN),
    getFormattedValue('salesGrowth', salesGrowth?.[growthPoint]),
    getFormattedValue(
      'marketShare',
      marketShare ?? NaN,
      settings.customerName,
      settings.exploreMarketShareTitle
    ),
    ...(settings.customerName
      ? [
          getFormattedValue(
            'customerMarketShare',
            customerMarketShare,
            settings.customerName,
            settings.exploreCustomerMarketShareTitle
          ),
        ]
      : []),
  ];
  const itemElements = items.map(({ title, value, tooltipKey }) => {
    const component = (
      <li className={appStyles.verticalFlex} key={title}>
        <h5>{title}</h5>
        <h1>{value}</h1>
      </li>
    );

    return withTooltip(
      'explorePage',
      tooltipKey as TooltipKey,
      component,
      'bottom',
      undefined,
      undefined,
      settings.customerName
    );
  });

  if (isLoading(products)) {
    return null;
  }

  return (
    <div
      className={classNames(
        rightSectionPadding,
        appStyles.verticalFlex,
        appStyles.gap4,
        appStyles.backgroundBackground,
        appStyles.shadowed
      )}
    >
      <ul className={classNames(appStyles.justifySpaceBetween, styles.container)}>
        {join(itemElements)}
      </ul>
    </div>
  );
}
