import type { ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';
import styles from './SelectSelectedOptions.module.scss';
import appStyles from '../../layout/App.module.scss';
import { SelectSelectedOption } from './SelectSelectedOption';
import type { Option, RawOptionType } from 'components/general/select/types';

type Props<T extends Option<RawOptionType>> = {
  selectedOptions: T[];
  onClick: (option: T) => void;
  selectedOptionsTitle?: string;
  displayValueGetter: (option: T) => ReactNode;
  optionClassNameGetter?: (option: T) => string;
};

export function SelectSelectedOptions<T extends Option<RawOptionType>>(props: Props<T>) {
  const {
    selectedOptions,
    onClick,
    selectedOptionsTitle = 'Selected Options',
    displayValueGetter,
    optionClassNameGetter,
  } = props;
  const title = `${selectedOptionsTitle} [${selectedOptions.length}]`;

  return (
    <div className={classNames(appStyles.verticalFlex, appStyles.gap1, styles.container)}>
      <h6 className={styles.containerTitle}>{title}</h6>
      <ul
        className={classNames(
          appStyles.horizontalFlex,
          appStyles.wrap,
          styles.innerContainer,
          appStyles.overflowOverlay,
          appStyles.scrollbarHalfPadding
        )}
      >
        {selectedOptions.map((option, i) => (
          <SelectSelectedOption
            key={option.value?.toString() ?? i}
            option={option}
            onRemove={onClick}
            displayValueGetter={displayValueGetter}
            optionClassNameGetter={optionClassNameGetter}
          />
        ))}
      </ul>
    </div>
  );
}
