import classNames from 'classnames';
import React from 'react';
import type { FallbackProps } from 'react-error-boundary';
import styles from './ErrorFallback.module.scss';
import appStyles from '../layout/App.module.scss';
import { Icon } from './Icon';
import { AuthError } from 'utils/auth';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from './Loader';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { analyticsState } from 'store/analytics';
import { customerIdState } from 'store/auth';

type Props = FallbackProps;

export function ErrorFallback(props: Props) {
  const { error } = props;
  const analytics = useRecoilValue(analyticsState);
  const loadableCustomerId = useRecoilValueLoadable(customerIdState);
  const isAuthError = error instanceof AuthError;

  // To cover uncatched authentication errors, we clear the storage and cookies so the user will be redirected to
  // the login page on the next refresh
  localStorage.clear();
  invalidateAllCookies();

  analytics.track('Error', {
    customerId: loadableCustomerId.contents,
    message: error.message,
    isAuthError,
  });

  if (isAuthError) {
    const { logout } = useAuth0();

    void logout({ logoutParams: { returnTo: window.location.origin } });

    return <Loader />;
  }

  const refreshWithoutSearch = () => location.replace(location.origin + location.pathname);

  return (
    <div
      className={classNames(
        styles.container,
        appStyles.horizontalFlex,
        appStyles.alignCenter,
        appStyles.gap1
      )}
    >
      <p className={styles.message}>Something Went Wrong</p>
      <button
        type='button'
        onClick={refreshWithoutSearch}
        className={classNames(appStyles.buttonPrimary, appStyles.gap1, styles.button)}
      >
        {'Refresh '}
        <Icon name='rotate-right' weight='light' />
      </button>
    </div>
  );
}

function invalidateAllCookies() {
  const cookies = document.cookie.split(';');
  const firstDateEver = new Date(0).toUTCString();

  cookies.forEach(cookie => (document.cookie = `${cookie}=;expires=${firstDateEver}`));
}
