import type { ClientSettings } from './interfaces';

export const tooltipTexts = {
  explorePage: {
    productsTableMetadataCustomerMarketShare: (customerName?: ClientSettings['customerName']) =>
      customerName
        ? `Total sales for ${customerName} products associated with the tags relative to total category sales`
        : '',
    productsTableMetadataMarketShare:
      'Total sales of selected products associated with the tags relative to total category sales',
    productsTableMetadataSalesGrowth: "Total sales growth for tags' selection",
    productsTableMetadataProducts: 'Total Products with sales',
    productsTableMetadataBrands: 'Total Brands with sales',
    growthFilterInPeriod: 'In period growth compare the last week sales relative to first week',
    growthFilterLastYear:
      'Year over Year growth compares the current period relative to the equivalent last year',
    diamondChartDisplayFilterTitle:
      'Set the metric for determining the size of each tag in the visualization. Each metric is a result of joining the query with the displayed tag',
    diamondChartDisplayFilterBrandSignificanceWhenEnabled:
      'Explore which tags are significantly more popular within the Brand than the rest of the category',
    diamondChartDisplayFilterBrandSignificanceWhenDisabled:
      'Select single Brand or more to explore which tags are significantly more popular within the Brand than the rest of the category',
    drivers:
      'Explore Tags growing faster than the category with significant $ sales and number of products',
    drains:
      'Explore Tags growing slower than the category with significant $ sales and number of products',
    radar:
      'Explore emerging tags, growing 2+ times faster than the category with relatively low sales (configured to be less than 1% of sales associated with the query)',
    reviews:
      'Explore Tags that are highly mentioned in product Reviews and with a significant number of products.',
    marketing:
      'Explore Tags that are highly mentioned in product Listings and with a significant number of products.',
  },
  comparisonPage: {
    sortByComponent:
      'The sorting measure determines which tags will be displayed in the Venn diagram and informs the order of tags in the table on the right.',
    diffrentiationMagnitudeComponent:
      'The Differentiation magnitude filter establishes the minimum threshold for Brand Significance necessary for a tag to be differentiated for a specific entity. A differentiation magnitude of 1 indicates that the Brand Significance metric of any tag for an entity can exceed that of other entities by any amount to be classified as differentiated. Increasing the differentiation magnitude requires a stronger brand significance score to be recognized as differentiated.',
  },
  trendsPage: {
    metricFiltersTitle:
      'The filters in this section are optional, and allow you to fine tune results. Imposing more conditions further limits the results. Start with one or two filters below before going broader',
    trendingScoreFilterLabel:
      'Set score between 0 to 1, higher scores are more differentiated from the competitive set',
    productsCountFilterLabel: 'Set minimum or maximum number of products which comprise a trend',
    brandsCountFilterLabel: 'Set minimum or maximum number of brands which comprise a trend',
    marketShareFilterLabel: 'Set thresholds for trend sales relative to category sales',
    marketShareGrowthFilterLabel: "Set trend sales relative to category sales's growth range",
    totalSalesFilterLabel: "Set trend's total absolute sales range",
    salesGrowthFilterLabel: "Set trend's total sales growth range",
    salesGrowthExcludingDominantBrandFilterLabel:
      "Set the trend's growth range without the top-selling brand to reduce the impact of a dominant brand in a category",
    tagsGrowthRelativeToEachIndividualTagFilterLabel:
      "Set the trend's growth range relative to the growth of each tag in the trend. Rates greater than 1 grow faster than the individual tags, while trends smaller than 1 are slower",
    relativeToBrandGrowthFilterLabel:
      "Set the trend's growth range relative to the growth of the brands that participate in it. Rates greater than 1 are faster than the participating brands, while trends smaller than 1 are slower",
    relativeToCategoryGrowthFilterLabel:
      "Set the trend's growth range relative to the growth of the category. Rates greater than 1 are faster than the category, while trends smaller than 1 grow slower",
    tagsCategoryMaxAffiliationFilterLabel:
      'Set the level of affiliation between the tags in trend and the category. Numbers closer to 0 represent tags that are not common in the category, and 1 is a tag that exists in every product in the category',
    groupTrendByCategories: 'Display trends across all categories',
    groupTrendByMarkets: 'Display trends across all markets',
    newTrendSign: 'New Trend! Click to Explore',
    trendTrendingScore: 'Score between 0 to 1 which indicates the trend strength',
    trendSalesGrowth: "Trend's total sales growth",
    trendMarketShare: "Trend's total sales relative to the scoped categories sales",
  },
  common: {
    sourceAll: 'Tags associated with products that were mentioned in Listing or Reviews',
    sourceListing: 'Tags associated with products that were mentioned in Listing only',
    sourceReviews: 'Tags associated with products that were mentioned in Reviews only',
    sourceReviewsListing:
      'Tags associated with products that were mentioned in Listing and Reviews',
    ascSort: 'Ascending order',
    descSort: 'Descending order',
    exportNoResultsWarning: 'There are no results to export',
    exportSelectEntitiesWarning: 'Select at least 2 entities to start analysis',
  },
  navbar: {
    comparison:
      'Brand Comparison! Explore the unique market position of brands, sub brands and manufacturers',
    trends: "Trends! Identify what's breaking through",
    explore: "Explore! Analyze trends' drivers and new tags combinations",
    attribution: 'Attribution!',
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Func = (...args: any[]) => unknown;

type NestedTypes<T> = T extends object
  ? {
      [K in keyof T]-?: T[K] extends Func ? T[K] : T[K] extends object ? NestedTypes<T[K]> : T[K];
    }[keyof T]
  : never;

export type TooltipContent = NestedTypes<typeof tooltipTexts>;
