import { missingValue, string } from '@harmonya/utils';
import type { AttributeMetadata, ProductAttributes } from 'store/attribution.types';

function* generateRowEntries(
  attributesMetadata: Iterable<AttributeMetadata>,
  productAttributes: ProductAttributes
) {
  for (const { key, isPredicted, title } of attributesMetadata) {
    const { value, confidenceLevel } = productAttributes[key];
    yield [title, value] as const;

    if (isPredicted) {
      const confidenceLevelValue = confidenceLevel
        ? string.capitalize(confidenceLevel)
        : missingValue;
      yield [`${title}_ConfidenceLevel`, confidenceLevelValue] as const;
    }
  }
}

export function getFileContent(
  attributesMetadata: Iterable<AttributeMetadata>,
  productsAttributes: Iterable<ProductAttributes>
) {
  const rows = Array.from(productsAttributes, productAttributes => {
    const rowEntriesGenerator = generateRowEntries(attributesMetadata, productAttributes);

    return Object.fromEntries(rowEntriesGenerator);
  });

  return rows;
}
