type Args<MouseDownArgs extends unknown[], InitData> = {
  className: string;
  initDataGetter: (clientX: number, ...args: MouseDownArgs) => InitData;
  handler: (clientX: number, initData: InitData) => void;
};

export const onDrag = <MouseDownArgs extends unknown[], InitData>({
  className,
  initDataGetter,
  handler,
}: Args<MouseDownArgs, InitData>) => {
  const mouseDownHandler = (clientX: number, ...args: MouseDownArgs) => {
    const abortController = new AbortController();
    const initData = initDataGetter(clientX, ...args);
    const drag = (event: MouseEvent) => handler(event.clientX, initData);
    const stopDragging = () => {
      document.body.classList.remove(className);
      abortController.abort();
    };

    document.body.classList.add(className);

    document.addEventListener('mousemove', drag, { signal: abortController.signal });
    document.addEventListener('mouseup', stopDragging, { signal: abortController.signal });
  };

  return mouseDownHandler;
};
