import type { PlotSplineOptions } from 'highcharts';
import { styleVariables } from 'utils/styleVariables';
import type { ColorizedSalesSeriesItem } from 'components/general/charts/types';
import { withModifiedSaturation } from 'utils/color';
import { formatDate } from '@harmonya/utils';

const saturatedLineChartColorScheme = styleVariables.lineChartColorScheme.map(color =>
  withModifiedSaturation(color, saturation => saturation + 15)
);

export function getChartColors(hasQuery = false) {
  return hasQuery ? saturatedLineChartColorScheme : styleVariables.lineChartColorScheme;
}

export function seriesNameFormatter(name: string) {
  return formatDate(name, true);
}

export function getSeriesColorProps(item: ColorizedSalesSeriesItem): Partial<PlotSplineOptions> {
  return item.isQuery
    ? {
        lineWidth: 4,
        shadow: { color: 'var(--theme-secondary-color-dark)', offsetY: 4, width: 8 },
        color: {
          linearGradient: { x1: 0.3, x2: 1, y1: 1, y2: 0 },
          stops: styleVariables.gradientColorScheme.map((color, i) => [i, color]),
        },
      }
    : {
        lineWidth: 2,
        shadow: false,
        color: item.color,
      };
}
