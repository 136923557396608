import type { ComponentProps } from 'react';
import React, { Suspense } from 'react';
import classNames from 'classnames';
import type { ViewKey } from './ExplorePageSubSection';
import { ExplorePageSubSection } from './ExplorePageSubSection';
import appStyles from '../App.module.scss';
import styles from './ExplorePageSubSections.module.scss';
import { settingsState } from 'store/settings';
import { categorySalesEnabledLevelsState, categorySalesState } from 'store/categorySales';
import { brandSalesState, brandSalesEnabledLevelsState } from 'store/brandSales';
import { productSalesState } from 'store/productSales';
import { marketSalesState } from 'store/marketSales';
import { array, string, iterator } from '@harmonya/utils';
import { SubSectionMap } from '../../general/charts/SubSectionMap';
import type { SalesSeries } from 'models/SalesSeries';
import { Loader } from 'components/general/Loader';
import { formatters } from 'utils/formatters';
import { SubSectionChart } from '../../general/charts/SubSectionChart';
import { SubSectionBarChart } from '../../general/charts/SubSectionBarChart';
import { SubSectionLineChart } from '../../general/charts/SubSectionLineChart';
import {
  explorePageBrandingLevelState,
  explorePageCategoryLevelState,
  explorePagePeriodIdsState,
} from 'store/explorePage';
import { periodsState } from 'store/periods';
import type { RecoilValue } from 'recoil';
import { useRecoilValue } from 'recoil';
import { categoryLevelsState } from 'store/categories';
import { brandingLevelsState } from 'store/branding';
import { ExplorePageLevelSelector } from './ExplorePageLevelSelector';
import { ErrorBoundary } from 'react-error-boundary';
import { analyticsState } from 'store/analytics';
import { brandSalesSortDirectionState } from 'store/brandSales.constants';
import { productSalesSortDirectionState } from 'store/productSalesState';
import { env } from '../../../../env';
type ExplorePageSubSectionChildren = ComponentProps<typeof ExplorePageSubSection>['children'];

function isSales(activeSeriesKey: keyof SalesSeries) {
  return activeSeriesKey === 'totalSalesItems';
}

type Props = {
  rightSectionPadding: string;
};

export function ExplorePageSubSections(props: Props) {
  const { rightSectionPadding } = props;
  const analytics = useRecoilValue(analyticsState);
  const settings = useRecoilValue(settingsState);
  const periods = useRecoilValue(periodsState);
  const brandingLevel = useRecoilValue(explorePageBrandingLevelState);
  const brandingLevels = useRecoilValue(brandingLevelsState);
  const brandingTitle =
    iterator.find(brandingLevels, item => item.value === brandingLevel)?.displayValue ??
    formatters.brandsCount.title;
  const explorePagePeriodIds = useRecoilValue(explorePagePeriodIdsState);
  const computedExplorePagePeriodIds = explorePagePeriodIds.length
    ? [...explorePagePeriodIds].sort()
    : [...periods.keys()];
  const periodNames = iterator.definedMap(computedExplorePagePeriodIds, id => periods.get(id));
  const isContinuousPeriods =
    computedExplorePagePeriodIds.length > 1 &&
    [...computedExplorePagePeriodIds].every((id, i, items) => {
      const previousId = items[i - 1];
      const hasPrevious = previousId != null;
      const nextId = items[i + 1];
      const hasNext = nextId != null;

      return (!hasPrevious || previousId + 1 === id) && (!hasNext || nextId - 1 === id);
    });
  const formattedPeriodNames = isContinuousPeriods
    ? `${periodNames[0]}-${periodNames.at(-1)}`
    : periodNames.join(', ');
  const brandsSortState = env.ENABLE_SORT_LINE_CHART ? brandSalesSortDirectionState : undefined;
  const productsSortState = env.ENABLE_SORT_LINE_CHART ? productSalesSortDirectionState : undefined;
  const renderSubSectionChartChildren =
    (salesSeriesState: RecoilValue<SalesSeries>): ExplorePageSubSectionChildren =>
    (activeSeriesKey: ViewKey) => [
      {
        iconName: 'line-chart',
        label: `${isSales(activeSeriesKey) ? 'Sales' : 'Sales Growth'} Over Time`,
        placeholder: 'No Sales',
        element: (
          <SubSectionChart
            sortByQueryEnabled
            activeSeriesKey={activeSeriesKey}
            salesSeriesState={salesSeriesState}
          >
            {innerProps => <SubSectionLineChart {...innerProps} />}
          </SubSectionChart>
        ),
      },
      {
        iconName: 'bar-chart',
        label: (
          <>
            {`${isSales(activeSeriesKey) ? 'Total Sales' : 'Sales Growth'} in `}
            <div className={styles.formattedPeriodNames}>{formattedPeriodNames}</div>
          </>
        ),
        placeholder: 'No Sales',
        element: (
          <SubSectionChart
            alignLegend='left'
            labelTooltipEnabled
            activeSeriesKey={activeSeriesKey}
            salesSeriesState={salesSeriesState}
          >
            {innerProps => <SubSectionBarChart {...innerProps} />}
          </SubSectionChart>
        ),
      },
    ];

  const renderSubSectionMarketChartsChildren =
    (): ExplorePageSubSectionChildren => (activeSeriesKey: ViewKey) => {
      const chartChildren = renderSubSectionChartChildren(marketSalesState)(activeSeriesKey);
      const ensuredChartChildren = array.ensure(chartChildren);

      if (settings.map) {
        ensuredChartChildren.unshift({
          iconName: 'map',
          label: isSales(activeSeriesKey) ? 'Total Sales' : 'Sales Growth',
          placeholder: 'Market map cannot be displayed',
          element: (
            <Suspense fallback={<Loader />}>
              <ErrorBoundary fallback={null} onError={() => analytics.track('map error')}>
                <SubSectionMap
                  retailerDisplayName={settings.map.retailerDisplayName}
                  activeSeriesKey={activeSeriesKey}
                />
              </ErrorBoundary>
            </Suspense>
          ),
        });
      }

      return ensuredChartChildren;
    };

  return (
    <div
      className={classNames(
        appStyles.flexGrow1,
        appStyles.positionRelative,
        rightSectionPadding,
        appStyles.verticalFlex,
        appStyles.alignCenter,
        appStyles.gap4,
        appStyles.overflowOverlay,
        styles.container
      )}
    >
      <ExplorePageSubSection
        title='Categories'
        iconName='cube'
        defaultViewKey='salesGrowthItems'
        displayFilters={
          <ExplorePageLevelSelector
            levelState={explorePageCategoryLevelState}
            optionsState={categoryLevelsState}
            enabledLevelsState={categorySalesEnabledLevelsState}
            iconName='category-levels'
          />
        }
      >
        {renderSubSectionChartChildren(categorySalesState)}
      </ExplorePageSubSection>
      <ExplorePageSubSection title='Markets' iconName='location-crosshairs'>
        {renderSubSectionMarketChartsChildren()}
      </ExplorePageSubSection>
      <ExplorePageSubSection
        title={string.plural(brandingTitle)}
        sectionId='branding'
        sortState={brandsSortState}
        iconName={formatters.brandsCount.icon.name}
        displayFilters={
          <ExplorePageLevelSelector
            levelState={explorePageBrandingLevelState}
            optionsState={brandingLevelsState}
            enabledLevelsState={brandSalesEnabledLevelsState}
            iconName='brand-levels'
          />
        }
      >
        {renderSubSectionChartChildren(brandSalesState)}
      </ExplorePageSubSection>
      <ExplorePageSubSection
        title={formatters.productsCount.title}
        sortState={productsSortState}
        iconName={formatters.productsCount.icon.name}
      >
        {renderSubSectionChartChildren(productSalesState)}
      </ExplorePageSubSection>
    </div>
  );
}
