import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Tabs } from '../../general/Tabs';
import type {
  ComparableItem,
  TabName,
} from 'components/layout/comparisonPage/comparison-page.types';
import appStyles from 'components/layout/App.module.scss';
import styles from './ComparisonPageDiagramDetails.module.scss';
import { TagSection } from './diagramDetails/TagSection';
import { useSearchParam } from 'hooks/useSearchParam';
import { useComparableItems } from 'hooks/useComparableItems';
import { useRecoilState, useRecoilValue } from 'recoil';
import { comparisonPageComperableItemIdsState } from 'store/comparisonPage';
import { Loader } from 'components/general/Loader';
import { EmptyTags } from './diagramDetails/EmptyTags';
import { useExpandableTags } from './diagramDetails/hooks/useExpandableTags';
import { ReactComponent as NoResults } from '../../../assets/emptyState/brandsFilter.svg';
import { EmptyState } from 'components/general/EmptyState';
import { analyticsState } from 'store/analytics';
import { Path } from 'components/general/Path';
import { iterator } from '@harmonya/utils';
import {
  getTooltipContentByTabName,
  tabNames,
} from 'components/layout/comparisonPage/comparison-page.constants';

type Props = {
  differentiationMagnitude: number;
  focusOn?: ComparableItem;
  resetFocusOn: () => void;
  width: number;
};

export function ComparisonPageDiagramDetails(props: Props) {
  const { differentiationMagnitude, focusOn, resetFocusOn, width } = props;
  const [selectedTabName, setSelectedTabName] = useSearchParam<TabName>('tabName', {
    defaultValue: tabNames[0],
  });
  const [comparableItemIds, setComparableItemIds] = useRecoilState(
    comparisonPageComperableItemIdsState
  );
  const [commonEntityName, setCommonEntityName] = useState<string | undefined>();
  const { searchTags, getFilteredTagList, changeAllSectionExpanded } = useExpandableTags(
    selectedTabName,
    differentiationMagnitude
  );
  const analytics = useRecoilValue(analyticsState);
  const { sortDirection, comparableItems } = useComparableItems(differentiationMagnitude);

  if (!comparableItems) {
    return <Loader />;
  }

  const majors = comparableItems.filter(item => item.sets?.length === 1);

  const getDefaultcommonEntityName = () => {
    const maxComparableBrand = iterator.max(comparableItems, brand =>
      !brand.sets || brand.sets.length <= 1 ? undefined : brand.sets.length
    );

    return maxComparableBrand?.name;
  };

  const getCommonBrand = () => {
    let commonBrand = comparableItems.find(item => item.name === commonEntityName);

    if (!commonBrand) {
      const defaultcommonEntityName = getDefaultcommonEntityName();

      if (defaultcommonEntityName) {
        commonBrand = comparableItems.find(item => item.name === defaultcommonEntityName);
      }
    }

    return commonBrand;
  };

  const resetComparableItem = (id: number) => {
    setComparableItemIds(comparableItemIds.filter(itemId => itemId !== id));
  };

  useEffect(() => {
    if (focusOn) {
      const isCommonTags = focusOn.sets?.length > 1;

      if (isCommonTags) {
        setCommonEntityName(focusOn.name);
        setSelectedTabName('Shared Tags');
      } else if (selectedTabName === 'Shared Tags') {
        setSelectedTabName(tabNames[0]);
      }
    } else {
      setCommonEntityName(getDefaultcommonEntityName());
    }
  }, [focusOn]);

  useEffect(() => {
    const isToShared = selectedTabName === 'Shared Tags';

    if (isToShared && !focusOn) {
      setCommonEntityName(getDefaultcommonEntityName());
    }
  }, [selectedTabName]);

  useEffect(() => {
    changeAllSectionExpanded(selectedTabName, false);
  }, [sortDirection]);

  useEffect(() => {
    resetFocusOn();
  }, [comparableItemIds.length]);

  const commonBrand = getCommonBrand();
  const tagSectionsRef = useRef<HTMLDivElement>(null);

  const trackAndResetComparableItem = (item: ComparableItem) => {
    resetComparableItem(item.itemIds[0]);
    analytics.track('Reset a single entity', {
      name: 'Eliipsis',
      type: 'button',
      value: item.name,
    });
  };

  const getTitleElement = (items: typeof comparableItems, tagsCount: string) => {
    const getOrDefault = (
      item: (typeof comparableItems)[number],
      key: 'fullPathParts' | 'compactPathParts'
    ) => item[key] ?? [item.name];
    const pathItems = items.map((item, itemIndex) => (
      <Path
        key={`${item.name}-${itemIndex}`}
        fullPathParts={getOrDefault(item, 'fullPathParts')}
        compactPathParts={getOrDefault(item, 'compactPathParts')}
      />
    ));

    return (
      <span
        className={classNames(
          appStyles.horizontalFlex,
          appStyles.gap1,
          appStyles.justifySpaceBetween,
          appStyles.alignBaseline,
          appStyles.overflowHidden
        )}
      >
        {iterator.join(pathItems, index => (
          <span key={`arrow-${index}`}>&</span>
        ))}
        <span className={styles.titleTagsCount}>{`(${tagsCount})`}</span>
      </span>
    );
  };

  const getCommonTitleElement = (
    commonItem: (typeof comparableItems)[number],
    tagsCount: string
  ) => {
    const items = iterator.definedMap(commonItem.itemIds, itemId =>
      comparableItems.find(
        comparableItem =>
          comparableItem.itemIds.length === 1 && comparableItem.itemIds[0] === itemId
      )
    );

    return getTitleElement(items as ComparableItem[], tagsCount);
  };

  return (
    <div
      className={classNames(
        styles.container,
        appStyles.unpaddedBox,
        appStyles.verticalFlex,
        appStyles.backgroundBackground,
        appStyles.padded0,
        appStyles.shadowed
      )}
    >
      <div
        className={classNames(
          styles.header,
          appStyles.horizontalFlex,
          appStyles.justifySpaceBetween,
          appStyles.alignCenter
        )}
      >
        <div className={classNames(appStyles.horizontalFlex, appStyles.fullHeight)}>
          <Tabs
            names={tabNames}
            isSelected={tabName => selectedTabName === tabName}
            tooltipGetter={(tab: TabName) =>
              getTooltipContentByTabName(
                differentiationMagnitude,
                majors.map(item => item.name)
              )[tab]
            }
            onSelect={tabName => {
              setSelectedTabName(tabName);
              resetFocusOn();
            }}
          />
        </div>
      </div>
      {!comparableItems.length || (selectedTabName === 'Shared Tags' && !commonBrand) ? (
        <EmptyTags
          text={[`Select ${comparableItems.length ? 'another' : 'an'} entity to start analyzing`]}
        />
      ) : (
        <div
          ref={tagSectionsRef}
          className={classNames(appStyles.horizontalFlex, appStyles.gap3, styles.brandsTable)}
        >
          {selectedTabName === 'Shared Tags' ? (
            commonBrand &&
            (getFilteredTagList(commonBrand, selectedTabName).length ? (
              <TagSection
                tab={selectedTabName}
                key={`${commonBrand.itemIds.join(',')}|${selectedTabName}`}
                comparableItem={commonBrand}
                titleGetter={tagsCount => getCommonTitleElement(commonBrand, tagsCount)}
                differentiationMagnitude={differentiationMagnitude}
                metricDetailsTitlesDisplayed={width > +styles.minWidthToDisplayTitles}
              />
            ) : (
              <EmptyState
                image={<NoResults />}
                message={
                  searchTags.length ? (
                    <span>No matches found</span>
                  ) : (
                    <>
                      <span>We couldn&apos;t find any Tags that match your comparison.</span>
                      <span>Try adjusting the filters </span>
                    </>
                  )
                }
              />
            ))
          ) : (
            <>
              {majors
                .map(item => getFilteredTagList(item, selectedTabName).length)
                .some(length => length > 0) ? (
                comparableItems
                  .filter(item => item.sets?.length === 1)
                  .map(
                    item =>
                      item.sets?.length === 1 && (
                        <TagSection
                          tab={selectedTabName}
                          key={`${item.itemIds.join(',')}|${selectedTabName}`}
                          titleGetter={tagsCount => getTitleElement([item], tagsCount)}
                          resetComparableItem={() => trackAndResetComparableItem(item)}
                          disabled={!!focusOn && focusOn.name !== item.name}
                          isAnyDisabled={!!focusOn}
                          comparableItem={item}
                          differentiationMagnitude={differentiationMagnitude}
                          scrollTo={(left: number) => {
                            tagSectionsRef?.current?.scrollTo({
                              left: left - tagSectionsRef.current.offsetLeft,
                              behavior: 'smooth',
                            });
                          }}
                        />
                      )
                  )
              ) : (
                <EmptyState
                  image={<NoResults />}
                  message={
                    searchTags.length ? (
                      <span>No matches found</span>
                    ) : (
                      <>
                        <span>We couldn&apos;t find any Tags that match your comparison.</span>
                        <span>Try adjusting the filters </span>
                      </>
                    )
                  }
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
