const tiers = [' ', 'K', 'M', 'B', 'T'];

export const isNumber = (value: unknown): value is number =>
  typeof value === 'number' && !isNaN(value);

export const short = <T, R = T extends number ? string : T>(
  number: T,
  digits = 1,
  minTotalDigits = 0
): R => {
  if (!isNumber(number)) {
    return number as unknown as R;
  }

  const absValue = Math.abs(number);
  const tier = (Math.log10(absValue) / 3) | 0;
  const tierdValue = (absValue / 10 ** (tier * 3)) * Math.sign(number);
  // Flooring needed for avoid parse 0.51-1.00 to 1 instead of 0 (For example, 1,600 to 2K rather than 1K)
  const floored = digits || minTotalDigits ? tierdValue : Math.floor(tierdValue);
  const fixedCount = minTotalDigits ? minTotalDigits - getIntDigitsCount(tierdValue) : digits;
  const fixed = floored.toFixed(fixedCount);
  const computedTier = (tiers[tier] || 'E').trim();
  const result = fixed + computedTier;

  return result as unknown as R;
};

export const shortCurrency = <T>(number: T, digits = 1, minTotalDigits = 0) => {
  if (!isNumber(number)) {
    return `$${short(number, digits, minTotalDigits)}`;
  }

  const absValue = Math.abs(number);
  const shortNumber = short(absValue, digits, minTotalDigits);
  const sign = number < 0 ? '-' : '';

  return `${sign}$${shortNumber}`;
};

const getIntDigitsCount = (number: number) =>
  Math.max(Math.floor(Math.log10(Math.abs(number))), 0) + 1;

export const getPrecisionDigitsCount = (number: unknown) => {
  if (typeof number !== 'number' || !isFinite(number)) {
    return 0;
  }

  let currentNumber = 1;
  let count = 0;

  while (Math.round(number * currentNumber) / currentNumber !== number) {
    currentNumber *= 10;
    count++;
  }

  // ToLocaleString minimumFractionDigits and maximumFractionDigits limit is 20
  const FractionDigitsLimit = 20;

  return Math.min(FractionDigitsLimit, count);
};

export const format = <T extends number | string | undefined | null>(
  number: T,
  options: Intl.NumberFormatOptions = { maximumFractionDigits: 20 }
): string => (isNumber(number) ? number.toLocaleString('en-US', options) : number ?? '');

export const currency = <T extends number | string | undefined | null>(
  number: T,
  options?: Intl.NumberFormatOptions
): string => {
  const precisionDigitsCount = getPrecisionDigitsCount(number);

  return format(number, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: precisionDigitsCount,
    maximumFractionDigits: precisionDigitsCount,
    ...options,
  });
};

export const shortFractionCurrency = <T extends number | string | undefined | null>(
  number: T,
  options?: Intl.NumberFormatOptions
): string => currency(number, { ...options, minimumFractionDigits: 0, maximumFractionDigits: 2 });

export const percent = <T extends number | string | undefined | null>(
  number: T,
  options?: Intl.NumberFormatOptions
): string => {
  const precisionDigitsCount = Math.max(getPrecisionDigitsCount(number) - 2, 0);

  return format(number, {
    style: 'percent',
    minimumFractionDigits: precisionDigitsCount,
    maximumFractionDigits: precisionDigitsCount,
    ...options,
  });
};

export const singleDigitFractionPercent = <T extends number | string | undefined | null>(
  number: T,
  options?: Intl.NumberFormatOptions
): string => percent(number, { ...options, minimumFractionDigits: 1, maximumFractionDigits: 1 });

export const getDigitsCount = (number: number) => (Math.log(number) * Math.LOG10E + 1) | 0;

const defaultDecimelPointNumbers = 3;
const defaultDecimelPointFactor = 10 ** defaultDecimelPointNumbers;

export const fixedNumber = (value: number, factor = defaultDecimelPointFactor) =>
  Math.floor(value * factor) / factor;

export const fixedPercentNumber = (value: number, fractionDigits = 4) => {
  const fixedValue = +(value / 100).toFixed(fractionDigits);
  const formattedValue = percent(fixedValue);

  return formattedValue;
};

export const currencyToFloat = (value: string) => {
  return parseFloat(value.replace(/[^0-9.-]+/g, ''));
};

export function minmax(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}
