import React from 'react';
import classNames from 'classnames';
import { FilterSelects } from 'components/layout/FilterSelects';
import {
  comparisonPageCategoryIdsState,
  comparisonPageMarketIdsState,
  comparisonPagePeriodIdsState,
} from 'store/comparisonPage';
import appStyles from '../App.module.scss';
import styles from './ComparisonPageFilters.module.scss';
import { Icon } from '../../general/Icon';
import { useResetRecoilState } from 'recoil';
import type { SliderFilter } from 'components/general/sliderFilters/SliderFiltersDropdown';
import { SliderFiltersDropdown } from 'components/general/sliderFilters/SliderFiltersDropdown';
import type { AggregateTag } from 'models/AggregateTag';
import { useScopeFilters } from 'hooks/useScopeFilters';

type Props = {
  sliderFilters: SliderFilter<AggregateTag>[];
  reset: () => void;
};

export function ComparisonPageFilters(props: Props) {
  const { sliderFilters, reset } = props;
  const scopeFiltersSelects = useScopeFilters(
    comparisonPageCategoryIdsState,
    comparisonPageMarketIdsState,
    comparisonPagePeriodIdsState
  );
  const scopeFiltersSelectsResetters = scopeFiltersSelects.map(({ valuesRecoilState }) =>
    useResetRecoilState(valuesRecoilState)
  );

  const resetScopeFilters = () => {
    scopeFiltersSelectsResetters.forEach(resetter => resetter());
    reset();
  };

  return (
    <div
      className={classNames(
        appStyles.box,
        appStyles.shadowed,
        appStyles.backgroundBackground,
        appStyles.horizontalFlex,
        appStyles.alignCenter,
        appStyles.flexGrow1,
        appStyles.justifySpaceBetween,
        styles.container
      )}
    >
      <Icon name='bars-filter' className={appStyles.mediumFont} />
      <div className={classNames(appStyles.flexGrow1, styles.filters)}>
        <FilterSelects selects={scopeFiltersSelects} buttonClassName={appStyles.filter} />
        <SliderFiltersDropdown buttonTitle='Metrics' sliderFilters={sliderFilters} reset={reset} />
      </div>
      <button className={appStyles.unpaddedButton} type='button' onClick={resetScopeFilters}>
        Reset
      </button>
    </div>
  );
}
