import type { HttpMethod } from '@harmonya/utils';
import { fetchMock } from './fetchMock';
import { getAccessTokenSilently, AuthError } from './auth';
import { cachedFetch } from './cachedFetch';
import { env } from '../../env';

interface ExtendedRequestInit extends RequestInit {
  isFile?: boolean;
}

const getUrlWithSearchParams = (url: string, params = {}) => {
  const search = new URLSearchParams(params).toString();
  return search ? `${url}?${search}` : url;
};

class ServerError extends Error {
  name = 'ServerError';
}

const isNonEmptyResponse = (response: Response) =>
  response.status !== 204 && response.statusText !== 'No Content';

export function getCustomHeaders(customerId: string, userEmail: string) {
  return { 'Customer-Id': customerId, 'User': userEmail };
}

export const genericFetch = async <B extends object = object>(
  url: string,
  customerId: string,
  userEmail: string,
  method: HttpMethod,
  body?: B,
  params?: object,
  { isFile, ...init }: ExtendedRequestInit = {},
  groupKey = ''
) => {
  let responseBody;

  let authToken: string;
  try {
    authToken = await getAccessTokenSilently();
  } catch (error) {
    throw new AuthError(error as Error);
  }

  if (env.USE_MOCK) {
    responseBody = fetchMock(url, method, body, params, init);
  }

  if (responseBody == null) {
    const computedUrl = env.SERVER_URL + getUrlWithSearchParams(url, params);
    const computedBody = body && method !== 'GET' && { body: JSON.stringify(body) };
    const pathname = location.pathname.substring(1);
    const customHeaders = env.USE_AUTH && getCustomHeaders(customerId, userEmail);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
      'Pathname': pathname,
      ...customHeaders,
    };
    const cacheKey = JSON.stringify([computedUrl, method, computedBody, customerId]);
    const response = await cachedFetch(cacheKey, computedUrl, `${url}${groupKey}`, {
      method,
      ...computedBody,
      headers,
      ...init,
    });

    if (response.status === 401) {
      throw new AuthError(response);
    } else if (!response.ok) {
      throw new ServerError(response.statusText);
    }

    if (isNonEmptyResponse(response)) {
      if (isFile) {
        const arrayBuffer = await response.arrayBuffer();
        const blob = new Blob([arrayBuffer]);

        return blob;
      }

      responseBody = await response.json();
    }
  }

  return responseBody;
};

export const fetchGet = <T>(
  url: string,
  customerId: string,
  userEmail: string,
  params?: object,
  init?: ExtendedRequestInit
): Promise<T> => genericFetch(url, customerId, userEmail, 'GET', {}, params, init);

export const fetchPost = <T, B extends object = object>(
  url: string,
  customerId: string,
  userEmail: string,
  body?: B,
  params?: object,
  init?: ExtendedRequestInit,
  groupKey?: string
): Promise<T> => genericFetch(url, customerId, userEmail, 'POST', body, params, init, groupKey);

export const fetchPut = <T, B extends object = object>(
  url: string,
  customerId: string,
  userEmail: string,
  body?: B,
  params?: object,
  init?: ExtendedRequestInit
): Promise<T> => genericFetch(url, customerId, userEmail, 'PUT', body, params, init);

export const fetchDelete = <T, B extends object = object>(
  url: string,
  customerId: string,
  userEmail: string,
  body?: B,
  params?: object,
  init?: ExtendedRequestInit
): Promise<T> => genericFetch(url, customerId, userEmail, 'DELETE', body, params, init);
