import { isObject } from './object';

export const isDate = (value: unknown): value is Date =>
  isObject(value) && value.constructor === Date;

const millisecondsInSecond = 1000;
const millisecondsInMinute = millisecondsInSecond * 60;
const millisecondsInHour = millisecondsInMinute * 60;
const millisecondsInDay = millisecondsInHour * 24;
type DateValue = ConstructorParameters<typeof Date>[0];

export function daysDiff(first: Date, second: Date) {
  const millisecondsGap = Math.abs(+first - +second);
  const result = Math.ceil(millisecondsGap / millisecondsInDay);

  return result;
}

export function formatDate(value: DateValue, yearShown = false) {
  const date = new Date(value);
  const formattedDate = date.toLocaleString(undefined, {
    year: yearShown ? '2-digit' : undefined,
    month: 'short',
    day: 'numeric',
  });

  return formattedDate;
}

export function formatDateTime(value: DateValue): string {
  const date = new Date(value);
  const formattedDate = date.toLocaleString(undefined, {
    month: 'long',
    day: 'numeric',
  });
  const formattedTime = date.toLocaleString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
  const formattedDateTime = `${formattedDate}, ${formattedTime}`;

  return formattedDateTime;
}

const formatDatePart = (part: number) => part.toString().padStart(2, '0');

export const getDateKey = (date: Date) =>
  `${formatDatePart(date.getMonth() + 1)}.${formatDatePart(date.getDate())}.${date.getFullYear()}`;
