import type { PropsWithChildren, ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';
import { Icon } from '../../../general/Icon';
import appStyles from '../../App.module.scss';
import styles from './TagProgressiveCard.module.scss';
import type { AggregateTag } from 'models/AggregateTag';
import { Tooltip } from 'components/general/Tooltip';
import { Tag } from 'components/general/Tag';
import { useRecoilValue } from 'recoil';
import { analyticsState } from 'store/analytics';
import { TagTooltip } from 'components/general/TagTooltip';
import { ignoredTagTooltipProps } from 'components/layout/comparisonPage/comparison-page.constants';

type Props = PropsWithChildren<{
  tag: AggregateTag;
  hideTag: (tagId: number) => void;
  style?: React.CSSProperties;
  collapsedRowClassName?: string;
  isExpanded: boolean;
  onIconClick: () => void;
  details: ReactNode;
}>;

export function TagProgressiveCard(props: Props) {
  const { tag, hideTag, style, collapsedRowClassName, isExpanded, onIconClick, details, children } =
    props;
  const analytics = useRecoilValue(analyticsState);

  const handleClick = () => {
    analytics.track('Tag card toggled', { name: 'Tag card', value: tag.name, type: 'tagCard' });
    onIconClick();
  };

  return (
    <div
      style={style}
      className={classNames(
        appStyles.verticalFlex,
        appStyles.gap0,
        styles.container,
        !isExpanded && styles.collapsed
      )}
    >
      <div
        className={classNames(
          collapsedRowClassName,
          styles.visibleRowContainer,
          appStyles.horizontalFlex,
          appStyles.gap1,
          appStyles.justifySpaceBetween
        )}
      >
        <Tooltip
          className={appStyles.overflowHidden}
          key={tag.id}
          isHoverable
          content={<TagTooltip tag={tag} hideTag={hideTag} ignoredKeys={ignoredTagTooltipProps} />}
        >
          <Tag name={tag.name} colorId={tag.dominantType?.colorId} />
        </Tooltip>
        <span
          className={classNames(
            styles.detailsActionsContainer,
            appStyles.horizontalFlex,
            appStyles.justifySpaceBetween
          )}
        >
          <span
            className={classNames(appStyles.horizontalFlex, appStyles.alignCenter, appStyles.gap2)}
          >
            {details}
          </span>
          <Icon
            onClick={handleClick}
            className={classNames(appStyles.mediumFont, styles.arrow)}
            name='angle-up'
          />
        </span>
      </div>
      <div className={styles.collapsable}>{children}</div>
    </div>
  );
}
