import { useRecoilValue, useSetRecoilState } from 'recoil';
import { analyticsState } from 'store/analytics';
import { toastMessageState } from 'store/toastMessage';
import { downloadCsvFiles, type ExportableCsv } from 'utils/file';
import type { PathName } from 'utils/pageName';

export function useCsvExport(pathName: PathName, generateCsvFiles: () => ExportableCsv[]) {
  const analytics = useRecoilValue(analyticsState);
  const setToastMessage = useSetRecoilState(toastMessageState);

  const handleExport = () => {
    try {
      const files = generateCsvFiles();
      analytics.track('CSV Export', {
        path: pathName,
        type: 'button',
        name: 'download',
        files: files.map(file => file.name),
      });
      void downloadCsvFiles(pathName, files);
    } catch (error) {
      setToastMessage({ type: 'error', text: 'CSV export failed' });
    }
  };

  return handleExport;
}
