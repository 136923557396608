import { useEffect } from 'react';
import { useHotkeysContext } from 'react-hotkeys-hook';

export const useEnableOnlySingleHotKeysScope = (scope: string) => {
  const { enableScope } = useHotkeysContext();

  useEffect(() => {
    enableScope(scope);

    return () => enableScope('*');
  }, []);

  return scope;
};
