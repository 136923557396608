import type { ComponentProps } from 'react';
import React from 'react';
import type { Options as HighchartsOptions, SeriesSplineOptions } from 'highcharts';
import Highcharts from 'highcharts';
import { styleVariables } from '../../../utils/styleVariables';
import { seriesNameFormatter, getSeriesColorProps } from './chart.utils';
import type { SubSectionChart } from './SubSectionChart';
import HighchartsReact from 'highcharts-react-official';
import { object, string } from '@harmonya/utils';

type Props = Parameters<ComponentProps<typeof SubSectionChart>['children']>[0];

export function SubSectionLineChart(props: Props) {
  const { rawDataItems, activatedIds, hoveredIds, valueFormatFunction, tooltipGetter } = props;

  if (rawDataItems) {
    const series = rawDataItems.map(item => {
      const colorProps = getSeriesColorProps(item);

      return {
        visible: activatedIds.has(item.id),
        opacity: hoveredIds.size && !hoveredIds.has(item.id) ? 0.15 : 1,
        name: item.id,
        data: item.data.map(({ x, y }) => ({ name: seriesNameFormatter(x), y })),
        ...colorProps,
      };
    });
    const options: HighchartsOptions = {
      accessibility: { enabled: false },
      chart: {
        type: 'spline',
        backgroundColor: 'transparent',
      },
      credits: { enabled: false },
      title: undefined,
      series: series as SeriesSplineOptions[],
      xAxis: {
        minPadding: 0,
        labels: {
          formatter: label => {
            // We know the value is always a number (the index of the point)
            if (string.isString(label.value)) {
              return label.value;
            }

            const i = label.value;
            const dataItem = (label.chart.userOptions.series as typeof series)[0]?.data?.[i];
            const name = object.getIfMatch(dataItem, 'name', 'string') ?? '';

            return name;
          },
          style: {
            color: 'var(--theme-secondary-color-dark)',
            fontSize: '1rem',
            fontFamily: styleVariables.titleFontFamiliy,
          },
        },
        tickWidth: 0,
        lineColor: 'var(--theme-secondary-color-regular)',
        lineWidth: 1,
        crosshair: { width: 1, dashStyle: 'Dash', color: 'var(--theme-secondary-color-dark)' },
      },
      yAxis: {
        title: { text: null },
        lineWidth: 1,
        lineColor: 'var(--theme-secondary-color-regular)',
        gridLineColor: 'transparent',
        labels: {
          formatter: ({ value }) => valueFormatFunction(value),
          style: {
            color: 'var(--theme-secondary-color-dark)',
            fontSize: '1rem',
            fontFamily: styleVariables.titleFontFamiliy,
          },
        },
        plotLines: [
          {
            value: 0,
            color: 'var(--theme-secondary-color-regular)',
          },
        ],
      },
      legend: { enabled: false },
      tooltip: {
        outside: true,
        enabled: true,
        shared: true,
        shadow: false,
        borderWidth: 0,
        useHTML: true,
        backgroundColor: undefined,
        formatter: tooltipGetter,
      },
      plotOptions: {
        series: {
          states: { hover: { enabled: false } },
          marker: { enabled: false },
          lineWidth: 2,
        },
      },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }

  return null;
}
