import { selector } from 'recoil';
import { salesSeriesToSalesSeriesItems } from '../utils/timeSeriesConverter';
import { getSalesFilters, explorePageCategoryLevelState } from './explorePage';
import { productsState } from './products';
import type { SalesSeries, SalesSeriesItems } from '@harmonya/models';

export const categorySalesEnabledLevelsState = selector({
  key: 'categorySalesEnabledLevels',
  get: ({ get }) => {
    const products = get(productsState);

    if (!products.metadata) {
      return new Set<number>();
    }

    const { categorySalesDataByLevel } = products.metadata;
    const enabledLevels = Object.keys(categorySalesDataByLevel).map(Number);

    return new Set(enabledLevels);
  },
});

export const categorySalesState = selector({
  key: 'categorySalesState',
  get: ({ get }) => {
    const levelId = get(explorePageCategoryLevelState);

    if (levelId) {
      const { salesLimit } = getSalesFilters(get);
      const products = get(productsState);

      if (!products.metadata) {
        return {
          totalSalesItems: [],
          salesGrowthItems: { inPeriod: [] },
        };
      }

      const { categorySalesDataByLevel, querySalesData } = products.metadata;
      const categoriesSalesData = categorySalesDataByLevel[levelId];

      if (categoriesSalesData) {
        const combine = (getter: (series: SalesSeries) => SalesSeriesItems) =>
          [
            ...(querySalesData ? getter(querySalesData) ?? [] : []),
            ...(getter(categoriesSalesData) ?? []),
          ]
            .slice(0, salesLimit)
            .sort((a, b) => b.aggregatedValue - a.aggregatedValue);
        const combinedSales = {
          keys: categoriesSalesData.keys,
          totalSalesItems: combine(sales => sales.totalSalesItems),
          salesGrowthItems: {
            inPeriod: combine(sales => sales.salesGrowthItems.inPeriod),
            ...(!!categoriesSalesData.salesGrowthItems.lastYear && {
              lastYear: combine(sales => sales.salesGrowthItems.lastYear as SalesSeriesItems),
            }),
          },
        };
        const sales = salesSeriesToSalesSeriesItems(combinedSales);

        return sales;
      }
    }

    return {
      totalSalesItems: [],
      salesGrowthItems: { inPeriod: [] },
    };
  },
});
