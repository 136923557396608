import React from 'react';
import classNames from 'classnames';
import styles from './SelectExpandCollapseButton.module.scss';
import appStyles from '../../layout/App.module.scss';
import { Icon } from '../Icon';

type Props = {
  isAllExpanded: boolean;
  onClick: () => void;
  colorInverted?: boolean;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export function SelectExpandCollapseButton(props: Props) {
  const { isAllExpanded, onClick, className, colorInverted, ...buttonProps } = props;
  const computedClassName = classNames(
    appStyles.unpaddedButton,
    appStyles.horizontalFlex,
    appStyles.gap1,
    styles.container,
    className,
    colorInverted && appStyles.colorInverted
  );
  const buttonText = isAllExpanded ? 'Collapse All' : 'Expand All';

  return (
    <button className={computedClassName} onClick={onClick} {...buttonProps}>
      <Icon
        name='arrow-turn-down'
        weight='light'
        flip={isAllExpanded ? 'vertical' : 'horizontal'}
      />
      {buttonText}
    </button>
  );
}
