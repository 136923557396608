import React from 'react';
import appStyles from '../layout/App.module.scss';
import styles from './InlineQuery.module.scss';
import type { Tag } from 'models/Tag';
import { Tag as TagComponent } from './Tag';
import { Tooltip } from './Tooltip';
import tagTooltipStyles from './TagTooltip.module.scss';
import { Capsule } from './Capsule';
import classNames from 'classnames';
import { queryToTokens } from '../../functions';

type Props = {
  query: string;
  tags: Map<number, Tag>;
};

export function InlineQuery(props: Props) {
  const { query, tags } = props;
  const queryTokens = queryToTokens(query);
  const queryElements = queryTokens.map((token, index) => {
    const isOperand = token.type === 'operand';

    if (isOperand && token.id) {
      const tokenId = Number(token.id);
      const tag = tags.get(tokenId);
      const tagType = tag?.dominantType;

      const tooltipContent = tagType && (
        <div
          className={classNames(appStyles.horizontalFlex, appStyles.gap1, appStyles.alignCenter)}
        >
          <span>Type: </span>
          <Capsule
            className={tagTooltipStyles.tagType}
            id={tagType.id}
            text={tagType.name}
            colorId={tagType.colorId}
          />
        </div>
      );

      const tagElement = (
        <TagComponent
          name={tag?.name ?? ''}
          colorId={tag?.dominantType?.colorId ?? 0}
          className={styles.operand}
        />
      );

      const tagElementWithTooltip = tooltipContent ? (
        <Tooltip key={`${index}_${tokenId}`} content={tooltipContent}>
          {tagElement}
        </Tooltip>
      ) : (
        tagElement
      );

      return tagElementWithTooltip;
    }

    return (
      <span key={`${index}_${token.id}`} className={styles.operator}>
        {token.id ?? ''}
      </span>
    );
  });

  return <>{queryElements}</>;
}
