import { useSetRecoilState } from 'recoil';
import { toastMessageState } from 'store/toastMessage';

export const useClipboard = () => {
  const setToastMessage = useSetRecoilState(toastMessageState);

  const copy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);

      setToastMessage({
        type: 'success',
        text: `"${text}" has been copied to the clipboard`,
      });
    } catch {
      setToastMessage({
        type: 'error',
        text: `Failed to copy "${text}" to the clipboard`,
      });
    }
  };

  const paste = async (setter: (value: string) => void) => {
    const text = await navigator.clipboard.readText();

    setter(text);
  };

  return { copy, paste };
};
