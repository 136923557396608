import type {
  CSSProperties,
  DetailedHTMLProps,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
} from 'react';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './Tooltip.module.scss';
import appStyles from '../layout/App.module.scss';
import { Transition } from 'react-transition-group';
import { styleVariables } from '../../utils/styleVariables';
import type { TooltipContent } from '../../tooltipTexts';
import { tooltipTexts } from '../../tooltipTexts';
import { createPortal } from '../../utils/portal';
import { number, string } from '@harmonya/utils';
import { useBooleanState } from 'hooks/useBooleanState';

type HorizontalDirection = 'center' | 'left' | 'right';
type VerticalDirection = 'center' | 'top' | 'bottom';
type Position = Required<DOMRectInit>;

type SVGProps = React.SVGProps<SVGGElement>;
type SpanProps = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const getVerticalOffset = (
  direction: VerticalDirection,
  height: number,
  tooltipHeight: number,
  padding: number
) => {
  switch (direction) {
    case 'center':
      return height / 2 - tooltipHeight / 2;
    case 'top':
      return -tooltipHeight - padding;
    case 'bottom':
      return height + padding;
  }
};

const getHorizontalOffset = (
  direction: HorizontalDirection,
  width: number,
  tooltipWidth: number,
  padding: number
) => {
  switch (direction) {
    case 'center':
      return width / 2 - tooltipWidth / 2;
    case 'left':
      return -tooltipWidth - padding;
    case 'right':
      return width + padding;
  }
};

const getPosition = (element: Element) => {
  const elementBoundingClientRect = element.getBoundingClientRect();
  const { width, height } = elementBoundingClientRect;
  const { left, top } =
    element.firstChild instanceof Element
      ? element.firstChild.getBoundingClientRect()
      : elementBoundingClientRect;

  return { x: left, y: top, width, height };
};

const getCoords = (
  position: Position,
  tooltipElement: HTMLDivElement,
  verticalDirection: VerticalDirection,
  horizontalDirection: HorizontalDirection,
  padding: number
) => {
  const { x, y, width, height } = position;
  const { width: tooltipWidth, height: tooltipHeight } = tooltipElement.getBoundingClientRect();
  const verticalOffset = getVerticalOffset(verticalDirection, height, tooltipHeight, padding);
  const horizontalOffset = getHorizontalOffset(horizontalDirection, width, tooltipWidth, padding);

  const getValidatedOffset = (offset: number, windowSpace: number) => {
    const validatedOffset = number.minmax(offset, padding, windowSpace - padding);

    return validatedOffset;
  };

  const newCoords = {
    left: getValidatedOffset(x + horizontalOffset, window.innerWidth - tooltipWidth),
    top: getValidatedOffset(y + verticalOffset, window.innerHeight - tooltipHeight),
  };

  return newCoords;
};

function isHovered(ref: React.RefObject<HTMLElement>) {
  return ref.current?.matches(':hover');
}

type Props<IsSvg extends boolean> = PropsWithChildren<{
  content: ReactNode;
  verticalDirection?: VerticalDirection;
  horizontalDirection?: HorizontalDirection;
  padding?: number;
  position?: Position;
  isSvg?: IsSvg;
  isHoverable?: boolean;
  tooltipClassName?: string;
}> &
  (IsSvg extends true ? SVGProps : SpanProps);

export function Tooltip<IsSvg extends boolean>(props: Props<IsSvg>) {
  const {
    content,
    verticalDirection = 'top',
    horizontalDirection = 'center',
    padding = styleVariables.padding,
    position,
    isSvg,
    isHoverable,
    tooltipClassName,
    children,
    ...containerProps
  } = props;
  const [isOpen, open, close] = useBooleanState(false);
  const [coords, setCoords] = useState({ left: 0, top: 0 });
  const containerRef = useRef<HTMLElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [currentContent, setCurrentContent] = useState<ReactNode>();

  // When there is a multiple elements that each have a tooltip, and when the mouse moves over the tooltip a change
  // occurs in the array, the content will change but the tooltip will remain. To prevent and control this, we manage
  // two content variables.
  useEffect(() => {
    setTimeout(setCurrentContent, styleVariables.animationDuration, content);
  }, [content]);

  const onMouseEnter: React.MouseEventHandler = event => {
    const element = event.currentTarget;

    open();

    setTimeout(() => {
      if (tooltipRef.current) {
        const newCoords = getCoords(
          position ?? getPosition(element),
          tooltipRef.current,
          verticalDirection,
          horizontalDirection,
          padding
        );

        setCoords(newCoords);
      }
    });
  };

  const onMouseOut = () => {
    if (!isHovered(containerRef) && (!isHoverable || !isHovered(tooltipRef))) {
      close();
    }
  };

  const computedContainerProps = {
    onMouseEnter,
    onMouseOut,
    ref: containerRef,
    ...containerProps,
  };
  const tooltipProps =
    // When there is a gap between the offset strip and the element on which the tooltip is (for example with a
    // rhombus shape, where only the middle top tip touches the offset strip), if we do not add these listeners it
    // will be almost impossible to move the cursor over the tooltip
    isHoverable ? { onMouseEnter: open, onMouseOut } : undefined;

  return (
    <>
      {createPortal(
        'tooltip',
        <Transition
          in={isOpen}
          nodeRef={tooltipRef}
          timeout={{ exit: styleVariables.animationDuration }}
          unmountOnExit
          mountOnEnter
        >
          {state => (
            <div
              ref={tooltipRef}
              style={{ ...coords, '--offset': string.pixelize(padding) } as CSSProperties}
              className={classNames(
                appStyles.horizontalFlex,
                styles.tooltip,
                state,
                tooltipClassName,
                isHoverable && styles.hoverable
              )}
              {...tooltipProps}
            >
              {currentContent}
            </div>
          )}
        </Transition>
      )}
      {isSvg ? (
        <g {...(computedContainerProps as SVGProps)}>{children}</g>
      ) : (
        <span {...(computedContainerProps as SpanProps)}>{children}</span>
      )}
    </>
  );
}

export const withTooltip = <Page extends keyof typeof tooltipTexts>(
  page: Page,
  key: undefined | keyof (typeof tooltipTexts)[Page],
  component: ReactNode,
  verticalDirection?: VerticalDirection,
  horizontalDirection?: HorizontalDirection,
  padding?: number,
  customerName?: string
) => {
  if (!key) {
    return component;
  }

  const content = tooltipTexts[page][key] as unknown as TooltipContent;
  const computedContent = typeof content === 'string' ? content : content(customerName);

  return (
    <Tooltip
      key={`${page}.${key.toString()}`}
      content={computedContent}
      verticalDirection={verticalDirection}
      horizontalDirection={horizontalDirection}
      padding={padding}
    >
      {component}
    </Tooltip>
  );
};
