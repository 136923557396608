import React from 'react';
import classNames from 'classnames';
import appStyles from '../layout/App.module.scss';
import styles from './Capsule.module.scss';
import { useRecoilValue } from 'recoil';
import { analyticsState } from 'store/analytics';
import { Divider } from './Divider';

type Props<T> = {
  id: T;
  text: string;
  value?: number;
  isActive?: boolean;
  isInactive?: boolean;
  className?: string;
  colorId: number;
  onClick?: (id: T) => void;
};

export function Capsule<T>(props: Props<T>) {
  const { id, text, value, isActive, isInactive, className, colorId, onClick } = props;
  const analytics = useRecoilValue(analyticsState);
  const colorClass = `color${colorId}` as keyof typeof appStyles;

  const computedOnClick = (event: React.MouseEvent) => {
    if (onClick) {
      onClick(id);
      event.stopPropagation();
    }

    analytics.track('Tag clicked', { name: 'Tag', value: text, type: 'tagType' });
  };

  const computedClassName = classNames(
    styles.container,
    appStyles.overflowHidden,
    appStyles[colorClass],
    isActive && styles.active,
    isInactive && !isActive && styles.inactive,
    className,
    onClick && styles.clickable
  );

  return (
    <div className={computedClassName} onClick={computedOnClick}>
      <span className={classNames(styles.detail, styles.text)}>{text}</span>
      {value != null && (
        <>
          <Divider />
          <span className={classNames(styles.detail, styles.value)}>{value}</span>
        </>
      )}
    </div>
  );
}
