import React, { type ComponentProps } from 'react';
import styles from './AnimatedIcon.module.scss';
import classNames from 'classnames';
import { Icon } from './Icon';

type IconProps = ComponentProps<typeof Icon>;

type Props = {
  isFirstActivated: boolean;
  firstIcon: IconProps;
  secondIcon: IconProps;
};

export function AnimatedIcon(props: Props) {
  const { isFirstActivated, firstIcon, secondIcon } = props;

  return (
    <span className={styles.container}>
      <Icon {...firstIcon} className={classNames(styles.icon, !isFirstActivated && styles.hide)} />
      <Icon {...secondIcon} className={classNames(styles.icon, isFirstActivated && styles.hide)} />
    </span>
  );
}
