import type { ComponentProps } from 'react';
import React from 'react';
import type { RecoilState, RecoilValue, RecoilValueReadOnly } from 'recoil';
import { useRecoilState, useRecoilValue } from 'recoil';
import type { TreeOption } from 'utils/node';
import { Select } from '../general/select/Select';
import styles from './FilterSelect.module.scss';

type OptionValue = string | TreeOption;
type OptionKey = string | number;
type Options = Map<OptionKey, OptionValue>;

export type GroupableOptions = Options | Map<string, Options>;

type SelectProps = ComponentProps<typeof Select<number, number[]>>;

type Props = {
  title: string;
  pluralTitle: string;
  optionsRecoilState: RecoilValue<GroupableOptions>;
  valuesRecoilState: RecoilState<number[]>;
  defaultValueState?: RecoilValueReadOnly<number[]>;
} & Omit<SelectProps, 'value' | 'onChange' | 'options'>;

export function FilterSelect(props: Props) {
  const {
    title,
    pluralTitle,
    optionsRecoilState,
    valuesRecoilState,
    defaultValueState,
    ...selectProps
  } = props;
  const [values, setValues] = useRecoilState<number[]>(valuesRecoilState);
  const defaultValue = defaultValueState ? useRecoilValue(defaultValueState) : [];
  const options = useRecoilValue(optionsRecoilState);

  if (!options.size) {
    // Empty options should be skipped
    return null;
  }

  return (
    <Select
      className={styles.select}
      key={title}
      value={values}
      onChange={setValues}
      defaultValue={defaultValue}
      title={title}
      options={options as Iterable<number>}
      isMultiselect
      bordered
      resetButtonEnabled
      searchEnabled
      placeholder='All'
      notFoundText={`No matched ${pluralTitle.toLowerCase()} were found`}
      selectedOptionsTitle={`Selected ${pluralTitle}`}
      {...selectProps}
    />
  );
}
