import React from 'react';
import type { Workspace } from './NavWorkspace.utils';
import classNames from 'classnames';
import { AnimatedFontWeight } from 'components/general/AnimatedFontWeight';
import styles from './WorkspaceButton.module.scss';
import appStyles from '../../layout/App.module.scss';
import { WorkspaceSymbol } from './WorkspaceSymbol';

type Props = {
  workspace: Workspace;
  onClick: () => void;
};

export function WorkspaceButton(props: Props) {
  const {
    workspace: { colorClass, isActive, name, shortName, type },
    onClick,
  } = props;

  return (
    <button
      className={classNames(
        appStyles.horizontalFlex,
        appStyles.alignCenter,
        appStyles.unpaddedButton,
        colorClass,
        styles.button,
        isActive && styles.active
      )}
      onClick={onClick}
    >
      <WorkspaceSymbol type={type} shortName={shortName} />
      <AnimatedFontWeight altWeight={600} altActivated={isActive} className={styles.name}>
        {name}
      </AnimatedFontWeight>
    </button>
  );
}
