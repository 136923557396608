import React, {
  type ComponentProps,
  type MouseEventHandler,
  type ComponentType,
  type ReactElement,
  type MouseEvent,
  useState,
  useCallback,
} from 'react';
import { Transition } from 'react-transition-group';
import { styleVariables } from 'utils/styleVariables';
import { useBooleanState } from 'hooks/useBooleanState';
import { DisplayedContextMenu, type ContentComponentProps } from './DisplayedContextMenu';

type ContextMenuProps<T = never> = {
  children: (contextMenuHandler: MouseEventHandler) => ReactElement;
  ContentComponent: T;
  isDisabled?: boolean;
};

type DisplayedContextMenuPropKeys = keyof ComponentProps<typeof DisplayedContextMenu>;

type Props<T> = T &
  ContextMenuProps<
    ComponentType<
      Omit<Omit<T, keyof ContextMenuProps>, DisplayedContextMenuPropKeys> & ContentComponentProps
    >
  >;

export function ContextMenu<T>(props: Props<T>) {
  const [isOpen, open, close] = useBooleanState(false);
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const { children, ContentComponent, isDisabled, ...contentComponentProps } = props;
  const contextMenuHandler = useCallback((event: MouseEvent) => {
    event.preventDefault();
    open();
    setPosition({ left: event.clientX, top: event.clientY });
  }, []);

  return (
    <>
      {children(contextMenuHandler)}
      <Transition
        in={isOpen && !isDisabled}
        timeout={{ exit: styleVariables.animationDuration }}
        unmountOnExit
        mountOnEnter
      >
        {state => (
          <DisplayedContextMenu
            ContentComponent={ContentComponent}
            position={position}
            close={close}
            isOpen={isOpen}
            state={state}
            {...contentComponentProps}
          />
        )}
      </Transition>
    </>
  );
}
