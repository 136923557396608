import type { User } from '@auth0/auth0-spa-js';
import { DefaultValue, atom } from 'recoil';
import { storageAtom } from 'utils/storageAtom';
import type { RecursivePartial, Role } from '@harmonya/utils';
import type { ClientSettings } from '../interfaces';

export type CustomerData = {
  displayName: string;
  settingsOverrides?: RecursivePartial<ClientSettings>;
};

export interface Auth {
  user: User & { email: string; isAdmin: boolean };
  customers: Map<string, CustomerData>;
  roles: Role[];
}

export const authState = atom<Auth>({ key: 'auth' });

export const auth = {
  logout: () => null,
  getAccessTokenSilently: () => Promise.resolve(''),
  user: {
    sub: 'auth0|6280b70b0738e60068bc92e1',
    name: 'John Doe',
    nickname: 'Johnny',
    email: 'john@doe.com',
    email_verified: true,
    picture: 'user-icon.png', // 'https://picsum.photos/200',
    updated_at: '2022-05-17T15:10:12.513Z',
    isAdmin: true,
  },
};

export const customerIdState = storageAtom<string>({
  key: 'customerId',
  default: '',
  effects: [
    ({ onSet }) => {
      onSet((newValue, oldValue) => {
        const isFirstTime = oldValue instanceof DefaultValue;

        // If the localStorage does not contain the customerId, it will be assigned. In that case, we don't need to
        // refresh the page
        if (!isFirstTime) {
          // In principle, a change of `customerId` should trigger all the Recoil selector fetches again. The problem
          // is that Recoil does not manage the dependency tree in a tight manner, so some responses return before
          // others, so data from different tenants live on the client side at the same moment. Therefore, refreshing
          // the page is necessary to bypass this problem
          location.assign(location.origin);
        }
      });
    },
  ],
});
