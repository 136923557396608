import type { SortDirection } from '@harmonya/utils';
import { iterator, map } from '@harmonya/utils';
import React, { memo, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { ExplorePageTags } from './ExplorePageTags';
import appStyles from '../../layout/App.module.scss';
import styles from './ExplorePageTypesAndTags.module.scss';
import { aggregateTagsByExploreState, tagHashesState, tagsState } from '../../../store/tags';
import type { TagType } from '../../../models/TagType';
import type { AggregateTag, TagValue } from '../../../models/AggregateTag';
import { useRecoilValueLoadableState } from '../../../hooks/useRecoilValueLoadable';
import { TagTypesFilter } from '../../general/TagTypesFilter';
import { useTagTypeActiveIds } from 'hooks/useTagTypeActiveIds';
import { tagTypesState } from 'store/tagTypes';
import { Loader } from 'components/general/Loader';
import { useSearchParam } from 'hooks/useSearchParam';
import type { RecoilState } from 'recoil';
import { useRecoilValue } from 'recoil';
import {
  explorePageBrandingIdsState,
  explorePageGrowthPresetState,
  explorePageQueryState,
  explorePageSourcePresetState,
} from 'store/explorePage';
import type { PresetConfig } from './ExplorePageTags.utils';
import { usePresets, getExplorePageSliderFiltersConfig } from './ExplorePageTags.utils';
import { ExplorePageTagFilter } from './ExplorePageTagFilter';
import type { RangeValue } from 'components/general/Slider';
import { useSliderFilters } from 'hooks/useSliderFilters';
import { getTagValueGetter } from 'hooks/useTagValueGetter';
import { productsState } from 'store/products';
import { settingsState } from 'store/settings';
import { growthPointState } from 'store/growthPoint';
import { useResettableRecoilValue } from 'hooks/useResettableRecoil';
import { getIsPriortizedTag, getTokenValueSet, isTokenNewToQuery } from 'utils/prioritizedTags';
import { queryToTokens } from '../../../functions';
import type { GrowthPresetName, PresetName, SourcePresetName } from '@harmonya/models';

export const ExplorePageTypesAndTags = memo(function ExplorePageTypesAndTags() {
  const aggregateTags = useRecoilValueLoadableState<Map<AggregateTag['id'], AggregateTag>>(
    aggregateTagsByExploreState
  );
  const allTags = useRecoilValue(tagsState);
  const tagHashes = useRecoilValue(tagHashesState);
  const allTagTypes = useRecoilValue<Map<number, TagType>>(tagTypesState);
  const query = useRecoilValue(explorePageQueryState);
  const [activeTagTypeIds, setActiveTagTypeIds] = useTagTypeActiveIds(allTagTypes);
  const settings = useRecoilValue(settingsState);
  const [, setSortDirection] = useSearchParam<SortDirection>('diamondChartSort', {
    defaultValue: settings.defaults.explorePage.searchParams.diamondChartSort,
  });
  const [, setTagValueProp] = useSearchParam<TagValue>('tagValueProp', {
    defaultValue: settings.defaults.explorePage.searchParams.tagValueProp,
  });
  const queryTokens = queryToTokens(query);
  const selectedOperandNamesAsSet = getTokenValueSet(queryTokens, token =>
    tagHashes.get(Number(token.id))
  );
  const isPriortizedTag = getIsPriortizedTag(
    allTags?.values() ?? [],
    allTagTypes,
    activeTagTypeIds,
    settings.tagTypePriority
  );
  const tags =
    allTags && aggregateTags
      ? new Map(
          iterator.filter(aggregateTags, ([, aggregateTag]) => {
            const hash = map.safeGet(tagHashes, aggregateTag?.id);
            return isTokenNewToQuery(hash, selectedOperandNamesAsSet as Set<string>);
          })
        )
      : aggregateTags;
  const explorePageBrandingIds = useRecoilValue(explorePageBrandingIdsState);
  const brandSignificanceDisabled = !explorePageBrandingIds.length;
  const growthPoint = useRecoilValue(growthPointState);
  const products = useRecoilValueLoadableState(productsState);
  const [growthPreset, resetGrowthPreset] = useResettableRecoilValue(explorePageGrowthPresetState);
  const [sourcePreset, resetSourcePreset] = useResettableRecoilValue(explorePageSourcePresetState);

  const resetPresets = () => {
    resetGrowthPreset();
    resetSourcePreset();
  };

  const presets = usePresets();
  const tagsAsArray = [...(tags?.values() || [])];
  const sliderFiltersConfig = getExplorePageSliderFiltersConfig(brandSignificanceDisabled);
  const rawSliderFilters = useSliderFilters(tagsAsArray, getTagValueGetter, sliderFiltersConfig);

  const getFilterAndSortValuesByPresets = (
    activeGrowthPreset?: GrowthPresetName,
    activeSourcePreset?: SourcePresetName
  ) => {
    const sliderFiltersValues = new Map<
      string,
      ReturnType<PresetConfig['getValueBySliderTitle']>
    >();
    let newSortDirection: PresetConfig['sortDirection'] | undefined;
    let newSortBy: PresetConfig['sortBy'] | undefined;

    const setPresetValues = (activePreset?: PresetName) => {
      if (!activePreset) {
        return;
      }

      const presetConfig = map.safeGet(presets, activePreset);

      newSortDirection = presetConfig.sortDirection;
      newSortBy = presetConfig.sortBy;
      rawSliderFilters.sliderFilters.forEach(({ title, minValue, maxValue }) => {
        const newSliderValue = presetConfig.getValueBySliderTitle(title, minValue, maxValue);

        if (newSliderValue) {
          sliderFiltersValues.set(title, newSliderValue);
        }
      });
    };

    setPresetValues(activeSourcePreset);

    setPresetValues(activeGrowthPreset);

    return { sliderFiltersValues, newSortDirection, newSortBy };
  };

  const applyFilterAndSortValuesByPresets = (
    activeGrowthPreset?: GrowthPresetName,
    activeSourcePreset?: SourcePresetName
  ) => {
    const { sliderFiltersValues, newSortDirection, newSortBy } = getFilterAndSortValuesByPresets(
      activeGrowthPreset,
      activeSourcePreset
    );

    if (newSortDirection) {
      setSortDirection(newSortDirection);
    }

    if (newSortBy) {
      setTagValueProp(newSortBy);
    }

    rawSliderFilters.sliderFilters.forEach(slider => {
      const value = sliderFiltersValues.get(slider.title);

      slider.handleChange(value);
    });
  };

  const isGrowthPreset = <T extends PresetName>(
    selectedNameRecoilState: RecoilState<T | undefined>,
    presetName?: PresetName
  ): presetName is GrowthPresetName => selectedNameRecoilState === explorePageGrowthPresetState;

  const activatePreset = <T extends PresetName>(
    selectedNameRecoilState: RecoilState<T | undefined>,
    presetName: T,
    isSelected: boolean
  ) => {
    const newPreset = isSelected ? undefined : presetName;
    const [activeGrowthPreset, activeSourcePreset] = isGrowthPreset(
      selectedNameRecoilState,
      newPreset
    )
      ? [newPreset, sourcePreset]
      : [growthPreset, newPreset];

    applyFilterAndSortValuesByPresets(activeGrowthPreset, activeSourcePreset);
  };

  useEffect(() => {
    applyFilterAndSortValuesByPresets(growthPreset, sourcePreset);
  }, [products?.metadata, growthPoint]);

  const resetMetricFilters = () => {
    rawSliderFilters.reset();
    resetPresets();
  };

  const shouldPresetReset = (
    presetName: PresetName | undefined,
    sliderTitle: string,
    minValue: number,
    maxValue: number
  ) => {
    if (!presetName) {
      return false;
    }
    const presetConfig = map.safeGet(presets, presetName);
    const value = presetConfig.getValueBySliderTitle(sliderTitle, minValue, maxValue);

    return !!value;
  };

  const sliderFilters = rawSliderFilters.sliderFilters.map(slider => ({
    ...slider,
    handleChange: (value?: RangeValue<number>) => {
      slider.handleChange(value);

      if (shouldPresetReset(sourcePreset, slider.title, slider.minValue, slider.maxValue)) {
        resetSourcePreset();
      }
      if (shouldPresetReset(growthPreset, slider.title, slider.minValue, slider.maxValue)) {
        resetGrowthPreset();
      }
    },
    reset: () => {
      slider.reset();
      resetPresets();
    },
  }));
  const filters = iterator.definedMap(sliderFilters, item => item.isFilteredItem);
  const filteredBySlidersTags = tagsAsArray.filter(tag =>
    filters.every(sliderFilter => sliderFilter(tag))
  );
  const filteredByTagTypeAndSlidersTags = useMemo(() => {
    const filteredByTagType = iterator.filter(filteredBySlidersTags, tag => {
      const isFilteredByTagType =
        !activeTagTypeIds.size ||
        iterator.some(activeTagTypeIds, tagTypeId => tag.types.has(tagTypeId));
      const hash = tagHashes.get(tag.id);
      const isPriortized = !!hash && isPriortizedTag(tag, hash);

      return isFilteredByTagType && isPriortized;
    });

    return filteredByTagType;
  }, [activeTagTypeIds, filteredBySlidersTags]);
  const containerClassName = classNames(
    appStyles.unpaddedBox,
    appStyles.verticalFlex,
    appStyles.flexGrow1,
    appStyles.positionRelative,
    appStyles.fullBasis,
    appStyles.gap2,
    styles.container
  );
  const isLoading = !tags || !products;

  if (isLoading) {
    return (
      <div className={containerClassName}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={containerClassName}>
      <ExplorePageTagFilter
        sliderFilters={sliderFilters}
        reset={resetMetricFilters}
        activatePreset={activatePreset}
      />
      <TagTypesFilter
        tags={filteredBySlidersTags}
        containerClassName={classNames(
          appStyles.horizontalFlex,
          appStyles.wrap,
          styles.typesContainer
        )}
        onChange={tagTypeIds => setActiveTagTypeIds([...tagTypeIds])}
      />
      <ExplorePageTags
        resetMetricFilters={resetMetricFilters}
        sliderFilters={sliderFilters}
        activeTagTypeIds={activeTagTypeIds}
        tags={filteredByTagTypeAndSlidersTags}
        totalTagsCount={iterator.length(filteredBySlidersTags)}
      />
    </div>
  );
});
