import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import styles from './SelectOptions.module.scss';
import { SelectOption } from './SelectOption';
import { styleVariables } from 'utils/styleVariables';
import { useVirtualizer } from '@tanstack/react-virtual';
import appStyles from '../../layout/App.module.scss';
import selectOptionStyles from './SelectOption.module.scss';
import { SelectAllButton } from './SelectAllButton';
import { hasSelectedDescendant, hasSelectedAncestor } from 'utils/node';
import { useIsScrolling } from 'hooks/useIsScolling';
import { iterator, string } from '@harmonya/utils';
import type { Option, RawOptionType } from 'components/general/select/types';

/** @todo Duplicate keys when more than one divider */
export const dividerOption = { name: 'DIVIDER', value: -Infinity };

type Props<T extends Option<RawOptionType>> = {
  options: [string, T[]][];
  highlightedIndex: number | null;
  isMultiselect?: boolean;
  isSelected: (option: T) => boolean;
  isVisible?: (option: T) => boolean;
  onClick: (option: T) => void;
  onMultipleClick?: (option: T[]) => void;
  onMouseOver?: (option: T) => void;
  colorInverted?: boolean;
  notFoundText?: string;
  displayValueGetter: (option: T) => ReactNode;
  optionClassNameGetter?: (option: T) => string;
  parentRef?: React.MutableRefObject<HTMLDivElement>;
  className?: string;
  toggleChildren?: (option: T) => void;
  isCheckboxDisplayed?: boolean;
  isHierarchial?: boolean;
  arrowPosition?: 'left' | 'right';
};

export function SelectOptions<T extends Option<RawOptionType>>(props: Props<T>) {
  const {
    options,
    highlightedIndex,
    isMultiselect,
    isSelected,
    isVisible,
    onClick,
    onMultipleClick,
    onMouseOver,
    colorInverted,
    notFoundText = 'No matches found',
    displayValueGetter,
    optionClassNameGetter,
    parentRef = useRef(null),
    className,
    toggleChildren,
    isCheckboxDisplayed,
    isHierarchial,
    arrowPosition,
  } = props;
  const visibleOptions = isVisible
    ? options.map(([title, values]) => [title, values.filter(isVisible)] as const)
    : options;
  const flattedOptions = visibleOptions.flatMap(([title, values]) =>
    title
      ? [
          {
            title,
            allChildrenCount: values.length,
            activeChildrenCount: iterator.count(values, option => !option.disabled),
            allChildrenSelected: values.every(isSelected),
            handleClick:
              onMultipleClick && (() => onMultipleClick(values.filter(option => !option.disabled))),
          },
          ...values,
        ]
      : values
  );
  const rowVirtualizer = useVirtualizer({
    overscan: 20,
    getScrollElement: () => parentRef.current,
    count: flattedOptions.length,
    estimateSize: useCallback(
      index => {
        const option = flattedOptions[index];
        const isDivider = 'value' in option && option.value === dividerOption.value;

        return styleVariables.selectOptionHeight * (isDivider ? 0.6 : 1);
      },
      [flattedOptions]
    ),
  });
  const isScrolling = useIsScrolling(parentRef);

  useEffect(() => {
    if (highlightedIndex != null && flattedOptions.length) {
      // Without it, when the highlightedIndex is equal to 0 and there are group titles, the first group title
      // will never be displayed in keyboard navigation
      const hasTitle = 'title' in flattedOptions[0];
      const isFirstOption = highlightedIndex === 1 && hasTitle;
      const computedHighlightedIndex = isFirstOption ? 0 : highlightedIndex;

      rowVirtualizer.scrollToIndex(computedHighlightedIndex, { align: 'auto' });
    }
  }, [highlightedIndex]);

  const containerClassName = classNames(
    appStyles.overflowOverlay,
    styles.container,
    colorInverted && styles.colorInverted,
    className
  );

  return options.length ? (
    <div className={containerClassName} ref={parentRef}>
      <ul className={styles.innerContainer} style={{ height: rowVirtualizer.getTotalSize() }}>
        {rowVirtualizer.getVirtualItems().map(({ size, index, start }) => {
          const style = { height: size, transform: `translateY(${string.pixelize(start)})` };
          const option = flattedOptions[index];
          const isGroupTitleOption = 'title' in option;
          const isDivider = 'value' in option && option.value === dividerOption.value;

          if (isGroupTitleOption) {
            return (
              <li
                key={option.title}
                style={style}
                className={classNames(
                  appStyles.horizontalFlex,
                  appStyles.justifySpaceBetween,
                  appStyles.alignBaseline,
                  selectOptionStyles.option,
                  styles.groupTitle
                )}
              >
                <span>{option.title}</span>
                {isMultiselect && option.handleClick && (
                  <SelectAllButton
                    onClick={option.handleClick}
                    allChildrenSelected={option.allChildrenSelected}
                    allChildrenCount={option.allChildrenCount}
                    activeChildrenCount={option.activeChildrenCount}
                  />
                )}
              </li>
            );
          }

          if (isDivider) {
            return (
              <div style={style} key={String(option.value)} className={styles.horizontalDivider} />
            );
          }

          const [allDecendantsSelected, anyDescendantSelected] = hasSelectedDescendant(
            isSelected,
            option
          );
          const optionSelected = allDecendantsSelected || hasSelectedAncestor(isSelected, option);
          // as we display all or no children, we can check only the first one
          const childrenDisplayed =
            !!option.node?.children?.length && isVisible?.(option.node?.children[0] as T);
          const treeOptions =
            !toggleChildren || childrenDisplayed == null
              ? {}
              : { onArrowClick: toggleChildren, childrenDisplayed };

          /** @todo Add animation to open children by arrow */
          return (
            <SelectOption
              onMouseOver={item => !isScrolling && onMouseOver?.(item)}
              style={style}
              toggleEnabled={isMultiselect}
              key={String(option.value)}
              option={option}
              isHighlighted={index === highlightedIndex}
              displayValueGetter={displayValueGetter}
              colorInverted={colorInverted}
              isSelected={optionSelected}
              hasSelectedDescendant={!optionSelected && anyDescendantSelected}
              onClick={onClick}
              tooltip={option.tooltip}
              optionClassNameGetter={optionClassNameGetter}
              isCheckboxDisplayed={
                isCheckboxDisplayed || (isCheckboxDisplayed === undefined && isHierarchial)
              }
              arrowPosition={arrowPosition}
              {...treeOptions}
            />
          );
        })}
      </ul>
    </div>
  ) : (
    <h6 className={styles.placeholder}>{notFoundText}</h6>
  );
}
