import React, { type ReactElement } from 'react';
import classNames from 'classnames';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ComparisonPage } from './comparisonPage/ComparisonPage';
import { ExplorePage } from './explorePage/ExplorePage';
import appStyles from './App.module.scss';
import { authState } from 'store/auth';
import { useRecoilValue } from 'recoil';
import type { PathName } from 'utils/pageName';
import { getDefaultRoute, getEnabledPathNames } from 'utils/pageName';
import { AttributionPage } from './attributionPage/AttributionPage';
import { appsState } from 'store/apps';
import type { Role } from '@harmonya/utils';
import { AnimatedLayout } from './AnimatedLayout';

export function Main() {
  const location = useLocation();
  const { roles } = useRecoilValue(authState);
  const enabledApps = useRecoilValue(appsState);
  const enabledPathNames = getEnabledPathNames(enabledApps);
  const getByRole = (routeComponent: ReactElement, role: Role) =>
    roles.includes(role) ? routeComponent : <Navigate to='/' />;
  const defaultRoute = getDefaultRoute(enabledPathNames);

  const routeElements: Record<PathName, ReactElement> = {
    explore: <ExplorePage />,
    attribution: <AttributionPage />,
    comparison: getByRole(<ComparisonPage />, 'Comparison Page'),
    trends: <Navigate to='/' />,
  };

  return (
    <main
      className={classNames(
        appStyles.positionRelative,
        appStyles.flexGrow1,
        appStyles.overflowHidden
      )}
    >
      <Routes location={location}>
        <Route element={<AnimatedLayout />}>
          {enabledPathNames.map(pathName => (
            <Route path={pathName} key={pathName} element={routeElements[pathName]} />
          ))}
          <Route path='*' element={<Navigate to={defaultRoute} replace />} />
        </Route>
      </Routes>
    </main>
  );
}
