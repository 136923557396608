import React, { memo } from 'react';
import styles from './Shortcut.module.scss';
import { iterator } from '@harmonya/utils';

type Translations = Record<string, string>;

const isMac = navigator.userAgent.includes('Mac OS X');
const combinationKey = '+';
const translatedCombinationKey = isMac ? '' : '+';
const globalTranslations: Translations = {
  arrowRight: '→',
  arrowLeft: '←',
  arrowUp: '↑',
  arrowDown: '↓',
};
const macTranslations: Translations = {
  ctrl: '⌃',
  shift: '⇧',
  alt: '⌥',
  meta: '⌘',
};
const defaultTranslations: Translations = {
  ctrl: 'Ctrl',
  shift: 'Shift',
  alt: 'Alt',
  meta: 'Meta',
};

const translateKeyboard = (code: string) => {
  const osTranslations = isMac ? macTranslations : defaultTranslations;
  const translatedKeyboard = osTranslations[code] ?? globalTranslations[code] ?? code.toUpperCase();

  return translatedKeyboard;
};

type Props = {
  children: string;
};

export const Shortcut = memo(function Shortcut(props: Props) {
  const { children } = props;
  const elements = children
    .split(combinationKey)
    .map((code, i) => <span key={i}>{translateKeyboard(code)}</span>);

  const combinedElements = translatedCombinationKey
    ? iterator.join(elements, i => <span key={i}>{translatedCombinationKey}</span>)
    : elements;

  return <span className={styles.container}>{combinedElements}</span>;
});
