import React from 'react';
import type { WorkspaceType } from './NavWorkspace.utils';
import { Icon } from 'components/general/Icon';
import styles from './WorkspaceSymbol.module.scss';

type Props = {
  type: WorkspaceType;
  shortName?: string;
};

export function WorkspaceSymbol(props: Props) {
  const { type, shortName } = props;

  switch (type) {
    case 'insight':
      return <div className={styles.symbol}>{shortName}</div>;
    case 'attribution':
      return (
        <div className={styles.appIcon}>
          <Icon name='book-open' weight='light' />
        </div>
      );
  }
}
