import { sortedFlattenTreeCategoriesState } from 'store/categories';
import type { FilterSelects } from '../components/layout/FilterSelects';
import { marketsState } from 'store/markets';
import { periodsState, rawPeriodsState } from 'store/periods';
import { sortedFlattenTreeBrandingState } from 'store/branding';
import { useIsBrandingOptionDisabled } from './useIsBrandingOptionDisabled';
import type { RecoilState } from 'recoil';
import { useRecoilValue } from 'recoil';
import type { Level } from 'components/general/select/types';
import type { ComponentProps } from 'react';
import { createElement } from 'react';
import { SelectOptionWithDescription } from 'components/general/select/SelectOptionWithDescription';
import { formatDate } from '@harmonya/utils';
import { brandingFilterTitle } from './useConfigurationExportContent';

type IdsState = RecoilState<number[]>;

export const useScopeFilters = (
  categoryIdsState: IdsState,
  marketIdsState: IdsState,
  periodIdsState: IdsState,
  brandinsIdsState?: IdsState,
  displayedBrandingsLevels?: Set<Level>
) => {
  const periods = useRecoilValue(rawPeriodsState);
  const scopeFiltersSelects: ComponentProps<typeof FilterSelects>['selects'] = [
    {
      title: 'Category',
      pluralTitle: 'Categories',
      optionsRecoilState: sortedFlattenTreeCategoriesState,
      valuesRecoilState: categoryIdsState,
    },
    {
      title: 'Market',
      pluralTitle: 'Markets',
      optionsRecoilState: marketsState,
      valuesRecoilState: marketIdsState,
    },
    {
      title: 'Time',
      pluralTitle: 'Times',
      optionsRecoilState: periodsState,
      valuesRecoilState: periodIdsState,
      displayValueGetter: periodOption => {
        const period = periods.get(periodOption.value);
        const result = period
          ? createElement(SelectOptionWithDescription, {
              name: period.name,
              desription: `${period.weeksCount} Weeks | ${formatDate(period.firstWeek)} - ${formatDate(period.lastWeek)}`,
            })
          : '';
        return result;
      },
      selectedOptionDisplayValueGetter: option => option.displayValue,
      displaySelectedValueGetter: option => option.displayValue,
    },
  ];

  if (brandinsIdsState) {
    const isBrandingOptionDisabled = useIsBrandingOptionDisabled(
      categoryIdsState,
      displayedBrandingsLevels
    );

    scopeFiltersSelects.push({
      title: brandingFilterTitle,
      pluralTitle: 'Manufacturers or Brands',
      optionsRecoilState: sortedFlattenTreeBrandingState,
      valuesRecoilState: brandinsIdsState,
      isDisabledOption: isBrandingOptionDisabled,
    });
  }

  return scopeFiltersSelects;
};
