import React, { useMemo } from 'react';
import './NavWorkspace.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { env } from '../../../../env';
import { NavWindow } from './NavWindow';
import { useRecoilValue } from 'recoil';
import { customerIdState, authState, auth as authMock } from 'store/auth';
import { isLoading, useRecoilStateLoadableState } from 'hooks/useRecoilValueLoadable';
import { getWorkspaceById, buildWorkspaces } from './NavWorkspace.utils';
import { WorkspaceList } from './WorkspaceList';
import { WorkspaceSwitchItem } from './WorkspaceSwitchItem';

type Props = {
  buttonClassName?: string;
};

export function NavWorkspace(props: Props) {
  const { buttonClassName } = props;
  const { user } = env.USE_AUTH ? useAuth0() : authMock;
  const [customerId, setCustomerId] = useRecoilStateLoadableState(customerIdState);
  const { customers } = useRecoilValue(authState);

  const workspaces = useMemo(() => buildWorkspaces(customers, customerId), [customers, customerId]);

  if (isLoading(user) || isLoading(customerId)) {
    return null;
  }

  const activeWorkspace = getWorkspaceById(workspaces, customerId);

  if (!activeWorkspace) {
    throw new Error(`${customerId} is invalid active customer ID`);
  }

  return (
    <NavWindow content={<WorkspaceList workspaces={workspaces} setCustomerId={setCustomerId} />}>
      <WorkspaceSwitchItem activeWorkspace={activeWorkspace} buttonClassName={buttonClassName} />
    </NavWindow>
  );
}
