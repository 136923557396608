import React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import styles from './NavLinks.module.scss';
import navStyles from './Nav.module.scss';
import appStyles from '../../layout/App.module.scss';
import type { IconName } from '../../general/Icon';
import { Icon } from '../../general/Icon';
import type { tooltipTexts } from '../../../tooltipTexts';
import { withTooltip } from '../../general/Tooltip';
import { styleVariables } from '../../../utils/styleVariables';
import { authState } from 'store/auth';
import { useRecoilValue } from 'recoil';
import { type Role, iterator } from '@harmonya/utils';

export type Link = {
  to: string;
  iconName: IconName;
  tooltipTextKey?: keyof typeof tooltipTexts.navbar;
  role?: Role;
};

type Props = {
  links: Link[];
};

export function NavLinks(props: Props) {
  const { roles } = useRecoilValue(authState);

  return (
    <div className={classnames(styles.links, appStyles.verticalFlex, appStyles.gap6)}>
      {iterator.definedMap(props.links, ({ to, iconName, tooltipTextKey, role }) => {
        if (!role || roles.includes(role)) {
          const iconComponent = <Icon name={iconName} />;
          const tooltipPadding = styleVariables.padding + styleVariables.gap;

          return (
            <NavLink to={to} className={classnames(navStyles.link, styles.link)} key={iconName}>
              {withTooltip(
                'navbar',
                tooltipTextKey,
                iconComponent,
                'center',
                'right',
                tooltipPadding
              )}
            </NavLink>
          );
        }
      })}
      <div className={styles.activeSign} />
    </div>
  );
}
