/* eslint-disable id-length */ // using R, x, y as variable names of variables as it similar to the mathematical equation notation
export type Circle = {
  radius: number;
  center: {
    x: number;
    y: number;
  };
};

export const rectToCircle = (rect: Omit<DOMRect, 'toJSON'>): Circle => ({
  radius: rect.width / 2,
  center: {
    x: rect.left + rect.width / 2,
    y: rect.top + rect.height / 2,
  },
});

export const circleYIntersections = (y: number, circle: Circle) => {
  const {
    radius: R,
    center: { x: cx, y: cy },
  } = circle;
  // From circle eq. (y - cy) ** 2 + (x - cx) ** 2 = r ** 2
  // (x - cx) ** 2 = r**2 - (y - cy) ** 2
  const xDiffSquare = R ** 2 - (y - cy) ** 2;

  // x = cx +- SQRT ( xDiffSquare )
  if (xDiffSquare > 0) {
    const sqrt = Math.sqrt(xDiffSquare);

    return [cx - sqrt, cx + sqrt];
  }
};
