import React from 'react';
import styles from './IconsBox.module.scss';
import classnames from 'classnames';
import type { IconName } from './Icon';
import { Icon } from './Icon';

type Props<T extends IconName> = {
  iconNames: readonly T[];
  activeIconName?: T;
};

export function IconsBox<T extends IconName>(props: Props<T>) {
  const { iconNames, activeIconName } = props;

  return (
    <div
      className={classnames(styles.container, activeIconName != null && styles.hasActiveIconName)}
    >
      {iconNames.map(icon => (
        <Icon
          key={icon}
          className={classnames(activeIconName !== icon && styles.hide, styles.icon)}
          name={icon}
          weight='solid'
        />
      ))}
    </div>
  );
}
