import { useClipboard } from 'hooks/useClipboard';
import { useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import type { ProductAttributeContent } from 'store/attribution.types';
import { shortcuts } from 'utils/shortcuts';

export const useCopyPaste = (
  value: ProductAttributeContent['value'],
  setCellValue: (value: string) => void
) => {
  const clipboard = useClipboard();
  const paste = useCallback(() => clipboard.paste(setCellValue), [setCellValue]);
  const copy = useCallback(async () => {
    if (value != '' && value != null) {
      await clipboard.copy(value);
    }
  }, [value]);
  const hotkeyOptions = { scopes: '*', preventDefault: true };

  useHotkeys(shortcuts.paste, paste, hotkeyOptions, []);
  useHotkeys(shortcuts.copy, copy, hotkeyOptions, [value]);

  return { copy, paste };
};
