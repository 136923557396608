import type { AtomEffect, AtomOptions, RecoilState } from 'recoil';
import { atom, DefaultValue } from 'recoil';
import { history } from './history';
import type { Options as SearchParamOptions, Parser } from '../hooks/useSearchParam';
import {
  parseValue,
  applyIfHasChange,
  getNewSearchParams,
  getEnsuredSearchParam,
} from '../hooks/useSearchParam';
import type { PathName } from './pageName';
import { getCurrentPageName } from './pageName';

const getSearchParams = <T>(key: string, isArray: boolean, options: SearchParamOptions<T> = {}) => {
  const { defaultValue } = options;
  const ensuredSearchParamsValue = getEnsuredSearchParam(
    key,
    location.search,
    defaultValue,
    isArray
  );
  let parsedParam: T | (T | undefined)[] | undefined;

  if (Array.isArray(options.parser)) {
    if (Array.isArray(ensuredSearchParamsValue)) {
      const indexedParsers = options.parser;

      parsedParam = ensuredSearchParamsValue.map((item, i) => {
        const indexedParser = indexedParsers.find(([index]) => index === i);

        return parseValue(item, indexedParser?.[1], options.validator);
      });
    }
  } else {
    const parse = (curValue: typeof ensuredSearchParamsValue) =>
      parseValue(curValue, options.parser as Parser<T>, options.validator);

    parsedParam = Array.isArray(ensuredSearchParamsValue)
      ? ensuredSearchParamsValue.map(parse)
      : parse(ensuredSearchParamsValue);
  }

  return parsedParam;
};

export const historyAtom = <T>(
  key: string,
  scopePathName: PathName,
  options: AtomOptions<T>,
  searchOptions?: SearchParamOptions<T>
): RecoilState<T> => {
  const defaultValue = 'default' in options ? options.default : undefined;
  const isArray =
    searchOptions?.isArray || Array.isArray(searchOptions?.defaultValue ?? defaultValue);
  const isInScopePathName = () => getCurrentPageName() === scopePathName;

  const historyEffect: AtomEffect<T> = ({ setSelf, onSet }) => {
    const searchParam = getSearchParams(key, isArray, searchOptions);
    let latestValue = searchParam;

    if (isInScopePathName() && searchParam != null) {
      setSelf(searchParam as T);
    }

    onSet((newValue, oldValue, isReset) => {
      if (isInScopePathName()) {
        latestValue = newValue;

        const searchParams = getNewSearchParams(key, newValue, defaultValue, isReset);

        history.push({ search: searchParams.toString() });
      }
    });

    return history.listen(() => {
      if (isInScopePathName()) {
        const newSearchParam = getSearchParams(key, isArray, searchOptions);

        applyIfHasChange(newSearchParam, latestValue, () => {
          // Without this condition, loaders will appear whenever the URL changes, even if there is nothing to load
          setSelf((newSearchParam as T) ?? new DefaultValue());
        });
      }
    });
  };

  const newOptions = {
    ...options,
    effects: [...(options.effects ?? []), historyEffect],
  };

  return atom(newOptions);
};
