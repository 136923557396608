import type {
  ChangeEventHandler,
  ChangeEvent,
  DetailedHTMLProps,
  InputHTMLAttributes,
} from 'react';
import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import appStyles from '../layout/App.module.scss';
import styles from './Input.module.scss';
import type { IconName } from './Icon';
import { Icon } from './Icon';
import { Tooltip } from './Tooltip';
import { AutoExpandableInput, setValue } from './AutoExpandableInput';
import { useIsFocused } from '../../hooks/useIsFocused';
import { debouncer } from 'utils/debouncer';
import type { Stringable } from '@harmonya/utils';

type Props = {
  debounce?: number;
  onInput: (event: ChangeEvent<HTMLInputElement>) => void;
  value: unknown;
  formattedValue?: Stringable;
  sign?: string;
  iconName?: IconName;
  containerClassName?: string;
  error?: string;
  autoExpanded?: boolean;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export function Input(props: Props) {
  const {
    debounce,
    onInput,
    value,
    formattedValue,
    sign,
    iconName,
    containerClassName,
    error,
    autoExpanded,
    className,
    ...inputProps
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const isFocused = useIsFocused(inputRef);
  const formattedValueDisplayed = !isFocused && formattedValue != null;
  const debouncedHandleInput = debouncer(
    (currentTarget: HTMLInputElement, event: ChangeEvent<HTMLInputElement>) => {
      // When event's use is defferd, the currentEvent is deleted
      event.currentTarget = currentTarget;
      onInput(event);
    },
    debounce
  );
  const computedOnInput: ChangeEventHandler<HTMLInputElement> = debounce
    ? event => debouncedHandleInput(event.currentTarget, event)
    : onInput;

  useEffect(() => {
    // Not yet checked on regular input (and not auto-expanded)
    setValue(value, formattedValue, true, inputRef, formattedValueDisplayed, inputProps.type);
  }, [value, isFocused]);

  const containerProps = {
    className: classNames(
      appStyles.horizontalFlex,
      appStyles.gap0,
      appStyles.buttonBorder,
      styles.container,
      inputProps.disabled && styles.disabled,
      error && styles.invalid,
      containerClassName
    ),
    onClick: () => inputRef.current?.focus(),
  };
  const computedInputProps = {
    onInput: computedOnInput,
    defaultValue: value,
    // We use title='' as a hack because formNoValidate does not work
    title: '',
    className: classNames(appStyles.flexGrow1, styles.input, className),
    ...inputProps,
  };
  const content = (
    <>
      {!!iconName && <Icon name={iconName} className={appStyles.padded1} />}
      {autoExpanded ? (
        <AutoExpandableInput
          {...computedInputProps}
          uncontrolled
          value={value}
          formattedValue={formattedValue}
          ref={inputRef}
        />
      ) : (
        <input {...computedInputProps} ref={inputRef} />
      )}
      {!!sign && <span className={styles.sign}>{sign}</span>}
    </>
  );
  const result =
    error && !isFocused ? (
      <Tooltip {...containerProps} content={error}>
        {content}
      </Tooltip>
    ) : (
      <div {...containerProps}>{content}</div>
    );

  return result;
}
