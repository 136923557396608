import React, { useCallback, useMemo, useState } from 'react';
import { SelectDropdown } from './select/SelectDropdown';
import type { ExportOption, OptionsHook } from './ExportButton';
import type { Option } from 'components/general/select/types';
import { iterator } from '@harmonya/utils';
import { Divider } from './Divider';
import { Icon } from './Icon';
import styles from './ExportButton.module.scss';
import { withTooltip } from './Tooltip';
import classNames from 'classnames';
import appStyles from '../layout/App.module.scss';
import { useCsvExport } from 'hooks/useCsvExport';

type ExportSelectOption = Option<string> & {
  name: string;
};

type Props = {
  useOptions: OptionsHook;
  pageKey: 'explore' | 'comparison';
};

export function ExportButtonDropdown(props: Props) {
  const { useOptions, pageKey } = props;
  const {
    options,
    isAllDisabled = false,
    disabledTooltip = 'exportNoResultsWarning',
  } = useOptions();
  const [selectedFileKeys, setSelectedFileKeys] = useState(new Set(options.map(i => i.key)));

  const filteredSelectedFileKeys = useMemo(
    () =>
      new Set(
        iterator.definedMap([...options, ...options.flatMap(option => option.children)], option => {
          if (option) {
            const { key, disabled } = option;
            return selectedFileKeys.has(key) && !disabled ? key : undefined;
          }
        })
      ),
    [options, selectedFileKeys]
  );

  const handleChange = useCallback((value: string[]) => {
    setSelectedFileKeys(new Set(value));
  }, []);

  const mapExportOptionToFile = ({ key, fileContent }: ExportOption) => ({
    name: key,
    data: fileContent || [],
  });

  const generateCsvFiles = () =>
    iterator
      .definedMap([...options, ...options.flatMap(option => option.children)], option => {
        if (option) {
          const { key, children } = option;
          if (filteredSelectedFileKeys.has(key)) {
            return children?.length
              ? children.map(child => mapExportOptionToFile(child))
              : [mapExportOptionToFile(option)];
          }
        }
      })
      .flatMap(file => file);

  const handleExport = useCsvExport(pageKey, generateCsvFiles);

  const mapExportOptionsToSelectOptions = (
    exportOptions = options,
    level = 1,
    parent?: ExportSelectOption
  ): ExportSelectOption[] => {
    return exportOptions.flatMap(exportOption => {
      const option: ExportSelectOption = {
        value: exportOption.key,
        name: exportOption.title,
        disabled: isAllDisabled || exportOption.disabled,
      };

      let children: ExportSelectOption[] = [];
      // node in options signifies computed options are hierarchial, so only then it should be defined
      if (parent || exportOption.children) {
        children = exportOption.children?.length
          ? mapExportOptionsToSelectOptions(exportOption.children, level + 1, option)
          : [];
        option.node = {
          level,
          children,
          parent,
        };
      }

      return [option, ...children];
    });
  };

  const computedOptions = useMemo(
    () => mapExportOptionsToSelectOptions(),
    [options, isAllDisabled]
  );

  const displayValueGetter = (option: ExportSelectOption) => (
    <div className={styles.option}>
      {option.name}
      <Divider />
      <h6>CSV</h6>
      {option.node?.children && option.node.children.length > 0 && (
        <span className={styles.fileCount}>
          {option.node?.children.length}
          <Icon name='file' weight='light' className={styles.icon} />
        </span>
      )}
    </div>
  );

  const footer = withTooltip(
    'common',
    isAllDisabled ? disabledTooltip : undefined,
    <button
      className={classNames(
        appStyles.horizontalFlex,
        appStyles.unpaddedButtonPrimary,
        styles.downloadButton
      )}
      onClick={handleExport}
      disabled={isAllDisabled || !filteredSelectedFileKeys.size}
    >
      Download
    </button>
  );

  return (
    <SelectDropdown
      onChange={handleChange}
      options={computedOptions}
      value={[...filteredSelectedFileKeys]}
      isMultiselect
      displayValueGetter={displayValueGetter}
      isSelected={option => filteredSelectedFileKeys.has(option.value)}
      colorInverted
      footer={footer}
      isCheckboxDisplayed
      arrowPosition='right'
    />
  );
}
