import React, { type ReactNode } from 'react';
import appStyles from '../../../App.module.scss';
import styles from './AttributionAnalyticsPanelCardHeader.module.scss';
import { Icon } from 'components/general/Icon';
import { PercentageProgressBar } from '../../../../general/PercentageProgressBar';
import classNames from 'classnames';

type Props = {
  displayedTitle: ReactNode;
  accuracy: number;
  isExpanded: boolean;
  isSelected: boolean;
  onToggle: () => void;
  onPerformanceBoostingTagClick: () => void;
};

export function AttributionAnalyticsPanelCardHeader(props: Props) {
  const {
    displayedTitle,
    accuracy,
    isExpanded,
    isSelected,
    onToggle,
    onPerformanceBoostingTagClick,
  } = props;

  return (
    <div className={styles.headerContainer}>
      <h3 className={styles.title}>{displayedTitle}</h3>
      <PercentageProgressBar
        value={accuracy}
        className={styles.progressBarContainer}
        percentageClassName={styles.progressBarPercentage}
      />
      <button
        className={classNames(styles.performanceBoostingTagButton, isSelected && styles.selected)}
        onClick={onPerformanceBoostingTagClick}
      >
        <Icon name='crosshairs-simple' weight='light' />
      </button>
      <button
        className={classNames(
          appStyles.unpaddedButton,
          styles.expandCollapseButton,
          isExpanded && styles.expanded
        )}
        onClick={onToggle}
      >
        <Icon name='chevron-down' weight='regular' className={appStyles.mediumFont} />
      </button>
    </div>
  );
}
