const defaultToggleEvent = { title: 'Dropdown toggled', props: { name: '', type: 'dropdown' } };
const defaultSelectEvent = { title: 'Dropdown select', props: { name: '', type: 'dropdown' } };

type BaseEventProps = { title?: string; props?: { name?: string; type?: string } };

export type ToggleEventProps = BaseEventProps;

export type SelectEventProps = BaseEventProps & { props?: { value?: unknown } };

function getEventProps(
  baseEvent: ToggleEventProps | SelectEventProps,
  title?: string,
  props?: (ToggleEventProps | SelectEventProps)['props']
) {
  return {
    ...(props && { ...baseEvent.props, ...props }),
    title,
  };
}

export const getToggleEvent = (
  props?: ToggleEventProps['props'],
  title?: string
): ToggleEventProps => getEventProps(defaultToggleEvent, title, props);

export const getSelectEvent = (
  props?: SelectEventProps['props'],
  title?: string
): SelectEventProps => getEventProps(defaultSelectEvent, title, props);
