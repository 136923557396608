import React, { memo } from 'react';
import { StackedProgressBar } from './StackedProgressBar';
import { styleVariables } from 'utils/styleVariables';

type Props = {
  success: number;
  warning: number;
  error: number;
};

export const TrafficLightsStackedProgressBar = memo(function TrafficLightsStackedProgressBar(
  props: Props
) {
  const { success, warning, error } = props;
  const { successColor, warningColor, errorColor } = styleVariables;
  const values = [
    [success, successColor],
    [warning, warningColor],
    [error, errorColor],
  ] as const;

  return <StackedProgressBar values={values} />;
});
