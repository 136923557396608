import type { IconName } from 'components/general/Icon';
import type { TagValue } from 'models/AggregateTag';
import { tooltipTexts } from 'tooltipTexts';
import { formatters } from 'utils/formatters';
import { number, type SortDirection } from '@harmonya/utils';
import type { AggregateTagValue, PresetName } from '@harmonya/models';
import type { Config } from '../../../hooks/useSliderFilters';
import { useRecoilValueLoadableState } from 'hooks/useRecoilValueLoadable';
import { useRecoilValue } from 'recoil';
import { growthPointState } from 'store/growthPoint';
import { productsState } from 'store/products';
import { explorePageBrandingIdsState } from 'store/explorePage';
import { settingsState } from 'store/settings';

export type BasePresetConfig = {
  title: string;
  presetName: PresetName;
  sortDirection: SortDirection;
  sortBy: AggregateTagValue;
};

export const basePresetsConfig: Record<PresetName, BasePresetConfig> = {
  drivers: {
    title: 'Drivers',
    presetName: 'drivers',
    sortDirection: 'desc',
    sortBy: 'salesGrowth',
  },
  drains: {
    title: 'Drains',
    presetName: 'drains',
    sortDirection: 'asc',
    sortBy: 'salesGrowth',
  },
  radar: {
    title: 'Under The Radar',
    presetName: 'radar',
    sortDirection: 'desc',
    sortBy: 'salesGrowth',
  },
  reviews: {
    title: 'Popular in Reviews',
    presetName: 'reviews',
    sortDirection: 'desc',
    sortBy: 'reviewProductsRatio',
  },
  marketing: {
    title: 'Popular in Marketing',
    presetName: 'marketing',
    sortDirection: 'desc',
    sortBy: 'listingProductsRatio',
  },
};
const minimalProductsCount = 2;
const selectedBrandMinimalCount = 1;

export const growthPresetNames = ['drivers', 'drains', 'radar'] as const;

export const sourcePresetNames = ['reviews', 'marketing'] as const;

export type GrowthPresetName = (typeof growthPresetNames)[number];

const presetIcons: Record<PresetName, IconName> = {
  drivers: 'arrow-up-small-big',
  drains: 'arrow-down-small-big',
  radar: 'radar',
  reviews: 'source-review',
  marketing: 'source-listing',
};

export interface PresetConfig {
  presetName: PresetName;
  title: string;
  iconName: IconName;
  tooltip: string;
  sortDirection: SortDirection;
  sortBy: TagValue;
  getValueBySliderTitle(
    title: string,
    minValue: number,
    maxValue: number
  ): [number, number] | undefined;
}

export function usePresets() {
  const settings = useRecoilValue(settingsState);
  const growthPoint = useRecoilValue(growthPointState);
  const products = useRecoilValueLoadableState(productsState);
  const selectedBrands = useRecoilValue(explorePageBrandingIdsState);

  const {
    productsCount = 0,
    totalSales = 0,
    salesGrowth,
    reviewProductRatioTopPercentile,
    listingProductRatioTopPercentile,
    brandsCount = 0,
  } = products?.metadata ?? {};
  const presetNameToSliderValuesMap = settings.presets;

  const getMinimalBrandsFilter = (
    brandRatio: number,
    minimalBrandsCount: number,
    maximalBrandsCount: number = Infinity
  ) => {
    if (selectedBrands.length) {
      return selectedBrandMinimalCount;
    }

    const partiallyBrandsCountByRatio = Math.floor(brandsCount * brandRatio);
    const limitedBrandsCount = number.minmax(
      partiallyBrandsCountByRatio,
      minimalBrandsCount,
      maximalBrandsCount
    );

    return limitedBrandsCount;
  };

  const getSalesGrowthFilter = (negativeGrowthSalesGrowth?: number, growthRatio?: number) => {
    if (typeof growthRatio === 'undefined') {
      return undefined;
    }

    const salesGrowthValue = salesGrowth?.[growthPoint];

    if (salesGrowthValue == null) {
      return NaN;
    }

    if (salesGrowthValue < 0) {
      if (negativeGrowthSalesGrowth) {
        return negativeGrowthSalesGrowth;
      }
    } else if (growthRatio) {
      return salesGrowthValue * growthRatio;
    }

    return salesGrowthValue;
  };

  const getProductCountsFilter = (productRatio: number) => {
    return Math.max(Math.floor(productsCount * productRatio), minimalProductsCount);
  };

  const basePresets = Object.values(basePresetsConfig);
  const fullPresetConfigs = basePresets.map(({ presetName, ...partialConfig }) => {
    const sliderFiltersValues = presetNameToSliderValuesMap[presetName];
    const {
      negativeGrowthSalesGrowth,
      minGrowthRatio,
      maxGrowthRatio,
      minimalBrandsCount,
      maximalBrandsCount,
      minSalesRatio,
      maxSalesRatio,
      productRatio,
      brandRatio,
    } = sliderFiltersValues;
    const minSalesGrowth = getSalesGrowthFilter(negativeGrowthSalesGrowth, minGrowthRatio);
    const maxSalesGrowth = getSalesGrowthFilter(negativeGrowthSalesGrowth, maxGrowthRatio);
    const config: PresetConfig = {
      presetName,
      tooltip: tooltipTexts.explorePage[presetName],
      getValueBySliderTitle(title, minValue, maxValue) {
        switch (title) {
          case 'Number Of Brands':
            return [
              getMinimalBrandsFilter(brandRatio, minimalBrandsCount, maximalBrandsCount),
              maxValue,
            ];
          case 'Number Of Products':
            return [getProductCountsFilter(productRatio), maxValue];
          case formatters.salesGrowth.title:
            if (minSalesGrowth) {
              return [minSalesGrowth, maxValue];
            }

            if (maxSalesGrowth) {
              return [minValue, maxSalesGrowth];
            }

            if (presetName === 'drains') {
              return [minValue, salesGrowth?.[growthPoint] ?? maxValue];
            }

            break;
          case formatters.totalSales.title:
            if (minSalesRatio) {
              return [totalSales * minSalesRatio, maxValue];
            }

            if (maxSalesRatio) {
              return [minValue, totalSales * maxSalesRatio];
            }

            break;
          case formatters.reviewProductsRatio.title:
            if (presetName === 'reviews') {
              return [reviewProductRatioTopPercentile ?? minValue, maxValue];
            }

            break;
          case formatters.listingProductsRatio.title:
            if (presetName === 'marketing') {
              return [listingProductRatioTopPercentile ?? minValue, maxValue];
            }
            break;
        }
      },
      ...partialConfig,
      iconName: presetIcons[presetName],
    };

    return [presetName, config] as const;
  });

  return new Map(fullPresetConfigs);
}

export const getExplorePageSliderFiltersConfig = (
  brandSignificanceDisabled: boolean
): Config<AggregateTagValue>[] => [
  {
    title: 'Number Of Brands',
    icon: formatters.brandsCount.icon,
    prop: 'brandsCount',
  },
  {
    title: 'Number Of Products',
    icon: formatters.productsCount.icon,
    prop: 'productsCount',
  },
  {
    title: formatters.salesGrowth.title,
    icon: formatters.salesGrowth.icon,
    prop: 'salesGrowth',
  },
  {
    title: formatters.totalSales.title,
    icon: formatters.totalSales.icon,
    prop: 'totalSales',
  },
  {
    title: formatters.reviewProductsRatio.title,
    icon: formatters.reviewProductsRatio.icon,
    prop: 'reviewProductsRatio',
    tooltipText: tooltipTexts.explorePage[formatters.reviewProductsRatio.tooltipKey],
  },
  {
    title: formatters.listingProductsRatio.title,
    icon: formatters.listingProductsRatio.icon,
    prop: 'listingProductsRatio',
    tooltipText: tooltipTexts.explorePage[formatters.listingProductsRatio.tooltipKey],
  },
  {
    title: formatters.brandSignificance.title,
    icon: formatters.brandSignificance.icon,
    prop: 'brandSignificance',
    isDisabled: brandSignificanceDisabled,
  },
];
