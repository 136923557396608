import { Icon } from 'components/general/Icon';
import { usePerformanceBoostingTagTitle } from 'components/layout/attributionPage/hooks/usePerformanceBoosting';
import React from 'react';
import { useResetRecoilState } from 'recoil';
import { attributionPerformanceBoostingTagState } from 'store/attribution';
import appStyles from '../App.module.scss';
import styles from './AttributionPerformanceBoostingTag.module.scss';

export function AttributionPerformanceBoostingTag() {
  const performanceBoostingTagTitle = usePerformanceBoostingTagTitle();
  const resetPerformanceBoostingTag = useResetRecoilState(attributionPerformanceBoostingTagState);

  if (!performanceBoostingTagTitle) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Icon name='crosshairs-simple' weight='light' className={styles.icon} />
      <span className={styles.content}>
        Performance Boosting: <h3>{performanceBoostingTagTitle}</h3>
      </span>
      <button className={appStyles.unpaddedButton} onClick={resetPerformanceBoostingTag}>
        <Icon name='xmark' weight='regular' />
      </button>
    </div>
  );
}
