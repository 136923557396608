import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import type { Link } from './NavLinks';
import { NavLinks } from './NavLinks';
import appStyles from '../../layout/App.module.scss';
import styles from './Nav.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactComponent as LogoIcon } from '../../../assets/icons/logo.svg';
import { NavWindow } from './NavWindow';
import { NavWorkspace } from './NavWorkspace';
import type { PathName } from 'utils/pageName';
import { getPageName } from 'utils/pageName';
import { useRecoilValue } from 'recoil';
import { auth } from '../../../store/auth';
import { env } from '../../../../env';
import { appsState } from 'store/apps';

export function Nav() {
  const location = useLocation();
  const [pathes, setPathes] = useState(new Map<PathName | '', string>());
  const enabledApps = useRecoilValue(appsState);
  const { logout, user } = env.USE_AUTH ? useAuth0() : auth;
  const isLoading = !user;

  useEffect(() => {
    const newPathes = new Map(pathes);

    newPathes.set(getPageName(location.pathname) as PathName, location.pathname + location.search);

    setPathes(newPathes);
  }, [location.search]);

  const getPath = (keyOrDefault: PathName | '') => pathes.get(keyOrDefault) ?? keyOrDefault;

  if (isLoading) {
    return null;
  }

  const insightLinks: Link[] = [
    { to: getPath('explore'), iconName: 'magnifying-glass-chart', tooltipTextKey: 'explore' },
    {
      to: getPath('comparison'),
      iconName: 'diagram-venn',
      tooltipTextKey: 'comparison',
      role: 'Comparison Page',
    },
  ];
  const attributionLinks: Link[] = [
    {
      to: getPath('attribution'),
      iconName: 'book-open',
      tooltipTextKey: 'attribution',
    },
  ];
  const supportedLinks = [
    ...(enabledApps.insight ? insightLinks : []),
    ...(enabledApps.attribution ? attributionLinks : []),
  ];
  const userWindowContent = (
    <div className={classNames(appStyles.verticalFlex, appStyles.alignCenter, appStyles.gap4)}>
      <div className={classNames(styles.user, styles.userDetailsPicture)}>
        <img src={user.picture} />
      </div>
      <div className={classNames(appStyles.verticalFlex, appStyles.alignCenter, appStyles.gap1)}>
        <h4 className={styles.userDetailsName}>{user.name}</h4>
        <h5>{user.email}</h5>
      </div>
      <button className={appStyles.buttonPrimary} type='button' onClick={() => logout()}>
        Sign Out
      </button>
    </div>
  );

  return (
    <nav
      className={classNames(
        appStyles.box,
        appStyles.verticalFlex,
        appStyles.shadowed,
        appStyles.animatedFadeIn
      )}
    >
      <div className={classNames(appStyles.flexGrow2, appStyles.verticalFlex, appStyles.gap6)}>
        <NavLink to={getPath('')}>
          <LogoIcon className={classNames(styles.link, styles.logoLink)} />
        </NavLink>
        <NavLinks links={supportedLinks} />
      </div>
      {/* Profile & settings links */}
      <div
        className={classNames(
          appStyles.verticalFlex,
          appStyles.flexGrow4,
          appStyles.gap6,
          appStyles.justifyEnd
        )}
      >
        <NavWorkspace buttonClassName={styles.windowButton} />
        <NavWindow content={userWindowContent}>
          <div className={classNames(styles.user, styles.windowButton)}>
            <img src={user.picture} />
          </div>
        </NavWindow>
      </div>
    </nav>
  );
}
