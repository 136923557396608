import type { RefObject } from 'react';
import { useState, useEffect } from 'react';

type ResizeObserverEntryWithElement = ResizeObserverEntry & {
  readonly target: Element;
};

export const useResize = (ref: RefObject<Element>) => {
  const [dimensions, setDimensions] = useState<DOMRectReadOnly | null>(null);

  useEffect(() => {
    const target = ref.current;

    if (target) {
      const resizeObserver = new ResizeObserver(([entry]: ResizeObserverEntryWithElement[]) =>
        setDimensions(entry.contentRect)
      );

      resizeObserver.observe(target);

      return () => resizeObserver.unobserve(target);
    }
  }, [ref]);

  return dimensions;
};
