import { selector } from 'recoil';
import { fetchGet } from '../utils/fetch';
import { authState, customerIdState } from './auth';

export type EnabledApps = { attribution: boolean; insight: boolean };

export const appsState = selector({
  key: 'apps',
  cachePolicy_UNSTABLE: { eviction: 'keep-all' },
  get: async ({ get }) => {
    const customerId = get(customerIdState);
    const { user } = get(authState);
    const enabledApps = await fetchGet<EnabledApps>('/api/apps', customerId, user.email);

    return enabledApps;
  },
});
