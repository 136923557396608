import type { ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';
import styles from './EmptyState.module.scss';
import appStyles from '../layout/App.module.scss';

const containerClasses = [
  appStyles.verticalFlex,
  appStyles.fullWidth,
  appStyles.justifyCenter,
  appStyles.alignCenter,
  appStyles.animatedFadeIn,
];

type Props = {
  message: ReactNode;
  image: ReactNode;
};

export function EmptyState(props: Props) {
  const { message, image } = props;

  return (
    <div className={classNames(containerClasses)}>
      {image}
      <div className={classNames(appStyles.verticalFlex, appStyles.gap1, styles.text)}>
        <span className={styles.title}>We&apos;re Sorry!</span>
        {message}
      </div>
    </div>
  );
}
