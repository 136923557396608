import type { EnabledApps } from 'store/apps';

export const getPageName = (page: string) => page.replace('/', '');

// Use window to detect current pathname instead of useLocation().pathname which have delay
export const getCurrentPathName = () => window.location.pathname;

export const getCurrentPageName = () => getPageName(getCurrentPathName());

export const pathNames = ['explore', 'comparison', 'attribution', 'trends'] as const;

export type PathName = (typeof pathNames)[number];

export const defaultRoute: PathName = 'explore';

export const getDefaultRoute = (enabledPathNames: PathName[]) =>
  defaultRoute in enabledPathNames ? defaultRoute : enabledPathNames[0];

export const getEnabledPathNames = ({ insight, attribution }: EnabledApps) =>
  [
    ...(insight ? ['explore', 'comparison'] : []),
    ...(attribution ? ['attribution'] : []),
  ] as PathName[];
