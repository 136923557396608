import { useInstanceId } from 'hooks/useInstanceId';
import type { PropsWithChildren } from 'react';
import React, { createContext, useContext, useMemo } from 'react';
import type { RecoilState, RecoilValueReadOnly } from 'recoil';
import { DefaultValue, selectorFamily, useRecoilValue } from 'recoil';
import type { ColumnKey } from '../DataTableBase';

export type ColumnWidth = number | undefined;

type ColumnWidthState = (columnKey: ColumnKey) => RecoilState<ColumnWidth>;
type ColumnIdMaxWidthState = (columnKey: ColumnKey) => RecoilValueReadOnly<number>;

export const ColumnWidthsContext = createContext<
  | undefined
  | {
      columnWidthState: ColumnWidthState;
      columnIdMaxWidthRecoilState: ColumnIdMaxWidthState;
    }
>(undefined);

type Props = {
  minWidth?: number;
  recoilState: RecoilState<Map<ColumnKey, ColumnWidth>>;
  columnIdMaxWidthRecoilState: ColumnIdMaxWidthState;
};

export const useColumnWidthContext = (columnKey: ColumnKey) => {
  const state = useContext(ColumnWidthsContext);

  if (state) {
    const { columnWidthState } = state;
    const width = useRecoilValue(columnWidthState(columnKey));

    return width;
  }
};

export function ColumnWidthsProvider(props: PropsWithChildren<Props>) {
  const instanceId = useInstanceId();
  const { minWidth = 0, recoilState, columnIdMaxWidthRecoilState, children } = props;

  const context = useMemo(
    () => ({
      columnIdMaxWidthRecoilState,
      columnWidthState: selectorFamily({
        key: `setColumnWidth${instanceId}`,
        get:
          (columnId: ColumnKey) =>
          ({ get }) => {
            const columnWidths = get(recoilState);
            const width = columnWidths.get(columnId);

            return width;
          },
        set:
          (columnId: ColumnKey) =>
          ({ get, set }, width) => {
            const columnWidths = get(recoilState);
            const newColumnWidths = new Map(columnWidths);

            if (width instanceof DefaultValue || width == null) {
              newColumnWidths.delete(columnId);
            } else {
              const computedWidth = Math.max(minWidth, width);
              newColumnWidths.set(columnId, computedWidth);
            }

            set(recoilState, newColumnWidths);
          },
      }),
    }),
    []
  );

  return <ColumnWidthsContext.Provider value={context}>{children}</ColumnWidthsContext.Provider>;
}
