export const pixelize = (value: string | number) => `${value}px`;

export const isString = (value: unknown): value is string => typeof value === 'string';

export const stringCompare = (a: string, b: string) => a.localeCompare(b);

export const getWords = (value: string) => value.split(/\s/).filter(Boolean);

export const plural = (text: string) => `${text}s`;

export const getPluralOrSingular = (text: string, count: number) =>
  count === 1 ? text : plural(text);

export const capitalize = <T extends string>(text: T): Capitalize<T> => {
  const [firstLetter, ...otherLetters] = text;
  const result = `${firstLetter.toUpperCase()}${otherLetters.join('')}`;

  return result as Capitalize<T>;
};
