import type { ReactNode } from 'react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, type DropdownRef } from 'components/general/dropdown/Dropdown';
import { SelectDropdown } from 'components/general/select/SelectDropdown';
import styles from './AttributionCellDropdown.module.scss';
import { useRecoilValue } from 'recoil';
import { Textarea } from 'components/general/Textarea';
import { getSortedFilteredOptions } from 'utils/sortedFilteredOptions';
import { HighlightTexts } from 'components/general/HighlightTexts';
import type { ColumnId } from 'store/attribution.types';
import { columnOptionFiltersState } from 'store/attributionPage';
import { Icon } from 'components/general/Icon';

type Props = {
  columnId: ColumnId;
  setValue: (value: string) => void;
  initDisplayValue: string;
  isOpenedByDoubleClick?: boolean;
};

const dropdownSections = [
  <div key='title' className={styles.dropdownTitle}>
    Type in to search or create new
  </div>,
];

export function AttributionCellDropdown(props: Props) {
  const dropdownRef = useRef<DropdownRef>({});
  const { columnId, setValue, initDisplayValue, isOpenedByDoubleClick } = props;
  const [displayValue, setDisplayValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const options = useRecoilValue(columnOptionFiltersState(columnId));
  const normalizedOptions = Array.from(options.keys(), option => ({ value: option }));
  const { filteredOptions, highlightedOptions } = useMemo(() => {
    const isNewSearchValue = searchValue.trim() && !options.has(searchValue);
    const result = getSortedFilteredOptions<{ value: string; displayValue?: ReactNode }>(
      searchValue,
      normalizedOptions,
      'value'
    );

    if (isNewSearchValue) {
      result.filteredOptions.push({
        value: searchValue,
        displayValue: (
          <div className={styles.newValueOption}>
            <Icon name='plus' weight='light' />
            <span>
              Add the value{' '}
              <q>
                <b>{searchValue}</b>
              </q>
            </span>
          </div>
        ),
      });
    }

    return result;
  }, [searchValue, options]);

  useEffect(() => {
    dropdownRef.current.open?.();
  }, []);

  const buttonContent = (
    <Textarea
      value={displayValue}
      isAutoSelect={isOpenedByDoubleClick}
      setValue={newValue => {
        setDisplayValue(newValue);
        setSearchValue(newValue);
      }}
    />
  );

  useEffect(() => {
    if (isOpenedByDoubleClick) {
      setDisplayValue(initDisplayValue);
    }
  }, []);

  return (
    <Dropdown
      minWidth={300}
      dropdownRef={dropdownRef}
      buttonContent={buttonContent}
      className={styles.dropdown}
      buttonContainerClassName={styles.buttonContainer}
    >
      <SelectDropdown
        value={undefined}
        sections={dropdownSections}
        onChange={newValue => {
          if (newValue) {
            setValue(newValue);
            dropdownRef.current.close?.();
          }
        }}
        options={filteredOptions}
        displayValueGetter={option =>
          option.displayValue || (
            <span>
              <HighlightTexts
                items={highlightedOptions.get(option.value)}
                defaultText={option.value}
              />
            </span>
          )
        }
      />
    </Dropdown>
  );
}
