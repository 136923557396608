import React, { type ComponentProps, memo, type ComponentType } from 'react';
import { withColumnWidths } from './hoc/withColumnWidths';
import { withMarkedCells } from './hoc/withMarkedCells';
import { DataTableBase, type Column, type ColumnKey } from './DataTableBase';
import type { InFull } from '@harmonya/utils';
import type { RecoilState, RecoilValueReadOnly } from 'recoil';
import { withPinnedColumns } from './hoc/withPinnedColumns';
import { withHiddenColumns } from './hoc/withHiddenColumns';
import { withOrderedColumns } from './hoc/withOrderedColumns';
import type { ColumnWidth } from './context/ColumnWidthContext';

type Props<H extends Column> = ComponentProps<typeof DataTableBase<H>> & {
  HeaderCellComponent: ComponentType<{ column: H; width?: number }>;
  isMarkedCellsEnabled?: boolean;
} & InFull<{
    isColumnWidthsEnabled: boolean;
    columnKeyWidthsState: RecoilState<Map<ColumnKey, ColumnWidth>>;
    columnIdMaxWidthState: (param: ColumnKey) => RecoilValueReadOnly<number>;
  }> &
  InFull<{
    isHiddenColumnsEnabled: boolean;
    hiddenColumnKeysState: RecoilState<Set<ColumnKey>>;
  }> &
  InFull<{
    isOrderedColumnsEnabled: boolean;
    orderedColumnKeysState: RecoilValueReadOnly<ColumnKey[]>;
  }> &
  InFull<{
    isPinnedColumnsEnabled: boolean;
    pinnedColumnKeysState: RecoilState<Set<ColumnKey>>;
  }>;

export const DataTable = memo(function DataTable<H extends Column>(props: Props<H>) {
  const {
    isColumnWidthsEnabled,
    columnIdMaxWidthState,
    columnKeyWidthsState,
    isMarkedCellsEnabled,
    isPinnedColumnsEnabled,
    pinnedColumnKeysState,
    isHiddenColumnsEnabled,
    hiddenColumnKeysState,
    isOrderedColumnsEnabled,
    orderedColumnKeysState,
  } = props;

  let DataTableComponent: ComponentType<Props<H>> = DataTableBase;

  if (isColumnWidthsEnabled) {
    DataTableComponent = withColumnWidths(DataTableComponent, {
      recoilState: columnKeyWidthsState,
      columnIdMaxWidthRecoilState: columnIdMaxWidthState,
      minWidth: 90,
    });
  }

  if (isMarkedCellsEnabled) {
    DataTableComponent = withMarkedCells(DataTableComponent);
  }

  if (isPinnedColumnsEnabled) {
    DataTableComponent = withPinnedColumns(DataTableComponent, {
      recoilState: pinnedColumnKeysState,
    });
  }

  if (isHiddenColumnsEnabled) {
    DataTableComponent = withHiddenColumns(DataTableComponent, {
      recoilState: hiddenColumnKeysState,
    });
  }

  if (isOrderedColumnsEnabled) {
    DataTableComponent = withOrderedColumns(DataTableComponent, {
      recoilState: orderedColumnKeysState,
    });
  }

  return <DataTableComponent {...props} />;
});
