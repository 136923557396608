import React, { type ReactNode } from 'react';
import classNames from 'classnames';
import styles from './ToastMessage.module.scss';
import appStyles from '../layout/App.module.scss';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Icon, type IconName } from './Icon';
import { useTimeout } from 'hooks/useTimeout';
import { toastMessageState } from 'store/toastMessage';
import { object } from '@harmonya/utils';

type Action = {
  title: string;
  clickHandler: () => void;
};

export interface ToastMessage {
  type: 'error' | 'warning' | 'success' | 'info';
  text: string;
  timeout?: number;
  iconElement?: ReactNode;
  action?: Action;
}

const icons: { [key in ToastMessage['type']]: IconName } = {
  error: 'circle-xmark',
  warning: 'circle-exclamation',
  success: 'circle-check',
  info: 'circle-info',
};

const getEstimatedReadingTime = (text = '') => {
  const wordPerMinute = 60;
  const wordsCount = text?.trim().split(/\s+/).length;
  const minutes = wordsCount / wordPerMinute;
  const millisecondes = Math.floor(minutes * 60_000);

  return millisecondes;
};

export function ToastMessage() {
  const toastMessage = useRecoilValue(toastMessageState);
  const resetToastMessage = useResetRecoilState(toastMessageState);

  const computedTimeout =
    object.getIfMatch(toastMessage, 'timeout', 'number') ??
    getEstimatedReadingTime(toastMessage?.text);
  useTimeout(resetToastMessage, computedTimeout, [toastMessage?.timestamp]);

  if (!toastMessage) {
    return null;
  }

  const {
    type,
    text,
    iconElement = <Icon className={styles[type]} name={icons[type]} weight='light' />,
    action,
  } = toastMessage;

  return (
    <div
      className={classNames(
        appStyles.horizontalFlex,
        appStyles.alignCenter,
        appStyles.justifySpaceBetween,
        styles.message
      )}
    >
      <div className={styles.section}>
        <div className={styles.iconContainer}>{iconElement}</div>
        <p className={appStyles.mediumFont}>{text}</p>
      </div>
      <div className={styles.section}>
        {action && (
          <button
            className={styles.actionButton}
            onClick={() => {
              action.clickHandler();
              resetToastMessage();
            }}
          >
            {action.title}
          </button>
        )}
        <button
          className={classNames(appStyles.unpaddedButton, appStyles.colorInverted)}
          onClick={resetToastMessage}
        >
          <Icon name='xmark' weight='regular' />
        </button>
      </div>
    </div>
  );
}
