import { Icon } from 'components/general/Icon';
import React from 'react';
import styles from './AttributionPageSaveStatus.module.scss';
import classNames from 'classnames';
import { useRecoilValue } from 'recoil';
import { saveStatusState } from 'store/attribution';

export const statuses = {
  offline: {
    title: 'Trying to connect...',
    iconName: 'cloud-slash',
  },
  saving: {
    title: 'Saving now...',
    iconName: 'arrows-repeat',
  },
  recentlySaved: {
    title: 'Saved',
    iconName: 'cloud-check',
  },
  saved: {
    title: '',
    iconName: 'cloud-check',
  },
} as const;

export function AttributionPageSaveStatus() {
  const activeStatus = useRecoilValue(saveStatusState);

  const elements = Object.entries(statuses).map(([status, { title, iconName }]) => (
    <div
      key={status}
      className={classNames(
        styles.saveStatus,
        status === 'offline' && styles.offline,
        activeStatus === status && styles.active
      )}
    >
      <Icon name={iconName} />
      {title}
    </div>
  ));

  return <div className={styles.container}>{elements}</div>;
}
