import type { ComponentProps } from 'react';
import React from 'react';
import type { DropDownDirection } from 'components/general/charts/types';
import { FilterSelect } from './FilterSelect';

type FilterSelectProps = ComponentProps<typeof FilterSelect>;

type GlobalProps = {
  selects: FilterSelectProps[];
  direction?: DropDownDirection;
  minWidthEnabled?: boolean;
  maxWidth?: number;
  buttonClassName?: string;
};

type Props = {
  selects: Omit<ComponentProps<typeof FilterSelect>, keyof GlobalProps>[];
} & GlobalProps;

export function FilterSelects(props: Props) {
  return (
    <>
      {props.selects.map((selectProps, i) => (
        <FilterSelect key={i} {...selectProps} {...props} />
      ))}
    </>
  );
}
