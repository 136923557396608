import { selector } from 'recoil';
import { fetchGet } from '../utils/fetch';
import { authState, customerIdState } from './auth';
import { object, type SortDirection } from '@harmonya/utils';
import type { ClientSettings } from 'interfaces';
import type { TableProduct } from 'store/product.types';

export const settingsState = selector({
  key: 'settings',
  cachePolicy_UNSTABLE: { eviction: 'keep-all' },
  get: async ({ get }) => {
    const customerId = get(customerIdState);
    const { user, customers } = get(authState);
    const settings = await fetchGet<ClientSettings>('/api/settings', customerId, user.email);
    const customerData = customers.get(customerId);

    if (customerData?.settingsOverrides) {
      object.nestedOverride(settings, customerData.settingsOverrides);
    }

    return settings;
  },
});

/**
 * Explore page
 */

export const explorePageQueryDefaultState = selector({
  key: 'explorePageQueryDefault',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const settings = get(settingsState);

    return settings.defaults.explorePage.query ?? '';
  },
});

export const explorePageCategoryIdsDefaultState = selector({
  key: 'explorePageCategoryIdsDefault',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const settings = get(settingsState);

    return settings.defaults.explorePage.categoryIds ?? [];
  },
});

export const explorePageMarketIdsDefaultState = selector({
  key: 'explorePageMarketIdsDefault',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const settings = get(settingsState);

    return settings.defaults.explorePage.marketIds ?? [];
  },
});

export const explorePagePeriodIdsDefaultState = selector({
  key: 'explorePagePeriodIdsDefault',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const settings = get(settingsState);

    return settings.defaults.explorePage.periodIds ?? [];
  },
});

export const explorePageBrandingIdsDefaultState = selector({
  key: 'explorePageBrandIdsDefault',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const settings = get(settingsState);

    return settings.defaults.explorePage.brandingIds ?? [];
  },
});

export const explorePageProductsTableSearchTextDefaultState = selector({
  key: 'explorePageProductsTableSearchTextDefault',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const settings = get(settingsState);

    return settings.defaults.explorePage.productsTable?.searchText ?? '';
  },
});

export const explorePageProductsTableSortDefaultState = selector({
  key: 'explorePageProductsTableSortDefault',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const settings = get(settingsState);

    return (
      settings.defaults.explorePage.productsTable?.sort ?? {
        field: 'name' as keyof TableProduct,
        direction: 'asc' as SortDirection,
      }
    );
  },
});

export const explorePageCategoryLevelDefaultState = selector({
  key: 'explorePageCategoryLevelDefault',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const settings = get(settingsState);

    return settings.defaults.explorePage.categoryLevelId;
  },
});

export const explorePageBrandingLevelDefaultState = selector({
  key: 'explorePageBrandingLevelDefault',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const settings = get(settingsState);

    return settings.defaults.explorePage.brandingLevelId;
  },
});

/**
 * Trends page
 */

export const trendsPageCategoryIdsDefaultState = selector({
  key: 'trendsPageCategoryIdsDefault',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const settings = get(settingsState);

    return settings.defaults.trendsPage?.categoryIds ?? [];
  },
});

export const trendsPageMarketIdsDefaultState = selector({
  key: 'trendsPageMarketIdsDefault',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const settings = get(settingsState);

    return settings.defaults.trendsPage?.marketIds ?? [];
  },
});

export const trendsPagePeriodIdsDefaultState = selector({
  key: 'trendsPagePeriodIdsDefault',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const settings = get(settingsState);

    return settings.defaults.trendsPage?.periodIds ?? [];
  },
});

export const trendsPageBrandingIdsDefaultState = selector({
  key: 'trendsPageBrandIdsDefault',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const settings = get(settingsState);

    return settings.defaults.trendsPage?.brandingIds ?? [];
  },
});

/**
 * comparison page
 */

/** @todo: when comparison page settings are filled (from the ETL)
 * have this line instead of the line below
 * const getComparisonPageDefaults = (settings: ClientSettings) => settings.defaults.comparisonPage
 * */
const getComparisonPageDefaults = (settings: ClientSettings) =>
  settings.defaults.comparisonPage ?? settings.defaults.explorePage;

export const comparisonPageCategoryIdsDefaultState = selector({
  key: 'comparisonPageCategoryIdsDefault',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const settings = get(settingsState);

    return getComparisonPageDefaults(settings)?.categoryIds ?? [];
  },
});

export const comparisonPageMarketIdsDefaultState = selector({
  key: 'comparisonPageMarketIdsDefault',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const settings = get(settingsState);

    return getComparisonPageDefaults(settings)?.marketIds ?? [];
  },
});

export const comparisonPagePeriodIdsDefaultState = selector({
  key: 'comparisonPagePeriodIdsDefault',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const settings = get(settingsState);

    return getComparisonPageDefaults(settings)?.periodIds ?? [];
  },
});
