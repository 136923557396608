import type { ForwardedRef, ReactNode } from 'react';
import React from 'react';
import { Tooltip } from '../Tooltip';

type Props = {
  content: ReactNode;
  containerRef: ForwardedRef<HTMLDivElement>;
  tooltip?: ReactNode;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'content'>;

export function DropdownButton(props: Props) {
  const { content, containerRef, tooltip, ...divProps } = props;
  const result = (
    <div ref={containerRef} {...divProps}>
      {content}
    </div>
  );

  return tooltip ? <Tooltip content={tooltip}>{result}</Tooltip> : result;
}
