import type { ComponentProps } from 'react';
import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import styles from './ExplorePageTagsDisplayOptions.module.scss';
import appStyles from '../App.module.scss';
import selectStyles from '../../general/select/Select.module.scss';
import type { IconName } from '../../general/Icon';
import { Icon } from '../../general/Icon';
import type { DropdownRef } from 'components/general/dropdown/Dropdown';
import { Dropdown } from 'components/general/dropdown/Dropdown';
import { tooltipTexts } from 'tooltipTexts';
import { formatters } from 'utils/formatters';
import type { TagValue } from 'models/AggregateTag';
import { explorePageBrandingIdsState } from 'store/explorePage';
import { useRecoilValue } from 'recoil';
import { useSearchParam } from 'hooks/useSearchParam';
import { SelectButton } from 'components/general/select/SelectButton';
import type { Option } from 'components/general/select/types';
import { useKey } from 'hooks/useKey';
import { SelectOptions } from 'components/general/select/SelectOptions';

export const maxTagsDisplayedOptions = [20, 30, 40, 50].map(value => ({
  value,
  displayValue: `Top ${value}`,
  disabled: false,
}));

type Props = {
  buttonTitle: string;
  buttonIconName?: IconName;
  className?: string;
  resetPresets: () => void;
} & Omit<ComponentProps<typeof Dropdown>, 'buttonContent' | 'minWidth'>;

export function ExplorePageTagsDisplayOptions(props: Props) {
  const { buttonTitle, buttonIconName, className, resetPresets, ...dropdownProps } = props;
  const dropdownRef = useRef<DropdownRef>({});
  const explorePageBrandingIds = useRecoilValue(explorePageBrandingIdsState);
  const brandSignificanceDisabled = !explorePageBrandingIds.length;
  const tagValueOptions: Option<TagValue>[] = [
    { value: 'productsCount', displayValue: 'Number Of Products' },
    { value: 'salesGrowth', displayValue: formatters.salesGrowth.title },
    { value: 'totalSales', displayValue: formatters.totalSales.title },
    {
      value: 'brandSignificance',
      displayValue: formatters.brandSignificance.title,
      tooltip: brandSignificanceDisabled
        ? tooltipTexts.explorePage.diamondChartDisplayFilterBrandSignificanceWhenDisabled
        : tooltipTexts.explorePage.diamondChartDisplayFilterBrandSignificanceWhenEnabled,
      disabled: brandSignificanceDisabled,
    },
    { value: 'reviewProductsRatio', displayValue: formatters.reviewProductsRatio.title },
    { value: 'listingProductsRatio', displayValue: formatters.listingProductsRatio.title },
  ];
  const [tagValueProp, setTagValueProp] = useSearchParam<TagValue>('tagValueProp', {
    defaultValue: tagValueOptions[0].value,
  });
  const [maxTagsDisplayed, setMaxTagsDisplayed] = useSearchParam('maxTagsDisplayed', {
    defaultValue: maxTagsDisplayedOptions[0].value,
    parser: 'number',
  });
  const borderedClassName = classNames(appStyles.border, appStyles.backgroundBackground);
  const buttonContent = (
    <SelectButton
      className={classNames(
        appStyles.horizontalFlex,
        appStyles.justifySpaceBetween,
        appStyles.gap1,
        appStyles.button,
        appStyles.positionRelative,
        selectStyles.button,
        borderedClassName,
        className
      )}
      selectedOptions={tagValueOptions.filter(option => option.value === tagValueProp)}
      title='Sort by'
      displayValueGetter={option => option.displayValue}
      containerElement={null}
    />
  );
  const [highlightedIndex, setHighlightedIndex] = useState(0);

  const setSelectedItemByHighlightedItem = (item: Option<TagValue> | Option<number>) => {
    const isMaxTagsDisplayedOption = typeof item.value === 'number';

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isMaxTagsDisplayedOption
      ? setMaxTagsDisplayed(item.value as number)
      : setTagValueProp(item.value as TagValue);
  };

  const totalOptionsLength = tagValueOptions.length + maxTagsDisplayedOptions.length;

  useKey(
    {
      ArrowUp: () => {
        const index = highlightedIndex < 0 ? totalOptionsLength - 1 : highlightedIndex - 1;

        setHighlightedIndex(index);
      },
      ArrowDown: () => {
        const index = highlightedIndex > totalOptionsLength - 1 ? 0 : highlightedIndex + 1;

        setHighlightedIndex(index);
      },
      Enter: () => {
        const highlightedItem =
          highlightedIndex < tagValueOptions.length
            ? tagValueOptions[highlightedIndex]
            : maxTagsDisplayedOptions[highlightedIndex - tagValueOptions.length];

        if (!highlightedItem.disabled) {
          setSelectedItemByHighlightedItem(highlightedItem);
          dropdownRef.current.close?.();
        }
      },
    },
    [highlightedIndex, tagValueProp, maxTagsDisplayed]
  );

  function getClickHandler<V, T extends { value: V }>(action: (value: V) => void) {
    return (option: T) => {
      action(option.value);
      dropdownRef.current.close?.();
    };
  }

  const onTagValuePropClick = (option: Option<TagValue>) => {
    if (option.value !== null) {
      getClickHandler(setTagValueProp)(option);
    }

    resetPresets();
  };

  return (
    <Dropdown dropdownRef={dropdownRef} {...dropdownProps} buttonContent={buttonContent}>
      <div
        className={classNames(
          appStyles.verticalFlex,
          appStyles.backgroundBackground,
          appStyles.shadowed,
          styles.select
        )}
      >
        <div>
          <div className={styles.title}>Sort by</div>
          <SelectOptions
            onMouseOver={option =>
              setHighlightedIndex(
                tagValueOptions.findIndex(tagValueOption => tagValueOption.value === option.value)
              )
            }
            highlightedIndex={highlightedIndex < tagValueOptions.length ? highlightedIndex : -1}
            onClick={onTagValuePropClick}
            options={[['', tagValueOptions]]}
            isSelected={option => tagValueProp === option.value}
            displayValueGetter={option => (
              <div
                className={classNames(
                  appStyles.horizontalFlex,
                  appStyles.gap1,
                  appStyles.alignBaseline,
                  styles.option
                )}
              >
                <div className={styles.optionIconContainer}>
                  <Icon weight='light' {...formatters[option.value].icon} />
                </div>
                {option.displayValue}
              </div>
            )}
          />
        </div>
        <div className={styles.divider} />
        <div>
          <div className={styles.title}>View Tag Results</div>
          <SelectOptions
            onMouseOver={option =>
              setHighlightedIndex(
                maxTagsDisplayedOptions.findIndex(
                  maxTagsDisplayedOption => maxTagsDisplayedOption.value === option.value
                ) + tagValueOptions.length
              )
            }
            highlightedIndex={
              highlightedIndex >= tagValueOptions.length
                ? highlightedIndex - tagValueOptions.length
                : -1
            }
            onClick={getClickHandler(setMaxTagsDisplayed)}
            options={[['', maxTagsDisplayedOptions]]}
            isSelected={option => maxTagsDisplayed === option.value}
            displayValueGetter={option => (
              <div
                className={classNames(
                  appStyles.horizontalFlex,
                  appStyles.gap1,
                  appStyles.alignCenter,
                  appStyles.resetLineHeight,
                  styles.option
                )}
              >
                {option.displayValue}
              </div>
            )}
          />
        </div>
      </div>
    </Dropdown>
  );
}
