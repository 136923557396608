import type { ErrorInfo } from 'react';
import React from 'react';
import classNames from 'classnames';
import { ExplorePageQuery } from './ExplorePageQuery';
import { ExplorePageMetadata } from './ExplorePageMetadata';
import appStyles from '../../layout/App.module.scss';
import styles from './ExplorePage.module.scss';
import { styleVariables } from '../../../utils/styleVariables';
import { ExplorePageProductsTable } from './ExplorePageProductsTable';
import {
  explorePageCategoryIdsState,
  explorePageMarketIdsState,
  explorePagePeriodIdsState,
  explorePageBrandingIdsState,
} from '../../../store/explorePage';
import { Page } from '../Page';
import { FilterSelects } from '../FilterSelects';
import { ExplorePageSubSections } from './ExplorePageSubSections';
import { GrowthPointSelect } from '../../general/GrowthPointSelect';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../../general/ErrorFallback';
import { logger } from '../../../utils/logger';
import { ExplorePageResetScopeFilters } from './ExplorePageResetScopeFilters';
import { ExplorePageTypesAndTags } from './ExplorePageTypesAndTags';
import { Icon } from 'components/general/Icon';
import { useScopeFilters } from 'hooks/useScopeFilters';
import { ExplorePageExportButton } from 'components/layout/explorePage/ExplorePageExportButton';

const rightSectionPadding = appStyles.padded3;

export function ExplorePage() {
  const scopeFiltersSelects = useScopeFilters(
    explorePageCategoryIdsState,
    explorePageMarketIdsState,
    explorePagePeriodIdsState,
    explorePageBrandingIdsState
  );
  const logError = (error: Error, info: ErrorInfo) =>
    logger(error.message, error.stack, info.componentStack);

  return (
    <Page
      title='EXPLORE'
      headerChildren={
        <>
          <ExplorePageQuery />
          <ExplorePageExportButton />
        </>
      }
    >
      <div className={appStyles.horizontalFlex}>
        <div
          className={classNames(
            appStyles.box,
            appStyles.shadowed,
            appStyles.backgroundBackground,
            appStyles.horizontalFlex,
            appStyles.alignCenter,
            appStyles.flexGrow1,
            appStyles.justifySpaceBetween,
            styles.filtersBar
          )}
        >
          <Icon name='filter-sales' className={styles.filterIcon} />
          <div className={classNames(appStyles.flexGrow1, styles.filters)}>
            <FilterSelects
              selects={scopeFiltersSelects}
              minWidthEnabled
              buttonClassName={classNames(appStyles.filter, styles.filter)}
              maxWidth={styleVariables.selectDropdownMaxWidth}
            />
          </div>
          <ExplorePageResetScopeFilters text='Reset' className={appStyles.unpaddedButton} />
        </div>
        <div
          className={classNames(
            appStyles.box,
            appStyles.shadowed,
            appStyles.backgroundBackground,
            appStyles.horizontalFlex,
            appStyles.justifySpaceBetween,
            appStyles.alignCenter,
            styles.filtersBar
          )}
        >
          <div className={classNames(appStyles.horizontalFlex, appStyles.alignCenter)}>
            <GrowthPointSelect />
          </div>
        </div>
      </div>
      <section
        className={classNames(
          appStyles.positionRelative,
          appStyles.horizontalFlex,
          appStyles.flexGrow1,
          appStyles.justifyCenter,
          appStyles.overflowHidden
        )}
      >
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
          <ExplorePageTypesAndTags />
          {/* Right side */}
          <div
            className={classNames(
              appStyles.verticalFlex,
              appStyles.flexGrow1,
              appStyles.fullBasis,
              appStyles.unpaddedBox,
              appStyles.shadowed,
              appStyles.overflowHidden,
              appStyles.gap0,
              styles.rightContainer
            )}
          >
            <ExplorePageMetadata rightSectionPadding={rightSectionPadding} />
            <ExplorePageSubSections rightSectionPadding={rightSectionPadding} />
            <ExplorePageProductsTable padding={rightSectionPadding} />
          </div>
        </ErrorBoundary>
      </section>
    </Page>
  );
}
