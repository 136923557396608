import React from 'react';
import styles from './AttributionPageColumnActionsContent.module.scss';
import { Icon, type IconName } from 'components/general/Icon';
import { join } from 'components/general/Divider';
import { noop as _SHOULD_BE_IMPLEMENTED } from '@harmonya/utils';
import type { Column } from 'components/general/dataTable/DataTableBase';
import classNames from 'classnames';
import { useAttributionPinnedColumn, useAttributionHiddenColumn } from 'store/attribution';
import { useModifyCells } from './AttributionHeaderCell.utils';
import type { Resetter } from 'recoil';

type Button = { key: string; action: () => void; iconName: IconName; isActive?: boolean };
type ButtonGroup = Button[];

type Props = {
  column: Column;
  resetMarkedCells: Resetter;
  markedCells: Map<number, Set<number>>;
  close: () => void;
};

export function AttributionPageColumnActionsContent(props: Props) {
  const { column, resetMarkedCells, markedCells, close } = props;
  const [toggleColumnPinning, isColumnPinned] = useAttributionPinnedColumn(column.key);
  const [toggleColumnHidding] = useAttributionHiddenColumn(column.key);
  const { validateCells } = useModifyCells(markedCells);
  const buttonGroups: ButtonGroup[] = [
    [
      {
        key: 'hide',
        action: toggleColumnHidding,
        iconName: 'eye-slash',
      },
      {
        key: 'pin',
        action: toggleColumnPinning,
        iconName: 'thumbtack',
        isActive: isColumnPinned,
      },
    ],
    [
      { key: 'edit', action: _SHOULD_BE_IMPLEMENTED, iconName: 'edit' },
      {
        key: 'validate',
        action: validateCells,
        iconName: 'circle-check',
      },
    ],
  ];
  const buttonGroupElements = buttonGroups.map((buttons, groupIndex) => (
    <div key={groupIndex} className={styles.buttonsGroup}>
      {buttons.map(({ key, action, iconName, isActive }) => (
        <button
          key={key}
          className={classNames(styles.button, isActive && styles.active)}
          onClick={() => {
            action();
            resetMarkedCells();
            close();
          }}
        >
          <Icon name={iconName} weight={isActive ? 'solid' : 'light'} />
        </button>
      ))}
    </div>
  ));

  return <div className={styles.container}>{join(buttonGroupElements)}</div>;
}
