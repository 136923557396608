import React, { useContext, useEffect, useRef } from 'react';
import { useBooleanState } from 'hooks/useBooleanState';
import { useDeepCompareState } from 'hooks/useDeepCompareState';
import classNames from 'classnames';
import styles from './AttributionHeaderCell.module.scss';
import type { Column } from '../../general/dataTable/DataTableBase';
import { AttributionPageColumnActionsContent } from './AttributionPageColumnActionsContent';
import { getElementCenterAndTopOffset, noop, iterator } from '@harmonya/utils';
import { MarkedCellContext } from 'components/general/dataTable/context/MarkedCellContext';
import { sortedFilteredAttributionDataState } from 'store/attributionPage';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { useModifyCell } from './hooks/useModifyCell';
import type { RevisionAction } from 'store/attribution';

export function useColumnActions(column: Column, width?: number) {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, , close, toggle] = useBooleanState(false);
  const [offset, setOffset] = useDeepCompareState({ top: 0, left: 0 });
  const markedCellsState = useContext(MarkedCellContext)?.markedCellsState;
  const [resetMarkedCells, setMarkedCells] = markedCellsState
    ? [useResetRecoilState(markedCellsState), useSetRecoilState(markedCellsState)]
    : [noop, noop];
  const sortedFilteredAttributionData = useRecoilValue(sortedFilteredAttributionDataState);
  useOnClickOutside(close, [ref], isOpen);

  const columnIdentifiers = Map.groupBy(
    Array.from(sortedFilteredAttributionData.keys(), (itemId, i) => ({
      rowIndex: i,
      itemId,
      columnId: column.key,
    })),
    ({ columnId }) => columnId
  );

  const calcOffset = () => {
    if (ref.current) {
      const newColumnActionsOffset = getElementCenterAndTopOffset(ref.current);
      setOffset(newColumnActionsOffset);
    }
  };

  const handleOpenerClick = () => {
    toggle();

    if (markedCellsState) {
      resetMarkedCells();

      if (!isOpen) {
        const newMarkedCells = new Map(
          Array.from(columnIdentifiers, ([columnId, items]) => [
            columnId,
            new Set(items.map(({ rowIndex }) => rowIndex)),
          ])
        );

        setMarkedCells(newMarkedCells);
      }
    }
  };

  const parentProps = {
    onMouseEnter: calcOffset,
    ref,
  };

  const markedCells = new Map(
    Array.from(columnIdentifiers, ([columnId, items]) => [
      columnId,
      new Set(items.map(({ itemId }) => itemId)),
    ])
  );

  const element = (
    <div style={offset} className={classNames(styles.columnActions, isOpen && styles.opened)}>
      <div className={styles.opener} onClick={handleOpenerClick} />
      <div className={styles.panel}>
        <AttributionPageColumnActionsContent
          column={column}
          resetMarkedCells={resetMarkedCells}
          markedCells={markedCells}
          close={close}
        />
      </div>
    </div>
  );

  useEffect(calcOffset, [width]);

  return { parentProps, element };
}

export const useModifyCells = (cells: Map<number, Set<number>>) => {
  const modifyCell = useModifyCell();
  const productsAttributes = useRecoilValue(sortedFilteredAttributionDataState);
  const validateCells = () => {
    const actions = Array.from(cells, ([columnId, itemIds]) => {
      return iterator.definedMap(itemIds, itemId => {
        const cell = productsAttributes.get(itemId)?.[columnId];

        if (cell) {
          const { value, isValidated, isEdited, cellType, confidenceLevel } = cell;
          const validationEnabled = value && !isValidated && !isEdited;

          if (validationEnabled) {
            const action: RevisionAction = {
              itemId,
              columnId,
              value,
              oldValue: value,
              cellType: 'validated',
              oldCellType: cellType,
              confidenceLevel: null,
              oldConfidenceLevel: confidenceLevel ?? null,
            };

            return action;
          }
        }
      });
    }).flat();

    void modifyCell(actions);
  };

  return { validateCells };
};
