import React from 'react';
import appStyles from '../layout/App.module.scss';
import styles from './TagTooltip.module.scss';
import classNames from 'classnames';
import { Icon } from 'components/general/Icon';
import { getColorClassById } from 'utils/styleVariables';
import type { AggregateTag } from 'models/AggregateTag';
import { Capsule } from './Capsule';
import { useRecoilValue } from 'recoil';
import { analyticsState } from 'store/analytics';
import { TagTooltipItem } from './TagTooltipItem';
import { useGetTagValues } from 'hooks/useGetTagValues';

type Props = {
  tag: AggregateTag;
  hideTag: (tagId: number) => void;
  ignoredKeys?: (keyof AggregateTag['values'])[];
};

const tagValueKeys: (keyof AggregateTag['values'])[] = [
  'productsCount',
  'brandSignificance',
  'salesGrowth',
  'totalSales',
  'brandsCount',
  'marketShare',
  'customerMarketShare',
  'reviewProductsRatio',
  'listingProductsRatio',
];

export function TagTooltip(props: Props) {
  const { tag, hideTag, ignoredKeys } = props;
  const { id, name, types, dominantType } = tag;
  const analytics = useRecoilValue(analyticsState);
  const colorClassName = getColorClassById(dominantType.colorId);
  const ignoredKeysAsSet = new Set(ignoredKeys);
  const valueKeys = ignoredKeysAsSet.size
    ? tagValueKeys.filter(key => !ignoredKeysAsSet.has(key))
    : tagValueKeys;
  const getTagValues = useGetTagValues(valueKeys);
  const tagValues = getTagValues(tag);

  const trackAndHideTag = () => {
    analytics.track('Hidden tag removed', { name: 'Hide Tag', type: 'button', value: name });
    hideTag(id);
  };

  return (
    <div className={appStyles.verticalFlex}>
      <div className={classNames(styles.header, appStyles.verticalFlex, appStyles.gap1)}>
        <div className={classNames(appStyles.horizontalFlex, appStyles.justifySpaceBetween)}>
          <div className={classNames(appStyles.tag, colorClassName)}>{name}</div>
          <Icon className={styles.icon} weight='light' name='eye-slash' onClick={trackAndHideTag} />
        </div>
        <div
          className={classNames(appStyles.horizontalFlex, appStyles.gap1, appStyles.alignCenter)}
        >
          <Capsule
            className={styles.tagType}
            id={dominantType.id}
            key={dominantType.id}
            text={dominantType.name}
            colorId={dominantType.colorId}
          />
          {types.size > 1 && <div className={styles.tagTypesCount}>{`+${types.size}`}</div>}
        </div>
      </div>
      <div className={classNames(appStyles.verticalFlex, appStyles.gap1, styles.points)}>
        {tagValues.map(item => (
          <TagTooltipItem key={item.key} title={item.title} value={item.value} icon={item.icon} />
        ))}
      </div>
    </div>
  );
}
