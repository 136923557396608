import { comparisonPageAggregateTagsState, tagHashesState } from 'store/tags';
import { useSearchParam } from './useSearchParam';
import { useRecoilValueLoadableState } from './useRecoilValueLoadable';
import { AggregateTag } from 'models/AggregateTag';
import { getTagValueGetter, useTagValueGetter } from './useTagValueGetter';
import { useRecoilValue } from 'recoil';
import { comparisonPageSortFieldState } from 'store/comparisonPage';
import type { TagType } from '../models/TagType';
import { tagTypesState } from 'store/tagTypes';
import type { Config } from 'hooks/useSliderFilters';
import { useSliderFilters } from 'hooks/useSliderFilters';
import { formatters } from 'utils/formatters';
import { vennOrderBySets } from 'components/layout/comparisonPage/venn/utils';
import { getColorizedTags, getIsPriortizedTag } from 'utils/prioritizedTags';
import { useTagTypeActiveIds } from './useTagTypeActiveIds';
import { useCallback } from 'react';
import { tooltipTexts } from 'tooltipTexts';
import { settingsState } from 'store/settings';
import type { SortDirection } from '@harmonya/utils';
import { map, getGrowth, number, iterator } from '@harmonya/utils';
import type { ComparableItem } from 'components/layout/comparisonPage/comparison-page.types';
import type { AggregateTagValue } from '@harmonya/models';

const tagDifferentiationValue = (tag: AggregateTag) => tag.values.brandSignificance;
const getCombinations = <T>(arr: T[]) =>
  (function fn(active: T[], rest: T[], result: T[][]) {
    if (active || rest.length) {
      if (rest.length) {
        const [firstRest, ...otherRest] = rest;

        fn([...active, firstRest], otherRest, result);
        fn(active, otherRest, result);
      } else if (active.length) {
        result.push(active);
      }

      return result;
    }
  })([], arr, []) ?? [];

const getSortedIndexCombinations = (indexCombinations: number[][]) => {
  const orderedIndexCombinations = indexCombinations
    .map(combination => ({
      combination,
      order: vennOrderBySets.findIndex(
        orderSets =>
          orderSets.every(set => combination.includes(set)) &&
          orderSets.length === combination.length
      ),
    }))
    .sort((a, b) => a.order - b.order);

  return orderedIndexCombinations.map(orderedCombinations => orderedCombinations.combination);
};

export const comparePageSliderFiltersConfig: Config<AggregateTagValue>[] = [
  { title: 'Number Of Products', prop: 'productsCount', icon: formatters.productsCount.icon },
  {
    title: formatters.salesGrowth.title,
    prop: 'salesGrowth',
    icon: formatters.salesGrowth.icon,
  },
  { title: formatters.totalSales.title, prop: 'totalSales', icon: formatters.totalSales.icon },
  {
    title: formatters.reviewProductsRatio.title,
    prop: 'reviewProductsRatio',
    icon: formatters.reviewProductsRatio.icon,
    tooltipText: tooltipTexts.explorePage[formatters.reviewProductsRatio.tooltipKey],
  },
  {
    title: formatters.listingProductsRatio.title,
    prop: 'listingProductsRatio',
    icon: formatters.listingProductsRatio.icon,
    tooltipText: tooltipTexts.explorePage[formatters.listingProductsRatio.tooltipKey],
  },
  {
    title: formatters.brandSignificance.title,
    prop: 'brandSignificance',
    icon: formatters.brandSignificance.icon,
  },
];

export const useComparableItems = (differentiationMagnitude: number) => {
  const allTagTypes = useRecoilValueLoadableState<Map<number, TagType>>(tagTypesState);
  const settings = useRecoilValueLoadableState(settingsState);
  const [sortDirection, setSortDirection] = useSearchParam<SortDirection>('sort', {
    defaultValue: 'desc',
  });
  const [activeTagTypeIds, setActiveTagTypeIds] = useTagTypeActiveIds(allTagTypes);
  const setActiveTagTypeIdsAsSet = (newActiveTagTypeIdsAsSet: Set<TagType['id']>) =>
    setActiveTagTypeIds([...newActiveTagTypeIdsAsSet]);
  const [hiddenTagIds, setHiddenTagIds] = useSearchParam<number[]>('hiddenTagIds', {
    defaultValue: [],
    parser: 'number',
  });
  const tagHashes = useRecoilValue(tagHashesState);
  const hiddenTagHashes = iterator.definedMap(hiddenTagIds, id => tagHashes.get(id));
  let minDiff = Infinity;
  let maxDiff = -Infinity;
  const activeTagProp = useRecoilValue(comparisonPageSortFieldState);
  const tagValueGetter = useTagValueGetter(activeTagProp);
  // The API result
  const majorTagsState = useRecoilValueLoadableState(comparisonPageAggregateTagsState);
  const rawSliderFilters = useSliderFilters(
    majorTagsState?.majorEntitiesTags?.flatMap(item => item.allTags) ?? [],
    getTagValueGetter,
    comparePageSliderFiltersConfig
  );
  let comparableItems: (ComparableItem & {
    fullPathParts?: string[];
    compactPathParts?: string[];
  })[] = [];

  const sliderFilters = iterator.definedMap(
    rawSliderFilters.sliderFilters,
    item => item.isFilteredItem
  );
  const sliderFilterFunc = useCallback(
    (testedTags: Iterable<AggregateTag>) =>
      iterator.filter(testedTags, tag => sliderFilters.every(sliderFilter => sliderFilter(tag))),
    [sliderFilters]
  );

  if (majorTagsState && allTagTypes) {
    const { majorEntitiesTags, majorToTags, containedIds } = majorTagsState;
    const tagTypesFilterFunc = (testedTags: AggregateTag[]) =>
      testedTags.filter(
        tag =>
          !activeTagTypeIds.size ||
          iterator.some(tag.types.values(), tagType => activeTagTypeIds.has(tagType.id))
      );

    const addTrimmedItemIds = (
      item:
        | (typeof majorEntitiesTags)[number]
        | Omit<(typeof majorEntitiesTags)[number], 'trimmedItemIds'>
    ) => {
      // In 3 circles, we can have 2 items that are contained in the other one
      // then the trimmed item ids for the common of the two contained items should be the original item ids
      const hasAnyNonContainingItem =
        containedIds.size &&
        item.itemIds.length > 1 &&
        item.itemIds.some(itemId => !containedIds.has(itemId));
      const trimmedItemIds = hasAnyNonContainingItem
        ? item.itemIds.filter(id => !containedIds.has(id))
        : item.itemIds;

      return { ...item, trimmedItemIds };
    };

    const getTagHash = (tag: AggregateTag) => map.safeGet(tagHashes, tag.id);
    const filterBySameName = (allTags: AggregateTag[]) => {
      const isPriortizedTag = getIsPriortizedTag(
        allTags,
        allTagTypes,
        activeTagTypeIds,
        settings?.tagTypePriority,
        getTagHash
      );

      return allTags.filter(tag => isPriortizedTag(tag, getTagHash(tag)));
    };

    const filteredComparableItems = majorEntitiesTags.map(({ allTags, ...items }) =>
      addTrimmedItemIds({
        allTags: sliderFilterFunc(filterBySameName(tagTypesFilterFunc(allTags))),
        ...items,
      })
    );
    const majorItems = filteredComparableItems.filter(item => item.sets.length === 1) ?? [];
    const itemToTagValues = majorItems.map(item => ({
      id: item.itemIds[0],
      tagValues: item.allTags.map(tag => ({ tagId: tag.id, value: tagDifferentiationValue(tag) })),
    }));
    const tagToItemValues = itemToTagValues.reduce(
      (acc, { id, tagValues }) => {
        tagValues.forEach(({ tagId, value }) => {
          if (value) {
            acc[tagId] ??= {};
            acc[tagId][id] = value;
          }
        });

        return acc;
      },
      {} as { [tagId: number]: { [id: string]: number } }
    );

    // Tracking min max diffs
    const differenciateNumbers = (first: number, second: number) => {
      const diff = Math.abs(first / second);

      // track diff
      minDiff = Math.min(diff, minDiff);
      maxDiff = Math.max(diff, maxDiff);

      return diff;
    };

    const selectDiffrentiatedItems = (sortedEntries: [string, number][]) => {
      let selectedItems;
      // Differentiate by the first two items:
      const [[biggestItemId, biggestValue]] = sortedEntries;
      const [, [secondItemId, secondValue]] = sortedEntries;

      // If difference of A and B is bigger than differentiator => select A
      if (differenciateNumbers(biggestValue, secondValue) >= differentiationMagnitude) {
        selectedItems = [biggestItemId];
      }

      if (!selectedItems) {
        if (sortedEntries.length > 2) {
          // Differentiate by the last two items from erlier in the code, A > B but they are not
          // differentiated. If the differenciation of A and C is smaller than the differentiator, select
          // [A, B, C], else [A, B] are differentiated from C
          const [smallestItemId, lastValue] = sortedEntries[sortedEntries.length - 1];

          if (differenciateNumbers(biggestValue, lastValue) <= differentiationMagnitude) {
            selectedItems = [biggestItemId, secondItemId, smallestItemId];
          } else {
            selectedItems = [secondItemId, biggestItemId];
          }
        } else {
          // in 2 items, If difference of A and B is smaller than differentiator => select A,B
          selectedItems = [secondItemId, biggestItemId];
        }
      }

      return selectedItems;
    };

    const tagToSelectedItems = Object.fromEntries(
      iterator
        .definedMap(Object.entries(tagToItemValues), ([tagId, valuesByItem]) => {
          const valueEntries = Object.entries(valuesByItem);
          let selectedItems;

          if (valueEntries.length > 1) {
            const sortedEntriesDesc = [...valueEntries].sort(([, valueEntry1], [, valueEntry2]) =>
              valueEntry2 > valueEntry1 ? 1 : -1
            );

            selectedItems = selectDiffrentiatedItems(sortedEntriesDesc);

            return [tagId, new Set(selectedItems.map(Number))];
          }
          return undefined;
        })
        .filter((entry): entry is [string, Set<number>] => entry !== undefined) // Filters out undefined entries
    );

    const indexes = majorEntitiesTags.map((_, i) => i);
    const indexCombinations = getCombinations(indexes);
    const orderedIndexCombinations = getSortedIndexCombinations(indexCombinations);
    const allItemTags = orderedIndexCombinations.map(combination => {
      const itemTagsCombination = combination.map(index => filteredComparableItems[index]);

      if (itemTagsCombination.length === 1) {
        return itemTagsCombination[0];
      }

      return itemTagsCombination.reduce(({ name, allTags, itemIds }, current) =>
        addTrimmedItemIds({
          name: [name, current.name].join(' & '),
          sets: combination,
          itemIds: [...itemIds, ...current.itemIds],
          allTags: current.allTags.filter(tag => allTags.some(nextTag => nextTag.id === tag.id)),
          differentiatedTags: [],
          compactPathParts: undefined,
          fullPathParts: undefined,
        })
      );
    });
    // aggregate tag values from all the major items on common item tags
    const commonItemTags = allItemTags.filter(itemTags => itemTags?.sets.length > 1);

    commonItemTags.forEach(commonItem => {
      commonItem.allTags = commonItem.allTags.map(tag => {
        const rawTags = commonItem.trimmedItemIds.map(itemId => majorToTags[itemId][tag.id]);
        const firstWeekSales = iterator.sum(rawTags, rawTag => rawTag.rawValues.firstWeekSales);
        const lastWeekSales = iterator.sum(rawTags, rawTag => rawTag.rawValues.lastWeekSales);
        const lastYearTotalSales = iterator.sum(
          rawTags,
          rawTag => rawTag.rawValues.lastYearTotalSales
        );
        const totalSales = iterator.sum(rawTags, rawTag => rawTag.values.totalSales);
        const productsCount = iterator.sum(rawTags, rawTag => rawTag.values.productsCount);
        const reviewProductsCount = iterator.sum(
          rawTags,
          rawTag => rawTag.rawValues.reviewProductsCount
        );
        const listingProductsCount = iterator.sum(
          rawTags,
          rawTag => rawTag.rawValues.listingProductsCount
        );
        const salesGrowth = {
          inPeriod: getGrowth(firstWeekSales, lastWeekSales) ?? 0,
          lastYear: getGrowth(lastYearTotalSales, totalSales) ?? 0,
        };
        const classedTag = new AggregateTag(
          {
            ...rawTags[0],
            values: {
              productsCount,
              totalSales,
              salesGrowth,
              brandSignificance: undefined,
              reviewProductsRatio: number.fixedNumber(reviewProductsCount / productsCount),
              listingProductsRatio: number.fixedNumber(listingProductsCount / productsCount),
              brandsCount: commonItem.trimmedItemIds.length,
              marketShare: NaN,
            },
          },
          allTagTypes,
          { width: tag.width, height: tag.height }
        );

        return classedTag;
      });
    });

    // Map tags to the selected comparedEntity
    const differentiatedComparableItems =
      allItemTags?.map(({ allTags, ...item }) => ({
        ...item,
        allTags,
        differentiatedTags: allTags.filter(
          tag =>
            !tagToSelectedItems[tag.id] ||
            (item.itemIds.every(itemId => tagToSelectedItems[tag.id].has(itemId)) &&
              item.itemIds.length === tagToSelectedItems[tag.id].size)
        ),
      })) ?? [];
    const sorter = (tags: AggregateTag[]) =>
      [...tags].sort(
        (a, b) =>
          ((tagValueGetter(a) ?? 0) - (tagValueGetter(b) ?? 0)) * (sortDirection === 'asc' ? 1 : -1)
      );

    comparableItems = differentiatedComparableItems.map(
      ({ allTags, differentiatedTags, ...item }) => ({
        ...item,
        allTags: getColorizedTags(sorter(allTags), allTagTypes, new Set(activeTagTypeIds)),
        differentiatedTags: getColorizedTags(
          sorter(differentiatedTags),
          allTagTypes,
          new Set(activeTagTypeIds)
        ),
      })
    );
  }

  return {
    rawSliderFilters,
    comparableItems,
    minDifferentiator: minDiff,
    maxDifferentiator: maxDiff,
    sortDirection,
    hiddenTagIds,
    hiddenTagHashes,
    sliderFilterFunc,
    setHiddenTagIds,
    setSortDirection,
    setActiveTagTypeIds: setActiveTagTypeIdsAsSet,
  };
};
