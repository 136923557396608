import type { ComponentProps, MouseEventHandler } from 'react';
import React from 'react';
import classNames from 'classnames';
import styles from './SliderFiltersDropdown.module.scss';
import appStyles from '../../layout/App.module.scss';
import type { IconName } from '../Icon';
import { Icon } from '../Icon';
import { Dropdown } from 'components/general/dropdown/Dropdown';
import type { RangeValue } from '../Slider';
import { Slider } from '../Slider';
import { useRecoilValue } from 'recoil';
import { analyticsState } from 'store/analytics';
import { getToggleEvent } from 'utils/analyticsSelect';
import { Tooltip } from '../Tooltip';
import { join } from '../Divider';

export type SliderFilter<T> = {
  isFilteredItem: ((item: T) => boolean) | undefined;
  reset: () => void;
  textValue: string;
  isDisabled: boolean;
  title: string;
  tooltipText?: string;
  value: RangeValue;
  handleChange: (newValue: RangeValue | undefined) => void;
  formatter: (value?: number) => string | undefined;
  minValue: number;
  maxValue: number;
  isPercent: boolean;
  isSingle?: boolean;
  icon?: ComponentProps<typeof Icon>;
  onTrackEvent?: (range: RangeValue) => void;
};

type Props<T> = {
  sliderFilters: SliderFilter<T>[];
  reset: () => void;
  buttonTitle: string;
  buttonIconName?: IconName;
  className?: string;
} & Omit<ComponentProps<typeof Dropdown>, 'buttonContent' | 'minWidth'>;

export function SliderFiltersDropdown<T>(props: Props<T>) {
  const { sliderFilters, reset, buttonTitle, buttonIconName, className, ...dropdownProps } = props;
  const hasActiveFilter = sliderFilters.some(item => item.isFilteredItem);
  const analytics = useRecoilValue(analyticsState);

  const handleResetFilters: MouseEventHandler = event => {
    // Preventing the dropdown from toggling
    event.stopPropagation();
    reset();
  };

  const filtersButton = (
    <button
      className={classNames(
        appStyles.horizontalFlex,
        appStyles.justifySpaceBetween,
        appStyles.gap3,
        appStyles.buttonBorder,
        appStyles.backgroundBackground,
        appStyles.filter,
        className
      )}
    >
      <div className={classNames(appStyles.horizontalFlex, appStyles.gap1, appStyles.alignCenter)}>
        {buttonIconName && <Icon name={buttonIconName} />}
        <span className={classNames(appStyles.textFont, appStyles.fontWeight500)}>
          {buttonTitle}
        </span>
      </div>
      <div className={classNames(appStyles.horizontalFlex, appStyles.alignCenter, appStyles.gap1)}>
        <Icon
          className={classNames(
            appStyles.smallFont,
            styles.resetFilters,
            !hasActiveFilter && appStyles.transparentHide
          )}
          onClick={handleResetFilters}
          name='xmark'
        />
        <Icon name='angle-down' />
      </div>
    </button>
  );

  const onToggle = () => {
    const toggleEvent = getToggleEvent({ name: buttonTitle });
    const { title: eventTitle, props: eventProps } = toggleEvent;

    if (eventTitle) {
      analytics.track(eventTitle, eventProps);
    }
  };

  return (
    <Dropdown onToggle={onToggle} {...dropdownProps} buttonContent={filtersButton} minWidth={300}>
      <div
        className={classNames(
          appStyles.box,
          appStyles.verticalFlex,
          appStyles.backgroundBackground,
          appStyles.shadowed
        )}
      >
        {join(
          sliderFilters.map(
            ({
              isDisabled,
              title,
              tooltipText,
              value,
              handleChange,
              formatter,
              minValue,
              maxValue,
              isPercent,
              isSingle,
              icon,
            }) => {
              const element = (
                <div
                  key={title}
                  className={classNames(
                    appStyles.verticalFlex,
                    styles.filter,
                    isDisabled && styles.disabled
                  )}
                >
                  <div className={classNames(appStyles.textFont, appStyles.fontWeight500)}>
                    {icon ? (
                      <div
                        className={classNames(
                          appStyles.horizontalFlex,
                          appStyles.alignCenter,
                          appStyles.gap1
                        )}
                      >
                        <Icon {...icon} className={appStyles.mediumFont} />
                        <span className={appStyles.mediumFont}>{title}</span>
                      </div>
                    ) : (
                      title
                    )}
                  </div>
                  <Slider
                    analyticsEventTitle={title}
                    value={value}
                    formatter={formatter}
                    onChange={handleChange}
                    min={minValue}
                    max={maxValue}
                    isPercent={isPercent}
                    isSingle={isSingle}
                    rounded
                    tickMarks={{ count: 4, labelsEnabled: true }}
                  />
                </div>
              );

              return tooltipText ? (
                <Tooltip key={title} content={tooltipText} tooltipClassName={styles.tooltip}>
                  {element}
                </Tooltip>
              ) : (
                element
              );
            }
          ),
          { direction: 'horizontal' }
        )}
      </div>
    </Dropdown>
  );
}
