import { useEffect, useState } from 'react';
import { debouncer } from 'utils/debouncer';

export const useIsScrolling = (elementRef: React.RefObject<Element>, debounce = 50) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const debouncedSetIsScrolling = debouncer(() => setIsScrolling(false), debounce);

  useEffect(() => {
    const scrollElement = elementRef.current;

    if (scrollElement) {
      const abortController = new AbortController();

      const handleScroll = () => {
        setIsScrolling(true);
        debouncedSetIsScrolling();
      };

      scrollElement.addEventListener('scroll', handleScroll, { signal: abortController.signal });

      return () => abortController.abort();
    }
  }, [elementRef.current]);

  return isScrolling;
};
