import type { Falsy } from '@harmonya/utils';
import { iterator, map, set } from '@harmonya/utils';
import type { DependencyList } from 'react';
import { useEffect } from 'react';

type Listener = (event: KeyboardEvent) => void;
type ListenerData = { listener: Listener; stopPropagation?: boolean } | Listener;

const listeners = new Map<Key, Set<ListenerData>>();

window.addEventListener('keydown', event => {
  const keyListeners = listeners.get(event.key as Key);

  if (keyListeners) {
    for (const listenerData of keyListeners) {
      const { listener, stopPropagation } =
        typeof listenerData === 'object'
          ? listenerData
          : { listener: listenerData, stopPropagation: false };

      listener(event);

      if (stopPropagation) {
        break;
      }
    }
  }
});

export const useKey = <T extends Key>(
  callbacks: Record<T, ListenerData | Falsy> | Iterable<readonly [T, ListenerData | Falsy]>,
  deps: DependencyList = []
) => {
  useEffect(() => {
    const iterableCallbacks = iterator.isIterable(callbacks)
      ? callbacks
      : (Object.entries(callbacks) as [Key, ListenerData][]);

    for (const [key, callback] of iterableCallbacks) {
      if (callback) {
        const ensuredCallback: NonNullable<ListenerData> = callback;
        const keyListeners = map.getOrCreateMap(listeners, key, () => new Set());
        const stopPropagationEnabled =
          typeof ensuredCallback === 'object' && ensuredCallback.stopPropagation;

        keyListeners.add(ensuredCallback);

        if (stopPropagationEnabled) {
          const newKeyListeners = set.changeOrder(keyListeners, ensuredCallback, 0);
          listeners.set(key, newKeyListeners);
        }
      }
    }

    return () => {
      for (const [key, callback] of iterableCallbacks) {
        if (callback) {
          listeners.get(key)?.delete(callback);
        }
      }
    };
  }, deps);
};

/** @see https://www.freecodecamp.org/news/javascript-keycode-list-keypress-event-key-codes */
export type Key =
  | 'Backspace'
  | 'Tab'
  | 'Enter'
  | 'Shift'
  | 'Control'
  | 'Alt'
  | 'Pause'
  | 'CapsLock'
  | 'Escape'
  | 'PageUp'
  | 'PageDown'
  | 'End'
  | 'Home'
  | 'ArrowLeft'
  | 'ArrowUp'
  | 'ArrowRight'
  | 'ArrowDown'
  | 'PrintScreen'
  | 'Insert'
  | 'Delete'
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | 'a'
  | 'b'
  | 'c'
  | 'd'
  | 'e'
  | 'f'
  | 'g'
  | 'h'
  | 'i'
  | 'j'
  | 'k'
  | 'l'
  | 'm'
  | 'n'
  | 'o'
  | 'p'
  | 'q'
  | 'r'
  | 's'
  | 't'
  | 'u'
  | 'v'
  | 'w'
  | 'x'
  | 'y'
  | 'z'
  | 'Meta'
  | 'ContextMenu'
  | '*'
  | '+'
  | '-'
  | '.'
  | '/'
  | 'F1'
  | 'F2'
  | 'F3'
  | 'F4'
  | 'F5'
  | 'F6'
  | 'F7'
  | 'F8'
  | 'F9'
  | 'F10'
  | 'F11'
  | 'F12'
  | 'NumLock'
  | 'ScrollLock'
  | 'AudioVolumeMute'
  | 'AudioVolumeDown'
  | 'AudioVolumeUp'
  | 'LaunchMediaPlayer'
  | 'LaunchApplication1'
  | 'LaunchApplication2'
  | ';'
  | '='
  | ','
  | '`'
  | '['
  | '\\'
  | ']'
  | "'";
