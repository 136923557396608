import { categoryNames } from './raw/categoryNames';
import { marketNames } from './raw/marketNames';
import { periodNames } from './raw/periodNames';
import { tagNames } from './raw/tagNames';
import { brandingNames } from './raw/brandingNames';

export const generateNumber = (max: number, min = 0) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const decimelPointNumbers = 1;
const decimelPointFactor = 10 ** decimelPointNumbers;

export const generateFloatNumber = (max: number, min = 0) =>
  generateNumber(max * decimelPointFactor, min * decimelPointFactor) / decimelPointFactor;

export const generateSelectOptions = (map: Map<number, string>) =>
  [...map].map(([id, name]) => ({ id, name }));

export const getRandomFrom = <T>(array: T[], minItems: number, maxItems: number) => {
  const itemsCount = generateNumber(maxItems, minItems);
  const items = [...Array(itemsCount)].map(() => {
    const index = generateNumber(array.length - 1, 0);

    return array[index];
  });

  return [...new Set(items)];
};

function namesToMap<T, V = string>(
  names: T[],
  mapper: (name: T, id: number) => V = name => name as unknown as V
) {
  const entries = names.map((name, i) => [i, mapper(name, i)] as const);
  return new Map(entries);
}

export const tagIdsMap = namesToMap(tagNames);

const levels = [
  {
    id: 1,
    name: 'First Level',
  },
  {
    id: 2,
    name: 'Second Level',
  },
  {
    id: 3,
    name: 'Third Level',
  },
  {
    id: 4,
    name: 'Forth Level',
  },
];

function getHierarchyMap(names: string[]) {
  const namesWithLevels = names.map((name, i) => ({
    id: i,
    levelId: generateNumber(levels.length - 1),
    name,
  }));
  const result = new Map(
    namesWithLevels.map(item => {
      const optionalParentItems = namesWithLevels.filter(
        parent => parent.levelId === item.levelId - 1
      );
      const [parent] = getRandomFrom(optionalParentItems, 1, 1);

      return [
        item.id,
        {
          ...item,
          level: levels[item.levelId],
          ...(!!item.levelId && { parentId: parent.id }),
        },
      ];
    })
  );

  return result;
}

export const categoryIdsMap = getHierarchyMap(categoryNames);

export const marketIdsMap = namesToMap(marketNames);

export const periodIdsMap = namesToMap(periodNames);

export const brandingIdsMap = getHierarchyMap(brandingNames);
