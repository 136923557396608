import React from 'react';
import styles from './AttributionAnalyticsPanelPerformanceCard.module.scss';
import { AttributionAnalyticsPanelCardStat as CardStat } from './AttributionAnalyticsPanelCardStat';
import { AttributionAnalyticsPanelCardPredictions as CardPredictions } from './AttributionAnalyticsPanelCardPredictions';
import { AttributionAnalyticsPanelCardValidatedLabels as CardValidatedLabels } from './AttributionAnalyticsPanelCardValidatedLabels';
import { Divider } from 'components/general/Divider';
import type { AttributePerformance } from 'store/attribution';

type Props = {
  performance: Omit<AttributePerformance, 'key' | 'title'>;
};

export function AttributionAnalyticsPanelPerformanceCard(props: Props) {
  const { accuracy, coverage, predictions, attributeValuesTotal, attributeValidatedValuesTotal } =
    props.performance;
  return (
    <div className={styles.container}>
      <h2>Attribute Performance</h2>
      <div className={styles.cardStats}>
        <CardStat title='Training Accuracy' value={accuracy} />
        <CardStat title='Coverage' value={coverage} />
      </div>
      <Divider direction='horizontal' />
      <CardPredictions {...predictions} />
      <Divider direction='horizontal' />
      <CardValidatedLabels
        total={attributeValuesTotal}
        validatedTotal={attributeValidatedValuesTotal}
      />
    </div>
  );
}
