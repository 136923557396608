import JSZip from 'jszip';
import type { PathName } from './pageName';

type FileSuffix = 'csv' | 'zip';
export type ExportableCsv = {
  name: string;
  data: Record<string, unknown>[];
};

export const getExportFileName = (title: string, pathName: PathName, suffix: FileSuffix) => {
  const dateTimeString = new Date()
    .toJSON()
    .replace('T', '_')
    .replaceAll(':', '-')
    .replace(/\..+/, '');
  const parts = ['Harmonya', pathName, title, dateTimeString];
  const name = `${parts.join('_')}.${suffix}`;

  return name;
};

export const downloadFileInMemory = (
  content: BlobPart | Blob,
  fileName: string,
  type = 'text/plain'
) =>
  // We use the promise to make it easy to work with multiple
  // requests, some of which call for remote APIs
  new Promise(resolve => {
    const blob = content instanceof Blob ? content : new Blob([content], { type });
    const href = URL.createObjectURL(blob);
    const anchorElement = document.createElement('a');

    anchorElement.download = fileName;
    anchorElement.href = href;
    anchorElement.hidden = true;

    document.body.appendChild(anchorElement);

    anchorElement.click();
    anchorElement.remove();

    window.URL.revokeObjectURL(href);

    resolve(undefined);
  });

function convertArrayToCSV(dataArray: object[]): string {
  if (!dataArray.length) {
    return '';
  }

  const headers = Object.keys(dataArray[0]).join(',');
  const rows = dataArray
    .map(obj =>
      Object.values(obj)
        .map(value => `"${value}"`)
        .join(',')
    )
    .join('\n');

  return `${headers}\n${rows}`;
}

const zipFiles = (files: { fileName: string; content: string }[]) => {
  const zip = new JSZip();
  files.forEach(({ content, fileName }) => {
    zip.file(fileName, content);
  });
  return zip.generateAsync({ type: 'blob' });
};

export async function downloadCsvFiles(pathName: PathName, files: ExportableCsv[]) {
  const exportFiles = files.map(({ data, name }) => {
    const content = convertArrayToCSV(data);
    const fileName = getExportFileName(name, pathName, 'csv');
    return { fileName, content };
  });

  if (exportFiles.length === 1) {
    const [{ fileName, content }] = exportFiles;
    await downloadFileInMemory(content, fileName, 'text/csv');
  } else {
    const zipBlob = await zipFiles(exportFiles);
    const zipFileName = getExportFileName('export', pathName, 'zip');
    await downloadFileInMemory(zipBlob, zipFileName, 'application/zip');
  }
}
