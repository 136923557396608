export const flat = <K, V>(maps: Map<K, V>[]) => {
  const computedMap = new Map<K, V>();

  for (const map of maps) {
    for (const [key, value] of map) {
      computedMap.set(key, value);
    }
  }

  return computedMap;
};

export const getFirstEntry = <K, V>(map: Map<K, V>): [K, V] => map.entries().next().value;

export const getFirstKey = <K, V>(map: Map<K, V>): K => getFirstEntry(map)?.[0];

export const getFirstMap = <K, V>(map: Map<K, V>): V => getFirstEntry(map)?.[1];

export const safeGet = <K, V>(map: Map<K, V>, key: K, errorContext?: string): V => {
  if (map.has(key)) {
    return map.get(key) as V;
  }

  throw new Error(`${errorContext ? `${errorContext}: ` : ''}Key ${key} was not found`);
};

export const nullableGet = <K, V>(map: Map<K, V>, key: K | null | undefined): V | undefined => {
  if (key) {
    return map.get(key);
  }
};

export const union = <K, V>(...maps: Map<K, V>[]) => {
  function* generator() {
    for (const map of maps) {
      yield* map;
    }
  }

  const iterable = generator();
  const result = new Map(iterable);

  return result;
};

export function getOrCreateMap<K, V>(map: Map<K, V>, key: K, newValueGetter: () => NonNullable<V>) {
  if (map.has(key)) {
    return map.get(key) as V;
  }

  const newValue = newValueGetter();

  map.set(key, newValue);

  return newValue;
}

export function definedMapValues<K, V, NewV>(
  map: Map<K, V>,
  mapper: (value: V) => NewV
): Map<K, NewV> {
  const newMap = new Map<K, NewV>();

  for (const [key, value] of map) {
    const mappedValue = mapper(value);

    if (mappedValue !== undefined) {
      newMap.set(key, mappedValue);
    }
  }

  return newMap;
}

export const changeOrder = <K, V>(source: Map<K, V>, key: K, index: number): Map<K, V> => {
  const target = new Map(source);
  const value = safeGet(target, key);

  target.delete(key);

  const targetAsArray = [...target];

  targetAsArray.splice(index, 0, [key, value]);

  return new Map(targetAsArray);
};

export function sort<K, V>(map: Map<K, V>, comparator: (a: V, b: V) => number): Map<K, V> {
  const sortedEntries = [...map].sort((a, b) => comparator(a[1], b[1]));

  return new Map(sortedEntries);
}

export type Key<T> = T extends Map<infer K, unknown> ? K : never;
export type Value<T> = T extends Map<unknown, infer V> ? V : never;
