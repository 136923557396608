import classNames from 'classnames';
import type { ComponentType } from 'react';
import React, { memo, useCallback } from 'react';
import appStyles from '../../layout/App.module.scss';
import type { Column } from './DataTableBase';
import styles from './DataTableHeader.module.scss';
import { DataTableHeaderCell } from './DataTableHeaderCell';
import { DataTablePinCells } from './DataTablePinCells';

type Props<T extends Column> = {
  columns: T[];
  HeaderCellComponent: ComponentType<{ column: T; width?: number }>;
};

export const DataTableHeader = memo(function DataTableHeader<T extends Column>(props: Props<T>) {
  const { columns, HeaderCellComponent } = props;

  const cellRenderer = useCallback(
    (column: T) => (
      <DataTableHeaderCell column={column} HeaderCellComponent={HeaderCellComponent} />
    ),
    [HeaderCellComponent]
  );

  return (
    <div className={classNames(appStyles.horizontalFlex, appStyles.gap0, styles.header)}>
      <DataTablePinCells columns={columns}>{cellRenderer}</DataTablePinCells>
    </div>
  );
});
