import React, { useCallback } from 'react';
import classNames from 'classnames';
import appStyles from '../App.module.scss';
import { TagsVenn } from './venn/TagsVenn';
import { useRecoilState, useRecoilValue } from 'recoil';
import { comparisonPageComperableItemIdsState } from 'store/comparisonPage';
import { useComparableItems } from 'hooks/useComparableItems';
import { tagHashesState, tagsState, tagsWidthCache } from 'store/tags';
import { brandingsState, sortedFlattenTreeBrandingState } from 'store/branding';
import type { AggregateTag } from 'models/AggregateTag';
import { map, array, iterator } from '@harmonya/utils';
import type { ComparableItem } from 'components/layout/comparisonPage/comparison-page.types';

const generateLoadingTag = (id: number, name: string): AggregateTag & { isLoading: boolean } => {
  const { width, height } = tagsWidthCache.get(name) ?? { width: 269.295, height: 26 };

  return {
    id,
    height,
    name,
    width,
    hash: '',
    values: {
      productsCount: NaN,
      brandSignificance: NaN,
      salesGrowth: { inPeriod: NaN },
      totalSales: NaN,
      brandsCount: NaN,
      marketShare: NaN,
      customerMarketShare: NaN,
      reviewProductsRatio: NaN,
      listingProductsRatio: NaN,
    },
    types: new Map(),
    dominantType: { colorId: 1, name: '', id: 0 },
    isLoading: true,
  };
};

type Props = {
  focusIndex?: number;
  onClick: (index?: number) => void;
  itemName: string;
  differentiationMagnitude: number;
  width: string;
};

export function ComparisonPageDiagramChart(props: Props) {
  const { focusIndex, onClick, itemName, differentiationMagnitude, width } = props;
  const { comparableItems, hiddenTagIds, hiddenTagHashes, setHiddenTagIds } =
    useComparableItems(differentiationMagnitude);
  const rawComparableItems = useRecoilValue(brandingsState);
  const tags = useRecoilValue(tagsState);
  const tagHashes = useRecoilValue(tagHashesState);
  const optionItems = useRecoilValue(sortedFlattenTreeBrandingState);
  const [comperableItemIds, setComperableItemIds] = useRecoilState(
    comparisonPageComperableItemIdsState
  );
  const onItemSelect = useCallback(
    (itemId: number, index: number) => {
      const newComperableItemIds = [...comperableItemIds];

      newComperableItemIds.splice(index, 1, itemId);

      setComperableItemIds(newComperableItemIds);
    },
    [comperableItemIds]
  );
  const toggleTagVisibility = useCallback(
    (tagId: number) => {
      const newHiddenTagIds = array.toggle(hiddenTagIds, tagId);

      setHiddenTagIds(newHiddenTagIds);
    },
    [hiddenTagIds]
  );
  const loadingComperableItems = comperableItemIds.flatMap((id, index) => {
    const hasComparableItem = comparableItems.some(
      item => item.itemIds.length === 1 && item.itemIds[0] === id
    );
    const result = [];

    if (!hasComparableItem) {
      if (index === 1) {
        result.push({
          name: [0, 1].toString(),
          sets: [0, 1],
          itemIds: [comperableItemIds[0], id],
          trimmedItemIds: [comperableItemIds[0], id],
        });
      }

      if (index === 2) {
        result.push(
          {
            name: [0, 2].toString(),
            sets: [0, 2],
            itemIds: [comperableItemIds[0], id],
            trimmedItemIds: [comperableItemIds[0], id],
          },
          {
            name: [0, 1, 2].toString(),
            sets: [0, 1, 2],
            itemIds: [comperableItemIds[0], id],
            trimmedItemIds: [comperableItemIds[0], id],
          },
          {
            name: [1, 2].toString(),
            sets: [1, 2],
            itemIds: [comperableItemIds[0], id],
            trimmedItemIds: [comperableItemIds[0], id],
          }
        );
      }

      const entity = rawComparableItems.get(id);

      result.push({
        name: entity?.name ?? id.toString(),
        sets: [index],
        itemIds: [id],
        trimmedItemIds: [id],
      });
    }

    if (result.length > 0) {
      const tagValues = tags.values();
      const loadingTags = iterator.definedMap(Array(30), (_, i) => {
        const tag = iterator.elementAt(tagValues, i % tags.size);
        return tag ? generateLoadingTag(tag.id, tag.name) : undefined;
      });

      return result.map(item => ({
        ...item,
        differentiatedTags: loadingTags,
        allTags: [],
      }));
    }

    return [];
  });
  const comparableWithLoadingItems = [...comparableItems, ...loadingComperableItems];

  return (
    <div
      style={{ width }}
      className={classNames(appStyles.box, appStyles.verticalFlex, appStyles.alignCenter)}
    >
      <TagsVenn
        onButtonOptionSelect={onItemSelect}
        buttonOptions={optionItems}
        buttonValues={comperableItemIds}
        items={comparableWithLoadingItems as ComparableItem[]}
        focusIndex={focusIndex}
        onClick={onClick}
        differentiationMagnitude={differentiationMagnitude}
        firstCirclePlaceholder={`Select An ${itemName} To Compare`}
        secondCirclePlaceholder={`Select Another ${itemName} To Compare`}
        hiddenTagIds={hiddenTagIds}
        hiddenTagHashes={hiddenTagHashes as string[]}
        getTagHash={tagId => map.safeGet(tagHashes, tagId)}
        setHiddenTagIds={setHiddenTagIds}
        toggleTagVisibility={toggleTagVisibility}
        reflowIndicator={width}
      />
    </div>
  );
}
