export const categoryNames = [
  'SALTY SNACKS',
  'AK HI PR AND OTHER DSD GROCERY',
  'COOKIES AND CRACKERS',
  'SHELF STABLE JUICE',
  'CSD',
  'LUNCHBOX SNACKS',
  'PROTEIN SNACKS',
  'CHILLED BEVERAGES',
  'WATER',
  'NON ALC MIXERS',
  'TEA AND COFFEE',
  'HYDRATION BEV',
  'DEPT LEVEL SALES',
  'ALT BEVERAGE-ISOTONICS',
  'AK HI PR AND OTHER GROCERY',
  'STATE FEES',
  'DOTCOM',
  'LIQUOR & MIXES',
  'GLASSWARE',
  'PERISHABLE WRAP',
  'ENERGY MANAGEMENT',
  'STORE SUPPLIES',
  'SUPPLIES1',
  'INCUBATION SET',
  'SPECIALTY FOODS',
  'PR CATEGORIES',
  'LATIN FOODS',
  'FRESH BREAD AND DONUTS',
  'LUNCH',
  'DRINKS',
  'SNACKS AND BEVERAGES MISC L2',
  'DRIED FRUIT NUTS SNACKS',
  'BASIC CANDY',
];
