import type { Key, ReactNode } from 'react';
import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import appStyles from '../../layout/App.module.scss';
import styles from './DiamondCloud.module.scss';
import { DiamondCloudItem } from './DiamondCloudItem';
import { useBoundingClientRect } from '../../../hooks/useBoundingClientRect';
import { Tooltip } from '../Tooltip';
import { useDebouncedEffect } from 'hooks/useDebouncedEffect';

export interface Item {
  name: string;
}

export interface Point {
  x: number;
  y: number;
}

interface Rect {
  topLeft: Point;
  topRight: Point;
  bottomLeft: Point;
  bottomRight: Point;
}

export interface ComputedItem<T extends Item> {
  value: number;
  size: number;
  hypotenuse: number;
  fontSize: number;
  rect: Rect;
  gappedRect: Rect;
  rotatedTopLeft: Point;
  source: T;
}

interface Container {
  rect: Rect;
  center: Point;
  relativeCenter: Point;
}

type PointCalculator = ({ x, y }: Point, size: number, offset: number, sizeOffset: number) => Point;

type Corner = keyof Rect;

type Axis = keyof Point;

const degreesToRadians = (degrees: number) => (degrees * Math.PI) / 180;

const getSizes = (
  sizesCount: number,
  sizeIncrementFactor: number,
  gap: number,
  minSize: number
) => {
  let previous = minSize;
  const sizes = [previous];

  for (let i = 1; i < sizesCount; i++) {
    previous = previous * sizeIncrementFactor + gap;

    sizes.push(previous);
  }

  return sizes;
};

const getPointCalculators = (gap: number) =>
  new Map<Corner, PointCalculator>([
    [
      'topLeft',
      ({ x, y }, size, offset) => ({
        x: x + offset,
        y: y - size - gap,
      }),
    ],
    [
      'bottomRight',
      ({ x, y }, size, offset) => ({
        x: x - size - offset,
        y: y + gap,
      }),
    ],
    [
      'topRight',
      ({ x, y }, size, offset) => ({
        x: x + gap,
        y: y + offset,
      }),
    ],
    [
      'bottomLeft',
      ({ x, y }, size, offset, sizeOffset) => ({
        x: x - size - gap,
        y: y - sizeOffset,
      }),
    ],
  ]);
const getRect = (topLeftX: number, topLeftY: number, width: number, height = width): Rect => ({
  topLeft: { x: topLeftX, y: topLeftY },
  topRight: { x: topLeftX + width, y: topLeftY },
  bottomLeft: { x: topLeftX, y: topLeftY + height },
  bottomRight: { x: topLeftX + width, y: topLeftY + height },
});

const getContainer = (containerElement: Element) => {
  const { x, y, width, height } = containerElement.getBoundingClientRect();
  const rect = getRect(x, y, width, height);
  const relativeCenter: Point = { x: width / 2, y: height / 2 };
  const center: Point = { x: x + relativeCenter.x, y: y + relativeCenter.y };

  return { rect, relativeCenter, center };
};

const getDistance = (a: Point, b: Point) => {
  const x = a.x - b.x;
  const y = a.y - b.y;
  const distance = Math.sqrt(x * x + y * y);

  return distance;
};

const getRotatedPoint = (point: Point, rotateRadians: number, containerCenter: Point) => {
  const angleRadians =
    Math.atan2(point.y - containerCenter.y, point.x - containerCenter.x) + rotateRadians;
  const distanceFromCenter = getDistance(point, containerCenter);
  const x = distanceFromCenter * Math.cos(angleRadians) + containerCenter.x;
  const y = distanceFromCenter * Math.sin(angleRadians) + containerCenter.y;

  return { x, y };
};

const join = (a: Point, b: Point): Point => ({
  x: a.x + b.x,
  y: a.y + b.y,
});

const getTopLeftBasedOnItem = <T extends Item>(
  baseItem: ComputedItem<T>,
  corner: Corner,
  useStartEdge: boolean,
  size: number,
  pointCalculator: PointCalculator
) => {
  const { size: baseItemSize, rect: baseItemRect } = baseItem;
  const offset = +!useStartEdge * (baseItemSize - size);
  const sizeOffset = useStartEdge ? size : baseItemSize; // A better name is needed
  const basePoint = baseItemRect[corner];
  const topLeft = pointCalculator(basePoint, size, offset, sizeOffset);

  return topLeft;
};

const isOutsideRect = ({ x, y }: Point, rectTopLeft: Point, rectBottomRight: Point) =>
  x < rectTopLeft.x || y < rectTopLeft.y || x > rectBottomRight.x || y > rectBottomRight.y;

const getGappedRect = (gap: number, { topLeft, topRight, bottomLeft, bottomRight }: Rect): Rect => {
  const insideGap = gap - 1;

  return {
    topLeft: { x: topLeft.x - insideGap, y: topLeft.y - insideGap },
    topRight: { x: topRight.x + insideGap, y: topRight.y - insideGap },
    bottomLeft: { x: bottomLeft.x - insideGap, y: bottomLeft.y + insideGap },
    bottomRight: { x: bottomRight.x + insideGap, y: bottomRight.y + insideGap },
  };
};

const hasGap = (rectA: Rect, rectB: Rect, axis: Axis) =>
  rectA.topLeft[axis] > rectB.bottomRight[axis] || rectB.topLeft[axis] > rectA.bottomRight[axis];

const isAvailableArea = <T extends Item>(
  computedItems: ComputedItem<T>[],
  rect: Rect,
  rotateRadians: number,
  containerTopLeft: Point,
  containerBottomRight: Point,
  containerCenter: Point
) => {
  let isAvailable = true;
  const isOutside = Object.values(rect).some(point => {
    const absolutePoint = join(point, containerTopLeft);
    const rotatedTopLeft = getRotatedPoint(absolutePoint, rotateRadians, containerCenter);

    return isOutsideRect(rotatedTopLeft, containerTopLeft, containerBottomRight);
  });

  if (isOutside) {
    isAvailable = false;
  } else {
    for (const item of computedItems) {
      const hasHorizontalGap = hasGap(rect, item.gappedRect, 'x');

      if (!hasHorizontalGap) {
        const hasVerticalGap = hasGap(rect, item.gappedRect, 'y');

        if (!hasVerticalGap) {
          isAvailable = false;
          break;
        }
      }
    }
  }

  return isAvailable;
};

const getMostCentralRect = <T extends Item>(
  computedItems: ComputedItem<T>[],
  pointCalculators: Map<Corner, PointCalculator>,
  container: Container,
  rotateRadians: number,
  size: number
) => {
  const isFirstItem = !computedItems.length;
  const booleanOptions = [true, false];
  let mostCentralRect;

  if (isFirstItem) {
    const itemHalfSize = size / 2;
    const topLeftX = container.relativeCenter.x - itemHalfSize;
    const topLeftY = container.relativeCenter.y - itemHalfSize;
    const rect = getRect(topLeftX, topLeftY, size);
    const isAvailable = isAvailableArea(
      computedItems,
      rect,
      rotateRadians,
      container.rect.topLeft,
      container.rect.bottomRight,
      container.center
    );

    if (isAvailable) {
      mostCentralRect = rect;
    }
  } else {
    let mostCentralDistance = Infinity;

    const setMostCentral = (
      computedItem: ComputedItem<T>,
      corner: Corner,
      useStartEdge: boolean,
      pointCalculator: PointCalculator
    ) => {
      const { x, y } = getTopLeftBasedOnItem(
        computedItem,
        corner,
        useStartEdge,
        size,
        pointCalculator
      );
      const rect = getRect(x, y, size);
      const currentMostCentralDistance = Object.values(rect).reduce(
        (min, point) => Math.min(min, getDistance(point, container.relativeCenter)),
        Infinity
      );
      const isCloser = currentMostCentralDistance < mostCentralDistance;
      const isCloserAndAvailable =
        isCloser &&
        isAvailableArea(
          computedItems,
          rect,
          rotateRadians,
          container.rect.topLeft,
          container.rect.bottomRight,
          container.center
        );

      if (isCloserAndAvailable) {
        mostCentralDistance = currentMostCentralDistance;
        mostCentralRect = rect;
      }
    };

    for (const [corner, pointCalculator] of pointCalculators) {
      if (!pointCalculator) {
        throw new Error(`Missed point calculator for ${corner}`);
      }

      for (const computedItem of computedItems) {
        for (const useStartEdge of booleanOptions) {
          setMostCentral(computedItem, corner, useStartEdge, pointCalculator);
        }
      }
    }
  }

  return mostCentralRect;
};

function getMostEffectiveComputedItems<T extends Item>(
  props: Props<T>,
  containerElement: Element
): ComputedItem<T>[] {
  let computedItems: ComputedItem<T>[] = [];
  // Binary search
  let low = 0;
  const { width, height } = containerElement.getBoundingClientRect();
  let high = Math.min(width, height);

  while (low <= high) {
    const middle = Math.floor((low + high) / 2);
    const midComputedItems = getComputedItems(props, middle, containerElement);

    if (midComputedItems.length) {
      computedItems = midComputedItems;
      // Search in the right half
      low = middle + 1;
    } else {
      // Search in the left half
      high = middle - 1;
    }
  }

  return computedItems;
}

function getComputedItems<T extends Item>(
  props: Props<T>,
  minSize: number,
  containerElement: Element
): ComputedItem<T>[] {
  const { items, sizesCount, sizeIncrementFactor, gap, rotateDegrees, valueGetter } = props;
  const computedItems: ComputedItem<T>[] = [];

  if (items.length) {
    const rotateRadians = degreesToRadians(rotateDegrees);
    const sizes = getSizes(sizesCount, sizeIncrementFactor, gap, minSize);
    const fontSizes = sizes.map(itemSize => Math.sqrt(itemSize) * 1.5);
    const smallestSize = sizes[sizesCount - 1];
    const smallestFontSize = fontSizes[sizesCount - 1];
    const maxValue = valueGetter(items[0]) ?? NaN;
    const minValue = valueGetter(items[items.length - 1]) ?? NaN;
    const valuesRange = maxValue - minValue;
    const pointCalculators = getPointCalculators(gap);
    const container = getContainer(containerElement);

    for (const item of items) {
      const value = valueGetter(item) ?? NaN;
      const percentageValue = valuesRange ? (value - minValue) / valuesRange : 1;
      const itemSizeIndex = Math.max(0, Math.ceil(sizesCount * percentageValue) - 1);
      const itemSize = sizes[itemSizeIndex] ?? smallestSize;
      const rect = getMostCentralRect(
        computedItems,
        pointCalculators,
        container,
        rotateRadians,
        itemSize
      );

      if (rect) {
        const gappedRect = getGappedRect(gap, rect);
        const rotatedTopLeft = getRotatedPoint(
          rect.topLeft,
          rotateRadians,
          container.relativeCenter
        );
        const hypotenuse = Math.sqrt(itemSize ** 2 / 2) * 2;
        const fontSize = fontSizes[itemSizeIndex] ?? smallestFontSize;
        const computedItem: ComputedItem<T> = {
          source: item,
          size: itemSize,
          value,
          hypotenuse,
          fontSize,
          rect,
          gappedRect,
          rotatedTopLeft,
        };

        computedItems.push(computedItem);
      } else {
        return [];
      }
    }
  }

  return computedItems;
}

type Props<T extends Item> = {
  items: T[];
  sizesCount: number;
  sizeIncrementFactor: number;
  gap: number;
  rotateDegrees: number;
  keyGetter: (name: string, index: number) => Key;
  valueGetter: (item: T) => number | null;
  displayValueGetter: (item: ComputedItem<T>) => ReactNode;
  tooltipContentGetter: (item: ComputedItem<T>) => ReactNode;
  getColorId: (item: ComputedItem<T>) => number;
  getSubtext: (item: ComputedItem<T>) => string;
  onItemClick?: (item: ComputedItem<T>) => void;
};

export function DiamondCloud<T extends Item>(props: Props<T>) {
  const {
    items,
    sizesCount,
    sizeIncrementFactor,
    gap,
    rotateDegrees,
    keyGetter,
    displayValueGetter,
    tooltipContentGetter,
    getColorId,
    onItemClick,
    getSubtext,
  } = props;
  const containerElementRef = useRef<SVGSVGElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const dimensions = useBoundingClientRect(containerRef, 'dimensions');
  const [computedItems, setComputedItems] = useState<ComputedItem<T>[]>([]);

  const updateData = () => {
    const containerElement = containerElementRef.current;

    if (containerElement) {
      const { width, height } = containerElement.getBoundingClientRect();

      if (width || height) {
        const newComputedItems = getMostEffectiveComputedItems(props, containerElementRef.current);

        setComputedItems(newComputedItems);
      }
    }
  };

  useDebouncedEffect(() => {
    updateData();
  }, [
    items,
    sizesCount,
    sizeIncrementFactor,
    gap,
    rotateDegrees,
    dimensions?.width,
    dimensions?.height,
  ]);

  return (
    <div className={classNames(appStyles.positionRelative, styles.fit)} ref={containerRef}>
      <svg
        className={styles.fit}
        style={{ '--rotate': `${rotateDegrees}deg` } as React.CSSProperties}
        ref={containerElementRef}
      >
        {computedItems.map((item, i) => (
          <Tooltip
            key={keyGetter(item.source.name, i)}
            content={tooltipContentGetter(item)}
            isSvg
            isHoverable
          >
            <DiamondCloudItem
              item={item}
              displayValueGetter={displayValueGetter}
              onClick={onItemClick}
              getColorId={getColorId}
              getSubtext={getSubtext}
            />
          </Tooltip>
        ))}
      </svg>
    </div>
  );
}
