import { string } from '@harmonya/utils';
import React, { memo, useCallback } from 'react';
import type { Column } from './DataTableBase';
import type { PropsWithCellComponent } from './DataTableCell';
import { DataTableCell } from './DataTableCell';
import { DataTablePinCells } from './DataTablePinCells';
import styles from './DataTableRow.module.scss';

type Props = PropsWithCellComponent<{
  topOffset: number;
  columns: Column[];
  itemId: number;
  index: number;
  lastRowIndex: number;
}>;

export const DataTableRow = memo(function DataTableRow(props: Props) {
  const { topOffset, columns, itemId, index, lastRowIndex, isDisabledColumnState, CellComponent } =
    props;
  const displayedIndex = index + 1;
  const style = { transform: `translateY(${string.pixelize(topOffset)})` };

  const cellRenderer = useCallback(
    (column: Column, nextColumn?: Column, prevColumn?: Column) => (
      <DataTableCell
        columnId={column.key}
        itemId={itemId}
        nextColumnId={nextColumn?.key}
        prevColumnId={prevColumn?.key}
        rowIndex={index}
        lastRowIndex={lastRowIndex}
        CellComponent={CellComponent}
        isDisabledColumnState={isDisabledColumnState}
      />
    ),
    [itemId, index, lastRowIndex, CellComponent]
  );

  return (
    <div className={styles.row} style={style}>
      <DataTablePinCells columns={columns} index={displayedIndex}>
        {cellRenderer}
      </DataTablePinCells>
    </div>
  );
});
