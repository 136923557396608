import React from 'react';
import styles from './AttributionHeaderCellFiltersSortSection.module.scss';
import type { ColumnId } from 'store/attribution.types';
import { sortState, sortOptions } from 'store/attributionPage';
import { useRecoilState } from 'recoil';
import { AttributionHeaderCellFiltersSection } from './AttributionHeaderCellFiltersSection';
import classNames from 'classnames';
import { Icon } from 'components/general/Icon';
import { ButtonGroup } from 'components/general/ButtonGroup';
import type { SortDirection } from '@harmonya/utils';
import { tooltipTexts } from 'tooltipTexts';

type Props = {
  columnId: ColumnId;
};

export function AttributionHeaderCellFiltersSortSection(props: Props) {
  const { columnId } = props;
  const [sort, setSort] = useRecoilState(sortState);

  return (
    <AttributionHeaderCellFiltersSection title='Sort By'>
      {sortOptions.map(option => {
        const isActive = sort.columnId === columnId && sort.option === option;
        const changeSortDirection = (direction: SortDirection) => {
          if (!isActive || sort.direction !== direction) {
            setSort({ columnId, option, direction });
          }
        };

        return (
          <div
            key={option}
            className={classNames(styles.item, isActive && styles.active)}
            onClick={() => setSort({ columnId, option, direction: 'asc' })}
          >
            {option}
            <ButtonGroup
              className={styles.sortDirectionButtonGroup}
              selectedValue={sort.direction}
              onClick={changeSortDirection}
            >
              {[
                {
                  value: 'desc',
                  element: <Icon name='arrow-down-wide-short' weight='light' />,
                  tooltip: tooltipTexts.common.descSort,
                },
                {
                  value: 'asc',
                  element: <Icon name='arrow-up-short-wide' weight='light' />,
                  tooltip: tooltipTexts.common.ascSort,
                },
              ]}
            </ButtonGroup>
          </div>
        );
      })}
    </AttributionHeaderCellFiltersSection>
  );
}
