import React from 'react';
import { Tag } from 'components/general/Tag';
import styles from './TagsRow.module.scss';
import appStyles from '../../../layout/App.module.scss';
import { Tooltip } from 'components/general/Tooltip';
import classNames from 'classnames';
import { TagTooltip } from 'components/general/TagTooltip';
import { ignoredTagTooltipProps } from 'components/layout/comparisonPage/comparison-page.constants';
import type { Props } from 'components/layout/comparisonPage/venn/venn.types';

export function TagsRow(props: Props) {
  const { tags, top, left, width, tagClassName, isMajor, hideTag } = props;

  return (
    <div
      key={tags.map(tag => tag.name).join('|')}
      className={classNames(
        appStyles.animatedFadeIn,
        styles.dataLabelRow,
        isMajor && styles.majorSpacing
      )}
      style={{ top, left, width }}
    >
      {tags.map(tag => (
        <Tooltip
          key={tag.id}
          isHoverable
          className={styles.tooltip}
          content={<TagTooltip tag={tag} hideTag={hideTag} ignoredKeys={ignoredTagTooltipProps} />}
        >
          <Tag
            className={tagClassName}
            name={tag.name}
            colorId={tag.dominantType?.colorId}
            isLoading={tag.isLoading}
          />
        </Tooltip>
      ))}
    </div>
  );
}
