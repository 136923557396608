import React from 'react';
import type { OptionsHook } from 'components/general/ExportButton';
import { ExportButton } from 'components/general/ExportButton';
import { useComparableItems } from 'hooks/useComparableItems';
import { useComparePageConfigurationExportContent } from 'hooks/useConfigurationExportContent';
import { unifyTagFileParts, useTagsExportContent } from 'hooks/useTagsExportContent';
import type { AggregateTag } from 'models/AggregateTag';
import { useRecoilValue } from 'recoil';
import { tagHashesState } from 'store/tags';
import { map } from '@harmonya/utils';

function getOptionHook(differentiationMagnitude: number): OptionsHook {
  return () => {
    const tagHashes = useRecoilValue(tagHashesState);
    const { comparableItems, hiddenTagHashes } = useComparableItems(differentiationMagnitude);
    const hiddenTagHashesAsSet = new Set(hiddenTagHashes);
    const configurationExportContent = useComparePageConfigurationExportContent();
    const isCompared = comparableItems?.length > 1;
    const { getTagsExport } = useTagsExportContent(['customerMarketShare']);
    const filterHiddenTag = (tagList: AggregateTag[]) =>
      tagList.filter(tag => !hiddenTagHashesAsSet.has(map.safeGet(tagHashes, tag.id)));
    const itemsRaw = comparableItems?.filter(item => item.itemIds?.length === 1);
    const allTagById = new Map(itemsRaw.flatMap(item => item.allTags.map(tag => [tag.id, tag])));
    const sharedItemsRaw = comparableItems?.filter(item => item.itemIds?.length > 1);

    // Add market share to shared items
    const sharedItemsWithMarketShare = sharedItemsRaw.map(item => ({
      ...item,
      differentiatedTags: filterHiddenTag(item.differentiatedTags).map(
        tag =>
          ({
            ...tag,
            values: {
              ...tag.values,
              marketShare: allTagById.get(tag.id)?.values?.marketShare,
            },
          }) as AggregateTag
      ),
    }));

    const itemsTagFileParts = itemsRaw.map(item =>
      getTagsExport(filterHiddenTag(item.allTags), item.name)
    );
    const sharedItemsTagFileParts = sharedItemsWithMarketShare.map(item =>
      getTagsExport(item.differentiatedTags, item.name)
    );

    const options = [
      {
        key: 'configuration',
        title: 'Configuration',
        fileContent: configurationExportContent,
      },
      {
        key: 'shared-tags',
        title: 'Shared Tags',
        fileContent: unifyTagFileParts(sharedItemsTagFileParts),
      },
      {
        key: 'all-tags',
        title: 'All Tags',
        fileContent: unifyTagFileParts(itemsTagFileParts),
      },
      {
        key: 'differentiated-tags',
        title: 'Differentiated Tags',
        children: itemsRaw.map(({ name, differentiatedTags }) => ({
          key: `differentiated-tags_${name}`,
          title: name,
          fileContent: getTagsExport(filterHiddenTag(differentiatedTags)),
        })),
      },
    ];

    return {
      options,
      isAllDisabled: !isCompared || !allTagById.size,
      ...(!isCompared && { disabledTooltip: 'exportSelectEntitiesWarning' }),
    };
  };
}

type Props = {
  differentiationMagnitude: number;
};

export function ComparisonPageExportButton(props: Props) {
  const { differentiationMagnitude } = props;
  const optionsHook = getOptionHook(differentiationMagnitude);

  return <ExportButton useOptions={optionsHook} pageKey='comparison' minWidth={250} />;
}
