export const caretAtStart = (element: HTMLInputElement): boolean =>
  element.selectionStart === element.selectionEnd && !element.selectionStart;

export const caretAtEnd = (element: HTMLInputElement): boolean =>
  element.selectionStart === element.selectionEnd && element.value.length === element.selectionEnd;

export const hasEllipsis = (element: Element): boolean =>
  element instanceof HTMLElement && element.offsetWidth < element.scrollWidth;

export function getElementPosition(element: HTMLElement) {
  let top = 0;
  let left = 0;
  let currentElement = element;

  while (currentElement) {
    const { offsetTop, offsetLeft, offsetParent } = currentElement;

    top += offsetTop;
    left += offsetLeft;
    currentElement = offsetParent as HTMLElement;
  }

  let parent = element.parentElement;

  while (parent) {
    const { tagName, scrollTop, scrollLeft, parentElement } = parent;

    if (tagName !== 'BODY') {
      top -= scrollTop;
      left -= scrollLeft;
    }

    parent = parentElement;
  }

  return { top, left };
}

export function getElementCenterAndTopOffset(element: HTMLElement) {
  const { top, left } = getElementPosition(element);
  const { width } = element.getBoundingClientRect();
  const horizontalCenter = left + width / 2;

  return {
    top,
    left: horizontalCenter,
  };
}
