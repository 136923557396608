import type { TagProp } from 'components/layout/comparisonPage/comparison-page.types';

export const tagProps: TagProp[] = [
  'totalSales',
  'productsCount',
  'salesGrowth',
  'brandSignificance',
  'marketShare',
  'customerMarketShare',
  'reviewProductsRatio',
  'listingProductsRatio',
];
export const differentiationMagnitudeProp = 'differentiationMagnitude';

export const tagDetailsExcludedProps: TagProp[] = ['customerMarketShare'];

export const ignoredTagTooltipProps: TagProp[] = ['customerMarketShare', 'marketShare'];

export const tabNames = ['Differentiated Tags', 'Shared Tags', 'All Tags'] as const;

export const getTooltipContentByTabName = (diffrentiator: number, brandNames: string[]) => ({
  'All Tags': 'Explore all tags that were associated with the selected brands',
  'Differentiated Tags': `Explore the tags that have been identified as differentiated tags because they exhibit more than ${diffrentiator} times greater significance compared to the brand being compared`,
  'Shared Tags': `Explore shared tags that were found similarly significant within the ${brandNames.length ? brandNames.join(' and ') : 'selected brands'}`,
});
