import type { ComponentProps, MouseEventHandler } from 'react';
import React, { forwardRef } from 'react';
import styles from './AttributionCellContent.module.scss';
import { AttributionCellConfidenceLevel as ConfidenceLevel } from './AttributionCellConfidenceLevel';
import { AttributionCellValidationButton as ValidationButton } from './AttributionCellValidationButton';
import type { ProductAttributeContent } from 'store/attribution.types';
import { emptyValueText } from 'store/attribution';
import classNames from 'classnames';

type Props = Omit<ComponentProps<'div'>, 'content'> & {
  content: ProductAttributeContent;
  isDisabled?: boolean;
  isMarked?: boolean;
  onValidate?: () => void;
  contextMenuHandler: MouseEventHandler;
};

export const AttributionCellContent = forwardRef<HTMLDivElement, Props>(
  function AttributionCellContent(props, ref) {
    const {
      content: { value, confidenceLevel, isValidated, isEdited, cellType },
      isDisabled,
      isMarked,
      onValidate,
      contextMenuHandler,
      ...containerProps
    } = props;
    const hasValue = value != '' && value != null;
    const validationButtonDisplayed = !isDisabled && hasValue && isMarked;
    const confidenceLevelDisplayed =
      !isDisabled && hasValue && confidenceLevel && !isValidated && !isEdited;

    const emptyValueTextDisplayed = cellType === 'predicted' && !hasValue;

    return (
      <div className={styles.cell} {...containerProps} ref={ref} onContextMenu={contextMenuHandler}>
        <div className={styles.value}>
          {confidenceLevelDisplayed && <ConfidenceLevel level={confidenceLevel} />}
          <span
            className={classNames(
              styles.ellipsisValue,
              emptyValueTextDisplayed && styles.emptyValue
            )}
          >
            {emptyValueTextDisplayed ? emptyValueText : value}
          </span>
        </div>
        {validationButtonDisplayed && (
          <ValidationButton
            isDisabled={isValidated || isEdited}
            validate={onValidate}
            isEdited={isEdited}
          />
        )}
      </div>
    );
  }
);
