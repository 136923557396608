import type { PropsWithChildren, ReactEventHandler } from 'react';
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'utils/portal';
import styles from './Modal.module.scss';
import { Transition } from 'react-transition-group';
import { styleVariables } from '../../utils/styleVariables';
import classNames from 'classnames';
import { Icon } from 'components/general/Icon';
import appStyles from '../layout/App.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  header: string;
};

export function Modal(props: PropsWithChildren<Props>) {
  const { isOpen, onClose, children, header } = props;
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
    }
  }, [isOpen]);

  const onCancel: ReactEventHandler<HTMLDialogElement> = event => {
    event.preventDefault();
    onClose();
  };

  return (
    <Transition
      in={isOpen}
      timeout={{
        exit: styleVariables.animationDuration,
      }}
      unmountOnExit
      mountOnEnter
    >
      {state =>
        createPortal(
          `modal`,
          <dialog
            ref={dialogRef}
            className={classNames(styles.modal, state)}
            onClose={onClose}
            onCancel={onCancel}
          >
            <div
              className={classNames(
                styles.header,
                appStyles.justifySpaceBetween,
                appStyles.alignCenter,
                appStyles.horizontalFlex
              )}
            >
              <h3>{header}</h3>
              <button
                onClick={onClose}
                className={classNames(appStyles.unpaddedButton, appStyles.colorInverted)}
              >
                <Icon className={appStyles.mediumFont} weight='light' name='xmark-large' />
              </button>
            </div>
            <div className={styles.content}>{children}</div>
          </dialog>
        )
      }
    </Transition>
  );
}
