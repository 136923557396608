import React from 'react';
import classNames from 'classnames';
import appStyles from 'components/layout/App.module.scss';
import styles from './Tabs.module.scss';
import { Tooltip } from './Tooltip';
import { analyticsState } from 'store/analytics';
import { useRecoilValue } from 'recoil';

type Props<T extends string> = {
  names: readonly T[];
  isSelected: (name: T) => boolean;
  onSelect: (name: T) => void;
  tooltipGetter: (name: T) => string;
};

export function Tabs<T extends string>(props: Props<T>) {
  const { names, isSelected, onSelect, tooltipGetter } = props;
  const className = classNames(appStyles.buttonSecondary, styles.tab);
  const analytics = useRecoilValue(analyticsState);

  const onClick = (name: T) => {
    onSelect(name);
    analytics.track('Tab clicked', { value: name, name: 'Tabs', type: 'tabs' });
  };

  return (
    <>
      {names.map(name => (
        <div
          key={name}
          className={classNames(styles.container, isSelected(name) && styles.selected)}
        >
          <Tooltip content={<span className={styles.tooltipContent}>{tooltipGetter(name)}</span>}>
            <button onClick={() => onClick(name)} className={className}>
              {name}
            </button>
            <div className={styles.activeSign} />
          </Tooltip>
        </div>
      ))}
    </>
  );
}
