import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { growthPointState } from '../../store/growthPoint';
import { RadioButtonGroup } from './RadioButtonGroup';
import { analyticsState } from 'store/analytics';
import type { Growth } from '@harmonya/models';

export function GrowthPointSelect() {
  const [growthPoint, setGrowthPoint] = useRecoilState(growthPointState);
  const analytics = useRecoilValue(analyticsState);

  const onSetGrowthPoint = (value: keyof Growth) => {
    analytics.track('Growth clicked', { name: 'Growth', value, type: 'radioButton' });
    setGrowthPoint(value);
  };

  return (
    <>
      <h5>Growth</h5>
      <RadioButtonGroup
        value={growthPoint}
        onChange={onSetGrowthPoint}
        options={[
          {
            value: 'inPeriod',
            name: 'In Period',
            tooltip: { page: 'explorePage', key: 'growthFilterInPeriod' },
          },
          {
            value: 'lastYear',
            name: 'Year Over Year',
            tooltip: { page: 'explorePage', key: 'growthFilterLastYear' },
          },
        ]}
      />
    </>
  );
}
