import type { ReactNode, ComponentProps } from 'react';
import React, { useEffect, useState, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import vennInit from 'highcharts/modules/venn';
import {
  findRowRange,
  getSeriesData,
  tagsConfig,
  textPadding,
  getComputedVennPoints,
  getVennAreaTooltipText,
} from './utils';
import classNames from 'classnames';
import styles from './TagsVenn.module.scss';
import appStyles from '../../App.module.scss';
import { TagsRow } from './TagsRow';
import { Title } from './Title';
import { CircularText } from 'components/general/CircularText';
import { rectToCircle } from 'utils/circle';
import { Icon } from 'components/general/Icon';
import { Select } from 'components/general/select/Select';
import { styleVariables } from 'utils/styleVariables';
import type { AggregateTag } from 'models/AggregateTag';
import { HiddenTagsMenu } from 'components/general/HiddenTagsMenu';
import { TagsVennMoreTagsIndicator } from './TagsVennMoreTagsIndicator';
import { TagsVennNoFound } from './TagsVennNoFound';
import { analyticsState } from 'store/analytics';
import { useRecoilValue } from 'recoil';
import { useIsBrandingOptionDisabled } from 'hooks/useIsBrandingOptionDisabled';
import { comparisonPageCategoryIdsState } from 'store/comparisonPage';
import type { TreeOption } from 'utils/node';
import { isDefined, iterator } from '@harmonya/utils';
import type { Branding } from '@harmonya/models';
import type { Option, RawOptionType } from 'components/general/select/types';
import type { ComparableItem } from 'components/layout/comparisonPage/comparison-page.types';

vennInit(Highcharts);

type ComputedVennPoints = ReturnType<typeof getComputedVennPoints>;

const hasMorePlace = (
  paddedLeft: number,
  havePlacedTags: boolean,
  paddedRight: number,
  nextTag?: AggregateTag
) => {
  if (nextTag) {
    const paddingBetweenTags = havePlacedTags ? tagsConfig.tagHorizontalPadding : 0;
    const hasPlaceToNextTag = paddedLeft + paddingBetweenTags + nextTag.width < paddedRight;

    if (hasPlaceToNextTag) {
      return nextTag;
    }
  }
};

const circleTitlesConfig = [
  { offset: 7, maxOffset: 30, sweepFlag: 1, dy: -12 },
  { offset: 30, maxOffset: 68, sweepFlag: 1, dy: -12 },
  { offset: 30, maxOffset: 40, sweepFlag: 0, dy: 22 },
];

type Props<K extends RawOptionType> = {
  items: ComparableItem[];
  firstCirclePlaceholder: string;
  secondCirclePlaceholder: string;
  focusIndex?: number;
  onClick: (index?: number) => void;
  onButtonOptionSelect: (id: K, circleIndex: number) => void;
  buttonOptions: Map<number, TreeOption>;
  buttonValues: K[];
  differentiationMagnitude: number;
  getTagHash: (tagId: number) => string;
  hiddenTagHashes: string[];
  hiddenTagIds: number[];
  setHiddenTagIds: (tagIds: number[]) => void;
  toggleTagVisibility: (tagId: number) => void;
  // Changes in the value indicate need to reflow
  reflowIndicator: string;
};

export function TagsVenn<K extends RawOptionType>(props: Props<K>) {
  const {
    items,
    firstCirclePlaceholder,
    secondCirclePlaceholder,
    focusIndex,
    onClick,
    onButtonOptionSelect,
    buttonOptions,
    buttonValues,
    differentiationMagnitude,
    hiddenTagIds,
    hiddenTagHashes,
    getTagHash,
    setHiddenTagIds,
    toggleTagVisibility,
    reflowIndicator,
  } = props;
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [buttonElements, setButtonElements] = useState<ReactNode[]>([]);
  const [titleElements, setTitleElements] = useState<ReactNode[]>([]);
  const [tagElements, setTagElements] = useState<ReactNode[]>([]);
  const [options, setOptions] = useState<Highcharts.Options>();
  const isBrandingOptionDisabledByCategory = useIsBrandingOptionDisabled(
    comparisonPageCategoryIdsState
  );
  const isBrandingOptionDisabled = (option: Option<K>) =>
    isBrandingOptionDisabledByCategory(option as Option<Branding['id']>) ||
    buttonValues.includes(option.value);
  const aggregatedTags = new Map(items.flatMap(({ allTags }) => allTags.map(tag => [tag.id, tag])));
  const analytics = useRecoilValue(analyticsState);

  const placeButtons = ({ majors }: ComputedVennPoints) => {
    const getButtonElement = (i: number, top: number, left: number) => {
      const isDisabled = !!(i && buttonValues[i - 1] == null);
      const buttonItemClassName = classNames(
        i === 2 ? [styles.thirdOptionButton, appStyles.button] : appStyles.unpaddedButtonPrimary,
        styles.addButton
      );
      const buttonItem = (
        <button className={buttonItemClassName} disabled={isDisabled}>
          <Icon weight='light' name='plus' />
        </button>
      );
      const element = (
        <Select
          direction='up'
          align='center'
          offset={styleVariables.padding * 1.5}
          style={{ top, left }}
          className={isDisabled ? styles.disabledSelect : styles.select}
          key={i}
          title='Ven Diagram Select'
          value={buttonValues[i]}
          onChange={id => onButtonOptionSelect(id, i)}
          analyticsToggleEventProps={{
            title: 'Add brand click',
            props: { name: 'Add Brand', type: 'button' },
          }}
          analyticsSelectEventProps={{
            title: 'Brand Added',
            props: { name: 'Add Brand', type: 'button' },
          }}
          options={buttonOptions as Iterable<K>}
          bordered
          searchEnabled
          buttonContentGetter={() => buttonItem}
          isDisabledOption={isBrandingOptionDisabled}
        />
      );

      return element;
    };

    const newButtonElements = iterator.definedMap([0, 1, 2], i => {
      if (buttonValues[i] == null) {
        const circle = majors[i];
        const isBottomCircle = !circle;
        let element: JSX.Element;

        if (isBottomCircle) {
          const [leftCircle, rightCircle] = majors;
          const { bottom, left } = leftCircle.rect;
          const { right } = rightCircle.rect;

          element = getButtonElement(i, bottom, left + (right - left) / 2);
        } else {
          const { width, height, top, left } = circle.rect;

          element = getButtonElement(i, top + height / 2, left + width / 2);
        }

        return element;
      }
    });

    setButtonElements(newButtonElements);
  };

  const placeTags = ({ all, majors }: ComputedVennPoints) => {
    const majorCircles = iterator.definedMap(majors, pred => rectToCircle(pred.rect));
    const tagAreaElements = iterator.definedMap(all, pred => {
      const { rect, tags, sets, className, name } = pred;

      const isThreeVennMode = majors.length > 2;
      const isMajor = sets.length === 1;
      const isTopMajorCircle = isMajor && sets[0] !== '2';
      const spaceForMoreTagsIndicator =
        tagsConfig.moreTagsIndicatorHeight + 2 * tagsConfig.moreTagsIndicatorPadding;
      let maxY = rect.top + rect.height - spaceForMoreTagsIndicator;
      let top = rect.top + tagsConfig.tagVerticalPadding;

      if (tags.length) {
        const rowHeight = tags[0].height;
        const tagClassName = className?.includes(styles.focused)
          ? 'focusedCloudTag'
          : styles.cloudTag;
        const isBottomCircle = isThreeVennMode && sets[0] === '2';
        let bottomCirclePaddingAdded = false;
        let rows: (Omit<ComponentProps<typeof TagsRow>, 'hideTag'> & { hasPlacedTag: boolean })[] =
          [];
        let tagIndex = 0;
        let rowIndex = 0;

        // On top major circles in 3 venn mode more spacing from the buttom of the circle is required as the
        // itersection is not in the middle
        maxY -=
          isTopMajorCircle && isThreeVennMode ? rowHeight + tagsConfig.moreTagsIndicatorPadding : 0;

        // Loop verticly and place rows of tags
        while (tagIndex < tags.length && top + rowHeight < maxY) {
          const xRange = findRowRange(top, rowHeight, sets.map(Number), majorCircles);

          if (xRange && isBottomCircle && !bottomCirclePaddingAdded) {
            top += tagsConfig.tagVerticalPadding;
            rowIndex++;
            bottomCirclePaddingAdded = true;
          } else {
            if (xRange) {
              const [left, right] = xRange;
              let paddedLeft = left + tagsConfig.tagHorizontalPadding;
              const paddedRight = right - tagsConfig.tagHorizontalPadding;
              const width = right - left;
              let nextTag: AggregateTag | undefined;

              rows[rowIndex] = {
                width,
                left,
                top,
                tags: [],
                tagClassName,
                isMajor,
                get hasPlacedTag() {
                  return !!this.tags.length;
                },
              };

              // Loop in row and assign tags to it by the width of the row and the tags
              // eslint-disable-next-line no-cond-assign, max-depth
              while (
                (nextTag = hasMorePlace(
                  paddedLeft,
                  rows[rowIndex].hasPlacedTag,
                  paddedRight,
                  tags[tagIndex]
                ))
              ) {
                tagIndex += 1;
                rows[rowIndex].tags.push(nextTag);
                paddedLeft += nextTag.width + tagsConfig.tagHorizontalPadding;
              }

              // place at least one tag in a row if there is place
              // eslint-disable-next-line max-depth
              if (
                !rows[rowIndex].tags.length &&
                tags[tagIndex] &&
                width > tagsConfig.minimalTagWidth
              ) {
                rows[rowIndex].tags.push({
                  ...tags[tagIndex],
                  dominantType: tags[tagIndex].dominantType,
                  width,
                });
                tagIndex += 1;
              }
            }

            top += rows[rowIndex]?.hasPlacedTag
              ? rowHeight + tagsConfig.tagVerticalPadding
              : tagsConfig.minYStepSize;
            rowIndex++;
          }
        }

        // Clear empty rows
        rows = rows.filter(row => row.tags.length);

        // Adjust top for moreTagsIndicator
        if (!rows.length) {
          top = rect.top + rect.height / 2;
        }

        if (isThreeVennMode && sets.length === 2 && ['0', '1'].every(set => sets.includes(set))) {
          top = rect.top + tagsConfig.moreTagsIndicatorPadding;
        }

        const xRangeMoreTagsIndicator = findRowRange(
          top,
          tagsConfig.moreTagsIndicatorHeight,
          sets.map(Number),
          majorCircles
        );

        return (
          <div key={name} className={styles.dataLabelContainer}>
            {rows.map((row, i) => (
              <TagsRow key={i} hideTag={toggleTagVisibility} {...row} />
            ))}
            {!!xRangeMoreTagsIndicator && tagIndex < tags.length && (
              <TagsVennMoreTagsIndicator
                width={xRangeMoreTagsIndicator[1] - xRangeMoreTagsIndicator[0]}
                left={xRangeMoreTagsIndicator[0]}
                top={top}
                tagIndex={tagIndex}
                tags={tags}
                tooltipDescription={getVennAreaTooltipText(
                  sets.length > 1,
                  differentiationMagnitude,
                  majors.map(major => major.name)
                )}
                vennName={name}
              />
            )}
          </div>
        );
      }

      if (!tags.length && isMajor && !['-1', '-2'].includes(sets[0])) {
        const hightAdjustByCircle = isTopMajorCircle
          ? -tagsConfig.emptyStateHeight / 2
          : tagsConfig.emptyStateHeight;

        top = rect.top + rect.height / 2 + hightAdjustByCircle;

        const xRangeMoreTagsIndicator = findRowRange(
          top,
          tagsConfig.moreTagsIndicatorHeight,
          sets.map(Number),
          majorCircles
        );

        return (
          xRangeMoreTagsIndicator && (
            <TagsVennNoFound
              width={xRangeMoreTagsIndicator[1] - xRangeMoreTagsIndicator[0]}
              left={xRangeMoreTagsIndicator[0]}
              top={top}
              key={name}
            />
          )
        );
      }
    });

    setTagElements(tagAreaElements);
  };

  const placeTitles = ({ majors, minors }: ComputedVennPoints) => {
    const newTitleElements = majors.map((item, i) => {
      const { rect, sets: majorSets, tags } = item;

      const { width, height, top, left } = rect;
      const radius = width / 2;
      const center = {
        x: radius + textPadding / 2,
        y: radius + textPadding / 2,
      };
      const style = {
        width: width + textPadding,
        height: height + textPadding,
        top: top - textPadding / 2,
        left: left - textPadding / 2,
      };
      const [currentSet] = majorSets;
      const optionId = Number(buttonValues[Number(currentSet)]);
      const option = buttonOptions.get(optionId);
      const pathParts = option?.compactPathParts ?? [];
      const totalTags =
        tags.length +
        iterator.sum(
          minors.filter(minor => minor.sets.includes(currentSet)),
          minor => minor.tags.length
        );
      const element = (
        <CircularText
          key={i}
          id={i.toString()}
          circle={{ radius, center }}
          className={appStyles.positionAbsolute}
          style={style}
          {...circleTitlesConfig[i]}
        >
          <Title pathParts={pathParts} tagsCount={totalTags} />
        </CircularText>
      );

      return element;
    });

    setTitleElements(newTitleElements);
  };

  useEffect(() => {
    const chartData = getSeriesData(
      items,
      [firstCirclePlaceholder, secondCirclePlaceholder],
      getTagHash,
      focusIndex,
      new Set(hiddenTagHashes)
    );
    const chartOptions: Highcharts.Options = {
      accessibility: { enabled: false },
      credits: undefined,
      title: undefined,
      series: [
        {
          type: 'venn',
          opacity: 0.5,
          data: chartData,
        },
      ],
      chart: { backgroundColor: 'transparent' },
      tooltip: { enabled: false },
      plotOptions: {
        venn: { states: { inactive: { opacity: 1 } } },
        series: {
          events: {
            afterAnimate() {
              const chart = chartRef.current;
              const container = containerRef.current;

              if (chart) {
                const place = () => {
                  const computedVennPoints = getComputedVennPoints(chart, container);

                  placeButtons(computedVennPoints);
                  placeTitles(computedVennPoints);
                  placeTags(computedVennPoints);
                };

                if (buttonElements.length && titleElements.length) {
                  place();
                } else {
                  setTimeout(place, styleVariables.animationDuration);
                }
              }
            },
          },
          point: {
            events: {
              click(event) {
                const { index } = event.point;

                if (index < 0 || this.getClassName()?.includes(styles.focused)) {
                  onClick(undefined);
                } else {
                  onClick(index);
                  analytics.track('Venn diagram select focus', {
                    name: 'Venn Diagram',
                    type: 'chart',
                    value: index,
                  });
                }

                event.stopPropagation();
              },
            },
          },
          dataLabels: { enabled: false },
        },
      },
    };

    setOptions(chartOptions);
  }, [items, focusIndex, hiddenTagIds]);

  useEffect(() => {
    const chart = chartRef?.current?.chart;

    chart?.reflow();
  }, [reflowIndicator]);

  return (
    <div
      ref={containerRef}
      className={classNames(
        appStyles.verticalFlex,
        appStyles.alignCenter,
        appStyles.justifyCenter,
        appStyles.positionRelative,
        styles.container
      )}
    >
      {buttonElements}
      {titleElements}
      <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
      {tagElements}
      <div className={styles.hiddenTagsMenuContainer}>
        <HiddenTagsMenu
          hiddenTags={
            hiddenTagIds?.map(id => aggregatedTags?.get(id)).filter(isDefined) as AggregateTag[]
          }
          toggleHiddenTag={toggleTagVisibility}
          clearHiddenTags={() => setHiddenTagIds([])}
        />
      </div>
    </div>
  );
}
