import React, { useMemo } from 'react';
import classNames from 'classnames';
import appStyles from '../App.module.scss';
import styles from './ExplorePageProductsTable.module.scss';
import type { TableProduct } from 'store/product.types';
import { productsTableState } from 'store/products';
import { Icon } from '../../general/Icon';
import { Input } from '../../general/Input';
import type { ColumnMap } from '../../general/VirtualizedTable';
import { VirtualizedTable } from '../../general/VirtualizedTable';
import { isLoading, useRecoilValueLoadableState } from 'hooks/useRecoilValueLoadable';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  explorePageProductsTableSearchTextState,
  explorePageProductsTableSortState,
} from 'store/explorePage';
import { growthPointState } from 'store/growthPoint';
import { getSortedFilteredOptions } from 'utils/sortedFilteredOptions';
import { missingValue, number } from '@harmonya/utils';

function getColumnMap<K extends keyof TableProduct>(columnMap: ColumnMap<TableProduct, K>) {
  return columnMap as unknown as ColumnMap<TableProduct, keyof TableProduct>;
}

const defaultFormatter = (value: string) => value;

type Props = {
  padding: string;
};

export function ExplorePageProductsTable(props: Props) {
  const [searchText, setSearchText] = useRecoilState(explorePageProductsTableSearchTextState);
  const [sort, setSort] = useRecoilState(explorePageProductsTableSortState);
  const products = useRecoilValueLoadableState(productsTableState);
  const growthPoint = useRecoilValue(growthPointState);
  const { filteredOptions: filteredProducts } = useMemo(
    () => getSortedFilteredOptions(searchText, products?.items ?? [], ['name', 'brandName']),
    [searchText, products?.items]
  );

  if (isLoading(products)) {
    return null;
  }

  const columnsMap: ColumnMap<TableProduct, keyof TableProduct>[] = [
    getColumnMap({
      key: 'name',
      widthUnits: 4,
      header: 'Description',
      formatter: defaultFormatter,
      defaultSortDirection: 'asc',
    }),
    getColumnMap({
      key: 'brandName',
      widthUnits: 3,
      header: 'Brand',
      formatter: defaultFormatter,
      defaultSortDirection: 'asc',
    }),
    getColumnMap({
      key: 'sales',
      widthUnits: 2,
      header: 'Sales',
      formatter: value => number.shortCurrency(value),
      defaultSortDirection: 'desc',
    }),
    getColumnMap({
      key: 'salesDaysCount',
      widthUnits: 2,
      header: 'Sales Days Count',
      formatter: number.format,
      defaultSortDirection: 'desc',
    }),
    getColumnMap({
      key: 'firstWeekSales',
      widthUnits: 2,
      header: 'First Week Sales',
      formatter: value => number.shortCurrency(value),
      defaultSortDirection: 'desc',
    }),
    getColumnMap({
      key: 'lastWeekSales',
      widthUnits: 2,
      header: 'Last Week Sales',
      formatter: value => number.shortCurrency(value),
      defaultSortDirection: 'desc',
    }),
    getColumnMap({
      key: 'growth',
      widthUnits: 2,
      header: 'Growth',
      formatter: value => {
        const computedValue = value[growthPoint];

        return computedValue == null ? (
          missingValue
        ) : (
          <span
            className={classNames(computedValue < 0 && styles.down, computedValue > 0 && styles.up)}
          >
            {number.percent(computedValue < 10 ? computedValue : Math.floor(computedValue))}
          </span>
        );
      },
    }),
  ];

  return (
    <>
      <div className={styles.placeholder} />
      <div
        className={classNames(
          appStyles.box,
          appStyles.shadowed,
          appStyles.verticalFlex,
          styles.container,
          appStyles.backgroundBackground,
          props.padding,
          appStyles.gap0,
          appStyles.overflowOverlay
        )}
      >
        <div
          className={classNames(
            styles.header,
            appStyles.horizontalFlex,
            appStyles.justifySpaceBetween
          )}
        >
          <div
            className={classNames(appStyles.horizontalFlex, appStyles.alignCenter, appStyles.gap1)}
          >
            <h3>Products</h3>
            <h5>{`(${filteredProducts.length === products.items?.length ? '' : `${filteredProducts.length} of `}${number.format(products?.items?.length ?? 0)})`}</h5>
          </div>
          <div className={classNames(appStyles.horizontalFlex, appStyles.alignCenter)}>
            <Icon name='angle-down' className={classNames(appStyles.mediumFont, styles.arrow)} />
          </div>
        </div>
        <div className={appStyles.horizontalFlex}>
          <Input
            containerClassName={classNames(appStyles.flexGrow1, styles.searchInput)}
            debounce={300}
            placeholder='Search product'
            iconName='search'
            value={searchText}
            onInput={({ currentTarget }) => setSearchText(currentTarget.value)}
          />
        </div>
        <VirtualizedTable
          columnsMap={columnsMap}
          rows={filteredProducts}
          rowHeight={29.5}
          keyGetter={product => product.id}
          sort={{
            ...sort,
            onChange: (field, direction) => setSort({ field, direction }),
            valueGetter: value => (typeof value === 'object' ? value[growthPoint as never] : value),
            dependencies: [growthPoint],
          }}
        />
      </div>
    </>
  );
}
