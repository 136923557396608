import classNames from 'classnames';
import type { IconName } from 'components/general/Icon';
import { Icon } from 'components/general/Icon';
import type { Option } from 'components/general/select/types';
import { Select } from 'components/general/select/Select';
import React, { useCallback, useMemo, memo, useEffect } from 'react';
import type { RecoilState, RecoilValueReadOnly } from 'recoil';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useRecoilValueLoadableState } from 'hooks/useRecoilValueLoadable';
import styles from './ExplorePageLevelSelector.module.scss';
import appStyles from '../App.module.scss';

type Props = {
  levelState: RecoilState<number>;
  optionsState: RecoilValueReadOnly<Option<number>[]>;
  enabledLevelsState: RecoilValueReadOnly<Set<number>>;
  iconName: IconName;
};

export const ExplorePageLevelSelector = memo(function ExplorePageLevelSelector(props: Props) {
  const { levelState, optionsState, enabledLevelsState, iconName } = props;
  const [level, setLevel] = useRecoilState(levelState);
  const levels = useRecoilValue(optionsState);
  const enabledLevels = useRecoilValueLoadableState(enabledLevelsState);
  const levelOptions = useMemo(
    () =>
      new Map([
        [
          'View By',
          levels.map(levelItem => ({
            ...levelItem,
            disabled: !enabledLevels?.has(levelItem.value),
          })),
        ],
      ]),
    [levels, enabledLevels]
  );
  const displayFilterSelectedItemGetter = useCallback(
    (option: Option<number>) => (
      <span className={classNames(appStyles.horizontalFlex, appStyles.gap1, appStyles.alignCenter)}>
        <Icon className={appStyles.hugeFont} name={iconName} />
        <span className={appStyles.ellipsis}>{option.displayValue}</span>
      </span>
    ),
    [iconName]
  );

  useEffect(() => {
    if (enabledLevels?.has(level) === false) {
      const [topEnabledLevel] = enabledLevels;

      setLevel(topEnabledLevel);
    }
  }, [enabledLevels]);

  return (
    <Select
      className={styles.displayFilter}
      displaySelectedValueGetter={displayFilterSelectedItemGetter}
      options={levelOptions}
      value={level}
      onChange={setLevel}
      bordered
    />
  );
});
