import { useVirtualizer } from '@tanstack/react-virtual';
import React, { memo, useRef, type ComponentType, type ReactNode } from 'react';
import type { RecoilValueReadOnly } from 'recoil';
import { useRecoilValue } from 'recoil';
import { styleVariables } from 'utils/styleVariables';
import appStyles from '../../layout/App.module.scss';
import styles from './DataTableBase.module.scss';
import type { PropsWithCellComponent } from './DataTableCell';
import { DataTableHeader } from './DataTableHeader';
import { DataTableRow } from './DataTableRow';

export type ItemId = number;
export type RowIndex = number;

export type CellContent = {
  value?: ReactNode;
  id: string;
};

export type Column = {
  key: number;
  title: string;
};

export type ColumnKey = Column['key'];

type Props<H extends Column> = PropsWithCellComponent<{
  itemIdsState: RecoilValueReadOnly<ItemId[]>;
  columns: H[];
  HeaderCellComponent: ComponentType<{ column: H; width?: number }>;
}>;

export const DataTableBase = memo(function DataTableBase<H extends Column>(props: Props<H>) {
  const { columns, itemIdsState, CellComponent, HeaderCellComponent, isDisabledColumnState } =
    props;
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const itemIds = useRecoilValue(itemIdsState);

  const rowVirtualizer = useVirtualizer({
    count: itemIds.length,
    getScrollElement: () => scrollContainerRef.current,
    estimateSize: () => styleVariables.dataTableRowHeight,
    overscan: 10,
    paddingStart: styleVariables.dataTableRowHeight,
    isScrollingResetDelay: 0,
  });

  const height = rowVirtualizer.getTotalSize();

  return (
    <div className={styles.container} ref={scrollContainerRef}>
      <div className={appStyles.positionRelative} style={{ height }}>
        <DataTableHeader columns={columns} HeaderCellComponent={HeaderCellComponent} />
        {rowVirtualizer.getVirtualItems().map(({ index, start, key }) => (
          <DataTableRow
            topOffset={start}
            key={key}
            columns={columns}
            itemId={itemIds[index]}
            index={index}
            lastRowIndex={itemIds.length - 1}
            CellComponent={CellComponent}
            isDisabledColumnState={isDisabledColumnState}
          />
        ))}
      </div>
    </div>
  );
});
