import type { PropsWithChildren, ReactNode } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import appStyles from '../App.module.scss';
import styles from './NavWindow.module.scss';
import { styleVariables } from '../../../utils/styleVariables';
import { Transition } from 'react-transition-group';
import { createPortal } from '../../../utils/portal';
import { useBooleanState } from 'hooks/useBooleanState';

type Props = PropsWithChildren<{
  content: ReactNode;
}>;

export function NavWindow(props: Props) {
  const { content, children } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState({ left: 0, bottom: 0 });
  const [isOpen, open, close] = useBooleanState(false);

  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const left = rect.right + styleVariables.padding + styleVariables.gap;
      const bottom = window.innerHeight - rect.bottom - styleVariables.padding;

      setStyle({ left, bottom });
    }
  }, [ref.current]);

  return (
    <>
      <div ref={ref} onMouseMove={open} onMouseLeave={close}>
        {children}
      </div>
      <Transition
        in={isOpen}
        timeout={{ exit: styleVariables.animationDuration }}
        unmountOnExit
        mountOnEnter
      >
        {state =>
          createPortal(
            'navWindow',
            <div
              style={style}
              onMouseMove={open}
              onMouseLeave={close}
              className={classNames(appStyles.box, appStyles.padded3, styles.container, state)}
            >
              {content}
            </div>
          )
        }
      </Transition>
    </>
  );
}
