import type { Auth0ContextInterface } from '@auth0/auth0-react';
import type { auth as authMock } from '../store/auth';

export let getAccessTokenSilently:
  | Auth0ContextInterface['getAccessTokenSilently']
  | typeof authMock.getAccessTokenSilently;

/**
 * The Auth0 React SDK relies on hooks to obtain tokens.
 * However, since we are making fetch requests within Recoil selectors, which operate outside of React components,
 * we cannot use hooks.
 * Instead, we store a reference to the function for use outside the component scope.
 *
 * For more details, refer to the discussion on Recoil's GitHub issue #1382:
 * @see https://github.com/facebookexperimental/Recoil/issues/1382
 *
 * And Auth0 React SDK's GitHub issue #67:
 * @see https://github.com/auth0/auth0-react/issues/67
 **/
export function setAccessTokenSilentlyGetter(fn: typeof getAccessTokenSilently) {
  getAccessTokenSilently = fn;
}

export class AuthError extends Error {
  constructor(input: string | Error | Response) {
    let message: string;

    if (typeof input === 'string') {
      message = input;
    } else if (input instanceof Error) {
      ({ message } = input);
    } else {
      message = `${input.status}: ${input.statusText}`;
    }

    super(message);
  }

  name = 'AuthError';
}
