import { array as arrayUtil } from './array';
import { addMany } from './set';

export const some = <T>(items: Iterable<T> | undefined, predicate: (item: T) => boolean) => {
  if (items) {
    for (const item of items) {
      if (predicate(item)) {
        return true;
      }
    }
  }

  return false;
};

export const every = <T>(items: Iterable<T> | undefined, predicate: (item: T) => boolean) => {
  if (items) {
    for (const item of items) {
      if (!predicate(item)) {
        return false;
      }
    }
  }

  return true;
};

export const find = <T>(items: Iterable<T>, predicate: (item: T) => boolean) => {
  for (const item of items) {
    if (predicate(item)) {
      return item;
    }
  }
};

export const filter = <T>(items: Iterable<T>, predicate: (item: T) => boolean) => {
  const filteredItems: T[] = [];

  for (const item of items) {
    if (predicate(item)) {
      filteredItems.push(item);
    }
  }

  return filteredItems;
};

type Mapper<T> = (index: number) => T;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const join = <T>(items: Iterable<T>, delimiterItem: T | Mapper<T>) => {
  const results: T[] = [];

  if (typeof delimiterItem === 'function') {
    let index = 0;

    for (const item of items) {
      const mappedItem = (<Mapper<T>>delimiterItem)(index);

      results.push(item, mappedItem);
      index++;
    }
  } else {
    for (const item of items) {
      results.push(item, delimiterItem);
    }
  }

  // Remove the last (unnecessary) delimiter item
  results.pop();

  return results;
};

export const definedMap = <T, U>(
  iterable: Iterable<T> | undefined,
  mapPredicate: (value: T, index: number, items: Iterable<T>) => U | undefined
): U[] => {
  const mappedItems: U[] = [];

  if (!iterable) {
    return mappedItems;
  }

  let i = 0;

  for (const item of iterable) {
    const mappedItem = mapPredicate(item, i, iterable);

    if (arrayUtil.isDefined(mappedItem)) {
      mappedItems.push(mappedItem);
    }

    i++;
  }

  return mappedItems;
};

export const isIterable = (value: unknown): value is Iterable<unknown> =>
  typeof value !== 'string' && Symbol.iterator in <Record<string, unknown>>value;

export const length = (items: Iterable<unknown>) => {
  if (Array.isArray(items) || typeof items === 'string') {
    return items.length;
  }

  if (items instanceof Map || items instanceof Set) {
    return items.size;
  }

  let count = 0;

  for (const _ of items) {
    count++;
  }

  return count;
};

export const sum = <T>(
  items: Iterable<T>,
  valueGetter: (item: T, index: number) => number = Number
) => {
  let result = 0;
  let index = 0;

  for (const item of items) {
    const value = valueGetter(item, index);

    result += value;
    index++;
  }

  return result;
};

export const avg = <T>(items: Iterable<T>, valueGetter: (item: T) => number = Number) => {
  let total = 0;
  let count = 0;

  for (const item of items) {
    const value = valueGetter(item);

    total += value;
    count++;
  }

  const result = count > 0 ? total / count : 0;

  return result;
};

export const max = <T>(
  items: Iterable<T>,
  valueGetter: (item: T) => number | undefined = Number
) => {
  let maxValue: number | undefined;
  let maxItem: T | undefined;

  for (const item of items) {
    const value = valueGetter(item);

    if (value != null && (maxValue == null || maxValue < value)) {
      maxValue = value;
      maxItem = item;
    }
  }

  return maxItem;
};

export const count = <T>(
  items: Iterable<T>,
  predicate: (item: T, index: number) => boolean = Boolean
) => {
  const result = sum(items, (value, index) => +predicate(value, index));

  return result;
};

export const countDistinct = <T, S>(
  items: Iterable<S>,
  mapper: (item: S) => T,
  nullOrUndefinedIncluded = false
) => {
  const set = new Set<T | null | undefined>();
  addMany(set, items, mapper);

  if (!nullOrUndefinedIncluded) {
    set.delete(null);
    set.delete(undefined);
  }

  return set.size;
};

export const elementAt = <T>(items: Iterable<T>, index: number) => {
  let currentIndex = 0;

  for (const item of items) {
    if (currentIndex++ === index) {
      return item;
    }
  }
};

export const split = <T>(items: Iterable<T>, predicate: (item: T) => boolean) => {
  const first: T[] = [];
  const second: T[] = [];

  for (const item of items) {
    const array = predicate(item) ? first : second;

    array.push(item);
  }

  return [first, second];
};

export const getLast = <T>(items: Iterable<T>): T | undefined => {
  let lastItem: T | undefined;

  for (const item of items) {
    lastItem = item;
  }

  return lastItem;
};

type Reducer<U, T> = (
  accumulator: U,
  currentValue: T,
  currentIndex: number,
  iterable: Iterable<T>
) => U;

export function reduce<T>(iterable: Iterable<T>, reducer: Reducer<T, T>): T;
export function reduce<T, U>(iterable: Iterable<T>, reducer: Reducer<U, T>, initialValue: U): U;
export function reduce<T, U>(
  iterable: Iterable<T>,
  reducer: Reducer<T | U, T>,
  initialValue?: U
): T | U {
  const iterator = iterable[Symbol.iterator]();
  let result: T | U;
  let i = 0;

  const getNext = () => {
    const next = iterator.next();
    if (next.done) throw new TypeError('Reduce of empty iterable with no initial value');
    return next.value;
  };

  if (initialValue === undefined) {
    result = getNext();
    i++;
  } else {
    result = initialValue;
  }

  for (let item = iterator.next(); !item.done; item = iterator.next(), i++) {
    result = reducer(result, item.value, i, iterable);
  }

  return result;
}
