import classNames from 'classnames';
import React, { type ComponentType, memo } from 'react';
import styles from './DataTableCell.module.scss';
import { useMarkedCellProps } from './DataTableCell.utils';
import { useColumnWidthContext } from './context/ColumnWidthContext';
import type { RecoilValue } from 'recoil';
import { useRecoilValue } from 'recoil';
import type { ColumnKey } from './DataTableBase';

export type PropsWithCellComponent<P> = P & {
  CellComponent: ComponentType<{
    columnId: number;
    itemId: number;
    isMarked?: boolean;
    isDisabled?: boolean;
    onBlur?: () => void;
  }>;
  isDisabledColumnState: (columnKey: ColumnKey) => RecoilValue<boolean>;
};

type Props = PropsWithCellComponent<{
  columnId: number;
  itemId: number;
  nextColumnId?: number;
  prevColumnId?: number;
  rowIndex: number;
  lastRowIndex: number;
}>;

export const DataTableCell = memo(function DataTableCell(props: Props) {
  const {
    columnId,
    itemId,
    nextColumnId,
    prevColumnId,
    rowIndex,
    lastRowIndex,
    isDisabledColumnState,
    CellComponent,
  } = props;

  const width = useColumnWidthContext(columnId);
  const isDisabled = useRecoilValue(isDisabledColumnState(columnId));
  const { markedCellProps, markedCellComponentProps, ref } = useMarkedCellProps(
    columnId,
    nextColumnId,
    prevColumnId,
    rowIndex,
    lastRowIndex
  );

  const cell = (
    <div
      ref={ref}
      key={columnId}
      style={{ width }}
      {...markedCellProps}
      className={classNames(styles.cell, isDisabled && styles.disabled, markedCellProps?.className)}
    >
      <CellComponent
        columnId={columnId}
        itemId={itemId}
        isDisabled={isDisabled}
        {...markedCellComponentProps}
      />
    </div>
  );

  return cell;
});
