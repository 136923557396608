import classNames from 'classnames';
import { Modal } from 'components/general/Modal';
import { useBooleanState } from 'hooks/useBooleanState';
import { useCsvExport } from 'hooks/useCsvExport';
import React from 'react';
import { Transition } from 'react-transition-group';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  attributionActorState,
  attributionMetadataState,
  attributionSortedColumnIncludesPinnedState,
  attributionSortedColumnKeysState,
  columnIdMaxWidthState,
  columnIdWidthsState,
  hiddenColumnKeysState,
  isDisabledColumnState,
  pinnedColumnKeysState,
} from 'store/attribution';
import {
  sortedFilteredAttributionDataState,
  sortedFilteredAttributionItemIdsState,
} from 'store/attributionPage';
import { authState, customerIdState } from 'store/auth';
import { toastMessageState } from 'store/toastMessage';
import { fetchGet } from 'utils/fetch';
import { styleVariables } from 'utils/styleVariables';
import { DataTable } from '../../general/dataTable/DataTable';
import appStyles from '../App.module.scss';
import { Page } from '../Page';
import { AttributionAnalyticsPanel } from './analyticsPanel/AttributionAnalyticsPanel';
import { getFileContent } from './attribution.utils.csv';
import { AttributionCell } from './AttributionCell';
import { AttributionHeaderCell } from './AttributionHeaderCell';
import styles from './AttributionPage.module.scss';
import { AttributionPageChangesSummary } from './AttributionPageChangesSummary';
import { AttributionPageSaveStatus } from './AttributionPageSaveStatus';
import { AttributionPageToolbar } from './AttributionPageToolbar';
import { schemaMock, sourceNameMock } from './data';

export const triggerModelText = 'Retrain Models';

export function AttributionPage() {
  const [isModalOpen, openModal, closeModal] = useBooleanState(false);
  const [isAnalyticsPanelOpen, openAnalyticsPanel, closeAnalyticsPanel] = useBooleanState(true);
  const productsAttributes = useRecoilValue(sortedFilteredAttributionDataState);
  const attributionMetadata = useRecoilValue(attributionMetadataState);
  const { allColumns } = useRecoilValue(attributionSortedColumnIncludesPinnedState);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useRecoilValue(attributionActorState);
  const { user } = useRecoilValue(authState);
  const customerId = useRecoilValue(customerIdState);
  const setToastMessage = useSetRecoilState(toastMessageState);
  const schema = `${schemaMock}-${sourceNameMock}`;

  const generateCsvFiles = () => {
    return [{ name: schema, data: getFileContent(allColumns, productsAttributes.values()) }];
  };

  const triggerLoadModelOutputs = async () => {
    const response = await fetchGet<{ isSuccess: boolean }>(
      '/api/attribution/sync',
      customerId,
      user.email
    );

    setToastMessage({
      type: response.isSuccess ? 'success' : 'error',
      text: 'Model sync',
    });
  };

  const handleExport = useCsvExport('attribution', generateCsvFiles);

  const headerChildren = (
    <div
      className={classNames(
        appStyles.horizontalFlex,
        appStyles.justifySpaceBetween,
        appStyles.fullBasis,
        appStyles.alignCenter,
        styles.header
      )}
    >
      <AttributionPageSaveStatus />
      <span className={classNames(appStyles.horizontalCenteredFlex)}>
        <button
          className={classNames(
            user.isAdmin ? appStyles.buttonBorder : appStyles.buttonPrimary,
            styles.headerButton
          )}
          onClick={openModal}
        >
          {triggerModelText}
        </button>

        {user.isAdmin && (
          <button
            className={classNames(appStyles.buttonPrimary, styles.headerButton)}
            onClick={triggerLoadModelOutputs}
          >
            Model sync
          </button>
        )}
      </span>
      <Modal isOpen={isModalOpen} onClose={closeModal} header={triggerModelText}>
        <AttributionPageChangesSummary
          editedUpcsCount={59}
          totalEditedUpcsCount={200}
          validatedCount={205}
          editedCount={15}
          onClose={closeModal}
        />
      </Modal>
    </div>
  );

  return (
    <Page title={`Attribution / ${schema}`} headerChildren={headerChildren}>
      <div
        className={classNames(
          appStyles.positionRelative,
          appStyles.overflowHidden,
          appStyles.fullHeight,
          appStyles.horizontalFlex,
          appStyles.gap
        )}
      >
        <div
          className={classNames(
            styles.analyticsPanelContainer,
            isAnalyticsPanelOpen && styles.open
          )}
        >
          <Transition
            in={isAnalyticsPanelOpen}
            timeout={{ exit: styleVariables.animationDuration }}
            unmountOnExit
            mountOnEnter
          >
            {analyticsPanelState => (
              <AttributionAnalyticsPanel
                state={analyticsPanelState}
                closeAnalyticsPanel={closeAnalyticsPanel}
              />
            )}
          </Transition>
        </div>
        <div
          className={classNames(
            appStyles.verticalFlex,
            appStyles.overflowHidden,
            styles.tableContainer,
            isAnalyticsPanelOpen && styles.narrow
          )}
        >
          <AttributionPageToolbar
            isAnalyticsPanelOpen={isAnalyticsPanelOpen}
            openAnalyticsPanel={openAnalyticsPanel}
            onExport={handleExport}
          />
          <DataTable
            columns={attributionMetadata}
            columnIdMaxWidthState={columnIdMaxWidthState}
            itemIdsState={sortedFilteredAttributionItemIdsState}
            CellComponent={AttributionCell}
            HeaderCellComponent={AttributionHeaderCell}
            isColumnWidthsEnabled
            columnKeyWidthsState={columnIdWidthsState}
            isMarkedCellsEnabled
            isPinnedColumnsEnabled
            pinnedColumnKeysState={pinnedColumnKeysState}
            isDisabledColumnState={isDisabledColumnState}
            isHiddenColumnsEnabled
            hiddenColumnKeysState={hiddenColumnKeysState}
            isOrderedColumnsEnabled
            orderedColumnKeysState={attributionSortedColumnKeysState}
          />
        </div>
      </div>
    </Page>
  );
}
