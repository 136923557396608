import classNames from 'classnames';
import { getInsideWindowPosition } from 'utils/getInsideWindowPosition';
import { useKey } from 'hooks/useKey';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import React, { useLayoutEffect, useRef, useState, type ComponentType } from 'react';
import type { TransitionStatus } from 'react-transition-group';
import { createPortal } from 'utils/portal';
import styles from './DisplayedContextMenu.module.scss';

export type ContentComponentProps = {
  close: () => void;
};

type DisplayedContextMenuProps<T = never> = {
  position: { left: number; top: number };
  close: () => void;
  isOpen: boolean;
  state: TransitionStatus;
  ContentComponent: T;
};

type Props<T> = T &
  DisplayedContextMenuProps<
    ComponentType<Omit<T, keyof DisplayedContextMenuProps> & ContentComponentProps>
  >;

export function DisplayedContextMenu<T>(props: Props<T>) {
  const { close, isOpen, state, position, ContentComponent, ...contentComponentProps } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState({});

  useKey({ Escape: close });
  useOnClickOutside(close, [containerRef], isOpen);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const positionStyle = getInsideWindowPosition(position, containerRef.current);

      setStyle(positionStyle);
    }
  }, []);

  return createPortal(
    'context-menu',
    <div
      ref={containerRef}
      className={classNames(styles.container, state)}
      style={style}
      onContextMenu={event => event.preventDefault()}
    >
      <ContentComponent {...contentComponentProps} close={close} />
    </div>
  );
}
