import { selector } from 'recoil';
import { fetchPost } from '../utils/fetch';
import { salesSeriesToSalesSeriesItems } from '../utils/timeSeriesConverter';
import { getSalesFilters } from './explorePage';
import { authState, customerIdState } from './auth';
import type { SalesSeries } from '@harmonya/models';

export const marketSalesState = selector({
  key: 'marketSales',
  get: async ({ get }) => {
    const filters = getSalesFilters(get);
    const customerId = get(customerIdState);
    const { user } = get(authState);
    const marketSales = await fetchPost<SalesSeries>(
      '/api/sales/markets',
      customerId,
      user.email,
      filters
    );

    const sales = Object.keys(marketSales).length
      ? salesSeriesToSalesSeriesItems(marketSales)
      : {
          totalSalesItems: [],
          salesGrowthItems: { inPeriod: [] },
        };

    return sales;
  },
});
