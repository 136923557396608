import React from 'react';
import styles from './SelectOptionWithDescription.module.scss';

type Props = {
  name: string;
  desription: string;
};

export function SelectOptionWithDescription(props: Props) {
  const { name, desription } = props;

  return (
    <>
      {name}
      <span className={styles.description}>{desription}</span>
    </>
  );
}
