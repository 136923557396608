import { useRecoilValue } from 'recoil';
import {
  explorePageSourcePresetState,
  explorePageGrowthPresetState,
  getFilters,
} from 'store/explorePage';
import { tagTypesState } from 'store/tagTypes';
import { formatters, getTitle } from 'utils/formatters';
import { useSearchParam } from './useSearchParam';
import { useTagTypeActiveIds } from './useTagTypeActiveIds';
import { growthPointState } from 'store/growthPoint';
import { settingsState } from 'store/settings';
import {
  basePresetsConfig,
  getExplorePageSliderFiltersConfig,
} from 'components/layout/explorePage/ExplorePageTags.utils';
import type { Config } from './useSliderFilters';
import { getSliderFilterSearchParamName } from './useSliderFilters';
import { tagsState } from 'store/tags';
import type { Tag } from 'models/Tag';
import { marketsState } from 'store/markets';
import { periodsState } from 'store/periods';
import { getPathParts } from 'utils/node';
import type { SelectOption } from 'models/SelectOption';
import { rawBrandingState } from 'store/branding';
import { rawCategoriesState } from 'store/categories';
import { iterator, map, string, type SortDirection, missingValue } from '@harmonya/utils';
import type {
  AggregateTagValue,
  ConfigurationExportContent,
  PresetName,
  QueryToken,
  RangeFilterValue,
} from '@harmonya/models';
import { queryToTokens } from '../functions';
import {
  comparisonPageComperableItemIdsState,
  comparisonPageCategoryIdsState,
  comparisonPageMarketIdsState,
  comparisonPagePeriodIdsState,
  comparisonPageSortFieldState,
} from 'store/comparisonPage';
import { comparePageSliderFiltersConfig } from './useComparableItems';
import { differentiationMagnitudeProp } from 'components/layout/comparisonPage/comparison-page.constants';
import type { RangeValue } from 'components/general/Slider';

export const growthPresetNames = ['drivers', 'drains', 'radar'] as const;
export const sourcePresetNames = ['reviews', 'marketing'] as const;
export const brandingFilterTitle = 'Manufacturer | Brand';

export const useTagTypeIds = () => {
  const allTagTypes = useRecoilValue(tagTypesState);
  const [activeTagTypeIds] = useTagTypeActiveIds(allTagTypes);

  const idsIterator = activeTagTypeIds.size > 0 ? activeTagTypeIds : allTagTypes?.keys() || [];
  return [...idsIterator];
};

export const getRangeFilterValues = (sliderFiltersConfig: Config<AggregateTagValue>[]) => {
  const rangeFilterValues: RangeFilterValue[] = [];
  sliderFiltersConfig.forEach(config => {
    const { prop, title } = config;
    const searchParamName = getSliderFilterSearchParamName(prop);
    const [value] = useSearchParam(searchParamName, {
      defaultValue: [undefined, undefined],
      parser: 'number',
    });
    const [startValue, endValue] = value.map(formatters[prop].valueGetter);
    rangeFilterValues.push({
      title,
      startValue,
      endValue,
    });
  });

  return rangeFilterValues;
};

function useComparePageConfiguration(): ConfigurationExportContent {
  const comperableItemIds = useRecoilValue(comparisonPageComperableItemIdsState);
  const categoryIds = useRecoilValue(comparisonPageCategoryIdsState);
  const marketIds = useRecoilValue(comparisonPageMarketIdsState);
  const periodIds = useRecoilValue(comparisonPagePeriodIdsState);
  const growthPoint = useRecoilValue(growthPointState);
  const settings = useRecoilValue(settingsState);
  const sortBy = useRecoilValue(comparisonPageSortFieldState);
  const tagTypeIds = useTagTypeIds();
  const [sortDirection] = useSearchParam<SortDirection>('sort', {
    defaultValue: 'desc',
  });
  const [hiddenTagIds] = useSearchParam<number[]>('hiddenTagIds', {
    defaultValue: [],
    parser: 'number',
  });
  const rangeFilterValues = getRangeFilterValues(comparePageSliderFiltersConfig);
  const { initialDifferentiationMagnitude } = settings;
  const paramName = getSliderFilterSearchParamName(differentiationMagnitudeProp);
  const [[differentiationMagnitude]] = useSearchParam<RangeValue>(paramName, {
    defaultValue: [initialDifferentiationMagnitude, initialDifferentiationMagnitude],
    parser: 'number',
  });
  const rawSortByTitle = getTitle(sortBy, settings.customerName);
  /**
   * @todo Align "Products" vs "Number Of Products" all over the code base
   */
  const sortByTitle = rawSortByTitle === 'Products' ? 'Number Of Products' : rawSortByTitle;

  return {
    brandingIds: comperableItemIds,
    categoryIds,
    marketIds,
    periodIds,
    growthPoint,
    rangeFilterValues,
    tagTypeIds,
    sortByTitle,
    sortDirection,
    hiddenTagIds,
    differentiationMagnitude,
  };
}

function useExplorePageConfiguration(): ConfigurationExportContent {
  const settings = useRecoilValue(settingsState);
  const sourcePreset = useRecoilValue(explorePageSourcePresetState);
  const growthPreset = useRecoilValue(explorePageGrowthPresetState);
  const growthPoint = useRecoilValue(growthPointState);
  const tagTypeIds = useTagTypeIds();
  const filters = getFilters(useRecoilValue);
  const [sortDirection] = useSearchParam<SortDirection>('diamondChartSort', {
    defaultValue: settings.defaults.explorePage.searchParams.diamondChartSort,
  });
  const [sortBy] = useSearchParam<keyof typeof formatters>('tagValueProp', {
    defaultValue: settings.defaults.explorePage.searchParams.tagValueProp,
  });
  const [hiddenTagIds] = useSearchParam<number[]>('hiddenTagIds', {
    defaultValue: [],
    parser: 'number',
  });

  const sliderFiltersConfig = getExplorePageSliderFiltersConfig(false);
  const rangeFilterValues = getRangeFilterValues(sliderFiltersConfig);

  const getPresetsSelection = () => {
    const presetEntryMapper = (selectedPreset?: string) => (name: PresetName) => ({
      title: basePresetsConfig[name].title,
      isSelected: selectedPreset === name,
    });
    const presetSelection = [
      ...sourcePresetNames.map(presetEntryMapper(sourcePreset)),
      ...growthPresetNames.map(presetEntryMapper(growthPreset)),
    ];
    return presetSelection;
  };

  const presetsSelection = getPresetsSelection();
  const rawSortByTitle = getTitle(sortBy, settings.customerName);
  /**
   * @todo Align "Products" vs "Number Of Products" all over the code base
   */
  const sortByTitle = rawSortByTitle === 'Products' ? 'Number Of Products' : rawSortByTitle;

  const result = {
    ...filters,
    rangeFilterValues,
    growthPoint,
    presetsSelection,
    sortByTitle,
    sortDirection,
    tagTypeIds,
    hiddenTagIds,
  };

  return result;
}

function useConfigurationExportCsvContent(configuration: ConfigurationExportContent) {
  const {
    query,
    differentiationMagnitude,
    marketIds,
    categoryIds,
    periodIds,
    brandingIds,
    growthPoint,
    rangeFilterValues,
    presetsSelection = [],
    sortByTitle,
    sortDirection,
    tagTypeIds,
    hiddenTagIds,
  } = configuration;
  const tags = useRecoilValue(tagsState);
  const markets = useRecoilValue(marketsState);
  const periods = useRecoilValue(periodsState);
  const tagTypes = useRecoilValue(tagTypesState);
  const brandings = useRecoilValue(rawBrandingState);
  const categories = useRecoilValue(rawCategoriesState);

  const allValue = 'All';
  const getAllValue = (title: string) => [[title, allValue]];

  const getTagWithTypes = (tag: Tag) => {
    const types = Array.from(tag.types.values(), type => type.name).join(' / ');

    return `${tag.name} | ${types}`;
  };

  const getQueryString = (queryTokens: QueryToken[]) => {
    const queryParts = queryTokens.map(({ type, id }) => {
      const isOperand = type === 'operand';
      return isOperand ? getTagWithTypes(map.safeGet(tags, id)) : id;
    });

    return queryParts.join(' ');
  };

  const getItems = <T>(ids: number[] | undefined, itemsMap: Map<number, T>) =>
    iterator.definedMap(ids as number[], id => map.safeGet(itemsMap, id));

  const mappedMarkets = getItems(marketIds, markets);
  const mappedPeriods = getItems(periodIds, periods);
  const mappedTagTypes = getItems(tagTypeIds, tagTypes);
  const hiddenTags = getItems(hiddenTagIds, tags);

  const getHirarchialNames = <Entity extends SelectOption>(
    title: string,
    items: Map<number, Entity>,
    ids: number[] = []
  ) =>
    ids?.length
      ? ids.map(id => [title, getPathParts(map.safeGet(items, id)).join(', ')])
      : getAllValue(title);

  const getFormattedRangeValues = (startValue = missingValue, endValue = missingValue) => {
    const hasMissingValue = [startValue, endValue].includes(missingValue);
    const formattedRangeValues = hasMissingValue ? allValue : `${startValue} - ${endValue}`;

    return formattedRangeValues;
  };

  const getFilterNames = (title: string, items: string[]) =>
    items.length ? items.map(name => [title, name]) : getAllValue(title);

  const fileContent = [
    query && ['Query', getQueryString(queryToTokens(query))],
    ...getHirarchialNames('Category', categories, categoryIds),
    ...getFilterNames('Market', mappedMarkets),
    ...getFilterNames('Period', mappedPeriods),
    ...getHirarchialNames(brandingFilterTitle, brandings, brandingIds),
    ['Growth', growthPoint === 'lastYear' ? 'Year over year' : 'In period'],
    ...rangeFilterValues.map(({ title, startValue, endValue }) => [
      title,
      getFormattedRangeValues(startValue, endValue),
    ]),
    ...presetsSelection.map(({ title, isSelected }) => [
      string.capitalize(title),
      isSelected ? 'Selected' : 'Not Selected',
    ]),
    differentiationMagnitude && [
      'Differentiation Magnitude',
      'x' + differentiationMagnitude.toFixed(1),
    ],
    ['Sort By Parameter', sortByTitle],
    ['Sort By', sortDirection === 'asc' ? 'Ascending order' : 'Descending order'],
    ['Types', Array.from(mappedTagTypes, ({ name }) => name).join(', ')],
    ['Hidden Tags', hiddenTags.map(getTagWithTypes).join(', ')],
  ]
    .filter(Array.isArray)
    .map(([key, value]) => ({ Filter: key, Value: value }));

  return fileContent;
}

export function setToNames(items: Iterable<{ name: string }>) {
  return Array.from(items, ({ name }) => name).join(', ');
}

export function useExplorePageConfigurationExportContent() {
  const configuration = useExplorePageConfiguration();
  return useConfigurationExportCsvContent(configuration);
}

export function useComparePageConfigurationExportContent() {
  const configuration = useComparePageConfiguration();
  return useConfigurationExportCsvContent(configuration);
}
