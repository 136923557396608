import React, { memo } from 'react';
import styles from './StackedProgressBar.module.scss';
import { iterator } from '@harmonya/utils';

type Props = {
  values: Iterable<readonly [value: number, color?: string]>;
};

export const StackedProgressBar = memo(function StackedProgressBar(props: Props) {
  const valueElements = iterator.definedMap(props.values, ([flex, backgroundColor], i) =>
    flex ? <div key={i} className={styles.item} style={{ flex, backgroundColor }} /> : undefined
  );

  return <div className={styles.container}>{valueElements}</div>;
});
