import type { ComponentType } from 'react';
import React from 'react';
import type { Column } from './DataTableBase';
import styles from './DataTableHeaderCell.module.scss';
import { DataTableResizeHandle } from './DataTableResizeHandle';
import { useColumnWidthContext } from './context/ColumnWidthContext';

type Props<T extends Column> = {
  column: T;
  HeaderCellComponent: ComponentType<{ column: T; width?: number }>;
};

export function DataTableHeaderCell<T extends Column>(props: Props<T>) {
  const { column, HeaderCellComponent } = props;
  const { key } = column;
  const width = useColumnWidthContext(column.key);

  return (
    <div key={key} className={styles.headerCell} style={{ width }}>
      <HeaderCellComponent column={column} width={width} />
      <DataTableResizeHandle columnId={key} />
    </div>
  );
}
