import { object } from '@harmonya/utils';

const rootLevelCommas = /[^,\s+(]+(?:\([^)]*\))?/g;

export function getParsers(baseFontSize: string) {
  const remPx = parseFloat(baseFontSize);
  const parseRemToPx = (rem: string) => parseFloat(rem) * remPx;
  const parsers = {
    px: parseFloat,
    ms: parseFloat,
    rem: parseRemToPx,
    default: (value: string): string => value,
    icon: (value: string) =>
      value
        .replace('\\', '')
        .replace(/[0-9a-fA-F]{4}/, substring => String.fromCharCode(parseInt(substring, 16))),
  };

  return parsers;
}

type SingleOrArray<T> = T | T[];
type Result = {
  parsed: SingleOrArray<number> | SingleOrArray<string>;
  original: SingleOrArray<string>;
};

export function parseString(rawVariable: string, parsers: ReturnType<typeof getParsers>): Result {
  const [rawType, ...values] = rawVariable.match(rootLevelCommas) ?? [];

  if (!rawType) {
    throw 'The exported variable should be in the format of "{type}, [...{comma separated values}]"';
  }

  const [type, iteratorType] = rawType.split('-');
  const parser = object.getIfExists(parsers, type) ?? parsers.default;
  const computedValues = values.map(value => parser(value)) as string[] | number[];
  const result =
    iteratorType === 'list'
      ? { parsed: computedValues, original: values }
      : { parsed: computedValues[0], original: values[0] };

  return result;
}
