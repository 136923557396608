import { selector } from 'recoil';
import { fetchGet } from '../utils/fetch';
import { authState, customerIdState } from './auth';
import { explorePageMarketIdsDefaultState } from './settings';
import type { SelectOption } from '@harmonya/models';

export const marketsState = selector({
  key: 'markets',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: async ({ get }) => {
    const customerId = get(customerIdState);
    const { user } = get(authState);
    const explorePageMarketIdsDefault = get(explorePageMarketIdsDefaultState);
    const explorePageMarketIdsDefaultAsSet = new Set(explorePageMarketIdsDefault);
    const markets = await fetchGet<SelectOption[]>('/api/markets', customerId, user.email);
    const sortedMarkets = [...markets].sort((a, b) => {
      const hasA = explorePageMarketIdsDefaultAsSet.has(a.id);
      const hasB = explorePageMarketIdsDefaultAsSet.has(b.id);

      if (hasA !== hasB) {
        return hasA ? -1 : 1;
      }

      return a.name.localeCompare(b.name);
    });
    const sortedMarketEntries = sortedMarkets.map(({ id, name }) => [id, name] as const);
    const marketsMap = new Map(sortedMarketEntries);

    return marketsMap;
  },
});
