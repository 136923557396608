export type Falsy = false | 0 | '' | null | undefined;

export type InFull<T> = Required<T> | { [K in keyof T]?: never };

export const isDefined = <T>(item: T | undefined): item is T => item != null;

export const noop = () => undefined;

export const missingValue = 'N/A';

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends object ? RecursivePartial<T[P]> : T[P];
};

export type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };
