import type HighchartsReact from 'highcharts-react-official';
import type { Point } from 'highcharts';
import type { AggregateTag } from 'models/AggregateTag';
import type { Circle } from 'utils/circle';
import { circleYIntersections } from 'utils/circle';
import type { ComparableItem } from 'components/layout/comparisonPage/comparison-page.types';
import { styleVariables } from 'utils/styleVariables';
import styles from './TagsVenn.module.scss';
import { iterator, number } from '@harmonya/utils';

export const vennArrIndex = {
  // Indexes are ordered by the left => right, top => down order of the venn areas
  // from the venn diagram perspective:
  // V_1        C_12          V_2
  //    C_13    C_123    C_23
  //             V_3
  Venn_1: 0,
  Common_12: 1,
  Venn_2: 2,
  Common_13: 3,
  Common_123: 4,
  Common_23: 5,
  Venn_3: 6,
};

export const vennOrderBySets = [[0], [0, 1], [1], [0, 2], [0, 1, 2], [1, 2], [2]];

export type VennIndex = keyof typeof vennArrIndex;

export type ComparableTagVenn = {
  title: string;
  ids: number[];
  differentiatedTags: AggregateTag[];
};

export type VennSeries = {
  name: string;
  sets: string[];
  tags: AggregateTag[];
  value: number;
  color: string;
  className?: string;
};

export type VennPoint = Point & VennSeries;

export const generateSeries = (props: Partial<VennSeries>): VennSeries => ({
  name: '',
  tags: [],
  sets: [],
  value: 1,
  color: 'rgba(var(--theme-secondary-color-regular-rgb), 0.1)',
  ...props,
});

// From the venn diagram,
// map venn index to the corrosponding index of all the y intersection of all the circles
// by the number of intersection points
// const arr = [1, 12, 2, 13, 123, 23, 3]
export const vennYIntersectionIndex: {
  [vennId in VennIndex]: { [intersectionPointsCount: number]: number };
} = {
  Venn_1: {
    4: 0,
    5: 1,
    6: 0,
  },
  Common_12: { 4: 1 },
  Venn_2: {
    4: 2,
    5: 3,
    6: 4,
  },
  Common_13: { 6: 1 },
  Common_123: { 6: 2 },
  Common_23: { 6: 3 },
  Venn_3: { 2: 0 },
};

export const colors: { [vennId in VennIndex]: { [intersectionPointsCount: number]: string } } = {
  Venn_1: 'red',
  Common_12: 'blue',
  Venn_2: 'green',
  Common_13: 'gray',
  Common_123: 'yellow',
  Common_23: 'orange',
  Venn_3: 'purple',
};

// This function finds the minimal width of the row by the top and bottom intersections of the tag row
export function getMinimalWidthIntersectionsX(
  y: number,
  rowHeight: number,
  circle: Circle,
  isOwnCircles: boolean
) {
  const topIntersections = circleYIntersections(y, circle);
  const bottomIntersections = circleYIntersections(y + rowHeight, circle);

  if (topIntersections && bottomIntersections) {
    const [minTopX, maxTopX] = topIntersections;
    const [minBottomX, maxBottomX] = bottomIntersections;
    const minPicker = isOwnCircles ? Math.max : Math.min;
    const maxPicker = isOwnCircles ? Math.min : Math.max;
    const left = minPicker(minTopX, minBottomX);
    const right = maxPicker(maxTopX, maxBottomX);

    return [left, right];
  }

  if (topIntersections || bottomIntersections) {
    // Identify partial hight intersection
    return [-1];
  }
}

export function setsToVennKey(circlesIndex: number[]): VennIndex {
  const key =
    circlesIndex.length === 1
      ? `Venn_${circlesIndex[0] + 1}`
      : `Common_${[...circlesIndex]
          .sort()
          .map(index => index + 1)
          .join('')}`;

  return key as VennIndex;
}

// Find current x range for the row matching y value that intersect this circle and bound by other circles
// returns: [left, right] or undefined if the range doesnt exist
export function findRowRange(
  y: number,
  rowHeight: number,
  circlesIndexes: number[],
  majorCircles: Circle[]
): number[] {
  const intersections = iterator.definedMap(majorCircles, (circle, index) =>
    getMinimalWidthIntersectionsX(y, rowHeight, circle, circlesIndexes.includes(index))
  );
  const vennKey = setsToVennKey(circlesIndexes);
  const config = vennYIntersectionIndex[vennKey];
  const sortedX = intersections.flat().sort((a, b) => a - b);

  if (sortedX.length in config) {
    const intersectionPoint = config[sortedX.length];
    const left = sortedX[intersectionPoint];
    const right = sortedX[intersectionPoint + 1];

    return [left, right];
  }

  return [];
}

export const textPadding = 80;

export const tagsConfig = {
  tagHorizontalPadding: styleVariables.padding,
  tagVerticalPadding: styleVariables.padding,
  moreTagsIndicatorPadding: styleVariables.padding,
  moreTagsIndicatorHeight: 15,
  emptyStateHeight: 15,
  minYStepSize: 10, // Y step to increment when no tags placed in a row
  minimalTagWidth: 70, // Minimal width to add tag with elipsis
  circleValue: {
    // Controls the proportions of the circles
    major: 2,
    minor: 0.5,
  },
};

export function isMoreTagsIndicatorOnTop(majorsLength: number, sets: string[]) {
  const isIndicatorOnTopSets = majorsLength === 3 ? ['0', '1'] : [];

  return (
    isIndicatorOnTopSets &&
    isIndicatorOnTopSets.length === sets.length &&
    isIndicatorOnTopSets.every(set => sets.includes(set))
  );
}

export function getComputedVennPoints(
  { chart }: HighchartsReact.RefObject,
  container: HTMLDivElement | null
) {
  const points = chart.series[0].points as VennPoint[];

  const all = iterator.definedMap(points, ({ graphic, ...point }) => {
    if (graphic && container) {
      const containerRect = container.getBoundingClientRect();
      const { element } = graphic;
      const elementRect = element.getBoundingClientRect();
      const rect = {
        width: elementRect.width,
        height: elementRect.height,
        top: elementRect.top - containerRect.top,
        left: elementRect.left - containerRect.left,
        bottom: elementRect.bottom - containerRect.top,
        right: elementRect.right - containerRect.left,
        x: elementRect.x - containerRect.x,
        y: elementRect.y - containerRect.y,
      };

      return { ...point, graphic, rect };
    }
  });

  const [majors, minors] = iterator.split(all, point => point.graphic.element.tagName === 'circle');

  return { all, majors, minors };
}

export function getSeriesData(
  items: ComparableItem[],
  placeholders: string[],
  getTagHash: (tagId: number) => string,
  focusIndex?: number,
  hiddenTagHashes?: Set<string>
) {
  let seriesData = items.map(({ sets, name, differentiatedTags }, index) =>
    generateSeries({
      name,
      tags: hiddenTagHashes
        ? differentiatedTags.filter(tag => !hiddenTagHashes.has(getTagHash(tag.id)))
        : differentiatedTags,
      sets: sets.map(String),
      value: sets.length > 1 ? tagsConfig.circleValue.minor : tagsConfig.circleValue.major,
      className: index === focusIndex ? styles.focused : '',
    })
  );

  if (seriesData.length < 3) {
    seriesData = [
      seriesData[0] ??
        generateSeries({
          name: placeholders[0],
          sets: ['-1'],
          value: tagsConfig.circleValue.major,
        }),
      generateSeries({
        name: ' ',
        sets: [seriesData[0]?.sets[0] ? seriesData[0].sets[0] : '-1', '-2'],
        value: tagsConfig.circleValue.minor,
      }),
      generateSeries({
        name: placeholders[1],
        sets: ['-2'],
        value: tagsConfig.circleValue.major,
      }),
    ];
  }

  return seriesData;
}

export const getVennAreaTooltipText = (
  isSharedArea: boolean,
  differentiationMagnitude: number,
  majorNames: string[]
) =>
  isSharedArea
    ? `Explore shared tags that were found similarly significant within ${majorNames.join(' and ')}`
    : `Explore the tags that have been identified as differentiated tags based on their significance score,
    which shows more than ${number.fixedNumber(differentiationMagnitude)} times greater significance compared to the entity being compared`;
