import type { CSSProperties, PropsWithChildren, ReactNode, RefObject } from 'react';
import React, { useCallback, useImperativeHandle, useRef } from 'react';
import classNames from 'classnames';
import appStyles from '../../layout/App.module.scss';
import styles from './Dropdown.module.scss';
import { DropdownButton } from './DropdownButton';
import type { Align } from './DropdownWindow';
import { DropdownWindow } from './DropdownWindow';
import { useRecoilState } from 'recoil';
import { openedDropdownElementState } from 'store/dropdown';
import { useKey } from 'hooks/useKey';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { useIsHidden } from 'hooks/useIsHidden';
import type { DropDownDirection } from 'components/general/charts/types';

export interface DropdownRef {
  close?: () => void;
  open?: () => void;
}

type Props = PropsWithChildren<{
  buttonContent: ReactNode | ((isOpen: boolean) => ReactNode);
  className?: string;
  buttonContainerClassName?: string;
  dropdownBorderedClassName?: string;
  style?: CSSProperties;
  maxWidth?: string | number;
  minWidth?: string | number;
  direction?: DropDownDirection;
  buttonTooltip?: ReactNode;
  buttonRef?: RefObject<HTMLDivElement>;
  dropdownRef?: RefObject<DropdownRef>;
  inputRef?: RefObject<HTMLInputElement>;
  align?: Align;
  offset?: number;
  buttonMarkingEnabled?: boolean;
  onToggle?: () => void;
  isDraggable?: boolean;
}>;

export function Dropdown(props: Props) {
  const {
    buttonContent,
    className,
    buttonContainerClassName,
    dropdownBorderedClassName,
    style,
    maxWidth,
    minWidth,
    direction,
    buttonTooltip,
    buttonRef = useRef<HTMLDivElement>(null),
    dropdownRef = useRef<DropdownRef>({}),
    inputRef,
    align,
    offset,
    buttonMarkingEnabled,
    onToggle,
    children,
    isDraggable,
  } = props;
  const [openedDropdownElement, setOpenedDropdownElement] = useRecoilState(
    openedDropdownElementState
  );
  const ref = useRef<HTMLDivElement>(null);
  const isOpen = !!ref.current && ref.current === openedDropdownElement;
  const windowRef = useRef<HTMLDivElement>(null);
  const close = useCallback(() => {
    if (isOpen) {
      setOpenedDropdownElement(null);
    }
  }, [isOpen]);
  const open = useCallback(() => setOpenedDropdownElement(ref.current), [ref.current]);
  const toggle = useCallback(() => {
    if (isOpen) {
      close();
    } else {
      open();
    }

    onToggle?.();
  }, [isOpen]);

  useImperativeHandle(
    dropdownRef,
    () => ({
      close,
      open,
    }),
    [close]
  );

  useKey({ Escape: close }, [close]);

  useOnClickOutside(close, [windowRef, ref, inputRef], isOpen);

  useIsHidden(close, buttonRef);

  return (
    <div
      ref={ref}
      style={{ ...style, maxWidth }}
      className={classNames(appStyles.positionRelative, className)}
    >
      <DropdownButton
        className={classNames(
          isOpen && buttonMarkingEnabled && styles.buttonMarked,
          buttonContainerClassName
        )}
        containerRef={buttonRef}
        content={typeof buttonContent === 'function' ? buttonContent(isOpen) : buttonContent}
        tooltip={buttonTooltip}
        onClick={toggle}
      />
      <DropdownWindow
        windowRef={windowRef}
        content={children}
        open={open}
        isOpen={isOpen}
        buttonRef={buttonRef}
        inputRef={inputRef}
        direction={direction}
        align={align}
        minWidth={minWidth}
        maxWidth={maxWidth}
        offset={offset}
        isDraggable={isDraggable}
        borderedClassName={dropdownBorderedClassName}
      />
    </div>
  );
}
