import classNames from 'classnames';
import React from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import type { TransitionStatus } from 'react-transition-group';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import styles from './AnimatedLayout.module.scss';
import { styleVariables } from 'utils/styleVariables';

export function AnimatedLayout() {
  const location = useLocation();
  const currentOutlet = useOutlet();

  return (
    <SwitchTransition>
      <CSSTransition
        timeout={{ exit: styleVariables.animationDuration }}
        classNames={styles.main}
        key={location.pathname}
        unmountOnExit
      >
        {(state: TransitionStatus) => {
          return <div className={classNames(styles.AnimatedLayout, state)}>{currentOutlet}</div>;
        }}
      </CSSTransition>
    </SwitchTransition>
  );
}
