import type { RecursivePartial } from './general';

export const isObject = (object: unknown): object is Record<string, unknown> =>
  object != null && typeof object === 'object';

export const isEmpty = (obj: object) => {
  // eslint-disable-next-line no-unreachable-loop
  for (const _ in obj) {
    return false;
  }

  return true;
};

export const getKeysLength = (obj: Record<string, unknown>) => {
  let i = 0;

  for (const _ in obj) {
    i++;
  }

  return i;
};

type ObjectKeys<T> = T extends Array<unknown> | string
  ? string[]
  : T extends object
    ? (keyof T)[]
    : T extends number | boolean
      ? []
      : never;

export function keys<T>(obj: T): ObjectKeys<T>;

export function keys(obj: null): never;

// Number: keys(1) => type: [], actual: []
// String: keys('abc') => type: string[], actual: ['0', '1', '2']
// Boolean: keys(true) => type: [], actual: []
// Array: keys([1, 2, 3]) => type: string[], actual: ['0', '1', '2']
// Object: keys({ a: 1, b: 2, c: 3 }) => type: ('a' | 'b' | 'c')[], actual: ['a', 'b', 'c']
// Null: keys(null) => type: never, actual: throws an error
// Undefined: keys(undefined) => type: never, actual: throws an error
export function keys<T extends object>(obj: T): ObjectKeys<T> {
  return Object.keys(obj) as ObjectKeys<T>;
}

// delete keys with undefined or empty array value
export function definedKeys<T extends object>(obj: T) {
  for (const key in obj) {
    const value = obj[key as keyof typeof obj];

    if (Array.isArray(value) && 'length' in value ? !value.length : !value) {
      delete obj[key as keyof typeof obj];
    }
  }

  return obj;
}

type Type = 'bigint' | 'boolean' | 'number' | 'string' | 'symbol' | 'object';

type TypeByName<T extends Type> = T extends 'bigint'
  ? bigint
  : T extends 'boolean'
    ? boolean
    : T extends 'function'
      ? typeof Function
      : T extends 'number'
        ? number
        : T extends 'string'
          ? string
          : T extends 'symbol'
            ? symbol
            : T extends 'object'
              ? object
              : never;

export const getIfMatch = <T extends Type>(
  object: unknown,
  path: string,
  type: T
): undefined | TypeByName<T> => {
  const pathSegments = path.split('.');
  let currentObject: unknown = object;

  for (const segment of pathSegments) {
    const hasKeys =
      (typeof currentObject !== 'object' && typeof currentObject !== 'function') ||
      currentObject === null ||
      !(segment in currentObject);

    if (hasKeys) {
      return;
    }

    currentObject = (currentObject as Record<string, unknown>)[segment];
  }

  if (typeof currentObject === type) {
    return currentObject as TypeByName<T>;
  }
};

export function findFirstValueRecursively<T>(
  obj: unknown,
  predicate: (value: unknown) => boolean
): T | null {
  if (predicate(obj)) {
    return obj as T;
  }

  if (Array.isArray(obj)) {
    for (const item of obj) {
      const found = findFirstValueRecursively(item, predicate);

      if (found !== null) {
        return found as T;
      }
    }
  } else if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const found = findFirstValueRecursively((obj as Record<string, unknown>)[key], predicate);

        if (found != null) {
          return found as T;
        }
      }
    }
  }

  return null;
}

export const alwaysFalse = () => false;

export const alwaysTrue = () => true;

export function getOrCreate<K extends string | symbol | number, V>(
  obj: Record<K, V>,
  key: K,
  newValueGetter: () => V
) {
  if (key in obj) {
    return obj[key];
  }

  const newValue = newValueGetter();

  obj[key] = newValue;

  return newValue;
}

export function ensuredIncrement<K extends string | symbol | number>(
  obj: Record<K, number>,
  key: K,
  value: number
) {
  obj[key] ??= 0;
  obj[key] += value;

  return obj[key];
}

const isIn = (obj: object, key: string | symbol | number): key is keyof typeof obj => key in obj;

export function getIfExists<T extends object>(
  obj: T,
  prop: string | number | symbol
): T[keyof T] | undefined {
  if (isIn(obj, prop)) {
    return obj[prop];
  }
}

export function nestedOverride<T extends object>(original: T, override: RecursivePartial<T>) {
  for (const key in override) {
    if (Object.prototype.hasOwnProperty.call(override, key)) {
      const originalNested = original[key];
      const overrideNested = override[key];

      if (isObject(originalNested) && !Array.isArray(originalNested) && isObject(overrideNested)) {
        nestedOverride(originalNested, overrideNested as RecursivePartial<typeof originalNested>);
      } else {
        original[key] = overrideNested as typeof originalNested;
      }
    }
  }
}
