import type { AtomEffect, AtomOptions, RecoilState } from 'recoil';
import { atom, DefaultValue } from 'recoil';
import { applyIfHasChange } from '../hooks/useSearchParam';
import { StorageListener } from './storageListener';

interface Parser {
  constructor: { new (...args: unknown[]): never };
  parser: (value: never) => never;
}

const dataTypeKey = '$$dataType';
const dataTypesMapper = new Map<string, Parser>([
  ['Map', { constructor: Map as never, parser: (value: Map<never, never>) => [...value] as never }],
  ['Set', { constructor: Set as never, parser: (value: Set<never>) => [...value] as never }],
  ['Date', { constructor: Date as never, parser: (value: never) => value }],
]);

export const storageAtom = <T>(options: AtomOptions<T>, storage = localStorage): RecoilState<T> => {
  const defaultValue = 'default' in options ? options.default : undefined;

  const getStorageValue = () => {
    const stringifiedValue = storage[options.key];
    const parsedValue =
      stringifiedValue &&
      JSON.parse(stringifiedValue, (key, value) => {
        const constructor = dataTypesMapper.get(value?.[dataTypeKey])?.constructor;

        return constructor ? new constructor(value.value) : value;
      });

    return parsedValue as T;
  };

  const setStorageValue = (value: T) => {
    const stringifiedValue = JSON.stringify(value, (key, currentValue) => {
      const constructorName = currentValue?.constructor?.name;
      const parser = constructorName && dataTypesMapper.get(constructorName)?.parser;

      return parser
        ? { [dataTypeKey]: constructorName, value: parser(currentValue as never) }
        : currentValue;
    });

    storage[options.key] = stringifiedValue;
  };

  const storageEffect: AtomEffect<T> = ({ setSelf, onSet }) => {
    const value = getStorageValue();
    let latestValue = value;

    if (value != null) {
      setSelf(value);
    }

    onSet((newValue, oldValue, isReset) => {
      latestValue = newValue;

      delete storage[options.key];

      if (!isReset) {
        applyIfHasChange(newValue, defaultValue, () => setStorageValue(newValue));
      }
    });

    const storageListener = new StorageListener(storage);

    return storageListener.add(options.key, () => {
      const newValue = getStorageValue();

      // Without this condition, loaders will appear whenever the storage changes, even if there is nothing to load
      applyIfHasChange(newValue, latestValue, () => setSelf(newValue ?? new DefaultValue()));
    });
  };

  const newOptions = {
    ...options,
    effects: [...(options.effects ?? []), storageEffect],
  };

  return atom(newOptions);
};
