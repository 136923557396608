import React, { type ReactNode, type PropsWithChildren } from 'react';
import styles from './AttributionAnalyticsPanelExpandableCard.module.scss';
import { AttributionAnalyticsPanelCardHeader as CardHeader } from './AttributionAnalyticsPanelCardHeader';
import { useBooleanState } from 'hooks/useBooleanState';
import classNames from 'classnames';
import type { SetterOrUpdater } from 'recoil';
import type { ColumnId } from 'store/attribution.types';

type Props = {
  attributeKey: ColumnId;
  displayedTitle: ReactNode;
  accuracy: number;
  performanceBoostingTag?: number;
  setPerformanceBoostingTag: SetterOrUpdater<number | undefined>;
};

export function AttributionAnalyticsPanelExpandableCard(props: PropsWithChildren<Props>) {
  const { attributeKey, performanceBoostingTag, setPerformanceBoostingTag, children } = props;
  const [isExpanded, , , toggleCard] = useBooleanState(false);
  const isSelected = performanceBoostingTag === attributeKey;

  const handlePerformanceBoostingTagClick = () =>
    setPerformanceBoostingTag(isSelected ? undefined : attributeKey);

  return (
    <div className={classNames(styles.container, isSelected && styles.selected)}>
      <CardHeader
        {...props}
        isSelected={isSelected}
        isExpanded={isExpanded}
        onToggle={toggleCard}
        onPerformanceBoostingTagClick={handlePerformanceBoostingTagClick}
      />
      <div className={classNames(styles.content, isExpanded && styles.expanded)}>{children}</div>
    </div>
  );
}
