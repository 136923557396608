import { selector } from 'recoil';
import { salesSeriesToSalesSeriesItems } from '../utils/timeSeriesConverter';
import { productsState } from './products';

export const productSalesState = selector({
  key: 'productSales',
  get: ({ get }) => {
    const products = get(productsState);

    if (!products.metadata) {
      return {
        totalSalesItems: [],
        salesGrowthItems: { inPeriod: [] },
      };
    }

    const { productsSalesData } = products.metadata;
    const mappedProductSales = salesSeriesToSalesSeriesItems(productsSalesData);

    return mappedProductSales;
  },
});
