import React, { type ReactNode } from 'react';
import styles from './HighlightTexts.module.scss';
import type { Highlightable } from 'utils/sortedFilteredOptions';
import { string } from '@harmonya/utils';

type Wrapper = (elements: ReactNode) => ReactNode;

const defaultWrapper: Wrapper = e => e;

type Props = {
  items?: Highlightable[];
  defaultText?: ReactNode;
  children?: Wrapper;
};

export function HighlightTexts(props: Props) {
  const { items, defaultText = null, children = defaultWrapper } = props;
  const defaultTextElement = string.isString(defaultText) ? (
    <span>{defaultText}</span>
  ) : (
    defaultText
  );

  return items?.length
    ? children(
        items.map(({ node, isHighlighted }, i) =>
          isHighlighted ? (
            <span key={i} className={styles.highlightText}>
              {node}
            </span>
          ) : (
            <React.Fragment key={i}>{node}</React.Fragment>
          )
        )
      )
    : defaultTextElement;
}
