import { useEffect } from 'react';
import { useBooleanState } from './useBooleanState';

export const useIsFocused = (inputRef: React.RefObject<Element>) => {
  const [isFocused, focus, blur] = useBooleanState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const options = { signal: abortController.signal };

    if (inputRef.current) {
      inputRef.current.addEventListener('focus', focus, options);
      inputRef.current.addEventListener('blur', blur, options);
    }

    return () => abortController.abort();
  }, []);

  return isFocused;
};
