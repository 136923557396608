import { join } from 'components/general/Divider';
import { TrafficLightsStackedProgressBar } from 'components/general/TrafficLightsStackedProgressBar';
import React from 'react';
import type { ConfidenceLevel } from 'store/attribution.types';
import {
  cellConfidenceLevelFilterState,
  cellConfidenceLevelFilters,
  cellStatusFilterState,
  cellStatusFilters,
  cellValidationFilterState,
  cellValidationFilters,
} from 'store/attributionPage';
import { attributionConfidenceLevelIcons } from './attribution.utils';
import { AttributionCellConfidenceLevel } from './AttributionCellConfidenceLevel';
import styles from './AttributionHeaderCellFilters.module.scss';
import { AttributionHeaderCellFiltersFilterGroup } from './AttributionHeaderCellFiltersFilterGroup';
import type { FilterSection } from './AttributionHeaderCellFiltersFilterSection';
import { AttributionHeaderCellFiltersFilterSection } from './AttributionHeaderCellFiltersFilterSection';
import { AttributionHeaderCellFiltersOptionsSection } from './AttributionHeaderCellFiltersOptionsSection';
import { useRecoilValue } from 'recoil';
import { AttributionHeaderCellFiltersSortSection } from './AttributionHeaderCellFiltersSortSection';

type Props = {
  columnId: number;
  isPredicted: boolean;
};

const confidenceLevelFormatter = (key: ConfidenceLevel) => {
  const { title } = attributionConfidenceLevelIcons[key];

  return (
    <>
      <AttributionCellConfidenceLevel level={key} />
      {title}
    </>
  );
};

const confidenceLevelProgressBarGetter = ({
  low,
  medium,
  high,
}: Record<ConfidenceLevel, number>) => (
  <div className={styles.confidenceLevelProgressBar}>
    <TrafficLightsStackedProgressBar success={high} warning={medium} error={low} />
  </div>
);

export function AttributionHeaderCellFilters(props: Props) {
  const { columnId, isPredicted } = props;
  const columnSelectedStatuses = useRecoilValue(cellStatusFilterState(columnId));

  const predictedFilters = isPredicted
    ? [
        <AttributionHeaderCellFiltersFilterSection
          key='Filters'
          title='Filters'
          columnId={columnId}
          setFilterState={cellStatusFilterState}
          options={cellStatusFilters}
        />,
        <AttributionHeaderCellFiltersFilterGroup
          key='Predicted Cells'
          title='Predicted Cells'
          items={
            [
              {
                title: 'Validation',
                columnId: columnId,
                setFilterState: cellValidationFilterState,
                options: cellValidationFilters,
              },
              {
                title: 'Confidence Level',
                columnId: columnId,
                setFilterState: cellConfidenceLevelFilterState,
                options: cellConfidenceLevelFilters,
                labelFormatter: confidenceLevelFormatter,
                children: confidenceLevelProgressBarGetter,
              },
            ] as FilterSection<string>[]
          }
          isDisabled={columnSelectedStatuses.size > 0 && !columnSelectedStatuses.has('Predicted')}
        />,
      ]
    : [];

  return (
    <div className={styles.container}>
      {join(
        [
          ...predictedFilters,
          <AttributionHeaderCellFiltersOptionsSection key='Options' columnId={columnId} />,
          <AttributionHeaderCellFiltersSortSection key='Sort By' columnId={columnId} />,
        ],
        { direction: 'horizontal', className: styles.divider }
      )}
    </div>
  );
}
