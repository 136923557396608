import type { PropsWithChildren } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './Scroller.module.scss';
import appStyles from '../layout/App.module.scss';
import { useResize } from 'hooks/useResize';
import { Icon } from 'components/general/Icon';

type Direction = 'horizontal' | 'vertical';
type Side = 'start' | 'end';

const scrollStep = 250;
const directionProps = {
  horizontal: {
    size: 'clientWidth',
    scrollSize: 'scrollWidth',
    scrollFrom: 'scrollLeft',
    scrollTo: 'left',
    arrow: { start: 'left', end: 'right' },
  },
  vertical: {
    size: 'clientHeight',
    scrollSize: 'scrollHeight',
    scrollFrom: 'scrollTop',
    scrollTo: 'top',
    arrow: { start: 'up', end: 'down' },
  },
} as const;

type Props = PropsWithChildren<{
  direction: Direction;
  className?: string;
  title?: string;
}>;

export function Scroller(props: Props) {
  const { direction, title, className, children } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [overflow, setOverflow] = useState({ start: false, end: false });
  const rect = useResize(ref);
  const { size, scrollSize, scrollFrom, scrollTo, arrow } = directionProps[direction];

  const setOverflowIfNeeded = () => {
    const element = ref.current;

    if (element) {
      const start = element[scrollFrom] > 0;
      const end = element[scrollSize] - element[scrollFrom] > element[size];
      const hasChange = overflow.start !== start || overflow.end !== end;

      if (hasChange) {
        setOverflow({ start, end });
      }
    }
  };

  useEffect(setOverflowIfNeeded, [rect]);

  const scrollManually = (side: Side) => {
    const element = ref.current;

    if (element) {
      const sideFactor = side === 'start' ? -1 : 1;
      const newScrollAmount = element[scrollFrom] + scrollStep * sideFactor;

      element.scroll({ [scrollTo]: newScrollAmount, behavior: 'smooth' });
    }
  };

  const renderButton = (side: Side) => {
    const button = (
      <button
        disabled={!overflow[side]}
        className={classNames(appStyles.button, appStyles.horizontalFlex, appStyles.alignCenter)}
        onMouseDown={() => scrollManually(side)}
      >
        <Icon name={`chevron-${arrow[side]}`} />
      </button>
    );

    return title && side === 'start' ? (
      <div
        className={classNames(
          appStyles.horizontalFlex,
          appStyles.alignCenter,
          styles.titleButtonContainer,
          appStyles.gap1
        )}
      >
        <span className={styles.title}>{title}</span>
        {button}
      </div>
    ) : (
      button
    );
  };

  return (
    <div className={classNames(styles.container, className)} dir={direction}>
      {renderButton('start')}
      <div ref={ref} className={styles.innerContainer} onScroll={setOverflowIfNeeded}>
        {children}
      </div>
      {renderButton('end')}
    </div>
  );
}
