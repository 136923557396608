import type { MouseEventHandler } from 'react';
import React, { memo, useContext } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { onDrag } from 'utils/onDrag';
import styles from './DataTableResizeHandle.module.scss';
import { ColumnWidthsContext } from './context/ColumnWidthContext';

type Props = {
  columnId: number;
};

export const DataTableResizeHandle = memo(function DataTableResizeHandle(props: Props) {
  const { columnId } = props;
  const context = useContext(ColumnWidthsContext);

  if (!context) {
    return null;
  }

  const { columnWidthState, columnIdMaxWidthRecoilState } = context;
  const setColumnWidth = useSetRecoilState(columnWidthState(columnId));
  const columnIdMaxWidth = useRecoilValue(columnIdMaxWidthRecoilState(columnId));

  const onResize = onDrag({
    className: styles.resizing,
    initDataGetter: (clientX: number, width: number) => ({ width, clientX, columnId }),
    handler: (clientX, { ...init }) => {
      const columnWidth = clientX - init.clientX + init.width;

      setColumnWidth(columnWidth);
    },
  });

  const mouseDownHandler: MouseEventHandler = event => {
    const columnElement = event.currentTarget.parentElement;

    if (columnElement) {
      const { width } = columnElement.getBoundingClientRect();
      onResize(event.clientX, width);
    }
  };

  return (
    <div
      className={styles.resizeHandle}
      onDoubleClick={() => setColumnWidth(columnIdMaxWidth)}
      onMouseDown={mouseDownHandler}
    />
  );
});
