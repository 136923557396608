import classNames from 'classnames';
import React from 'react';
import appStyles from '../App.module.scss';
import styles from './ComparisonPageTypeFilter.module.scss';
import { TagTypesFilter } from '../../general/TagTypesFilter';
import { useRecoilValueLoadableState } from '../../../hooks/useRecoilValueLoadable';
import type { AggregateTag } from '../../../models/AggregateTag';
import { aggregateTagsByComparableItemsState } from '../../../store/tags';
import type { TagType } from '../../../models/TagType';
import { Scroller } from 'components/general/Scroller';
import { tagTypesState } from 'store/tagTypes';
import { useTagTypeActiveIds } from 'hooks/useTagTypeActiveIds';

type Props = {
  onChange: (newValue: Set<TagType['id']>) => void;
  sliderFilterFunc: (tags: Iterable<AggregateTag>) => AggregateTag[];
};

export function ComparisonPageTypeFilter(props: Props) {
  const { onChange, sliderFilterFunc } = props;
  const allTagTypes = useRecoilValueLoadableState<Map<number, TagType>>(tagTypesState);
  const tags = useRecoilValueLoadableState<Map<AggregateTag['id'], AggregateTag>>(
    aggregateTagsByComparableItemsState
  );
  const [activeTagTypeIds] = useTagTypeActiveIds(allTagTypes);
  const isLoading = !tags || !allTagTypes;

  if (isLoading) {
    return null;
  }

  const titleActiveTagTypesCount = activeTagTypeIds.size ? `${activeTagTypeIds.size}/` : '';
  const title = `Tag Types (${titleActiveTagTypesCount}${allTagTypes?.size})`;

  return (
    <Scroller direction='horizontal' title={title}>
      <TagTypesFilter
        tags={sliderFilterFunc(tags.values())}
        containerClassName={classNames(appStyles.horizontalFlex, styles.container)}
        onChange={onChange}
      />
    </Scroller>
  );
}
