import { selector } from 'recoil';
import { fetchPost } from '../utils/fetch';
import { getSalesFilters } from './explorePage';
import { authState, customerIdState } from './auth';
import type {
  Growth,
  SalesOverview,
  SalesOverviewItem,
  SalesOverviewItems,
} from '@harmonya/models';

export type SalesOverviewItemsMap = Map<SalesOverviewItem['id'], SalesOverviewItem['value']>;

export type MarketsMapSalesState = {
  totalSalesItems: SalesOverviewItemsMap;
  salesGrowthItems: Growth<SalesOverviewItemsMap>;
};

const salesOverviewItemsToMap = (items: SalesOverviewItems) =>
  new Map(items.map(({ id, value }) => [id, value]));

export const marketsMapSalesState = selector({
  key: 'marketsMapSales',
  get: async ({ get }) => {
    // Ignore market ids from sales filter
    const { marketIds, salesLimit, ...filters } = getSalesFilters(get);
    const customerId = get(customerIdState);
    const { user } = get(authState);
    const marketsMapSalesServer = await fetchPost<SalesOverview>(
      '/api/sales/markets/overview',
      customerId,
      user.email,
      filters
    );
    const mappedMarketSales = {
      totalSalesItems: salesOverviewItemsToMap(marketsMapSalesServer.totalSalesItems),
      salesGrowthItems: {
        inPeriod: salesOverviewItemsToMap(marketsMapSalesServer.salesGrowthItems.inPeriod),
        lastYear: marketsMapSalesServer.salesGrowthItems.lastYear
          ? salesOverviewItemsToMap(marketsMapSalesServer.salesGrowthItems.lastYear)
          : undefined,
      },
    };

    return mappedMarketSales;
  },
});
