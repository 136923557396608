import React from 'react';
import { AttributionHeaderCellFiltersSection } from './AttributionHeaderCellFiltersSection';
import {
  AttributionHeaderCellFiltersFilterSection,
  type FilterSection,
} from './AttributionHeaderCellFiltersFilterSection';
import styles from './AttributionHeaderCellFiltersFilterGroup.module.scss';
import appStyles from '../App.module.scss';
import classNames from 'classnames';

type Props = {
  title: string;
  items: FilterSection<string>[];
  isDisabled?: boolean;
};

export function AttributionHeaderCellFiltersFilterGroup(props: Props) {
  const { title, items, isDisabled } = props;

  return (
    <div className={classNames(isDisabled && appStyles.disabled)}>
      <AttributionHeaderCellFiltersSection title={title}>
        <div className={styles.container}>
          {items.map(item => (
            <AttributionHeaderCellFiltersFilterSection key={item.title} isGroupedFilter {...item} />
          ))}
        </div>
      </AttributionHeaderCellFiltersSection>
    </div>
  );
}
