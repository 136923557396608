import type { ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';
import styles from './SelectSelectedOption.module.scss';
import appStyles from '../../layout/App.module.scss';
import { Icon } from '../Icon';
import type { Option, RawOptionType } from 'components/general/select/types';

type Props<T extends Option<RawOptionType>> = {
  option: T;
  onRemove: (option: T) => void;
  optionClassNameGetter?: (option: T) => string;
  displayValueGetter: (option: T) => ReactNode;
};

export function SelectSelectedOption<T extends Option<RawOptionType>>(props: Props<T>) {
  const { option, onRemove, optionClassNameGetter, displayValueGetter } = props;

  const onClick = (event: React.MouseEvent) => {
    onRemove(option);
    event.stopPropagation();
  };

  const className = classNames(
    appStyles.horizontalFlex,
    appStyles.gap0,
    appStyles.box,
    appStyles.alignCenter,
    styles.container,
    optionClassNameGetter?.(option)
  );

  return (
    <li className={className}>
      <span className={appStyles.ellipsis}>
        {displayValueGetter ? displayValueGetter(option) : option.displayValue}
      </span>
      <Icon name='xmark' className={styles.remove} onClick={onClick} />
    </li>
  );
}
