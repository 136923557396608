import React from 'react';
import analyticsStyles from '../AttributionAnalyticsPanel.module.scss';
import styles from './AttributionAnalyticsPanelCardStat.module.scss';
import { number } from '@harmonya/utils';

type Props = {
  title: string;
  value: number;
};

export function AttributionAnalyticsPanelCardStat(props: Props) {
  const { title, value } = props;

  return (
    <div className={styles.container}>
      <h4 className={analyticsStyles.panelTitle}>{title}</h4>
      <h1>{number.singleDigitFractionPercent(value)}</h1>
    </div>
  );
}
