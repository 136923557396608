import React, { type PropsWithChildren, type ReactNode } from 'react';
import appStyles from '../App.module.scss';
import styles from './AttributionHeaderCellFiltersSection.module.scss';
import { Icon } from 'components/general/Icon';
import classNames from 'classnames';
import { useBooleanState } from 'hooks/useBooleanState';

type Props = PropsWithChildren<{
  headerAdditionalContent?: ReactNode;
  title?: string;
  scrollbarDisplayed?: boolean;
  isGroupedFilter?: boolean;
}>;

export function AttributionHeaderCellFiltersSection(props: Props) {
  const { headerAdditionalContent, title, scrollbarDisplayed, isGroupedFilter, children } = props;
  const [isOpen, , , toggleOpen] = useBooleanState(true);

  const openButton = (
    <button onClick={toggleOpen} className={classNames(styles.openButton, isOpen && styles.open)}>
      <Icon name='chevron-down' weight='regular' className={appStyles.mediumFont} />
    </button>
  );

  return (
    <div>
      <div className={styles.header}>
        {title && (
          <h4 className={isGroupedFilter ? styles.secondaryTitle : styles.primaryTitle}>{title}</h4>
        )}
        {headerAdditionalContent}
        {!isGroupedFilter && openButton}
      </div>
      <div
        className={classNames(
          styles.body,
          isOpen && styles.open,
          scrollbarDisplayed && styles.scrollbarDisplayed
        )}
      >
        {children}
      </div>
    </div>
  );
}
