import React, { useEffect } from 'react';
import { Capsule } from './Capsule';
import { tagTypesState } from '../../store/tagTypes';
import type { TagType } from '../../models/TagType';
import type { AggregateTag } from '../../models/AggregateTag';
import { useRecoilValueLoadableState } from '../../hooks/useRecoilValueLoadable';
import { isDeepEqual } from 'utils/isDeepEqual';
import './TagTypesFilter.module.scss';
import { useTagTypeActiveIds } from 'hooks/useTagTypeActiveIds';
import { iterator, set } from '@harmonya/utils';

const getTagTypes = (tags: Iterable<AggregateTag>) => {
  const computedTagTypes = new Map<number, number>();

  for (const tag of tags) {
    for (const tagType of tag.types.values()) {
      const count = (computedTagTypes.get(tagType.id) ?? 0) + 1;

      computedTagTypes.set(tagType.id, count);
    }
  }

  return computedTagTypes;
};

type Props = {
  tags: Iterable<AggregateTag>;
  onChange: (newValue: Set<TagType['id']>) => void;
  containerClassName?: string;
};

export function TagTypesFilter(props: Props) {
  const { tags, onChange, containerClassName } = props;
  const allTagTypes = useRecoilValueLoadableState<Map<number, TagType>>(tagTypesState);
  const [activeTagTypeIds, setActiveTagTypeIds] = useTagTypeActiveIds(allTagTypes);
  const isLoading = !allTagTypes;

  const resetActiveType = () => {
    onChange(new Set());
    setActiveTagTypeIds([]);
  };

  const tagTypes = getTagTypes(tags);

  if (allTagTypes) {
    allTagTypes.forEach((_, id) => {
      if (!tagTypes.has(id)) {
        tagTypes.set(id, 0);
      }
    });
  }

  useEffect(() => {
    if (tags) {
      const newTagTypeIds = iterator.filter(activeTagTypeIds, tagTypeId => tagTypes.has(tagTypeId));
      const hasChanged = isDeepEqual(newTagTypeIds, activeTagTypeIds);

      if (hasChanged) {
        setActiveTagTypeIds(newTagTypeIds);
      }
    }
  }, [tags]);

  if (isLoading) {
    return null;
  }

  const sortedTagTypes = [...tagTypes].sort(
    ([, aTagsCount], [, bTagsCount]) => bTagsCount - aTagsCount
  );

  const toggleActiveTagTypeId = (id: TagType['id']) => {
    const newActiveTagTypeIds = set.toggleSet(activeTagTypeIds, id);

    setActiveTagTypeIds(newActiveTagTypeIds);
    onChange(new Set(newActiveTagTypeIds));
  };

  return (
    <div className={containerClassName} onClick={resetActiveType}>
      {iterator.definedMap(sortedTagTypes, ([id, tagsCount]) => {
        const tagType = allTagTypes.get(id);

        if (tagType) {
          const { name, colorId } = tagType;

          return (
            <Capsule
              id={id}
              key={id}
              text={name}
              value={tagsCount}
              isActive={activeTagTypeIds.has(id)}
              isInactive={!tagsCount}
              onClick={toggleActiveTagTypeId}
              colorId={colorId}
            />
          );
        }
      })}
    </div>
  );
}
