import { env } from '../../env';
import { useEffect, useState } from 'react';
import type { RecoilState, RecoilValueReadOnly, Loadable } from 'recoil';
import { useRecoilStateLoadable, useRecoilValueLoadable } from 'recoil';

const loaderTimeout = env.LOADER_TIMEOUT ?? 3000;

const use = <T>(loadable: Loadable<T>, initialState?: T | (() => T)) => {
  const [value, setValue] = useState<T | undefined>(initialState);
  const [timeoutId, setTimeoutId] = useState<number>();

  useEffect(() => {
    switch (loadable.state) {
      case 'hasValue':
        clearTimeout(timeoutId);
        setValue(loadable.contents);
        break;
      case 'loading':
        clearTimeout(timeoutId);

        setTimeoutId(() => {
          const newTimeoutId = window.setTimeout(() => {
            if (value != null) {
              setValue(undefined);
            }
          }, loaderTimeout);

          // If the promise has already been fulfilled, we should cancel the timeout
          void loadable.contents.then(() => clearTimeout(newTimeoutId));

          return newTimeoutId;
        });

        break;
      case 'hasError':
        throw loadable.contents;
    }
  }, [loadable.state, loadable.contents]);

  return value;
};

export const useRecoilStateLoadableState = <T>(
  state: RecoilState<T>,
  initialState?: T | (() => T)
) => {
  const [loadable, setLoadable] = useRecoilStateLoadable(state);
  const value = use(loadable, initialState);

  return [value, setLoadable] as const;
};

export const useRecoilValueLoadableState = <T>(
  state: RecoilValueReadOnly<T>,
  initialState?: T | (() => T)
) => {
  const loadable = useRecoilValueLoadable(state);
  const value = use(loadable, initialState);

  return value;
};

export const isLoading = (value: unknown): value is undefined => value === undefined;

export const isLoaded = <T>(value: T | undefined): value is T => !isLoading(value);
