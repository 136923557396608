import type { RefObject } from 'react';
import { useEffect } from 'react';

export function useIsHidden(onHide: () => void, targetElementRef: RefObject<HTMLDivElement>) {
  useEffect(() => {
    const element = targetElementRef.current;

    if (element) {
      let first = false;
      const intersectionObserver = new IntersectionObserver(() => {
        if (first) {
          onHide();
        } else {
          first = true;
        }
      });

      intersectionObserver.observe(element);

      return () => intersectionObserver.disconnect();
    }
  }, [targetElementRef, onHide]);
}
