import { iterator, map } from '@harmonya/utils';

type Listener = (newValue: string, oldValue: string) => void;
type Config = { intervalId: number; listeners: Map<string, Set<Listener>> };

export class StorageListener {
  static #configs = new Map<Storage, Config>();
  static #timeout = 1000;
  #storage: Storage;

  constructor(storage: Storage) {
    this.#storage = storage;
  }

  #start() {
    return map.getOrCreateMap(StorageListener.#configs, this.#storage, () => {
      let currentStorage = { ...this.#storage };
      const intervalId = window.setInterval(() => {
        const { listeners } = map.safeGet(StorageListener.#configs, this.#storage);

        for (const [key, keyListeners] of listeners) {
          const newValue = this.#storage[key];
          const oldValue = currentStorage[key];

          if (oldValue !== newValue) {
            keyListeners.forEach(listener => listener(newValue, oldValue));
          }
        }

        currentStorage = { ...this.#storage };
      }, StorageListener.#timeout);

      return {
        intervalId,
        listeners: new Map(),
      };
    });
  }

  #stop() {
    const { intervalId } = map.safeGet(StorageListener.#configs, this.#storage);

    StorageListener.#configs.delete(this.#storage);
    clearInterval(intervalId);
  }

  get #isEmpty() {
    const config = StorageListener.#configs.get(this.#storage);
    const storageListeners = config?.listeners.values();

    return iterator.every(storageListeners, keyListeners => !keyListeners.size);
  }

  add(key: string, listener: Listener) {
    const { listeners } = this.#start();
    const keyListeners = map.getOrCreateMap(listeners, key, () => new Set());

    keyListeners.add(listener);

    return () => {
      keyListeners.delete(listener);

      if (this.#isEmpty) {
        this.#stop();
      }
    };
  }
}
