import { useEffect, type DependencyList } from 'react';

export function useTimeout<T extends unknown[]>(
  callback: (...params: T) => void,
  timeout: number,
  deps: DependencyList = [],
  ...params: T
) {
  let timeoutId: number;
  useEffect(() => {
    timeoutId = window.setTimeout(callback, timeout, ...params);
    return () => clearTimeout(timeoutId);
  }, [timeout, ...deps, ...params]);
}
