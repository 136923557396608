import React from 'react';
import classNames from 'classnames';
import { Icon } from 'components/general/Icon';
import appStyles from '../../App.module.scss';
import styles from './ComparableItemStats.module.scss';
import { comparisonStatsState } from 'store/comparisonPage';
import { useRecoilValueLoadableState } from 'hooks/useRecoilValueLoadable';
import { formatters } from 'utils/formatters';
import { growthPointState } from 'store/growthPoint';
import { useRecoilValue } from 'recoil';
import { join } from 'components/general/Divider';
import { getGrowth, iterator } from '@harmonya/utils';
import type { Growth, Metadata } from '@harmonya/models';

const metricKeys = ['totalSales', 'productsCount', 'salesGrowth'] as const;

type ValueKey = (typeof metricKeys)[number];

const getValue = (
  comparableItemData: Map<number, Metadata>,
  itemIds: number[],
  valueKey: ValueKey,
  growthPoint: keyof Growth
) => {
  const comparableItemsData = iterator.definedMap(itemIds, itemId =>
    comparableItemData.get(itemId)
  );
  const values = comparableItemsData.map(data => data?.[valueKey]);

  if (valueKey === 'salesGrowth') {
    if (growthPoint === 'inPeriod') {
      const firstWeekSales = iterator.sum(
        comparableItemsData,
        stat => stat.rawValues.firstWeekSales
      );
      const lastWeekSales = iterator.sum(comparableItemsData, stat => stat.rawValues.lastWeekSales);

      return formatters.salesGrowth.valueGetter(getGrowth(firstWeekSales, lastWeekSales));
    }

    const lastYearSales = iterator.sum(
      comparableItemsData,
      comparableItemStats => comparableItemStats.rawValues.lastYearTotalSales
    );
    const totalSales = iterator.sum(
      comparableItemsData,
      comparableItemStats => comparableItemStats.rawValues.totalSales
    );

    return formatters.salesGrowth.valueGetter(getGrowth(lastYearSales, totalSales));
  }

  const value = iterator.sum(values);

  return formatters[valueKey].valueGetter(value);
};

type Props = {
  itemIds: number[];
};

export function ComparableItemStats(props: Props) {
  const { itemIds } = props;
  const comparableItemData = useRecoilValueLoadableState(comparisonStatsState);
  const growthPoint = useRecoilValue(growthPointState);

  if (!comparableItemData) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.container,
        appStyles.horizontalFlex,
        appStyles.boxShadow,
        appStyles.justifySpaceBetween
      )}
    >
      {join(
        metricKeys.map(key => {
          const { title, icon } = formatters[key];

          return (
            <div key={title} className={appStyles.verticalFlex}>
              <div
                className={classNames(appStyles.horizontalFlex, styles.comparableItemStatsTitle)}
              >
                <Icon className={styles.optionsIcon} weight='light' {...icon} />
                <h5 className={styles.comparableItemStatsTitleText}>{title}</h5>
              </div>
              <h4 className={styles.itemData}>
                {getValue(comparableItemData, itemIds, key, growthPoint)}
              </h4>
            </div>
          );
        })
      )}
      <span />
    </div>
  );
}
