import { useMemo } from 'react';
import { useSearchParam } from './useSearchParam';

export const useTagTypeActiveIds = <T extends { id: number }>(allTagTypes?: Map<number, T>) => {
  const isLoading = !allTagTypes;
  const [activeTagTypeIds, setActiveTagTypeIds] = useSearchParam<T['id'][]>('activeTagTypeIds', {
    parser: 'number',
    defaultValue: [],
    validator: id => id != null && (isLoading || allTagTypes.has(id)),
    isArray: true,
  });
  const activeTagTypeIdsAsSet = useMemo(() => new Set(activeTagTypeIds), [activeTagTypeIds.length]);

  const computedSetActiveTagTypeIds = (newActiveTagTypeIds: Iterable<T['id']>) => {
    const activeTagTypeIdsAsArray = Array.isArray(newActiveTagTypeIds)
      ? newActiveTagTypeIds
      : [...newActiveTagTypeIds];

    setActiveTagTypeIds(activeTagTypeIdsAsArray);
  };

  return [activeTagTypeIdsAsSet, computedSetActiveTagTypeIds] as const;
};
