import React from 'react';
import classNames from 'classnames';
import styles from './SelectAllButton.module.scss';
import appStyles from '../../layout/App.module.scss';
import { Icon } from '../Icon';
import { number } from '@harmonya/utils';
import type { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

type Props = {
  allChildrenSelected: boolean;
  activeChildrenCount: number;
  allChildrenCount: number;
  iconDisplayed?: boolean;
  colorInverted?: boolean;
  onClick: () => void;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export function SelectAllButton(props: Props) {
  const {
    allChildrenSelected,
    activeChildrenCount,
    allChildrenCount,
    iconDisplayed = true,
    onClick,
    colorInverted,
    className,
    ...buttonProps
  } = props;
  const computedClassName = classNames(
    appStyles.unpaddedButton,
    appStyles.horizontalFlex,
    appStyles.gap1,
    styles.container,
    colorInverted && appStyles.colorInverted,
    className
  );
  let countText = number.format(activeChildrenCount);

  if (activeChildrenCount < allChildrenCount) {
    countText += `/${number.format(allChildrenCount)}`;
  }

  return (
    <button
      disabled={allChildrenSelected}
      className={computedClassName}
      onClick={onClick}
      {...buttonProps}
    >
      {iconDisplayed && <Icon name='arrow-turn-down' weight='light' flip='horizontal' />}
      {`Select All [${countText}]`}
    </button>
  );
}
