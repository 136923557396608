import type { RevisionRequestSchema } from '@harmonya/attribution.types';
import type {
  AttributeMetadata,
  ProductAttributeContent,
  ProductAttributes,
} from 'store/attribution.types';
import type { RawProductData } from 'components/layout/attributionPage/attribution-page.types';

/** @todo Schema should come from the productsData */
export const schemaMock = 'Schema';

/** @todo SourcName should come from the productsData */
export const sourceNameMock = 'SourceName';

const capitalizeFirstLetter = (value: string) => {
  return value
    .split(' ')
    .map(word =>
      ['fl', 'oz'].includes(word)
        ? word
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join(' ');
};

const rawData: ProductAttributes[] = [
  {
    'UPC (NIQ)': {
      value: '3977114065',
    },
    'item (NIQ)': {
      value: 'GDY CRBN WTR SFT DRNK RED Cherry POP BTL 12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'ACON INVESTMENTS LLC',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'GOODY',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$291.09',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'RED',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'ACON INVESTMENTS LLC',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'GOODY',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'GOODY',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Cherry',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'PET',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'Count': {
      value: '1',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'REGULAR',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'Provided',
    },
    'Description': {
      value: 'SOFT DRINKS RED GOODY GOODY Cherry REGULAR CAFFEINE',
    },
    'Size Total': {
      value: '1 pk 12 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '3977114066',
    },
    'item (NIQ)': {
      value: 'GDY CRBN WTR SFT DRNK RED Lime POP BTL 20 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'AO MANUFACTURERS',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'GOODY',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$291.09',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'RED',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'AO MANUFACTURERS',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'GOODY',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'GOODY',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Lime',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'PET',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '20 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '4',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'REGULAR',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'SOFT DRINKS RED GOODY GOODY Lime REGULAR CAFFEINE',
    },
    'Size Total': {
      value: '4 pk 20 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '3977114067',
    },
    'item (NIQ)': {
      value: 'GDY CRBN WTR SFT DRNK RED Cherry CAFFREE POP BTL 12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'ACON INVESTMENTS LLC',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'GOODY',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$291.09',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'RED',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'ACON INVESTMENTS LLC',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'GOODY',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'GOODY',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Cherry',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'PET',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '12 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '6',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'REGULAR',
      confidenceLevel: 'Low',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'SOFT DRINKS RED GOODY GOODY Cherry REGULAR CAFFEINE FREE',
    },
    'Size Total': {
      value: '6 pk 12 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '3977114068',
    },
    'item (NIQ)': {
      value: 'GDY CRBN WTR SFT DRNK RED Cherry CAFFREE POP BTL 12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'ACON INVESTMENTS LLC',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'GOODY',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$291.09',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'Medium',
    },
    'SUB_CATEGORY': {
      value: 'RED',
      confidenceLevel: 'Medium',
    },
    'MANUFACTURER': {
      value: 'ACON INVESTMENTS LLC',
      confidenceLevel: 'Medium',
    },
    'BRAND': {
      value: 'GOODY',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'GOODY',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Lime',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'PET',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '12 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '1',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'REGULAR',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'SOFT DRINKS RED GOODY GOODY Lime REGULAR CAFFEINE FREE',
    },
    'Size Total': {
      value: '1 pk 12 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '4900005126',
    },
    'item (NIQ)': {
      value: 'BRQS CRBN WTR LOW SDM SFT DRNK HGH FRCT CRN SYRP RED CRM SD CNTR DIET BTL 67.6 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'COCA-COLA COMPANY',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: "BARQ'S",
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$2,333,937.78',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'RED',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'COCA-COLA COMPANY',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: "BARQ'S",
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: "BARQ'S DIET",
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Cherry',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'PET',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '67.6 fl oz',
      confidenceLevel: 'Medium',
    },
    'Count': {
      value: '4',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'DIET',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'High',
    },
    'Description': {
      value: "SOFT DRINKS RED BARQ'S BARQ'S DIET Cherry DIET CAFFEINE",
    },
    'Size Total': {
      value: '4 pk 67.6 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '4900005127',
    },
    'item (NIQ)': {
      value: 'BRQS CRBN WTR LOW SDM SFT DRNK HGH FRCT CRN SYRP RED CRM SD CNTR BTL DIET 67.6 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'COCA-COLA COMPANY',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: "BARQ'S",
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$2,333,937.78',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'RED',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'COCA-COLA COMPANY',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: "BARQ'S",
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: "BARQ'S DIET",
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Lime',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'PET',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '67.6 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '6',
      confidenceLevel: 'Low',
    },
    'Dietary': {
      value: 'DIET',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'Medium',
    },
    'Description': {
      value: "SOFT DRINKS RED BARQ'S BARQ'S DIET Lime DIET CAFFEINE",
    },
    'Size Total': {
      value: '6 pk 67.6 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '4900005128',
    },
    'item (NIQ)': {
      value:
        'BRQS CRBN WTR LOW SDM SFT DRNK HGH FRCT CRN SYRP RED CRM SD CNTR CAFFREE BTL 67.6 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'AO MANUFACTURERS',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: "BARQ'S",
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$2,333,937.78',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'RED',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'COCA-COLA COMPANY',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: "BARQ'S",
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: "BARQ'S",
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Cherry',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'PET',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '67.6 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '1',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'REGULAR',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'Medium',
    },
    'Description': {
      value: "SOFT DRINKS RED BARQ'S BARQ'S Cherry REGULAR CAFFEINE FREE",
    },
    'Size Total': {
      value: '1 pk 67.6 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '4900005129',
    },
    'item (NIQ)': {
      value:
        'BRQS CRBN WTR LOW SDM SFT DRNK HGH FRCT CRN SYRP RED CRM SD CNTR CAFFREE BTL 67.6 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'COCA-COLA COMPANY',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: "BARQ'S",
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$2,333,937.78',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'Medium',
    },
    'SUB_CATEGORY': {
      value: 'RED',
      confidenceLevel: 'Medium',
    },
    'MANUFACTURER': {
      value: 'COCA-COLA COMPANY',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: "BARQ'S",
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: "BARQ'S",
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Lime',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'PET',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '67.6 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '10',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'REGULAR',
      confidenceLevel: 'Provided',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'High',
    },
    'Description': {
      value: "SOFT DRINKS RED BARQ'S BARQ'S Lime REGULAR CAFFEINE FREE",
    },
    'Size Total': {
      value: '10 pk 67.6 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '7181700029',
    },
    'item (NIQ)': {
      value: 'BIG RED CRBN WTR SFT DRNK HGH FRCT CRN SYRP MANGO CAN 12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'KEURIG DR PEPPER',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'BIG RED',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$150,160.10',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'RED',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'KEURIG DR PEPPER',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'BIG RED',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'BIG RED DIET',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Mango',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '12 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '1',
      confidenceLevel: 'Low',
    },
    'Dietary': {
      value: 'DIET',
      confidenceLevel: 'Low',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'Provided',
    },
    'Description': {
      value: 'SOFT DRINKS RED BIG RED BIG RED DIET Mango DIET CAFFEINE',
    },
    'Size Total': {
      value: '1 pk 12 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '7181700030',
    },
    'item (NIQ)': {
      value: 'BIG RED CRBN WTR SFT DRNK HGH FRCT CRN SYRP APPLE CAN 12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'KEURIG DR PEPPER',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'BIG RED',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$150,160.10',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'RED',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'KEURIG DR PEPPER',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'BIG RED',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'BIG RED DIET',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Apple',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '12 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '6',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'DIET',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'SOFT DRINKS RED BIG RED BIG RED DIET Apple DIET CAFFEINE',
    },
    'Size Total': {
      value: '6 pk 12 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '7181700031',
    },
    'item (NIQ)': {
      value: 'BIG RED CRBN WTR SFT DRNK HGH FRCT CRN SYRP MANGO CAN 12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'KEURIG DR PEPPER',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'BIG RED',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$150,160.10',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'RED',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'KEURIG DR PEPPER',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'BIG RED',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'BIG RED',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Mango',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '12 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '10',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'REGULAR',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'SOFT DRINKS RED BIG RED BIG RED Mango REGULAR CAFFEINE FREE',
    },
    'Size Total': {
      value: '10 pk 12 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '7181700032',
    },
    'item (NIQ)': {
      value: 'BIG RED CRBN WTR SFT DRNK HGH FRCT CRN SYRP APPLE CAN 12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'KEURIG DR PEPPER',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'BIG RED',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$150,160.10',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'Medium',
    },
    'SUB_CATEGORY': {
      value: 'RED',
      confidenceLevel: 'Medium',
    },
    'MANUFACTURER': {
      value: 'KEURIG DR PEPPER',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'BIG RED',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'BIG RED',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Apple',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '12 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '1',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'REGULAR',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'SOFT DRINKS RED BIG RED BIG RED Apple REGULAR CAFFEINE FREE',
    },
    'Size Total': {
      value: '1 pk 12 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '71817000301',
    },
    'item (NIQ)': {
      value: 'BIG RED ZR ACSL PTSM SCRL SFT DRNK VRY LOW SDM CLRS CRBN WTR MANGO CAN 12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'KEURIG DR PEPPER',
      confidenceLevel: 'Low',
    },
    'BRAND (NIQ)': {
      value: 'BIG RED',
      confidenceLevel: 'Low',
    },
    'Sales (NIQ)': {
      value: '$170.94',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'Low',
    },
    'SUB_CATEGORY': {
      value: 'RED',
      confidenceLevel: 'Low',
    },
    'MANUFACTURER': {
      value: 'KEURIG DR PEPPER',
      confidenceLevel: 'Low',
    },
    'BRAND': {
      value: 'BIG RED',
      confidenceLevel: 'Low',
    },
    'Sub brand': {
      value: 'BIG RED ZERO',
      confidenceLevel: 'Low',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'Low',
    },
    'Flavor': {
      value: 'Mango',
      confidenceLevel: 'Low',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'Low',
    },
    'Size': {
      value: '12 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '1',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'ENHANCED',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'Provided',
    },
    'Description': {
      value: 'SOFT DRINKS RED BIG RED BIG RED ZERO Mango ENHANCED CAFFEINE',
    },
    'Size Total': {
      value: '1 pk 12 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '71817000302',
    },
    'item (NIQ)': {
      value: 'BIG RED ZR ACSL PTSM SCRL SFT DRNK VRY LOW SDM CLRS CRBN WTR APPLE CAN 12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'AO MANUFACTURERS',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'BIG RED',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$170.94',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'RED',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'KEURIG DR PEPPER',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'BIG RED',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'BIG RED ZERO',
      confidenceLevel: 'Low',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Apple',
      confidenceLevel: 'Low',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'Low',
    },
    'Size': {
      value: '12 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '1',
      confidenceLevel: 'Medium',
    },
    'Dietary': {
      value: 'ZERO SUGER ENHANCED',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'SOFT DRINKS RED BIG RED BIG RED ZERO Apple ZERO SUGER ENHANCED CAFFEINE',
    },
    'Size Total': {
      value: '1 pk 12 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '71817000303',
    },
    'item (NIQ)': {
      value: 'BIG RED ZR ACSL PTSM SCRL SFT DRNK VRY LOW SDM CLRS CRBN WTR MANGO CAN 12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'KEURIG DR PEPPER',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'BIG RED',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$170.94',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'RED',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'KEURIG DR PEPPER',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'BIG RED',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'BIG RED ZERO',
      confidenceLevel: 'Low',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Mango',
      confidenceLevel: 'Low',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'Low',
    },
    'Size': {
      value: '12 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '4',
      confidenceLevel: 'Low',
    },
    'Dietary': {
      value: 'ENHANCED',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'SOFT DRINKS RED BIG RED BIG RED ZERO Mango ENHANCED CAFFEINE FREE',
    },
    'Size Total': {
      value: '4 pk 12 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '71817000304',
    },
    'item (NIQ)': {
      value: 'BIG RED ZR ACSL PTSM SCRL SFT DRNK VRY LOW SDM CLRS CRBN WTR APPLE CAN 12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'KEURIG DR PEPPER',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'BIG RED',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$170.94',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'RED',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'KEURIG DR PEPPER',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'BIG RED',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'BIG RED ZERO',
      confidenceLevel: 'Low',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Apple',
      confidenceLevel: 'Low',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'Low',
    },
    'Size': {
      value: '12 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '4',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'ZERO SUGER ENHANCED',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'SOFT DRINKS RED BIG RED BIG RED ZERO Apple ZERO SUGER ENHANCED CAFFEINE FREE',
    },
    'Size Total': {
      value: '4 pk 12 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '1200000019',
    },
    'item (NIQ)': {
      value: 'PPS BTL HGH FRCT CRN SYRP CRBN WTR CL SFT DRNK 6PK 16 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'PEPSICO INC',
      confidenceLevel: 'Provided',
    },
    'BRAND (NIQ)': {
      value: 'PEPSI',
      confidenceLevel: 'Provided',
    },
    'Sales (NIQ)': {
      value: '$654.00',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'Provided',
    },
    'SUB_CATEGORY': {
      value: 'COLA',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER': {
      value: 'PEPSICO INC',
      confidenceLevel: 'Provided',
    },
    'BRAND': {
      value: 'PEPSI',
      confidenceLevel: 'Provided',
    },
    'Sub brand': {
      value: 'PEPSI ENHANCED',
      confidenceLevel: 'Provided',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'Provided',
    },
    'Flavor': {
      value: 'Mango',
      confidenceLevel: 'Provided',
    },
    'Container': {
      value: 'GLASS BOTTLE',
      confidenceLevel: 'Provided',
    },
    'Size': {
      value: '16 FL OZ',
      confidenceLevel: 'Provided',
    },
    'Count': {
      value: '4',
      confidenceLevel: 'Medium',
    },
    'Dietary': {
      value: 'ENHANCED',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'SOFT DRINKS COLA PEPSI PEPSI ENHANCED Mango ENHANCED CAFFEINE',
    },
    'Size Total': {
      value: '4 pk 16 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '12000000191',
    },
    'item (NIQ)': {
      value: 'PPS BTL HGH FRCT CRN SYRP CRBN WTR CL SFT DRNK 6PK 16 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'PEPSICO INC',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'PEPSI',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$654.00',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'COLA',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'PEPSICO INC',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'PEPSI',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'PEPSI',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Apple',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'GLASS BOTTLE',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '16 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '6',
      confidenceLevel: 'Low',
    },
    'Dietary': {
      value: 'ZERO SUGER ENHANCED',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'SOFT DRINKS COLA PEPSI PEPSI ENHANCED Apple ZERO SUGER ENHANCED CAFFEINE',
    },
    'Size Total': {
      value: '6 pk 16 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '12000000192',
    },
    'item (NIQ)': {
      value: 'PPS BTL HGH FRCT CRN SYRP CRBN WTR CL SFT DRNK 6PK 16 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'AO MANUFACTURERS',
      confidenceLevel: 'Medium',
    },
    'BRAND (NIQ)': {
      value: 'PEPSI',
      confidenceLevel: 'Medium',
    },
    'Sales (NIQ)': {
      value: '$654.00',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'Medium',
    },
    'SUB_CATEGORY': {
      value: 'COLA',
      confidenceLevel: 'Medium',
    },
    'MANUFACTURER': {
      value: 'PEPSICO INC',
      confidenceLevel: 'Medium',
    },
    'BRAND': {
      value: 'PEPSI',
      confidenceLevel: 'Medium',
    },
    'Sub brand': {
      value: 'PEPSI ENHANCED',
      confidenceLevel: 'Medium',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'Medium',
    },
    'Flavor': {
      value: 'Mango',
      confidenceLevel: 'Medium',
    },
    'Container': {
      value: 'GLASS BOTTLE',
      confidenceLevel: 'Medium',
    },
    'Size': {
      value: '16 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '10',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'ENHANCED',
      confidenceLevel: 'Medium',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'SOFT DRINKS COLA PEPSI PEPSI ENHANCED Mango ENHANCED CAFFEINE FREE',
    },
    'Size Total': {
      value: '10 pk 16 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '12000000193',
    },
    'item (NIQ)': {
      value: 'PPS BTL HGH FRCT CRN SYRP CRBN WTR CL SFT DRNK 6PK 16 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'PEPSICO INC',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'PEPSI',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$654.00',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'COLA',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'PEPSICO INC',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'PEPSI',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'PEPSI',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Apple',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'GLASS BOTTLE',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '16 fl oz',
      confidenceLevel: 'Low',
    },
    'Count': {
      value: '4',
      confidenceLevel: 'Low',
    },
    'Dietary': {
      value: 'ZERO SUGER ENHANCED',
      confidenceLevel: 'Medium',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'Medium',
    },
    'Description': {
      value: 'SOFT DRINKS COLA PEPSI PEPSI Apple ZERO SUGER ENHANCED CAFFEINE FREE',
    },
    'Size Total': {
      value: '4 pk 16 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '2561604213',
    },
    'item (NIQ)': {
      value: 'MRBR CF VNL CAN NNCR FLVR LQD ICD ZS 8.12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'KING CAR FOOD INDUSTRIAL CO.',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'MR.BROWN COFFEE',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$6,992.40',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'RTD COFFEE',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'VANILLA',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'KING CAR FOOD INDUSTRIAL CO.',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'MR.BROWN COFFEE',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'MR.BROWN COFFEE ENHANCED',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '12 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '6',
      confidenceLevel: 'Low',
    },
    'Dietary': {
      value: 'ENHANCED',
      confidenceLevel: 'Low',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'High',
    },
    'Description': {
      value:
        'RTD COFFEE VANILLA MR.BROWN COFFEE MR.BROWN COFFEE ENHANCED Flavored ENHANCED CAFFEINE',
    },
    'Size Total': {
      value: '6 pk 12 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '25616042134',
    },
    'item (NIQ)': {
      value: 'MRBR CF VNL CAN NNCR FLVR LQD ICD 8.12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'KING CAR FOOD INDUSTRIAL CO.',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'MR.BROWN COFFEE',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$6,992.40',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'RTD COFFEE',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'VANILLA',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'KING CAR FOOD INDUSTRIAL CO.',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'MR.BROWN COFFEE',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'MR.BROWN COFFEE',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '12 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '1',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'ZERO SUGER ENHANCED',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'High',
    },
    'Description': {
      value:
        'RTD COFFEE VANILLA MR.BROWN COFFEE MR.BROWN COFFEE Flavored ZERO SUGER ENHANCED CAFFEINE',
    },
    'Size Total': {
      value: '1 pk 12 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '25616042135',
    },
    'item (NIQ)': {
      value: 'MRBR CF VNL CAN NNCR FLVR LQD ICD 8.12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'AO MANUFACTURERS',
      confidenceLevel: 'Medium',
    },
    'BRAND (NIQ)': {
      value: 'MR.BROWN COFFEE',
      confidenceLevel: 'Medium',
    },
    'Sales (NIQ)': {
      value: '$6,992.40',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'RTD COFFEE',
      confidenceLevel: 'Medium',
    },
    'SUB_CATEGORY': {
      value: 'VANILLA',
      confidenceLevel: 'Medium',
    },
    'MANUFACTURER': {
      value: 'KING CAR FOOD INDUSTRIAL CO.',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'MR.BROWN COFFEE',
      confidenceLevel: 'Medium',
    },
    'Sub brand': {
      value: 'MR.BROWN COFFEE ENHANCED',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'Medium',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'Medium',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '12 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '1',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'ENHANCED',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'Medium',
    },
    'Description': {
      value:
        'RTD COFFEE VANILLA MR.BROWN COFFEE MR.BROWN COFFEE ENHANCED Flavored ENHANCED CAFFEINE FREE',
    },
    'Size Total': {
      value: '1 pk 12 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '25616042136',
    },
    'item (NIQ)': {
      value: 'MRBR CF VNL CAN NNCR FLVR LQD ICD 8.12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'KING CAR FOOD INDUSTRIAL CO.',
      confidenceLevel: 'Low',
    },
    'BRAND (NIQ)': {
      value: 'MR.BROWN COFFEE',
      confidenceLevel: 'Low',
    },
    'Sales (NIQ)': {
      value: '$6,992.40',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'RTD COFFEE',
      confidenceLevel: 'Low',
    },
    'SUB_CATEGORY': {
      value: 'VANILLA',
      confidenceLevel: 'Low',
    },
    'MANUFACTURER': {
      value: 'KING CAR FOOD INDUSTRIAL CO.',
      confidenceLevel: 'Low',
    },
    'BRAND': {
      value: 'MR.BROWN COFFEE',
      confidenceLevel: 'Low',
    },
    'Sub brand': {
      value: 'MR.BROWN COFFEE',
      confidenceLevel: 'Low',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'Low',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'Low',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'Low',
    },
    'Size': {
      value: '12 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '6',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'ZERO SUGER ENHANCED',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'High',
    },
    'Description': {
      value:
        'RTD COFFEE VANILLA MR.BROWN COFFEE MR.BROWN COFFEE Flavored ZERO SUGER ENHANCED CAFFEINE FREE',
    },
    'Size Total': {
      value: '6 pk 12 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '2800035080',
    },
    'item (NIQ)': {
      value: 'CHML VNL CAN TRPL SHT NO CRBN FLVR LQD CF 11 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'NESTLE HOLDINGS INC',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'CHAMELEON',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$88,811.75',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'RTD COFFEE',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'VANILLA',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'NESTLE HOLDINGS INC',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'CHAMELEON',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'CHAMELEON ENHANCED',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '11 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '6',
      confidenceLevel: 'Medium',
    },
    'Dietary': {
      value: 'ENHANCED',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'RTD COFFEE VANILLA CHAMELEON CHAMELEON ENHANCED Flavored ENHANCED CAFFEINE',
    },
    'Size Total': {
      value: '6 pk 11 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '28000350801',
    },
    'item (NIQ)': {
      value: 'CHML VNL CAN TRPL SHT NO CRBN FLVR LQD CF 11 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'NESTLE HOLDINGS INC',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'CHAMELEON',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$88,811.75',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'RTD COFFEE',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'VANILLA',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'NESTLE HOLDINGS INC',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'CHAMELEON',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'CHAMELEON',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '11 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '4',
      confidenceLevel: 'Provided',
    },
    'Dietary': {
      value: 'ZERO SUGER ENHANCED',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'Low',
    },
    'Description': {
      value: 'RTD COFFEE VANILLA CHAMELEON CHAMELEON Flavored ZERO SUGER ENHANCED CAFFEINE',
    },
    'Size Total': {
      value: '4 pk 11 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '28000350802',
    },
    'item (NIQ)': {
      value: 'CHML VNL CAN TRPL SHT NO CRBN FLVR LQD CF 16 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'NESTLE HOLDINGS INC',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'CHAMELEON',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$88,811.75',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'RTD COFFEE',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'VANILLA',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'NESTLE HOLDINGS INC',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'CHAMELEON',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'CHAMELEON ENHANCED',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '16 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '1',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'ENHANCED',
      confidenceLevel: 'Low',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'RTD COFFEE VANILLA CHAMELEON CHAMELEON ENHANCED Flavored ENHANCED CAFFEINE FREE',
    },
    'Size Total': {
      value: '1 pk 16 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '28000350803',
    },
    'item (NIQ)': {
      value: 'CHML VNL CAN TRPL SHT NO CRBN FLVR LQD CF 16 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'NESTLE HOLDINGS INC',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'CHAMELEON',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$88,811.75',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'RTD COFFEE',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'VANILLA',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'NESTLE HOLDINGS INC',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'CHAMELEON',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'CHAMELEON',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '16 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '1',
      confidenceLevel: 'Medium',
    },
    'Dietary': {
      value: 'ZERO SUGER ENHANCED',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'RTD COFFEE VANILLA CHAMELEON CHAMELEON Flavored ZERO SUGER ENHANCED CAFFEINE FREE',
    },
    'Size Total': {
      value: '1 pk 16 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '4850020130',
    },
    'item (NIQ)': {
      value: 'STRB SKNY VNL LT BTL ESPR NO CRBN FLVR LQD ICD CF 40 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'NORTH AMERICAN COFFEE PARTNERSHIP',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'STARBUCKS',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$20,683,855.37',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'RTD COFFEE',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'VANILLA',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'NORTH AMERICAN COFFEE PARTNERSHIP',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'STARBUCKS',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'STARBUCKS ENHANCED',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Vanilla',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '40 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '1',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'ENHANCED',
      confidenceLevel: 'Medium',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'Medium',
    },
    'Description': {
      value: 'RTD COFFEE VANILLA STARBUCKS STARBUCKS ENHANCED Vanilla ENHANCED CAFFEINE',
    },
    'Size Total': {
      value: '1 pk 40 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '48500201301',
    },
    'item (NIQ)': {
      value: 'STRB SKNY VNL LT BTL ESPR NO CRBN FLVR LQD ICD CF 40 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'NORTH AMERICAN COFFEE PARTNERSHIP',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'STARBUCKS',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$20,683,855.37',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'RTD COFFEE',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'VANILLA',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'NORTH AMERICAN COFFEE PARTNERSHIP',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'STARBUCKS',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'STARBUCKS',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Vanilla',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '40 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '6',
      confidenceLevel: 'Low',
    },
    'Dietary': {
      value: 'ZERO SUGER ENHANCED',
      confidenceLevel: 'Low',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'RTD COFFEE VANILLA STARBUCKS STARBUCKS Vanilla ZERO SUGER ENHANCED CAFFEINE',
    },
    'Size Total': {
      value: '6 pk 40 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '48500201302',
    },
    'item (NIQ)': {
      value: 'STRB SKNY VNL LT BTL ESPR NO CRBN FLVR LQD ICD CF 40 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'AO MANUFACTURERS',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'STARBUCKS',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$20,683,855.37',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'RTD COFFEE',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'VANILLA',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'AO MANUFACTURERS',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'STARBUCKS',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'STARBUCKS',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Vanilla',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '40 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '6',
      confidenceLevel: 'Low',
    },
    'Dietary': {
      value: 'ZERO SUGER ENHANCED',
      confidenceLevel: 'Low',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'RTD COFFEE VANILLA STARBUCKS STARBUCKS Vanilla ZERO SUGER ENHANCED CAFFEINE',
    },
    'Size Total': {
      value: '6 pk 40 fl oz',
    },
  },

  {
    'UPC (NIQ)': {
      value: '70847037922',
    },
    'item (NIQ)': {
      value: 'MNST ULT SNR SCRL ERYT ENRG DRNK CAN 24PK 16 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'MONSTER ENERGY COMPANY',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'MONSTER',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$9,155.30',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'ENERGY BEVERAGES',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'ENERGY DRINKS',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'MONSTER ENERGY COMPANY',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'MONSTER',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'MONSTER ULTRA SUNRISE',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Berry',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '16 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '6',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'ENHANCED',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'High',
    },
    'Description': {
      value:
        'ENERGY BEVERAGES ENERGY DRINKS MONSTER MONSTER ULTRA SUNRISE Berry ENHANCED CAFFEINE FREE',
    },
    'Size Total': {
      value: '6 pk 16 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '70847037923',
    },
    'item (NIQ)': {
      value: 'MNST ULT SNR SCRL ERYT ENRG DRNK CAN 24PK 16 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'AO MANUFACTURERS',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'MONSTER',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$9,155.30',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'ENERGY BEVERAGES',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'ENERGY DRINKS',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'AO MANUFACTURERS',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'MONSTER',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'MONSTER ULTRA SUNRISE',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Berry',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '16 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '1',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'REGULAR',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'High',
    },
    'Description': {
      value:
        'ENERGY BEVERAGES ENERGY DRINKS MONSTER MONSTER ULTRA SUNRISE Berry REGULAR CAFFEINE FREE',
    },
    'Size Total': {
      value: '1 pk 16 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '61126918254',
    },
    'item (NIQ)': {
      value: 'RED BL BL EDT BLBR ENRG DRNK LM CAN 24PK 12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'RED BULL GMBH',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'RED BULL',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$1,658,974.60',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'ENERGY BEVERAGES',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'ENERGY DRINKS',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER': {
      value: 'RED BULL GMBH',
      confidenceLevel: 'Provided',
    },
    'BRAND': {
      value: 'RED BULL',
      confidenceLevel: 'Provided',
    },
    'Sub brand': {
      value: 'RED BULL THE BLUE EDITION',
      confidenceLevel: 'Provided',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'Provided',
    },
    'Flavor': {
      value: 'LIME',
      confidenceLevel: 'Provided',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'Provided',
    },
    'Size': {
      value: '12 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '10',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'ENHANCED',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'Low',
    },
    'Description': {
      value:
        'ENERGY BEVERAGES ENERGY DRINKS RED BULL RED BULL THE BLUE EDITION LIME ENHANCED CAFFEINE',
    },
    'Size Total': {
      value: '10 pk 12 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '611269182541',
    },
    'item (NIQ)': {
      value: 'RED BL BL EDT BLBR ENRG DRNK CAN 24PK 12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'RED BULL GMBH',
      confidenceLevel: 'Medium',
    },
    'BRAND (NIQ)': {
      value: 'RED BULL',
      confidenceLevel: 'Medium',
    },
    'Sales (NIQ)': {
      value: '$1,658,974.60',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'ENERGY BEVERAGES',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'ENERGY DRINKS',
      confidenceLevel: 'Medium',
    },
    'MANUFACTURER': {
      value: 'RED BULL GMBH',
      confidenceLevel: 'Medium',
    },
    'BRAND': {
      value: 'RED BULL',
      confidenceLevel: 'Medium',
    },
    'Sub brand': {
      value: 'RED BULL THE BLUE EDITION',
      confidenceLevel: 'Medium',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'Medium',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'Medium',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'Medium',
    },
    'Size': {
      value: '12 fl oz',
      confidenceLevel: 'Medium',
    },
    'Count': {
      value: '1',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'REGULAR',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'High',
    },
    'Description': {
      value:
        'ENERGY BEVERAGES ENERGY DRINKS RED BULL RED BULL THE BLUE EDITION Flavored REGULAR CAFFEINE',
    },
    'Size Total': {
      value: '1 pk 12 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '611269182542',
    },
    'item (NIQ)': {
      value: 'RED BL BL EDT BLBR ENRG DRNK CAN 24PK 12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'RED BULL GMBH',
      confidenceLevel: 'Medium',
    },
    'BRAND (NIQ)': {
      value: 'RED BULL',
      confidenceLevel: 'Medium',
    },
    'Sales (NIQ)': {
      value: '$1,658,974.60',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'ENERGY BEVERAGES',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'ENERGY DRINKS',
      confidenceLevel: 'Medium',
    },
    'MANUFACTURER': {
      value: 'RED BULL GMBH',
      confidenceLevel: 'Medium',
    },
    'BRAND': {
      value: 'RED BULL',
      confidenceLevel: 'Medium',
    },
    'Sub brand': {
      value: 'RED BULL THE BLUE EDITION',
      confidenceLevel: 'Medium',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'Medium',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'Medium',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'Medium',
    },
    'Size': {
      value: '12 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '1',
      confidenceLevel: 'Provided',
    },
    'Dietary': {
      value: 'ENHANCED',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'High',
    },
    'Description': {
      value:
        'ENERGY BEVERAGES ENERGY DRINKS RED BULL RED BULL THE BLUE EDITION Flavored ENHANCED CAFFEINE FREE',
    },
    'Size Total': {
      value: '1 pk 12 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '611269182543',
    },
    'item (NIQ)': {
      value: 'RED BL BL EDT BLBR ENRG DRNK CAN 24PK 12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'RED BULL GMBH',
      confidenceLevel: 'Provided',
    },
    'BRAND (NIQ)': {
      value: 'RED BULL',
      confidenceLevel: 'Provided',
    },
    'Sales (NIQ)': {
      value: '$1,658,974.60',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'ENERGY BEVERAGES',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'ENERGY DRINKS',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER': {
      value: 'RED BULL GMBH',
      confidenceLevel: 'Provided',
    },
    'BRAND': {
      value: 'RED BULL',
      confidenceLevel: 'Provided',
    },
    'Sub brand': {
      value: 'RED BULL THE BLUE EDITION',
      confidenceLevel: 'Provided',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'Provided',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'Provided',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'Provided',
    },
    'Size': {
      value: '12 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '4',
      confidenceLevel: 'Medium',
    },
    'Dietary': {
      value: 'REGULAR',
      confidenceLevel: 'Provided',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'Low',
    },
    'Description': {
      value:
        'ENERGY BEVERAGES ENERGY DRINKS RED BULL RED BULL THE BLUE EDITION Flavored REGULAR CAFFEINE FREE',
    },
    'Size Total': {
      value: '4 pk 12 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '61300874955',
    },
    'item (NIQ)': {
      value: 'ARZN RX ENR TEA FLVR ENRG DRNK BTL GRN ELTH RT EXTR PNX GNSN SCHS 34 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'FEROLITO, VULTAGGIO & SONS',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'ARIZONA',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$1,067,360.69',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'ENERGY BEVERAGES',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'ENERGY DRINKS',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'FEROLITO, VULTAGGIO & SONS',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'ARIZONA',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'ARIZONA RX ENERGY',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'PET',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '34 fl oz',
      confidenceLevel: 'Low',
    },
    'Count': {
      value: '1',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'ENHANCED',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'ENERGY BEVERAGES ENERGY DRINKS ARIZONA ARIZONA RX ENERGY Flavored ENHANCED CAFFEINE',
    },
    'Size Total': {
      value: '1 pk 34 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '613008749551',
    },
    'item (NIQ)': {
      value: 'ARZN RX ENR TEA FLVR ENRG DRNK BTL GRN ELTH RT EXTR PNX GNSN SCHS 34 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'FEROLITO, VULTAGGIO & SONS',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'ARIZONA',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$1,067,360.69',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'ENERGY BEVERAGES',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'ENERGY DRINKS',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'FEROLITO, VULTAGGIO & SONS',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'ARIZONA',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'ARIZONA RX ENERGY',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'PET',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '34 fl oz',
      confidenceLevel: 'Medium',
    },
    'Count': {
      value: '1',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'REGULAR',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'ENERGY BEVERAGES ENERGY DRINKS ARIZONA ARIZONA RX ENERGY Flavored REGULAR CAFFEINE',
    },
    'Size Total': {
      value: '1 pk 34 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '613008749552',
    },
    'item (NIQ)': {
      value: 'ARZN RX ENR TEA FLVR ENRG DRNK BTL GRN ELTH RT EXTR PNX GNSN SCHS 34 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'FEROLITO, VULTAGGIO & SONS',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'ARIZONA',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$1,067,360.69',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'ENERGY BEVERAGES',
      confidenceLevel: 'Medium',
    },
    'SUB_CATEGORY': {
      value: 'ENERGY DRINKS',
      confidenceLevel: 'Medium',
    },
    'MANUFACTURER': {
      value: 'FEROLITO, VULTAGGIO & SONS',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'ARIZONA',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'ARIZONA RX ENERGY',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'Medium',
    },
    'Container': {
      value: 'PET',
      confidenceLevel: 'Medium',
    },
    'Size': {
      value: '34 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '1',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'ENHANCED',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'High',
    },
    'Description': {
      value:
        'ENERGY BEVERAGES ENERGY DRINKS ARIZONA ARIZONA RX ENERGY Flavored ENHANCED CAFFEINE FREE',
    },
    'Size Total': {
      value: '1 pk 34 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '613008749553',
    },
    'item (NIQ)': {
      value: 'ARZN RX ENR TEA FLVR ENRG DRNK BTL GRN ELTH RT EXTR PNX GNSN SCHS 34 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'FEROLITO, VULTAGGIO & SONS',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'ARIZONA',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$1,067,360.69',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'ENERGY BEVERAGES',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'ENERGY DRINKS',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'FEROLITO, VULTAGGIO & SONS',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'ARIZONA',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'ARIZONA RX ENERGY',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'PET',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '34 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '1',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'REGULAR',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'High',
    },
    'Description': {
      value:
        'ENERGY BEVERAGES ENERGY DRINKS ARIZONA ARIZONA RX ENERGY Flavored REGULAR CAFFEINE FREE',
    },
    'Size Total': {
      value: '1 pk 34 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '84259510659',
    },
    'item (NIQ)': {
      value: 'CLCR C4 ENR ENRG DRNK PRFR SPRH AWKN YR SPR FRZN BMBS LQD CAN 16 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'WOODBOLT INTERNATIONAL',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'CELLUCOR',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$31,510,206.64',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'ENERGY BEVERAGES',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'ENERGY DRINKS',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'WOODBOLT INTERNATIONAL',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'CELLUCOR',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'CELLUCOR C4 ENERGY',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '16 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '4',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'ENHANCED',
      confidenceLevel: 'Provided',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'High',
    },
    'Description': {
      value:
        'ENERGY BEVERAGES ENERGY DRINKS CELLUCOR CELLUCOR C4 ENERGY Flavored ENHANCED CAFFEINE',
    },
    'Size Total': {
      value: '4 pk 16 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '842595106591',
    },
    'item (NIQ)': {
      value: 'CLCR C4 ENR ENRG DRNK PRFR SPRH AWKN YR SPR FRZN BMBS LQD CAN 16 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'WOODBOLT INTERNATIONAL',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'CELLUCOR',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$31,510,206.64',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'ENERGY BEVERAGES',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'ENERGY DRINKS',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'WOODBOLT INTERNATIONAL',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'CELLUCOR',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'CELLUCOR C4 ENERGY',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '16 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '4',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'REGULAR',
      confidenceLevel: 'Provided',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'ENERGY BEVERAGES ENERGY DRINKS CELLUCOR CELLUCOR C4 ENERGY Flavored REGULAR CAFFEINE',
    },
    'Size Total': {
      value: '4 pk 16 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '842595106594',
    },
    'item (NIQ)': {
      value: 'CLCR C4 ENR ENRG DRNK PRFR SPRH AWKN YR SPR FRZN BMBS LQD CAN 16 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'WOODBOLT INTERNATIONAL',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'CELLUCOR',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$31,510,206.64',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'ENERGY BEVERAGES',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'ENERGY DRINKS',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'WOODBOLT INTERNATIONAL',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'CELLUCOR',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'CELLUCOR C4 ENERGY',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'High',
    },
    'Container': {
      value: 'CAN',
      confidenceLevel: 'High',
    },
    'Size': {
      value: '16 fl oz',
      confidenceLevel: 'High',
    },
    'Count': {
      value: '4',
      confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'ENHANCED',
      confidenceLevel: 'Provided',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'High',
    },
    'Description': {
      value:
        'ENERGY BEVERAGES ENERGY DRINKS CELLUCOR CELLUCOR C4 ENERGY Flavored ENHANCED CAFFEINE',
    },
    'Size Total': {
      value: '4 pk 16 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '842595106598',
    },
    'item (NIQ)': {
      value: 'CLCR C4 ENR ENRG DRNK PRFR SPRH AWKN YR SPR FRZN BMBS LQD CAN 16 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'WOODBOLT INTERNATIONAL',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'CELLUCOR',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$31,510,206.64',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'ENERGY BEVERAGES',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'ENERGY DRINKS',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'WOODBOLT INTERNATIONAL',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'CELLUCOR',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'CELLUCOR C4 ENERGY',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'High',
    },
    'Container': {
      value: '',
      // value: 'CAN',
      // confidenceLevel: 'High',
    },
    'Size': {
      value: '',
      // value: '16 fl oz',
      // confidenceLevel: 'High',
    },
    'Count': {
      value: '',
      // value: '4',
      // confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'REGULAR',
      confidenceLevel: 'Provided',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'ENERGY BEVERAGES ENERGY DRINKS CELLUCOR CELLUCOR C4 ENERGY Flavored REGULAR CAFFEINE',
    },
    'Size Total': {
      value: '4 pk 16 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '842595106591',
    },
    'item (NIQ)': {
      value: 'CLCR C4 ENR ENRG DRNK PRFR SPRH AWKN YR SPR FRZN BMBS LQD CAN 16 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'WOODBOLT INTERNATIONAL',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'CELLUCOR',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$31,510,206.64',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'ENERGY BEVERAGES',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'ENERGY DRINKS',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'WOODBOLT INTERNATIONAL',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'CELLUCOR',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: 'CELLUCOR C4 ENERGY',
      confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'High',
    },
    'Container': {
      value: '',
      // value: 'CAN',
      // confidenceLevel: 'High',
    },
    'Size': {
      value: '',
      // value: '16 fl oz',
      // confidenceLevel: 'High',
    },
    'Count': {
      value: '',
      // value: '1',
      // confidenceLevel: 'Medium',
    },
    'Dietary': {
      value: 'REGULAR',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'Caffeine',
      confidenceLevel: 'High',
    },
    'Description': {
      value: 'ENERGY BEVERAGES ENERGY DRINKS CELLUCOR CELLUCOR C4 ENERGY Flavored REGULAR CAFFEINE',
    },
    'Size Total': {
      value: '1 pk 16 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '842595106592',
    },
    'item (NIQ)': {
      value: 'CLCR C4 ENR ENRG DRNK PRFR SPRH AWKN YR SPR FRZN BMBS LQD CAN 16 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'WOODBOLT INTERNATIONAL',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'CELLUCOR',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$31,510,206.64',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'ENERGY BEVERAGES',
      confidenceLevel: 'Medium',
    },
    'SUB_CATEGORY': {
      value: 'ENERGY DRINKS',
      confidenceLevel: 'Medium',
    },
    'MANUFACTURER': {
      value: 'WOODBOLT INTERNATIONAL',
      confidenceLevel: 'Medium',
    },
    'BRAND': {
      value: 'CELLUCOR',
      confidenceLevel: 'Medium',
    },
    'Sub brand': {
      value: '',
      // value: 'CELLUCOR C4 ENERGY',
      // confidenceLevel: 'Medium',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'Medium',
    },
    'Flavor': {
      value: 'Flavored',
      confidenceLevel: 'Medium',
    },
    'Container': {
      value: '',
      // value: 'CAN',
      // confidenceLevel: 'Medium',
    },
    'Size': {
      value: '',
      // value: '16 fl oz',
      // confidenceLevel: 'High',
    },
    'Count': {
      value: '',
      // value: '1',
      // confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'ENHANCED',
      confidenceLevel: 'High',
    },
    'Caffeine': {
      value: 'CAFFEINE FREE',
      confidenceLevel: 'High',
    },
    'Description': {
      value:
        'ENERGY BEVERAGES ENERGY DRINKS CELLUCOR CELLUCOR C4 ENERGY Flavored ENHANCED CAFFEINE FREE',
    },
    'Size Total': {
      value: '1 pk 16 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '842595106593',
    },
    'item (NIQ)': {
      value: 'CLCR C4 ENR ENRG DRNK PRFR SPRH AWKN YR SPR FRZN BMBS LQD CAN 16 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'WOODBOLT INTERNATIONAL',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'CELLUCOR',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$31,510,206.64',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'ENERGY BEVERAGES',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: 'ENERGY DRINKS',
      confidenceLevel: 'High',
    },
    'MANUFACTURER': {
      value: 'WOODBOLT INTERNATIONAL',
      confidenceLevel: 'High',
    },
    'BRAND': {
      value: 'CELLUCOR',
      confidenceLevel: 'High',
    },
    'Sub brand': {
      value: '',
      // value: 'CELLUCOR C4 ENERGY',
      // confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: '',
      // value: 'Flavored',
      // confidenceLevel: 'High',
    },
    'Container': {
      value: '',
      // value: 'CAN',
      // confidenceLevel: 'High',
    },
    'Size': {
      value: '',
      // value: '16 fl oz',
      // confidenceLevel: 'High',
    },
    'Count': {
      value: '',
      // value: '1',
      // confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'REGULAR',
      confidenceLevel: 'Medium',
    },
    'Caffeine': {
      value: '',
      // value: 'CAFFEINE FREE',
      // confidenceLevel: 'High',
    },
    'Description': {
      value:
        'ENERGY BEVERAGES ENERGY DRINKS CELLUCOR CELLUCOR C4 ENERGY Flavored REGULAR CAFFEINE FREE',
    },
    'Size Total': {
      value: '1 pk 16 fl oz',
    },
  },
  {
    'UPC (NIQ)': {
      value: '1200015320',
    },
    'item (NIQ)': {
      value: 'STBR SD PRFD STV LF EXTR SGR AGV VNL CRM SFT DRNK LOW SDM CRBN WTR BTL 12 FL OZ',
      confidenceLevel: 'Provided',
    },
    'MANUFACTURER (NIQ)': {
      value: 'PEPSICO INC',
      confidenceLevel: 'High',
    },
    'BRAND (NIQ)': {
      value: 'STUBBORN SODA',
      confidenceLevel: 'High',
    },
    'Sales (NIQ)': {
      value: '$9,155.30',
      confidenceLevel: 'Provided',
    },
    'CATEGORY': {
      value: 'SOFT DRINKS',
      confidenceLevel: 'High',
    },
    'SUB_CATEGORY': {
      value: '',
    },
    'MANUFACTURER': {
      value: '',
      // value: 'PEPSICO INC',
      // confidenceLevel: 'High',
    },
    'BRAND': {
      value: '',
      // value: 'STUBBORN SODA',
      // confidenceLevel: 'High',
    },
    'Sub brand': {
      value: '',
      // value: 'STUBBORN SODA',
      // confidenceLevel: 'High',
    },
    'Department (Food/Beverages)': {
      value: 'Beverages',
      confidenceLevel: 'High',
    },
    'Flavor': {
      value: '',
      // value: 'Flavored',
      // confidenceLevel: 'Medium',
    },
    'Container': {
      value: '',
      // value: 'GLASS BOTTLE',
      // confidenceLevel: 'High',
    },
    'Size': {
      value: '',
      // value: '12 fl oz',
      // confidenceLevel: 'High',
    },
    'Count': {
      value: '',
      // value: '1',
      // confidenceLevel: 'High',
    },
    'Dietary': {
      value: 'LOW SODIUM',
      confidenceLevel: 'Medium',
    },
    'Caffeine': {
      value: '',
      // value: 'CAFFEINE FREE',
      // confidenceLevel: 'High',
    },
    'Description': {
      value: 'SOFT DRINKS STUBBORN SODA STUBBORN SODA Flavored LOW SODIUM CAFFEINE FREE',
    },
    'Size Total': {
      value: '1 pk 12 fl oz',
    },
  },
].map((item: Record<number, RawProductData>, itemIndex: number) => {
  return Object.fromEntries(
    Object.entries({
      ...item,
      'First Seen': { value: new Date().toLocaleDateString() } as RawProductData,
      'Last Seen': { value: new Date().toLocaleDateString() } as RawProductData,
      'Last Updated': { value: new Date().toLocaleDateString() } as RawProductData,
    }).map(([_, { value, confidenceLevel }], columnIndex) => {
      const cellType = confidenceLevel
        ? confidenceLevel === 'Provided'
          ? 'provided'
          : 'predicted'
        : 'readonly';
      return [
        columnIndex,
        {
          value: value === 'PET' ? value : capitalizeFirstLetter(value),
          confidenceLevel:
            confidenceLevel !== 'Provided'
              ? (confidenceLevel?.toLowerCase() as 'high' | 'medium' | 'low')
              : undefined,
          isValidated: confidenceLevel === 'Provided',
          isPredicted: !!confidenceLevel,
          isProvided: cellType === 'provided',
          isEdited: false, // As cellType is calculated, it could never be edited
          cellType,
          columnId: columnIndex,
          itemId: itemIndex,
          history: [] as RevisionRequestSchema[],
        },
      ];
    })
  );
});

export const productsAttributesMock: Map<ProductAttributeContent['itemId'], ProductAttributes> =
  new Map(rawData.map(row => [row[0].itemId, row]));

export const getAttributesMetadataMock = (productsAttributesCount: number): AttributeMetadata[] => {
  return [
    {
      title: `UPC (NIQ) (${productsAttributesCount})`,
      isPredicted: false,
      type: '',
    },
    {
      title: 'Description (NIQ)',
      isPredicted: false,
      type: '',
    },
    {
      title: 'MANUFACTURER (NIQ)',
      isPredicted: false,
      type: '',
    },
    {
      title: 'BRAND (NIQ)',
      isPredicted: false,
      type: '',
    },
    {
      title: 'Sales 52W (NIQ)',
      isPredicted: false,
      precision: 77.31,
      type: 'sales',
    },
    {
      title: 'Category',
      isPredicted: true,
      precision: 90,
    },
    {
      title: 'Sub Category',
      isPredicted: true,
      precision: 86,
    },
    {
      title: 'Manufacturer',
      isPredicted: true,
      precision: 95,
    },
    {
      title: 'Brand',
      isPredicted: true,
      precision: 94,
    },
    {
      title: 'Sub Brand',
      isPredicted: true,
      precision: 88,
    },
    {
      title: 'Department (Food/Beverages)',
      isPredicted: true,
      precision: 89,
    },
    {
      title: 'Flavor',
      isPredicted: true,
      precision: 87,
    },
    {
      title: 'Container',
      isPredicted: true,
      precision: 92,
    },
    {
      title: 'Size',
      isPredicted: true,
      precision: 89,
    },
    {
      title: 'Count',
      isPredicted: true,
      precision: 89,
    },
    {
      title: 'Dietary',
      isPredicted: true,
      precision: 90,
    },
    {
      title: 'Caffeine',
      isPredicted: true,
      precision: 88,
      metadata: {},
    },
    {
      title: 'Description',
      isPredicted: false,
    },
    {
      title: 'Size Total',
      isPredicted: false,
      metadata: { ruleExplain: 'description text' },
    },
    {
      title: 'First Seen',
      isPredicted: false,
      type: '',
    },
    {
      title: 'Last Seen',
      isPredicted: false,
      type: '',
    },
    {
      title: 'Last Updated',
      isPredicted: false,
      type: '',
    },
  ].map((item, index) => ({ key: index, type: '', order: index + 1, ...item }));
};
