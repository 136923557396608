import { useAuth0 } from '@auth0/auth0-react';
import {
  array,
  map,
  type RecursivePartial,
  type CustomJwtPayload,
  type Role,
} from '@harmonya/utils';
import type { ClientSettings } from 'interfaces';
import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { AuthError, setAccessTokenSilentlyGetter } from 'utils/auth';
import { env } from '../../env';
import { auth as authMock, authState, customerIdState } from '../store/auth';
import useAsyncError from './useAsyncError';
import { useRecoilStateLoadableState } from './useRecoilValueLoadable';

const claimNamespace = env.CUSTOM_CLAIM_NAMESPACE;
const jwtCustomerIdPath = `${claimNamespace}/customer_id`;
const jwtCustomerIdDisplayNamesPath = `${claimNamespace}/customer_id_display_names`;
const jwtCustomerIdRolesPath = `${claimNamespace}/roles`;
const jwtCustomerIdOoerridesPath = `${claimNamespace}/overrides`;

type TokenData = {
  rawCustomerId: string;
  roles: Role[];
  customerIdDisplayNames: string[];
  customerIdOverrides: Record<string, RecursivePartial<ClientSettings>>;
};

const getTokenData = (parsedToken: CustomJwtPayload): TokenData => ({
  rawCustomerId: parsedToken[jwtCustomerIdPath] as string,
  roles: parsedToken[jwtCustomerIdRolesPath] as Role[],
  customerIdDisplayNames: parsedToken[jwtCustomerIdDisplayNamesPath] as string[],
  customerIdOverrides:
    (parsedToken[jwtCustomerIdOoerridesPath] as Record<string, RecursivePartial<ClientSettings>>) ??
    {},
});

export function useAuthentication() {
  const { user, getAccessTokenSilently } = env.USE_AUTH ? useAuth0() : authMock;
  const [auth, setAuth] = useRecoilStateLoadableState(authState);
  const [customerId, setCustomerId] = useRecoilState(customerIdState);
  const throwError = useAsyncError();

  useEffect(() => {
    getAccessTokenSilently()
      .then(token => {
        const parsedToken = jwtDecode<CustomJwtPayload>(token);

        if (!parsedToken) {
          throw new AuthError('Unable to decode JWT token');
        }

        const tokenData = getTokenData(parsedToken);
        const { rawCustomerId, roles, customerIdDisplayNames, customerIdOverrides } = tokenData;

        if (!rawCustomerId) {
          throw new AuthError('Customer ID is missing');
        }

        const rawCustomerIds = array.ensure(rawCustomerId);
        const customers = new Map(
          rawCustomerIds.map((id, i) => [
            id,
            {
              displayName: customerIdDisplayNames[i],
              settingsOverrides: customerIdOverrides[id],
            },
          ])
        );

        // For Mabl tests
        localStorage.testDataAuthToken = token;
        localStorage.testDataAuthTokenData = JSON.stringify(tokenData);

        if (user?.email) {
          setAuth({
            customers,
            roles,
            user: { ...user, email: user.email, isAdmin: roles.includes('Admin') },
          });
        }

        if (!customerId) {
          const defaultCustomerId = map.getFirstKey(customers);
          setCustomerId(defaultCustomerId);
        }
      })
      .catch(error => {
        throwError(new AuthError(error));
      });
  }, [getAccessTokenSilently, user?.sub, env.USE_AUTH]);

  useEffect(() => {
    setAccessTokenSilentlyGetter(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  return { user, auth, customerId };
}
