import type { ErrorInfo } from 'react';
import { fetchPut } from './fetch';
import type { ErrorLog } from '@harmonya/models';

export const logger = (
  message: string,
  stack: Error['stack'],
  componentStack: ErrorInfo['componentStack']
) => {
  /** @todo Remove 'return' after the bug is fixed */
  return;

  /** @todo Add customer ID & User email */
  const customerId = '';
  const userEmail = '';

  void fetchPut<never, ErrorLog>('/api/logs', customerId, userEmail, {
    message,
    stack,
    componentStack,
  });
};
