import { getDateKey } from '@harmonya/utils';
import type { SalesSeries, SalesSeriesItems } from '@harmonya/models';

const mapItems = (mappedKeys: string[], items: SalesSeriesItems) =>
  items.map(({ id, values, aggregatedValue }) => ({
    id: id.toString(),
    aggregatedValue,
    data: values.map((y, i) => ({ x: mappedKeys[i], y })),
  }));

export const salesSeriesToSalesSeriesItems = (salesSeries: SalesSeries) => {
  const { keys, totalSalesItems, salesGrowthItems } = salesSeries;
  const mappedKeys = keys.map(key => getDateKey(new Date(key)));

  return {
    totalSalesItems: mapItems(mappedKeys, totalSalesItems),
    salesGrowthItems: {
      inPeriod: mapItems(mappedKeys, salesGrowthItems.inPeriod),
      ...(!!salesGrowthItems.lastYear && {
        lastYear: mapItems(mappedKeys, salesGrowthItems.lastYear),
      }),
    },
  };
};
