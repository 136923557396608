import React from 'react';
import { Icon } from 'components/general/Icon';
import appStyles from '../../App.module.scss';
import attributionStyles from '../AttributionPage.module.scss';
import classNames from 'classnames';

type Props = {
  closeAnalyticsPanel: () => void;
};

export function AttributionAnalyticsPanelHeader(props: Props) {
  const { closeAnalyticsPanel } = props;
  return (
    <div className={classNames(appStyles.horizontalCenteredFlex, appStyles.justifySpaceBetween)}>
      <h2>Analytics</h2>
      <button
        className={classNames(appStyles.unpaddedButton, attributionStyles.toolbarButton)}
        onClick={closeAnalyticsPanel}
      >
        <Icon name='collapse-left' className={appStyles.mediumFont} />
      </button>
    </div>
  );
}
