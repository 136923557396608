import type { Token } from 'components/layout/explorePage/ExplorePageQuery';
import type { BaseTag } from 'models/BaseTag';
import type { AggregateTag } from '../models/AggregateTag';
import type { TagType } from '../models/TagType';
import { array, map, iterator } from '@harmonya/utils';

export const getColorizedTags = (
  sortedTopTags: AggregateTag[],
  tagTypes: Map<number, TagType>,
  activeTagTypeIds: Set<number>
) => {
  const result = activeTagTypeIds.size
    ? sortedTopTags.map(tag => {
        const dominantTypeId = iterator.find(tag.types.keys(), id => activeTagTypeIds.has(id));
        const dominantType = map.nullableGet(tagTypes, dominantTypeId);

        return dominantType ? { ...tag, dominantType } : tag;
      })
    : sortedTopTags;

  return result;
};

export const getSameNameTagSorter = (
  tagTypes: Map<number, TagType>,
  tagTypePriorityOrder: string[],
  activeTagTypeIds?: Set<number>
) => {
  const tagTypeByName = new Map(Array.from(tagTypes.values(), tagType => [tagType.name, tagType]));
  const tagTypeIdPriorityOrder = iterator.definedMap(
    tagTypePriorityOrder,
    tagTypeName => tagTypeByName.get(tagTypeName)?.id
  );

  return (a: BaseTag, b: BaseTag) => {
    if (activeTagTypeIds) {
      const aIsActive = iterator.some(a.types.keys(), id => activeTagTypeIds.has(id));
      const bIsActive = iterator.some(b.types.keys(), id => activeTagTypeIds.has(id));

      if (aIsActive && !bIsActive) {
        return -1;
      }
      if (!aIsActive && bIsActive) {
        return 1;
      }
    }

    const aIndex = tagTypeIdPriorityOrder.indexOf(a.dominantType.id);
    const bIndex = tagTypeIdPriorityOrder.indexOf(b.dominantType.id);

    if (aIndex !== bIndex) {
      return aIndex - bIndex;
    }

    // If typeIds are the same, compare by name
    return a.name.localeCompare(b.name);
  };
};

export function getTokenValueSet<T extends Token>(
  tokens: Iterable<T>,
  nameGetter: (token: T) => string | undefined
) {
  const selectedOperandsNames = iterator.definedMap(tokens, token =>
    token.type === 'operator' ? undefined : nameGetter(token)
  );
  return new Set(selectedOperandsNames);
}

export function isTokenNewToQuery(name: string, selectedOperandNames: Set<string>) {
  return !selectedOperandNames.has(name);
}

export function getIsPriortizedTag<T extends BaseTag>(
  tags: Iterable<T>,
  tagTypes: Map<number, TagType>,
  activeTagTypeIds?: Set<number>,
  tagTypePriority?: string[],
  getHash = (tag: T) => tag.hash
) {
  const tagsAsArray = [...tags];
  const tagHashToTags = tagsAsArray.reduce<Record<string, T[]>>(
    (acc, tag) => array.ensuredPush(acc, getHash(tag), tag),
    {}
  );

  const tagTypePriorityOrder = tagTypePriority ?? [];
  const sameNameTagSorter = getSameNameTagSorter(tagTypes, tagTypePriorityOrder, activeTagTypeIds);
  for (const tagHash in tagHashToTags) {
    const tagHashTags = tagHashToTags[tagHash];
    tagHashTags.sort(sameNameTagSorter);
  }
  function isPriortized<I extends { id: number }>(tag: I, hash: string) {
    return tagHashToTags[hash]?.[0].id === tag.id;
  }

  return isPriortized;
}
