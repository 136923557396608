import { selector } from 'recoil';
import { salesSeriesToSalesSeriesItems } from '../utils/timeSeriesConverter';
import { productsState } from './products';
import { explorePageBrandingLevelState } from './explorePage';

export const brandSalesEnabledLevelsState = selector({
  key: 'brandSalesEnabledLevels',
  get: ({ get }) => {
    const products = get(productsState);

    if (!products.metadata) {
      return new Set<number>();
    }

    const { brandingSalesDataByLevel } = products.metadata;
    const enabledLevels = Object.keys(brandingSalesDataByLevel).map(Number);

    return new Set(enabledLevels);
  },
});

export const brandSalesState = selector({
  key: 'brandSales',
  get: ({ get }) => {
    const levelId = get(explorePageBrandingLevelState);

    if (levelId) {
      const products = get(productsState);

      if (!products.metadata) {
        return {
          totalSalesItems: [],
          salesGrowthItems: { inPeriod: [] },
        };
      }

      const { brandingSalesDataByLevel } = products.metadata;
      const brandsSalesData = brandingSalesDataByLevel[levelId];

      if (brandsSalesData) {
        const mappedBrandSales = salesSeriesToSalesSeriesItems(brandsSalesData);

        return mappedBrandSales;
      }
    }

    return {
      totalSalesItems: [],
      salesGrowthItems: { inPeriod: [] },
    };
  },
});
