import type { DependencyList, EffectCallback } from 'react';
import { useEffect } from 'react';

export const useDebouncedEffect = (
  effect: EffectCallback,
  deps: DependencyList = [],
  debounce = 300
) => {
  useEffect(() => {
    let destructor: ReturnType<EffectCallback>;
    const timeoutId = setTimeout(() => (destructor = effect()), debounce);

    return () => {
      destructor?.();
      clearTimeout(timeoutId);
    };
  }, [...deps, debounce]);
};
