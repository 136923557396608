import { useMemo, useState } from 'react';

export const useBooleanState = (initialValue: boolean) => {
  const [value, setValue] = useState(initialValue);

  const actions = useMemo(() => {
    const setTrue = () => setValue(true);
    const setFalse = () => setValue(false);
    const toggle = () => setValue(prevValue => !prevValue);

    return [setTrue, setFalse, toggle] as const;
  }, []);

  return [value, ...actions] as const;
};
