import type { RefObject } from 'react';
import { useEffect, useState } from 'react';

type Rect = Required<DOMRectReadOnly>;
type Dimensions = Pick<Rect, 'width' | 'height'>;
type Position = Pick<Rect, 'x' | 'y'>;
type Observed = 'dimensions' | 'position' | 'both' | keyof Dimensions | keyof Position;

const detectorGetter = (observed: keyof Rect) => (oldRect: Rect, newRect: Rect) =>
  oldRect[observed] !== newRect[observed];
const changeDetectors: { [key in Observed]: ReturnType<typeof detectorGetter> } = {
  width: detectorGetter('width'),
  height: detectorGetter('height'),
  x: detectorGetter('x'),
  y: detectorGetter('y'),
  dimensions(oldRect, newRect) {
    return this.width(oldRect, newRect) || this.height(oldRect, newRect);
  },
  position(oldRect, newRect) {
    return this.x(oldRect, newRect) || this.y(oldRect, newRect);
  },
  both(oldRect, newRect) {
    return this.dimensions(oldRect, newRect) || this.position(oldRect, newRect);
  },
};

export const useBoundingClientRect = (ref: RefObject<Element>, observed: Observed = 'both') => {
  const [rect, setRect] = useState<Rect>();

  useEffect(() => {
    const element = ref.current;

    if (element) {
      const callback = () =>
        setRect(prevRect => {
          const newRect = element.getBoundingClientRect();
          const hasChange = !prevRect || changeDetectors[observed](prevRect, newRect);

          return hasChange ? newRect : prevRect;
        });
      /** @todo Consider add debounce to the observer callbacks */
      const resizeObserver = new ResizeObserver(callback);
      const intersectionObserver = new IntersectionObserver(callback);

      resizeObserver.observe(element);
      intersectionObserver.observe(element);

      return () => {
        resizeObserver.disconnect();
        intersectionObserver.disconnect();
      };
    }
  }, [ref]);

  return rect;
};
