import type { ComponentProps, ReactElement, ReactNode } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import type { Point, Tooltip } from 'highcharts';
import { SubSectionChartLegend } from './SubSectionChartLegend';
import type { SalesSeriesItem } from '../../../models/SalesSeriesItem';
import { salesHiddenIdsState } from '../../../store/salesHiddenIds';
import type { RecoilValue } from 'recoil';
import { useRecoilValue } from 'recoil';
import { growthPointState } from '../../../store/growthPoint';
import type { SalesSeries } from 'models/SalesSeries';
import type { ViewKey } from '../../layout/explorePage/ExplorePageSubSection';
import explorePageSubSectionStyles from '../../layout/explorePage/ExplorePageSubSection.module.scss';
import styles from './SubSectionChart.module.scss';
import classNames from 'classnames';
import { LimitedInlineQuery } from 'components/general/LimitedInlineQuery';
import { tagsState } from 'store/tags';
import { getChartColors } from './chart.utils';
import { useRecoilValueLoadableState } from 'hooks/useRecoilValueLoadable';
import { Loader } from '../Loader';
import { createRoot } from 'react-dom/client';
import { flushSync } from 'react-dom';
import { number, set, string } from '@harmonya/utils';
import { isValidQuery } from '../../../functions';
import type { ColorizedSalesSeriesItem } from 'components/general/charts/types';

const maxTagsInTooltip = 2;
const maxTagsInLegendTitle = 2;
const maxTagsInLegendTooltip = 12;

const getAsHtml = (component: ReactElement) => {
  const container = document.createElement('div');
  const root = createRoot(container);
  flushSync(() => root.render(component));
  const html = container.innerHTML;
  root.unmount();

  return html;
};

type SalesSeriesItemId = SalesSeriesItem['id'];

type ChildrenProps = {
  rawDataItems?: ColorizedSalesSeriesItem[];
  activatedIds: Set<SalesSeriesItem['id']>;
  hoveredIds: Set<SalesSeriesItem['id']>;
  valueFormatFunction: (value: number | string) => string;
  tooltipGetter?: (tooltip: Tooltip) => string | string[];
};

type Props = {
  alignLegend?: ComponentProps<typeof SubSectionChartLegend>['align'];
  salesSeriesState: RecoilValue<SalesSeries>;
  activeSeriesKey: ViewKey;
  sortTooltipByValuesEnabled?: boolean;
  labelTooltipEnabled?: boolean;
  sortByQueryEnabled?: boolean;
  children: (props: ChildrenProps) => JSX.Element;
};

export function SubSectionChart(props: Props) {
  const {
    alignLegend = 'bottom',
    salesSeriesState,
    activeSeriesKey,
    sortTooltipByValuesEnabled = true,
    labelTooltipEnabled = false,
    sortByQueryEnabled,
    children,
  } = props;
  const salesSeries = useRecoilValueLoadableState(salesSeriesState);
  const tags = useRecoilValue(tagsState);
  const growthPoint = useRecoilValue(growthPointState);
  const salesHiddenIds = useRecoilValue(salesHiddenIdsState);
  const [activatedIds, setActivatedIds] = useState(new Set<SalesSeriesItemId>());
  const [hoveredIds, setHoveredIds] = useState(new Set<SalesSeriesItemId>());
  const isGrowth = activeSeriesKey === 'salesGrowthItems';

  useEffect(() => {
    if (salesSeries) {
      resetActivatedIds();
      setHoveredIds(new Set());
    }
  }, [salesSeries, activeSeriesKey, growthPoint]);

  const valueFormatFunction = useCallback(
    (value: number | string) => {
      let formattedValue: string;

      if (string.isString(value)) {
        formattedValue = value;
      } else if (isGrowth) {
        formattedValue = number.fixedPercentNumber(value * 100);
      } else {
        formattedValue = number.short(value);
      }

      return isGrowth ? formattedValue : number.shortCurrency(formattedValue);
    },
    [isGrowth]
  );

  if (!salesSeries) {
    return <Loader />;
  }

  const resetActivatedIds = () => {
    const newActivatedIds = new Set<SalesSeriesItemId>();

    const add = (items: SalesSeriesItem[]) => {
      for (const { id } of items) {
        if (!salesHiddenIds.has(id)) {
          newActivatedIds.add(id);
        }
      }
    };

    add(salesSeries.salesGrowthItems.inPeriod);
    add(salesSeries.totalSalesItems);

    if (salesSeries.salesGrowthItems.lastYear) {
      add(salesSeries.salesGrowthItems.lastYear);
    }

    setActivatedIds(newActivatedIds);
  };

  const toggleActivatedId = (id: SalesSeriesItem['id']) => {
    const newActivatedIds = set.toggleSet(activatedIds, id);

    if (newActivatedIds.size) {
      setActivatedIds(newActivatedIds);
    } else {
      resetActivatedIds();
    }

    setHoveredIds(new Set());
  };

  const toggleHoveredId = (id: SalesSeriesItem['id'], state: boolean) => {
    const newHoveredIds = new Set(hoveredIds);

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    state && (!activatedIds.size || activatedIds.has(id))
      ? newHoveredIds.add(id)
      : newHoveredIds.delete(id);

    setHoveredIds(newHoveredIds);
  };

  const getQueryElement = (id: string, tagsLimit: number, asHtml = true) => {
    const isQuery = isValidQuery(id);
    const element = <LimitedInlineQuery key={id} query={id} tags={tags} limit={tagsLimit} />;
    const nameElement = isQuery ? element : id;
    const formatted: ReactNode =
      asHtml && typeof nameElement !== 'string' ? getAsHtml(element) : nameElement;

    return { isQuery, formatted };
  };

  const tooltipGetter = (tooltip: Tooltip) => {
    if (!tooltip.chart.hoverPoints || !tooltip.chart.hoverPoint) {
      return tooltip.defaultFormatter(tooltip);
    }

    // We know that values are always non-empty
    let points = tooltip.chart.hoverPoints as (Point & { y: number })[];

    if (sortTooltipByValuesEnabled) {
      points = [...points].sort((a, b) => b.y - a.y);
    }

    const title = tooltip.chart.hoverPoint.name;
    const pointsHtml = points
      .map(({ color, y, series: { name } }) => {
        const { isQuery, formatted } = getQueryElement(name, maxTagsInTooltip);
        const bulletStyle = `class='${styles.tooltipBullet}${isQuery ? ` ${styles.hasGradient}'` : `' style='background: ${color}'`}`;
        const nameStyle = `class='${styles.tooltipPointName}'`;

        return `<div class='${styles.tooltipPoint}'>
                <span class='${styles.tooltipPointLeft}'>
                    <span ${bulletStyle}> </span>
                    <span ${nameStyle}>${formatted}</span>
                </span>
                <span>${valueFormatFunction(y)}</span>
            </div>`;
      })
      .join('');
    const safeTitle = title
      ? `<div class='${explorePageSubSectionStyles.tooltipTitle}'>${title}</div>`
      : '';

    return `
            <div class='${explorePageSubSectionStyles.tooltipContainer}'>
                ${safeTitle}
                ${pointsHtml}
            </div>`;
  };

  const rawDataItems = isGrowth
    ? salesSeries.salesGrowthItems[growthPoint]
    : salesSeries.totalSalesItems;

  if (rawDataItems?.length) {
    const rawDataItemsWithQueryFlag = rawDataItems.map(item => ({
      ...item,
      isQuery: isValidQuery(item.id),
    }));
    const hasQuery = rawDataItemsWithQueryFlag.some(item => item.isQuery);
    const colors = getChartColors(hasQuery);
    const colorizedDataItems = rawDataItemsWithQueryFlag.map((item, i) => ({
      ...item,
      color: colors[i],
    }));
    const sortedColorizedDataItems = sortByQueryEnabled
      ? [...colorizedDataItems].sort(item => (item.isQuery ? 1 : -1))
      : colorizedDataItems;
    // In order for the line to be displayed on top of all other lines
    // it has to be the last item in the array
    const sortedLegenedItems = sortByQueryEnabled
      ? [...sortedColorizedDataItems].reverse()
      : colorizedDataItems;
    const chartProps = {
      rawDataItems: sortedColorizedDataItems,
      activatedIds,
      hoveredIds,
      valueFormatFunction,
      sortTooltipByValuesEnabled,
      ...(!labelTooltipEnabled && { tooltipGetter }),
    };

    return (
      <div className={classNames(styles.container, alignLegend === 'left' && styles.legendLeft)}>
        {children(chartProps)}
        <SubSectionChartLegend
          tooltipEnabled={labelTooltipEnabled}
          align={alignLegend}
          titleGetter={(title, placement) =>
            getQueryElement(
              title,
              placement === 'tooltip' ? maxTagsInLegendTooltip : maxTagsInLegendTitle,
              false
            ).formatted
          }
          data={sortedLegenedItems.map(({ id, isQuery, color }) => ({
            id,
            hasGradient: isQuery,
            color,
          }))}
          activatedIds={activatedIds}
          onClick={toggleActivatedId}
          onHover={toggleHoveredId}
        />
      </div>
    );
  }

  return null;
}
