import type { ComponentProps } from 'react';
import React from 'react';
import styles from './TagTooltipItem.module.scss';
import { Icon } from 'components/general/Icon';

type Props = {
  title: string;
  value: string;
  icon: ComponentProps<typeof Icon>;
};

export function TagTooltipItem(props: Props) {
  const { title, value, icon } = props;

  return (
    <>
      <Icon {...icon} className={styles.icon} />
      <span className={styles.title}>{title}</span>
      <span className={styles.value}>{value}</span>
    </>
  );
}
