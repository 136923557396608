import { selector } from 'recoil';
import { fetchGet } from '../utils/fetch';
import { authState, customerIdState } from './auth';
import { getTreeMap, selectOptionsToTreeMap, getPaths } from 'utils/node';
import { iterator } from '@harmonya/utils';
import type { SelectOption } from '@harmonya/models';

export const rawCategoryListState = selector({
  key: 'rawCategoryList',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: async ({ get }) => {
    const customerId = get(customerIdState);
    const { user } = get(authState);
    const rawCategories = await fetchGet<SelectOption[]>('/api/categories', customerId, user.email);

    return rawCategories;
  },
});

export const rawCategoriesState = selector({
  key: 'rawCategories',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const rawCategories = get(rawCategoryListState);
    const categoriesMap = getTreeMap(rawCategories);

    return categoriesMap;
  },
});

export const sortedFlattenTreeCategoriesState = selector({
  key: 'treeCategories',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const categoriesMap = get(rawCategoriesState);
    const treeMap = selectOptionsToTreeMap(
      categoriesMap,
      category => category.parent,
      category => category.children
    );

    return treeMap;
  },
});

export const categoriesState = selector({
  key: 'categories',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const categories = get(rawCategoriesState);
    const categoriesMap = new Map([...categories].map(([id, { name }]) => [id, name]));

    return categoriesMap;
  },
});

export const categoriesPathState = selector({
  key: 'groupedCategories',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const categories = get(rawCategoriesState);
    const categoriesPaths = getPaths(categories);

    return new Map(categoriesPaths);
  },
});

export const categoryLevelsState = selector({
  key: 'categoryLevels',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const categories = get(rawCategoryListState);
    const levels = new Map<number, string>();

    for (const category of categories.values()) {
      const { level } = category;
      const { name, id } = level ?? {};

      if (id != null && !levels.has(id)) {
        levels.set(id, name ?? '');
      }
    }

    const levelOptions = iterator.definedMap(levels.entries(), ([id, name]) => ({
      value: id,
      displayValue: name,
    }));

    return levelOptions;
  },
});
