import { iterator } from '@harmonya/utils';
import type { QueryToken, TokenId } from '@harmonya/models';

const typeDelimiter = ':';
const tokensDelimiter = '_';

export const setsToTokens = (sets: Set<TokenId>[]) => {
  const andOperator: QueryToken = { key: 'logic', type: 'operator', id: 'AND' };
  const orOperator: QueryToken = { key: 'logic', type: 'operator', id: 'OR' };

  const conditionTokens = sets.map<QueryToken[]>(set =>
    iterator.join<QueryToken>(
      Array.from(set, id => ({ key: 'tag', type: 'operand', id })),
      andOperator
    )
  );
  return iterator.join<QueryToken[]>(conditionTokens, [orOperator]).flatMap(set => set);
};

export const tokensToQuery = (tokens: QueryToken[]) => {
  const hasOperandId = tokens.some(token => token.type === 'operand' && token.id);
  return hasOperandId
    ? tokens
        .map(token => [token.type, token.key, token.id].join(typeDelimiter))
        .join(tokensDelimiter)
    : '';
};

const queryTokenToToken = (queryToken: string) => {
  const [type, key, id] = queryToken.split(typeDelimiter);
  return { type, key, id: key === 'tag' ? Number(id) : id } as QueryToken;
};

export const queryToTokens = (query: string) => {
  return query?.split(tokensDelimiter).map(queryTokenToToken) ?? [];
};

export const setsToQuery = (sets: Set<TokenId>[]) => {
  const tokens = setsToTokens(sets);
  return tokensToQuery(tokens);
};

export const tagSources = {
  all: 'All',
  reviewsListing: 'Reviews AND Listing',
  reviews: 'Only Reviews',
  listing: 'Only Listing',
} as const;

export const splitConditionsBy = (token: QueryToken) => {
  const isOperator = token.type === 'operator';
  const isOr = token.id === 'OR';

  return token && isOr && isOperator;
};

const updateConditionsByToken = (conditions: Set<TokenId>[], token: QueryToken) => {
  if (token.id && (token.type !== 'operator' || token.id !== 'AND')) {
    if (splitConditionsBy(token)) {
      conditions.push(new Set());
    } else if (conditions.length) {
      conditions[conditions.length - 1].add(token.id);
    } else {
      conditions.push(new Set([token.id]));
    }
  }

  return conditions;
};

export const queryToSets = (query?: string): Set<TokenId>[] => {
  return (
    query?.split(tokensDelimiter).reduce<Set<TokenId>[]>((conditions, queryToken) => {
      const token = queryTokenToToken(queryToken);

      return updateConditionsByToken(conditions, token);
    }, []) ?? []
  );
};

const validQueryRegExp = new RegExp(
  `^[\\w]*${typeDelimiter}[\\w]*${typeDelimiter}[\\w]*(?:${tokensDelimiter}[\\w]*${typeDelimiter}[\\w]*${typeDelimiter}[\\w]*)*$`
);

export const isValidQuery = (query: string) => validQueryRegExp.test(query);
