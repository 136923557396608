import * as React from 'react';
import classNames from 'classnames';
import { getColorClassById } from 'utils/styleVariables';
import styles from './Tag.module.scss';
import appStyles from '../layout/App.module.scss';

type Props = {
  name: string;
  colorId: number;
  isLoading?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function Tag(props: Props) {
  const { name, colorId, isLoading, className, ...divProps } = props;
  const computedClassName = classNames(
    appStyles.borderedTag,
    styles.tag,
    getColorClassById(colorId),
    isLoading && styles.loading,
    className
  );

  return (
    <div {...divProps} className={computedClassName}>
      {name}
    </div>
  );
}
