import type { WorkspaceType } from './NavWorkspace.utils';
import React from 'react';
import classNames from 'classnames';
import appStyles from '../../layout/App.module.scss';
import { Icon } from 'components/general/Icon';
import './WorkspaceTypeHeader.module.scss';

type Props = {
  type: WorkspaceType;
};

export function WorkspaceTypeHeader(props: Props) {
  switch (props.type) {
    case 'insight':
      return (
        <h5 className={classNames(appStyles.horizontalFlex, appStyles.gap1, appStyles.alignCenter)}>
          <Icon name='arrows-rotate' weight='light' className={appStyles.smallFont} />
          Harmonya Insights
        </h5>
      );
    case 'attribution':
      return null;
  }
}
