import type { Option, RawOptionType } from 'components/general/select/types';
import type { Highlightable } from './sortedFilteredOptions';

export function getHighlightables(
  option: Option<RawOptionType>,
  highlightedOptions: Map<string, Highlightable[]>
): Highlightable[] {
  const node =
    option.displayValue ?? (option.value instanceof Date ? option.value.toString() : option.value);
  const isHighlightable = typeof node === 'string';

  if (highlightedOptions.size && isHighlightable) {
    const highlightables = highlightedOptions.get(node);

    if (highlightables) {
      return highlightables;
    }
  }

  return [{ node, isHighlighted: false }];
}
