import type { TagType } from './TagType';
import { map } from '@harmonya/utils';
import type { Dimensions } from 'store/tags';
import type { Tag } from '@harmonya/models';

export class BaseTag implements Omit<Tag, 'typeIds' | 'productsCount'> {
  id: number;
  name: string;
  types: Map<TagType['id'], TagType>;
  width: number;
  height: number;
  hash: string;

  constructor(
    tag: Omit<Tag, 'productsCount'>,
    allTagTypes: Map<number, TagType>,
    dimensions: Dimensions
  ) {
    this.id = tag.id;
    this.name = tag.name;
    this.types = new Map(tag.typeIds.map(id => [id, map.safeGet(allTagTypes, id)]));
    this.width = dimensions.width;
    this.height = dimensions.height;
    this.hash = tag.hash ?? tag.name;
  }

  get dominantType(): TagType {
    return map.getFirstMap(this.types);
  }
}
