import { selector } from 'recoil';
import { fetchPost } from '../utils/fetch';
import { authState, customerIdState } from './auth';

import { object } from '@harmonya/utils';
import type { FilterDependencies } from '@harmonya/models';

export type FilterDependenciesClient = {
  brandingIds: {
    categoryIds: { [categoryId: string]: Set<number> };
  };
};

export const filterDependenciesState = selector({
  key: 'filterDependencies',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: async ({ get }) => {
    const customerId = get(customerIdState);
    const { user } = get(authState);
    const response = await fetchPost<FilterDependencies>(
      '/api/filterDependencies',
      customerId,
      user.email
    );
    const responseWithSet: FilterDependenciesClient = { brandingIds: { categoryIds: {} } };

    for (const dependencyKey of object.keys(response)) {
      const dependency = response[dependencyKey];

      for (const dependsOn of object.keys(dependency)) {
        for (const id in dependency[dependsOn]) {
          const dependentIds = dependency[dependsOn][id];

          responseWithSet[dependencyKey][dependsOn][id] = new Set(dependentIds);
        }
      }
    }

    return responseWithSet;
  },
});
