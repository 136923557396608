import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { analyticsState } from 'store/analytics';
import { styleVariables } from 'utils/styleVariables';

export const useExpander = <T extends HTMLElement>(
  initialLeftWidth: string,
  expanderLeftOffset: number,
  containerXOffset: number | undefined,
  containerOffsetWidth: number | undefined,
  containerOffsetHeight: number | undefined,
  expanderMinLeft: number,
  expanderMinRight: number
) => {
  const [leftWidth, setLeftWidth] = useState(initialLeftWidth);
  const [rightWidth, setRightWidth] = useState(expanderMinRight);
  const [isDragging, setIsDragging] = useState(false);
  const analytics = useRecoilValue(analyticsState);
  const expanderRef = useRef<T>(null);

  const handleDrag = (event: MouseEvent) => {
    if (isDragging) {
      event.preventDefault(); // Prevent text selection during dragging

      if (containerOffsetWidth == null || containerXOffset == null) {
        return;
      }

      const mouseX = event.clientX - containerXOffset - expanderLeftOffset;
      let newLeftWidth = mouseX / containerOffsetWidth;

      // handle min width of the expandable parts
      if (expanderMinLeft || expanderMinRight) {
        const leftWidthInPixels = newLeftWidth * containerOffsetWidth;
        const rightWidthInPixels = (1 - newLeftWidth) * containerOffsetWidth;

        if (leftWidthInPixels <= expanderMinLeft) {
          newLeftWidth = expanderMinLeft / containerOffsetWidth;
        } else if (rightWidthInPixels <= expanderMinRight) {
          newLeftWidth = (containerOffsetWidth - expanderMinRight) / containerOffsetWidth;
        }
      }

      const newWidth = `${100 * newLeftWidth}%`;

      setLeftWidth(newWidth);
      setRightWidth(containerOffsetWidth * (1 - newLeftWidth));
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    analytics.track('Expander dragged', { name: 'Expander', type: 'drag' });
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  useEffect(() => {
    if (expanderRef?.current && isDragging) {
      const controller = new AbortController();
      const { signal } = controller;

      document.addEventListener('mousemove', handleDrag, { signal });
      document.addEventListener('mouseup', handleDragEnd, { signal });
      setLeftWidth(leftWidth);

      return () => {
        controller.abort();
      };
    }
  }, [expanderRef?.current, isDragging]);

  useEffect(() => {
    if (containerOffsetWidth) {
      const handler = setTimeout(
        () => setLeftWidth(`${leftWidth} `),
        styleVariables.animationDuration
      );

      return () => clearTimeout(handler);
    }
  }, [containerOffsetWidth, containerOffsetHeight]);

  return { expanderRef, leftWidth, rightWidth, handleDragStart };
};
