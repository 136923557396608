import React from 'react';
import styles from './TagsVennNoFound.module.scss';

type Props = {
  top: number;
  width: number;
  left: number;
};

export function TagsVennNoFound(props: Props) {
  return (
    <div className={styles.container} style={props}>
      No Tags Found
    </div>
  );
}
