import type { PropsWithChildren } from 'react';
import React from 'react';
import styles from './AnimatedFontWeight.module.scss';
import classNames from 'classnames';

type Weights =
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900
  | 'bold'
  | 'bolder'
  | 'lighter'
  | 'normal';

type Props = PropsWithChildren<{
  altWeight: Weights;
  altActivated: boolean;
  children: string;
  className?: string;
}>;

export function AnimatedFontWeight(props: Props) {
  const { altWeight, altActivated, children, className } = props;

  return (
    <span className={styles.container}>
      <span
        className={classNames(
          className,
          styles.text,
          styles.origin,
          altActivated && styles.altActivated
        )}
      >
        {children}
      </span>
      <span
        className={classNames(className, styles.text, styles.alt)}
        style={{ fontWeight: altWeight }}
      >
        {children}
      </span>
    </span>
  );
}
