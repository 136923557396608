import type { FormEvent, ReactNode } from 'react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './SelectDropdown.module.scss';
import { SelectSelectedOptions } from './SelectSelectedOptions';
import { SelectOptions } from './SelectOptions';
import type { Highlightable } from 'utils/sortedFilteredOptions';
import { getSortedFilteredOptions } from 'utils/sortedFilteredOptions';
import { SelectAllButton } from './SelectAllButton';
import { useKey } from 'hooks/useKey';
import {
  allDescendantsSelected,
  getChildren,
  getWithoutImplicitNodes,
  hasSelectedAncestor,
} from 'utils/node';
import { SelectExpandCollapseButton } from './SelectExpandCollapseButton';
import { getHighlightables } from 'utils/highlight';
import { array, iterator, set } from '@harmonya/utils';
import type {
  GroupedOptions,
  NormalizedCollection,
  Option,
  RawOptionType,
} from 'components/general/select/types';

function getOnChangeFunction<T extends RawOptionType, O extends Option<T>, V extends T | T[]>(
  onChange: (value: V) => void,
  isSelected: (option: O) => boolean,
  propsValue: V,
  isMultiselect?: boolean
) {
  return isMultiselect
    ? (option: O) => {
        const ensuredPropsValue = array.ensure(propsValue);
        const oldValues = new Set(ensuredPropsValue);
        const optionSelected =
          hasSelectedAncestor(isSelected, option) || allDescendantsSelected(oldValues, option);
        const newValues = set.toggleSet(oldValues, option.value, false, optionSelected);
        const newValuesWithoutImplicitNodes = getWithoutImplicitNodes(
          oldValues,
          newValues,
          option,
          !optionSelected
        );
        const newValuesWithoutImplicitNodesAsArray = [...newValuesWithoutImplicitNodes];

        onChange(newValuesWithoutImplicitNodesAsArray as V);
      }
    : (option: O) => onChange(option.value as V);
}

const defaultHighlightedIndex = { groupIndex: 0, optionIndex: 0 };
const optionToRawOption = (option: Option<RawOptionType>): RawOptionType => option.value;

type Props<T extends RawOptionType, O extends Option<T>, V extends T | T[]> = {
  value: V;
  onChange: (value: V) => void;
  options: O[] | GroupedOptions<O>;
  searchEnabled?: boolean;
  isMultiselect?: boolean;
  isSelected?: (option: O) => boolean;
  selectedOptions?: O[];
  colorInverted?: boolean;
  notFoundText?: string;
  selectedOptionsTitle?: string;
  displayValueGetter: (option: O, highlights?: Highlightable[]) => ReactNode;
  selectedOptionDisplayValueGetter?: (option: O) => ReactNode;
  sections?: ReactNode[];
  footer?: ReactNode;
  optionClassNameGetter?: (option: O) => string;
  className?: string;
  isCheckboxDisplayed?: boolean;
  arrowPosition?: 'left' | 'right';
  formatSearchResults?: (
    groupedOptions: NormalizedCollection<O>,
    searchValue: string
  ) => { postSearchOptionGroups: NormalizedCollection<O>; newExpandedIds: Set<T> };
};

export function SelectDropdown<T extends RawOptionType, O extends Option<T>, V extends T | T[]>(
  props: Props<T, O, V>
) {
  const {
    value,
    onChange,
    options,
    searchEnabled,
    isMultiselect,
    isSelected = () => false,
    selectedOptions = [],
    colorInverted,
    notFoundText,
    selectedOptionsTitle,
    displayValueGetter,
    selectedOptionDisplayValueGetter,
    sections,
    footer,
    optionClassNameGetter,
    className,
    formatSearchResults,
    isCheckboxDisplayed,
    arrowPosition,
  } = props;
  const selectOptionsRef = useRef<HTMLDivElement>(document.createElement('div'));
  const [searchValue, setSearchValue] = useState<string>('');
  const [highlightedIndex, setHighlightedIndex] = useState(defaultHighlightedIndex);
  const searchRef = useRef<HTMLInputElement>(null);
  const { filteredOptions, highlightedOptions } = useMemo(
    () => getSortedFilteredOptions(searchValue, options, ['displayValue', 'searchValue']),
    [searchValue, options]
  );
  const isGroups = !Array.isArray(filteredOptions);
  const rawGroupedOptions: NormalizedCollection<O> = isGroups
    ? [...filteredOptions]
    : [['', filteredOptions]];
  const isHierarchial = rawGroupedOptions.some(([, groupOptions]) =>
    groupOptions.some(option => option.node)
  );
  const [expandedIds, setExpandedIds] = useState(() => {
    if (isHierarchial) {
      const topLevelParents = rawGroupedOptions.flatMap<Option<T>>(
        ([, groupOptions]) =>
          iterator.definedMap(groupOptions, option =>
            option.node?.parent ? undefined : option
          ) as Option<T>[]
      );
      let children = topLevelParents?.[0]?.node?.children;

      // In case of one element, find level with more than one element and expand it and the path to it
      const pathToMultipleChildren = [];
      while (children && topLevelParents.length === 1) {
        if (children.length > 1) {
          const childrenIds = [...pathToMultipleChildren, ...children].map(child => child.value);

          return new Set(childrenIds);
        }

        const [firstChild] = children;
        pathToMultipleChildren.push(firstChild);
        const nextLevelChildren = firstChild?.node?.children;
        children = nextLevelChildren;
      }

      const topLevelParentIds = topLevelParents.map(parent => parent.value);

      return new Set(topLevelParentIds);
    }

    return new Set<T>();
  });
  const isAllExpanded = isHierarchial && [...options.values()].flat().length === expandedIds.size;
  let groupedOptions = rawGroupedOptions;
  let computedExpandedIds = new Set<T>();

  if (searchValue && formatSearchResults) {
    // when search enabled, we want to show the results as expanded and keep the expanded ids
    ({ postSearchOptionGroups: groupedOptions, newExpandedIds: computedExpandedIds } =
      formatSearchResults(rawGroupedOptions, searchValue));
  }

  set.addMany(computedExpandedIds, expandedIds);

  const computedOnChange = getOnChangeFunction(onChange, isSelected, value, isMultiselect);
  const onMultipleClick = isMultiselect
    ? (values: Option<RawOptionType>[]) => {
        let newValues: RawOptionType[];

        if (Array.isArray(value) && value.length) {
          const existsValues = value as RawOptionType[];

          newValues = [
            ...existsValues,
            ...values
              .filter(newValue => !existsValues.includes(newValue.value))
              .map(optionToRawOption),
          ];
        } else {
          newValues = values.map(optionToRawOption);
        }

        onChange(newValues as V);
      }
    : undefined;
  const onSearchInput = (event: FormEvent<HTMLInputElement>) =>
    setSearchValue(event.currentTarget.value);
  const isVisible = (option: Option<T>) =>
    !option.node?.parent || computedExpandedIds.has(option.value);
  const computedHighlightedIndex = (() =>
    highlightedIndex.groupIndex +
    iterator.sum(groupedOptions, ([, groupOptions], groupIndex) =>
      iterator.count(
        groupOptions,
        (option, optionIndex) =>
          groupIndex <= highlightedIndex.groupIndex &&
          optionIndex < highlightedIndex.optionIndex &&
          isVisible(option)
      )
    ))();

  const toggleChildren = (parentOption: Option<T>) => {
    const newExpandedIds = new Set(expandedIds);

    parentOption.node?.children.forEach(childOption =>
      set.toggleSet(newExpandedIds, childOption.value, true)
    );

    // If collapsing, collapse all children
    if (newExpandedIds.size < expandedIds.size) {
      for (const child of getChildren(parentOption, option => option.node?.children ?? [])) {
        if (child.value !== parentOption.value) {
          newExpandedIds.delete(child.value);
        }
      }
    }

    // Make sure the parents are always expanded
    let parent = parentOption.node?.parent;

    while (parent) {
      set.addMany(newExpandedIds, parent.node?.children, child => child.value);
      parent = parent.node?.parent;
    }

    setExpandedIds(newExpandedIds);
  };

  const setHighlightedIndexByOffset = (offset: number) => {
    let computedOffset = offset;
    const offsetSign = Math.sign(offset);
    let { groupIndex, optionIndex } = highlightedIndex;

    while (computedOffset) {
      computedOffset -= offsetSign;
      optionIndex += offsetSign;

      if (!(optionIndex in groupedOptions[groupIndex][1])) {
        groupIndex = (groupIndex + offsetSign + groupedOptions.length) % groupedOptions.length;
        optionIndex = optionIndex < 0 ? groupedOptions[groupIndex][1].length - 1 : 0;
      }
    }

    setHighlightedIndex({ groupIndex, optionIndex });
  };

  const handleOptionsMouseOver = (option: O) => {
    const groupIndex = groupedOptions.findIndex(([, groupOptions]) =>
      groupOptions.includes(option)
    );
    const optionIndex = groupedOptions[groupIndex][1].indexOf(option);

    setHighlightedIndex({ groupIndex, optionIndex });
  };

  const getExpandCollapseButton = () => {
    const onClick = () => {
      const newExpandedIds = isAllExpanded
        ? []
        : [...options.values()].flat().map(option => option.value);

      setExpandedIds(new Set(newExpandedIds));
    };

    return (
      <SelectExpandCollapseButton
        className={styles.expandCollapseButton}
        onClick={onClick}
        isAllExpanded={isAllExpanded}
        colorInverted={colorInverted}
      />
    );
  };

  const getSelectAllButton = () => {
    if (onMultipleClick) {
      const onClick = () => {
        const flattedGroupOptions = groupedOptions
          .flatMap(([, groupOptions]) => groupOptions)
          .filter(option => !option.node?.parent && !option.disabled);

        onMultipleClick(flattedGroupOptions);
      };

      const allChildrenSelected = isHierarchial
        ? groupedOptions.every(([, groupOptions]) =>
            groupOptions.every(option => option.node?.level !== 1 || isSelected(option))
          )
        : Array.isArray(value) &&
          groupedOptions.every(
            ([, groupOptions]) =>
              value.length >= groupOptions.length &&
              groupOptions.every(option => value.includes(option.value))
          );
      const allChildrenCount = iterator.sum(
        groupedOptions,
        ([, groupOptions]) => groupOptions.length
      );
      const activeChildrenCount = iterator.sum(groupedOptions, ([, groupOptions]) =>
        iterator.count(groupOptions, option => !option.disabled)
      );

      return (
        <SelectAllButton
          className={styles.selectAllButton}
          iconDisplayed={!isHierarchial}
          onClick={onClick}
          allChildrenSelected={allChildrenSelected}
          activeChildrenCount={activeChildrenCount}
          allChildrenCount={allChildrenCount}
          colorInverted={colorInverted}
          disabled={!activeChildrenCount}
        />
      );
    }
  };

  useKey(
    {
      ArrowUp: { stopPropagation: true, listener: () => setHighlightedIndexByOffset(-1) },
      ArrowDown: {
        stopPropagation: true,
        listener: () => setHighlightedIndexByOffset(1),
      },
      Enter: {
        stopPropagation: true,
        listener: () => {
          const [, groupOptions] = groupedOptions[highlightedIndex.groupIndex];
          const option = groupOptions[highlightedIndex.optionIndex];

          computedOnChange(option);
        },
      },
    },
    [highlightedIndex, value, filteredOptions]
  );

  useEffect(() => {
    setTimeout(() => searchRef.current?.focus());
  }, []);

  const selectAllButton = getSelectAllButton() ?? false;
  /**
   * @todo Consider fixing according to this comment:
   * @see https://github.com/harmonya-ai/harmonya-app/pull/1836#discussion_r1616623047
   */
  const buttonsContainerDisplayed = (isMultiselect && !searchEnabled) || isHierarchial;

  return (
    <div className={classNames(styles.container, colorInverted && styles.colorInverted, className)}>
      {sections}
      {buttonsContainerDisplayed && (
        <div className={styles.buttonsContainer}>
          {isHierarchial && getExpandCollapseButton()}
          {selectAllButton}
        </div>
      )}
      {searchEnabled && (
        <>
          <input
            ref={searchRef}
            value={searchValue}
            onInput={onSearchInput}
            className={classNames(styles.search, isMultiselect && styles.hasSelectAllButton)}
            placeholder='Search...'
          />
          {!isHierarchial && selectAllButton}
        </>
      )}
      {isMultiselect && !!selectedOptions.length && (
        <SelectSelectedOptions
          onClick={computedOnChange}
          selectedOptions={selectedOptions}
          selectedOptionsTitle={selectedOptionsTitle}
          displayValueGetter={selectedOptionDisplayValueGetter ?? displayValueGetter}
          optionClassNameGetter={optionClassNameGetter}
        />
      )}
      <SelectOptions
        options={groupedOptions}
        highlightedIndex={computedHighlightedIndex}
        isVisible={isHierarchial ? isVisible : undefined}
        isMultiselect={isMultiselect}
        isSelected={isSelected}
        onClick={computedOnChange}
        onMultipleClick={onMultipleClick}
        onMouseOver={handleOptionsMouseOver}
        colorInverted={colorInverted}
        displayValueGetter={option => {
          const highlightables = getHighlightables(option, highlightedOptions);
          const displayValue = displayValueGetter(option, highlightables);

          return displayValue;
        }}
        notFoundText={notFoundText}
        parentRef={selectOptionsRef}
        toggleChildren={toggleChildren}
        optionClassNameGetter={optionClassNameGetter}
        isCheckboxDisplayed={isCheckboxDisplayed}
        isHierarchial={isHierarchial}
        arrowPosition={arrowPosition}
      />
      {footer && <div>{footer}</div>}
    </div>
  );
}
