import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { growthPointState } from 'store/growthPoint';
import type { FormmaterKey } from 'utils/formatters';
import type { Growth } from '@harmonya/models';
import type { AggregateTag } from '../models/AggregateTag';

type TagValueProp = keyof AggregateTag['values'] & FormmaterKey;
type ItemType = Pick<AggregateTag, 'values'>;

export const getTagValueGetter = (
  prop: TagValueProp,
  growthPoint: keyof Growth
): ((item: ItemType) => number | null) =>
  prop === 'salesGrowth'
    ? useCallback(item => item.values.salesGrowth[growthPoint] ?? null, [growthPoint])
    : useCallback(item => item.values[prop] ?? null, [prop]);

export const useTagValueGetter = (prop: TagValueProp): ((item: ItemType) => number | null) => {
  const growthPoint = useRecoilValue(growthPointState);
  const tagValueGetter = getTagValueGetter(prop, growthPoint);

  return tagValueGetter;
};

export const useTagPropGetter = (): ((item: ItemType, prop: TagValueProp) => number | null) => {
  const growthPoint = useRecoilValue(growthPointState);

  const tagPropValueGetter = (item: ItemType, prop: TagValueProp): number | null => {
    const value = prop === 'salesGrowth' ? item.values.salesGrowth[growthPoint] : item.values[prop];

    return value ?? null;
  };

  return tagPropValueGetter;
};
