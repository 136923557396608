import type { CSSProperties, ReactNode } from 'react';
import React, { Suspense } from 'react';
import classNames from 'classnames';
import appStyles from '../App.module.scss';
import styles from './ExplorePageSubSection.module.scss';
import type { IconName } from '../../general/Icon';
import { Icon } from '../../general/Icon';
import type { SalesSeries } from '../../../models/SalesSeries';
import { useSearchParam } from '../../../hooks/useSearchParam';
import { ButtonGroup } from '../../general/ButtonGroup';
import type { RecoilState } from 'recoil';
import { useRecoilState, useRecoilValue } from 'recoil';
import type { SortDirection } from '@harmonya/utils';
import { array, object } from '@harmonya/utils';
import { analyticsState } from 'store/analytics';
import { Loader } from 'components/general/Loader';

export type ViewKey = keyof SalesSeries;

const viewIcons: { [key in ViewKey]: IconName } = {
  totalSalesItems: 'dollar',
  salesGrowthItems: 'percent',
};
const defaultViewIcon = object.keys(viewIcons);

interface Content {
  iconName: IconName;
  element: JSX.Element;
  placeholder: string;
  label: ReactNode;
}

function getButtonGroup<T, Entry>(
  entries: Entry[],
  mapper: (entry: Entry, index: number) => [T, IconName],
  selectedValue: T,
  setValue: (newValue: T) => void
) {
  if (entries.length > 1) {
    const buttons = entries.map((entry, i) => {
      const [value, iconName] = mapper(entry, i);

      return { value, element: <Icon name={iconName} weight='light' /> };
    });

    return (
      <ButtonGroup selectedValue={selectedValue} onClick={setValue}>
        {buttons}
      </ButtonGroup>
    );
  }
}

const getSortButtonGroup = (sortState?: RecoilState<SortDirection>) => {
  if (sortState) {
    const [sortDirection, setSortDirection] = useRecoilState(sortState);
    const entries: [SortDirection, IconName][] = [
      ['asc', 'arrow-down'],
      ['desc', 'arrow-up'],
    ];
    const buttonGroup = getButtonGroup(entries, entry => entry, sortDirection, setSortDirection);

    return buttonGroup;
  }
};

const getContentButtonGroup = (
  contents: Content[],
  activeContentIndex: number,
  setActiveContentIndex: (newValue?: number) => void
) => {
  const buttonGroup = getButtonGroup(
    contents,
    ({ iconName }, i) => [i, iconName],
    activeContentIndex,
    setActiveContentIndex
  );

  return buttonGroup;
};

const getViewButtonGroup = (
  enabledViewKeys: ViewKey[],
  activeSeriesKey: ViewKey,
  setActiveSeriesKey: (newValue: ViewKey) => void
) => {
  const buttonGroup = getButtonGroup(
    enabledViewKeys,
    key => [key, viewIcons[key]],
    activeSeriesKey,
    setActiveSeriesKey
  );

  return buttonGroup;
};

type Props = {
  iconName: IconName;
  title: string;
  sectionId?: string;
  sortState?: RecoilState<SortDirection>;
  enabledViewKeys?: ViewKey[];
  defaultViewKey?: ViewKey;
  displayFilters?: ReactNode;
  children: (activeSeriesKey: ViewKey) => Content | Content[];
};

export function ExplorePageSubSection(props: Props) {
  const {
    iconName,
    title,
    sectionId,
    sortState,
    enabledViewKeys = defaultViewIcon,
    defaultViewKey = 'totalSalesItems',
    displayFilters,
    children,
  } = props;
  const sectionKey = sectionId ?? title;
  const [activeSeriesKey, setActiveSeriesKey] = useSearchParam<ViewKey>(
    `active${sectionKey}SeriesKey`,
    { defaultValue: defaultViewKey }
  );
  const [activeContentIndex, setActiveContentIndex] = useSearchParam(
    `active${sectionKey}ContentIndex`,
    { defaultValue: 0, parser: 'number' }
  );
  const analytics = useRecoilValue(analyticsState);

  const handleSeriesKeyActivation = (value: ViewKey) => {
    setActiveSeriesKey(value);

    analytics.track('Chart view click', { name: title, value, type: 'button' });
  };

  const contents = array.ensure(children(activeSeriesKey));
  const computedActiveContentIndex =
    activeContentIndex > contents.length - 1 ? 0 : activeContentIndex;
  const sortButtonGroup = getSortButtonGroup(sortState);
  const contentButtonGroup = getContentButtonGroup(
    contents,
    computedActiveContentIndex,
    setActiveContentIndex
  );
  const viewButtonGroup = getViewButtonGroup(
    enabledViewKeys,
    activeSeriesKey,
    handleSeriesKeyActivation
  );
  const { label } = contents[computedActiveContentIndex];

  return (
    <div className={classNames(appStyles.verticalFlex, styles.container)}>
      <div className={classNames(appStyles.horizontalFlex, appStyles.justifySpaceBetween)}>
        <div className={appStyles.verticalFlex}>
          <h3 className={styles.title}>
            <Icon className={styles.titleIcon} name={iconName} weight='light' />
            {` ${title}`}
          </h3>
          <h5>{label}</h5>
        </div>
        <div className={appStyles.horizontalFlex}>
          {displayFilters}
          {contentButtonGroup}
          {sortButtonGroup}
          {viewButtonGroup}
        </div>
      </div>
      <div className={styles.contents}>
        <Suspense fallback={<Loader />}>
          {contents.map(({ element, placeholder }, i) => (
            <div
              key={i}
              style={{ '--offset': computedActiveContentIndex - i } as CSSProperties}
              className={styles.content}
              data-placeholder={placeholder}
            >
              {element}
            </div>
          ))}
        </Suspense>
      </div>
    </div>
  );
}
