import { selector } from 'recoil';
import { fetchPost } from '../utils/fetch';
import { getSalesFilters } from './explorePage';
import { authState, customerIdState } from './auth';
import { productSalesSortDirectionState } from './productSalesState';
import { brandSalesSortDirectionState } from './brandSales.constants';
import type { Products } from '@harmonya/models';
import { brandingsState, rawBrandingState } from './branding';
import { rawCategoriesState } from './categories';
import { marketsState } from './markets';
import { periodsState } from './periods';
import { getNamedPath } from 'utils/node';
import { missingValue, number, map } from '@harmonya/utils';

export const productsState = selector({
  key: 'products',
  get: async ({ get }) => {
    const filters = getSalesFilters(get);
    const productSalesSortDirection = get(productSalesSortDirectionState);
    const brandSalesSortDirection = get(brandSalesSortDirectionState);
    const customerId = get(customerIdState);
    const { user } = get(authState);
    const products = await fetchPost<Products>('/api/products', customerId, user.email, {
      ...filters,
      productSalesSortDirection,
      brandSalesSortDirection,
    });
    /**
     * @todo Solve this infrastructurally at `resolve(new Response('{}'));` in `throttledFetch.ts:13`
     * @see https://app.clickup.com/t/86bzby638
     */
    const { items = [], ...otherProps } = products;

    return { items, ...otherProps };
  },
});

export const productsTableState = selector({
  key: 'productsTable',
  get: ({ get }) => {
    const brandings = get(brandingsState);
    const { metadata, items } = get(productsState);
    const mappedItems = items.map(
      ({ brandingId, periodIds, marketIds, brandId, marketShare, ...item }) => ({
        ...item,
        brandName: map.safeGet(brandings, brandId).name,
      })
    );

    return { metadata, items: mappedItems };
  },
});

export const productsExportState = selector({
  key: 'productsExport',
  get: ({ get }) => {
    const brandings = get(rawBrandingState);
    const categories = get(rawCategoriesState);
    const markets = get(marketsState);
    const periods = get(periodsState);
    const { items } = get(productsState);
    const mappedItems = items.map(
      ({
        name,
        categoryId,
        brandingId,
        brandId,
        marketIds,
        periodIds,
        sales,
        lastYearSales,
        firstWeekSales,
        lastWeekSales,
        growth,
        normalizedUpc,
        marketShare,
      }) => {
        const category = map.safeGet(categories, categoryId);
        const namedCategoriesPath = getNamedPath(category, 'topDown');
        const branding = brandings.get(brandingId) ?? map.safeGet(brandings, brandId);
        const namedBrandingPath = getNamedPath(branding);

        return {
          'Name': name,
          'UPC': normalizedUpc,
          ...namedCategoriesPath, // Should be { 'Level 1': string, 'Level 2': string, 'Level 3': string }
          'Market': marketIds.map(id => map.safeGet(markets, id)),
          'Time': periodIds.map(id => map.safeGet(periods, id)),
          ...namedBrandingPath, // Should be { 'Manufacturer': string, 'Brand': string, 'Sub Brand': string }
          'Total Sales': number.shortFractionCurrency(sales),
          'Last Year Sales': number.shortFractionCurrency(lastYearSales),
          'Market Share': number.percent(marketShare),
          'First Week Sales': number.shortFractionCurrency(firstWeekSales),
          'Last Week Sales': number.shortFractionCurrency(lastWeekSales),
          'Growth - In Period':
            growth.inPeriod == null ? missingValue : number.percent(growth.inPeriod),
          'Growth - Year Over Year':
            growth.lastYear == null ? missingValue : number.percent(growth.lastYear),
        };
      }
    );

    return mappedItems;
  },
});
