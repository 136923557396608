// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debouncer<T extends (...args: any[]) => void>(
  func: T,
  debounce: number | undefined
): (...args: Parameters<T>) => void {
  let timeoutId: number;

  return (...args: Parameters<T>) => {
    clearTimeout(timeoutId);
    timeoutId = window.setTimeout(func, debounce, ...args);
  };
}
