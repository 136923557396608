import React, { type PropsWithChildren, type ComponentType } from 'react';

// Step 1: Define the HOC function that takes a provider component and its props
export function providerToHoc(ProviderComponent: ComponentType<PropsWithChildren>) {
  // Step 2: Return a function that takes a component and returns the final HOC
  return function getHoc<ComponentProps extends object>(Component: ComponentType<ComponentProps>) {
    // Step 3: Define the final HOC
    return function WithProvider(props: ComponentProps) {
      return (
        <ProviderComponent>
          <Component {...props} />
        </ProviderComponent>
      );
    };
  };
}

// Step 1: Define the HOC function that takes a provider component and its props
export function providerWithPropsToHoc<ProviderProps>(
  ProviderComponent: ComponentType<ProviderProps>
) {
  // Step 2: Return a function that takes a component and returns the final HOC
  return function getHoc<ComponentProps extends object>(
    Component: ComponentType<ComponentProps>,
    providerProps: ProviderProps
  ) {
    // Step 3: Define the final HOC
    // eslint-disable-next-line react/no-multi-comp
    return function WithProvider(props: ComponentProps) {
      return (
        <ProviderComponent {...providerProps}>
          <Component {...props} />
        </ProviderComponent>
      );
    };
  };
}
