export const toggleSet = <T>(
  source: Set<T>,
  item: T,
  mutable = false,
  includes = source.has(item)
): Set<T> => {
  const target = mutable ? source : new Set(source);

  if (includes) {
    target.delete(item);
  } else {
    target.add(item);
  }

  return target;
};

export const getFirstFromSet = <T>(set: Set<T>): T => set.values().next().value;

export function addMany<S, T>(set: Set<T>, iterable?: Iterable<S>, mapper?: (item: S) => T): void;

export function addMany<T>(set: Set<T>, iterable?: Iterable<T>): void;

export function addMany<T, S>(set: Set<T>, iterable?: Iterable<T | S>, mapper?: (item: S) => T) {
  if (iterable) {
    if (mapper) {
      for (const item of iterable as Iterable<S>) {
        const mappedItem = mapper(item);

        set.add(mappedItem);
      }
    } else {
      for (const item of iterable as Iterable<T>) {
        set.add(item);
      }
    }
  }
}

export const changeOrder = <T>(source: Set<T>, item: T, index: number): Set<T> => {
  const target = new Set(source);

  target.delete(item);

  const targetAsArray = [...target];

  targetAsArray.splice(index, 0, item);

  return new Set(targetAsArray);
};

export const difference = <T>(source: Set<T>, other: Set<T>): Set<T> => {
  const target = new Set(source);

  for (const item of other) {
    if (other.has(item)) {
      target.delete(item);
    }
  }

  return target;
};
