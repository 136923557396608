import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { TagProgressiveCard } from './TagProgressiveCard';
import { Icon } from '../../../general/Icon';
import appStyles from '../../App.module.scss';
import styles from './TagSection.module.scss';
import { useVirtualizer } from '@tanstack/react-virtual';
import {
  tagDetailsExcludedProps,
  tagProps,
} from 'components/layout/comparisonPage/comparison-page.constants';
import type { AggregateTag } from 'models/AggregateTag';
import { Dropdown } from 'components/general/dropdown/Dropdown';
import { SelectDropdown } from 'components/general/select/SelectDropdown';
import { NavLink } from 'react-router-dom';
import { ComparableItemStats } from './ComparableItemStats';
import { formatters } from 'utils/formatters';
import { useTagPropGetter } from 'hooks/useTagValueGetter';
import { useExpandableTags } from 'components/layout/comparisonPage/diagramDetails/hooks/useExpandableTags';
import { useComparableItems } from 'hooks/useComparableItems';
import { string, array, isDefined } from '@harmonya/utils';
import { useTagLinks } from 'hooks/useTagLinks';
import { useRecoilValue } from 'recoil';
import { comparisonPageSortFieldState } from 'store/comparisonPage';
import { EmptyTags } from './EmptyTags';
import { Tooltip } from 'components/general/Tooltip';
import { settingsState } from 'store/settings';
import { analyticsState } from 'store/analytics';
import { join } from 'components/general/Divider';
import type {
  ComparableItem,
  TabName,
  TagProp,
} from 'components/layout/comparisonPage/comparison-page.types';

const previewMetricsWide: TagProp[] = ['totalSales', 'salesGrowth'];
const previewMetricsSmall: TagProp[] = ['totalSales'];
const getAllMetrics = () => tagProps.filter(prop => !tagDetailsExcludedProps.includes(prop));

const selectPreviewMetrics = (selectedMetric: TagProp, previewMetrics: TagProp[]) => {
  const combinedMetrics = [
    selectedMetric,
    ...previewMetrics.filter(metric => metric !== selectedMetric),
  ];

  combinedMetrics.length = previewMetrics.length;

  return combinedMetrics;
};

const selectDetailMetrics = (previewMetrics: TagProp[]) =>
  getAllMetrics().filter(prop => !previewMetrics.includes(prop));

const getTagCardHeight = (isCommonMode: boolean, isExpanded: boolean, itemsCount: number) => {
  if (isCommonMode) {
    return isExpanded
      ? +styles.extendedCommonTagCardHeight +
          itemsCount * +styles.extendedCommonTagCardItemDetailHeight
      : +styles.commonTagCardHeight;
  }

  return isExpanded ? +styles.expandedItemTagCardHeight : +styles.itemTagCardHeight;
};

const getIndexGap = (index: number, collectionSize: number, gap?: number) => {
  const isLast = index === collectionSize - 1;

  return isLast ? 0 : gap ?? +styles.tagCardGap;
};

type Props = {
  tab: TabName;
  titleGetter: (tagsCount: string) => ReactNode;
  comparableItem: ComparableItem;
  disabled?: boolean;
  isAnyDisabled?: boolean;
  resetComparableItem?: () => void;
  differentiationMagnitude: number;
  metricDetailsTitlesDisplayed?: boolean;
  scrollTo?: (left: number) => void;
};

export function TagSection(props: Props) {
  const {
    tab,
    titleGetter,
    comparableItem,
    disabled,
    isAnyDisabled,
    resetComparableItem,
    differentiationMagnitude,
    metricDetailsTitlesDisplayed,
    scrollTo,
  } = props;
  const settings = useRecoilValue(settingsState);
  const parentRef = useRef(null);
  const tagPropGetter = useTagPropGetter();
  const {
    hiddenTagIds,
    setHiddenTagIds,
    comparableItems: allItems,
  } = useComparableItems(differentiationMagnitude);
  const {
    getTagList,
    getFilteredTagList,
    changeItemExpand,
    changeAllSectionExpanded,
    changeSectionExpanded,
    isAllExpanded,
    isAllCollapsed,
    activeExpandedSetByItem,
    expandActionOptionsCommonSection,
    expandActionOptionsMajorSection,
    getSectionId,
  } = useExpandableTags(tab, differentiationMagnitude);
  const { getExploreUrl, getTrendUrl } = useTagLinks(comparableItem.itemIds);
  const sectionId = getSectionId(comparableItem.itemIds);
  const tagList = getTagList(comparableItem, tab);
  const aggregatedTags = getFilteredTagList(comparableItem, tab);
  const isExpandedSet = activeExpandedSetByItem?.[sectionId] ?? new Set();
  const isCommonMode = comparableItem.sets.length > 1;
  const isWideMode = isCommonMode || allItems.length === 1;
  const selectedMetric = useRecoilValue(comparisonPageSortFieldState);
  const previewMetrics = selectPreviewMetrics(
    selectedMetric,
    isWideMode ? previewMetricsWide : previewMetricsSmall
  );
  const expandedMetrics = selectDetailMetrics(previewMetrics);
  const expandActionOptions = isCommonMode
    ? expandActionOptionsCommonSection
    : expandActionOptionsMajorSection;
  const uniqueItems = allItems.filter(
    item => item.sets.length === 1 && comparableItem.sets.includes(item.sets[0])
  );
  const optionsMinWidth = 150;
  const componentRef = useRef<HTMLDivElement>(null);
  const analytics = useRecoilValue(analyticsState);
  const isTrendsEnabled = settings.trendsEnabled;

  const getCommonTagItemTags = (commonTag: AggregateTag) => {
    const itemTags = uniqueItems
      .map(item => {
        const tag = item.allTags.find(itemTag => itemTag.id === commonTag.id);

        return tag
          ? {
              id: item.name,
              title: item.name,
              values: tag.values,
            }
          : undefined;
      })
      .filter(isDefined);

    return itemTags;
  };

  const rowVirtualizer = useVirtualizer({
    overscan: 10,
    getScrollElement: () => parentRef.current,
    count: aggregatedTags.length,
    estimateSize: useCallback(
      index => {
        const aggregatedTag = aggregatedTags[index];
        const isExpanded = isExpandedSet?.has(aggregatedTag.id);
        const height = getTagCardHeight(
          isCommonMode,
          isExpanded,
          isCommonMode ? getCommonTagItemTags(aggregatedTag).length : 1
        );
        const gappedHeight = getIndexGap(index, aggregatedTags.length) + height;

        return gappedHeight;
      },
      [aggregatedTags, isExpandedSet]
    ),
  });
  const hideTag = useCallback(
    (tagId: number) => {
      const newHiddenTagIds = array.toggle(hiddenTagIds, tagId);

      setHiddenTagIds(newHiddenTagIds);
    },
    [hiddenTagIds]
  );

  const onExpandAction = (actionValue?: string) => {
    if (!actionValue || isExpandActionDisabled(actionValue)) {
      return;
    }

    switch (actionValue) {
      case 'expand_this_item':
        changeSectionExpanded(tab, sectionId, true);
        break;
      case 'collapse_this_item':
        changeSectionExpanded(tab, sectionId, false);
        break;
      case 'expand_all_items':
        changeAllSectionExpanded(tab, true);
        break;
      case 'collapse_all_items':
        changeAllSectionExpanded(tab, false);
        break;
      case 'reset_this_item':
        resetComparableItem?.();
        break;
      default:
        break;
    }
  };

  const isExpandActionDisabled = (optionValue: string) => {
    switch (optionValue) {
      case 'expand_this_item':
        return aggregatedTags.length === isExpandedSet.size;
      case 'collapse_this_item':
        return !isExpandedSet.size;
      case 'expand_all_items':
        return isAllExpanded || allItems.length === 1;
      case 'collapse_all_items':
        return isAllCollapsed || allItems.length === 1;
      default:
        break;
    }

    return false;
  };

  const expandActionValueGetter = (option: { label: string; value: string }) => {
    const isDisabled = isExpandActionDisabled(option.value);

    return isDisabled ? <span className={appStyles.disabled}>{option.label}</span> : option.label;
  };

  const getMetricTitle = (metric: TagProp) =>
    metric === 'marketShare' && settings.exploreMarketShareTitle
      ? settings.exploreMarketShareTitle
      : (formatters[metric].title as string);

  useEffect(() => {
    rowVirtualizer.measure();
  }, [isExpandedSet?.size, tab]);

  useEffect(() => {
    if (!disabled && isAnyDisabled && componentRef?.current?.offsetLeft) {
      scrollTo?.(componentRef.current.offsetLeft);
    }
  }, [disabled, isAnyDisabled]);

  const commonTagDetails = (commonTag: AggregateTag) => {
    const itemTags = getCommonTagItemTags(commonTag);

    return (
      <div className={classNames(styles.commonTagItemsTable, appStyles.verticalFlex)}>
        <div
          className={classNames(
            styles.commonTagItemsTableRow,
            styles.commonTagItemsTableHeaderRow,
            appStyles.horizontalFlex,
            appStyles.justifySpaceBetween,
            appStyles.alignCenter
          )}
        >
          <div>Brand</div>
          {getAllMetrics().map(prop => (
            <Tooltip
              key={prop}
              content={formatters[prop].title as string}
              className={classNames(
                appStyles.horizontalFlex,
                appStyles.gap1,
                appStyles.overflowHidden,
                styles.commonTagTableHeader
              )}
            >
              <Icon
                {...formatters[prop].icon}
                className={styles.commonTagTableHeaderIcon}
                weight='light'
              />
              {metricDetailsTitlesDisplayed && (
                <div className={appStyles.ellipsis}>{formatters[prop].title as string}</div>
              )}
            </Tooltip>
          ))}
        </div>
        {itemTags.map(tag => (
          <div
            className={classNames(
              styles.commonTagItemsTableRow,
              appStyles.horizontalFlex,
              appStyles.justifySpaceBetween
            )}
            key={`${commonTag.id}|${tag.id}|`}
          >
            <div className={appStyles.overflowHidden}>
              <span className={appStyles.ellipsis}>{tag.title}</span>
            </div>
            {getAllMetrics().map(prop => (
              <div key={prop}>{formatters[prop].valueGetter(tagPropGetter(tag, prop))}</div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const tagsCount =
    tagList.length === aggregatedTags.length
      ? aggregatedTags.length
      : `${aggregatedTags.length}/${tagList.length}`;

  const trackTagCardNavigation = (page: string) => {
    analytics.track('Tag card navigation', { name: 'Tag card', value: page, type: 'tagCard' });
  };

  return (
    <div
      ref={componentRef}
      className={classNames(
        appStyles.verticalFlex,
        appStyles.gap2,
        styles.tagSection,
        disabled === true && appStyles.disabled
      )}
    >
      <div
        className={classNames(
          appStyles.horizontalFlex,
          appStyles.justifySpaceBetween,
          appStyles.alignCenter
        )}
      >
        <div className={classNames(appStyles.horizontalFlex, styles.title)}>
          {titleGetter(tagsCount.toString())}
        </div>
        <Dropdown
          minWidth={optionsMinWidth}
          direction='down'
          align='right'
          buttonContent={
            <div className={classNames(appStyles.horizontalFlex, appStyles.justifyEnd)}>
              <div className={appStyles.button}>
                <Icon
                  className={styles.optionsIcon}
                  weight='solid'
                  name='ellipsis-stroke-vertical'
                />
              </div>
            </div>
          }
        >
          <SelectDropdown
            onChange={onExpandAction}
            options={expandActionOptions}
            value={undefined}
            displayValueGetter={expandActionValueGetter}
          />
        </Dropdown>
      </div>
      <ComparableItemStats itemIds={comparableItem.trimmedItemIds} />
      {aggregatedTags.length ? (
        <div ref={parentRef} className={styles.tagsContainer}>
          <div
            className={appStyles.positionRelative}
            style={{ height: rowVirtualizer.getTotalSize() }}
          >
            {rowVirtualizer.getVirtualItems().map(({ size, index, start }) => {
              const computedGap = getIndexGap(index, aggregatedTags.length);
              const style = {
                height: size - computedGap,
                marginBottom: computedGap,
                transform: `translateY(${string.pixelize(start)})`,
              };
              const aggregatedTag = aggregatedTags[index];
              const explorePageHref = getExploreUrl(aggregatedTag.id);
              const trendsPageHref = getTrendUrl(aggregatedTag.id);

              return (
                <TagProgressiveCard
                  style={style}
                  key={`${comparableItem.sets.join('-')}|${aggregatedTag.id}|${index}`}
                  tag={aggregatedTag}
                  hideTag={hideTag}
                  isExpanded={isExpandedSet?.has(aggregatedTag.id)}
                  onIconClick={() => changeItemExpand(tab, sectionId, aggregatedTag.id)}
                  details={
                    <div className={classNames(styles.details, appStyles.horizontalFlex)}>
                      {join(
                        previewMetrics.map(metric => (
                          <Tooltip key={metric} content={formatters[metric].title as string}>
                            <span>
                              <Icon
                                {...formatters[metric].icon}
                                className={styles.detailsIcon}
                                weight='light'
                              />
                              {formatters[metric].valueGetter(tagPropGetter(aggregatedTag, metric))}
                            </span>
                          </Tooltip>
                        ))
                      )}
                    </div>
                  }
                >
                  <div className={appStyles.verticalFlex}>
                    {isCommonMode ? (
                      commonTagDetails(aggregatedTag)
                    ) : (
                      <div className={classNames(styles.expandTagDetails, appStyles.gap2)}>
                        {expandedMetrics.map(metric => (
                          <React.Fragment key={metric}>
                            <Tooltip content={getMetricTitle(metric)}>
                              <div
                                className={classNames(
                                  appStyles.gap1,
                                  appStyles.horizontalFlex,
                                  appStyles.justifySpaceBetween,
                                  appStyles.alignCenter
                                )}
                              >
                                <Icon
                                  {...formatters[metric].icon}
                                  className={styles.tagInfoIcon}
                                  weight='light'
                                />
                                {formatters[metric].valueGetter(
                                  tagPropGetter(aggregatedTag, metric)
                                )}
                              </div>
                            </Tooltip>
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                    <div className={styles.dividerHorizontal} />
                    <div
                      className={classNames(
                        appStyles.horizontalFlex,
                        isCommonMode ? appStyles.justifyEnd : appStyles.justifyCenter
                      )}
                    >
                      <div
                        className={classNames(
                          styles.navButtonContainer,
                          !isCommonMode && appStyles.fullWidth
                        )}
                      >
                        <NavLink
                          to={explorePageHref}
                          onClick={() => trackTagCardNavigation('explore')}
                        >
                          <button
                            className={classNames(
                              styles.navButton,
                              appStyles.horizontalFlex,
                              appStyles.buttonSecondary
                            )}
                          >
                            <Icon name='magnifying-glass-chart' weight='light' />
                            Explore
                          </button>
                        </NavLink>
                      </div>
                      {isTrendsEnabled && (
                        <div
                          className={classNames(
                            styles.navButtonContainer,
                            !isCommonMode && appStyles.fullWidth
                          )}
                        >
                          <NavLink
                            to={trendsPageHref}
                            onClick={() => trackTagCardNavigation('trends')}
                          >
                            <button
                              className={classNames(
                                styles.navButton,
                                appStyles.horizontalFlex,
                                appStyles.buttonSecondary
                              )}
                            >
                              <Icon name='chart-line-up' weight='light' />
                              Trend
                            </button>
                          </NavLink>
                        </div>
                      )}
                    </div>
                  </div>
                </TagProgressiveCard>
              );
            })}
          </div>
        </div>
      ) : (
        <EmptyTags text={['No matching tags according', 'to your filters']} />
      )}
    </div>
  );
}
