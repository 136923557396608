import type { ComponentProps } from 'react';
import React from 'react';
import type { Options as HighchartsOptions, SeriesBarOptions } from 'highcharts';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { styleVariables } from '../../../utils/styleVariables';
import { getSeriesColorProps } from './chart.utils';
import type { SubSectionChart } from './SubSectionChart';

type Props = Parameters<ComponentProps<typeof SubSectionChart>['children']>[0];

export function SubSectionBarChart(props: Props) {
  const { rawDataItems, activatedIds, hoveredIds, valueFormatFunction, tooltipGetter } = props;

  if (rawDataItems) {
    /**
     * @see https://github.com/highcharts/highcharts/issues/5853 Without this type gymnastics the chart doesn't
     * update the correct orientation when you go back to 'spline' from 'bar'.
     */
    const series = rawDataItems.map(item => {
      const colorProps = getSeriesColorProps(item);

      return {
        visible: activatedIds.has(item.id),
        opacity: hoveredIds.size && !hoveredIds.has(item.id) ? 0.4 : 1,
        name: item.id,
        data: [item.aggregatedValue],
        pointPlacement: 0.02,
        minPointLength: 10,
        ...colorProps,
        shadow: false,
      };
    });
    const options: HighchartsOptions = {
      accessibility: { enabled: false },
      chart: {
        type: 'bar',
        backgroundColor: 'transparent',
      },
      credits: { enabled: false },
      title: undefined,
      series: series as SeriesBarOptions[],
      xAxis: {
        lineColor: 'var(--theme-secondary-color-regular)',
        type: 'category',
        labels: { enabled: false },
      },
      yAxis: {
        title: { text: null },
        lineColor: 'var(--theme-secondary-color-regular)',
        lineWidth: 1,
        tickAmount: 5,
        gridLineColor: undefined,
        plotLines: [
          {
            value: 0,
            color: 'var(--theme-secondary-color-regular)',
          },
        ],
        labels: {
          formatter: ({ value }) => valueFormatFunction(value),
          style: {
            color: 'var(--theme-secondary-color-dark)',
            fontSize: '1rem',
            fontFamily: styleVariables.titleFontFamiliy,
          },
        },
      },
      legend: { enabled: false },
      tooltip: {
        outside: true,
        enabled: true,
        shared: true,
        shadow: false,
        useHTML: true,
        backgroundColor: undefined,
        formatter: tooltipGetter ?? (() => false),
      },
      plotOptions: {
        bar: {
          borderRadius: styleVariables.borderRadius,
          maxPointWidth: 21,
          states: { hover: { enabled: false } },
          groupPadding: 0.03,
          dataLabels: {
            enabled: true,
            style: {
              textDecoration: 'none',
              textOutline: 'none',
              color: 'var(--theme-secondary-color-dark)',
              fontSize: '1rem',
              fontFamily: styleVariables.titleFontFamiliy,
              position: 'right',
            },
            formatter() {
              const {
                point: { y = 0 },
              } = this;
              const value = valueFormatFunction(y);

              return value;
            },
          },
        },
      },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }

  return null;
}
