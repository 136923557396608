import { selector } from 'recoil';
import { fetchGet } from '../utils/fetch';
import { authState, customerIdState } from './auth';
import { getTreeMap, selectOptionsToTreeMap } from 'utils/node';

import { iterator } from '@harmonya/utils';
import type { Branding } from '@harmonya/models';

export const rawBrandingListState = selector({
  key: 'rawBrandingList',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: async ({ get }) => {
    const customerId = get(customerIdState);
    const { user } = get(authState);
    const rawBrandings = await fetchGet<Branding[]>('/api/branding', customerId, user.email);

    return rawBrandings;
  },
});

export const rawBrandingState = selector({
  key: 'rawBranding',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const rawBrandings = get(rawBrandingListState);
    const brandingsMap = getTreeMap(rawBrandings);

    return brandingsMap;
  },
});

export const sortedFlattenTreeBrandingState = selector({
  key: 'treeBranding',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const rawBranding = get(rawBrandingListState);
    const rawTreeMap = getTreeMap(rawBranding);
    const treeMap = selectOptionsToTreeMap(
      rawTreeMap,
      branding => branding.parent,
      branding => branding.children
    );

    return treeMap;
  },
});

export const brandingsState = selector({
  key: 'branding',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const rawBrandings = get(rawBrandingListState);
    const brandingItems = rawBrandings.map(({ ...branding }) => ({ ...branding }));
    const brandingMap = new Map([...brandingItems].map(item => [item.id, item]));

    return brandingMap;
  },
});

export const brandingLevelsState = selector({
  key: 'brandingLevels',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const brandings = get(rawBrandingListState);
    const levels = new Map<number, string>();

    for (const branding of brandings.values()) {
      const { level } = branding;
      const { name, id } = level ?? {};

      if (id != null && !levels.has(id)) {
        levels.set(id, name ?? '');
      }
    }

    const levelOptions = iterator.definedMap(levels.entries(), ([id, name]) => ({
      value: id,
      displayValue: name,
    }));

    return levelOptions;
  },
});
