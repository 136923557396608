import { useRecoilValue } from 'recoil';
import { settingsState } from 'store/settings';
import { formatters, getTitle } from 'utils/formatters';
import type { AggregateTag } from 'models/AggregateTag';
import { useTagPropGetter } from 'hooks/useTagValueGetter';
import { iterator, number } from '@harmonya/utils';

export const defaultTagValueKeys: (keyof AggregateTag['values'])[] = [
  'productsCount',
  'brandSignificance',
  'salesGrowth',
  'totalSales',
  'brandsCount',
  'marketShare',
  'customerMarketShare',
  'reviewProductsRatio',
  'listingProductsRatio',
];

export function useGetTagValues(
  tagValueKeys: (keyof AggregateTag['values'])[] = defaultTagValueKeys,
  numericTotalSalesDisplayed = false
) {
  const settings = useRecoilValue(settingsState);
  const { exploreMarketShareTitle, exploreCustomerMarketShareTitle } = settings;
  const tagPropGetter = useTagPropGetter();

  const getTagValues = (tag: AggregateTag) =>
    iterator.definedMap(tagValueKeys, key => {
      const computedValue = tagPropGetter(tag, key);
      const isEmptyBrandSignificane = key === 'brandSignificance' && computedValue == null;

      if (!isEmptyBrandSignificane) {
        const { valueGetter, icon } = formatters[key];
        const value =
          key === 'totalSales' && numericTotalSalesDisplayed
            ? number.shortFractionCurrency(computedValue)
            : valueGetter(computedValue);
        let title = getTitle(key, settings.customerName);

        if (key === 'marketShare') {
          title = exploreMarketShareTitle ?? 'Query Market Share';
        } else if (key === 'customerMarketShare' && exploreCustomerMarketShareTitle) {
          title = exploreCustomerMarketShareTitle;
        }

        return { key, title, value, icon };
      }
    });

  return getTagValues;
}
