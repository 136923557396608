import classNames from 'classnames';
import React from 'react';
import appStyles from '../layout/App.module.scss';
import styles from './RadioButtonGroup.module.scss';
import type { tooltipTexts } from '../../tooltipTexts';
import { withTooltip } from './Tooltip';
import { string } from '@harmonya/utils';

interface Option<T, TooltipPage extends keyof typeof tooltipTexts> {
  value: T;
  name: string;
  tooltip?: {
    page: TooltipPage;
    key: keyof (typeof tooltipTexts)[TooltipPage];
  };
}

type Props<T, TooltipPage extends keyof typeof tooltipTexts> = {
  value: T;
  options: Option<T, TooltipPage>[];
  onChange: (newValue: T) => void;
};

export function RadioButtonGroup<T extends string, TooltipPage extends keyof typeof tooltipTexts>(
  props: Props<T, TooltipPage>
) {
  const { value: selectedValue, options, onChange } = props;
  const formFieldKey = 'key';

  const change = (event: React.FormEvent<HTMLFormElement>) => {
    const form = new FormData(event.currentTarget);
    const newValue = form.get(formFieldKey);

    if (string.isString(newValue)) {
      onChange(newValue as T);
    }
  };

  return (
    <form className={classNames(appStyles.horizontalFlex, styles.label)} onChange={change}>
      {options.map(({ value, name, tooltip }, i) => {
        const element = (
          <label
            key={i}
            className={classNames(
              appStyles.horizontalFlex,
              appStyles.alignCenter,
              appStyles.gap1,
              styles.label
            )}
          >
            <input
              type='radio'
              name={formFieldKey}
              value={value}
              defaultChecked={value === selectedValue}
            />
            {name}
          </label>
        );

        return tooltip ? withTooltip(tooltip.page, tooltip.key, element, 'bottom') : element;
      })}
    </form>
  );
}
