import { selector } from 'recoil';
import { fetchGet } from '../utils/fetch';
import type { SalesSeriesItem } from '../models/SalesSeriesItem';
import { authState, customerIdState } from './auth';

export const salesHiddenIdsState = selector({
  key: 'salesHiddenIds',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: async ({ get }) => {
    const customerId = get(customerIdState);
    const { user } = get(authState);
    const hiddenIds = await fetchGet<SalesSeriesItem['id'][]>(
      '/api/sales/hiddenIds',
      customerId,
      user.email
    );
    const hiddenIdsSet = new Set(hiddenIds);

    return hiddenIdsSet;
  },
});
