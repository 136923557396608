import { useState, type Dispatch, type SetStateAction } from 'react';
import { isDeepEqual } from 'utils/isDeepEqual';

export function useDeepCompareState<T>(initialValue: T): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState(initialValue);

  const setValueIfChanged: Dispatch<SetStateAction<T>> = newValue => {
    setValue(prevValue => {
      const computedNewValue = newValue instanceof Function ? newValue(prevValue) : newValue;

      return isDeepEqual(prevValue, computedNewValue) ? prevValue : computedNewValue;
    });
  };

  return [value, setValueIfChanged];
}
