import { selector } from 'recoil';
import { fetchGet } from '../utils/fetch';
import { authState, customerIdState } from './auth';
import type { Period } from '@harmonya/models';

export const rawPeriodsState = selector({
  key: 'periods',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: async ({ get }) => {
    const customerId = get(customerIdState);
    const { user } = get(authState);
    const periods = await fetchGet<Period[]>('/api/periods', customerId, user.email);
    const periodsMap = new Map(periods.map(period => [period.id, period]));
    return periodsMap;
  },
});

export const periodsState = selector({
  key: 'periodsOptions',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const periods = get(rawPeriodsState);
    const periodsMap = new Map([...periods.values()].map(({ id, name }) => [id, name]));
    return periodsMap;
  },
});
