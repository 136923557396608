import type { AggregateTag } from 'models/AggregateTag';
import { historyAtom } from '../utils/historyAtom';
import {
  comparisonPageCategoryIdsDefaultState,
  comparisonPageMarketIdsDefaultState,
  comparisonPagePeriodIdsDefaultState,
} from './settings';
import type { GetRecoilValue } from 'recoil';
import { atom, selector } from 'recoil';
import { fetchPost } from 'utils/fetch';
import { authState, customerIdState } from './auth';
import type { IsExpendedMapper } from 'components/layout/comparisonPage/diagramDetails/hooks/useExpandableTags';
import { brandingsState } from './branding';

import { array, iterator, object } from '@harmonya/utils';
import type { Products } from '@harmonya/models';

export const comparisonExpandedSetByTabAndItem = atom<IsExpendedMapper>({
  key: 'comparisonExpandedSetByItem',
  default: {},
});

// store each brands tag list in seperate selector
const comparisonMetadataTemplate = (index: number) =>
  selector({
    key: `comparisonMetadata${index}`,
    cachePolicy_UNSTABLE: { eviction: 'most-recent' },
    get: async ({ get }) => {
      const idState = comparisonPageComperableItemIdStateByIndex[index];
      const id = get(idState);
      const keyFilter = 'brandingIds';
      const customerId = get(customerIdState);
      const { user } = get(authState);
      const filters = getFilters(get);
      const body = {
        ...filters,
        [keyFilter]: [id],
      };
      const products = await fetchPost<Products>('/api/products', customerId, user.email, body);

      return products.metadata;
    },
  });
const brandMetadata0State = comparisonMetadataTemplate(0);
const brandMetadata1State = comparisonMetadataTemplate(1);
const brandMetadata2State = comparisonMetadataTemplate(2);
const brandMetadataStates = [brandMetadata0State, brandMetadata1State, brandMetadata2State];

export const comparisonStatsState = selector({
  key: 'comparisonStats',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: async ({ get }) => {
    const keyIds = get(comparisonPageComperableItemIdsState);

    if (!keyIds.length) {
      return undefined;
    }

    const itemIds = get(comparisonPageComperableItemIdsState);
    const promises = iterator.definedMap(itemIds, (id, i) => {
      const brandMetadataState = brandMetadataStates[i];
      const metadata = get(brandMetadataState);

      return {
        brandId: id,
        metadata,
      };
    });
    const majorBrandsOverview = await Promise.all(promises);
    const overviewByBrand = new Map(
      majorBrandsOverview.map(overview => [overview.brandId, overview.metadata])
    );

    return overviewByBrand;
  },
});

export const comparisonPageCategoryIdsState = historyAtom<number[]>(
  'categories',
  'comparison',
  { key: 'comparisonPageCategoryIds', default: comparisonPageCategoryIdsDefaultState },
  { isArray: true, parser: 'number' }
);

export const comparisonPageMarketIdsState = historyAtom<number[]>(
  'markets',
  'comparison',
  { key: 'comparisonPageMarketIds', default: comparisonPageMarketIdsDefaultState },
  { isArray: true, parser: 'number' }
);

export const comparisonPagePeriodIdsState = historyAtom<number[]>(
  'periods',
  'comparison',
  { key: 'comparisonPagePeriodIds', default: comparisonPagePeriodIdsDefaultState },
  { isArray: true, parser: 'number' }
);

export const comparisonPageSortFieldState = historyAtom<
  keyof Omit<AggregateTag['values'], 'brandsCount'>
>('sortField', 'comparison', { key: 'comparisonPageSortField', default: 'productsCount' });

export const comparisonPageComperableItemIdsState = historyAtom<number[]>(
  'items',
  'comparison',
  { key: 'comparisonPageComperableItemIds', default: [] },
  { isArray: true, parser: 'number' }
);

// to improve change detection, keep the array of brandsPageComperableItemIdsState as array of seperated selectors
const comparisonPageItemTemplate = (index: number) =>
  selector({
    key: `comparisonPageId${index}`,
    cachePolicy_UNSTABLE: { eviction: 'most-recent' },
    get: ({ get }) => get(comparisonPageComperableItemIdsState)?.[index],
  });
const comparisonPageComperableItemId0State = comparisonPageItemTemplate(0);
const comparisonPageComperableItemId1State = comparisonPageItemTemplate(1);
const comparisonPageComperableItemId2State = comparisonPageItemTemplate(2);

export const comparisonPageComperableItemIdStateByIndex = [
  comparisonPageComperableItemId0State,
  comparisonPageComperableItemId1State,
  comparisonPageComperableItemId2State,
];

export const getFilters = (get: GetRecoilValue) => {
  const categoryIds = get(comparisonPageCategoryIdsState);
  const marketIds = get(comparisonPageMarketIdsState);
  const periodIds = get(comparisonPagePeriodIdsState);
  const filters = { categoryIds, marketIds, periodIds };
  const definedFilterKeys = object.definedKeys(filters);

  return definedFilterKeys;
};

export const getFiltersWithComparableItems = (get: GetRecoilValue) => {
  const pageFilters = getFilters(get);
  const comparableItemIds = get(comparisonPageComperableItemIdsState);
  const filters = { ...pageFilters, brandingIds: comparableItemIds };
  const definedFilterKeys = object.definedKeys(filters);

  return definedFilterKeys;
};

export const getComparableItemIdsByLevel = (get: GetRecoilValue, itemIds: number[]) => {
  const items = get(brandingsState);
  const itemIdsByLevel = itemIds.reduce((acc: Record<string, number[]>, itemId) => {
    const item = items.get(itemId);
    const levelName = item?.level?.name;

    if (levelName) {
      const itemIdWithoutLevelId = Math.floor(itemId);

      array.ensuredPush(acc, levelName, itemIdWithoutLevelId);
    }

    return acc;
  }, {});

  return itemIdsByLevel;
};
