import { useRecoilValue } from 'recoil';
import { growthPointState } from 'store/growthPoint';
import type { RangeValue } from 'components/general/Slider';
import { useSearchParam } from 'hooks/useSearchParam';
import type { FormmaterKey } from 'utils/formatters';
import { formatters } from 'utils/formatters';
import type { Icon } from 'components/general/Icon';
import { isDeepEqual } from 'utils/isDeepEqual';
import type { ComponentProps } from 'react';
import type { Growth } from '@harmonya/models';

type ValueGetter<T> = (item: T) => number | null;

export interface Config<P extends FormmaterKey> {
  title: string;
  prop: P;
  tooltipText?: string;
  isDisabled?: boolean;
  isSingle?: boolean;
  icon?: ComponentProps<typeof Icon>;
  precision?: number;
  onTrackEvent?: (range: RangeValue) => void;
}

const getRange = <T>(items: T[], valueGetter: ValueGetter<T>) => {
  let min = Infinity;
  let max = -Infinity;

  items.forEach(item => {
    const value = valueGetter(item);

    if (value != null) {
      min = Math.min(min, value);
      max = Math.max(max, value);
    }
  });

  if (isFinite(min) && isFinite(max)) {
    return [min, max] as const;
  }
};

export const getSliderFilterSearchParamName = (prop: string) => `${prop}RangeFilter`;

export function useSliderFilters<T, P extends FormmaterKey>(
  items: T[],
  getValueGetter: (prop: P, growthPoint: keyof Growth) => ValueGetter<T>,
  configs: Config<P>[]
) {
  const growthPoint = useRecoilValue(growthPointState);
  const sliderFilters = configs.map(({ prop, isDisabled, precision, ...props }) => {
    const valueGetter = getValueGetter(prop, growthPoint);
    const rawDefaultValue = !isDisabled && getRange(items, valueGetter);
    const defaultValue = rawDefaultValue || [0, 1];
    const computedIsDisabled = !rawDefaultValue;
    const [minValue, maxValue] = defaultValue;
    const [value, setValue] = useSearchParam<RangeValue>(getSliderFilterSearchParamName(prop), {
      defaultValue: [undefined, undefined],
      parser: 'number',
    });
    const [startValue, endValue] = value;
    const ensuredValue: RangeValue = computedIsDisabled
      ? [0, 1]
      : [startValue ?? minValue, endValue ?? maxValue];
    const formatter = formatters[prop].valueGetter;
    const formattedValue = ensuredValue.map(formatter);
    const isPercent = formattedValue.some(valuePart => valuePart?.toString().match(/[%X]/));
    const step = precision ?? isPercent ? 0.1 : 1;
    const [startFormattedValue, endFormattedValue] = formattedValue;
    const textValue = `${startFormattedValue} - ${endFormattedValue}`;
    const isDefault = startValue == null && endValue == null;
    const reset = () => setValue([undefined, undefined]);

    const isFilteredItem =
      isDefault || computedIsDisabled
        ? undefined
        : (item: T) => {
            const checkedValue = valueGetter(item);
            const isFiltered =
              checkedValue != null &&
              checkedValue >= (startValue ?? -Infinity) &&
              checkedValue <= (endValue ?? Infinity);

            return isFiltered;
          };

    const handleChange = (newValue?: RangeValue) => {
      if (!value || !newValue || !isDeepEqual(value, newValue)) {
        setValue(newValue);
      }
    };

    return {
      ...props,
      isFilteredItem,
      reset,
      textValue,
      isDisabled: computedIsDisabled,
      value: ensuredValue,
      formatter,
      handleChange,
      minValue,
      maxValue,
      isPercent,
      step,
    };
  });

  return {
    sliderFilters,
    reset: () => sliderFilters.forEach(({ reset }) => reset()),
  };
}
