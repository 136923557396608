import React from 'react';
import styles from './DataTableIndexCell.module.scss';

type Props = {
  index?: number;
};

export function DataTableIndexCell(props: Props) {
  return <div className={styles.indexCell}>{props.index}</div>;
}
