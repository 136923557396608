import classNames from 'classnames';
import type { Workspace } from './NavWorkspace.utils';
import React from 'react';
import { Icon } from 'components/general/Icon';
import styles from './WorkspaceSwitchItem.module.scss';
import appStyles from '../../layout/App.module.scss';

type Props = {
  activeWorkspace: Workspace;
  buttonClassName?: string;
};

export function WorkspaceSwitchItem(props: Props) {
  const { activeWorkspace, buttonClassName } = props;
  const itemClasses = classNames(
    styles.active,
    styles.button,
    activeWorkspace.colorClass,
    buttonClassName
  );

  switch (activeWorkspace.type) {
    case 'insight':
      return (
        <div className={classNames(styles.symbol, styles.circleIcon, itemClasses)}>
          {activeWorkspace.shortName}
        </div>
      );
    case 'attribution':
      return (
        <div className={classNames(styles.switcher, itemClasses)}>
          <Icon name='arrows-rotate' weight='light' className={appStyles.largeFont} />
        </div>
      );
  }
}
