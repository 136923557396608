import type { ToastMessage } from 'components/general/ToastMessage';
import { atom } from 'recoil';

export const toastMessageState = atom<(ToastMessage & { timestamp?: Date }) | undefined>({
  key: 'toastMessage',
  default: undefined,
  effects: [
    ({ setSelf, onSet }) => {
      onSet((newValue, oldValue, isReset) => {
        if (!isReset && newValue) {
          setSelf({ ...newValue, timestamp: new Date() });
        }
      });
    },
  ],
});
