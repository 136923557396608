import { useInstanceId } from 'hooks/useInstanceId';
import type { PropsWithChildren } from 'react';
import React, { createContext, useMemo } from 'react';
import type { RecoilState, RecoilValueReadOnly } from 'recoil';
import { atom, selectorFamily } from 'recoil';

type ColumnId = number;
type RowIndex = number;

export type CellIdentifier = {
  columnId: ColumnId;
  rowIndex: RowIndex;
};

type MarkedCellActions = {
  replaceMarkedCellState: (param: CellIdentifier) => RecoilState<boolean>;
  isTheOnlyOneMarkedState: (param: CellIdentifier) => RecoilValueReadOnly<boolean>;
  markedCellsState: RecoilState<Map<ColumnId, Set<RowIndex>>>;
};

export const MarkedCellContext = createContext<undefined | MarkedCellActions>(undefined);

export function MarkedCellProvider(props: PropsWithChildren) {
  const instanceId = useInstanceId();
  const { children } = props;

  const actions = useMemo(() => {
    const markedCellsState: MarkedCellActions['markedCellsState'] = atom({
      key: `markedCells${instanceId}`,
      default: new Map(),
    });

    const replaceMarkedCellState: MarkedCellActions['replaceMarkedCellState'] = selectorFamily({
      key: `markCell${instanceId}`,
      get:
        ({ columnId, rowIndex }) =>
        ({ get }) => {
          const markedCells = get(markedCellsState);
          const isMarked = !!markedCells.get(columnId)?.has(rowIndex);

          return isMarked;
        },
      set:
        ({ columnId, rowIndex }) =>
        ({ set }, newValue) => {
          set(
            markedCellsState,
            newValue === true ? new Map([[columnId, new Set([rowIndex])]]) : new Map()
          );
        },
    });

    const isTheOnlyOneMarkedState: MarkedCellActions['isTheOnlyOneMarkedState'] = selectorFamily({
      key: `isTheOnlyOneMarked${instanceId}`,
      get:
        cellIdentifier =>
        ({ get }) => {
          const isMarked = get(replaceMarkedCellState(cellIdentifier));

          if (isMarked) {
            const markedCells = get(markedCellsState);

            for (const [columnId, rowIndices] of markedCells) {
              if (
                (columnId !== cellIdentifier.columnId && rowIndices.size) ||
                (columnId === cellIdentifier.columnId && rowIndices.size > 1)
              ) {
                return false;
              }
            }
          }

          return isMarked;
        },
    });

    return {
      replaceMarkedCellState,
      isTheOnlyOneMarkedState,
      markedCellsState,
    };
  }, []);

  return <MarkedCellContext.Provider value={actions}>{children}</MarkedCellContext.Provider>;
}
