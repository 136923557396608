import type { Option, Level } from 'components/general/select/types';
import type { RecoilValue } from 'recoil';
import { useRecoilValue } from 'recoil';
import type { FilterDependenciesClient } from 'store/filterDependencies';
import { filterDependenciesState } from 'store/filterDependencies';
import { object } from '@harmonya/utils';
import type { Branding } from '@harmonya/models';

function getEnabledBrandingIds(
  categoryIds: number[],
  filterDependencies: FilterDependenciesClient
) {
  const includedBrandingIds = categoryIds.flatMap(categoryId => {
    const brandings = filterDependencies.brandingIds?.categoryIds[categoryId];

    return [...(brandings ?? [])];
  });

  return new Set(includedBrandingIds);
}

export function useIsBrandingOptionDisabled(
  categoryIdsState: RecoilValue<number[]>,
  enabledLevels?: Set<Level>
) {
  const categoryIds = useRecoilValue(categoryIdsState);
  const filterDependencies = useRecoilValue(filterDependenciesState);
  const enabledBrandingIds = getEnabledBrandingIds(categoryIds, filterDependencies);

  if (!categoryIds.length || !enabledBrandingIds.size) {
    return object.alwaysFalse;
  }

  const isEnabledByLevel = enabledLevels?.size
    ? (node?: { level: Level }) => node?.level != null && !enabledLevels.has(node.level)
    : object.alwaysFalse;

  return function (option: Option<Branding['id']>) {
    const { node, value } = option;
    const isDisabled = isEnabledByLevel(node) || !enabledBrandingIds.has(value);

    return isDisabled;
  };
}
