import { selector } from 'recoil';
import { fetchGet } from '../utils/fetch';
import type { TagType } from '../models/TagType';
import type { TagType as TagTypeModel } from '@harmonya/models';
import { authState, customerIdState } from './auth';
import { colorize } from '../utils/colorizedGroup';

export const tagTypesState = selector({
  key: 'tagTypes',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: async ({ get }) => {
    const customerId = get(customerIdState);
    const { user } = get(authState);
    const types = await fetchGet<TagTypeModel[]>('/api/tagTypes', customerId, user.email);
    const colorizedTypes: TagType[] = colorize(types);
    const colorizedTypesEntries = colorizedTypes.map<[number, (typeof colorizedTypes)[number]]>(
      type => [type.id, type]
    );
    const typesMap = new Map(colorizedTypesEntries);

    return typesMap;
  },
});

export const tagTypeNamesState = selector({
  key: 'tagTypeNames',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const types = get(tagTypesState);
    const mappedTypes: [number, string][] = [...types.values()].map(({ id, name }) => [id, name]);
    const typesMap = new Map(mappedTypes);

    return typesMap;
  },
});
