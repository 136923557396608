import './instrument';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './components/layout/App';
import type { Auth0ProviderOptions } from '@auth0/auth0-react';
import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react';
import { Loader } from './components/general/Loader';
import './index.scss';
import { env } from '../env';
import { history } from 'utils/history';

const authProviderConfig: Auth0ProviderOptions = {
  domain: env.DOMAIN,
  clientId: env.CLIENT_ID,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  onRedirectCallback(appState) {
    const state = appState && { url: appState.returnTo };

    history.push(window.location.origin, state);
  },
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: env.AUDIENCE,
  },
};
const AuthApp = withAuthenticationRequired(App, { onRedirecting: () => <Loader textDisplayed /> });
const rootHtmlElement = document.getElementById('root');

if (rootHtmlElement) {
  const root = ReactDOM.createRoot(rootHtmlElement);

  root.render(
    env.USE_AUTH ? (
      <Auth0Provider {...authProviderConfig}>
        <AuthApp />
      </Auth0Provider>
    ) : (
      <Suspense fallback={<Loader textDisplayed />}>
        <App />
      </Suspense>
    )
  );
}
