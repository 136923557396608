const getLeft = (left: number, width: number, viewportWidth: number) => {
  const hasHorizontalOverflow = viewportWidth < left + width;
  const result = hasHorizontalOverflow ? left - width : left;

  return result;
};

const getTop = (top: number, height: number, viewportHeight: number) => {
  const hasVerticalOverflow = viewportHeight < top + height;

  if (hasVerticalOverflow) {
    const alternativeTop = top - height;
    const hasSpaceAbove = alternativeTop > 0;

    if (hasSpaceAbove) {
      return alternativeTop;
    }
  }

  return top;
};

export const getInsideWindowPosition = (
  initialPosition: ({ left: number } | { right: number }) & ({ top: number } | { bottom: number }),
  element: { offsetHeight: number; offsetWidth: number }
) => {
  const { offsetWidth: width, offsetHeight: height } = element;
  const { innerWidth: viewportWidth, innerHeight: viewportHeight } = window;
  const left =
    'left' in initialPosition ? initialPosition.left : viewportWidth - initialPosition.right;
  const top =
    'top' in initialPosition ? initialPosition.top : viewportHeight - initialPosition.bottom;

  const newPosition = {
    left: getLeft(left, width, viewportWidth),
    top: getTop(top, height, viewportHeight),
  };

  return newPosition;
};
