import type { PropsWithChildren } from 'react';
import React, { createContext } from 'react';
import { useRecoilValue, type RecoilState } from 'recoil';

export const HiddenColumnsContext = createContext(new Set<number>());

type Props = {
  recoilState: RecoilState<Set<number>>;
};

export function HiddenColumnsProvider(props: PropsWithChildren<Props>) {
  const { recoilState, children } = props;
  const columnIds = useRecoilValue(recoilState);

  return (
    <HiddenColumnsContext.Provider value={columnIds}>{children}</HiddenColumnsContext.Provider>
  );
}
