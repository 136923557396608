import { fixedNumber } from './number';

export const getGrowth = (firstSales: number, lastSales: number) => {
  if (firstSales) {
    const rawGrowth = lastSales / firstSales;
    const growth = rawGrowth - 1;

    if (!isNaN(growth)) {
      const fixedGrowth = fixedNumber(growth);

      return fixedGrowth;
    }
  }

  return null;
};
