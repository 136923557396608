import { array, string } from '@harmonya/utils';
import { sanitizeQuery } from './sanitize';
import type { ReactNode } from 'react';

export type Highlightable = { node: ReactNode; isHighlighted: boolean };

type HighlightRanges = [fromIndex: number, toIndex: number][];
type GroupedOptions<T> = Map<string, T[]>;
type QueryData = { words: string[]; query: string };
type FilterOrderMatcher = (
  word: string,
  loweredOptionValue: string,
  optionValue: string,
  data: QueryData
) => Iterable<Highlightable> | undefined;

function getHighlightableTextPart(
  fullText: string,
  fromIndex: number,
  toIndex?: number,
  isHighlighted = false
) {
  const node = fullText.substring(fromIndex, toIndex);

  return { node, isHighlighted };
}

function* generateHighlightableTextParts(text: string, fromIndex: number, toIndex: number) {
  const isStartOfText = fromIndex <= 0;
  const isEndOfText = toIndex >= text.length;

  if (!isStartOfText) {
    yield getHighlightableTextPart(text, 0, fromIndex);
  }

  yield getHighlightableTextPart(text, fromIndex, toIndex, true);

  if (!isEndOfText) {
    yield getHighlightableTextPart(text, toIndex);
  }
}

function* generateHighlightableTextPartsByRanges(text: string, ranges: HighlightRanges) {
  if (!ranges.length) {
    return;
  }

  const [[firstFromIndex]] = ranges;
  const isStartOfText = firstFromIndex <= 0;

  if (!isStartOfText) {
    yield getHighlightableTextPart(text, 0, firstFromIndex);
  }

  for (let i = 0; i < ranges.length; i++) {
    const [fromIndex, toIndex] = ranges[i];

    yield getHighlightableTextPart(text, fromIndex, toIndex, true);

    const [nextFromIndex = text.length] = ranges[i + 1] ?? [];

    if (toIndex < nextFromIndex) {
      yield getHighlightableTextPart(text, toIndex, nextFromIndex);
    }
  }
}

// The filtered items order is determined by the strength of the match (the items will be order in an array
// according to the match found for them, if found, from the first to the last)
const matchers: FilterOrderMatcher[] = [
  // Water > water
  (_, loweredOptionValue, optionValue, { query }) => {
    const isMatch = loweredOptionValue === query;

    if (isMatch) {
      return [{ node: optionValue, isHighlighted: true }];
    }
  },
  // Er bot > water bottle
  (_, loweredOptionValue, optionValue, { query }) => {
    const fromIndex = loweredOptionValue.indexOf(query);

    if (array.isIndexIncluded(fromIndex)) {
      const toIndex = fromIndex + query.length;
      const highlightableTextParts = generateHighlightableTextParts(
        optionValue,
        fromIndex,
        toIndex
      );

      return highlightableTextParts;
    }
  },
  // Bottle water > water bottle
  (_, loweredOptionValue, optionValue, { words }) => {
    const ranges: HighlightRanges = [];

    for (const word of words) {
      const fromIndex = loweredOptionValue.indexOf(word);

      if (array.isIndexIncluded(fromIndex)) {
        const toIndex = fromIndex + word.length;
        const isNewMatch = ranges.every(
          ([existsFromIndex, existToIndex]) =>
            existsFromIndex >= toIndex || existToIndex <= fromIndex
        );

        if (isNewMatch) {
          ranges.push([fromIndex, toIndex]);
        }
      } else {
        return;
      }
    }

    ranges.sort(([fromIndexA], [fromIndexB]) => fromIndexA - fromIndexB);

    const highlightableTextParts = generateHighlightableTextPartsByRanges(optionValue, ranges);

    return highlightableTextParts;
  },
  // Water > water bottle
  (word, loweredOptionValue, optionValue, { query }) => {
    const isMatch = word === query;

    if (isMatch) {
      const fromIndex = loweredOptionValue.indexOf(query);
      const toIndex = fromIndex + query.length;
      const highlightableTextParts = generateHighlightableTextParts(
        optionValue,
        fromIndex,
        toIndex
      );

      return highlightableTextParts;
    }
  },
  // Water > watermelon
  (word, _, optionValue, { query }) => {
    const isMatch = word.startsWith(query);

    if (isMatch) {
      const highlightableTextParts = generateHighlightableTextParts(optionValue, 0, query.length);

      return highlightableTextParts;
    }
  },
  // Melon > watermelon
  (word, loweredOptionValue, optionValue, { query }) => {
    const isMatch = word.includes(query);

    if (isMatch) {
      const fromIndex = loweredOptionValue.indexOf(query);
      const toIndex = fromIndex + query.length;
      const highlightableTextParts = generateHighlightableTextParts(
        optionValue,
        fromIndex,
        toIndex
      );

      return highlightableTextParts;
    }
  },
];

const getMatcherOptionValueIndexes = (optionValue: string, queryData: QueryData) => {
  const loweredOptionValue = optionValue.toLowerCase();
  const words = string.getWords(loweredOptionValue);

  for (let matcherIndex = 0; matcherIndex < matchers.length; matcherIndex++) {
    const matcher = matchers[matcherIndex];

    for (let wordIndex = 0; wordIndex < words.length; wordIndex++) {
      const word = words[wordIndex];
      const highlightablesGenerator = matcher(word, loweredOptionValue, optionValue, queryData);

      if (highlightablesGenerator) {
        return { matcherIndex, wordIndex, highlightables: [...highlightablesGenerator] };
      }
    }
  }
};

const getMatcherOptionValuesIndices = <T>(
  keys: (keyof T)[] | undefined,
  option: T,
  queryData: QueryData
) => {
  const optionValues = keys ? keys.map(key => option[key]) : [option];

  for (const optionValue of optionValues) {
    if (string.isString(optionValue)) {
      const indices = getMatcherOptionValueIndexes(optionValue, queryData);

      return { indices, optionValue };
    }
  }
};

function* generateFilteredOptions<T>(
  matcherOptions: T[][][],
  secondarySort?: (optionA: T, optionB: T) => number
) {
  for (const matcherWordOptions of matcherOptions) {
    for (const options of matcherWordOptions) {
      if (options) {
        yield* secondarySort ? options.sort(secondarySort) : options;
      }
    }
  }
}

const getQueryData = (query: string) => {
  const validQuery = sanitizeQuery(query);

  if (validQuery) {
    const loweredQuery = query.toLowerCase();

    return {
      query: loweredQuery,
      words: string.getWords(loweredQuery),
    };
  }
};

function getMatcherOptions<T>(query: string, options: T[], keys?: (keyof T)[]) {
  const matcherOptions = matchers.map<T[][]>(() => []);
  const highlightedOptions = new Map<string, Highlightable[]>();
  const queryData = getQueryData(query);

  if (queryData) {
    for (const option of options) {
      const matcherOptionValues = getMatcherOptionValuesIndices(keys, option, queryData);

      if (matcherOptionValues?.indices) {
        const { indices, optionValue } = matcherOptionValues;
        const { matcherIndex: score, wordIndex, highlightables } = indices;

        array.ensuredPush(matcherOptions[score], wordIndex, option);

        highlightedOptions.set(optionValue, highlightables);
      }
    }
  }

  return { matcherOptions, highlightedOptions };
}

const getSortedFilteredUngroupedOptions = <T>(
  query: string | undefined,
  options: T[],
  keys?: (keyof T)[],
  secondarySort?: (optionA: T, optionB: T) => number
) => {
  if (query) {
    const { matcherOptions, highlightedOptions } = getMatcherOptions(query, options, keys);
    const filteredOptions = [...generateFilteredOptions(matcherOptions, secondarySort)];

    return { filteredOptions, highlightedOptions };
  }

  return { filteredOptions: options, highlightedOptions: new Map<string, Highlightable[]>() };
};

const getSortedFilteredGroupedOptions = <T>(
  query: string | undefined,
  options: GroupedOptions<T>,
  keys?: (keyof T)[],
  secondarySort?: (optionA: T, optionB: T) => number
) => {
  const filteredOptions: GroupedOptions<T> = new Map();
  const highlightedOptions = new Map<string, Highlightable[]>();

  for (const [groupKey, groupOptions] of options) {
    const matcherOptions = getSortedFilteredUngroupedOptions(
      query,
      groupOptions,
      keys,
      secondarySort
    );

    if (matcherOptions.filteredOptions.length) {
      filteredOptions.set(groupKey, matcherOptions.filteredOptions);

      matcherOptions.highlightedOptions.forEach((ranges, key) =>
        highlightedOptions.set(key, ranges)
      );
    }
  }

  return { filteredOptions, highlightedOptions };
};

type GroupableOptions<T> = T[] | GroupedOptions<T>;
type Result<T> = { filteredOptions: T; highlightedOptions: Map<string, Highlightable[]> };
type Args<O, T = O extends GroupableOptions<infer Option> ? Option : never> = [
  query: string | undefined,
  options: O,
  keys?: keyof T | (keyof T)[],
  secondarySort?: (optionA: T, optionB: T) => number,
];

export function getSortedFilteredOptions<T>(...args: Args<T[]>): Result<T[]>;

export function getSortedFilteredOptions<T>(
  ...args: Args<GroupedOptions<T>>
): Result<GroupedOptions<T>>;

export function getSortedFilteredOptions<T>(
  ...args: Args<GroupableOptions<T>>
): Result<GroupableOptions<T>>;

export function getSortedFilteredOptions<T>(
  ...args: Args<GroupableOptions<T>>
): Result<GroupableOptions<T>> {
  const [query, options, keys, secondarySort] = args;

  if (!query) {
    return { filteredOptions: options, highlightedOptions: new Map() };
  }

  const ensuredKeys = keys && array.ensure(keys);
  const filteredOptions = Array.isArray(options)
    ? getSortedFilteredUngroupedOptions(query, options, ensuredKeys, secondarySort)
    : getSortedFilteredGroupedOptions(query, options, ensuredKeys, secondarySort);

  return filteredOptions;
}
